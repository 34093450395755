import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './_eventsRange.scss';

const EventsRange = (props) => {
  const {
    startDate,
    startDateId,
    endDate,
    endDateId,
    handleDatesChange,
    focusedInput,
    handleFocusedInput,
    handleFilterDates,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  return (
    <div className="eventsrange-container__div">
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId={startDateId} // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId={endDateId} // PropTypes.string.isRequired,
        onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={handleFocusedInput} // PropTypes.func.isRequired,
        keepOpenOnDateSelect
        minimumNights={0}
        onClose={handleFilterDates}
        noBorder
        numberOfMonths={match767 ? 2 : 1}
        regular
      />
    </div>
  );
};

EventsRange.propTypes = {
  // eslint-disable-next-line react/require-default-props
  startDate: PropTypes.objectOf(PropTypes.any),
  startDateId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  endDate: PropTypes.objectOf(PropTypes.any),
  endDateId: PropTypes.string,
  handleDatesChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  focusedInput: PropTypes.string,
  handleFocusedInput: PropTypes.func.isRequired,
  handleFilterDates: PropTypes.func.isRequired,
};

EventsRange.defaultProps = {
  startDateId: 'startDate',
  endDateId: 'endDate',
};

export default EventsRange;
