import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import './_booksMediaLP.scss';

const BooksMediaLP = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="booksmedialp-container__div">
        <main className="booksmedialp-content__main">
          { scroll > 1000 && <CompFab />}
          <PageHeader header="Books &amp; Media" />
          <div className="booksmedialp-content__div">
            <section className="booksmedialp-floors__section">
              <div className="booksmedialp-floors__div">
                <div className="booksmedialp-floor__div">
                  <Link
                    to="/books-media/first-floor"
                    onClick={() => WithEvent('BOOKS_MEDIA', 'Clicked First Floor', 'BOOKS_MEDIA_FLOOR', 1)}
                    aria-label="First Floor"
                  >
                    <div className="booksmedialp-floor-bg__div booksmedialp-floor-first__div" />
                  </Link>
                </div>

                <div className="booksmedialp-floor__div">
                  <Link
                    to="/books-media/second-floor"
                    onClick={() => WithEvent('BOOKS_MEDIA', 'Clicked Second Floor', 'BOOKS_MEDIA_FLOOR', 1)}
                    aria-label="Second Floor"
                  >
                    <div className="booksmedialp-floor-bg__div booksmedialp-floor-second__div" />
                  </Link>
                </div>

                <div className="booksmedialp-floor__div">
                  <Link
                    to="/rare"
                    onClick={() => WithEvent('BOOKS_MEDIA', 'Clicked Rare & Collectibles', 'BOOKS_MEDIA_FLOOR', 1)}
                    aria-label="Rare &amp; Collectibles"
                  >
                    <div className="booksmedialp-floor-bg__div booksmedialp-floor-rare__div" />
                  </Link>
                </div>

                <div className="booksmedialp-floor__div">
                  <Link
                    to="/books-media/underground"
                    onClick={() => WithEvent('BOOKS_MEDIA', 'Clicked Underground', 'BOOKS_MEDIA_FLOOR', 1)}
                    aria-label="Underground"
                  >
                    <div className="booksmedialp-floor-bg__div booksmedialp-floor-underground__div" />
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BooksMediaLP.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BooksMediaLP;
