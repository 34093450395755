import { useState, useEffect } from 'react';
import { forceCheck } from 'react-lazyload';

const useProductListFilter = () => {
  const [prodFilterState, setProdFilter] = useState({
    checkSigned: false,
    bookBindings: [],
    bookCondition: [],
    // bookPrice: [],
    bookSection: [],
    bookPrice: { min: 0, max: 100, range: [0, 100] },
  });

  const handleProdFilter = (obj) => {
    setProdFilter(prevState => ({ ...prevState, ...obj }));
  };

  useEffect(() => {
    forceCheck();
  }, [prodFilterState]);

  return { prodFilterState, handleProdFilter };
};

export default useProductListFilter;
