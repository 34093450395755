import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WithEvent from '../withEvent/WithEvent';
import './_highlightItem.scss';

const HighlightItem = (props) => {
  const {
    link,
    hasHref,
    imgUrl,
    alt,
  } = props;

  const contentEl = (
    <div className="highlightitem-item__div">
      <img className="highlightitem-image__img" width="300" height="300" src={imgUrl} alt={alt} />
    </div>
  );

  const linkProps = {
    className: 'highlightitem-link__link',
    onClick: () => WithEvent('HIGHLIGHT', `Clicked ${alt} highlight`, 'HIGHLIGHT_LINK', 1),
    'aria-label': alt,
  };

  let childEl;

  if (link?.length) {
    if (hasHref) {
      childEl = (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          {contentEl}
        </a>
      );
    } else {
      childEl = (
        <Link
          to={link}
          {...linkProps}
        >
          {contentEl}
        </Link>
      );
    }
  } else {
    childEl = contentEl;
  }

  return (
    <div className="highlightitem-container__div">
      <div className="highlightitem-content__div">
        {childEl}
      </div>
    </div>
  );
};

HighlightItem.propTypes = {
  link: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  hasHref: PropTypes.bool,
};

export default HighlightItem;
