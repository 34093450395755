import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import Loading from '../loading/Loading';
import CompFab from '../compFab/CompFab';
import GenCarousel from '../genCarousel/GenCarousel';
// import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import use4Cite from '../../hooks/use4Cite';
import './_strandDay2021.scss';

const META_LIST_DETAIL = gql`
  query{
    bestsellersMeta: metaListDetail(metaTag: "Bestsellers"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    newArrivalsMeta: metaListDetail(metaTag: "New Arrivals"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    indieNextMeta: metaListDetail(metaTag: "Indie Next"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    prideMeta: metaListDetail(metaTag: "Pride 2021"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    tshirtMeta: metaListDetail(metaTag: "apparel t-shirts"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
  }
`;

const PRODUCT_LIST_DETAIL = gql`
  query{
    productListDetail(ean: ["9112084751", "9111026634", "9112128198", "911218845X", "9112216984", "2900012830337", "2900012649519", "9112087718", "9112331937", "9112101117", "2900012865445"]){
      gtin13
      imageUrl
      id
      title
    }
  }
`;

const IndieDay2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const match767 = useMediaQueryMinWidth(767);

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  const {
    error: errorTotes,
    loading: loadingTotes,
    data: dataTotes,
  } = useQuery(PRODUCT_LIST_DETAIL);

  if (errorMeta || errorTotes) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta || loadingTotes) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="home-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="strand-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="strand-content__main">
          <div className="strand-content__div">
            <section className="strand-hero__section">
              <div className="strand-hero__div">
                <img
                  src={match767 ? urlGenerator('/S/banners/banners_2021_strand_day1350x275.jpg') : urlGenerator('/S/banners/banners_2021_strand_day350x300.jpg')}
                  className="strand-hero__img"
                  alt="Indie Bookstore Day 2021"
                />
              </div>
            </section>
            <section className="strand-intro__section">
              <div className="strand-intro__div">
                <p className="strand-intro__p">
                  Thanks for supporting 94 years of Indie Bookstore history!
                  We&#39;re offering 20% off sitewide* the whole weekend,
                  so here are some lists to get started.
                </p>
              </div>
            </section>
            <div className="strand-border__div" />
            <section className="strand-carousels__section">
              <div className="strand-carousels__div">
                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="bestsellers"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.bestsellersMeta
                      && dataMeta.bestsellersMeta.product)
                      || []
                    }
                  />
                </div>

                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="new_arrivals"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.newArrivalsMeta
                      && dataMeta.newArrivalsMeta.product)
                      || []
                    }
                  />
                </div>

                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="/productsection/MERCH-TOTES"
                    dataType="default"
                    header="Totes"
                    products={(
                      dataTotes?.productListDetail
                    )
                    || []
                    }
                  />
                </div>

                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="indie_next"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.indieNextMeta
                      && dataMeta.indieNextMeta.product)
                      || []
                    }
                  />
                </div>

                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="pride_2021"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.prideMeta
                      && dataMeta.prideMeta.product)
                      || []
                    }
                  />
                </div>

                <div className="strand-carousel__div">
                  <GenCarousel
                    locationName="apparel_tshirts"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.tshirtMeta
                      && dataMeta.tshirtMeta.product)
                      || []
                    }
                  />
                </div>

              </div>
            </section>
            <section className="strand-disclaimer__section">
              <div className="strand-disclaimer__div">
                <p className="strand-disclaimer__p">
                  *Sale does not apply in-store. Excludes Rare Books,
                  Subscription Services (Book HookUp and Pick of the Month),
                  Books by the Foot, Vinyl Records, Gift Cards, Events, and Gift Wrapping
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

IndieDay2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default IndieDay2021;
