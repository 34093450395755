import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import EventsRange from './EventsRange';
import WithEvent from '../withEvent/WithEvent';
import convertURL from '../../helpers/convertURL';
import './_eventslist.scss';

const EventsList = (props) => {
  const { listEvents } = props;

  const [dateState, setDate] = useState({
    startDate: moment(),
    endDate: moment().add(1, 'M').endOf('month'),
    filterDates: [moment(), moment().add(1, 'M').endOf('month')],
  });

  const [focused1State, setFocused1] = useState(null);
  const [focused2State, setFocused2] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    setDate({ ...dateState, startDate, endDate });
  };

  const handleFilterDates = () => {
    if (!dateState.endDate) {
      setDate(
        {
          ...dateState,
          endDate: moment().endOf('month'),
          filterDates: [dateState.startDate, moment().endOf('month')],
        },
      );
    } else {
      setDate(
        {
          ...dateState,
          filterDates: [dateState.startDate, dateState.endDate],
        },
      );
    }
  };

  return (
    <div className="eventslist-container__div">
      <section className="eventslist-date-sort__section">
        <div className="eventslist-date-sort__div">
          <EventsRange
            startDate={dateState.startDate}
            startDateId="startDate-0"
            endDate={dateState.endDate}
            endDateId="endDate-0"
            focusedInput={focused1State}
            handleDatesChange={handleDatesChange}
            handleFocusedInput={focusInput => setFocused1(focusInput)}
            handleFilterDates={handleFilterDates}
          />
        </div>
      </section>
      <div className="eventslist-list__div">
        {
          listEvents
            && listEvents
              .filter((event) => {
                const eventDate = moment(event.endsAt, 'MM/DD/YYYY hh:mmA');
                return eventDate.isSameOrAfter(dateState.filterDates[0])
                  && eventDate.isSameOrBefore(dateState.filterDates[1]);
              }).length > 0
            ? (
              listEvents
                .filter(event => !event.private)
                .filter((event) => {
                  const eventDate = moment(event.endsAt, 'MM/DD/YYYY hh:mmA');
                  return eventDate.isSameOrAfter(dateState.filterDates[0])
                    && eventDate.isSameOrBefore(dateState.filterDates[1]);
                })
                .sort((a, b) => {
                  if (moment(a.dateObj).isBefore(moment(b.dateObj))) {
                    return -1;
                  }

                  if (moment(a.dateObj).isAfter(moment(b.dateObj))) {
                    return 1;
                  }

                  return 0;
                })
                .map((event) => {
                  const {
                    id,
                    eventName,
                    imageURL,
                    hideDateTime,
                    summary,
                    startsAt,
                    endsAt,
                    customAlias,
                  } = event;

                  const mStart = moment(startsAt, 'MM/DD/YYYY hh:mmA');
                  const mEnd = moment(endsAt, 'MM/DD/YYYY hh:mmA');

                  let timeFrame;

                  if (mStart.clone().startOf('day').isSame(mEnd.clone().startOf('day'))) {
                    timeFrame = `${mStart.format('dddd MMMM DD hh:mmA')}-${mEnd.format('hh:mmA')} ET`;
                  } else {
                    timeFrame = `${mStart.format('dddd MMMM DD hh:mmA')} through ${mEnd.format('dddd MMMM DD hh:mmA')} ET`;
                  }

                  return (
                    <div key={id} className="eventslist-item__div">
                      <Link
                        to={`/events/${id}?title=${customAlias.length ? convertURL(customAlias) : convertURL(eventName)}`}
                        className="eventslist-link__link"
                        onClick={() => WithEvent('EVENTS', `Clicked ${eventName} Image`, 'EVENTS_LIST_IMAGE', 1)}
                      >
                        <div className="eventslist-image__div">
                          <img className="eventslist-image__img" src={imageURL} alt={eventName} />
                        </div>
                      </Link>
                      <div className="eventslist-details__div">
                        <Link
                          to={`/events/${id}?title=${customAlias.length ? convertURL(customAlias) : convertURL(eventName)}`}
                          className="eventslist-link__link"
                          onClick={() => WithEvent('EVENTS', `Clicked ${eventName} Title`, 'EVENTS_LIST_TITLE', 1)}
                        >
                          <h3 className="eventslist-header__h3" dangerouslySetInnerHTML={{ __html: eventName }} />
                        </Link>
                        <div className="eventslist-accent__div" />
                        {!hideDateTime && <p className="eventslist-details__p eventslist-date__p" data-testid="event-date">{timeFrame}</p>}
                        <div data-testid="event-summary" className="eventslist-summary__div" dangerouslySetInnerHTML={(() => ({ __html: summary }))()} />
                        <div className="eventslist-more__div">
                          <Link
                            to={`/events/${id}?title=${customAlias.length ? convertURL(customAlias) : convertURL(eventName)}`}
                            className="eventslist-more-link__link"
                            onClick={() => WithEvent('EVENTS', `Clicked ${eventName} Title`, 'EVENTS_LIST_MORE_DETAILS', 1)}
                          >
                            <p className="eventslist-more__p">See More Details</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
            )
            : (
              <div className="eventslist-no-events__div">
                <h2 className="eventslist-header__h2">
                  We don&#39;t have any events at this time
                </h2>
              </div>
            )
        }
      </div>
      <section className="eventslist-date-sort__section">
        <div className="eventslist-date-sort__div">
          <EventsRange
            startDate={dateState.startDate}
            startDateId="startDate-1"
            endDate={dateState.endDate}
            endDateId="endDate-1"
            focusedInput={focused2State}
            handleDatesChange={handleDatesChange}
            handleFocusedInput={focusInput => setFocused2(focusInput)}
            handleFilterDates={handleFilterDates}
          />
        </div>
      </section>
    </div>
  );
};

EventsList.propTypes = {
  listEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EventsList;
