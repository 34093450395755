import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/react';

import urlGenerator from '../../helpers/urlGenerator';
import WithTracker from '../withTracker/WithTracker';
import Loading from '../loading/Loading';

import Checkout from '../checkout/Checkout';

import routesData from './routesData';

import './_routes.scss';

export const LOGGED_IN = gql`
 query{
   customerLoggedIn
 }
`;

export const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        catalogId
        description
        gtin
        isTaxable
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      customer{
        customerId
        firstName
        lastName
        verified
      }
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

export const OPEN_CHECKOUT = gql`
  query{
    checkoutIsOpen
  }
`;

const CONTEXT = gql`
  query {
   context { ua ci }
  }
`;

const Routes = () => {
  const {
    loading: contextIdLoading,
    data: contextIdData,
    error: contextIdError,
  } = useQuery(CONTEXT);

  const {
    error: errorCustomerLoggedIn,
    loading: loadingCustomerLoggedIn,
    data: dataCustomerLoggedIn,
    refetch: refetchCustomerLoggedIn,
  } = useQuery(LOGGED_IN);

  const {
    error: errorCartList,
    loading: loadingCartList,
    data: dataCartList,
    refetch: refetchCartList,
  } = useQuery(GET_CART_DETAILS);

  const {
    error: errorCheckoutIsOpen,
    loading: loadingCheckoutIsOpen,
    data: dataCheckoutIsOpen,
    refetch: refetchCheckoutIsOpen,
  } = useQuery(OPEN_CHECKOUT);

  const trackerProps = {
    customerLoggedIn: dataCustomerLoggedIn?.customerLoggedIn,
    cartList: dataCartList?.cartList,
    checkoutIsOpen: dataCheckoutIsOpen?.checkoutIsOpen,
    refetchCartList,
    refetchCheckoutIsOpen,
    refetchCustomerLoggedIn,
  };

  // useEffect(() => {
  //   refetchCartList();
  // }, [refetchCartList, dataCustomerLoggedIn]);

  if (!contextIdLoading && !contextIdError) {
    Sentry.setTag('ua-id', contextIdData.context.ua);
    Sentry.setTag('ci-id', contextIdData.context.ci);
  }

  useEffect(() => {
    localStorage.setItem('networkOk', true);
  });

  if (errorCustomerLoggedIn || errorCartList || errorCheckoutIsOpen) {
    console.log({ errorCustomerLoggedIn, errorCartList, errorCheckoutIsOpen });

    return (
      <div className="routes-error__div">
        <div className="routes-logo__div">
          <img className="routes-logo__img" alt="Strand Book Store" src={urlGenerator('/S/static/strand-logo-exact.png')} />
        </div>
        <h2 className="routes-header__h2">The site is currently down for maintenance.  Please try again later.</h2>
      </div>
    );
  }

  if (loadingCustomerLoggedIn || loadingCartList || loadingCheckoutIsOpen) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path="/checkout" component={Checkout} />

      {routesData.map((routeItem) => {
        const {
          type,
          to,
          Component,
          ...routeProps
        } = routeItem;

        if (type === 'redirect') {
          routeProps.children = <Redirect to={to} />;
        } else {
          routeProps.component = WithTracker(Component, {}, trackerProps);
        }

        return (
          <Route
            key={routeProps.path}
            {...routeProps}
          />
        );
      })}

      <Redirect to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
