import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WithEvent from '../withEvent/WithEvent';
import './_compBack.scss';

const CheckoutBack = (props) => {
  const {
    backURL,
  } = props;

  return (
    <div className="compback-container__div">
      <div className="compback-button__div">
        <Link
          className="compback-link__link"
          to={backURL}
          onClick={() => WithEvent('COMP_BACK', `Clicked back ${backURL}`, 'BACK_LINK', 1)}
        >
          <ArrowBackIcon />
          <span className="compback-btn-text__span">Go Back</span>
        </Link>
      </div>
    </div>
  );
};

CheckoutBack.propTypes = {
  backURL: PropTypes.string.isRequired,
};

export default CheckoutBack;
