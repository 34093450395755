import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import './_error.scss';

const Error = (props) => {
  const { location } = props;

  if (location && location.state) {
    // show error in console for debugging purposes

    // eslint-disable-next-line
    console.log('error log', location.state);
  }

  return (
    <div className="error-container__div">
      <main className="error-content__main">
        <div className="error-content__div">
          <div className="error-image__div">
            <img src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" className="error-image__img" />
          </div>
          <h2 className="error-header__h2">Uh-oh!</h2>
          <div className="error-details__div">
            {
              location
                && location.state
                && location.state.noKey
                && <p className="error-message__p">The password link seems to be broken. Please try again.</p>
            }
            {
              location
                && location.state
                && location.state.loadingCompError
                && <p className="error-message__p">The page took too long to respond. We apologize for the inconvenience.</p>
            }
            {
              location
               && location.state
               && (!location.state.noKey && !location.state.loadingCompError)
                && (
                  <p className="error-details__p">
                    There seems to be something wrong with our website at the moment.
                    We apologize for the inconvenience!
                    <br />
                    <br />
                    Rest assured that our booksellers are looking into the issue.
                  </p>
                )
            }
          </div>
          <div className="error-btn__div">
            <Link
              to="/"
              className="error-btn__link"
              onClick={() => WithEvent('ERROR', 'Click Return to Home', 'RETURN_BTN', 1)}
            >
              Return to Home
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

Error.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Error;

/*

error structure example

"errors": [
    {
      "locations": [
        {
          "column": 0,
          "line": 2
        }
      ],
      "message": "can't be blank",
      "path": [
        "checkoutUpdate"
      ],
      "pathf": [
        "ship_addr",
        "attention"
      ]
    }
  ]
*/
