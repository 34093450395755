import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import './_bbtfCollections.scss';

const BBTFCollections = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtfcollections-container__div">
        { scroll > 1000 && <CompFab />}
        <div className="bbtfcollections-hero__div" />
        <main className="bbtfcollections-content__main">
          <div className="bbtfcollections-content__div">
            <PageHeader header="Collections" />
            <section className="bbtfcollections-list__section">
              <div className="bbtfcollections-list__div">
                <div className="bbtfcollections-category__div bbtfcollections-category-color__div">
                  <Link
                    className="bbtfcollections-link__link"
                    to="/books-by-the-foot/color"
                    aria-label="collections by color"
                    onClick={() => WithEvent('BBTF', 'Clicked BBTF Color', 'BBTF_COLLECTIONS', 1)}
                  >
                    <div className="bbtfcollections-overlay__div">
                      <div
                        className="bbtfcollections-category-bg__div bbtfcollections-category-color-bg__div"
                      />
                    </div>
                  </Link>
                </div>
                <div className="bbtfcollections-category__div bbtfcollections-category-style__div">
                  <Link
                    className="bbtfcollections-link__link"
                    to="/books-by-the-foot/style"
                    aria-label="collections by style"
                    onClick={() => WithEvent('BBTF', 'Clicked BBTF Style', 'BBTF_COLLECTIONS', 1)}
                  >
                    <div
                      className="bbtfcollections-category-bg__div bbtfcollections-category-style-bg__div"
                    />
                  </Link>
                </div>
                <div className="bbtfcollections-category__div  bbtfcollections-category-subject__div">
                  <Link
                    className="bbtfcollections-link__link"
                    to="/books-by-the-foot/subject"
                    aria-label="collections by subject"
                    onClick={() => WithEvent('BBTF', 'Clicked BBTF Subject', 'BBTF_COLLECTIONS', 1)}
                  >
                    <div className="bbtfcollections-overlay__div">
                      <div
                        className="bbtfcollections-category-bg__div bbtfcollections-category-subject-bg__div"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFCollections.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFCollections;
