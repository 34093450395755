import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import StrandCastFeat from './StrandCastFeat';
import StrandCastList from './StrandCastList';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import strandCastEpList from './strandCastEpList';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_strandcast.scss';

const StrandCast = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      {/* <PageHeader header="The StrandCast" /> */}
      <div className="strandcast-container__div">
        {scroll > 1000 && <CompFab />}
        <div className="strandcast-logo__div">
          <img
            src={urlGenerator('/S/pages/strandcast-logo.png')}
            alt="The StrandCast"
            className="strandcast-logo__img"
          />
        </div>
        <main className="strandcast-content__main">
          <div className="strandcast-content__div">
            <section className="strandcast-info__section">
              <div className="strandcast-info__div">
                <div className="strandcast-info-details__div">
                  <p className="strandcast-info-details__p">
                    Welcome to the StrandCast, a literary podcast from your
                    favorite indie bookstore.
                    Episodes will be released every other week featuring author interviews,
                    reading recommendations, literary horoscopes and more. Share your thoughts,
                    feedback and ideas with us on social at&nbsp;
                    <a className="strandcast-link__a" href="https://twitter.com/strandbookstore" target="_blank" rel="noopener noreferrer">
                      @strandbookstore
                    </a>
                    .
                  </p>
                </div>
              </div>
            </section>
            <section className="strandcast-feature__section">
              <div className="strandcast-feature__div">
                <StrandCastFeat featEp={strandCastEpList[strandCastEpList.length - 1]} />
              </div>
            </section>
            <div className="strandcast-divider__div" />
            <section className="strandcast-list__section">
              <div className="strandcast-list__div">
                <h2 className="strandcast-header__h2">Episode List</h2>
                <StrandCastList
                  epList={strandCastEpList.slice(0, strandCastEpList.length - 1)}
                />
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

StrandCast.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default StrandCast;
