import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import convertURL from '../../helpers/convertURL';
import convertSlashURL from '../../helpers/convertSlashURL';
import locationsDictionary from '../../helpers/locationsDictionary';
import metaListDictionary from '../../helpers/metaListDictionary';
import urlGenerator from '../../helpers/urlGenerator';
import WithEvent from '../withEvent/WithEvent';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_genCarousel.scss';
import sectionsDictionary from '../../helpers/sectionsDictionary';

const GenCarousel = (props) => {
  const {
    products,
    locationName,
    dataType,
    noHeader,
    header,
  } = props;

  const useStyles = makeStyles({
    rootIconButtonPrev: {
      color: '#ffffff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
    rootNavigate: {
      width: '1.5em',
      height: '1.5em',
    },
    rootIconButtonNext: {
      color: '#ffffff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
    rootIconButton: {
      display: 'none',
    },
    rootIconButtonActive: {
      color: '#ffffff',
      opacity: 1,
      backgroundColor: 'rgba(191, 46, 26, .1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
  });

  const classes = useStyles();

  const PrevArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;

    return (
      <div style={{
        ...style,
        // display: 'flex',
        // justifyContent: 'flex-start',
        position: 'absolute',
        zIndex: 8,
        top: '45%',
        left: '10px',
      }}
      >
        <IconButton aria-label="previous" onClick={onClick} classes={{ root: classes.rootIconButtonActive }}>
          <NavigateBeforeRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };

  const NextArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;

    return (
      <div style={{
        ...style,
        // display: 'flex',
        // justifyContent: 'flex-end',
        position: 'absolute',
        zIndex: 8,
        top: '45%',
        right: '10px',
      }}
      >
        <IconButton aria-label="next" onClick={onClick} classes={{ root: classes.rootIconButtonActive }}>
          <NavigateNextRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };


  const settings = {
    slidesToScroll: 1,
    slidesToShow: 6,
    lazyLoad: true,
    dots: false,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: 'gencarousel-slider__div',
    responsive: [
      {
        breakpoint: 2561,
        settings: {
          slidesToShow: products && products.length < 5 ? products.length : 5,
          centerMode: false,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: products && products.length < 5 ? products.length : 5,
          centerMode: false,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products && products.length < 4 ? products.length : 4,
          centerMode: false,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: products && products.length < 4 ? products.length : 4,
          centerMode: false,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '100px',
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '100px',
          arrows: false,
        },
      },
    ],
  };

  const setHeader = (type) => {
    if (!type || type === 'default') {
      return header;
    }

    if (type === 'location') {
      return locationsDictionary(locationName);
    }

    if (type === 'metaList') {
      if (metaListDictionary(locationName).alt) {
        return metaListDictionary(locationName).alt;
      }
      return metaListDictionary(locationName).metaTag;
    }

    if (type === 'section') {
      return sectionsDictionary(locationName);
    }

    return locationsDictionary(locationName);
  };

  return (
    <div className="gencarousel-container__div" onFocus={() => {}}>
      <div className="gencarousel-content__div">
        {/* { noHeader ? <span /> : <PageSubHeader header={dataType === 'metaList' ? (metaListDictionary(locationName).alt ? metaListDictionary(locationName).alt : metaListDictionary(locationName).metaTag) : locationsDictionary(locationName)} />} */}
        { noHeader ? <span /> : <PageSubHeader header={setHeader(dataType)} />}
        <Slider {...settings}>
          {
            products.length
              && products.filter(prod => prod)
                .slice(0, 8)
                .map((prod, i) => {
                  const {
                    id,
                    imageUrl,
                    title,
                    // originator,
                    // retailStv,
                    gtin13,
                  } = prod;

                  return (
                    <div className="gencarousel-item__div" key={id}>
                      <Link
                        onClick={() => WithEvent('CAROUSEL', `Clicked ${locationName} Item ${title}`, 'CAROUSEL_ITEM_CLICK', 1)}
                        to={
                          location => (
                            {
                              ...location,
                              pathname: `/product/${gtin13}`,
                              search: `?title=${convertURL(title)}`,
                              state: { title: convertURL(title), gtin13 },
                            }
                          )
                        }
                        className="gencarousel-item-link__link"
                      >
                        <div className="gencarousel-item-image__div">
                          {
                            dataType === 'metaList'
                            && locationName === 'bestsellers'
                            && (
                            <div className="gencarousel-bestseller-flag__div">{i + 1}</div>
                            )
                          }
                          <img
                            alt={title}
                            src={imageUrl || urlGenerator('/G/83A3BB34/EAN-9789112251753')}
                            className="gencarousel-item-image__img"
                          />
                        </div>
                      </Link>
                    </div>
                  );
                })
            }
        </Slider>
        <div className="gencarousel-all-link__div">
          {
            dataType === 'default'
              && (
              <Link
                onClick={() => WithEvent('CAROUSEL', `Clicked ${locationName} View All`, 'CAROUSEL_VIEW_ALL', 1)}
                to={locationName}
                className="gencarousel-all-link__link"
              >
                View All
              </Link>
              )
          }
          {
            dataType === 'metaList'
              && (
              <Link
                onClick={() => WithEvent('CAROUSEL', `Clicked ${locationName} View All`, 'CAROUSEL_VIEW_ALL', 1)}
                to={location => (
                  {
                    ...location,
                    pathname: `metaList/${convertURL(locationName)}`,
                  }
                )}
                search="?page=1&pagelimit=40&sort=1&signed=false&stock=ANY&bookbindings=null&bookcondition=null&booksection=null&bookprice=null"
                className="gencarousel-all-link__link"
              >
                View All
              </Link>
              )
          }
          {
            dataType === 'location'
              && (
              <Link
                onClick={() => WithEvent('CAROUSEL', `Clicked ${locationName} View All`, 'CAROUSEL_VIEW_ALL', 1)}
                to={location => (
                  {
                    ...location,
                    pathname: `/productlocation/${convertURL(locationName)}`,
                    search: '?page=1&pagelimit=40&sort=1&signed=false&stock=ANY&bookbindings=null&bookcondition=null&booksection=null&bookprice=null',
                  }
                )
              }
                search="?page=1&pagelimit=40&sort=1&signed=false&stock=ANY&bookbindings=null&bookcondition=null&booksection=null&bookprice=null"
                className="gencarousel-all-link__link"
              >
                View All
              </Link>
              )
          }
          {
            dataType === 'section'
              && (
              <Link
                onClick={() => WithEvent('CAROUSEL', `Clicked ${locationName} View All`, 'CAROUSEL_VIEW_ALL', 1)}
                to={
                  location => (
                    {
                      ...location,
                      pathname: `/productsection/${convertSlashURL(locationName)}`,
                      search: '?page=1&pagelimit=40&sort=1&signed=false&stock=ANY&bookbindings=null&bookcondition=null&booksection=null&bookprice=null',
                    }
                  )}
                search="?page=1&pagelimit=40&sort=1&signed=false&stock=ANY&bookbindings=null&bookcondition=null&booksection=null&bookprice=null"
                className="gencarousel-all-link__link"
              >
                View All
              </Link>
              )
          }
          {/* <Link to={dataType === 'location' ? `/productlocation/${convertURL(locationName)}` : `/productsection/${convertSlashURL(locationName)}`} search="?page=1&pagelimit=40&sort=1&signed=false&bookbindings=null&bookcondition=null&booksection=null&bookprice=null" className="gencarousel-all-link__link">View All</Link> */}
          <div className="gencarousel-accent__div" />
        </div>
        <div className="gencarousel-border__div" />
      </div>
    </div>
  );
};

GenCarousel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  locationName: PropTypes.string.isRequired,
  dataType: PropTypes.oneOf(['location', 'section', 'metaList', 'default']).isRequired,
  // eslint-disable-next-line react/require-default-props
  noHeader: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  header: PropTypes.string,
};

export default GenCarousel;
