import urlGenerator from '../../helpers/urlGenerator';

const strandCastEpList = [
  {
    id: 'ep01',
    epTitle: 'Glory Edim',
    epSubTitle: 'Well-Read Black Girl: Finding Our Stories, Discovering Ourselves',
    hosts: 'Boice-Terrel Allen, Eirini Karoutsos, Sofia Nehlawi',
    description: 'How do you turn an Instagram account on books into a New York Times profile in just three years? Ask Glory Edim, founder of Well-Read Black Girl, a book club for black women and fiction centered on their lives. In scant time, Glory has taken Well-Read Black Girl from a handful of Instagram followers to an annual book festival and a book deal consisting of 2018’s bestselling Well-Read Black Girl: Finding Our Stories, Discovering Ourselves and a forthcoming memoir. What earnestly started as an online community has become a representation of both the joys and traumas of black women. Glory believes these life stories are for anyone who wants to learn about worlds outside their own. Stories of hope and triumph will always transcend.',
    iFrame: '//html5-player.libsyn.com/embed/episode/id/10227473/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-gloria-edim.jpg'),
    productURL: '/product/9780525619772?title=wellread_black_girl_finding_our_stories_discovering_ourselves',
    bookTitle: 'Well-Read Black Girl: Finding Our Stories, Discovering Ourselves',
    comingSoon: false,
    date: '07/18/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep02',
    epTitle: 'James Oseland',
    epSubTitle: 'Jimmy Neurosis: A Memoir',
    hosts: 'Boice-Terrel Allen, Eirini Karoutsos, Sofia Nehlawi',
    description: "In the case of James Oseland’s memoir, Jimmy Neurosis, we should totally judge a book by its cover. Replete in a slicked-back coif (think Bowie’s Thin White Duke period), ironic bank clerk-clothes and an insouciant expression; it's James at a time when he thought knew everything. Page after page might debunk this notion, but it's a ride through the late 70s with teenage James hanging out on/in the San Francisco punk scene. Jimmy Neurosis, James's alter ego, shines in the concentrated tale (1977-1980) of a young gay guy discovering himself through music, high school bullying, drugs and chic friends named Blackie O. It’s all narrated without a whiff of self-pity. If you’re a foodie who loved James from his judging stints on Top Chef Masters, Jimmy Neurosis will be your insiders look into a youth that was alternately harrowing and hilarious.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/10227548/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-james-oseland.jpg'),
    productURL: '/product/9780062267368?title=jimmy_neurosis_a_memoir',
    bookTitle: 'Jimmy Neurosis: A Memoir',
    comingSoon: false,
    date: '07/30/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep03',
    epTitle: 'Eddie Stern',
    epSubTitle: 'One Simple Thing: A New Look at the Science of Yoga and How It Can Transform Your Life',
    hosts: 'Boice-Terrel Allen, Eirini Karoutsos, Sofia Nehlawi',
    description: "According to yoga teacher Eddie Stern, we could all use a little more space for happiness. In his book, One Simple Thing: A New Look at the Science of Yoga and How It Can Transform Your Life, he gives us a road map to unearthing our best selves. For Eddie, the journey began in his mid-teens at a yoga camp. He's been sharing his knowledge and expertise ever since through Urban Yogis (a gun and harm-reduction program), his Ashtanga yoga classes, and co-founding the Breathing app, along with Moby and Deepak Chopra (who also wrote One Simple Thing’s foreword). One Simple Thing continues Eddie’s mission by providing the reader with the myriad benefits of both meditation and yoga practice. Plus, he was Madonna’s instructor for ten years!",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/10227722/height/45/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-eddie-stern.jpg'),
    productURL: '/product/9780865478398?title=one_simple_thing_a_new_look_at_the_science_of_yoga_and_how_it_can_transform_your_life',
    bookTitle: 'One Simple Thing: A New Look at the Science of Yoga and How It Can Transform Your Life',
    comingSoon: false,
    date: '08/13/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep04',
    epTitle: 'Lauren Wilkinson',
    epSubTitle: 'American Spy: A Novel',
    hosts: 'Boice-Terrel Allen, Eirini Karoutsos, Sofia Nehlawi',
    description: "Lauren Wilkinson doesn't have much of a problem with your distilling American Spy, her debut novel, into a book about a “black female spy”. Although technically accurate, Lauren’s debut is far more expansive than those three words might suggest. Set during the Cold War, FBI agent Marie Mitchell is assigned with taking down Thomas Sankara, a beloved communist leader in Burkina Faso. Based on the real-life president who was referred to as “Africa’s Che Guevara”, Marie develops feelings that fall outside of her duties. As with most novels of intrigue, no one is quite who they appear. Born and bred in New York, Lauren graduated from Columbia University with a MFA in fiction and literary translation. American Spy originated as a short story class assignment.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/10227998/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-lauren-wilkinson.jpg'),
    productURL: '/product/9780812998955?title=american_spy_a_novel',
    bookTitle: 'American Spy: A Novel',
    comingSoon: false,
    date: '08/27/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep05',
    epTitle: 'R.L. Stine',
    epSubTitle: 'Beloved Horror Fiction Author of Goosebumps and Fear Street',
    hosts: 'Boice-Terrel Allen',
    description: "Although his books can be enjoyed anytime of the year, October can deﬁnitely be referred to as R.L. Stine Season! For over 30 years, Stine has been the undisputed king of horror ﬁction for children and YA readers. Adults love him, too. With hundreds of titles to his credit, including the Goosebumps and Fear Street series of books, Stine continues to thrill fans by adding graphic novels and the forthcoming Fear Street movies to his résumé. It's no wonder R.L. Stine remains an inspiration for anyone who's ever read one of his books.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/11801231/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-just-beyond.jpg'),
    productURL: '',
    bookTitle: '',
    comingSoon: false,
    date: '10/28/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep06',
    epTitle: 'Elissa Altman',
    epSubTitle: "A Daughter's \"Motherland\" Memoir of Love, Loathing and Longing",
    hosts: 'Boice-Terrel Allen',
    description: "Elissa Altman’s memoir, <em>Motherland: A Memoir of Love, Loathing, and Longing</em> (Ballantine Books), spins our idea of the maternal instinct right on its archetypal head. Look elsewhere if you're expecting warm and cuddly tales of mother/daughter bonding because Elissa’s mother, Rita, shares life’s stage with no one and that includes her daughter. Motherland is Elissa’s more bitter, than sweet journey of a life spent living for her mother and how she learned to live for herself. Elissa is also the author of the previous books, Treyf: My Life as an Unorthodox Outlaw and Poor Man’s Feast: A Love Story of Comfort, Desire and the Art of Simple Cooking. Poor Man’s Feast is also the name of her James Beard Award-winning narrative blog.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/11990201/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast-elissa-altman.jpg'),
    productURL: '/product/9780399181580?title=motherland_a_memoir_of_love_loathing_and_longing',
    bookTitle: "A Daughter's \"Motherland\" Memoir of Love, Loathing and Longing",
    comingSoon: false,
    date: '11/12/19',
    authorImage: urlGenerator('/S/pages/strandcast_altman.png'),
    authorBio: "Elissa Altman is the author of the memoirs <em>Motherland, Treyf, Poor Man's Feast</em>, and the James Beard Award-winning narrative blog of the same name. Her work has appeared everywhere from <em>Narrative Magazine</em> and <em>O: The Oprah Magazine</em> to LitHub, <em>Tin House</em>, the <em>Wall Street Journal</em>, On Being, and the <em>Washington Post</em>, where her column, <em>Feeding My Mother</em>, ran for a year. A finalist for the Frank McCourt Memoir Prize, she teaches the craft of memoir at Fine Arts Work Center, 1440 Multiversity, The Loft Literary Center, Literature and Larder in Ireland, and has appeared live on the TEDx stage and The Public. She lives in New England.",
  },
  {
    id: 'ep07',
    epTitle: "Ryan O'Callaghan",
    epSubTitle: 'My Life on the Line: How the NFL Damn Near Killed Me, and Ended Up Saving My Life',
    hosts: 'boice-Terrel Allen',
    description: "Coming from a rural California background, Ryan O’Callaghan intuited at an early age that hiding he was gay was his only option. Whether it was personally or in his six-year career as an offensive lineman for the New England Patriots then the Kansas City Chiefs, Ryan’s life in the closet took its toll in numerous ways. In his memoir, My Life on the Line (co-written with Cyd Zeigler), Ryan details his years of depression, threats of suicide and an opioid addiction spurred by multiple football-related injuries. My Life on the Line isn't only about the cost of living a lie, it's ultimately about Ryan deciding to lead an open and honest life as a gay man.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/11676110/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/BC839512/EAN-9781617757594'),
    productURL: '/product/9781617757594?title=my_life_on_the_line_how_the_nfl_damn_near_killed_me_and_ended_up_saving_my_life',
    bookTitle: 'My Life on the Line: How the NFL Damn Near Killed Me, and Ended Up Saving My Life',
    comingSoon: false,
    date: '11/26/19',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep08',
    epTitle: 'Adam Mansbach',
    epSubTitle: "Author of Humor from a Children's Picture Book for Adults to \"A Field Guide to the Jewish People\"",
    hosts: 'boice-Terrel Allen',
    description: 'Adam Mansbach famously gave voice to two of parenting’s primal struggles in Go the F–k to Sleep and You Have to F–king Eat—the unrivalled pair of New York Times best sellers that ushered in a new era of radical honesty in humour books for parents. But what could possibly be left? Parents—new, old, expectant—of multiple children already knew the answer and Adam discovered it for himself by having two more kids, less than two years apart. F–k, Now There Are Two of You is a loving monologue about the new addition to the family, addressed to a big sibling and shot through with Adam’s trademark profane truth-telling. Gorgeously illustrated and full of unspoken sentiments channelled directly from the brains of parents worldwide, F–k, Now There Are Two of You articulates all the fears and frustrations attendant to the simple, maths-defying fact that two is a million more kids than one. As you probably know by now, you shouldn’t read it to a child.',
    iFrame: '//html5-player.libsyn.com/embed/episode/id/12329474/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/E86C6989/EAN-9781250191960'),
    productURL: '/product/9781250191960',
    bookTitle: 'A Field Guide to the Jewish People: Who They Are, Where They Come From, What to Feed Them...and Much More. Maybe Too Much More',
    comingSoon: false,
    date: '12/10/19',
    authorImage: urlGenerator('/S/pages/strandcast_mansbach.png'),
    authorBio: 'ADAM MANSBACH is a novelist, screenwriter, humorist, and cultural critic (in addition to having built an obscene fake children’s book empire with <em>Go the Fuck to Sleep</em> and <em>You Have to Fucking Eat</em>). His books include <em>Rage Is Back</em>, the California Book Award–winning <em>The End of the Jews</em>, and the best sellers <em>Angry Black White Boy</em> and <em>For This We Left Egypt?</em>, cowritten with Dave Barry and Alan Zweibel. Mansbach was nominated for an Independent Spirit Award for his screenplay for the Netflix Original <em>Barry</em>, and is currently adapting his middle-grade novel <em>Jake the Fake Keeps It Real</em> for the Disney Channel. His work has appeared in the <em>New Yorker</em>, the <em>New York Times Book Review</em>, <em>Esquire</em>, and on National Public Radio’s This American Life, the Moth, and All Things Considered. Fuck, Now There Are Two of You is his latest book. Adam is also the co-writer of A Field Guide to the Jewish People: Who They Are, Where They Come From, What to Feed Them…and Much More. Maybe Too Much More along with Dave Barry and Zweibel.',
  },
  {
    id: 'ep09',
    epTitle: 'André Aciman',
    epSubTitle: 'Find Me',
    hosts: 'boice-Terrel Allen',
    description: 'André Aciman’s 2007 novel <em>Call Me by Your Name</em> captivated readers with the relationship between Elio and Oliver. Ten years later, Luca Guadagnino’s ﬁlm adaption further expanded the adoration fans felt for the characters. <em>Find Me</em>, the follow-up, is a less a sequel and more of an unexpected view into the lives of Oliver, Elio and his father.',
    iFrame: '//html5-player.libsyn.com/embed/episode/id/12568367/height/360/theme/standard/thumbnail/no/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/3038E67C/EAN-9780374155018'),
    productURL: '/product/9780374155018?title=find_me',
    bookTitle: 'Find Me',
    comingSoon: false,
    date: '01/07/20',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep10',
    epTitle: 'Deborah Davis',
    epSubTitle: 'My Love Story',
    hosts: 'boice-Terrel Allen',
    description: "Only a select few can claim they've spent hours in the presence of Tina Turner. Deborah Davis is in that rareﬁed club. As the co-author (along with Dominik Wichmann) of Ms. Turner’s second memoir, <em>My Love Story</em>, she held witness to the triumphant life and career of the Queen of Rock and her story of resilience. Davis has also written books on Truman Capote, Andy Warhol and Oprah Winfrey.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/12807116/height/360/theme/standard/thumbnail/yes/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/S/pages/strandcast_my_love_story.jpg'),
    productURL: '',
    bookTitle: 'My Love Story',
    comingSoon: false,
    date: '01/21/20',
    authorImage: urlGenerator('/S/banners/strandcast_davis.jpg'),
    authorBio: 'Deborah Davis is the author of 10 books, including <em>My Love Story</em>, which she co-wrote with music legend Tina Turner, <em>Strapless: John Singer Sargent and the Fall of Madame X</em>, <em>Party of the Century: The Fabulous Story of Truman Capote and His Black and White Ball</em>, <em>Gilded: How Newport Became the Richest Resort in America</em>, <em>The Oprah Winfrey Show: Reflections on an American Legacy</em>, <em>Guest of Honor: Booker T. Washington</em>, <em>Theodore Roosevelt, and the White House Dinner that Shocked a Nation</em>, which won the prestigious Phillis Wheatley Award for best work of History in 2013, and was nominated for an NAACP Image Award; <em>Fabritius and the Goldfinch</em>, which Amazon named one of the Best Books of 2014, and <em>The Trip: Andy Warhol¹s Plastic-Fantastic Cross-Country Adventure</em>, the story of Warhol¹s kaleidoscopic road-trip to Hollywood and back in 1963.',
  },
  {
    id: 'ep11',
    epTitle: 'Anna Borges',
    epSubTitle: 'The More or Less Definitive Guide to Self-Care',
    hosts: 'boice-Terrel Allen',
    description: 'The beauty of <em>The More or Less Deﬁnitive Guide to Self-Care</em> by Anna Borges is how it proves self-care is a such personal act. The A-Z format of the book allows you to dip in and choose from dozens of suggestions that strike your fancy. If sitting silent for ﬁve minutes sounds like sheer torture, you can always doodle or maybe count sheep under a weighted blanket. <br/><br/> Borges is also the senior health editor at <em>SELF</em> and the podcast host of <em>We Are YA</em>.',
    iFrame: '//html5-player.libsyn.com/embed/episode/id/12991883/height/360/theme/standard/thumbnail/yes/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/EF03EFC0/EAN-9781615196104'),
    productURL: '/product/9781615196104',
    bookTitle: 'The More or Less Definitive Guide to Self-Care',
    comingSoon: false,
    date: '02/04/20',
    authorImage: urlGenerator('/S/pages/strandcast_borges.png'),
    authorBio: "Anna Borges is the author of <em>The More or Less Definitive Guide to Self-Care</em>. Currently, she's a senior editor at <em>SELF</em> magazine and the host of Penguin Teen's We Are YA podcast, and you can find her writing about mental health around the internet. An Oregon native, she lives in Brooklyn with her two cats.",
  },
  {
    id: 'ep12',
    epTitle: 'Ross Mathews',
    epSubTitle: "Author of \"Name Drop\" and Judge on Rupaul's Drag Race",
    hosts: 'boice-Terrel Allen',
    description: "When they go low, Ross Mathews goes funny. In his second book, <em>Name Drop</em>, Matthews shares thirteen non-mean-spirited brushes with celebrities. <em>Name Drop</em> is a hilarious collection of his encounters with the rich and famous: including Mathews making a diﬀerence in Lady Gaga's life and then there was that time he secretly stalked Liza Minnelli. This follow-up to Mathews 2013 <em>Man-Up</em> memoir also features original cocktails and his very own ROSSipes that accompany each star-struck tale.<br><br>Mathews is also a judge on <em>RuPaul's Drag Race</em> and the host of the podcast, <em>Straight Talk</em>.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/13196270/height/360/theme/standard/thumbnail/yes/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/2B7708BF/EAN-9781982116484'),
    productURL: '/product/9781982116484?title=name_drop_the_really_good_celebrity_stories_i_usually_only_tell_at_happy_hour',
    bookTitle: 'Name Drop: The Really Good Celebrity Stories I Usually Only Tell at Happy Hour',
    comingSoon: false,
    date: '02/18/20',
    authorImage: '',
    authorBio: '',
  },
  {
    id: 'ep13',
    epTitle: 'Lane Moore',
    epSubTitle: 'Comedian, Musician and Writer of Personal Essay Collection, "How to Be Alone"',
    hosts: 'boice-Terrel Allen',
    description: "The book that Lane Moore could never ﬁnd on a shelf is the very one she decided to write. How to Be Alone, her collection of personal essays, explores through candor and humor the fallout from her childhood trauma and how she's learning to make peace with the chaos. Moore is also a comedian and the host/creator of Tinder Live and fronts the band It Was Romance.",
    iFrame: '//html5-player.libsyn.com/embed/episode/id/13369298/height/360/theme/standard/thumbnail/yes/direction/backward/menu/no/',
    thumbnailURL: urlGenerator('/G/5E54F286/EAN-9781501178832'),
    productURL: '/product/9781501178832?title=how_to_be_alone_if_you_want_to_and_even_if_you_dont',
    bookTitle: "How to Be Alone: If You Want To, and Even If You Don't",
    comingSoon: false,
    date: '03/03/20',
    authorImage: '',
    authorBio: '',
  },
];

export default strandCastEpList;
