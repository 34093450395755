import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/styles';
import { Waypoint } from 'react-waypoint';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Info from '@material-ui/icons/InfoRounded';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import rentRarePartnersList from './rentRarePartnersList';
import urlGenerator from '../../helpers/urlGenerator';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_rentRare.scss';
import use4Cite from '../../hooks/use4Cite';

const settings = {
  slidesToScroll: 1,
  slidesToShow: 1,
  lazyLoad: true,
  infinite: true,
  fade: true,
  autoplaySpeed: 3000,
  autoplay: true,
  className: 'rentrare-slider__div',
  arrows: false,
  swipe: false,
  draggable: false,
  pauseOnHover: false,
  touchMove: false,
};

const useStyles = makeStyles({
  rootIconBtn: {
    color: '#af272f',
    '&:hover': {
      // backgroundColor: 'rgba(191, 46, 26, .4)',
      backgroundColor: 'transparent',
      color: '#d4301a',
    },
  },
  rootButtonBaseSubMenu: {
    fontFamily: 'inherit',
    fontSize: '1em',
    padding: '5px',
    top: '-1px',
    left: '-5px',
    '&:hover': {
      color: '#d4301a',
    },
  },
  rootButtonBase: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      textDecoration: 'underline',
    },
  },
  rootDialog: {
    minHeight: '50vh',
  },
  rootDialogContent: {
    padding: '8px 0',
  },
});

const RentRare = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const classes = useStyles();
  const [enterState, setEnter] = useState({
    img1: false,
    img2: false,
    img3: false,
    img4: false,
    rentalDetails: false,
    'The Party Box': false,
    'Great Performances': false,
    'Gracious Thyme': false,
    'Gooey & Co': false,
    'OM Booth': false,
    'Rev. & DJ CherishTheLuv': false,
    'NYC Flower Project': false,
    'The Giftbox': false,
    'Rachel Pearlman Photography': false,
    'New York Edge Music': false,
    partners: false,
    subMenu: false,
    gallery: false,
    stories: false,
    couples: false,
  });
  const [openDialog, setDialog] = useState({
    contact: false,
    'The Party Box': false,
    'Great Performances': false,
    'Gracious Thyme': false,
    'Gooey & Co': false,
    'OM Booth': false,
    'Rev. & DJ CherishTheLuv': false,
    'NYC Flower Project': false,
    'The Giftbox': false,
    'Rachel Pearlman Photography': false,
    'New York Edge Music': false,
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const handleDialog = (partner) => {
    setDialog({ ...openDialog, [partner]: !openDialog[partner] });
  };

  const handleEnter = (imgNum) => {
    setEnter({ ...enterState, [imgNum]: true });
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <section className="rentrare-hero__section">
        <Waypoint onEnter={() => setEnter({ ...enterState, subMenu: false })} onLeave={() => handleEnter('subMenu')}>
          <div className="rentrare-hero__div">
            <Slider {...settings}>
              <div className="rentrare-slider-hero__div">
                <img alt="Rent the Rare Book Room" src={urlGenerator('/S/pages/rbr-main-2.jpg')} className="rentrare-slider-hero-image__img" />
              </div>
              <div className="rentrare-slider-image__div">
                <img alt="Rent the Rare Book Room" src={urlGenerator('/S/pages/rbr-main-3.jpg')} className="rentrare-slider-hero-image__img" />
              </div>
              <div className="rentrare-slider-hero__div">
                <img alt="Rent the Rare Book Room" src={urlGenerator('/S/pages/rbr-main-1.jpg')} className="rentrare-slider-hero-image__img" />
              </div>
            </Slider>
          </div>
        </Waypoint>
      </section>
      <div className="rentrare-title-border__div">
        <div className="rentrare-title__div">
          <h1 className="rentrare-title__h1">Celebrate at Strand</h1>
          <h2 className="rentrare-title__h2">Rent the Rare Book Room</h2>
        </div>
      </div>
      <div className="rentrare-container__div">
        <main className="rentrare-content__main">
          <div className="rentrare-content__div">
            <div className={enterState.subMenu ? 'rentrare-submenu__div' : 'rentrare-submenu__div--nodisplay'}>
              <a className="rentrare-submenu-link__a" href="#about">About</a>
              <a className="rentrare-submenu-link__a" href="#details">Details</a>
              <a className="rentrare-submenu-link__a" href="#partners">Partners</a>
              <a className="rentrare-submenu-link__a" href="#gallery">Gallery</a>
              <ButtonBase onClick={() => handleDialog('contact')} classes={{ root: classes.rootButtonBaseSubMenu }}>Contact</ButtonBase>
            </div>
            <section className="rentrare-intro__section">
              <div className="rentrare-intro__div" id="about">
                <Waypoint onEnter={() => handleEnter('img1')}>
                  <Fade in={enterState.img1} timeout={{ enter: 2000 }}>
                    <img
                      className="rentrare-rbr-image__img rentrare-rbr-image-1__img"
                      alt="Rare Book Room"
                      src={urlGenerator('/S/pages/rbr-body-1.jpg')}
                    />
                  </Fade>
                </Waypoint>
                <Waypoint onEnter={() => handleEnter('img2')}>
                  <Fade in={enterState.img2} timeout={{ enter: 2000 }}>
                    <img
                      className="rentrare-rbr-image__img rentrare-rbr-image-2__img"
                      alt="Rare Book Room"
                      src={urlGenerator('/S/pages/rbr-body-3.jpg')}
                    />
                  </Fade>
                </Waypoint>
                <div className="rentrare-details__div rentrare-details-1__div">
                  <div className="rentrare-accent__div" />
                  <p className="rentrare-details__p">
                    The Strand&#39;s Rare Book Room is a perfect venue
                    for weddings, celebrations, and private events.
                    Spend your special evening surrounded by rare
                    and antique books curated by Strand&#39;s resident experts.
                  </p>
                  <div className="rentrare-accent__div" />
                </div>
                <div className="rentrare-border__div rentrare-border-1__div" />
                <Waypoint onEnter={() => handleEnter('img3')}>
                  <Fade in={enterState.img3} timeout={{ enter: 2000 }}>
                    <img
                      className="rentrare-rbr-image__img rentrare-rbr-image-3__img"
                      alt="Rare Book Room"
                      src={urlGenerator('/S/pages/rbr-body-2.jpg')}
                    />
                  </Fade>
                </Waypoint>
                <Waypoint onEnter={() => handleEnter('img4')}>
                  <Fade in={enterState.img4} timeout={{ enter: 2000 }}>
                    <img
                      className="rentrare-rbr-image__img rentrare-rbr-image-4__img"
                      alt="Rare Book Room"
                      src={urlGenerator('/S/pages/rbr-body-4.jpg')}
                    />
                  </Fade>
                </Waypoint>
                <div className="rentrare-details__div rentrare-details-2__div">
                  <div className="rentrare-accent__div" />
                  <p className="rentrare-details__p ">
                    We partner with some of the best caterers, florists,
                    and decorators in the city to bring your event to life.
                  </p>
                  <div className="rentrare-accent__div" />
                </div>
                <div className="rentrare-details__div rentrare-details-3__div">
                  <h3 className="rentrare-header__h3">Visit Us</h3>
                  <p className="rentrare-details__p ">
                    The Rare Book Room at our flagship location is open Saturday and Sunday from 10 am - 6 pm. Clients are free to view the space at their leisure during these times.
                    <br />
                    All walkthroughs that include additional details of private rentals are by
                    <strong> appointment only. </strong>
                    At this time, walkthroughs are only available Monday - Friday from 11 am until 4 pm, unless previously arranged by a Rentals Coordinator.
                    <br />
                    <br />
                    <ButtonBase classes={{ root: classes.rootButtonBase }} className="rentrare-intro-btn__buttonbase" onClick={() => handleDialog('contact')}>Contact Us</ButtonBase>
                    .
                  </p>
                </div>
              </div>
            </section>
            <section className="rentrare-pricing__section">
              <div className="rentrare-pricing__div" id="details">
                <div className="rentrare-pricing-banner__div">
                  <div className="rentrare-pricing-border__div">
                    <div className="rentrare-pricing-header__div">
                      <h3 className="rentrare-header__h3">Rental Details</h3>
                    </div>
                  </div>
                </div>
                <Waypoint onEnter={() => handleEnter('rentalDetails')}>
                  <Fade in={enterState.rentalDetails} timeout={{ enter: 2000 }}>
                    <div className="rentrare-pricing-details__div">
                      <h4 className="rentrare-header__h4">Guest Capacities</h4>
                      <div className="rentrare-header-accent__div" />
                      <div className="rentrare-details__div">
                        <p className="rentrare-details__p">
                          Seated Dinner: 100
                        </p>
                        <p className="rentrare-details__p">
                          Cocktail Party: 150 - 175
                        </p>
                        <br />
                      </div>
                      <h4 className="rentrare-header__h4">Rental Rates</h4>
                      <div className="rentrare-header-accent__div" />
                      <div className="rentrare-details__div">
                        <strong>Friday - Sunday Rates</strong>
                        <p className="rentalrate-details__p">
                          <strong>10:00AM - 7:00PM</strong>
                          &nbsp;- $3,000 per hour*
                        </p>
                        <p className="rentalrate-details__p">
                          <strong>7:00PM - 11:00PM</strong>
                          &nbsp;- $1,000 per hour*
                        </p>
                        <p className="rentalrate-details__p">
                          <strong>11:00PM – 1:00AM</strong>
                          &nbsp;- $2,000 per hour*
                        </p>
                        <br />
                        <strong>Monday - Thursday Rates</strong>
                        <p className="rentalrate-details__p">
                          <strong>10:00AM - 11:00PM</strong>
                          &nbsp;- $1,000 per hour*
                        </p>
                        <p className="rentalrate-details__p">
                          <strong>11:00PM – 1:00AM</strong>
                          &nbsp;- $2,000 per hour*
                        </p>
                        <br />
                        <p className="rentrare-details__p">
                          Private Guest and Freight Elevator Access Provided
                        </p>
                        <br />
                        <p className="rentrare-details__p">
                          *Prices are subject to change between December 1st - January 6th due to the holiday season.
                        </p>
                      </div>

                      <h4 className="rentrare-header__h4">Buy-Out and Filming</h4>
                      <div className="rentrare-header-accent__div" />
                      <div className="rentrare-details__div">
                        <p className="rentrare-details__p">
                          If you are looking to film or book a photshoot anywhere in
                          The Strand, please&nbsp;
                          <a href="https://www.strandbooks.com/buyout-price" className="rentrare-buy-out_a">click here</a>
                          &nbsp;to learn more.
                        </p>
                        <p className="rentrare-details__p">Valid as of February 2024</p>
                      </div>
                      <div className="rentrare-border__div" />
                      <div className="rentrare-included__div">
                        <div className="rentrare-header-details__div rentrare-header-details-included__div">
                          <h4 className="rentrare-header__h4 rentrare-header-included__h4">Included in Rental</h4>
                          <div className="rentrare-header-accent__div rentrare-header-accent-included__div" />
                          <div className="rentrare-details__div rentrare-details-included__div">
                            <p className="rentrare-details__p">
                              Assistance of Event Director or Event Assistant
                            </p>
                            <p className="rentrare-details__p">
                              Use of all On-Site Rare Book Room Surfaces
                              and Tables (must be covered)
                            </p>
                            <p className="rentrare-details__p">
                              Two (2) Leather Armchairs
                            </p>
                            <p className="rentrare-details__p">
                              Access to Half-Kitchen
                            </p>
                            <p className="rentrare-details__p">
                              Green Room/Wedding Dressing Suite
                            </p>
                          </div>
                        </div>
                        {/* <div className="rentrare-pricing-banner-2__div" /> */}
                        <img
                          src={urlGenerator('/S/pages/rentrare-pricing-banner-2.jpg')}
                          alt="Rent Rare Book Room"
                          className="rentrare-rbr-image__img rentrare-pricing-image__img"
                        />
                      </div>
                      <div className="rentrare-border__div" />
                      <h4 className="rentrare-header__h4">Additional Fees</h4>
                      <div className="rentrare-header-accent__div" />
                      <div className="rentrare-details__div rentrare-details-fees__div">
                        <div className="rentrare-categories__div">
                          <p className="rentrare-details__p">
                            Ten (10) 6’ x 2.5’ Wooden Tables -
                            &nbsp;
                            <strong>$100</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            Ten (10) 30” Round Adjustable Cocktail Tables (42” high top and 30” low top height) -
                            &nbsp;
                            <strong>$100</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            One (1) 65-inch TV screen -
                            &nbsp;
                            <strong>$200</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            Four (4) Cordless Microphones & two (2) Speakers -
                            &nbsp;
                            <strong>$250</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            120 Padded Folding Chairs -
                            &nbsp;
                            <strong>$250</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            Security Personnel -
                            &nbsp;
                            <strong>$200**</strong>
                          </p>
                          <br />
                          <p className="rentrare-details__p">
                            Client Storage -
                            &nbsp;
                            <strong>$250**</strong>
                          </p>
                          <br />
                        </div>
                        <div className="rentrare-note__div">
                          <p className="rentrare-details__p">
                            **Cost and stipulations outlined in rental contract.
                            Security personnel only required for events with an end
                            time past 1:00am.
                          </p>
                        </div>
                      </div>
                      <h4 className="rentrare-header__h4">Confirming Your Event</h4>
                      <div className="rentrare-header-accent__div" />
                      <div className="rentrare-details__div">
                        <p className="rentrare-details__p">
                          A signed contract, submission of credit card authorization form
                          and $1,000.00 deposit (not including any extra fees) are required
                          to reserve a date for your event. Final balance including additional fees
                          will be due 72 hours before the event.
                          All costs include applicable sales tax.
                        </p>
                        <br />
                        <p className="rentrare-details__p">
                          Valid as of January 2024
                        </p>
                      </div>
                    </div>
                  </Fade>
                </Waypoint>
              </div>
            </section>
            <section className="rentrare-partners__section">
              <div className="rentrare-partners__div" id="partners">
                <div className="rentrare-partners-banner__div">
                  <div className="rentrare-partners-border__div">
                    <div className="rentrare-partners-header__div">
                      <h3 className="rentrare-header__h3">Partners</h3>
                    </div>
                  </div>
                </div>
                <Waypoint onEnter={() => handleEnter('partners')}>
                  <Fade in={enterState.partners} timeout={{ enter: 2000 }}>
                    <div className="rentrare-partners-grid__div">
                      {
                        rentRarePartnersList.partners.map((partner) => {
                          const {
                            id,
                            name,
                            description,
                            link,
                            contactName,
                            contactLink,
                            contactEmail,
                            contactEmail2,
                            partnerImg,
                          } = partner;

                          return (
                            <div className="rentrare-partner__div" key={id}>
                              <Card>
                                <a href={link} rel="noopener noreferrer" target="_blank">
                                  <CardMedia
                                    component="img"
                                    alt={name}
                                    height={250}
                                    image={partnerImg}
                                    title={name}
                                  />
                                </a>
                                <div className="rentrare-partner-details__div">
                                  <h5 className="rentrare-header__h5">{name}</h5>
                                  <IconButton aria-label="more partner info" onClick={() => handleDialog(name)} className="rentrare-more__iconbutton" classes={{ root: classes.rootIconBtn }}>
                                    <Info />
                                  </IconButton>
                                </div>
                              </Card>
                              <Dialog open={openDialog[name]} onClose={() => handleDialog(name)}>
                                <div className="rentrare-dialog__div">
                                  <div className="rentrare-dialog-image__div">
                                    <img src={partnerImg} alt={name} className="rentrare-dialog-image__img" />
                                  </div>
                                  <div className="rentrare-dialog-details__div">
                                    <h4 className="rentrare-header__h4">{name}</h4>
                                    <p className="rentrare-dialog-details__p">
                                      {description}
                                    </p>
                                    <br />
                                    {
                                      contactName.length > 0
                                        && (
                                          <p className="rentrare-dialog-details__p">
                                            Contact&nbsp;
                                            <strong className="rentrare-dialog-details__strong--red">{contactLink ? <a href={contactLink}>{contactName}</a> : contactName}</strong>
                                            &nbsp;
                                            directly at&nbsp;
                                            <a href={`mailto: ${link}`} className="rentrare-dialog-link__a">{contactEmail}</a>
                                            {contactEmail2 ? (
                                              <>
                                                <span>&nbsp;or&nbsp;</span>
                                                <a href={`mailto:${contactEmail2}`} className="rentrare-dialog-link__a">{contactEmail2}</a>
                                              </>
                                           ) : null}
                                            .
                                          </p>
                                        )
                                    }
                                  </div>
                                </div>
                              </Dialog>
                            </div>
                          );
                        })
                      }
                    </div>
                  </Fade>
                </Waypoint>
              </div>
            </section>
            <section className="rentrare-gallery__section">
              <div className="rentrare-gallery__div" id="gallery">
                <div className="rentrare-gallery-banner__div">
                  <div className="rentrare-gallery-border__div">
                    <div className="rentrare-gallery-header__div">
                      <h3 className="rentrare-header__h3">Gallery</h3>
                    </div>
                  </div>
                </div>
                <Waypoint onEnter={() => handleEnter('gallery')}>
                  <Fade in={enterState.gallery} timeout={{ enter: 2000 }}>
                    <div className="rentrare-gallery-grid__div">
                      {
                        rentRarePartnersList.gallery.map((gallery) => {
                          const {
                            id,
                            galleryImg,
                          } = gallery;

                          return (
                            <div style={{ backgroundImage: `url(${galleryImg})` }} className="rentrare-gallery-img__div" key={id} />
                          );
                        })
                      }
                    </div>
                  </Fade>
                </Waypoint>
                <h4 className="rentrare-header__h4">Stories</h4>
                <div className="rentrare-header-accent__div" />
                <div className="rentrare-gallery-stories__div">
                  <iframe className="rentrare-gallery-iframe__iframe" title="New York Times Video - Embed Player" width="480" height="321" frameBorder="0" scrolling="no" allowFullScreen marginHeight="0" marginWidth="0" id="nyt_video_player" src="https://www.nytimes.com/video/players/offsite/index.html?videoId=100000005586582" />
                </div>
                <Waypoint onEnter={() => handleEnter('couples')}>
                  <Fade in={enterState.couples} timeout={{ enter: 2000 }}>
                    <div className="rentrare-gallery-couples__div">
                      {
                        rentRarePartnersList.photos.map((photo) => {
                          const {
                            id,
                            photoImg,
                            name,
                            link,
                          } = photo;

                          return (
                            <a key={id} className="rentrare-gallery-link__a" href={link} target="_blank" rel="noopener noreferrer">
                              <div style={{ backgroundImage: `url(${photoImg})` }} className="rentrare-gallery-couples-img__div">
                                <div className="rentrare-gallery-couples-header__div">
                                  <h4 className="rentrare-header__h4 rentrare-header-couple__h4">{name}</h4>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      }
                    </div>
                  </Fade>
                </Waypoint>
              </div>
            </section>
            <section className="rentrare-contact__section">
              <Dialog className="rentrare-contact-dialog__dialog" open={openDialog.contact} onClose={() => handleDialog('contact')}>
                <DialogContent classes={{ root: classes.rootDialogContent }} className="rentrare-contact-content__dialogcontent">
                  <iframe title="Contact Form" className="rentrare-contact-iframe__iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSfXpTDWruTnu9jtn7oFaseFQ5Z5MyAwgwN8F7jp7hhAiM-UyA/viewform?embedded=true" frameBorder="0" marginHeight="0" marginWidth="0">Loading...</iframe>
                </DialogContent>
              </Dialog>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

RentRare.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default RentRare;
