import React from 'react';
import PropTypes from 'prop-types';
// import { createMuiTheme } from '@material-ui/core/styles';
// import { makeStyles } from '@material-ui/styles';
// import TextField from '@material-ui/core/TextField';
import './_checkoutCartTotal.scss';

const CheckoutCartTotal = (props) => {
  const {
    payments,
    overweightState,
    tally,
    currentStep,
  } = props;
  // const useStyles = makeStyles({
  //   inputOutlinedInput: {
  //     height: '.2em',
  //   },
  //   rootInputLabel: {
  //     top: '-6px',
  //   }
  // });

  // const classes = useStyles();

  // const theme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: '#af272f',
  //     },
  //   },
  //   overrides: {
  //     MuiTextField: {
  //       root: {
  //         width: '175px',
  //         marginTop: '5px',
  //       },
  //     },
  //   },
  // });

  // const [promoState, setPromo] = useState('');

  // const handlePromo = (e) => {
  //   setPromo(e.target.value);
  // };

  // console.log('currentStep CheckoutCartTotal', currentStep);

  return (
    <div className="checkoutcarttotal-container__div">
      <div className="checkoutcarttotal-content__div">
        <div className="checkoutcarttotal-detail__div">
          <p className="checkoutcarttotal-header__p">Subtotal:</p>
          {/* <p className="checkoutcarttotal-value__p">{subTotal !== '-' && `$${subTotal.toFixed(2)}`}</p> */}
          <p className="checkoutcarttotal-value__p">{tally?.productStv ? `$${(tally.productStv * 0.01).toFixed(2)}` : '-'}</p>
        </div>
        <div className="checkoutcarttotal-detail__div">
          <p className="checkoutcarttotal-header__p">Shipping:</p>
          <p className="checkoutcarttotal-value__p">
            {currentStep !== 0 && currentStep !== 1 && tally?.shippingStv ? `$${(tally.shippingStv * 0.01).toFixed(2)}` : '-'}
            {
              overweightState && tally.shippingStv && <span className="checkoutcarttotal-notice__span">*</span>
            }
          </p>
        </div>
        <div className="checkoutcarttotal-detail__div">
          <p className="checkoutcarttotal-header__p">
            {/* eslint-disable-next-line no-nested-ternary */}
            {currentStep !== 0 && tally?.vatStv && tally?.vatStv > 0
              ? `VAT (${parseFloat((tally.vatRate * 100).toFixed(3))}%):`
              : tally.taxStv > 0
                ? `Sales Tax (${parseFloat((tally.taxRate * 100).toFixed(3))}%):`
                : '-'
            }
          </p>
          <p className="checkoutcarttotal-value__p">
            {/* eslint-disable-next-line no-nested-ternary */}
            {currentStep !== 0 && tally?.vatStv && tally?.vatStv > 0
              ? `$${(tally.vatStv * 0.01).toFixed(2)}`
              : currentStep !== 0 && tally?.taxStv && tally.taxStv > 0
                ? `$${(tally.taxStv * 0.01).toFixed(2)}`
                : '-'}
          </p>
        </div>
        {
          payments
            && payments.length > 1
            && payments.filter(paymentMethod => paymentMethod.paymentType === 'GIFT_CARD').length > 0
            && (
              <div className="checkoutcarttotal-detail__div">
                <p className="checkoutcarttotal-header__p">Gift Card:</p>
                <p className="checkoutcarttotal-value__p checkoutcarttotal-value__p--red">{`($${((payments.filter(paymentMethod => paymentMethod.paymentType === 'GIFT_CARD')[0].amountStv) * 0.01).toFixed(2)})`}</p>
              </div>
            )
        }
        <div className="checkoutcarttotal-detail__div">
          <div />
          <div className="checkoutcarttotal-divider__div" />
        </div>
        <div className="checkoutcarttotal-detail__div">
          <p className="checkoutcarttotal-header__p checkoutcarttotal-header__p--strong">Total:</p>
          <p className="checkoutcarttotal-value__p checkoutcarttotal-value__p--strong">
            {`$${(
              (tally?.totalStv ? (tally.totalStv * 0.01) : 0)
              - ((payments
                && payments.filter(paymentMethod => paymentMethod.paymentType === 'GIFT_CARD').length
                && ((payments.filter(paymentMethod => paymentMethod.paymentType === 'GIFT_CARD')[0].amountStv) * 0.01)) || 0).toFixed(2)).toFixed(2)
            }`}
          </p>
        </div>
        {
          overweightState
            && tally?.shippingStv
            && (
              <div className="checkoutcarttotal-notice__div">
                <p className="checkoutcarttotal-notice__p">
                  * Note: Additional shipping costs may apply
                  due to the weight of one or more items in your cart.
                  We will reach out to give you the final shipping cost for this order.
                </p>
              </div>
            )
        }
        {/* <div className="checkoutcarttotal-promo__div">
          <div className="checkoutcarttotal-promo-form__div">
            <form className="checkoutcarttotal-promo-form__form">
              <ThemeProvider theme={theme}>
                <TextField
                  id="promo-code"
                  value={promoState}
                  onChange={handlePromo}
                  variant="outlined"
                  label="Promo Code"
                  InputProps={{
                    classes: { input: classes.inputOutlinedInput },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.rootInputLabel },
                  }}
                />
              </ThemeProvider>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  );
};

CheckoutCartTotal.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/require-default-props
  overweightState: PropTypes.bool,
  tally: PropTypes.objectOf(PropTypes.any).isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default CheckoutCartTotal;
