import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import use4Cite from '../../hooks/use4Cite';
import './_giveaway.scss';

const Giveaway = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="giveaway-container__div">
        <PageHeader header="The Strand Indie Bookstore Day 2022 Sweepstakes" />
        <section className="giveaway-conditions__section">
          <div className="giveaway-content__div">
            <p className="giveaway-text__p">
              <strong>NO PURCHASE OR PAYMENT IS NECESSARY. A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING. This Sweepstakes is in no way sponsored, endorsed or administered by Instagram. By providing information in the Sweepstakes, you understand that you are providing information to Sponsor and not to Instagram.</strong>
              <br />
              <br />
              THIS SWEEPSTAKES IS INTENDED FOR PLAY IN THE 50 UNITED STATES &amp; D.C., ONLY AND WILL BE GOVERNED BY U.S. LAW. DO NOT ENTER IF YOU ARE NOT ELIGIBLE AND LOCATED IN THE 50 UNITED STATES or D.C., and 18+ AT THE TIME OF ENTRY.
              <br />
              <br />
            </p>
            <div className="giveaway-details__div">
              <h2 className="giveaway-subheader__h2">Sweepstakes Period:</h2>
              <p className="giveaway-text__p">
                The Sweepstakes begins at 5:00 PM Eastern Time (ET) on 4/20/2022 and ends at 12:00 PM Eastern Time (&ldquo;ET&rdquo;) on 4/29/2022 (the Sweepstakes Period).
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Eligibility:</h2>
              <p className="giveaway-text__p">
                <strong>The Strand Indie Bookstore Day 2022</strong>
                Sweepstakes (`Sweepstakes`) is open to legal residents of the fifty (50) United States and the District of Columbia, 18 years of age or older at the time of entry and who have a valid and active Instagram account. Employees of Strand Book Store, Inc. (`Sponsor`), Blick, Sips By, Brooklyn Roasting Co., Serino Coyne or any of their respective affiliates, subsidiaries, advertising agencies, or any other company or individual involved with the design, production execution or distribution of the Sweepstakes (collectively with Sponsor, the `Sweepstakes Entities`), and any of their respective parent and affiliate companies or subsidiaries, as well as the immediate family (spouse, parents and step-parents, siblings and step-siblings, and children and step-children) and household members of each such employee are not eligible to enter or win. The Sweepstakes is subject to all applicable federal, state, and local laws and regulations. Participation constitutes entrant`s full and unconditional agreement to these Official Rules and Sponsor`s decisions, which are final and binding in all matters related to the Sweepstakes.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">How to Enter:</h2>
              <p className="giveaway-text__p">
                To  enter the Sweepstakes, during the designated Sweepstakes Period, like the photo, follow @strandbookstore and @brooklynroasting, @sipsby, @BlickArtMaterials, @littleprincebwy on Instagram, tag 2 (two) friends in the comments on the sweepstakes post. Following the foregoing instructions will provide you with one (1) entry into the Sweepstakes. Limit one (1) entry per person/Instagram account. Entrants must adjust their Instagram profile settings to &lsquo;Public&rsquo; and respond to any message from Sponsor in the time specified below in Drawing/Winner Notification.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Prizes:</h2>
              <p className="giveaway-text__p">
                1 (ONE) winner will each receive; ONE (1) Strand Book Store tote ($25), ONE (1) $100 Strand Gift Card, 2 tickets to a Strand Event of their choice (pending availability ) ($60), 1 (ONE) Book HookUp box of the winner&rsquo;s choice ($50), a private tour for up to 10 people of The Strand ($250),1 (ONE) Blick $25 Gift Card, 3 (THREE) Month Sips By Subscription ($48), 1 (ONE $100 Brooklyn Roasting Co. Gift Card, 4 (FOUR) passes for brunch at a Brooklyn Cyclones Game (limited dates) ($196), 2 (TWO) tickets to New York Comic Con ($420), 4 (FOUR) tickets to see The Little Prince on Broadway ($636). No cash substitutions for prizes will be permitted and prizes are non-refundable and non-transferable, except at sole discretion of sponsor. The Strand reserves the right to substitute prize of equal or greater value. Federal, state and local taxes are solely the responsibility of the winner. Total ARV for all prizes $1,935.00. Limit one (1) prize per person/account. Winners are responsible for all federal, state and local taxes and all other costs and expenses associated with prize not specified herein as being awarded. Winner will have to provide their street address for fulfillment of prize (no P.O. boxes); Winner acknowledges that once product is shipped, Sponsor has no control over delivery or delivery time, and any loss or theft of product once delivered will be the responsibility of Winner, and no replacement prize will be supplied.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Drawing/Winner Notification:</h2>
              <p className="giveaway-text__p">
                &nbsp;On or about 4/29/2022, Sponsors will randomly select potential winners from among all eligible entries received. Odds of winning depend on the total number of eligible entries received. Entrants must adjust their Instagram settings to &lsquo;Public and respond within timeframe provided below. By entering this Sweepstakes, each entrant agrees that the Sponsor has the right to contact the entrant to administer and fulfill this Sweepstakes. Potential winners will be contacted via Instagram Direct Message (Message&rdquo;). Failure of a potential winner to respond to Message within twenty-four (72) hours of notification will result in disqualification and an alternate potential winner may be selected. Except where prohibited by law, each winner grants (and agrees to confirm this grant in writing, if requested) permission for Sponsor and those acting under its authority to use his/her name, Instagram handle, voice and/or likeness, for advertising and/or publicity purposes in any and all media now known or hereinafter invented without territorial or time limitations and without compensation.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Entry Conditions and Release:</h2>
              <p className="giveaway-text__p">
                By entering, each Entrant agrees to: (a) comply with and be bound by these Official Rules and the decisions of the Sponsor which are binding and final in all matters relating to this Sweepstakes; (b) to release Sponsor, its affiliates, subsidiaries, retailers, and agents, and each of their officers, directors, employees and agents (&ldquo;Sweepstakes Entities&rdquo;), from any and all liability, loss or damage incurred with respect to the awarding, shipping, receipt, possession, and/or consumption, use, or misuse of any prize; (c) under no circumstances will entrant be permitted to obtain awards for, and participant hereby waives all rights to claim, punitive, incidental, consequential, or any other damages, other than for actual out-of-pocket expenses as outlined in (d); (c) all causes of action arising out of or connected with this Sweepstakes, or any prize awarded, shall be resolved individually, without resort to any form of class action; and (d) any and all claims, judgments, and awards shall be limited to actual out-of-pocket costs incurred, excluding attorneys&rsquo; fees and court costs. Sponsor reserves the right, in its sole discretion, to modify, cancel, delay, or suspend the Sweepstakes or any of these Official Rules, or to disqualify any individual implicated in any of the following actions, if for any reason: (i) infection by computer virus, bugs, tampering, unauthorized intervention, actions by entrants, fraud, technical failures, or any other causes which, in Sponsor&rsquo;s sole opinion, corrupt or affect the administration, security, fairness, integrity or proper conduct of the Sweepstakes, (ii) the Sweepstakes or any website or technology associated therewith (or any portion thereof) becomes corrupted or does not allow the proper processing of entries, or (iii) the Sweepstakes is otherwise not capable of running as planned by Sponsor in its sole discretion.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Governing Law:</h2>
              <p className="giveaway-text__p">
                These Sweepstakes Official Rules are governed by US law and are subject to all applicable federal, state and local laws and regulations. All issues and questions concerning the construction, validity, interpretation and enforceability of the Official Rules, or the rights and obligations of entrant and Sponsor in connection with the Sweepstakes, shall be governed by, and construed in accordance with, the laws of the State/Commonwealth of Virginia, without giving effect to the conflict of laws rules thereof, and any matters or proceedings and/or for entering any judgment, shall take place in the State of New York, in the City of New York.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Privacy:</h2>
              <p className="giveaway-text__p">
                Information  provided by you for this Sweepstakes is subject to Sponsor&rsquo;s privacy notice:
                <a href="https://www.strandbooks.com/privacy">https://www.strandbooks.com/privacy</a>
                as applicable.
                <br />
                <br />
              </p>
              <h2 className="giveaway-subheader__h2">Winners List Request:</h2>
              <p className="giveaway-text__p">
                &nbsp;For the name of the Sweepstakes Winners (available after 4/29/2022) send a request via a direct message to
                <a href="https://www.instagram.com/strandbookstore/">https://www.instagram.com/strandbookstore/</a>
                &nbsp;
              </p>
            </div>
          </div>
        </section>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Giveaway.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Giveaway;
