import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ErrorIcon from '@material-ui/icons/Error';
import TextField from '@material-ui/core/TextField';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import WithEvent from '../withEvent/WithEvent';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';

import './_recoverAccount.scss';

// sample url for recover account: https://stage.strandbooks.com/password-reset/#{key}

export const CUSTOMER_PASSWORD_RESET = gql`
mutation pwReset($email: String!, $password: String!, $resetId: ID!){
  customerPasswordReset(email: $email, password: $password, resetId: $resetId){
    customerId
    firstName
    lastName
    verified
  }
}
`;

const useStyles = makeStyles({
  paperDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootIconButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootTextField: {
    margin: '10px 0',
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#af272f',
    },
  },
});

const RecoverAccount = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const {
    compSwitchState: showPassword,
    handleCompToggle: handleShowPassword,
  } = useOpenCloseComponent();

  const [dirtyState, setDirty] = useState(false);

  const [dirtyConfirmState, setConfirmDirty] = useState(false);

  const {
    compSwitchState: confirmPw,
    handleCompToggle: handleConfirmPw,
  } = useOpenCloseComponent();

  const { location } = props;
  const classes = useStyles();

  const [inputState, setInput] = useState({
    emailInput: '',
    passwordInput: '',
    confirmPasswordInput: '',
    notSame: false,
  });

  const [
    customerPasswordReset,
    {
      error: errorReset,
      loading: loadingReset,
      data: dataReset,
    },
  ] = useMutation(CUSTOMER_PASSWORD_RESET);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [customerPasswordReset]);

  if (location && !location.pathname.slice(16).length) return <Redirect to={{ pathname: '/error', state: { noKey: true } }} />;

  if (loadingReset) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="recoveraccount-container__div">
          <main className="recoveraccount-content__main">
            <div className="recoveraccount-content__div">
              <Loading />
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (dataReset && dataReset.customerPasswordReset) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="recoveraccount-container__div">
          <main className="recoveraccount-content__main">
            <div className="recoveraccount-content__div recoveraccount-success__div">
              <div className="recoveraccount-details__div">
                <p className="recoveraccount-details__p">
                  Your password has been reset. You can now log in.
                </p>
              </div>
              <div className="recoveraccount-btn__div">
                <Link
                  to="/"
                  className="recoveraccount-btn__link"
                  onClick={() => WithEvent('RECOVER_ACCOUNT', 'Clicked back to home', 'RECOVER_RETURN_BTN', 1)}
                >
                  Go back to Home Page
                </Link>
              </div>
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  const handleInput = field => e => setInput({
    ...inputState,
    [field]: e.target.value,
  });

  const handlePWwithEvent = () => {
    if (!dirtyState) {
      WithEvent('RECOVER_ACCOUNT', 'Clicked show password icon', 'SHOW_PASSWORD_ICON', 1);
      setDirty(true);
    }
    handleShowPassword();
  };

  const handleConfirmPWWithEvent = () => {
    if (!dirtyConfirmState) {
      WithEvent('RECOVER_ACCOUNT', 'Clicked show confirm password icon', 'SHOW_CONFIRM_PASSWORD_ICON', 1);
      setConfirmDirty(true);
    }
    handleConfirmPw();
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="recoveraccount-container__div">
        <main className="recoveraccount-content__main">

          <div className="recoveraccount-content__div">
            <PageHeader header="Reset Password" />
            <div className="recoveraccount-details__div">
              <p className="recoveraccount-details__p">
                Please fill out the fields below to reset your password
              </p>
            </div>
            {
              errorReset && (
                <div className="recoveraccount-error-message__div">
                  <ErrorIcon />
                  <p className="recoveraccount-error-message__p">
                    Something went wrong. Please try again.&nbsp;
                    {errorReset.message}
                  </p>
                </div>
              )
            }
            {
              inputState.notSame && (
                <div className="recoveraccount-error-message__div">
                  <ErrorIcon />
                  <p className="recoveraccount-error-message__p">
                    Confirm password does not match. Please try again.
                  </p>
                </div>
              )
            }
            <ThemeProvider theme={theme}>
              <div className="recoveraccount-form__div">
                <form
                  className="recoveraccount-form__form"
                  // noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();

                    Sentry.addBreadcrumb({
                      category: 'RECOVER_ACCOUNT',
                      message: 'Clicked Reset Password',
                      level: Sentry.Severity.Info,
                    });

                    if (inputState.passwordInput !== inputState.confirmPasswordInput) {
                      setInput({ ...inputState, notSame: true });
                    } else {
                      customerPasswordReset({
                        variables: {
                          email: inputState.emailInput,
                          password: inputState.passwordInput,
                          resetId: location.pathname.slice(16),
                        },
                      })
                        .then(() => setInput({ ...inputState, notSame: false }))
                        // display error in console for debugging purposes
                        // eslint-disable-next-line
                        .catch(err => console.error(err));
                    }
                  }}
                >
                  <TextField
                    onChange={handleInput('emailInput')}
                    label="Email Address"
                    value={inputState.emailInput}
                    variant="outlined"
                    className="recoveraccount-input__textfield"
                    type="email"
                    classes={{ root: classes.rootTextField }}
                    required
                    id="email-address"
                    InputProps={{
                      'aria-label': 'Email Address',
                      'data-testid': 'email-address',
                    }}
                  />
                  <TextField
                    onChange={handleInput('passwordInput')}
                    classes={{ root: classes.rootTextField }}
                    label="New Password"
                    value={inputState.passwordInput}
                    variant="outlined"
                    id="new-pw"
                    type={showPassword ? 'text' : 'password'}
                    data-testid="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            onClick={handlePWwithEvent}
                            aria-label="Show New Password"
                          >
                            {
                              showPassword
                                ? <Visibility />
                                : <VisibilityOff />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: 'new-password',
                      'aria-label': 'New Password',
                      'data-testid': 'new-password',
                    }}
                    required
                  />
                  <TextField
                    onChange={handleInput('confirmPasswordInput')}
                    classes={{ root: classes.rootTextField }}
                    label="Confirm Password"
                    value={inputState.confirmPasswordInput}
                    variant="outlined"
                    type={confirmPw ? 'text' : 'password'}
                    id="confirm-pw"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            onClick={handleConfirmPWWithEvent}
                            aria-label="Show Confirm Password"
                          >
                            {
                              confirmPw
                                ? <Visibility />
                                : <VisibilityOff />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: 'new-password',
                      'aria-label': 'Confirm Password',
                      'data-testid': 'confirm-password',
                    }}
                    required
                  />
                  <div className="recoveraccount-submit-btn__div">
                    <IconButton disableTouchRipple disableRipple classes={{ root: classes.rootIconButton }} type="submit" className="recoveraccount-submit-btn__iconbutton">
                      Reset Password
                    </IconButton>
                  </div>
                </form>
              </div>
            </ThemeProvider>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

RecoverAccount.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default RecoverAccount;
