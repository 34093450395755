import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import GenCarousel from '../genCarousel/GenCarousel';
import Loading from '../loading/Loading';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import convertSlashRegex from '../../helpers/convertSlashRegex';
import convertSlashURL from '../../helpers/convertSlashURL';
import capitalizeString from '../../helpers/capitalizeString';
import use4Cite from '../../hooks/use4Cite';
import './_productSectionSub.scss';

const GET_SECTION_GROUPS = gql`
query{
  sectionGroups{
    subsections
    prefix
  }
}
`;

const GET_SECTION_RESULT = gql`
query getSectionResult($section: String!){
  productBySectionResult(section: $section, param: {}, order: { dir: ASC, orderBy: TITLE}){
    title {
      key
      text
    }
    productList{
      id
      title
      gtin13
      imageUrl
      inventory{
        sku
        salePriceStv
        section
      }
    }
  }
}
`;

const ProductSectionSub = (props) => {
  const {
    match,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const scroll = useScrollY();

  const {
    data: sectionGroupsData,
    loading: sectionGroupsLoading,
    error: sectionGroupsError,
  } = useQuery(GET_SECTION_GROUPS);

  const {
    data: productSectionData,
    loading: productSectionLoading,
    error: productSectionError,
  } = useQuery(GET_SECTION_RESULT, {
    variables: {
      section: convertSlashRegex(match.params.prefix).toUpperCase(),
    },
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (sectionGroupsError || productSectionError) return <Redirect to={{ pathname: '/error', state: { sectionGroupsError, productSectionError } }} />;

  if (sectionGroupsLoading || productSectionLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productsectionlp-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (productSectionData
    && productSectionData.productBySectionResult
    && productSectionData.productBySectionResult.productList
    && productSectionData.productBySectionResult.productList.length > 0
    && sectionGroupsData
    && sectionGroupsData.sectionGroups
    && sectionGroupsData.sectionGroups.length > 0
    && sectionGroupsData.sectionGroups
      .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase()).length > 0
    && sectionGroupsData.sectionGroups
      .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections
    && sectionGroupsData.sectionGroups
      .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections.length === 0) {
    return <Redirect to={`/productsection/${convertSlashURL(match.params.prefix)}`} />;
  }

  // console.log('sectionGroupsData', sectionGroupsData, 'prodsecdata', productSectionData);

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="productsectionsub-container__div">
        <main className="productsectionsub-content__main">
          <PageHeader header={capitalizeString(convertSlashRegex(match.params.prefix))} />
          { scroll > 1000 && <CompFab />}
          <div className="productsectionsub-content__div">
            <section className="productsectionsub-prefix-carousel__section">
              <div className="productsectionsub-prefix-carousel__div">
                {
                productSectionData
                  && productSectionData.productBySectionResult
                  && productSectionData.productBySectionResult.productList
                  && productSectionData.productBySectionResult.productList.length > 0
                  && (
                    <GenCarousel
                      products={productSectionData.productBySectionResult.productList}
                      dataType="section"
                      locationName={match.params.prefix}
                      noHeader
                    />
                  )
              }
              </div>
            </section>
            <section className="productsectionsub-subsections__section">
              {
                sectionGroupsData
                && sectionGroupsData.sectionGroups
                && sectionGroupsData.sectionGroups.length > 0
                && sectionGroupsData.sectionGroups
                  .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase()).length > 0
                && sectionGroupsData.sectionGroups
                  .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections
                && sectionGroupsData.sectionGroups
                  .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections.length > 0
                && productSectionData
                && productSectionData.productBySectionResult
                && productSectionData.productBySectionResult.productList
                && productSectionData.productBySectionResult.productList.length !== 0
                && (
                  <div className="productsectionsub-subsections-header__div">
                    <PageSubHeader header={`Subjects Related to ${capitalizeString(convertSlashRegex(match.params.prefix))}`} />
                  </div>
                )
              }
              <div className="productsectionsub-subsections__div">
                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase()).length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections
                  && sectionGroupsData.sectionGroups
                    .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => sec.prefix.toLowerCase() === convertSlashRegex(match.params.prefix).toLowerCase())[0].subsections
                    .sort((a, b) => {
                      if (a > b) return 1;
                      if (b > a) return -1;
                      return 0;
                    })
                    .map(subSec => (
                      <div className="productsectionsub-subsection__div" key={subSec}>
                        <Link
                          className="productsectionsub-subsection-link__link"
                          to={`/productsection/${convertSlashURL(subSec)}`}
                          onClick={() => WithEvent('BOOKS_MEDIA', `Click ${subSec} Subsection`, 'BROWSE_SUBSECTION_LINK', 1)}
                        >
                          {capitalizeString(subSec)}
                        </Link>
                      </div>
                    ))
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ProductSectionSub.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ProductSectionSub;
