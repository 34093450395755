import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import './_booksThatShaped2021.scss';

const BooksThatShaped2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const match767 = useMediaQueryMinWidth(767);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="booksthatshaped2021-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="booksthatshaped2021-content__main">
          <div className="booksthatshaped2021-content__div">
            <section className="booksthatshaped2021-hero__section">
              <div className="booksthatshaped2021-hero__div">
                <img
                  src={match767 ? urlGenerator('/S/banners/banners_2021_books_that_shaped_2021_desktop.jpg') : urlGenerator('/S/banners/banners_2021_books_that_shaped_2021_mobile.jpg')}
                  className="booksthatshaped2021-hero__img"
                  alt="Books That Shaped 2021"
                />
              </div>
            </section>
            <section className="booksthatshaped2021-intro__section">
              <div className="booksthatshaped2021-intro__div">
                <p className="booksthatshaped2021-intro__p">
                  Looking back on the books that shaped the literary world in 2021.
                </p>
              </div>
            </section>
            <div className="booksthatshaped2021-border__div" />
            <section className="booksthatshaped2021-categories__section">
              <div className="booksthatshaped2021-categories__div">

                <LazyLoad height={300} offset={300}>
                  <div className="booksthatshaped2021-category__div">
                    <Link
                      to="/metalist/best_fiction_2021"
                      className="booksthatshaped2021-category-link__link"
                      onClick={() => WithEvent('BOOKS_THAT_SHAPED_2021', 'Clicked Best Fiction Tile', 'BOOKS_THAT_SHAPED_2021_LINK', 1)}
                      aria-label="Best Fiction 2021"
                    >
                      <img
                        className="booksthatshaped2021-category-image__img"
                        alt="Best Fiction 2021"
                        src={urlGenerator('/S/pages/books_that_shaped_2021_best_fiction.jpg')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="booksthatshaped2021-category__div">
                    <Link
                      to="/metalist/best_nonfiction_2021"
                      className="booksthatshaped2021-category-link__link"
                      onClick={() => WithEvent('BOOKS_THAT_SHAPED_2021', 'Clicked Best NonFiction Tile', 'BOOKS_THAT_SHAPED_2021_LINK', 1)}
                      aria-label="Best Non Fiction 2021"
                    >
                      <img
                        className="booksthatshaped2021-category-image__img"
                        alt="Best Non Fiction 2021"
                        src={urlGenerator('/S/pages/books_that_shaped_2021_best_nonfiction.jpg')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="booksthatshaped2021-category__div">
                    <Link
                      to="/metalist/best_scifi_mystery_2021"
                      className="booksthatshaped2021-category-link__link"
                      onClick={() => WithEvent('BOOKS_THAT_SHAPED_2021', 'Clicked Best Scifi Mystery Tile', 'BOOKS_THAT_SHAPED_2021_LINK', 1)}
                      aria-label="Best Scifi and Mystery 2021"
                    >
                      <img
                        className="booksthatshaped2021-category-image__img"
                        alt="Best Scifi and Mystery 2021"
                        src={urlGenerator('/S/pages/books_that_shaped_2021_best_scifi_mystery.jpg')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="booksthatshaped2021-category__div">
                    <Link
                      to="/metalist/best_ya_2021"
                      className="booksthatshaped2021-category-link__link"
                      onClick={() => WithEvent('BOOKS_THAT_SHAPED_2021', 'Clicked Best Young Adult Tile', 'BOOKS_THAT_SHAPED_2021_LINK', 1)}
                      aria-label="Best Young Adult 2021"
                    >
                      <img
                        className="booksthatshaped2021-category-image__img"
                        alt="Best Young Adult 2021"
                        src={urlGenerator('/S/pages/books_that_shaped_2021_best_young_adult.jpg')}
                      />
                    </Link>
                  </div>
                </LazyLoad>
              </div>
            </section>

          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BooksThatShaped2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BooksThatShaped2021;
