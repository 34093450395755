const shouldRedirect = (sec) => {
  const redirectObj = {
    'MERCH-STRAND TOTES': 'MERCH-TOTES',
  };

  if (redirectObj[sec]) return true;

  return false;
};

export default shouldRedirect;

export const redirectList = {
  'MERCH-STRAND TOTES': 'MERCH-TOTES',
};
