import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Footer from '../../components/footer/Footer';
import FooterDesktop from '../../components/footer/FooterDesktop';
import './_viewFooter.scss';

const ViewFooter = () => {
  const [opened, setOpened] = useState({
    books: false,
    events: false,
    picks: false,
    gifts: false,
    rare: false,
    bbtf: false,
    more: false,
    about: false,
    hours: false,
    account: false,
    careers: false,
    contact: false,
    blog: false,
    faq: false,
  });

  const match1023 = useMediaQuery('(min-width: 1023px)');

  const handleCollapse = (val) => {
    setOpened({ ...opened, [val]: !opened[val] });
  };

  return match1023
    ? (
      <FooterDesktop />
    )
    : (
      <Footer
        opened={opened}
        handleCollapse={handleCollapse}
      />
    );
};

export default ViewFooter;
