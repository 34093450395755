import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Loading from '../loading/Loading';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import use4Cite from '../../hooks/use4Cite';
import './_signup.scss';

export const CREATE_CUSTOMER = gql`
mutation createCustomer($customer: CustomerInput!){
  customerCreate(customer: $customer){
    failure
    success
    customer{
      customerId
      firstName
      lastName
      verified
    }
  }
}
`;

const useStyles = makeStyles({
  paperDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    width: '125px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootIconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
  rootTextField: {
    margin: '10px 0',
  },
  rootIconButtonClose: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});


const Signup = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const [inputState, setInput] = useState({
    forgotEmailInput: '',
    passwordInput: '',
    emailInput: '',
    firstNameInput: '',
    lastNameInput: '',
    phoneInput: '',
    newEmailInput: '',
    newPasswordInput: '',
    confirmPasswordInput: '',
    signUp: false,
    resetPassword: false,
    logIn: true,
  });

  const [showPassword, setPassword] = useState(false);
  const handleShowPassword = () => setPassword(prev => !prev);

  const handleInput = name => (e) => {
    setInput({ ...inputState, [name]: e.target.value });
  };

  const [customerCreate, {
    data: createData,
    loading: createLoading,
    error: createError,
  }] = useMutation(CREATE_CUSTOMER);

  if (createLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="signup-container__div">
          <main className="signup-content__main">
            { scroll > 1000 && <CompFab />}
            <div className="signup-content__div">
              <Loading />
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  const errorDic = (errTerm) => {
    const errObj = {
      last_name: 'Last Name',
      first_name: 'First Name',
      email: 'Email',
      password: 'Password',
      'email Account': 'Email Account',
    };

    return errObj[errTerm] ? errObj[errTerm] : errTerm;
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="signup-container__div">
        <main className="signup-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="signup-content__div">
            <ThemeProvider theme={theme}>
              <div className="signup-header-image__div">
                <img className="signup-header-image__img" src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" />
              </div>
              <h1 className="signup-header__h1">Create a Strand Account</h1>
              {
                createData?.customerCreate?.success
                  && <p className="signup-success__p">Success! A verification link has been sent to your email. You may now log in.</p>
              }
              {
                createError?.graphQLErrors
                  && createError?.graphQLErrors.length > 0
                  && createError?.graphQLErrors?.[0]?.message === 'address is undeliverable'
                  && <p className="loginsignup-error__p">Verification email failed to deliver. Please check your email.</p>
              }
              {
                createError?.graphQLErrors
                  && createError?.graphQLErrors.length > 0
                  && createError?.graphQLErrors?.[0]?.message !== 'address is undeliverable'
                  && createError?.graphQLErrors
                    .map(err => (
                      <p className="loginsignup-error__p" key={err?.pathf}>
                        {`${errorDic(err?.pathf)} ${err?.message}`}
                      </p>
                    ))
              }
              <div className="signup-form__div">
                <form
                  className="signup-form__form"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();

                    Sentry.addBreadcrumb({
                      category: 'SIGNUP',
                      message: 'Clicked Sign Up',
                      level: Sentry.Severity.Info,
                    });

                    customerCreate({
                      variables: {
                        customer: {
                          email: inputState.newEmailInput,
                          firstName: inputState.firstNameInput,
                          lastName: inputState.lastNameInput,
                          password: inputState.newPasswordInput,
                        },
                      },
                    })
                      .catch(() => {});
                  }}
                >
                  <TextField
                    onChange={handleInput('firstNameInput')}
                    label="First Name"
                    value={inputState.firstNameInput}
                    classes={{ root: classes.rootTextField }}
                    variant="outlined"
                    className="signup-input__textfield"
                    type="text"
                    id="signup-firstname"
                    required
                    InputProps={{
                      'aria-label': 'First Name',
                    }}
                  />
                  <TextField
                    onChange={handleInput('lastNameInput')}
                    label="Last Name"
                    value={inputState.lastNameInput}
                    classes={{ root: classes.rootTextField }}
                    variant="outlined"
                    className="signup-input__textfield"
                    type="text"
                    id="signup-lastname"
                    required
                    InputProps={{
                      'aria-label': 'Last Name',
                    }}
                  />
                  <TextField
                    onChange={handleInput('newEmailInput')}
                    label="Email Address"
                    value={inputState.newEmailInput}
                    classes={{ root: classes.rootTextField }}
                    variant="outlined"
                    className="signup-input__textfield"
                    type="email"
                    id="signup-email"
                    required
                    InputProps={{
                      'aria-label': 'Email Address',
                    }}
                  />
                  <TextField
                    onChange={handleInput('newPasswordInput')}
                    label="Password"
                    value={inputState.newPasswordInput}
                    classes={{ root: classes.rootTextField }}
                    required
                    variant="outlined"
                    id="signup-password"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            onClick={handleShowPassword}
                            aria-label="Show Password"
                          >
                            {
                              showPassword
                                ? <Visibility />
                                : <VisibilityOff />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                      autoComplete: 'new-password',
                      'aria-label': 'Password',
                    }}
                  />
                  <input
                    aria-label="sign up"
                    className="signup-submit__input"
                    type="submit"
                    value="Sign Up"
                  />
                </form>
              </div>
              <div className="signup-options__div">
                <Link to="/login" className="signup-option-btn__link">
                  <p className="signup-signup__p">Already have an account? Log In</p>
                </Link>
              </div>
            </ThemeProvider>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Signup.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Signup;
