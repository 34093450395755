import React from 'react';
import PropTypes from 'prop-types';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import './_catalogInfo.scss';


const useStyles = makeStyles({
  rootDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});

const CatalogInfo = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Catalog Info"
      classes={{ paper: classes.rootDialog }}
    >
      <div className="cataloginfo-container__div">
        <div className="cataloginfo-content__div">
          <div className="cataloginfo-close__div">
            <IconButton
              onClick={onClose}
              classes={{ root: classes.rootIconButton }}
              aria-label="close"
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <section className="cataloginfo-catalog__section">
            <h2 className="orderdetaildialog-header__h2">Order Status</h2>
            <div className="orderdetaildialog-accent__div" />
            <div className="cataloginfo-details__div">
              <div className="cataloginfo-catalog__div">
                <div className="cataloginfo-catalog-header__div">
                  <MenuBookRoundedIcon />
                  <p className="cataloginfo-header-details__p">
                    &nbsp;- Available in Strand
                  </p>
                </div>
                <p className="cataloginfo-details__p">
                  Ships from our store
                </p>
              </div>
              <div className="cataloginfo-catalog__div">
                <div className="cataloginfo-catalog-header__div">
                  <LocalShippingRoundedIcon />
                  <p className="cataloginfo-header-details__p">
                    &nbsp;- Drop Ship
                  </p>
                </div>
                <p className="cataloginfo-details__p">
                  Ships from out-of-state warehouse
                </p>
              </div>
            </div>
          </section>
          <section className="cataloginfo-conditions__section">
            {/* <PageSubHeader header="Conditions" /> */}
            <div className="cataloginfo-conditions__div">
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">New:</span>
                &nbsp;Brand new, never been sold before
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Used - Good:</span>
                &nbsp;Some wear and tear; hardcovers may not have a dust jacket.
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Used - Very Good:</span>
                &nbsp;Almost as good as new; may have a mark (a dot or a dash) on the bottom.
              </p>
              {/* <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Fair:</span>
                &nbsp;Almost as good as new; may have a mark (a dot or a dash) on the bottom.
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Fine:</span>
                &nbsp;Almost as good as new; may have a mark (a dot or a dash) on the bottom.
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Near Fine:</span>
                &nbsp;Almost as good as new; may have a mark (a dot or a dash) on the bottom.
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Very Fine:</span>
                &nbsp;Almost as good as new; may have a mark (a dot or a dash) on the bottom.
              </p> */}
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Good:</span>
                &nbsp;Some wear and tear. A rare and collectable item.
              </p>
              <p className="cataloginfo-details__p">
                <span className="cataloginfo-details__span--strong">Rare - Very Good:</span>
                &nbsp;Almost as good as new. A rare and collectable item.
              </p>
            </div>
          </section>
        </div>
      </div>
    </Dialog>
  );
};

CatalogInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CatalogInfo;
