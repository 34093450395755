import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
// import moment from 'moment';
import NumberFormat from 'react-number-format';
import Drawer from '@material-ui/core/Drawer';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Close from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import { ButtonBase } from '@material-ui/core';
// import Chip from '@material-ui/core/Chip';
// import Slider from '@material-ui/core/Slider';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { excludedBindingsDictionary } from '../../helpers/bindingDictionary';
// import capitalizeString from '../../helpers/capitalizeString';
import './_productListDrawer.scss';

/*

Should category only show the meta tags associated with the book?
I can also show it by genre. Also, does the payload contain the info?

*/

const CONDITION_LIST = gql`
  query{
    conditionList{
      display
      key
    }
  }
  `;

const BINDING_LIST = gql`
  query{
    bindingList{
      display
      key
    }
  }
  `;

const useStyles = makeStyles({
  paperAnchorLeftDrawer: {
    width: '260px',
    // paddingLeft: '25px',
    // paddingTop: '25px',
    backgroundColor: '#fbfaf6',
  },
  colorSecondaryCheckbox: {
    color: '#af272f !important',
  },
  rootSlider: {
    color: '#af272f',
    width: 175,
  },
  rootIconButton: {
    color: '#af272f',
    top: '5px',
    '&:hover': {
      backgroundColor: '#af272f',
      color: '#ffffff',
    },
  },
  colorSecondaryChip: {
    backgroundColor: '#af272f !important',
  },
  rootChip: {
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '13px',
  },
  rootTextField: {
    width: '100%',
    maxWidth: '90px',
    margin: '10px 0',
  },
  rootButtonBase: {
    backgroundColor: '#af272f',
    color: '#ffffff',
    borderRadius: '5px',
    position: 'relative',
    left: '-10px',
    padding: '5px',

    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootClose: {
    fontSize: '24px',
  },
  rootCloseIconButton: {
    width: '48px',
    display: 'block',
    margin: '10px 15px 0 auto',
    color: '#af272f',

    '&:hover': {
      background: 'transparent',
    },
  },
});

const NumberCopyrightMin = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={4}
    />
  );
};

NumberCopyrightMin.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberCopyrightMax = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={4}
    />
  );
};

NumberCopyrightMax.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberPriceMin = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={6}
    />
  );
};

NumberPriceMin.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberPriceMax = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={6}
    />
  );
};

NumberPriceMax.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const ProductListLocationTempDrawer = (props) => {
  const {
    handleSigned,
    signedState,
    sectionName,
    handleCondition,
    condState,
    bindState,
    handleBind,
    handlePriceRange,
    priceRangeState,
    handleReset,
    // handlePublish,
    // publishRangeState,
    open,
    onClose,
    handleApply,
    copyrightState,
    handleCopyright,
    handleStock,
    stockState,
  } = props;

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        input: {
          padding: '10.5px 10px',
        },
      },
    },
  });

  const classes = useStyles();
  const rareConditionTypes = ['RARE_FAIR', 'RARE_FINE', 'RARE_GOOD', 'RARE_NEARFINE', 'RARE_VERYFINE', 'RARE_VERYGOOD'];
  const [collapseState, setCollapse] = useState({
    section: false,
    condition: false,
    binding: false,
  });

  const stockTypes = [
    {
      display: 'All',
      value: 'ANY',
    },
    {
      display: 'In Stock',
      value: 'IN_STOCK',
    },
    {
      display: 'Just Arrived',
      value: 'JUST_ARRIVED',
    },
    {
      display: 'In Stock and Recently Out of Stock',
      value: 'IN_STOCK_AND_RECENT',
    },
  ];

  // const handleDelete = (filterOp) => {
  //   const isCond = (cond) => {
  //     const condTypes = ['NEW', 'RARE_FAIR', 'RARE_FINE', 'RARE_GOOD', 'RARE_NEARFINE', 'RARE_VERYFINE', 'RARE_VERYGOOD', 'USED_GOOD', 'USED_VERYGOOD'];

  //     return condTypes.indexOf(cond) > -1;
  //   };

  //   if (isCond(filterOp)) setCond('ANY');
  //   if (filterOp === 'signed') setSigned(false);
  //   if (!isCond(filterOp) && filterOp !== 'signed') setBind('ANY');
  // };

  const {
    error: errorConditionList,
    loading: loadingConditionList,
    data: dataConditionList,
  } = useQuery(CONDITION_LIST);

  const {
    error: errorBindingList,
    loading: loadingBindingList,
    data: dataBindingList,
  } = useQuery(BINDING_LIST);

  if (errorConditionList || errorBindingList) return <Redirect to={{ pathname: '/error', state: { errorConditionList, errorBindingList } }} />;

  if (loadingConditionList || loadingBindingList) return <span />;

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        open={open}
        onClose={onClose}
        classes={{ paperAnchorLeft: classes.paperAnchorLeftDrawer }}
      >
        <IconButton classes={{ root: classes.rootCloseIconButton }} onClick={onClose}>
          <Close />
        </IconButton>
        <div className="productlistdrawer-container__div">
          {/* <div className="productlistdrawer-changes__div">
            <div className="productlistdrawer-chips__div">
              {
              [bindState].concat([condState]).concat(signedState ? ['signed'] : [])
                .filter(state => state !== 'ANY')
                .map((filterOp, i) => (
                  <div className="productlistdrawer-chip__div" key={`filterKey${i + 200}`}>
                    <Chip
                      label={capitalizeString(filterOp)}
                      className="productlistdrawer-chip__chip"
                      onDelete={() => handleDelete(filterOp)}
                      color="secondary"
                      classes={{
                        colorSecondary: classes.colorSecondaryChip,
                        root: classes.rootChip,
                      }}
                    />
                  </div>
                ))
              }
            </div>
            {
              [bindState].concat(condState).concat(signedState ? ['signed'] : []).length > 2
                && (
                  <div className="productlistdrawer-clear__div">
                    <ButtonBase
                      disableRipple
                      className="productlistdrawer-clear-btn__buttonbase"
                      onClick={handleReset}
                    >
                      <p className="productlistdrawer-clear-btn__p">Clear all</p>
                    </ButtonBase>
                  </div>
                )
            }
          </div> */}
          {
        // price slider filter
        // <div className="productlistdrawer-filter__div productlistdrawer-price__div">
        //     <h2 id="range-price-slider" className="productlistdrawer-filter-header__h2">Price</h2>
        //     {
        //     (() => {
        //         const valueText = val => `$${val}`;
        //         return (
        //         <div className="productlistdrawer-filter-details__div">
        //             <Slider
        //             value={priceRangeState}
        //             valueLabelDisplay="auto"
        //             aria-labelledby="range-price-slider"
        //             getAriaValueText={valueText}
        //             classes={{ root: classes.rootSlider }}
        //             step={5}
        //             min={0}
        //             max={50}
        //             onChange={handlePriceRange}
        //             />
        //         </div>
        //         );
        //     })()
        //     }
        //     {/* <div className="productlistdrawer-apply__div">
        //     <ButtonBase
        //         disableRipple
        //         className="productlistdrawer-clear-btn__buttonbase"
        //         onClick={priceRangeButton}
        //         classes={{ root: classes.rootButtonBase }}
        //     >
        //         <p className="productlistdrawer-clear-btn__p productlistdrawer-apply-btn__p">Apply</p>
        //     </ButtonBase>
        //     </div> */}
        // </div>


        }

          <div className="productlistdrawer-filter__div">
            <div className="productlistdrawer-price__div">
              <div className="productlistdrawer-header__div">
                <h3 className="productlistdrawer-header__h2">Price</h3>
              </div>
              <div className="productlistdrawer-accent__div" />
              <div className="productlistdrawer-range__div">
                <TextField
                  id="price-min"
                  label="Min Price"
                  value={priceRangeState.minStv}
                  name="minPrice"
                  onChange={e => handlePriceRange(e, 'minStv')}
                  variant="outlined"
                  classes={{ root: classes.rootTextField }}
                  InputProps={{
                    inputComponent: NumberPriceMin,
                  }}
                />
                <TextField
                  id="price-max"
                  label="Max Price"
                  value={priceRangeState.maxStv}
                  name="maxPrice"
                  onChange={e => handlePriceRange(e, 'maxStv')}
                  variant="outlined"
                  classes={{ root: classes.rootTextField }}
                  InputProps={{
                    inputComponent: NumberPriceMax,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="productlistdrawer-filter__div">
            <div className="productlistdrawer-filter-header__div">
              <h2 className="productlistdrawer-filter-header__h2">Stock Type</h2>
            </div>
            {/* <Collapse in={collapseState.condition}> */}
            {
                stockTypes
                  .sort((a, b) => {
                    if (a.display > b.display) return 1;
                    if (b.display > a.display) return -1;
                    return 0;
                  })
                  .map(stock => (
                    <div className="productlistdrawer-filter-details__div" key={stock.display}>
                      <div className="productlistdrawer-filter-details__div" key={stock.display}>
                        <Radio
                          classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                          value={stock.value}
                          color="secondary"
                          inputProps={{ 'aria-label': `Stock Type ${stock.display}` }}
                          checked={stockState === stock.value}
                          onChange={() => handleStock(stock.value)}
                        />
                        <div className="productlistdrawer-filter-text-items__div">
                          <p className="productlistdrawer-filter__p">{stock.display}</p>
                        </div>
                      </div>
                    </div>
                  ))
            }
            {/* </Collapse> */}
          </div>

          {
        // bookbinding filter
          !sectionName.match(/merch/i)
            && (
              <div className="productlistdrawer-filter__div">
                <div className="productlistdrawer-filter-header__div">
                  <h2 className="productlistdrawer-filter-header__h2">Book Binding</h2>
                  <div className="productlistdrawer-filter-expand__div">
                    {
                    collapseState.binding
                      ? (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => ({ ...prev, ...{ binding: !prev.binding } }))
                            }
                          classes={{ root: classes.rootIconButton }}
                          aria-label="Expand Less"
                          disableRipple
                          disableTouchRipple
                        >
                          <ExpandLess />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => (
                              { ...prev, ...{ binding: !prev.binding } }))
                            }
                          classes={{ root: classes.rootIconButton }}
                          aria-label="Expand More"
                          disableRipple
                          disableTouchRipple
                        >
                          <ExpandMore />
                        </IconButton>
                      )
                    }
                  </div>
                </div>
                <Collapse in={collapseState.binding} collapsedHeight={80}>
                  {
                    dataBindingList
                    && dataBindingList.bindingList
                    && dataBindingList.bindingList
                      .filter(binding => binding.key === 'HARDCOVER').length > 0
                    && dataBindingList.bindingList
                      .filter(binding => binding.key === 'HARDCOVER')
                      .map(binding => (
                        <div className="productlistdrawer-filter-details__div" key={binding.key}>
                          <Radio
                            classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                            value={binding.key}
                            color="secondary"
                            inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                            checked={binding.key === bindState}
                            onChange={() => handleBind(binding.key)}
                          />
                          <div className="productlistdrawer-filter-text-items__div">
                            <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                          </div>
                        </div>
                      ))
                  }
                  {
                    dataBindingList
                    && dataBindingList.bindingList
                    && dataBindingList.bindingList
                      .filter(binding => binding.key === 'PAPERBACK').length > 0
                    && dataBindingList.bindingList
                      .filter(binding => binding.key === 'PAPERBACK')
                      .map(binding => (
                        <div className="productlistdrawer-filter-details__div" key={binding.key}>
                          <Radio
                            classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                            value={binding.key}
                            color="secondary"
                            inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                            checked={binding.key === bindState}
                            onChange={() => handleBind(binding.key)}
                          />
                          <div className="productlistdrawer-filter-text-items__div">
                            <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                          </div>
                        </div>
                      ))
                  }
                  {
                    dataBindingList
                      && dataBindingList.bindingList
                      && dataBindingList.bindingList
                        .filter(binding => excludedBindingsDictionary.indexOf(binding.key) < 0)
                        .filter(binding => binding.key !== 'PAPERBACK')
                        .filter(binding => binding.key !== 'HARDCOVER')
                        .sort((a, b) => {
                          if (a.key > b.key) return 1;
                          if (b.key > a.key) return -1;
                          return 0;
                        })
                        .map(binding => (
                          <div className="productlistdrawer-filter-details__div" key={binding.key}>
                            <Radio
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={binding.key}
                              color="secondary"
                              inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                              checked={binding.key === bindState}
                              onChange={() => handleBind(binding.key)}
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                            </div>
                          </div>
                        ))
                  }
                </Collapse>
              </div>
            )
        }
          {
        // book condition filter
            !sectionName.match(/merch/i)
              && (
                <div className="productlistdrawer-filter__div">
                  <div className="productlistdrawer-filter-header__div">
                    <h2 className="productlistdrawer-filter-header__h2">Condition</h2>
                  </div>
                  {/* <Collapse in={collapseState.condition}> */}
                  {
                    sectionName.match(/rare/i)
                      ? (
                        dataConditionList
                          && dataConditionList.conditionList
                          && dataConditionList.conditionList
                            .filter(cond => rareConditionTypes.indexOf(cond.key) > -1)
                            .map(cond => (
                              <div className="productlistdrawer-filter-details__div" key={cond.key}>
                                <div className="productlistdrawer-filter-details__div">
                                  <Radio
                                    classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                    value={cond.key}
                                    color="secondary"
                                    inputProps={{ 'aria-label': `Condition ${cond.display}` }}
                                    checked={condState === cond.key}
                                    onChange={() => handleCondition(cond.key)}
                                  />
                                  <div className="productlistdrawer-filter-text-items__div">
                                    <p className="productlistdrawer-filter__p">{`${cond.display}`}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                      )
                      : (
                        dataConditionList
                          && dataConditionList.conditionList
                          && dataConditionList.conditionList
                            .sort((a, b) => {
                              if (a.key > b.key) return 1;
                              if (b.key > a.key) return -1;
                              return 0;
                            })
                            .filter(cond => rareConditionTypes.indexOf(cond.key) < 0)
                            .map(cond => (
                              <div className="productlistdrawer-filter-details__div" key={cond.key}>
                                <div className="productlistdrawer-filter-details__div" key={cond.key}>
                                  <Radio
                                    classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                    value={cond.key}
                                    color="secondary"
                                    inputProps={{ 'aria-label': `Condition ${cond.display}` }}
                                    checked={condState === cond.key}
                                    onChange={() => handleCondition(cond.key)}
                                  />
                                  <div className="productlistdrawer-filter-text-items__div">
                                    <p className="productlistdrawer-filter__p">{cond.display}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                      )
                }
                  {/* </Collapse> */}
                </div>
              )
        }
          {
            !sectionName.match(/merch/i)
              && (
                <div className="productlistdrawer-filter__div">
                  <h2 className="productlistdrawer-filter-header__h2">Signed</h2>
                  <div className="productlistdrawer-filter-details__div">
                    <Checkbox
                      classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                      checked={signedState}
                      inputProps={{ 'aria-label': 'Signed Checkbox' }}
                      onChange={handleSigned}
                      value="checkedSigned"
                      color="secondary"
                    />
                    <p className="productlistdrawer-filter__p">
                      signed&nbsp;
                    </p>
                  </div>
                </div>
              )
          }
          {/* <div className="productlistdrawer-filter__div">
        <h2 className="productlistdrawer-filter-header__h2">Published Between</h2>
        {
            (() => {
                const valueText = val => `$${val}`;
                return (
                <div className="productlistdrawer-filter-details__div">
                    <Slider
                    value={publishRangeState}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-publish-slider"
                    getAriaValueText={valueText}
                    classes={{ root: classes.rootSlider }}
                    step={1}
                    min={1900}
                    max={new Date(Date.now()).getFullYear()}
                    onChange={handlePublish}
                    />
                </div>
                );
            })()
            }
        <div className="productlistdrawer-apply__div">
            <ButtonBase
            disableRipple
            className="productlistdrawer-clear-btn__buttonbase"
            onClick={handleApply}
            classes={{ root: classes.rootButtonBase }}
            >
            <p className="productlistdrawer-clear-btn__p productlistdrawer-apply-btn__p">Apply Filters</p>
            </ButtonBase>
        </div>
        </div> */}

          {
            !sectionName.match(/merch/i)
              && (
                <div className="productlistdrawer-filter__div">
                  <div className="productlistdrawer-copyright__div">
                    <div className="productlistdrawer-header__div">
                      <h2 className="productlistdrawer-filter-header__h2">Copyright</h2>
                    </div>
                    <div className="productlistdrawer-accent__div" />
                    <div className="productlistdrawer-range__div">
                      <TextField
                        id="copyright-min"
                        label="Min Year"
                        value={copyrightState.minYear}
                        name="minYear"
                        onChange={e => handleCopyright(e, 'minYear')}
                        variant="outlined"
                        classes={{ root: classes.rootTextField }}
                        InputProps={{
                          inputComponent: NumberCopyrightMin,
                        }}
                      />
                      <TextField
                        id="copyright-max"
                        label="Max Year"
                        value={copyrightState.maxYear}
                        name="maxYear"
                        onChange={e => handleCopyright(e, 'maxYear')}
                        variant="outlined"
                        classes={{ root: classes.rootTextField }}
                        InputProps={{
                          inputComponent: NumberCopyrightMax,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
          }

          <div className="productlistdrawer-apply__div">
            <ButtonBase
              disableRipple
              className="productlistdrawer-clear-btn__buttonbase"
              onClick={handleApply}
              classes={{ root: classes.rootButtonBase }}
            >
              <p className="productlistdrawer-clear-btn__p productlistdrawer-apply-btn__p">Apply Filters</p>
            </ButtonBase>
          </div>

          <div className="productlistdrawer-apply__div">
            <ButtonBase
              disableRipple
              className="productlistdrawer-clear-btn__buttonbase"
              onClick={handleReset}
              classes={{ root: classes.rootButtonBase }}
            >
              <p className="productlistdrawer-clear-btn__p productlistdrawer-apply-btn__p">Clear Filters</p>
            </ButtonBase>
          </div>

        </div>
      </Drawer>
    </ThemeProvider>
  );
};

ProductListLocationTempDrawer.propTypes = {
  handleSigned: PropTypes.func.isRequired,
  signedState: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired,
  handleCondition: PropTypes.func.isRequired,
  condState: PropTypes.string.isRequired,
  bindState: PropTypes.string.isRequired,
  handleBind: PropTypes.func.isRequired,
  handlePriceRange: PropTypes.func.isRequired,
  priceRangeState: PropTypes.objectOf(PropTypes.any).isRequired,
  handleReset: PropTypes.func.isRequired,
  // handlePublish: PropTypes.func.isRequired,
  // publishRangeState: PropTypes.arrayOf(PropTypes.number).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleApply: PropTypes.func.isRequired,
  copyrightState: PropTypes.objectOf(PropTypes.any).isRequired,
  handleCopyright: PropTypes.func.isRequired,
  stockState: PropTypes.string.isRequired,
  handleStock: PropTypes.func.isRequired,
};

export default ProductListLocationTempDrawer;
