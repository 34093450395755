import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { ApolloProvider } from 'react-apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import ReactGA from 'react-ga4';
import Routes from '../src/components/routes/Routes';
import CartSnackbarProvider from './components/cartSnackbar/CartSnackbarProvider';
// import BrowserRegister from './components/browserRegister/BrowserRegister';
import './_app.scss';

ReactGA.initialize('G-X3KLEPNSP5', {
  // debug: true,
  titleCase: false,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_URL,
      // credentials: 'same-origin',
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <CartSnackbarProvider>
      <div className="app-container__div">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </CartSnackbarProvider>
  </ApolloProvider>
);

export default App;
