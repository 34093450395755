import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Redirect, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuRounded from '@material-ui/icons/MenuRounded';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { makeStyles } from '@material-ui/core/styles';
import GlobalBanner from '../globalBanner/GlobalBanner';
import DrawerNav from '../drawerNav/DrawerNav';
import SearchBarDesktop from '../searchBar/SearchBarDesktop';
import NavMenu from '../navMenu/NavMenu';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import useToggleLoginSignup from '../../hooks/useToggleLoginSignup';
import useUserLogOut from '../../hooks/useUserLogOut';
import './_navbarDesktop.scss';

const useStyles = makeStyles({
  rootAppBar: {
    boxShadow: 'none',
  },
  appbarColorPrimary: {
    backgroundColor: '#fbfaf6',
  },
  iconBtnRoot: {
    color: '#af272f',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#d4301a',
    },
  },
  menuRoot: {
    width: '1.50em',
    height: '1.50em',
  },
  searchRoot: {
    width: '1.50em',
    height: '1.50em',
  },
  accountRoot: {
    width: '1.50em',
    height: '1.50em',
    color: '#af272f',

    '&:hover': {
      color: 'd4301a',
    },
  },
  shoppingRoot: {
    width: '1.50em',
    height: '1.50em',
  },

  badgeColorPrimary: {
    backgroundColor: '#af272f',
  },
  rootLogoutButton: {
    fontSize: '15px',
    fontfamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    color: '#000000',
    margin: 'auto',
    '&:hover': {
      color: '#d4301a',
    },
  },
});

const NavbarDesktop = (props) => {
  const {
    openDrawer,
    drawerToggle,
    cartList,
    customerLoggedIn,
    refetchCartList,
    refetchCustomerLoggedIn,
  } = props;

  const classes = useStyles();

  const toggleLoginSignup = useToggleLoginSignup();

  const {
    customerLogout,
    error: errorLogout,
    loading: loadingLogout,
    data: dataLogout,
  } = useUserLogOut();

  const [subMenuState, setSubMenu] = useState(null);

  const enterAccount = () => setSubMenu('account');

  if (loadingLogout) return <div />;

  if (errorLogout) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            errorLogout,
          },
        }}
      />
    );
  }

  if (dataLogout && dataLogout.customerLogout) {
    refetchCustomerLoggedIn();
    refetchCartList();
  }

  const handleLogOut = () => {
    customerLogout();
    setSubMenu(null);
    toggleLoginSignup[2]();
  };

  const cartItemCount = (cartList?.find(cart => cart?.cartType === 'PRIMARY')?.cartItem.reduce((acc, { qty }) => qty + acc, 0))
    || 0;

  const cartLabel = `Cart${cartItemCount > 0 ? ` (${cartItemCount} item${cartItemCount === 1 ? '' : 's'})` : ''}`;

  return (
    <div className="navbardesktop-container__div">
      <AppBar
        className="navbardesktop-appbar__appbar"
        classes={{ colorPrimary: classes.appbarColorPrimary, root: classes.rootAppBar }}
        position="fixed"
      >
        <GlobalBanner
          message="Open Every Day, 10am–8pm (Rare Book Room closed 6/9)"
          link="/hours-locations"
        />
        <Toolbar disableGutters>
          <div className="navbardesktop-icons__div">
            <div className="navbardesktop-lefticon__div">
              <IconButton aria-label="menu" onClick={drawerToggle} classes={{ root: classes.iconBtnRoot }}>
                <MenuRounded
                  className="navbar-menurounded__menurounded"
                  classes={{ root: classes.menuRoot }}
                />
              </IconButton>
              <SearchBarDesktop />
            </div>
            <NavLink
              to="/"
              className="navbardesktop-navlink__navlink"
              onClick={() => WithEvent('NAVBAR', 'Clicked logo link', 'NAVBAR_LINK', 1)}
            >
              <div className="navbardesktop-logo__div">
                <img
                  className="navbardesktop-image__img"
                  alt="Strand Book Store"
                  src={urlGenerator('/S/static/strand-logo-exact.png')}
                />
              </div>
            </NavLink>
            <div className="navbardesktop-righticon__div">
              <div
                className="navbardesktop-right-icons__div"
                onMouseLeave={() => setSubMenu(null)}
              >
                <NavLink
                  className="navbardesktop-cart__navlink"
                  to="/cart"
                  onClick={() => WithEvent('NAVBAR', 'Clicked cart link', 'NAVBAR_LINK', 1)}
                  aria-label={cartLabel}
                >
                  <Badge
                    className="navbar-badge__badge"
                    badgeContent={cartItemCount}
                    color="primary"
                    classes={{ colorPrimary: classes.badgeColorPrimary }}
                  >
                    <ShoppingBasket
                      className="navbardesktop-shoppingbasket__shoppingbasket"
                      classes={{ root: classes.shoppingRoot }}
                    />
                  </Badge>
                </NavLink>
                <div
                  className="navbardesktop-link__div"
                  onMouseEnter={enterAccount}
                  onFocus={enterAccount}
                >
                  <IconButton
                    style={{ position: 'relative' }}
                    className="navbardesktop-bottom-link__navlink navbardesktop-bottom-link-account__navlink  navbardesktop-account__navlink"
                    aria-label="account"
                    onClick={() => setSubMenu(subMenuState === 'account' ? null : 'account')}
                    classes={{ root: classes.iconBtnRoot }}
                  >
                    <AccountCircle
                      className="navbardesktop-account-icon__accountcircle"
                      classes={{ root: classes.accountRoot }}
                    />
                  </IconButton>
                  <div className={`navbardesktop-submenu__div${subMenuState === 'account' ? ' navbardesktop-submenu-account__div--active' : ''}`}>
                    <div className="navbardesktop-submenu-links__div navbardesktop-submenu-account-links__div">
                      {
                        customerLoggedIn
                        && (
                          <NavLink
                            to="/myaccount#orders"
                            className="navbardesktop-bottom-link__navlink"
                            onClick={() => WithEvent('NAVBAR', 'Clicked my orders link', 'NAVBAR_LINK', 1)}
                          >
                            My Orders
                          </NavLink>
                        )
                      }
                      {
                        customerLoggedIn
                          ? (
                            <ButtonBase
                              classes={{ root: classes.rootLogoutButton }}
                              onClick={handleLogOut}
                              disableRipple
                              disableTouchRipple
                            >
                              Logout
                            </ButtonBase>
                          )

                          : (
                            <Link
                              to="/login"
                              className="navbardesktop-login-link__navlink"
                            >
                              Log in or Sign up
                            </Link>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="navbardesktop-tagline__div">
              <p className="navbardesktop-tagline__p">Independent Booksellers for 97 Years</p>
            </div>
          </div>
        </Toolbar>
        <NavMenu
          subMenuState={subMenuState}
          setSubMenu={setSubMenu}
        />
      </AppBar>
      {/* Added toolbar components to provide space underneath floating navbar.
      Hacky, perhaps. But it is effective */}
      <Toolbar />
      <Toolbar />
      <DrawerNav openDrawer={openDrawer} drawerToggle={drawerToggle} />
    </div>
  );
};

NavbarDesktop.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  drawerToggle: PropTypes.func.isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
};

export default NavbarDesktop;
