import urlGenerator, { generateURLs } from '../../helpers/urlGenerator';

const bbtfList = {
  bbtfExperts: [
    {
      id: 'bbtfExpert01',
      name: 'Miguel Soto',
      role: 'Manager',
      email: 'miguel@strandbooks.com',
      expertImage: urlGenerator('/S/pages/bbtf-miguel.jpg'),
    },
    {
      id: 'bbtfExpert02',
      name: 'Jenna Kemmer',
      role: 'Associate',
      email: 'jenna@strandbooks.com',
      expertImage: urlGenerator('/S/pages/bbtf-jenna.jpg'),
    },
    {
      id: 'bbtfExpert03',
      name: 'Sarah Weldon',
      role: 'Associate',
      email: 'sarah@strandbooks.com',
      expertImage: urlGenerator('/S/pages/bbtf-sarah.jpg'),
    },
  ],
  bbtfFaqs: {
    purchases: [
      {
        id: 'faqsPurchases01',
        question: 'How does Books by the Foot work?',
        answer: 'If you order from one (or more) of our collections via the website, our team will box and ship your books, and soon after that they will arrive at your door! If you don’t see what you’re looking for, or you’re interested in a bulk order, feel free to directly contact us by emailing bksbythefoot@strandbooks.com or calling 212-660-6646. We can curate collections in most styles and subjects, so when in doubt, ask!\nBooks by the Foot is not a discount program nor is there a fee for our services. We simply assemble a personalized collection for you within your chosen price range. We help you get the content and aesthetic you want while keeping you within budget.',
      },
      {
        id: 'faqsPurchases02',
        question: 'Can I customize my order by subject and color?',
        answer: 'Yes! Most collections of can be customized by color. Please contact the Books by the Foot team (bksbythefoot@strandbooks.com or 212-660-6646) to discuss availability and receive a customized quote.',
      },
      {
        id: 'faqsPurchases03',
        question: 'How many books are in a foot?',
        answer: 'There is no set number of books in a foot, as the quantity can vary depending on spine thickness. However, generally there is an average of 10 books per foot.',
      },
      {
        id: 'faqsPurchases04',
        question: 'Is there a limit to how many feet I can order?',
        answer: 'No, there is no minimum or maximum on the number of feet you may order. However, certain categories may be limited by availability. Orders must be placed in whole feet.',
      },
      {
        id: 'faqsPurchases05',
        question: 'How long does an order take to complete?',
        answer: 'We can put together most custom collections within 24 hours. Orders of 75 feet or more may require a longer lead time. If you are on a specific timeline, please let us know when you order, and we will do our best to accommodate it.',
      },
      {
        id: 'faqsPurchases06',
        question: 'Can I see the books before the order is finalized?',
        answer: 'If you order via the “Buy Now” button on the website, your order will be processed and shipped without review. If you prefer to review your books via photos and a title list, you may place the order with us by emailing bksbythefoot@strandbooks.com or calling 212-660-6646. Markdown collections are sold as-is and are not available for review.',
      },
      {
        id: 'faqsPurchases07',
        question: 'Do you sell fake books/prop books/books that are blank inside?',
        answer: 'Nope! All our books are taken from the Strand’s in-store inventory. Our collections are made up of used and new books that provide both high quality shelf presence and something great to read.',
      },
      {
        id: 'faqsPurchases08',
        question: 'If I order from a collection, will I receive the books in the collection photo?',
        answer: 'The books pictured with each collection are a sample of the offerings from that category. Each new order is individually curated by our Books by the Foot experts. You can expect books of similar subject and/or look as those pictured, but each order is unique.',
      },
      {
        id: 'faqsPurchases09',
        question: 'Can I come into the store and buy a Books by the Foot collection?',
        answer: 'We’d love for you to stop by and talk to our Books by the Foot team about putting together a collection! However, each collection is curated once it’s been ordered, so we do not have pre-made Books by the Foot collections available for purchase in store.',
      },
      {
        id: 'faqsPurchases10',
        question: 'Do I need an appointment?',
        answer: 'Appointments are not necessary, but they are recommended if you would like to guarantee our availability to discuss your project.',
      },
      {
        id: 'faqsPurchases11',
        question: 'Can I choose the books that are in my collection?',
        answer: 'One of the great things about Books by the Foot is we provide a custom curated collection based on your interests. You tell us what you like, and we put together a collection you’ll love! You can choose the subjects and styles of the book in your collection, and if you have specific authors or titles that you’d like included, we’re happy to add them to your order if we have them in stock. \nHowever, if you want to personally choose all the books in your collection, that order would be processed as a standard sale, and you would pay per book at the store price. If you want to hand select a large number of books in store, we can assist you with selecting and boxing up the books.',
      },
      {
        id: 'faqsPurchases12',
        question: 'Is there a discount for bulk purchases? How about designers?',
        answer: 'All of our books are taken from our in-store stock, which is already significantly discounted, so we are unfortunately unable to offer any further discounts. Sorry!',
      },
      {
        id: 'faqsPurchases13',
        question: 'I was looking at a collection and the website says there are a certain number in stock. Is that how many books I’ll get?',
        answer: 'The “in stock” number listed on our website measures the number of feet available for online purchase in that collection. For example, if you are looking at the Fiction Classics Collection and the website says there are 33 in stock, that means there are 33 feet of Fiction Classics books available for purchase online. \nIf you’d like to purchase a greater amount of footage than what is listed as available on our website, please contact us directly at bksbythefoot@strandbooks.com or 212-660-6646.',
      },
      {
        id: 'faqsPurchases14',
        question: 'What are the shipping options?',
        answer: 'Our standard domestic shipments ship via UPS so that they are trackable and insured. We offer Domestic Standard Shipping and Domestic Express Shipping, including both Next Day Air and Second Day Air as well as International Shipping. We can ship via US Postal Service if requested or if shipping to a P.O. Box. UPS and other expedited shipping methods are not available to P.O. Boxes. \nCar delivery is available for $50 in Manhattan and to the Outer Boroughs for $75. Deliveries are available Monday through Friday. You are also welcome to arrange pick up for your order.',
      },
      {
        id: 'faqsPurchases15',
        question: 'What payment options do you accept?',
        answer: 'The Strand accepts all major credit cards, checks, cash and purchase orders. We do accept purchase orders from companies approved to bill net-30. Credit card authorization forms are processed via a secure online process. Please do not email your credit card information. If you have any questions about payment or are interested in setting up a billing account, email bksbythefoot@strandbooks.com or call us at 212-660-6646.',
      },
      {
        id: 'faqsPurchases16',
        question: 'What’s your return policy?',
        answer: 'Books with dust jackets can be returned within two weeks of receipt. A large amount of returned books may be subject to a 10% restock fee. When shipping returns, please clearly mark the packages for Books by the Foot.\nMarkdown books and books without jackets (including books by color) are final sale.',
      },
      {
        id: 'faqsPurchases17',
        question: 'What if I order online and then I need to cancel the order?',
        answer: 'Books with dust jackets can be returned within two weeks of receipt. A large amount of returned books may be subject to a 10% restock fee. When shipping returns, please clearly mark the packages for Books by the Foot. \nMarkdown books and books without jackets (including books by color) are final sale.',
      },
      {
        id: 'faqsPurchases18',
        question: 'I have more questions! How can I contact you?',
        answer: 'You can speak to us in person in our offices on the third floor of Strand Bookstore, by phone at at 212-660-6646, or via email at bksbythefoot@strandbooks.com. Our offices are open Monday through Friday, 9:30am–6:00pm.',
      },
    ],
    rentals: [
      {
        id: 'faqsRentals01',
        question: 'How do I place a Books By The Foot rental order?',
        answer: 'Orders can be placed in person in our offices on the third floor of Strand Bookstore, by phone at at 212-660-6646, or via email at bksbythefoot@strandbooks.com. Our offices are open Monday through Friday, 9:30am–6:00pm.',
      },
      {
        id: 'faqsRentals02',
        question: 'How many feet can I rent?',
        answer: 'There is no limit to how many feet a rental can be. However, larger rentals require a longer turnaround time, and orders for more than 60 feet of trade size books or more than 30 feet of oversize books will be charged a per foot larger order fee.',
      },
      {
        id: 'faqsRentals03',
        question: 'What is your turnaround time?',
        answer: 'Depending on rental size, we can put your rental together in as little as a few hours. Most rentals can be available for pickup within 24-48 hours. Rentals larger than 15 feet needed in less than 24 hours will be charged a per foot rush fee.',
      },
      {
        id: 'faqsRentals04',
        question: 'Can you customize the rental so the books are appropriate for my project?',
        answer: 'Yes - this is our specialty! We can pull books for any kind of character, from virtually any time period. Please get in touch to discuss all possible options.',
      },
      {
        id: 'faqsRentals05',
        question: 'My project is a period piece. Can I rent books from a certain time period?',
        answer: 'Yes, we have a very large vintage inventory and can create a collection from almost any time period. Please note there is an additional fee if books must be restricted to specific publication years.',
      },
      {
        id: 'faqsRentals06',
        question: 'Can I customize my rental order for a specific look?',
        answer: "Yes! Most collections of can be customized by color or style. We're happy to discuss available options for your rental. There are additional fees for customization by spine color, spine text color, height/depth restrictions, and dust jacket removal.",
      },
      {
        id: 'faqsRentals07',
        question: 'How long does my rental last?',
        answer: 'Our rentals are for one week. You may extend your rental if you need more time; the extension charge is 10% of your total rental cost for each additional week.',
      },
      {
        id: 'faqsRentals08',
        question: 'Can I pick out my own books?',
        answer: "Yes. The cost for renting books you've selected yourself is the full price of the books up front and half back upon return. For these rentals you can check out at any register; just ask to speak to a manager. You will need to return the books to a manager in person to receive your half back refund.\nClearance and final sale items are not eligible for full price, half back rentals. Paperback rentals must be approved and processed by the Books by the Foot department. To rent clearance, final sale or paperback books, please contact us at bksbythefoot@strandbooks.com or 212-660-6646.",
      },
      {
        id: 'faqsRentals09',
        question: 'What payment methods do you accept?',
        answer: 'For rentals, we accept all major credit cards. You can also pay for rentals by check or wire, but rentals will not be pulled until payment has been received. Credit card authorization forms are processed via a secure online process. Please do not email your credit card information.\nClients interested in setting up a billing account must provide credit/trade references and be approved by Books by the Foot. All terms net 30.',
      },
      {
        id: 'faqsRentals10',
        question: 'Can you deliver our rental?',
        answer: 'Rentals can be picked up at our Broadway location. At this time, we do not deliver.',
      },
      {
        id: 'faqsRentals11',
        question: 'We no longer need our book rental. Can I cancel my order for a refund?',
        answer: 'Rental orders may be cancelled until the books have been pulled for that order. Unfortunately, we are unable to cancel an order or refund you after your order has been filled.',
      },
      {
        id: 'faqsRentals12',
        question: 'I lost/damaged a book you loaned me. What now?',
        answer: 'You will be responsible for the full cost of the lost or damaged book. In the case of a multi-volume set, the entire cost of the set will be due if any volume is lost or damaged.',
      },
      {
        id: 'faqsRentals13',
        question: 'We are a small, independent production. Do you offer discounts?',
        answer: 'Due to the large demand for book rentals, we do not currently offer discounts for our rental services.',
      },
      {
        id: 'faqsRentals14',
        question: 'I need a W-9 form from you. Who do I contact to get it?',
        answer: 'Please contact Miguel Soto at 212-660-6646 or bksbythefoot@strandbooks.com.',
      },
      {
        id: 'faqsRentals15',
        question: 'May I rent books in exchange for a Strand promotional mention?',
        answer: 'Rentals for promotional exchange must be approved. Please contact our Director of Marketing and Communications at marketing@strandbooks.com.',
      },
    ],
  },
  bbtfRentalCollections: [
    {
      id: 'rentalCol01',
      colImage: urlGenerator('/S/pages/bbtf-rental-art.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-art-1.jpg', '/S/pages/bbtf-rental-art-2.jpg', '/S/pages/bbtf-rental-art-3.jpg', '/S/pages/bbtf-rental-art-4.jpg', '/S/pages/bbtf-rental-art-5.jpg', '/S/pages/bbtf-rental-art-6.jpg', '/S/pages/bbtf-rental-art-7.jpg']),
      colTitle: 'Art Books (Oversize Coffee Table Books)',
      colDesc: 'Add some art and culture to your library with a customized collection of over-sized, hardcover art books, perfect for display. We can customize by size, color and genre. Choose a subject, like Fashion, Artist Monographs, Photography, Architecture, Design, Contemporary Art, Art By Region, or do a mix of them all!',
      price: '$85.00/ft Rental',
    },
    {
      id: 'rentalCol02',
      colImage: urlGenerator('/S/pages/bbtf-rental-color.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-color-1.jpeg', '/S/pages/bbtf-rental-color-2.jpeg', '/S/pages/bbtf-rental-color-3.jpg', '/S/pages/bbtf-rental-color-4.jpg', '/S/pages/bbtf-rental-color-5.jpg', '/S/pages/bbtf-rental-color-6.jpg', '/S/pages/bbtf-rental-color-7.jpg', '/S/pages/bbtf-rental-color-8.jpg', '/S/pages/bbtf-rental-color-9.jpg', '/S/pages/bbtf-rental-color-10.jpg', '/S/pages/bbtf-rental-color-11.jpg', '/S/pages/bbtf-rental-color-12.jpg']),
      colTitle: 'Books by Color',
      colDesc: 'Hardcover books in good, clean condition. You may specify color (such as red, blue, white, black, brown, green, etc) and subjects (according to availability).\nChoose your books by color with jackets ($45/ft) or cloth or paper-bound spines without jackets ($65/ft).',
      price: '$80.00/ft Rental',
    },
    {
      id: 'rentalCol03',
      colImage: urlGenerator('/S/pages/bbtf-rental-bio.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-bio-1.jpg', '/S/pages/bbtf-rental-bio-2.jpg', '/S/pages/bbtf-rental-bio-3.jpg', '/S/pages/bbtf-rental-bio-4.jpg']),
      colTitle: 'Biographies',
      colDesc: 'A fabulous assortment of hardcover books on the lives of important figures throughout history. Read about the lives of great artists, writers, musicians, presidents, generals, or any person who catches your interest!',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol04',
      colImage: urlGenerator('/S/pages/bbtf-rental-choose.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-choose-1.jpg', '/S/pages/bbtf-rental-choose-2.jpg']),
      colTitle: 'Choose Your Subject (Over-sized Coffee Table Books)',
      colDesc: 'Customize your library with a mix of over-sized, hardcover coffee table books on your favorite topics. We have a great assortment of categories to choose from including travel, history, film and drama, sports, film & drama, cookbooks, gardening books, oceans, natural history, etc. These books are primarily visual in nature with color or black and white photography.',
      price: '$85.00/ft Rental',
    },
    {
      id: 'rentalCol05',
      colImage: urlGenerator('/S/pages/bbtf-rental-choose-trade.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-choose-trade-1.jpg', '/S/pages/bbtf-rental-choose-trade-2.jpg', '/S/pages/bbtf-rental-choose-trade-3.jpg', '/S/pages/bbtf-rental-choose-trade-4.jpg']),
      colTitle: 'Choose Your Subject (Trade-Sized)',
      colDesc: 'Customize your library with a mix of trade-sized books on all of your favorite subjects. We have an incredible array of categories to choose from such as travel, New York City, film & drama, sports, gardening books, oceans, natural history, etc. These books are primarily textual books with little to no photography.',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol06',
      colImage: urlGenerator('/S/pages/bbtf-rental-classics.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-classics-1.jpg', '/S/pages/bbtf-rental-classics-2.jpg']),
      colTitle: 'Classics for Children',
      colDesc: "Build a library collection to share with your children! We'll customize a collection of all of the beloved books of youth to inspire and grow their budding young minds! Books are hardcover and can be customized for your child's tastes.",
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol07',
      colImage: urlGenerator('/S/pages/bbtf-rental-cookbooks.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-cookbooks-1.jpg']),
      colTitle: 'Cookbooks',
      colDesc: "Whip up a batch of culinary delights after getting a collection of our trade-sized, hardcover cookbooks. You'll get a great assortment of recipe books and food writing to add to your pantry library!",
      price: '$85.00/ft Rental',
    },
    {
      id: 'rentalCol08',
      colImage: urlGenerator('/S/pages/bbtf-rental-english-antique.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-english-antique-1.jpg', '/S/pages/bbtf-rental-english-antique-2.jpg', '/S/pages/bbtf-rental-english-antique-3.jpg', '/S/pages/bbtf-rental-english-antique-4.jpg', '/S/pages/bbtf-rental-english-antique-5.jpg']),
      colTitle: 'English Language Antique Leather Books',
      colDesc: 'Beautiful antique leather books with gold tooling and marbled or gilded edges. These books are from the 18th, 19th and early 20th century and represent a variety of subjects such as history, literature and poetry.',
      price: '$150.00/ft Rental',
    },
    {
      id: 'rentalCol09',
      colImage: urlGenerator('/S/pages/bbtf-rental-faux.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-faux-1.jpg', '/S/pages/bbtf-rental-faux-2.jpg']),
      colTitle: 'Faux Leather Classics',
      colDesc: 'Hardcover reprints of classic literature and history in decorative faux-leather bindings.',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol10',
      colImage: urlGenerator('/S/pages/bbtf-rental-fiction.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-fiction-1.jpg', '/S/pages/bbtf-rental-fiction-2.jpg', '/S/pages/bbtf-rental-fiction-3.jpg', '/S/pages/bbtf-rental-fiction-4.jpg']),
      colTitle: 'Fiction Classics',
      colDesc: "An amazing assortment of collectible hardcover fiction from cherished masterpieces to modern classics. Let us know which authors or genres you love and we'll customize your collection of classic reading based on your input. We can even customize by color!",
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol11',
      colImage: urlGenerator('/S/pages/bbtf-rental-foreign-antique.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-foreign-antique-1.jpg', '/S/pages/bbtf-rental-foreign-antique-2.jpg']),
      colTitle: 'Foreign Language Antique Leather Books',
      colDesc: 'Beautiful finely-bound leather books in languages such as French, Spanish and German. Published between the 18th to early 20th century, these books feature gold tooling and marbled or gilded edges.',
      price: '$100.00/ft Rental',
    },
    {
      id: 'rentalCol12',
      colImage: urlGenerator('/S/pages/bbtf-rental-foreign-rustic.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-foreign-antique-1.jpg', '/S/pages/bbtf-rental-foreign-antique-2.jpg']),
      colTitle: 'Foreign Language Antique Leather Books (Rustic Finish)',
      colDesc: 'Antique leather bound books in used condition, dating from the 19th to early 20th century. Books are in sound condition and may be missing spine labels or have repaired hinges.',
      price: '$75.00/ft Rental',
    },
    {
      id: 'rentalCol13',
      colImage: urlGenerator('/S/pages/bbtf-rental-heritage.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-heritage-1.jpg', '/S/pages/bbtf-rental-heritage-2.jpg']),
      colTitle: 'Heritage Classics',
      colDesc: 'Classic titles from the Heritage Classics series include works from the greats of literature, history and philosophy. Authors can include Dickens, Twain, Shakespeare, etc. The books are lovely linen bound volumes, finely illustrated and often in their original slip case.',
      price: '$75.00/ft Rental',
    },
    {
      id: 'rentalCol14',
      colImage: urlGenerator('/S/pages/bbtf-rental-high-end.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-high-end-1.jpg']),
      colTitle: 'High-End Cookbooks',
      colDesc: 'For the dedicated culinary connoisseur, the best of the best in cookbooks. Get a collection of the newest, most beautiful recipe and technique books for your pantry shelf.',
      price: '$85.00/ft Rental',
    },
    {
      id: 'rentalCol15',
      colImage: urlGenerator('/S/pages/bbtf-rental-law.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-law-1.jpg', '/S/pages/bbtf-rental-law-2.jpg', '/S/pages/bbtf-rental-law-3.jpg']),
      colTitle: 'Law Books',
      colDesc: 'A wonderful assortment of hardcover leather law books.',
      price: '$45.00/ft Rental',
    },
    {
      id: 'rentalCol16',
      colImage: urlGenerator('/S/pages/bbtf-rental-classics-4.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-modern-1.jpg', '/S/pages/bbtf-rental-modern-2.jpg']),
      colTitle: 'Modern Library Classics',
      colDesc: "Enjoy the classics of history, literature, poetry, theater, and philosophy with these adorable volumes published by Random House. Billed as the “World's Best Books”, these classic tomes are an excellent value and one of our most popular sellers!",
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol17',
      colImage: urlGenerator('/S/pages/bbtf-rental-new-leather.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-new-leather-1.jpg', '/S/pages/bbtf-rental-new-leather-2.jpg']),
      colTitle: 'New Leather Classics',
      colDesc: 'Reprints of all the great classics of literature and history in beautiful full leather with gold tooling and gilded paper. These titles published by the Franklin Library and Easton Press make beautiful gifts that will be treasured always; keep them and they will be cherished books to pass on to future generations.',
      price: '$50.00/ft Rental',
    },
    {
      id: 'rentalCol18',
      colImage: urlGenerator('/S/pages/bbtf-rental-auction.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-auction-1.jpg', '/S/pages/bbtf-rental-auction-2.jpg']),
      colTitle: 'Paperback Auction Catalogues',
      colDesc: "Get an assortment of Sotheby's and Christie's paperback auction catalogs, each filled with beautiful art, photography, and incredible details about the featured artists and their works.",
      price: '$75.00/ft Rental',
    },
    {
      id: 'rentalCol19',
      colImage: urlGenerator('/S/pages/bbtf-rental-picture.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-picture-1.jpg', '/S/pages/bbtf-rental-picture-2.jpg', '/S/pages/bbtf-rental-picture-3.jpg']),
      colTitle: 'Picture Books',
      colDesc: 'Beautifully written and illustrated books for children of all ages. Get a customized collection of hardcover pictures books to read and share with your children for years to come.',
      price: '$85.00/ft Rental',
    },
    // {
    //   id: 'rentalCol20',
    //   colImage: urlGenerator('/S/pages/bbtf-rental-pocket.jpg'),
    //   galleryImages: generateURLs(['/S/pages/bbtf-rental-pocket-1.jpg', '/S/pages/bbtf-rental-pocket-2.jpg']),
    //   colTitle: 'Pocket Paperbacks',
    //   colDesc: "These small scale paperbacks are excellent reads and under 7' tall! All of these titles are classics and bestsellers with an average of 14-15 books per foot. These pocket-sized books are great travel reads and an excellent value.",
    //   price: '$35.00/ft Rental',
    // },
    {
      id: 'rentalCol21',
      colImage: urlGenerator('/S/pages/bbtf-rental-poetry.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-poetry-1.jpg']),
      colTitle: 'Poetry',
      colDesc: 'Add some poetry to your collection! Request a selection of poetry books from all your beloved bards. We can put together hardcover collections of the young, modern poets or a selection of poetry penned by the literary greats!',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol22',
      colImage: urlGenerator('/S/pages/bbtf-rental-reference.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-reference-1.jpg', '/S/pages/bbtf-rental-reference-2.jpg', '/S/pages/bbtf-rental-reference-3.jpg']),
      colTitle: 'Reference Books',
      colDesc: 'A terrific assortment of over-sized and medium-sized hardcover reference books including encyclopedias, dictionaries, thesauruses, etc. Bindings are a mix of faux-leather, cloth and dust-jacketed books.',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol23',
      colImage: urlGenerator('/S/pages/bbtf-rental-sets.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-sets-1.jpg', '/S/pages/bbtf-rental-sets-2.jpg']),
      colTitle: 'Sets',
      colDesc: 'Enjoy a collection of lovely uniform sets from a variety of subject areas, including history, biography, and literature. Books are hardcover and come in a variety of colors.',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol24',
      colImage: urlGenerator('/S/pages/bbtf-rental-strand-hardcover.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-strand-hardcover-1.jpg']),
      colTitle: 'Strand Bestsellers in Hardcover',
      colDesc: 'A great collection of our newest arrivals in hardcover, including books from the current Bestsellers List.',
      price: '$45.00/ft Rental',
    },
    {
      id: 'rentalCol25',
      colImage: urlGenerator('/S/pages/bbtf-rental-strand-paperback.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-strand-paperback-1.jpg']),
      colTitle: 'Strand Bestsellers in Paperback',
      colDesc: 'A terrific collection of our newest arrivals in paperback, including books from the current Bestsellers List.',
      price: '$45.00/ft Rental',
    },
    {
      id: 'rentalCol26',
      colImage: urlGenerator('/S/pages/bbtf-rental-textbook.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-textbook-1.jpg', '/S/pages/bbtf-rental-textbook-2.jpg', '/S/pages/bbtf-rental-textbook-3.jpg']),
      colTitle: 'Textbooks',
      colDesc: 'Give your room or set an air of the academic with one of our textbook collections. Textbooks are hardcover and come in an assortment of subjects and grade levels',
      price: '$35.00/ft Rental',
    },
    {
      id: 'rentalCol27',
      colImage: urlGenerator('/S/pages/bbtf-rental-victorian.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-victorian-1.jpg', '/S/pages/bbtf-rental-victorian-2.jpg', '/S/pages/bbtf-rental-victorian-3.jpg']),
      colTitle: 'Victorian Era Books',
      colDesc: 'Colorful cloth-bound hardcover titles in a wide range of subjects popular during the late 19th century, including poetry, histories and novels.',
      price: '$85.00/ft Rental',
    },
    {
      id: 'rentalCol28',
      colImage: urlGenerator('/S/pages/bbtf-rental-vintage.jpg'),
      galleryImages: generateURLs(['/S/pages/bbtf-rental-vintage-1.jpg', '/S/pages/bbtf-rental-vintage-2.jpg', '/S/pages/bbtf-rental-vintage-3.jpg', '/S/pages/bbtf-rental-vintage-4.jpg']),
      colTitle: 'Vintage Cloth Classics',
      colDesc: 'Enjoy the classics of literature, history, poetry and philosophy in cute vintage cloth bindings.',
      price: '$35.00/ft Rental',
    },
  ],
  bbtfGallery: [
    {
      id: 'gallery01',
      image: urlGenerator('/S/pages/bbtf-gallery-1.png'),
      review: null,
    },
    {
      id: 'gallery02',
      image: urlGenerator('/S/pages/bbtf-gallery-2.jpg'),
      review: null,
    },
    {
      id: 'gallery03',
      image: urlGenerator('/S/pages/bbtf-gallery-3.jpg'),
      review: null,
    },
    {
      id: 'gallery04',
      image: urlGenerator('/S/pages/bbtf-gallery-4.jpg'),
      review: null,
    },
    {
      id: 'gallery05',
      image: urlGenerator('/S/pages/bbtf-gallery-5.jpg'),
      review: null,
    },
    {
      id: 'gallery06',
      image: urlGenerator('/S/pages/bbtf-gallery-6.jpg'),
      review: null,
    },
    {
      id: 'gallery07',
      image: urlGenerator('/S/pages/bbtf-gallery-7.jpg'),
      review: null,
    },
    {
      id: 'gallery08',
      image: urlGenerator('/S/pages/bbtf-gallery-8.jpg'),
      review: null,
    },
    {
      id: 'gallery09',
      image: urlGenerator('/S/pages/bbtf-gallery-9.jpg'),
      review: null,
    },
    {
      id: 'gallery10',
      image: urlGenerator('/S/pages/bbtf-gallery-10.jpg'),
      review: null,
    },
    {
      id: 'gallery11',
      image: urlGenerator('/S/pages/bbtf-gallery-11.jpg'),
      review: null,
    },
    {
      id: 'gallery12',
      image: urlGenerator('/S/pages/bbtf-gallery-12.jpg'),
      review: null,
    },
    {
      id: 'gallery13',
      image: urlGenerator('/S/pages/bbtf-gallery-13.jpg'),
      review: null,
    },
    {
      id: 'gallery14',
      image: urlGenerator('/S/pages/bbtf-gallery-14.jpg'),
      review: null,
    },
    {
      id: 'gallery15',
      image: urlGenerator('/S/pages/bbtf-gallery-15.jpg'),
      review: null,
    },
    {
      id: 'gallery16',
      image: urlGenerator('/S/pages/bbtf-gallery-16.jpg'),
      review: null,
    },
    {
      id: 'gallery17',
      image: urlGenerator('/S/pages/bbtf-gallery-17.jpg'),
      review: null,
    },
    {
      id: 'gallery18',
      image: urlGenerator('/S/pages/bbtf-gallery-18.jpg'),
      review: null,
    },
    {
      id: 'gallery19',
      image: urlGenerator('/S/pages/bbtf-gallery-19.jpg'),
      review: null,
    },
    {
      id: 'gallery20',
      image: urlGenerator('/S/pages/bbtf-gallery-20.jpg'),
      review: null,
    },
    {
      id: 'gallery21',
      image: urlGenerator('/S/pages/bbtf-gallery-21.jpg'),
      review: null,
    },
    {
      id: 'gallery22',
      image: urlGenerator('/S/pages/bbtf-gallery-22.jpg'),
      review: null,
    },
    {
      id: 'gallery23',
      image: urlGenerator('/S/pages/bbtf-gallery-23.jpg'),
      review: null,
    },
    {
      id: 'gallery24',
      image: urlGenerator('/S/pages/bbtf-gallery-24.jpg'),
      review: null,
    },
    {
      id: 'gallery25',
      image: urlGenerator('/S/pages/bbtf-gallery-25.jpg'),
      review: null,
    },
    {
      id: 'gallery26',
      image: urlGenerator('/S/pages/bbtf-gallery-26.jpg'),
      review: null,
    },
    {
      id: 'gallery27',
      image: urlGenerator('/S/pages/bbtf-gallery-27.jpg'),
      review: null,
    },
    {
      id: 'gallery28',
      image: urlGenerator('/S/pages/bbtf-gallery-28.jpg'),
      review: null,
    },
    {
      id: 'gallery29',
      image: urlGenerator('/S/pages/bbtf-gallery-29.jpg'),
      review: null,
    },
    {
      id: 'gallery30',
      image: urlGenerator('/S/pages/bbtf-gallery-30.jpg'),
      review: null,
    },
    {
      id: 'gallery31',
      image: urlGenerator('/S/pages/bbtf-gallery-31.jpg'),
      review: null,
    },
    {
      id: 'gallery32',
      image: urlGenerator('/S/pages/bbtf-gallery-32.jpg'),
      review: null,
    },
    {
      id: 'gallery33',
      image: urlGenerator('/S/pages/bbtf-gallery-33.jpg'),
      review: null,
    },
    {
      id: 'gallery34',
      image: urlGenerator('/S/pages/bbtf-gallery-34.jpg'),
      review: null,
    },
    {
      id: 'gallery35',
      image: urlGenerator('/S/pages/bbtf-gallery-35.jpg'),
      review: null,
    },
    {
      id: 'gallery36',
      image: urlGenerator('/S/pages/bbtf-gallery-36.jpg'),
      review: '',
    },
    {
      id: 'gallery37',
      image: urlGenerator('/S/pages/bbtf-gallery-37.jpg'),
      review: null,
    },
    {
      id: 'gallery38',
      image: urlGenerator('/S/pages/bbtf-gallery-38.jpg'),
      review: null,
    },
    {
      id: 'gallery39',
      image: urlGenerator('/S/pages/bbtf-gallery-39.jpg'),
      review: null,
    },
    {
      id: 'gallery40',
      image: urlGenerator('/S/pages/bbtf-gallery-40.jpg'),
      review: null,
    },
    {
      id: 'gallery41',
      image: urlGenerator('/S/pages/bbtf-gallery-41.jpg'),
      review: null,
    },
    {
      id: 'gallery42',
      image: urlGenerator('/S/pages/bbtf-gallery-42.jpg'),
      review: null,
    },
    {
      id: 'gallery44',
      image: urlGenerator('/S/pages/bbtf-gallery-44.jpg'),
      review: null,
    },
    {
      id: 'gallery45',
      image: urlGenerator('/S/pages/bbtf-gallery-45.jpg'),
      review: null,
    },
    {
      id: 'gallery46',
      image: urlGenerator('/S/pages/bbtf-gallery-46.jpg'),
      review: null,
    },
    {
      id: 'gallery47',
      image: urlGenerator('/S/pages/bbtf-gallery-47.jpg'),
      review: null,
    },
    {
      id: 'gallery48',
      image: urlGenerator('/S/pages/bbtf-gallery-48.jpg'),
      review: null,
    },
    {
      id: 'gallery49',
      image: urlGenerator('/S/pages/bbtf-gallery-49.jpg'),
      review: null,
    },
    {
      id: 'gallery50',
      image: urlGenerator('/S/pages/bbtf-gallery-50.jpg'),
      review: null,
    },
    // {
    //   id: 'gallery51',
    //   image: urlGenerator('/S/pages/bbtf-gallery-51.jpg'),
    //   review: null,
    // },
    {
      id: 'gallery52',
      image: urlGenerator('/S/pages/bbtf-gallery-52.jpg'),
      review: null,
    },
    {
      id: 'gallery53',
      image: urlGenerator('/S/pages/bbtf-gallery-53.jpg'),
      review: null,
    },
    {
      id: 'gallery54',
      image: urlGenerator('/S/pages/bbtf-gallery-54.jpg'),
      review: null,
    },
    {
      id: 'gallery55',
      image: urlGenerator('/S/pages/bbtf-gallery-55.jpg'),
      review: null,
    },
    {
      id: 'gallery56',
      image: urlGenerator('/S/pages/bbtf-gallery-56.jpg'),
      review: null,
    },
    {
      id: 'gallery57',
      image: urlGenerator('/S/pages/bbtf-gallery-57.jpg'),
      review: null,
    },
    {
      id: 'gallery58',
      image: urlGenerator('/S/pages/bbtf-gallery-58.jpg'),
      review: null,
    },
    {
      id: 'gallery59',
      image: urlGenerator('/S/pages/bbtf-gallery-59.jpg'),
      review: null,
    },
    {
      id: 'gallery60',
      image: urlGenerator('/S/pages/bbtf-gallery-60.jpg'),
      review: null,
    },
    {
      id: 'gallery61',
      image: urlGenerator('/S/pages/bbtf-gallery-61.jpg'),
      review: null,
    },
    {
      id: 'gallery62',
      image: urlGenerator('/S/pages/bbtf-gallery-62.jpg'),
      review: null,
    },
    {
      id: 'gallery63',
      image: null,
      review: "The Wing was lucky to work with a brilliant team of women at The Strand to compile a truly impressive collection of new and used books that suit The Wing’s style and decor, as well as it’s mission. The Wing's Lady Library contains over 2,000 books that are diverse in subject and genre and as a collection represent inventive, curious, rule-breaking women.\n- Audrey Gelman, The Wing, Co-Founder & CEO",
    },
    {
      id: 'gallery64',
      image: null,
      review: 'I worked with the Strand for a few years now, they have a great selection books either to rent or purchase. They are reliable, fast and have great customer service. I recommend their services either for personal use or professional. I love their books by the foot!\n- Anne Boccio, Visual Project Manager, Club Monaco',
    },
    {
      id: 'gallery65',
      image: null,
      review: 'The collection was intended to be accessible but somewhat out of sight. That changed when the books arrived. They are now proudly and prominently displayed.\n- Amy Blumenthal, Books by the Foot Customer',
    },
    {
      id: 'gallery66',
      image: null,
      review: "As an Art Dept. Coordinator on television commercials, I have worked with the Strand Bookstore many times when I've needed books as set decoration. Whether the need is for beautiful art books or a family bookshelf, the staff at Strand have a 6th sense when it comes to putting together a book “package” so it can all be done with a phone call, and quickly.\n- William Hunter, USA Local 829 Art Department Coordinator",
    },
    {
      id: 'gallery67',
      image: null,
      review: 'We have been using Strand books for over 30 years. It is the one place for book rentals or purchases on specific topics or large quantities. Their staff will research it and have it ready to be picked within hours; and in our business they need to be right, look right, and be ready!\n- Andrew Humphries, General Manager, Four Seasons Resort Nevis',
    },
  ],
  seen: [
    {
      id: 'seen1',
      type: 'commercial',
      name: 'Bar NaNa',
      image: '',
    },
    {
      id: 'seen2',
      type: 'commercial',
      name: 'Faena Hotels',
      image: '',
    },
    {
      id: 'seen3',
      type: 'commercial',
      name: 'Fitzpatrick Hotel Group',
      image: '',
    },
    {
      id: 'seen4',
      type: 'commercial',
      name: 'Four Season Resort Nevis',
      image: '',
    },
    {
      id: 'seen5',
      type: 'commercial',
      name: 'Frette',
      image: '',
    },
    {
      id: 'seen6',
      type: 'commercial',
      name: 'Langham Place',
      image: '',
    },
    {
      id: 'seen7',
      type: 'commercial',
      name: 'Library Hotel',
      image: '',
    },
    {
      id: 'seen8',
      type: 'commercial',
      name: 'NYLO Hotels',
      image: '',
    },
    {
      id: 'seen9',
      type: 'commercial',
      name: 'Rag & Bone',
      image: '',
    },
    {
      id: 'seen11',
      type: 'commercial',
      name: 'Rittenhaus Hotel',
      image: '',
    },
    {
      id: 'seen12',
      type: 'commercial',
      name: 'Stumptown Coffee Roasters',
      image: '',
    },
    {
      id: 'seen13',
      type: 'commercial',
      name: 'Sullivan Street Bakery',
      image: '',
    },
    {
      id: 'seen14',
      type: 'commercial',
      name: 'The Sterling Mason Condominium',
      image: '',
    },
    {
      id: 'seen15',
      type: 'commercial',
      name: 'Waldorf-Astoria Hotel',
      image: '',
    },
    {
      id: 'seen16',
      type: 'commercial',
      name: 'West Elm',
      image: '',
    },
    {
      id: 'seen17',
      type: 'commercial',
      name: 'WeWork',
      image: '',
    },
    {
      id: 'seen18',
      type: 'commercial',
      name: 'The Wing',
      image: '',
    },
    {
      id: 'seen19',
      type: 'interior',
      name: 'Daniel Roumaldez Architects',
      image: '',
    },
    {
      id: 'seen20',
      type: 'interior',
      name: 'Daniel Scotti Design',
      image: '',
    },
    {
      id: 'seen21',
      type: 'interior',
      name: 'Fogarty Finger Architects',
      image: '',
    },
    {
      id: 'seen22',
      type: 'interior',
      name: 'Mark Hampton LLC',
      image: '',
    },
    {
      id: 'seen23',
      type: 'interior',
      name: 'Meyer Davis Studio',
      image: '',
    },
    {
      id: 'seen24',
      type: 'interior',
      name: 'SR Gambrel',
      image: '',
    },
    {
      id: 'seen25',
      type: 'movies',
      name: '13 Going On 30',
      image: '',
    },
    {
      id: 'seen26',
      type: 'movies',
      name: 'Beautiful Mind',
      image: '',
    },
    {
      id: 'seen27',
      type: 'movies',
      name: 'Birdman',
      image: '',
    },
    {
      id: 'seen28',
      type: 'movies',
      name: 'Black Swan',
      image: '',
    },
    {
      id: 'seen29',
      type: 'movies',
      name: 'Blue Jasmine',
      image: '',
    },
    {
      id: 'seen30',
      type: 'movies',
      name: 'Burn After Reading',
      image: '',
    },
    {
      id: 'seen31',
      type: 'movies',
      name: "Dallas Buyer's Club",
      image: '',
    },
    {
      id: 'seen32',
      type: 'movies',
      name: 'Extremely Loud and Incredibly Close',
      image: '',
    },
    {
      id: 'seen33',
      type: 'movies',
      name: 'Hitch',
      image: '',
    },
    {
      id: 'seen34',
      type: 'movies',
      name: 'How to Lose a Guy in 10 Days',
      image: '',
    },
    {
      id: 'seen35',
      type: 'movies',
      name: 'Indiana Jones',
      image: '',
    },
    {
      id: 'seen36',
      type: 'movies',
      name: 'SR Gambrel',
      image: '',
    },
    {
      id: 'seen37',
      type: 'movies',
      name: 'Inside Llewyn Davis',
      image: '',
    },
    {
      id: 'seen38',
      type: 'movies',
      name: "It's Complicated",
      image: '',
    },
    {
      id: 'seen39',
      type: 'movies',
      name: 'Julie & Julia',
      image: '',
    },
    {
      id: 'seen40',
      type: 'movies',
      name: "Ocean's 8",
      image: '',
    },
    {
      id: 'seen41',
      type: 'movies',
      name: 'Royal Tenenbaums',
      image: '',
    },
    {
      id: 'seen42',
      type: 'movies',
      name: 'School of Rock',
      image: '',
    },
    {
      id: 'seen43',
      type: 'movies',
      name: 'Sex and the City',
      image: '',
    },
    {
      id: 'seen44',
      type: 'movies',
      name: 'Sex and the City II',
      image: '',
    },
    {
      id: 'seen45',
      type: 'movies',
      name: 'Shutter Island',
      image: '',
    },
    {
      id: 'seen46',
      type: 'movies',
      name: "Something's Gotta Give",
      image: '',
    },
    {
      id: 'seen47',
      type: 'movies',
      name: 'The Devil Wears Prada',
      image: '',
    },
    {
      id: 'seen48',
      type: 'movies',
      name: 'The Wolf of Wall Street',
      image: '',
    },
    {
      id: 'seen49',
      type: 'movies',
      name: 'Vanilla Sky',
      image: '',
    },
    {
      id: 'seen50',
      type: 'movies',
      name: "You've Got Mail",
      image: '',
    },
    {
      id: 'seen51',
      type: 'tv',
      name: '30 Rock',
      image: '',
    },
    {
      id: 'seen52',
      type: 'tv',
      name: 'Blacklist',
      image: '',
    },
    {
      id: 'seen53',
      type: 'tv',
      name: 'Broad City',
      image: '',
    },
    {
      id: 'seen54',
      type: 'tv',
      name: 'Colbert Report',
      image: '',
    },
    {
      id: 'seen55',
      type: 'tv',
      name: 'Girls',
      image: '',
    },
    {
      id: 'seen56',
      type: 'tv',
      name: 'Good Wife',
      image: '',
    },
    {
      id: 'seen57',
      type: 'tv',
      name: 'Gossip Girl',
      image: '',
    },
    {
      id: 'seen58',
      type: 'tv',
      name: 'Gotham',
      image: '',
    },
    {
      id: 'seen59',
      type: 'tv',
      name: 'Late Night with Seth Myers',
      image: '',
    },
    {
      id: 'seen60',
      type: 'tv',
      name: 'Law & Order',
      image: '',
    },
    {
      id: 'seen61',
      type: 'tv',
      name: 'Louie',
      image: '',
    },
    {
      id: 'seen62',
      type: 'tv',
      name: 'Master of None',
      image: '',
    },
    {
      id: 'seen63',
      type: 'tv',
      name: 'Mr. Robot',
      image: '',
    },
    {
      id: 'seen64',
      type: 'tv',
      name: 'Orange is the New Black',
      image: '',
    },
    {
      id: 'seen65',
      type: 'tv',
      name: 'Saturday Night Live',
      image: '',
    },
    {
      id: 'seen66',
      type: 'tv',
      name: 'Sex and the City',
      image: '',
    },
    {
      id: 'seen67',
      type: 'tv',
      name: 'The Good Wife',
      image: '',
    },
    {
      id: 'seen68',
      type: 'tv',
      name: 'Unbreakable Kimmy Schmidt',
      image: '',
    },
    {
      id: 'seen69',
      type: 'tv',
      name: 'Younger',
      image: '',
    },
    {
      id: 'seen70',
      type: 'theater',
      name: 'The Under The Radar Festival At The Public Theater',
      image: '',
    },
    {
      id: 'seen71',
      type: 'theater',
      name: '33 Variations',
      image: '',
    },
    {
      id: 'seen72',
      type: 'theater',
      name: 'God of Carnage',
      image: '',
    },
    {
      id: 'seen73',
      type: 'theater',
      name: 'The Philanthropist',
      image: '',
    },
    {
      id: 'seen74',
      type: 'theater',
      name: 'Tin Pan Alley Rag',
      image: '',
    },
    {
      id: 'seen75',
      type: 'theater',
      name: 'Wicked',
      image: '',
    },
  ],
  booksColor: [
    '9789111979030',
    '9789111979047',
    '9789111979061',
    '9789111979078',
    '9789111979085',
    '9789111979092',
    '9789111979108',
    '9789111979115',
    '9789111979122',
    '9789111979139',
    '9789111979146',
    '9789112136814',
    '9789112136838',
    '9789112136845',
    '9789112136852',
    '9789112136876',
    '2900012880028',
    '2900012880035',
    '2900012880059',
    '2900012880899',
    '2900012880912',
    '2900012880936',
    '9789111933735',
    '9789111933742',
    '2900012885467',
    '2900012958260',
    '9789111933720',
    '2900012958277',
  ],
  booksStyle: [
    '9789112001402',
    '9789112001419',
    '9789112001433',
    '9789112001440',
    '9789112001525',
    '9789112001549',
    '9789112001587',
    '9789112001617',
    '9789112001648',
    '9789112001693',
    '9789112001723',
    '9789112001730',
    '9789112001747',
    '9789112001761',
    '9789112087604',
    '9789112002461',
  ],
  booksSubject: [
    '2900012741466',
    '2900012741473',
    '2900012787006',
    '2900012787013',
    '2900012787020',
    '2900012958345',
    '2900012958352',
    '2900012958369',
    '9789112001495',
    '9789112001501',
    '9789112001532',
    '9789112001556',
    '9789112001563',
    '9789112001600',
    '9789112001624',
    '9789112001631',
    '9789112001655',
    '9789112001662',
    '9789112001679',
    '9789112001709',
    '9789112001716',
    '9789112001778',
    '9789112001921',
    '9789112001952',
    '2900012851295',
    '2900012879763',
    '2900012879787',
    '2900012879961',
    '2900012958376',
  ],
};

export default bbtfList;
