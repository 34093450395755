import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../loading/Loading';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutBack from './CheckoutBack';
import CheckoutSnackbar from './CheckoutSnackbar';
import use4Cite from '../../hooks/use4Cite';
import './_checkoutPaymentShipping.scss';

export const UPDATE_CHECKOUT = gql`
  mutation updateCheckout($submit: CheckoutTransactionIn!){
    checkoutUpdate(submit: $submit){
      alts{
        canPo
        addr{
          addr1
          addr2
          addressId
          attention
          city
          countryCode
          postalCode
          state
        }
        email
        payment{
          balanceStv
          display
          paymentType
        }
        shipMethod{
          addPriceStv
          basePriceStv
          longName
          shipMethodId
          shortName
          transitInfo
        }
      }
      billAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipMethod{
        addPriceStv
        basePriceStv
        longName
        shortName
        transitInfo
        shipMethodId
      }
      contact{
        billEmail
        phone
        phoneSms
        shipEmail
      }
      tally{
        discountApplyStv
        dueStv
        insufficientFunds
        itemCount
        productStv
        shippingStv
        taxRate
        taxStv
        vatRate
        vatStv
        taxableStv
        totalStv
      }
      completeInfo
      specialInstructions
      deliveryInstructions
      checkoutId
      complete
      taxRate
    }
  }
`;

const useStyles = makeStyles({
  rootIconBtn: {
    color: '#ffffff',
    // color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '18px',
    color: '#ffffff',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
    },
  },
  rootIconBtnRed: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  rootTextField: {
    width: '100%',
    maxWidth: '500px',
    margin: '10px 0',
  },
  rootCheckbox: {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedCheckbox: {
    color: '#af272f !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  checkedRadio: {
    color: '#af272f !important',
  },
  colorSecondaryRadio: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
});

const CheckoutShipMethod = (props) => {
  const {
    setStepper,
    currentStep,
    checkoutState,
    setCheckout,
    handleReview,
    handleBack,
    checkoutError,
    overweightState,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  // const {
  //   alts,
  //   shipAddr,
  //   billAddr,
  //   taxRate,
  //   checkoutId,
  // } = checkoutDetails.beginData.checkoutBegin;

  const [checkoutUpdate, {
    data: updateData,
    loading: updateLoading,
    error: updateError,
  }] = useMutation(UPDATE_CHECKOUT);

  useEffect(() => {
    checkoutUpdate({ variables: { submit: {} } });
  }, [checkoutUpdate]);

  use4Cite();

  const classes = useStyles();

  if (updateError) {
    return <Redirect to={{ pathname: '/error', state: { updateError } }} />;
  }

  if (updateLoading) {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }

  // if (updateData && updateData.checkoutUpdate && updateData.checkoutUpdate.alts && updateData.checkoutUpdate.alts.shipMethod && updateData.checkoutUpdate.alts.shipMethod.length) {
  //   console.log('reach conditional');
  //   if (updateData.checkoutUpdate.alts.shipMethod.filter(method => method.shortName === 'Intl Standard').length) {
  //     const filterShipMethod = updateData.checkoutUpdate.alts.shipMethod.filter(method => method.shortName === 'Intl Standard')[0];

  //     setCheckout({
  //       ...checkoutState,
  //       shipMethod: filterShipMethod.shipMethodId,
  //       shipBase: filterShipMethod.basePriceStv,
  //       shipAdd: filterShipMethod.addPriceStv,
  //     });
  //   } else if (updateData.checkoutUpdate.alts.shipMethod.filter(method => method.shortName === 'USPS Economy').length > 0) {
  //     const economyShipMethod = updateData.checkoutUpdate.alts.shipMethod.filter(method => method.shortName === 'USPS Economy')[0];
  //     console.log('economy shipping available', economyShipMethod);
  //     setCheckout({
  //       ...checkoutState,
  //       shipMethod: economyShipMethod.shipMethodId,
  //       shipBase: economyShipMethod.basePriceStv,
  //       shipAdd: economyShipMethod.addPriceStv,
  //     });
  //   }
  // }

  // console.log('shipmethodupdate', updateData);

  const handleMethod = (e) => {
    setCheckout({
      ...checkoutState,
      shipMethod: e.target.value,
      shipBase: updateData.checkoutUpdate.alts.shipMethod
        .filter(method => method.shipMethodId === e.target.value)[0].basePriceStv,
      shipAdd: updateData.checkoutUpdate.alts.shipMethod
        .filter(method => method.shipMethodId === e.target.value)[0].addPriceStv,
      noShipMethod: false,
    });
  };

  return (
    <div className={!match767 ? 'checkoutpaymentshipping-container__div--mobile' : 'checkoutpaymentshipping-container__div'}>
      {
        !match767
          && (
            <div className="checkoutpaymentshipping-cont-btn__div">
              <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>Continue</Button>
            </div>
          )
      }
      <main className="checkoutpaymentshipping-info__main" aria-labelledby="checkoutpaymentshipping-title">
        <CheckoutBack handleBack={handleBack} />
        {
          updateData
            && updateData.checkoutUpdate.alts.shipMethod.length
            && (
              <section className="checkoutpaymentshipping-method__section">
                { /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */ }
                <h3 id="checkoutpaymentshipping-title" className="checkoutpaymentshipping-header__h3" tabIndex="0">Shipping Method</h3>
                  {
                    checkoutError
                    && checkoutError.graphQLErrors
                    && (
                      <CheckoutSnackbar
                        open
                        message={checkoutError || {}}
                        noShipMethod={checkoutState.noShipMethod}
                      />
                    )
                  }
                  {
                    checkoutState.noShipDirty
                    && checkoutState.noShipMethod
                    && (
                      <CheckoutSnackbar
                        open
                        message={{ graphQLErrors: [{ message: 'Please select a shipping method', pathf: [''] }] }}
                        noShipMethod={checkoutState.noShipMethod}
                      />
                    )
                  }
                <div className="checkoutpaymentshipping-methods__div">
                  {
                    updateData.checkoutUpdate.alts.shipMethod
                      .filter(method => method.shortName !== 'SPU')
                      .map(method => (
                        <div key={method.shipMethodId} className="checkoutpaymentshipping-method-details__div">
                          <Radio
                            value={method.shipMethodId}
                            inputProps={{ 'aria-label': method.longName }}
                            onChange={handleMethod}
                            checked={checkoutState.shipMethod === method.shipMethodId}
                            classes={{
                              checked: classes.checkedRadio,
                              colorSecondary: classes.colorSecondaryRadio,
                            }}
                            disableRipple
                            disableFocusRipple
                          />
                          <div>
                            {`${method.longName} (+$${(method.basePriceStv * 0.01).toFixed(2)} additional item: +$${(method.addPriceStv * 0.01).toFixed(2)})`}
                            <p style={{ color: 'red', display: method.longName.includes('International') ? 'block' : 'none' }}>
                              Shipping costs to Australia may be higher than what is listed.
                              A store staff member will reach out to you if so.
                            </p>

                          </div>
                        </div>
                      ))
                  }
                </div>
              </section>
            )
        }
      </main>
      { !match767 && <div className="checkoutpaymentshipping-divider__div" />}
      <aside data-testid="checkoutordersummary" className="checkoutpaymentshipping-order-summary__aside">
        <CheckoutOrderSummary
          taxRate={0}
          currentStep={currentStep}
          setStepper={setStepper}
          handleReview={handleReview}
          shipBase={checkoutState.shipBase}
          shipAdd={checkoutState.shipAdd}
          overweightState={overweightState}
          tally={updateData?.checkoutUpdate?.tally || {}}
        />
      </aside>
    </div>
  );
};

CheckoutShipMethod.propTypes = {
  setStepper: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  setCheckout: PropTypes.func.isRequired,
  handleReview: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  checkoutError: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/require-default-props
  overweightState: PropTypes.bool,
};

export default CheckoutShipMethod;
