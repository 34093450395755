/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import CompFab from '../compFab/CompFab';
import CompBack from '../compBack/CompBack';
import bookshelfAuthorsList from './bookshelfAuthorsList';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import metaListDictionary from '../../helpers/metaListDictionary';
import use4Cite from '../../hooks/use4Cite';
import './_singleAuthorsBookshelves.scss';

export const GET_METALIST = gql`
query metaList($metaTag: String!){
    metaListDetail(metaTag: $metaTag){
      metaTag
      product{
        title
        gtin13
        id
        imageUrl
        originator
        retailStv
        inventoryOverview{
          salePriceMaxStv
          salePriceMinStv
        }
      }
    }
}
`;

const SingleAuthorsBookshelves = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match450 = useMediaQuery('(min-width: 450px)');
  const scroll = useScrollY();
  const { match } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    loading,
    error,
    data,
  } = useQuery(GET_METALIST,
    { variables: { metaTag: metaListDictionary(match.params.author).metaTag } });

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (error) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  if (data && data.metaListDetail && data.metaListDetail.product && data.metaListDetail.product.length < 1) return <Redirect to="/page-not-found" />;

  const authorInfo = bookshelfAuthorsList.bookshelves
    .filter(bookshelf => metaListDictionary(match.params.author).metaTag === bookshelf.metaTag)[0];

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="singleauthorsbookshelf-container__div">
        <main className="singleauthorsbookshelf-content__main">
          { scroll > 1000 && <CompFab /> }
          <div className="singleauthorsbookshelf-content__div">
            <div className="singleauthorsbookshelf-image__div">
              <img
                src={match450 ? urlGenerator('/S/pages/ph-abookshelf-AuthorsBookshelf_LandingPage.png') : urlGenerator('/S/pages/ph-abookshelf-AuthorsBookshelf_Mobile.png')}
                alt="Author's Bookshelves"
                className="singleauthorsbookshelf-image__img"
              />
            </div>
            <section className="singleauthorsbookshelf-feature__section">
              <div className="singleauthorsbookshelf-feature__div">
                <div className="singleauthorsbookshelf-back__div">
                  <CompBack backURL="/books-media/authors-bookshelf" />
                </div>
                <div className="singleauthorsbookshelf-feat-image__div">
                  <img
                    className="singleauthorsbookshelf-feat-image__img"
                    src={authorInfo.imgPath}
                    alt={authorInfo.name}
                  />
                </div>
                <div className="singleauthorsbookshelf-credit__div">
                  {authorInfo.photoCred.length > 0 && <p className="singleauthorsbookshelf-credit__p">{`© ${authorInfo.photoCred}`}</p>}
                </div>
                <div className="singleauthorsbookshelf-header__div singleauthorsbookshelf-header-name__div">
                  <h3 className="singleauthorsbookshelf-header__h3">{authorInfo.name}</h3>
                </div>
                <div className="singleauthorsbookshelf-feat-details__div">
                  <div
                    dangerouslySetInnerHTML={
                    (() => ({ __html: authorInfo.bio }))()
                }
                  />
                </div>
              </div>
            </section>
            <div className="singleauthorsbookshelves-border__div" />
            <section className="singleauthorsbookshelf-bookshelf__section">
              <div className="singleauthorsbookshelf-bookshelf__div">
                <div className="singleauthorsbookshelf-header__div">
                  <PageSubHeader
                    header={metaListDictionary(match.params.author).alt
                      ? metaListDictionary(match.params.author).alt
                      : metaListDictionary(match.params.author).metaTag
                    }
                  />
                </div>
                <div className="singleauthorsbookshelf-items__div">
                  {
                    data
                     && data.metaListDetail
                     && data.metaListDetail.product
                     && data.metaListDetail.product
                       .filter(item => item)
                       .map((item) => {
                         const {
                           id,
                           title,
                           originator,
                           imageUrl,
                           gtin13,
                         } = item;

                         return (
                           <div className="singleauthorsbookshelf-item__div" key={id}>
                             <Link
                               to={`/product/${gtin13}`}
                               onClick={() => WithEvent('AUTHOR_BOOKSHELF', `Clicked ${title}`, 'CLICK_ITEM', 1)}
                             >
                               <div className="singleauthorsbookshelf-item-image__div">
                                 <img className="singleauthorsbookshelf-item-image__img" src={imageUrl} alt={title} />
                               </div>
                             </Link>
                             <div className="singleauthorsbookshelf-details__div">
                               <h4 className="singleauthorsbookshelf-header__h4">{title}</h4>
                               <p className="singleauthorsbookshelf-details__p">
                                 {`by ${originator}`}
                               </p>
                             </div>
                           </div>
                         );
                       })
                  }
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

SingleAuthorsBookshelves.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default SingleAuthorsBookshelves;
