import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Loading from '../loading/Loading';
import InPageLoading from '../loading/InPageLoading';
import Error from '../error/Error';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import { useCartSnackbar } from '../cartSnackbar/CartSnackbarProvider';
import CompFab from '../compFab/CompFab';
import CatalogInfo from './CatalogInfo';
import CompModal from '../compModal/CompModal';
import useScrollY from '../../hooks/useScrollY';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import 'array-flat-polyfill';
import locationsDictionary, { locationsNonPhysical } from '../../helpers/locationsDictionary';
import WithEvent, { WithFBEvent } from '../withEvent/WithEvent';
import onlyOnePromo from '../../helpers/promoLists';
import use4Cite from '../../hooks/use4Cite';
import atcUpdate from '../../helpers/atcUpdate';
import './_updatedSingleProductPage.scss';


export const GET_SINGLE_PRODUCT = gql`
query getSingleProduct($ean: String!){
  productDetail(ean: $ean){
     bookBinding
     bookFormat
     copyright
     description
     gtin13
     id
     apparel{
      apparelId
      size
      sizeDesc
      inventory{
        catalogId
        condition
        sku
        onHand
        salePriceStv
      }
    }
     imageUrl
     webDescription
     inventoryOverview{
       conditionList
       discountMaxPct
       discountMinPct
       locationList
       onHand
       salePriceMaxStv
       salePriceMinStv
       sectionList
       signedList
     }
     inventory{
       condition
       discountPct
       edition
       isTaxable
       jacketCondition
       location
       onHand
       salePrice
       salePriceStv
       section
       signed
       sku
       store
       catalogId
     }
     isbn10
     originDate
     originator
     outOfPrint
     prop{
       edition
       heightIn00
       noShipExpedited
       noShipInternational
       pagecount
       publishPlace
       thicknessIn00
       universityPress
       weightOz00
       weightLb00
       widthIn00
     }
     publisher
     retailPrice
     retailStv
     salePriceMax
     salePriceMin
     title
  }
  productDetailAux(ean: $ean){
    annotation
    contributors
    description
    dimensions
    ean
    jacket
    publisherImprint
  }
}
`;

export const ADD_ITEM_CART = gql`
  mutation addItemCart($cartItemInput: CartItemInput!){
    cartItemAdd(cartItemInput: $cartItemInput){
      cartId
      cartItemId
      cartPriceStv
      catalogId
      description
      gtin
      isTaxable
      qty
      retailStv
      salePriceStv
      sku
      productDetail{
        gtin13
        id
        imageUrl
        isbn10
        title
        originator
        bookBinding
        inventory{
          sku
          onHand
          catalogId
          signed
        }
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            sku
            onHand
            salePriceStv
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  colorSecondaryRadio: {
    color: '#af272f !important',
  },
  rootButton: {
    backgroundColor: '#af272f',
    fontFamily: ['Trade Gothic LT Std', 'Proxima Nova, sans-serif', 'Roboto', 'Helvetica Neue', 'Arial'],
    textTransform: 'none',
    color: '#ffffff',
    minWidth: '100px',
    fontSize: '15px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#d8341e',
    },
  },
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
  },
  rootProductIconButton: {
    color: '#af272f',
    cursor: 'zoom-in',
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
  },
  rootAnnoIconButton: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '15px',
  },
  rootTime: {
    paddingRight: '3px',
  },
});

const SingleProductPage = (props) => {
  const {
    match,
    cartList: upperCartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const classes = useStyles();
  const { ean } = match.params;
  const scroll = useScrollY();

  const {
    compSwitchState: infoState,
    handleCompOpen: handleInfoOpen,
    handleCompClose: handleInfoClose,
  } = useOpenCloseComponent();

  // const {
  //   compSwitchState: descCollapseState,
  //   handleCompOpen: handleDescCollapseOpen,
  //   handleCompClose: handleDescCollapseClose,
  //   handleCompToggle: handleDescCollapseToggle,
  // } = useOpenCloseComponent();

  const {
    compSwitchState: jacketCollapseState,
    handleCompToggle: handleJacketCollapseToggle,
  } = useOpenCloseComponent();

  const [jacketState, setJacket] = useState(false);

  const {
    compSwitchState: modalState,
    handleCompOpen: handleModalOpen,
    handleCompClose: handleModalClose,
  } = useOpenCloseComponent();

  const handleJacketToggleGA = () => {
    if (!jacketState) {
      WithEvent('PRODUCT', 'Clicked Jacket Details', 'PRODUCT_JACKET', 1);
      setJacket(true);
    }
    handleJacketCollapseToggle();
  };

  const handleOpenInfo = () => {
    WithEvent('PRODUCT', 'Clicked Catalog Info', 'PRODUCT_PAGE_INFO', 1);
    handleInfoOpen();
  };

  // hooks to get sku and qty and catalogId

  const [selectQty, setSelectQty] = useState({});

  const handleSelectQty = (e, sku) => {
    setSelectQty({ ...selectQty, [sku]: parseFloat(e.target.value) });
  };

  const createQty = (num) => {
    const divs = [];

    if (num > 30) {
      for (let i = 0; i < 30; i += 1) {
        divs.push(<option key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</option>);
      }
    } else {
      for (let i = 0; i < num; i += 1) {
        divs.push(<option key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</option>);
      }
    }

    return divs;
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => setSelectQty(1), []);

  use4Cite();

  // console.log(selectQty);
  // APOLLO HOOKS
  const [
    cartItemAdd,
    {
      error: errorItemAdd,
      loading: loadingItemAdd,
      // data: dataItemAdd,
    },
  ] = useMutation(ADD_ITEM_CART);

  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCT, { variables: { ean }, fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data && data.productDetail && data.productDetail.inventory && data.productDetail.inventory.length > 0) {
      setSelectQty(data.productDetail.inventory.reduce((acc, cv) => ({ ...acc, [cv.sku]: 1 }), {}));
    }

    if (data && data.productDetail && data.productDetail.apparel && data.productDetail.apparel.length > 0) {
      const reducedInv = data.productDetail.apparel.reduce((acc, cv) => [...acc, cv.inventory], []).flat();

      // console.log(reducedInv);

      if (reducedInv.length > 0) {
        setSelectQty(reducedInv.reduce((acc, cv) => ({ ...acc, [cv.sku]: 1 }), {}));
      }
    }
  }, [data]);

  const setCartSnackbar = useCartSnackbar();

  const handleCartSnackbarClose = useCallback(() => {
    setCartSnackbar(prev => ({ ...prev, open: false }));
  }, [setCartSnackbar]);

  const handleCartSnackbarOpen = useCallback((triggerElem) => {
    setCartSnackbar({
      prodTitle: data.productDetail.title,
      open: true,
      triggerElem,
      onClose: handleCartSnackbarClose,
    });
  }, [setCartSnackbar, handleCartSnackbarClose, data]);

  // Note on qty useEffect: this is still functional regardless of the add to cart rework--
  // this one sku now represents other skus of the same condition

  if (loading) {
    return (
      <div className="singleproductpage-container__div">
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </div>
    );
  }

  if (error || errorItemAdd) return <Error location={{ state: { error, errorItemAdd } }} />;

  if (
    data
      && data.productDetail
      && data.productDetail.inventory
      && data.productDetail.inventory.length === 0
      && !data.productDetail.apparel
  ) {
    return (
      <div className="singleproductpage-container__div">
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="singleproductpage-item__div singleproductpage-item-no-results__div">
          <div className="singleproductpage-main__div">
            <div className="singleproductpage-item-image__div">
              <IconButton
                onClick={handleModalOpen}
                disableRipple
                disableTouchRipple
                classes={{ root: classes.rootProductIconButton }}
              >
                <img
                  className="singleproductpage-item-image__img"
                  alt={data.productDetail.title}
                  src={data.productDetail.imageUrl}
                />
              </IconButton>
            </div>
            <div className="singleproductpage-item-header__div">
              <h1 className="singleproductpage-item-title__h1">{data.productDetail.title}</h1>
              <p className="singleproductpage-item-originator__p">{`by ${data.productDetail.originator}`}</p>
              {data && data.productDetail && data.productDetail.bookBinding && <p className="singleproductpage-item-binding__p">{`${data.productDetail.bookBinding}`}</p>}
              {
              data
                && data.productDetail
                && data.productDetail.bookFormat
                && <p className="singleproductpage-item-format__p">{data.productDetail.bookFormat}</p>
              }
            </div>
            <div className="singleproductpage-item-details__div">
              <h3 className="singleproductpage-message__h3">
                Sorry. We don&#39;t have this product in stock.
              </h3>
            </div>
          </div>
          <div className="singleproductpage-item-more__div">
            <div className="singleproductpage-item-more-content__div">
              <h2 className="singleproductpage-item-more-header__h2">Product Details</h2>
              {
                ((data.productDetail
                    && data.productDetail.webDescription
                    && data.productDetail.webDescription.length > 0)
                  || (data.productDetailAux
                    && data.productDetailAux.annotation
                    && data.productDetailAux.annotation.length > 0)
                  || (data.productDetailAux
                    && data.productDetailAux.jacket
                    && data.productDetailAux.jacket.length > 0)
                )
                  && <div className="singleproductpage-item-more-dash__div" />
              }
              {
                data.productDetail
                  && data.productDetail.webDescription
                  && data.productDetail.webDescription.length > 0
                  && data.productDetail.webDescription !== 'No description'
                  && (
                  <div className="singleproductpage-item-more-desc__div">
                    <h3 className="singleproductpage-item-more-desc-header__h3">Description</h3>
                    <p className="singleproductpage-item-detail__p singleproductpage-item-more-desc-detail__p">{data.productDetail.webDescription}</p>
                  </div>
                  )
              }
              {
                data.productDetailAux
                  && data.productDetailAux.annotation
                  && data.productDetailAux.annotation.length > 0
                  && <div className="singleproductpage-item-more-desc__div singleproductpage-annotation__div" dangerouslySetInnerHTML={(() => ({ __html: data.productDetailAux.annotation }))()} />
              }
              {
                data
                  && data.productDetailAux
                  && data.productDetailAux.jacket
                  && data.productDetailAux.jacket.length > 0
                  && (
                    <div className="singleproductpage-item-more-desc__div">
                      <h3 className="singleproductpage-item-more-desc-header__h3">From the Publisher</h3>
                      <Collapse in={jacketCollapseState} collapsedHeight={100}>
                        {
                          data.productDetailAux.jacket.map((note, index) => (
                            <div key={`j${index + 100}`} tag={`j${index}`} className="singleproductpage-item-more-desc__div" dangerouslySetInnerHTML={(() => ({ __html: note }))()} />
                          ))
                        }
                      </Collapse>
                        {
                          data.productDetailAux.jacket[0]
                            && data.productDetailAux.jacket[0].length > 200
                            && (
                              <div className="singleproductpage-anno-btn__div">
                                <IconButton
                                  onClick={handleJacketToggleGA}
                                  classes={{ root: classes.rootAnnoIconButton }}
                                >
                                  {
                                  jacketCollapseState
                                    ? 'View Less Details'
                                    : 'View More Details'
                                  }
                                </IconButton>
                              </div>
                            )
                        }
                    </div>
                  )
              }
              {/* <div className="singleproductpage-item-more-dash__div" /> */}
              {data.productDetail.isbn10 && <p className="singleproductpage-item-more__p">{`ISBN-10: ${data.productDetail.isbn10}`}</p>}
              {data.productDetail.gtin13 && <p className="singleproductpage-item-more__p">{`GTIN-13: ${data.productDetail.gtin13}`}</p>}
              <p className="singleproductpage-item-more__p">{`Publisher: ${data.productDetail.publisher}`}</p>
              {data.productDetail.copyright && <p className="singleproductpage-item-more__p">{`Copyright: ${data.productDetail.copyright}`}</p>}
              {data.productDetail.bookBinding !== 'Merch' && <p className="singleproductpage-item-more__p">{`Book Binding: ${data.productDetail.bookBinding === 'PaperBack' ? 'Paperback' : data.productDetail.bookBinding}`}</p>}
              {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.pages && <p className="singleproductpage-item-more__p">{`Pages: ${data.productDetailAux.dimensions.pages}`}</p>}
              {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.weight && <p className="singleproductpage-item-more__p">{`Weight: ${data.productDetailAux.dimensions.weight / 100}lb`}</p>}
              {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.length && data.productDetailAux.dimensions.width && data.productDetailAux.dimensions.height && <p className="singleproductpage-item-more__p">{`Dimensions(in) (L x W x H): ${data.productDetailAux.dimensions.length / 100} x ${data.productDetailAux.dimensions.width / 100} x ${data.productDetailAux.dimensions.height / 100}`}</p>}
              {
                data
                  && data.productDetail
                  && data.productDetail.inventoryOverview
                  && data.productDetail.inventoryOverview.locationList
                  && data.productDetail.inventoryOverview.locationList.length > 0
                  && data.productDetail.inventoryOverview.locationList
                    .filter(loc => loc.length > 1).length > 0
                  && <h3 className="singleproductpage-item-more-desc-header__h3 singleproductpage-location__h3">Location in Store</h3>
              }
              {
                data
                  && data.productDetail
                  && data.productDetail.inventoryOverview
                  && data.productDetail.inventoryOverview.locationList
                  && data.productDetail.inventoryOverview.locationList.length > 0
                  && data.productDetail.inventoryOverview.locationList
                    .filter(loc => loc.length > 1)
                    .map(loc => <p key={loc} className="singleproductpage-item-detail__p singleproductpage-location__p">{locationsDictionary(loc)}</p>)
              }
            </div>
          </div>
        </div>
        <CompModal
          imageObj={{
            imgSrc: data?.productDetail?.imageUrl,
            altText: data?.productDetail?.title,
          }}
          open={modalState}
          onClose={handleModalClose}
        />
        <ViewFooter />
      </div>
    );
  }

  // const { productDetail } = data;

  // const uniqueInventory = {};
  // const uniqueInvArr = [];

  // if (data && data.productDetail && data.productDetail.inventory) {
  //   const { inventory } = data.productDetail;

  //   // capture unique sku for each condition

  //   if (inventory) {
  //     inventory.forEach((inv) => {
  //       const { condition, onHand, signed } = inv;
  //       if (!signed) {
  //         if (uniqueInventory[condition]) {
  //           if (uniqueInventory[condition][0].onHand === 0 && onHand > 0) {
  //             uniqueInventory[condition].shift();
  //             uniqueInventory[condition].unshift(inv);
  //           }
  //         } else uniqueInventory[condition] = [inv];
  //       }
  //     });

  //     // add signed onHand sku to array

  //     inventory.forEach((inv) => {
  //       const { signed, onHand, condition } = inv;
  //       // if (signed && onHand > 0) {
  //       //   if (uniqueInventory[condition]
  //       //     && uniqueInventory[condition][1]
  //       //     && uniqueInventory[condition][1].onHand === 0) {
  //       //     uniqueInventory[condition].pop();
  //       //     uniqueInventory[condition].push(inv);
  //       //   } else if (uniqueInventory[condition] && uniqueInventory[condition].length > 0) {
  //       //     uniqueInventory[condition].push(inv);
  //       //   }
  //       // }
  //       if (signed && onHand > 0) {
  //         // consider refactoring this conditional since it will always replace non-signed sku
  //         uniqueInventory[condition] = inv;
  //       }
  //     });
  //     Object.keys(uniqueInventory).forEach((p, i) => (
  //       uniqueInvArr.push(uniqueInventory[Object.keys(uniqueInventory)[i]])
  //     ));
  //   }
  // }

  // new inventory format

  const formatInventory = [];

  if (data && data.productDetail && data.productDetail.inventory) {
    // separate inv between catalogs

    const catalogs = data.productDetail.inventory.reduce((acc, cv) => {
      if (!acc[cv.catalogId]) {
        return { ...acc, [cv.catalogId]: [cv] };
      }
      return { ...acc, [cv.catalogId]: [...acc[cv.catalogId], cv] };
    }, {});

    // console.log('catalogs', catalogs);

    // filter signed

    const signedSkus = {};
    const unsignedSkus = {};

    const filterSignedPre = arr => arr.filter(prod => prod.signed);

    const filterUnsigned = arr => arr.filter(prod => !prod.signed);

    Object.keys(catalogs).forEach((cat) => {
      signedSkus[cat] = filterSignedPre(catalogs[cat]);
      unsignedSkus[cat] = filterUnsigned(catalogs[cat]);
    });

    // take out prerelease skus too

    const filterPrerelease = arr => arr.filter(prod => prod.location === 'EVPRE');
    const filterNoPrerelease = arr => arr.filter(prod => prod.location !== 'EVPRE');

    const prereleaseSkus = {};
    const noprereleaseSkus = {};

    Object.keys(unsignedSkus).forEach((cat) => {
      prereleaseSkus[cat] = filterPrerelease(unsignedSkus[cat]);
      noprereleaseSkus[cat] = filterNoPrerelease(unsignedSkus[cat]);
    });

    const formatSignedPre = (arr) => {
      const signedPreFormatted = arr.map(prod => (
        {
          ...prod,
          onHandList: [prod.onHand],
          skuList: [prod.sku],
        }
      ));
      return signedPreFormatted;
    };

    // format prerelease

    const formatPrereleaseSkus = {};

    Object.keys(prereleaseSkus).forEach((cat) => {
      formatPrereleaseSkus[cat] = formatSignedPre(prereleaseSkus[cat]);
    });

    // push formatprereleaseskus to inventory

    Object.keys(formatPrereleaseSkus).forEach((cat) => {
      formatInventory.push(...formatPrereleaseSkus[cat]);
    });

    // console.log('formatprerelskus', formatPrereleaseSkus);

    // format signed skus

    const formatSignedSkus = {};

    Object.keys(signedSkus).forEach((cat) => {
      formatSignedSkus[cat] = formatSignedPre(signedSkus[cat]);
    });

    // console.log('formatSignedSkus', formatSignedSkus);

    // push signedSkus to final inventory

    Object.keys(formatSignedSkus).forEach((cat) => {
      formatInventory.push(...formatSignedSkus[cat]);
    });

    // combine conditions

    const newGroup = {};

    const groupCond = (arr) => {
      const groupedCond = arr.reduce((acc, cv) => {
        if (acc[cv.condition]) {
          return {
            ...acc,
            [cv.condition]: {
              ...acc[cv.condition],
              skuList: [...acc[cv.condition].skuList, cv.sku],
              onHandList: [...acc[cv.condition].onHandList, cv.onHand],
            },
          };
        }

        return { ...acc, [cv.condition]: { ...cv, skuList: [cv.sku], onHandList: [cv.onHand] } };
      }, {});

      return groupedCond;
    };

    Object.keys(noprereleaseSkus).forEach((cat) => {
      newGroup[cat] = groupCond(noprereleaseSkus[cat]);
    });

    // console.log('newGroup', newGroup);

    // combine skus together

    Object.keys(newGroup).forEach((cat) => {
      Object.keys(newGroup[cat]).forEach((cond) => {
        formatInventory.push(newGroup[cat][cond]);
      });
    });

    // console.log('formatInventory', formatInventory);
  }

  // console.log('singledata', data);

  const atc = (sku, qty, catalogId, triggerElem) => {
    cartItemAdd({
      variables: { cartItemInput: { sku, qty, catalogId } },
      update: atcUpdate,
    })
      .then(() => refetchCartList())
      .then(() => {
        handleCartSnackbarOpen(triggerElem);
      });
  };

  const addToCart = (onHandList, skuList, qty, catalogId, triggerElem) => {
    // GA Event Tracking
    WithEvent('PRODUCT', 'Add Product to Cart', 'PRODUCT_PAGE', 1);

    // FB Event Tracking

    WithFBEvent('AddToCart', {
      content_name: 'Product Page',
      content_type: 'product',
      contents: [{ id: ean, quantity: qty }],
    });

    Sentry.addBreadcrumb({
      category: 'ADD_ITEM',
      message: `Click add to cart ${ean}`,
      level: Sentry.Severity.Info,
    });

    if (qty <= onHandList[0]) {
      atc(skuList[0], qty, catalogId, triggerElem);
    } else if (qty > onHandList[0]) {
      // get needed skus and qtys

      const neededSkus = [];
      const neededQtys = [];
      let qtyTotal = qty;

      while (qtyTotal > 0) {
        const currentSku = skuList.shift();
        const currentQty = onHandList.shift();
        neededSkus.push(currentSku);
        neededQtys.push(currentQty);
        qtyTotal -= currentQty;
      }

      qtyTotal = qty;

      // if less than qty, add all onHand, else add remaining qtyTotal
      while (neededSkus.length > 0) {
        const addSku = neededSkus.shift();
        const neededQty = neededQtys.shift();

        if (neededQty <= qtyTotal) {
          atc(addSku, neededQty, catalogId, triggerElem);
        } else {
          atc(addSku, qtyTotal, catalogId, triggerElem);
        }

        qtyTotal -= neededQty;
      }
    }
  };

  const addToCartApparel = (sku, qty, catalogId, triggerElem) => {
    // GA Event Tracking
    WithEvent('PRODUCT', 'Add Product to Cart', 'PRODUCT_PAGE', 1);

    // FB Event Tracking

    WithFBEvent('AddToCart', {
      content_name: 'Product Page',
      content_type: 'product',
      contents: [{ id: ean, quantity: qty }],
    });

    Sentry.addBreadcrumb({
      category: 'ADD_ITEM',
      message: `Click add to cart ${ean}`,
      level: Sentry.Severity.Info,
    });

    atc(sku, qty, catalogId, triggerElem);
  };

  // console.log('data', data);

  return (
    <React.Fragment>
      <div className="singleproductpage-container__div">
        { scroll > 1000 && <CompFab />}
        { loadingItemAdd && <InPageLoading />}
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        {
          data
            && data.productDetail
            && data.productDetail.inventory
            && data.productDetail.inventory.length > 0
            && (
              <div className="singleproductpage-item__div">
                <div className="singleproductpage-main__div">
                  <div className="singleproductpage-item-image__div">
                    <IconButton
                      onClick={handleModalOpen}
                      disableRipple
                      disableTouchRipple
                      classes={{ root: classes.rootProductIconButton }}
                    >
                      <img
                        className="singleproductpage-item-image__img"
                        alt={data.productDetail.title}
                        src={data.productDetail.imageUrl}
                      />
                    </IconButton>
                  </div>
                  <div className="singleproductpage-item-header__div">
                    <h1 className="singleproductpage-item-title__h1">{data.productDetail.title}</h1>
                    <p className="singleproductpage-item-originator__p">{`by ${data.productDetail.originator}`}</p>
                    {data && data.productDetail && data.productDetail.bookBinding && <p className="singleproductpage-item-binding__p">{`${data.productDetail.bookBinding}`}</p>}
                    {
                    data
                      && data.productDetail
                      && data.productDetail.bookFormat
                      && <p className="singleproductpage-item-format__p">{data.productDetail.bookFormat}</p>
                    }
                  </div>
                  <div className="singleproductpage-item-details__div">
                    <div className="singleproductpage-item-inventory__div">
                      {
                        // uniqueInvArr.flat()
                        // uniqueInvArr.reduce((acc, val) => acc.concat(val), [])
                        // data
                        //   && data.productDetail
                        //   && data.productDetail.inventory
                          formatInventory
                            .sort((a, b) => a.salePrice - b.salePrice)
                            .map((inv) => {
                              const {
                                condition,
                                location,
                                onHand,
                                onHandList,
                                skuList,
                                salePrice,
                                salePriceStv,
                                signed,
                                sku,
                                catalogId,
                                discountPct,
                                section,
                              } = inv;

                              const onHandTotal = onHandList.reduce((acc, cv) => acc + cv, 0);

                              const isInStore = !locationsNonPhysical[location];

                              return (
                                <div key={sku} className={onHand > 0 ? 'singleproductpage-item-option__div' : 'singleproductpage-item-option__div singleproductpage-item-option__div--disabled'}>
                                  {
                                    (catalogId === 2 || (onHand > 0 && isInStore)) && (
                                      <div className={`singleproductpage-drop-tab__div${catalogId === 2 ? ' singleproductpage-drop-tab__div--warehouse' : ''}`}>
                                        {catalogId === 2
                                          ? (
                                            <>
                                              <AccessTimeRoundedIcon classes={{ root: classes.rootTime }} />
                                              <p className="singleproductpage-drop-tab__p">
                                                Warehouse Only: Fulfilled in 3-7 business days
                                              </p>
                                            </>
                                          ) : (
                                            <p className="singleproductpage-drop-tab__p">
                                              Available at our 828 Broadway location
                                            </p>
                                          )}
                                      </div>
                                    )
                                  }
                                  <div className="singleproductpage-item-detail-content__div">
                                    {condition && <p className="singleproductpage-item-detail__p">{`Condition: ${condition}`}</p>}
                                    {(location === 'EVPRE' || section === 'PRE-RELEASE') && <p className="singleproductpage-item-detail__p singleproductpage-item-detail__p--red">*Pre-release*</p>}
                                    {signed && <p className={signed ? 'singleproductpage-item-detail__p singleproductpage-item-detail-signed__p' : 'singleproductpage-item-detail__p--nodisplay'}>{signed && '*Signed Copy*'}</p>}
                                  </div>
                                  <div className="singleproductpage-item-detail-prices__div">
                                    <p className={!discountPct <= 0 && (data.productDetail.retailStv && data.productDetail.retailStv !== 0) && data.productDetail.retailStv !== salePriceStv ? 'singleproductpage-item-detail__p singleproductpage-item-detail__p--gray singleproductpage-item-detail__p--strikethrough' : 'singleproductpage-item-detail__p singleproductpage-item-detail__p--nodisplay'}>{`$${data.productDetail.retailStv !== 0 ? (parseFloat(data.productDetail.retailStv) * 0.01).toFixed(2) : <span />}`}</p>
                                    {salePrice && salePrice !== 0 && <p className="singleproductpage-item-detail__p">{`$${salePrice.toFixed(2)}`}</p>}
                                  </div>
                                  {!discountPct <= 0 && data.productDetail.retailStv !== 0 && <p className={data.productDetail.retailStv === salePriceStv ? 'singleproductpage-item-detail__p singleproductpage-item-detail__p--nodisplay' : 'singleproductpage-item-detail__p singleproductpage-item-detail-discount singleproductpage-item-detail__p--red'}>{data.productDetail.retailStv && data.productDetail.retailStv !== 0 ? `(${(((data.productDetail.retailStv - salePriceStv) / data.productDetail.retailStv) * 100).toFixed(0)}% off)` : <span />}</p>}
                                  <div className="singleproductpage-item-qty__div">
                                    <p className="singleproductpage-item-qty__p">Qty.</p>
                                    <select aria-label="quantity" className="singleproductpage-item-select__select" value={selectQty[sku]} onChange={e => handleSelectQty(e, sku)}>
                                      {onlyOnePromo.indexOf(ean) >= 0 ? <option key="qty0" className="singleproductpage-item-opt__option">1</option> : createQty(onHandTotal)}
                                    </select>
                                  </div>
                                  <div className="singleproductpage-item-detail-scarcity__div">
                                    <p className="singleproductpage-item-detail__p singleproductpage-item-detail__p--red">
                                      {
                                        onlyOnePromo.indexOf(ean) < 0
                                        && (() => {
                                          if (onHandTotal < 10) {
                                            if (onHandTotal < 1) {
                                              return 'Out of Stock!';
                                            }
                                            return `Only ${onHandTotal} left in stock!`;
                                          }
                                          return null;
                                        }
                                        )()
                                      }
                                    </p>
                                  </div>
                                  <div className="singleproductpage-catalog-icons__div">
                                    <IconButton
                                      onClick={handleOpenInfo}
                                      classes={{ root: classes.rootIconButton }}
                                      disableRipple
                                      disableTouchRipple
                                      aria-label="Info"
                                    >
                                      <InfoRoundedIcon />
                                    </IconButton>
                                    {
                                      catalogId === 2
                                        ? (
                                          <Tooltip title="Drop Ship">
                                            <LocalShippingRoundedIcon />
                                          </Tooltip>
                                        )
                                        : (
                                          <Tooltip title="Available in Strand">
                                            <MenuBookRoundedIcon />
                                          </Tooltip>
                                        )
                                    }
                                  </div>
                                  <div className="singleproductpage-item-button__div">
                                    <Button
                                      type="button"
                                      onClick={(e) => {
                                        addToCart(onHandList, skuList, selectQty[sku], catalogId, e.currentTarget);
                                      }}
                                      classes={{ root: classes.rootButton }}
                                    >
                                      Add to Cart
                                    </Button>
                                  </div>
                                </div>
                              );
                            })
                      }
                    </div>
                  </div>
                </div>
                <div className="singleproductpage-item-more__div">
                  <div className="singleproductpage-item-more-content__div">
                    <h2 className="singleproductpage-item-more-header__h2">Product Details</h2>
                    {
                      ((data?.productDetail?.webDescription?.length > 0
                          && data?.productDetail?.webDescription !== 'No description'
                      )
                        || data?.productDetailAux?.annotation?.length > 0
                        || data?.productDetailAux?.jacket?.length > 0
                      )
                        && <div className="singleproductpage-item-more-dash__div" />
                    }
                    {
                      data.productDetail
                        && data.productDetail.webDescription
                        && data.productDetail.webDescription.length > 0
                        && data.productDetail.webDescription !== 'No description'
                        && (
                        <div className="singleproductpage-item-more-desc__div">
                          <h3 className="singleproductpage-item-more-desc-header__h3">Description</h3>
                          <p className="singleproductpage-item-detail__p singleproductpage-item-more-desc-detail__p">{data.productDetail.webDescription}</p>
                        </div>
                        )
                    }
                    {
                      data.productDetailAux
                        && data.productDetailAux.annotation
                        && data.productDetailAux.annotation.length > 0
                        && <div className="singleproductpage-item-more-desc__div singleproductpage-annotation__div" dangerouslySetInnerHTML={(() => ({ __html: data.productDetailAux.annotation }))()} />
                    }
                    {
                      data
                        && data.productDetailAux
                        && data.productDetailAux.jacket
                        && data.productDetailAux.jacket.length > 0
                        && (
                          <div className="singleproductpage-item-more-desc__div">
                            <h3 className="singleproductpage-item-more-desc-header__h3">From the Publisher</h3>
                            <Collapse in={jacketCollapseState} collapsedHeight={100}>
                              {
                                data.productDetailAux.jacket.map((note, index) => (
                                  <div key={`j${index + 100}`} tag={`j${index}`} className="singleproductpage-item-more-desc__div" dangerouslySetInnerHTML={(() => ({ __html: note }))()} />
                                ))
                              }
                            </Collapse>
                              {
                                data.productDetailAux.jacket[0]
                                  && data.productDetailAux.jacket[0].length > 200
                                  && (
                                    <div className="singleproductpage-anno-btn__div">
                                      <IconButton
                                        onClick={handleJacketToggleGA}
                                        classes={{ root: classes.rootAnnoIconButton }}
                                      >
                                        {
                                        jacketCollapseState
                                          ? 'View Less Details'
                                          : 'View More Details'
                                        }
                                      </IconButton>
                                    </div>
                                  )
                              }
                          </div>
                        )
                    }
                    <div className="singleproductpage-item-more-dash__div" />
                    {data.productDetail.isbn10 && <p className="singleproductpage-item-more__p">{`ISBN-10: ${data.productDetail.isbn10}`}</p>}
                    {data.productDetail.gtin13 && <p className="singleproductpage-item-more__p">{`GTIN-13: ${data.productDetail.gtin13}`}</p>}
                    <p className="singleproductpage-item-more__p">{`Publisher: ${data.productDetail.publisher}`}</p>
                    {data.productDetail.copyright && <p className="singleproductpage-item-more__p">{`Copyright: ${data.productDetail.copyright}`}</p>}
                    {data.productDetail.bookBinding !== 'Merch' && <p className="singleproductpage-item-more__p">{`Book Binding: ${data.productDetail.bookBinding === 'PaperBack' ? 'Paperback' : data.productDetail.bookBinding}`}</p>}
                    {data && data.productDetailAux && data.productDetailAux.dimensions && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.pages && <p className="singleproductpage-item-more__p">{`Pages: ${data.productDetailAux.dimensions.pages}`}</p>}
                    {data && data.productDetailAux && data.productDetailAux.dimensions && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.weight && <p className="singleproductpage-item-more__p">{`Weight: ${data.productDetailAux.dimensions.weight / 100}lb`}</p>}
                    {data && data.productDetailAux && data.productDetailAux.dimensions && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.length && data.productDetailAux.dimensions.width && data.productDetailAux.dimensions.height && <p className="singleproductpage-item-more__p">{`Dimensions(in) (L x W x H): ${data.productDetailAux.dimensions.length / 100} x ${data.productDetailAux.dimensions.width / 100} x ${data.productDetailAux.dimensions.height / 100}`}</p>}
                    {
                      data
                        && data.productDetail
                        && data.productDetail.inventoryOverview
                        && data.productDetail.inventoryOverview.locationList
                        && data.productDetail.inventoryOverview.locationList.length > 0
                        && data.productDetail.inventoryOverview.locationList
                          .filter(loc => loc && loc.length > 1).length > 0
                        && <h3 className="singleproductpage-item-more-desc-header__h3 singleproductpage-location__h3">Location in Store</h3>
                    }
                    {
                      data
                        && data.productDetail
                        && data.productDetail.inventoryOverview
                        && data.productDetail.inventoryOverview.locationList
                        && data.productDetail.inventoryOverview.locationList.length > 0
                        && data.productDetail.inventoryOverview.locationList
                          .filter(loc => loc && loc.length > 1)
                          .map(loc => <p key={loc} className="singleproductpage-item-detail__p singleproductpage-location__p">{locationsDictionary(loc)}</p>)
                    }
                  </div>
                </div>
              </div>
            )
        }

        {
          // apparel logic

          data
            && data.productDetail
            && data.productDetail.apparel
            && data.productDetail.apparel.length > 0
            && (
              <div className="singleproductpage-item__div">
                <div className="singleproductpage-main__div">
                  <div className="singleproductpage-item-image__div">
                    <IconButton
                      onClick={handleModalOpen}
                      disableRipple
                      disableTouchRipple
                      classes={{ root: classes.rootProductIconButton }}
                    >
                      <img
                        className="singleproductpage-item-image__img"
                        alt={data.productDetail.title}
                        src={data.productDetail.imageUrl}
                      />
                    </IconButton>
                  </div>
                  <div className="singleproductpage-item-header__div">
                    <h1 className="singleproductpage-item-title__h1">{data.productDetail.title}</h1>
                    <p className="singleproductpage-item-originator__p">{`by ${data.productDetail.originator}`}</p>
                  </div>
                  <div className="singleproductpage-item-details__div">
                    <div className="singleproductpage-item-inventory__div">
                      {
                        data
                          && data.productDetail
                          // && data.productDetail.retailStv
                          && data.productDetail.apparel
                          && data.productDetail.apparel.length > 0
                          && data.productDetail.apparel
                            .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                            .flat()
                          && data.productDetail.apparel
                            .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                            .flat().length > 0
                          && data.productDetail.apparel
                            .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                            .flat()
                            .map((inv, i) => {
                              const {
                                onHand,
                                salePriceStv,
                                sku,
                                catalogId,
                                location,
                              } = inv;

                              const isInStore = !locationsNonPhysical[location];

                              return (
                                <div key={sku} className={onHand > 0 ? 'singleproductpage-item-option__div' : 'singleproductpage-item-option__div singleproductpage-item-option__div--disabled'}>
                                  {
                                    (catalogId === 2 || (onHand > 0 && isInStore)) && (
                                      <div className={`singleproductpage-drop-tab__div${catalogId === 2 ? ' singleproductpage-drop-tab__div--warehouse' : ''}`}>
                                        {catalogId === 2
                                          ? (
                                            <>
                                              <AccessTimeRoundedIcon classes={{ root: classes.rootTime }} />
                                              <p className="singleproductpage-drop-tab__p">
                                                Warehouse Only: Fulfilled in 3-7 business days
                                              </p>
                                            </>
                                          ) : (
                                            <p className="singleproductpage-drop-tab__p">
                                              Available at our 828 Broadway location
                                            </p>
                                          )}
                                      </div>
                                    )
                                  }
                                  <div className="singleproductpage-item-detail-content__div">
                                    {data.productDetail.apparel[i] && <p className="singleproductpage-item-detail__p">{`Size: ${data.productDetail.apparel[i].sizeDesc}`}</p>}
                                  </div>
                                  <div className="singleproductpage-item-detail-prices__div">
                                    {/* <p className={!discountPct <= 0 && (data.productDetail.retailStv && data.productDetail.retailStv !== 0) && data.productDetail.retailStv !== salePriceStv ? 'singleproductpage-item-detail__p singleproductpage-item-detail__p--gray singleproductpage-item-detail__p--strikethrough' : 'singleproductpage-item-detail__p singleproductpage-item-detail__p--nodisplay'}>{`$${data.productDetail.retailStv !== 0 ? (parseFloat(data.productDetail.retailStv) * 0.01).toFixed(2) : <span />}`}</p> */}
                                    {salePriceStv && salePriceStv !== 0 && <p className="singleproductpage-item-detail__p">{`$${(salePriceStv * 0.01).toFixed(2)}`}</p>}
                                  </div>
                                  {/* {!discountPct <= 0 && data.productDetail.retailStv !== 0 && <p className={data.productDetail.retailStv === salePriceStv ? 'singleproductpage-item-detail__p singleproductpage-item-detail__p--nodisplay' : 'singleproductpage-item-detail__p singleproductpage-item-detail-discount singleproductpage-item-detail__p--red'}>{data.productDetail.retailStv && data.productDetail.retailStv !== 0 ? `(${(((data.productDetail.retailStv - salePriceStv) / data.productDetail.retailStv) * 100).toFixed(0)}% off)` : <span />}</p>} */}
                                  <div className="singleproductpage-item-qty__div">
                                    <p className="singleproductpage-item-qty__p">Qty.</p>
                                    <select aria-label="quantity" className="singleproductpage-item-select__select" value={selectQty[sku]} onChange={e => handleSelectQty(e, sku)}>
                                      {onlyOnePromo.indexOf(ean) >= 0 ? <option key="qty0" className="singleproductpage-item-opt__option">1</option> : createQty(onHand)}
                                    </select>
                                  </div>
                                  <div className="singleproductpage-item-detail-scarcity__div">
                                    <p className="singleproductpage-item-detail__p singleproductpage-item-detail__p--red">
                                      {
                                        onlyOnePromo.indexOf(ean) < 0
                                          && (() => {
                                            if (onHand < 10) {
                                              if (onHand < 1) {
                                                return 'Out of Stock!';
                                              }
                                              return `Only ${onHand} left in stock!`;
                                            }
                                            return null;
                                          }
                                          )()
                                      }
                                    </p>
                                  </div>
                                  {/* <div className="singleproductpage-catalog-icons__div">
                                    <IconButton
                                      onClick={handleOpenInfo}
                                      classes={{ root: classes.rootIconButton }}
                                      disableRipple
                                      disableTouchRipple
                                      aria-label="Info"
                                    >
                                      <InfoRoundedIcon />
                                    </IconButton>
                                    {
                                      catalogId === 2
                                        ? (
                                          <Tooltip title="Drop Ship">
                                            <LocalShippingRoundedIcon />
                                          </Tooltip>
                                        )
                                        : (
                                          <Tooltip title="Available in Strand">
                                            <MenuBookRoundedIcon />
                                          </Tooltip>
                                        )
                                    }
                                  </div> */}
                                  <div className="singleproductpage-item-button__div">
                                    <Button
                                      type="button"
                                      onClick={(e) => {
                                        addToCartApparel(sku, selectQty[sku], catalogId, e.currentTarget);
                                      }}
                                      classes={{ root: classes.rootButton }}
                                    >
                                      Add to Cart
                                    </Button>
                                  </div>
                                </div>
                              );
                            })
                      }
                    </div>
                  </div>
                </div>
                <div className="singleproductpage-item-more__div">
                  <div className="singleproductpage-item-more-content__div">
                    <h2 className="singleproductpage-item-more-header__h2">Product Details</h2>
                    {
                      ((data.productDetail
                          && data.productDetail.webDescription
                          && data.productDetail.webDescription.length > 0)
                        || (data.productDetailAux
                          && data.productDetailAux.annotation
                          && data.productDetailAux.annotation.length > 0)
                        || (data.productDetailAux
                          && data.productDetailAux.jacket
                          && data.productDetailAux.jacket.length > 0)
                      )
                        && <div className="singleproductpage-item-more-dash__div" />
                    }
                    {
                      data.productDetail
                        && data.productDetail.webDescription
                        && data.productDetail.webDescription.length > 0
                        && data.productDetail.webDescription !== 'No description'
                        && (
                        <div className="singleproductpage-item-more-desc__div">
                          <h3 className="singleproductpage-item-more-desc-header__h3">Description</h3>
                          <p className="singleproductpage-item-detail__p singleproductpage-item-more-desc-detail__p">{data.productDetail.webDescription}</p>
                        </div>
                        )
                    }
                    {
                      data.productDetailAux
                        && data.productDetailAux.annotation
                        && data.productDetailAux.annotation.length > 0
                        && <div className="singleproductpage-item-more-desc__div singleproductpage-annotation__div" dangerouslySetInnerHTML={(() => ({ __html: data.productDetailAux.annotation }))()} />
                    }
                    {
                      data
                        && data.productDetailAux
                        && data.productDetailAux.jacket
                        && data.productDetailAux.jacket.length > 0
                        && (
                          <div className="singleproductpage-item-more-desc__div">
                            <h3 className="singleproductpage-item-more-desc-header__h3">From the Publisher</h3>
                            <Collapse in={jacketCollapseState} collapsedHeight={100}>
                              {
                                data.productDetailAux.jacket.map((note, index) => (
                                  <div key={`j${index + 100}`} tag={`j${index}`} className="singleproductpage-item-more-desc__div" dangerouslySetInnerHTML={(() => ({ __html: note }))()} />
                                ))
                              }
                            </Collapse>
                              {
                                data.productDetailAux.jacket[0]
                                  && data.productDetailAux.jacket[0].length > 200
                                  && (
                                    <div className="singleproductpage-anno-btn__div">
                                      <IconButton
                                        onClick={handleJacketToggleGA}
                                        classes={{ root: classes.rootAnnoIconButton }}
                                      >
                                        {
                                        jacketCollapseState
                                          ? 'View Less Details'
                                          : 'View More Details'
                                        }
                                      </IconButton>
                                    </div>
                                  )
                              }
                          </div>
                        )
                    }
                    {/* <div className="singleproductpage-item-more-dash__div" /> */}
                    {data.productDetail.isbn10 && <p className="singleproductpage-item-more__p">{`ISBN-10: ${data.productDetail.isbn10}`}</p>}
                    {data.productDetail.gtin13 && <p className="singleproductpage-item-more__p">{`GTIN-13: ${data.productDetail.gtin13}`}</p>}
                    <p className="singleproductpage-item-more__p">{`Publisher: ${data.productDetail.publisher}`}</p>
                    {data.productDetail.copyright && <p className="singleproductpage-item-more__p">{`Copyright: ${data.productDetail.copyright}`}</p>}
                    {data.productDetail.bookBinding !== 'Merch' && <p className="singleproductpage-item-more__p">{`Book Binding: ${data.productDetail.bookBinding === 'PaperBack' ? 'Paperback' : data.productDetail.bookBinding}`}</p>}
                    {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.pages && <p className="singleproductpage-item-more__p">{`Pages: ${data.productDetailAux.dimensions.pages}`}</p>}
                    {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.weight && <p className="singleproductpage-item-more__p">{`Weight: ${data.productDetailAux.dimensions.weight / 100}lb`}</p>}
                    {data.productDetailAux && Object.keys(data.productDetailAux.dimensions).length > 0 && data.productDetailAux.dimensions.length && data.productDetailAux.dimensions.width && data.productDetailAux.dimensions.height && <p className="singleproductpage-item-more__p">{`Dimensions(in) (L x W x H): ${data.productDetailAux.dimensions.length / 100} x ${data.productDetailAux.dimensions.width / 100} x ${data.productDetailAux.dimensions.height / 100}`}</p>}
                    {
                      data
                        && data.productDetail
                        && data.productDetail.inventoryOverview
                        && data.productDetail.inventoryOverview.locationList
                        && data.productDetail.inventoryOverview.locationList.length > 0
                        && data.productDetail.inventoryOverview.locationList
                          .filter(loc => loc && loc.length > 1).length > 0
                        && <h3 className="singleproductpage-item-more-desc-header__h3 singleproductpage-location__h3">Location in Store</h3>
                    }
                    {
                      data
                        && data.productDetail
                        && data.productDetail.inventoryOverview
                        && data.productDetail.inventoryOverview.locationList
                        && data.productDetail.inventoryOverview.locationList.length > 0
                        && data.productDetail.inventoryOverview.locationList
                          .filter(loc => loc && loc.length > 1)
                          .map(loc => <p key={loc} className="singleproductpage-item-detail__p singleproductpage-location__p">{locationsDictionary(loc)}</p>)
                    }
                  </div>
                </div>
              </div>
            )
        }
        {
          (data
            && data.productDetail
            && !data.productDetail.apparel)
          && (
            (data
            && data.productDetail
            && !data.productDetail.inventory)
              || (data
                  && data.productDetail
                  && data.productDetail.inventory
                  && data.productDetail.inventory.length === 0)
          )
            && (
              <div className="singleproductpage-item__div">
                <h3 className="singleproductpage-message__h3">
                  Sorry. We don&#39;t have this product in stock.
                </h3>
              </div>
            )
        }
        <CatalogInfo open={infoState} onClose={handleInfoClose} />
        <ViewFooter />
      </div>
      <CompModal
        imageObj={{
          imgSrc: data?.productDetail?.imageUrl,
          altText: data?.productDetail?.title,
        }}
        open={modalState}
        onClose={handleModalClose}
      />
    </React.Fragment>
  );
};

SingleProductPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default SingleProductPage;

/*

console.log('catalogs', catalogs);

    // let signedArr = [];

    // let groupCatalogs = {};

    // const groupCond = (arr) => {
    //   signedArr = [...signedArr, arr.filter(cv => cv.signed)];

    //   const unsignedArr = arr.filter(cv => !cv.signed);

    //   const groupUnsigned = unsignedArr.reduce((acc, cv) => {
    //     if (acc[cv.condition]) {
    //       return {
    //         ...acc,
    //         [cv.condition]: [...acc[cv.condition], {
    //           ...cv,
    //           skuList: [cv.sku],
    //           onHandList: [cv.onHand],
    //         }],
    //       };
    //     }

    //     // no condition

    //     return { ...acc, [cv.condition]: [{ ...cv, skuList: [cv.sku], onHandList: [cv.onHand] }] };
    //   }, {});

    //   return groupUnsigned;
    // };

    // Object.keys(catalogs).forEach((cat) => {
    //   groupCatalogs[cat] = groupCond(catalogs[cat]);
    // });

    // console.log('catalogsNow', groupCatalogs);

    // const groupCondObj = (groupCond(catalogs[1]));

    // // console.log(groupCondObj);

    // // combine conditions

    // const baseCond = groupCondObj.New.shift();
    // const restCond = [];

    // Object.keys(groupCondObj).forEach((cond) => {

    //   groupCondObj[cond].forEach((subCond) => {
    //     baseCond.skuList.push(subCond.sku);
    //     baseCond.onHandList.push(subCond.onHand);
    //   });

    //   console.log(baseCond);
    // });

*/

/*

create a function that will trigger a fn given the ff. conditions:

if qty <= first sku, use one
if qty >= first sku <= total, use x skus
if qty === total skus, use all skus

**********

const addFn = (skuArr, qtyArr, qty) => {
  const triggerFn = (sku, qty) => {
    console.log('trigger fn', sku, qty);
  }

  if (qty <= qtyArr[0]) {
    triggerFn(skuArr[0], qty);
  } else if (qty > qtyArr[0]) {

    // get needed skus and qtys

    const neededSkus = [];
    const neededQtys = [];
    let qtyTotal = qty;

    while (qtyTotal > 0) {
      const currentSku = skuArr.shift();
      const currentQty = qtyArr.shift();
      neededSkus.push(currentSku);
      neededQtys.push(currentQty);
      qtyTotal-=currentQty;
    }

    qtyTotal = qty;

    while (neededSkus.length > 0) {
      const addSku = neededSkus.shift();
      const neededQty = neededQtys.shift();

      if (neededQty <= qtyTotal) {
        triggerFn(addSku, neededQty);
      } else {
        triggerFn(addSku, qtyTotal);
      }
      qtyTotal-=neededQty;
    }

  }
}

*/
