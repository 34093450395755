import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Link, Redirect } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import {
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import LazyLoad from 'react-lazyload';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import Slider from 'react-slick';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import { useCartSnackbar } from '../cartSnackbar/CartSnackbarProvider';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import CompFab from '../compFab/CompFab';
import InPageLoading from '../loading/InPageLoading';
import WithEvent from '../withEvent/WithEvent';
import listBHU from './listBHU';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import Loading from '../loading/Loading';
import use4Cite from '../../hooks/use4Cite';
import atcUpdate from '../../helpers/atcUpdate';
import './_bookHookUp.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bhuSubscriptionOutlined from '../../static/bhu_subscription_outlined.pdf';


const fullProductList = [];
const fullYearList = [];
const defaultBhuState = {};
const defaultQtyState = {};

listBHU.bhuGroupList.forEach((item) => {
  const { bhuName, eanArr } = item;
  const lcBhuName = bhuName.toLowerCase();

  Array.prototype.push.call(fullProductList, ...eanArr.map(({ ean }) => ean));
  Array.prototype.push.apply(fullYearList, eanArr.find(({ type }) => type === 'Full Year'));
  defaultBhuState[lcBhuName] = eanArr[0].ean;
  defaultQtyState[lcBhuName] = 0;
});

export const ADD_ITEM_CART = gql`
mutation addItemCart($cartItemInput: CartItemInput!){
  cartItemAdd(cartItemInput: $cartItemInput){
    cartId
    cartItemId
    cartPriceStv
    catalogId
    description
    gtin
    isTaxable
    qty
    retailStv
    salePriceStv
    sku
    productDetail{
      gtin13
      id
      imageUrl
      isbn10
      title
      originator
      bookBinding
      inventory{
        sku
        onHand
        catalogId
        signed
      }
    }
  }
}
`;

export const GET_PRODUCT_LIST_DETAIL = gql`
query getProductListDetail{
  productListDetail(ean: ${JSON.stringify(fullProductList)}){
    title
    retailStv
    id
    gtin13
    inventory{
      sku
      salePriceStv
      catalogId
      salePriceStv
      onHand
    }
  }
}
`;

const useStyles = makeStyles({
  rootFormControlType: {
    minWidth: 110,
    color: '#af272f',
    margin: '5px 20px',
    textAlign: 'center',
  },
  rootFormControlQty: {
    minWidth: 50,
    margin: '5px 20px',
    textAlign: 'center',
    color: '#af272f',
  },
  rootBtn: {
    backgroundColor: '#af272f',
    color: '#ffffff',
    textTransform: 'none',
    borderRadius: '5px',
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '1em',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
    minWidth: '160px',
    height: '40px',
  },
  rootInputLabel: {
    color: '#000',
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    top: '-20px',
  },
  iconSelect: {
    color: '#af272f',
  },
  selectSelect: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  rootInfoIcon: {
    color: '#af272f',
    width: '.75em',
    height: '.75em',
    '&:hover': {
      color: '#d4301a',
    },
  },
});

const BookHookUp = (props) => {
  const {
    cartList: upperCartList,
    checkoutIsOpen,
    customerLoggedIn,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  use4Cite();

  const classes = useStyles();

  const settings = {
    slidesToScroll: 1,
    slidesToShow: listBHU.featuredPartners.length >= 5 ? 5 : listBHU.featuredPartners.length,
    lazyLoad: true,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    className: 'bookhookup-slider__div',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          arrows: false,
          slidesToShow: listBHU.featuredPartners.length >= 4 ? 4 : listBHU.featuredPartners.length,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: listBHU.featuredPartners.length >= 4 ? 4 : listBHU.featuredPartners.length,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: listBHU.featuredPartners.length >= 3 ? 3 : listBHU.featuredPartners.length,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '100px',
          arrows: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '100px',
          arrows: false,
        },
      },
      {
        breakpoint: 325,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '90px',
          arrows: false,
        },
      },
    ],
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
    overrides: {
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&:hover': {
            '&:not(.Mui-disabled)': {
              '&:before': {
                borderBottom: 'none',
              },
            },
            borderBottomColor: '#af272f',
          },
        },
      },
    },
  });

  // Scroll Y Hook
  const scroll = useScrollY();

  const setCartSnackbar = useCartSnackbar();

  const handleCartSnackbarClose = useCallback(() => {
    setCartSnackbar(prev => ({ ...prev, open: false }));
  }, [setCartSnackbar]);

  const handleCartSnackbarOpen = useCallback((prodTitle, triggerElem) => {
    setCartSnackbar({
      prodTitle,
      open: true,
      triggerElem,
      onClose: handleCartSnackbarClose,
    });
  }, [setCartSnackbar, handleCartSnackbarClose]);


  // GetProductList Custom Hook

  const [bhuState, setBhu] = useState({ ...defaultBhuState });

  const [qtyState, setQty] = useState({ ...defaultQtyState });

  const [cartItemAdd, {
    error: errorItemAdd,
    loading: loadingItemAdd,
  }] = useMutation(ADD_ITEM_CART);

  const { loading, error, data } = useQuery(GET_PRODUCT_LIST_DETAIL);

  if (error || errorItemAdd) return <Redirect to={{ pathname: '/error', state: { error, errorItemAdd } }} />;

  const handleBhu = (e, bhuName) => {
    setBhu({ ...bhuState, [bhuName]: e.target.value });
  };

  const handleQty = (e, bhuName) => {
    setQty({ ...qtyState, [bhuName]: e.target.value });
  };

  const createQty = (num) => {
    const divs = [];

    if (num > 30) {
      for (let i = 0; i < 30; i += 1) {
        divs.push(<MenuItem value={i} key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</MenuItem>);
      }
    } else {
      for (let i = 0; i < num; i += 1) {
        divs.push(<MenuItem value={i} key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</MenuItem>);
      }
    }
    return divs;
  };

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="bookhookup-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bookhookup-container__div">
        <main className="bookhookup-main__main">
          {scroll > 1000 && <CompFab />}
          {loadingItemAdd && <InPageLoading />}
          <div className="bookhookup-header__div">
            <div className="bookhookup-title__div">
              <img src={urlGenerator('/S/pages/bhu-header.png')} alt="The Book HookUp" className="bookhookup-title__img" />
            </div>
            <h3 className="bookhookup-header__h3">
              Welcome to the Book HookUp, Strand&#39;s subscription program,
              where the greatest and the latest books are delivered right to your door!
            </h3>
          </div>
          <div className="bookhookup-content__div">
            <section className="bookhookup-about__section">
              <div className="bookhookup-about__div">
                <div className="bookhookup-about-image__div">
                  <img src={urlGenerator('/S/pages/bhu-box-lead.jpg')} alt="Book HookUp Box" className="bookhookup-about-image__img" />
                </div>
                <div className="bookhookup-details__div">
                  <div className="bookhookup-notice__div">
                    <p className="bookhookup-notice__h">
                      Subscribe Now to Get a Summer Box!
                    </p>
                    <p className="bookhookup-notice__p">
                      Summer Boxes will deliver in June 2024
                    </p>
                    <br />
                  </div>

                  <p className="bookhookup-details__p">
                    When you sign up for The Book HookUp, a signed, first edition
                    of a highly anticipated title will show up in your mailbox
                    quarterly along with an additional paperback title and literary goodies
                    from people and places we love to sweeten the deal.
                    It’s the perfect gift for a loved one, or
                    the ultimate bookish treat for yourself.
                  </p>
                  <br />
                  <p className="bookhookup-details__p bookhookup-details__p--strong">
                    Gifting the Book HookUp? Download a printable certificate to give
                    to the recipient&nbsp;
                    <a className="bookhookup-link__link bookhookup-details-link__link" href={bhuSubscriptionOutlined} target="_blank" rel="noopener noreferrer">
                      here
                    </a>
                  </p>
                  <br />
                  <p className="bookhookup-details__p bookhookup-details__p--strong">
                    See our updated&nbsp;
                    <Link
                      to="/books-media/bookhookup/faq"
                      className="bookhookup-link__link bookhookup-details-link__link"
                      onClick={() => WithEvent('BOOK_HOOKUP', 'Clicked BHU header FAQ link', 'BHU_FAQ_LINK', 1)}
                    >
                      FAQ
                    </Link>
                    &nbsp;page for more information.
                    For inquiries, contact
                    &nbsp;
                    <a
                      href="mailto:subscriptions@strandbooks.com"
                      className="bookhookup-details-link__a"
                    >
                      subscriptions@strandbooks.com
                    </a>
                    .
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </section>
            <section className="bookhookup-bookshelves__section">
              <ThemeProvider theme={theme}>
                <div className="bookhookup-bookshelves__div">
                  {
                    data
                    && data.productListDetail
                    && data.productListDetail.length > 0
                    && listBHU.bhuGroupList
                      .sort((a, b) => {
                        if (a.bhuName > b.bhuName) return 1;
                        if (a.bhuName < b.bhuName) return -1;

                        return 0;
                      })
                      .map((bhu) => {
                        const {
                          id,
                          eanArr,
                          imgURL,
                          bhuName,
                          note,
                        } = bhu;

                        return (
                          <div key={id} className="bookhookup-bookshelf__div">
                            <Link
                              to={`/product/${bhuState[bhuName.toLowerCase()]}`}
                              className="bookhookup-shelf-link__link"
                            >
                              <div className="bookhookup-image__div">
                                <img alt={bhuName} src={imgURL} className="bookhookup-image__img" />
                              </div>
                              <h4 className="bookhookup-header__h4">
                                {bhuName}
                                &nbsp;
                                <InfoIcon classes={{ root: classes.rootInfoIcon }} />
                              </h4>
                              <div className="bookhookup-h4-accent__div" />
                            </Link>
                            <div className="bookhookup-price__div">
                              <p className="bookhookup-price__p">
                                {
                                  data
                                  && data.productListDetail
                                  && data.productListDetail.length > 0
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0]
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory.length > 0
                                  && `$${((data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory[0].salePriceStv) * 0.01).toFixed(2)}`
                                }
                                {
                                  data
                                    && data.productListDetail
                                    && data.productListDetail.length > 0
                                    && data.productListDetail.filter(prod => (
                                      prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                    ))[0]
                                    && data.productListDetail.filter(prod => (
                                      prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                    ))[0].inventory
                                    && data.productListDetail.filter(prod => (
                                      prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                    ))[0].inventory.length > 0
                                    && !fullYearList.some(ean => (
                                      ean === bhuState[bhuName.toLowerCase()]))
                                    ? '/box'
                                    : <br />
                                }
                              </p>
                            </div>
                            {
                              note.length > 0 ? <div className="bookhookup-note__div"><p className="bookhookup-note__p">{note}</p></div> : <div className="bookhookup-note__div--min-height" />
                            }
                            <div className="bookhookup-options__div">
                              <FormControl variant="outlined" classes={{ root: classes.rootFormControlType }}>
                                <InputLabel
                                  id="subscription-type-label"
                                  classes={{ root: classes.rootInputLabel }}
                                >
                                  Subscription Duration
                                </InputLabel>
                                <Select
                                  classes={{
                                    icon: classes.iconSelect,
                                    select: classes.selectSelect,
                                  }}
                                  value={bhuState[bhuName.toLowerCase()]}
                                  onChange={e => handleBhu(e, bhuName.toLowerCase())}
                                  labelId="subscription-type-label"
                                  id="select-option-id"
                                >
                                  {
                                    eanArr.map((option) => {
                                      const { ean, type } = option;

                                      return (
                                        <MenuItem key={ean} value={ean}>{type}</MenuItem>
                                      );
                                    })
                                  }
                                </Select>
                              </FormControl>
                              <FormControl variant="outlined" classes={{ root: classes.rootFormControlQty }}>
                                <InputLabel
                                  id="outlined-qty-label"
                                  classes={{ root: classes.rootInputLabel }}
                                >
                                  Qty
                                </InputLabel>
                                <Select
                                  classes={{
                                    icon: classes.iconSelect,
                                    select: classes.selectSelect,
                                  }}
                                  value={qtyState[bhuName.toLowerCase()]}
                                  onChange={e => handleQty(e, bhuName.toLowerCase())}
                                  labelId="outlined-qty-label"
                                  id="select-qty-id"
                                >
                                  {
                                    data
                                      && data.productListDetail
                                      && data.productListDetail.length > 0
                                      && data.productListDetail.filter(prod => (
                                        prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                      ))[0]
                                      && data.productListDetail.filter(prod => (
                                        prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                      ))[0].inventory
                                      && data.productListDetail.filter(prod => (
                                        prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                      ))[0].inventory.length > 0
                                      ? createQty(
                                        data.productListDetail.filter(prod => (
                                          prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                        ))[0].inventory[0].onHand,
                                      )
                                      : <MenuItem value={0} className="singleproductpage-item-opt__option">0</MenuItem>
                                  }
                                </Select>
                              </FormControl>
                            </div>
                            <div className="bookhookup-scarcity__div">
                              {
                                data
                                  && data.productListDetail
                                  && data.productListDetail.length > 0
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0]
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory.length > 0
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory[0].onHand
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory[0].onHand > 0
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory[0].onHand < 10
                                  ? (
                                    <p className="bookhookup-scarcity__p">
                                      {`Only ${data.productListDetail.filter(prod => (prod.gtin13 === bhuState[bhuName.toLowerCase()]))[0].inventory[0].onHand} left in stock!`}
                                    </p>
                                  )
                                  : (
                                    <p className="bookhookup-scarcity__p">
                                      &nbsp;
                                    </p>
                                  )
                              }
                            </div>
                            <div className="bookhookup-add__div">
                              {
                                data
                                  && data.productListDetail
                                  && data.productListDetail.length > 0
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0]
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory
                                  && data.productListDetail.filter(prod => (
                                    prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                  ))[0].inventory.length > 0
                                  ? (
                                    <Button
                                      classes={{ root: classes.rootBtn }}
                                      disabled={(!data.productListDetail.filter(prod => (
                                        prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                      ))[0].inventory[0].onHand)}
                                      onClick={(e) => {
                                        const { currentTarget: triggerElem } = e;

                                        WithEvent('HOOKUP', 'Add Product to Cart', 'BHU_BUTTON', 1);

                                        Sentry.addBreadcrumb({
                                          category: 'BOOK_OF_THE_MONTH',
                                          message: `Click add to cart ${bhuName}`,
                                          level: Sentry.Severity.Info,
                                        });

                                        cartItemAdd({
                                          variables: {
                                            cartItemInput: {
                                              catalogId: 1,
                                              qty: parseInt(
                                                qtyState[bhuName.toLowerCase()], 10,
                                              ) + 1,
                                              sku: data.productListDetail.filter(prod => (
                                                prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                              ))[0].inventory[0].sku,
                                            },
                                          },
                                          update: atcUpdate,
                                        })
                                          .then(() => refetchCartList())
                                          .then(() => {
                                            handleCartSnackbarOpen(bhuName, triggerElem);
                                          });
                                      }}
                                    >
                                      {
                                        (!data.productListDetail.filter(prod => (
                                          prod.gtin13 === bhuState[bhuName.toLowerCase()]
                                        ))[0].inventory[0].onHand) ? 'Sold Out' : 'Add to Cart'
                                      }
                                    </Button>
                                  ) : (
                                    <Button
                                      classes={{ root: classes.rootBtn }}
                                      disabled
                                    >
                                      Sold Out
                                    </Button>
                                  )
                              }
                            </div>
                          </div>
                        );
                      })
                  }
                </div>
              </ThemeProvider>
            </section>
            <section className="bookhookup-disclaimer__section">
              <div className="bookhookup-disclaimer__div">
                <p className="bookhookup-disclaimer__p">
                  <strong>Summer 2024 Boxes will be shipped throughout the month of June. You&apos;ll only be charged when the boxes are ready to be shipped.</strong>
                  <br />
                  <br />
                  <strong>We cannot accept PayPal or gift card payments. For more information please see our product descriptions and&nbsp;</strong>
                  <Link
                    to="/books-media/bookhookup/faq"
                    className="bookhookup-link__link bookhookup-disclaimer-link__link"
                    onClick={() => WithEvent('BOOK_HOOKUP', 'Clicked BHU disclaimer FAQ link', 'BHU_FAQ_LINK', 1)}
                  >
                    FAQ
                  </Link>
                  .
                </p>
              </div>
            </section>
            <section className="bookhookup-partners__section">
              <div className="bookhookup-partners__div">
                <PageSubHeader header="Our Past Partners" />
                <div className="bookhookup-partners-feat__div">
                  <Slider {...settings}>
                    {
                      listBHU.featuredPartners.map(partner => (
                        <div className="bookhookup-partner-slider__div" key={partner.id}>
                          <img
                            src={partner.partnerImage}
                            alt={partner.partnerName}
                            className="bookhookup-partner-slider__img"
                          />
                        </div>
                      ))
                    }
                  </Slider>
                </div>
                <div className="bookhookup-partners-past__div">
                  {
                    listBHU.partnersList.map(partner => (
                      <div className="bookhookup-partner__div" key={partner.id}>
                        <LazyLoad>
                          <img
                            src={partner.partnerImage}
                            alt={partner.partnerName}
                            className="bookhookup-partner__img"
                          />
                        </LazyLoad>
                      </div>
                    ))
                  }
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BookHookUp.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BookHookUp;
