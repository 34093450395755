export const fixPrice = (rPrice, saleMax) => {
  if (saleMax > 0) {
    return saleMax.toFixed(2);
  }
  if (rPrice > 0) {
    return rPrice.toFixed(2);
  }

  return null;
};

const formatPrice = (rPrice, saleMax, saleMin) => {
  if (saleMin) {
    if (saleMin === saleMax) {
      return saleMin.toFixed(2);
    }

    return [saleMin.toFixed(2), saleMax.toFixed(2)];
  }

  if (rPrice > 0) {
    return rPrice.toFixed(2);
  }

  return null;
};

export default formatPrice;
