import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded';

import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import Loading from '../loading/Loading';
import metaListDictionary from '../../helpers/metaListDictionary';

import './_memorial.scss';

const META_LIST_DETAIL = gql`
  query getMetaListDetail($metaTag: String!) {
    metaListDetail(metaTag: $metaTag) {
      metaTag
      product {
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
      }
    }
  }
`;

const Memorial = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const prefix = 'memorial';
  const metalistPath = 'ben_recommends';
  const metalist = metaListDictionary(metalistPath);

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL, {
    variables: {
      metaTag: metalist.metaTag,
    },
  });

  const useStyles = makeStyles({
    rootIconButtonPrev: {
      color: '#ffffff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
    rootNavigate: {
      width: '1.5em',
      height: '1.5em',
    },
    rootIconButtonNext: {
      color: '#ffffff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
    rootIconButton: {
      display: 'none',
    },
    rootIconButtonActive: {
      color: '#ffffff',
      opacity: 1,
      backgroundColor: 'rgba(191, 46, 26, .1)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(191, 46, 26, 1)',
      },
    },
  });

  const classes = useStyles();

  const PrevArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;

    return (
      <div style={{
        ...style,
        // display: 'flex',
        // justifyContent: 'flex-start',
        position: 'absolute',
        zIndex: 8,
        top: '45%',
        left: '10px',
      }}
      >
        <IconButton aria-label="previous" onClick={onClick} classes={{ root: classes.rootIconButtonActive }}>
          <NavigateBeforeRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };

  const NextArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;

    return (
      <div style={{
        ...style,
        // display: 'flex',
        // justifyContent: 'flex-end',
        position: 'absolute',
        zIndex: 8,
        top: '45%',
        right: '10px',
      }}
      >
        <IconButton aria-label="next" onClick={onClick} classes={{ root: classes.rootIconButtonActive }}>
          <NavigateNextRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: Math.min(5, dataMeta?.metaListDetail?.products?.length || 5),
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: Math.min(4, dataMeta?.metaListDetail?.products?.length || 4),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: Math.min(3, dataMeta?.metaListDetail?.products?.length || 3),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, dataMeta?.metaListDetail?.products?.length || 2),
          arrows: false,
        },
      },
      {
        breakpoint: 449,
        settings: {
          slidesToShow: 1,
          arrows: false,
          centerMode: true,
        },
      },
    ],
  };

  use4Cite();

  useEffect(() => {
    if (!dataMeta) {
      return;
    }

    const script = document.createElement('script');
    Object.assign(script, {
      src: '//embedr.flickr.com/assets/client-code.js',
      async: true,
      charSet: 'utf-8',
    });

    document.body.appendChild(script);
  }, [dataMeta]);

  if (errorMeta) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className={`${prefix}-container__div`}>
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className={`${prefix}-container__div`}>
        {scroll > 1000 && <CompFab />}
        <main className={`${prefix}-content__main`}>
          <div className={`${prefix}-content__div`}>
            <section className={`${prefix}-hero__section`}>
              <div className={`${prefix}-hero-heading__div`}>
                <h1 className={`${prefix}-hero-heading__h1`}>
                  Remembering Ben
                </h1>
              </div>

              <div className={`${prefix}-hero-image__div`}>
                <img
                  src={urlGenerator('/S/pages/memorial_hero-2.jpg')}
                  className={`${prefix}-hero-image__img`}
                  alt="Ben McFall"
                />
              </div>
            </section>

            <section className={`${prefix}-intro__section`}>
              <div className={`${prefix}-intro__div`}>
                <p className={`${prefix}-intro__p`}>
                  Mr. Ben McFall was hired on the spot in 1978 by Fred Bass to work at The Strand
                  and became part of the fabric of the bookstore as we know it today. His position
                  held no formal title, but then again it was hard to put a label on his
                  encyclopedic knowledge of books. It is safe to say if you did not know him
                  personally, you certainly knew of him and had seen him at his desk on the main
                  floor. His passing on December 22, 2021 has created a ripple of memories that we
                  will not soon forget. Here’s to the Oracle of The Strand.
                  <br />
                  <br />
                  Our thanks to the&nbsp;
                  <a
                    href="https://www.nytimes.com/2021/12/30/books/ben-mcfall-dead.html"
                    className={`${prefix}-link__link`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    New York Times
                  </a>
                  &nbsp;for composing an incredibly heartwarming tribute
                  to Ben.
                </p>
              </div>
            </section>

            <div className={`${prefix}-divider__div`}>
              <h2 className={`${prefix}-divider-heading__h2`}>
                <strong><em>Ben Recommends</em></strong>
              </h2>
            </div>

            <section className={`${prefix}-recommends__section`}>
              <div className={`${prefix}-recommends-text_div`}>
                <p className={`${prefix}-recommends-text_p`}>
                  Ben has introduced us all to some of our most cherished books. Here are just a few
                  of them.
                </p>
              </div>

              <div
                className={`${prefix}-recommends-slider__div`}
                onFocus={() => {}}
              >
                {dataMeta && (
                  <Slider {...sliderSettings}>
                    {dataMeta.metaListDetail.product.map(product => (
                      <div className={`${prefix}-recommends-slide__div`} key={product.id}>
                        <div className={`${prefix}-recommends-slide-inner__div`}>
                          <div className={`${prefix}-recommneds-product-image__div`}>
                            <Link
                              to={`/metalist/${metalistPath}`}
                              className={`${prefix}-recommends-image__link`}
                              onClick={() => WithEvent('MEMORIAL', `Clicked ${product.title} Image`, 'MEMORIAL_RECOMMENDED_PRODUCT_IMAGE', 1)}
                            >
                              <img
                                src={product.imageUrl}
                                alt={product.title}
                                className={`${prefix}-recommends-product-image__img`}
                              />
                            </Link>
                          </div>

                          <div className={`${prefix}-recommends-product-title__div`}>
                            <Link
                              to={`/metalist/${metalistPath}`}
                              className={`${prefix}-recommends-product-title__link`}
                              onClick={() => WithEvent('MEMORIAL', `Clicked ${product.title} Title`, 'MEMORIAL_RECOMMENDED_PRODUCT_IMAGE', 1)}
                            >
                              {product.title}
                            </Link>
                          </div>

                          <div className={`${prefix}-recommends-product-author__div`}>
                            <p className={`${prefix}-recommends-product-author__p`}>
                              {product.originator}
                            </p>
                          </div>

                          <div className={`${prefix}-recommends-product-price__div`}>
                            <p className={`${prefix}-recommends-product-price__p`}>
                              {`$${(product.retailPrice * 0.01).toFixed(2)}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>

              <div className={`${prefix}-recommends-button__div`}>
                <Link
                  to={`/metalist/${metalistPath}`}
                  className={`${prefix}-recommends-button__link`}
                  onClick={() => WithEvent('MEMORIAL', `Clicked ${metalist.metaTag} Page Link`, 'MEMORIAL_RECOMMENDED_SEE_ALL', 1)}
                >
                  See All
                </Link>
              </div>
            </section>

            <section className={`${prefix}-columns__section`}>
              <div className={`${prefix}-columns-row__div`}>
                <div className={`${prefix}-columns-col__div`}>
                  <div className={`${prefix}-columns-col-inner__div`}>
                    <div className={`${prefix}-columns-head__div`}>
                      <h2 className={`${prefix}-columns-head__h2`}>
                        <em>
                          In Memory Of Ben
                        </em>
                      </h2>
                    </div>

                    <div className={`${prefix}-columns-asset__div`}>
                      <img
                        src={urlGenerator('/S/pages/memorial_young_ben.png')}
                        className={`${prefix}-columns-asset__img`}
                        alt="young Ben McFall"
                      />
                    </div>

                    <div className={`${prefix}-columns-text__div`}>
                      <p className={`${prefix}-columns-text__p`}>
                        Ben&rsquo;s family and friends hope you will help continue his dedication to
                        creating support and space for the LGBTQIA+ community by donating to
                        the&nbsp;
                        <a
                          href="https://hmi.org/"
                          className={`${prefix}-link__link`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hetrick-Martin Institute
                        </a>
                        . The Hetrick-Martin Institute believes all young people, regardless of
                        sexual orientation or identity, deserve a safe and supportive environment in
                        which to achieve their full potential. They create this environment for
                        youth between the ages of 13 and 24 and their families.
                      </p>
                    </div>

                    <div className={`${prefix}-columns-button__div`}>
                      <a
                        href="https://support.hmi.org/site/Donation2;jsessionid=00000000.app30121a?1400.donation=form1&df_id=1400&mfc_pref=T&NONCE_TOKEN=E274ED1610836071564782B35276A670"
                        className={`${prefix}-columns-button__link`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Donate Now
                      </a>
                    </div>

                    <div className={`${prefix}-columns-bottom-text__div`}>
                      <p className={`${prefix}-columns-bottom-text__p`}>
                        When donating, please share Ben’s name under the “Tribute Gift” section
                      </p>
                    </div>
                  </div>
                </div>

                <div className={`${prefix}-columns-col__div`}>
                  <div className={`${prefix}-columns-col-inner__div`}>
                    <div className={`${prefix}-columns-head__div`}>
                      <h2 className={`${prefix}-columns-head__h2`}>
                        <em>A Celebration of Life</em>
                      </h2>
                    </div>

                    <div className={`${prefix}-columns-asset__div`}>
                      <div className={`${prefix}-columns-asset-wrapper__div`}>
                        <iframe
                          className={`${prefix}-columns-asset__iframe`}
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/bXNyRfJGhDM"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    </div>

                    <div className={`${prefix}-columns-text__div`}>
                      <p className={`${prefix}-columns-text__p`}>
                        <strong>
                          Sunday, March 13, 2022
                          <br />
                          5PM ET
                          <br />
                          The Strand Rare Book Room
                        </strong>
                        <br />
                        <br />
                        Thank you for joining us as we remember Ben McFall.
                      </p>
                    </div>

                    <div className={`${prefix}-columns-button__div`}>
                      <a
                        href="https://www.eventbrite.com/e/ben-mcfall-a-celebration-of-life-tickets-269959685687"
                        className={`${prefix}-columns-button__link`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Join Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className={`${prefix}-divider__div`}>
              <h2 className={`${prefix}-divider-heading__h2`}>
                <strong><em>Got Photos?</em></strong>
              </h2>
            </div>

            <section className={`${prefix}-photos__section`}>
              <div className={`${prefix}-photos-text__div`}>
                <p className={`${prefix}-photos-text__p`}>
                  Do you have a photo or story you&rsquo;d like to share about Ben? We&rsquo;re
                  collecting memories from customers, coworkers, book lovers, and New Yorkers. Send
                  your contribution to&nbsp;
                  <a
                    href="mailto:rememberingben@strandbooks.com"
                    className={`${prefix}-link__link`}
                  >
                    rememberingben@strandbooks.com
                  </a>
                  &nbsp;or use the hashtag&nbsp;
                  <a
                    href="https://www.instagram.com/explore/tags/rememberingbenmcfall/"
                    className={`${prefix}-link__link`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #RememberingBenMcfall
                  </a>
                  &nbsp;in your social media posts or comments.
                </p>
              </div>

              <div className={`${prefix}-photos-slides__div`}>
                <div className={`${prefix}-photos-slides-container__div`}>
                  <a data-flickr-embed="true" href="https://www.flickr.com/photos/149458413@N08" title="">
                    <img src="https://live.staticflickr.com/65535/51855930932_4110048d48.jpg" width="600" height="450" alt="" className={`${prefix}-photos-flickr__img`} />
                  </a>
                </div>

                <div className={`${prefix}-photos-slides-text__div`}>
                  <p className={`${prefix}-photos-slides-text__p`}>
                    Hover over the picture to scroll through more photos.
                  </p>
                </div>
              </div>

              <div className={`${prefix}-photos-button__div`}>
                <a
                  href="https://www.flickr.com/photos/149458413@N08"
                  className={`${prefix}-photos-button__link`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  See Full Gallery
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Memorial.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Memorial;
