import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ReactPixel from 'react-facebook-pixel';
import ReactTwitterTracker from 'react-twitter-conversion-tracker';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('1440520756350220', advancedMatching, options);

ReactTwitterTracker.init('o7kfr');

Sentry.init({
  dsn: 'https://c85bac44a7934fa996916d02d3d70b38@o392278.ingest.sentry.io/5239572',
  release: process.env.REACT_APP_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
