import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, Redirect } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import urlGenerator from '../../helpers/urlGenerator';
import './_checkoutConfirmation.scss';

const CheckoutConfirmationPage = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const location = useLocation();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (location && !location.state) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="checkoutconfirmation-container__div">
        <main className="checkoutconfirmation-main__main">
          <div className="checkoutconfirmation-main__div">
            <div className="checkoutconfirmation-content__div">
              <h1 className="checkoutconfirmation-header__h1">Thank You!</h1>
              <div className="checkoutconfirmation-image__div">
                <img
                  className="checkoutconfirmation-image__img"
                  src={urlGenerator('/S/static/confirmation_owl.png')}
                  alt="Order Confirmed"
                />
              </div>
              <h2 className="checkoutconfirmation-header__h2">{`Order Confirmation# ${location?.state?.orderNo}`}</h2>
              <div className="checkoutconfirmation-details__div">
                <p className="checkoutconfirmation-details__p">
                  Your order has been placed.
                  <br />
                  You will receive a confirmation email containing the details of your order.
                  <br />
                  <br />
                  If you have any questions regarding your order, please contact us at&nbsp;
                  <a className="checkoutconfirmation-link__a" href="mailto:customerservice@strandbooks.com">
                    customerservice@strandbooks.com
                  </a>
                  .
                  <br />
                  <br />
                  Thank you for shopping with us!
                </p>
              </div>
              <div className="checkoutconfirmation-btn__div">
                <Link
                  to="/"
                  className="checkoutconfirmation-btn__link"
                  onClick={() => WithEvent('CHECKOUT', 'Click continue shopping button', 'CONTINUE_BTN', 1)}
                >
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

CheckoutConfirmationPage.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default CheckoutConfirmationPage;
