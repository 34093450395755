/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import NavSidebar from '../navSidebar/NavSidebar';
import PageHeader from '../pageHeader/PageHeader';
import './_sellYourBooks.scss';
import use4Cite from '../../hooks/use4Cite';
import urlGenerator from '../../helpers/urlGenerator';

const SellYourBooks = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const scroll = useScrollY();

  const match767 = useMediaQuery('(min-width: 767px)');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <section className="sellyourbooks-hero__section">
        <div className="sellyourbooks-hero__div">
          { scroll > 1000 && <CompFab /> }
          <div className="sellyourbooks-header-border__div">
            <div className="sellyourbooks-header__div">
              <h1 className="sellyourbooks-header__h1">Sell Your Books</h1>
            </div>
          </div>
        </div>
      </section>
      <h3 className="sellyourbooks-header__h3"><strong>oil painting by Max Ferguson</strong></h3>
      <div className="sellyourbooks-container__div">
        <div className="sellyourbooks-content__div">
          <section className="sellyourbooks-details__section">
            <div className="sellyourbooks-intro__div">
              <div className="sellyourbooks-intro-image__div">
                <img className="sellyourbooks-intro-image__img" alt="Strand Book Store" src={urlGenerator('/S/pages/sellbooks_finding_new_homes_for_your_books.jpg')} />
              </div>
            </div>

            <PageHeader header="Sell Your Books" />

            <div className="sellyourbooks-subheader__div">
              {/*
              <p className="sellyourbooks-subheader__p">
                The Buying Desk will be closed on 12/25 and 1/1/2022.
              </p>
              */}
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="hours">Hours &amp; Location</h2>
              <div className="sellyourbooks-accent__div" />
              <p className="sellyourbooks-details__p sellyourbooks-details__p--strong">
                When: THURSDAY, FRIDAY, SATURDAY 10:00AM-4:00PM
                <br />
                <br />
                Where: 828 Broadway, Seller’s Entrance at 12th Street between Broadway and 4th Avenue
                <br />
                <br />
                No appointment necessary to sell up to 40 books.
                <br />
                <em>Be advised due to Covid-19 the line will be outside, rain or shine. Please pack your books accordingly.</em>
              </p>
            </div>

            <div className="sellyourbooks-steps__div">
              <div className="sellyourbooks-step__div sellyourbooks-step__div--border-right">
                <h2 className="sellyourbooks-header__h2 sellyourbooks-header__h2--center" id="hours">
                  A
                  <br />
                  Bring Your Gently Used Books
                </h2>
                <div className="sellyourbooks-details__div">
                  <div className="sellyourbooks-accent__div" />
                  <p className="sellyourbooks-details__p sellyourbooks-details__p--center">
                    We can process up to 40 books during our buying hours.
                  </p>
                </div>
              </div>

              <div className="sellyourbooks-step__div sellyourbooks-step__div--border-right">
                <h2 className="sellyourbooks-header__h2 sellyourbooks-header__h2--center" id="hours">
                  B
                  <br />
                  We&#39;ll Make An Offer
                </h2>
                <div className="sellyourbooks-details__div">
                  <div className="sellyourbooks-accent__div" />
                  <p className="sellyourbooks-details__p sellyourbooks-details__p--center">
                    You get cash or store credit (50% more value) for books we can use.
                  </p>
                </div>
              </div>

              <div className="sellyourbooks-step__div">
                <h2 className="sellyourbooks-header__h2 sellyourbooks-header__h2--center" id="hours">
                  C
                  <br />
                  You Get Cash (or Store Credit)
                </h2>
                <div className="sellyourbooks-details__div">
                  <div className="sellyourbooks-accent__div" />
                  <p className="sellyourbooks-details__p sellyourbooks-details__p--center">
                    ...and your old books find a new home.
                  </p>
                </div>
              </div>

            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="sell-books">Sell Us Your Books…</h2>
              <div className="sellyourbooks-accent__div" />
              <ul className="sellyourbooks-list__ul">
                <li className="sellyourbooks-list-item__li">Must be 18 years of age to sell books.</li>
                <li className="sellyourbooks-list-item__li">Books must be evaluated in person to make an offer.</li>
                <li className="sellyourbooks-list-item__li">Each buy is different and will be evaluated individually.</li>
                <li className="sellyourbooks-list-item__li">We&#39;ll make you an offer based on the condition as well as supply and demand.</li>
                <li className="sellyourbooks-list-item__li">Sellers must be present while the evaluation is completed.</li>
                <li className="sellyourbooks-list-item__li">Be prepared to show a government-issued photo ID if asked.</li>
                <li className="sellyourbooks-list-item__li">We may ask to see valid proof of purchase for some of the items you sell to us.</li>
                <li className="sellyourbooks-list-item__li">We reserve the right to decline any items brought to us.</li>
                <li className="sellyourbooks-list-item__li">Sellers under 18 years of age must be accompanied by a parent or guardian.</li>
              </ul>
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="good-value">Books of Good Commercial Value</h2>
              <div className="sellyourbooks-accent__div" />
              <ul className="sellyourbooks-list__ul">
                <li className="sellyourbooks-list-item__li">
                  Books in excellent condition, free of damage, underlining/highlighting, etc.
                </li>
                <li className="sellyourbooks-list-item__li">
                  <strong> Sought After Subjects, such as, but not limited to:</strong>
                  , such as: Philosophy, Science, Mathematics, Black Studies, Sports, Music, Occult, Fashion, Art Technique, Animation, Erotica, Graphic Novels, Sci-Fi &amp; Fantasy, New York, Maritime, Mythology &amp; Folklore, Plays, Sheet Music
                </li>
                <li className="sellyourbooks-list-item__li">
                  <strong>Recently Published and Perennial Classics</strong>
                  &nbsp;in all subjects; from fiction to science and beyond!
                </li>
                <li className="sellyourbooks-list-item__li">
                  <strong>Collectibles:</strong>
                  &nbsp;fine bindings, signed books and autograph material, collected first edition authors, collected early classic books, and other rare and antiquarian books and manuscripts.
                </li>
                <li className="sellyourbooks-list-item__li">
                  <strong>Good Quality Books</strong>
                  : Distinguished by high quality production, high quality illustration, or fascinating subject matter. Examples: Folio Books, Private presses, etc.
                </li>
              </ul>
              <br />
            </div>

            <div className="sellyourbooks-image__div sellyourbooks-image__div--center">
              <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sellbooks_good-commercial.png')} alt="sell your books" />
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="bad-value">Books & Material  We Are Often Unable to Pay For:</h2>
              <div className="sellyourbooks-accent__div" />
              <ul className="sellyourbooks-list__ul">
                <li className="sellyourbooks-list-item__li">
                  <strong>Books in poor condition</strong>
                  . This includes highlighting, underlining, tears to the pages or covers, moisture damage, mold or mildew, excessive odors including: cigarette smoke, stains, bug residue, animal hair or chewing, excessive dirt or dust, or heavy wear of any kind.
                </li>
                <li className="sellyourbooks-list-item__li">
                  <strong>Ex-Library Books</strong>
                </li>
                <li className="sellyourbooks-list-item__li">Most children`s books</li>
                <li className="sellyourbooks-list-item__li">VHS, cassette tapes, DVDs or CDs</li>
                <li className="sellyourbooks-list-item__li">
                  Most&nbsp;
                  <strong>hard-cover popular fiction</strong>
                  , once it has been released in paperback
                </li>
                <li className="sellyourbooks-list-item__li">Reader&#39;s Digest Condensed Books</li>
                <li className="sellyourbooks-list-item__li">Time-Life published books</li>
                <li className="sellyourbooks-list-item__li"> Textbooks and older reference (almanacs, study guides, travel guides, computer books, etc.)</li>
                <li className="sellyourbooks-list-item__li">Magazines or periodicals of any kind</li>
                <li className="sellyourbooks-list-item__li">Most Encyclopedia and Dictionary sets</li>
                <li className="sellyourbooks-list-item__li">Any item that we are presently overstocked on (by title or subject category)</li>
              </ul>
            </div>

            <div className="sellyourbooks-image__div sellyourbooks-image__div--center">
              <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sellbooks_unable.png')} alt="sell your books" />
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="large-qty">
                For Larger Quantities (More Than 40 Books)
              </h2>
              <div className="sellyourbooks-accent__div" />
              <p className="sellyourbooks-details__p">
                Please email us at&nbsp;
                <a className="sellyourbooks-details-link__a" title="mailto:buyers@strandbooks.com" href="mailto:buyers@strandbooks.com">buyers@strandbooks.com</a>
                &nbsp;to discuss the best way to proceed. Please provide answers to the following questions in your email:
              </p>
              <ul className="sellyourbooks-list__ul">
                <li className="sellyourbooks-list-item__li">
                  How many books do you have for sale? (You can count a couple shelves, get an average and multiply by the number of shelves to get an estimate.)
                </li>
                <li className="sellyourbooks-list-item__li">What is the condition of the books?</li>
                <li className="sellyourbooks-list-item__li">What subject matters are you selling? (Not individual titles)</li>
                <li className="sellyourbooks-list-item__li">
                  Have you already sold or disposed of any of the collection?
                </li>
                <li className="sellyourbooks-list-item__li">Where are the books located?</li>
                <li className="sellyourbooks-list-item__li">If contacting us by email, please include a phone number where you can be reached.</li>
              </ul>
              <p className="sellyourbooks-details__p">
                When sending an email to&nbsp;
                <a className="sellyourbooks-details-link__a" href="mailto: buyers@strandbooks.com">buyers@strandbooks.com</a>
                , please include photos of the bookshelves in which the spines are legible (no need to photograph each book individually). Photos are much more helpful than lists and faster to create! Please see the examples below. ( Please allow up to 72 hours for a response.)
              </p>
              <div className="sellyourbooks-sample__div">
                <div className="sellyourbooks-image__div">
                  <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sell-books-2.JPG')} alt="sell your books" />
                </div>
                <div className="sellyourbooks-image__div">
                  <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sell-books-1.JPG')} alt="sell your books" />
                </div>
              </div>
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="packing-boxes">Tips for Packing Boxes</h2>
              <div className="sellyourbooks-accent__div" />
              <p className="sellyourbooks-details__p">
                We encourage you to pack your books in a sturdy box of appropriate size to keep them from becoming damaged in transit. Please use boxes easily carried by a single person. We encourage you to pack your books in a similar fashion to the image shown below, to keep them in good condition.
                <strong>Pack the box fully to the top and along its sides to prevent it from being crushed in transit.</strong>
                Please remember that any damage that occurs in transit will likely affect our ability to pay for the book.
              </p>
            </div>

            <div className="sellyourbooks-packing__div">
              <div className="sellyourbooks-image__div">
                <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sellbooks_pack-4.jpg')} alt="sell your books" />
              </div>
              <div className="sellyourbooks-image__div">
                <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sellbooks_pack-5.jpg')} alt="sell your books" />
              </div>
            </div>

            <div className="sellyourbooks-image__div sellyourbooks-image__div--center">
              <img className="sellyourbooks-image__img" src={urlGenerator('/S/pages/sellbooks_pack-3.png')} alt="sell your books" />
            </div>

            <div className="sellyourbooks-details__div">
              <h2 className="sellyourbooks-header__h2" id="contact">Contact</h2>
              <div className="sellyourbooks-accent__div" />
              <p className="sellyourbooks-details__p">
                {/* Please call&nbsp;
                <a className="sellyourbooks-details-link__a" href="tel: 2124731452">
                  (212) 473-1452
                </a>
                &nbsp;ext. 240 for more information.
                <br /> */}
                <a className="sellyourbooks-details-link__a" href="mailto: buyers@strandbooks.com">
                  buyers@strandbooks.com
                </a>
                &nbsp;(Please allow up to 72 hours for a response.)
              </p>
              <br />
              <br />
              Strand Book Store buyers will&nbsp;
              <strong>only</strong>
              &nbsp;reach out to you or conduct business from an official&nbsp;
              <a className="sellyourbooks-details-link__a" href="/">
                strandbooks.com
              </a>
               &nbsp;email address (i.e.&nbsp;
              <a className="sellyourbooks-details-link__a" href="mailto:buyers@strandbooks.com">
                buyers@strandbooks.com
              </a>
              ) or a pre-arranged phone call. Buyers never conduct business via sites like Facebook, Facebook Marketplace, WhatsApp, Ebay, etc. If you are approached on social media by someone claiming to be a Strand Book Store buyer please report them and refuse to do business with them.
            </div>
          </section>
          {
            match767
              && (
                <section className="sellyourbooks-nav__section">
                  <NavSidebar content={[{ name: 'Sell Us Your Books', id: 'sell-books' }, { name: 'Books of Good Commercial Value', id: 'good-value' }, { name: 'Books We\'re Often Unable to Pay For', id: 'bad-value' }, { name: 'Tips for Packing Boxes', id: 'packing-boxes' }, { name: 'For Larger Quantities (More Than 40 Books)', id: 'large-qty' }, { name: 'Contact', id: 'contact' }]} />
                </section>
              )
          }
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

SellYourBooks.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default SellYourBooks;
