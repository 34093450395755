import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { Button, makeStyles } from '@material-ui/core';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import listEvents from './listEvents';
import CompBack from '../compBack/CompBack';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_event.scss';

const useStyles = makeStyles({
  disabledBtn: {
    '&.Mui-disabled': {
      color: '#5f5f5f',
      fontWeight: 'bold',
    },
  },
});

const Event = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const classes = useStyles();

  const { match } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (match?.params?.event) {
    const reducedDictionary = listEvents.reduce((acc, cv) => ({
      ...acc,
      [cv.id]: true,
    }), {});

    if (!reducedDictionary[match.params.event]) {
      return <Redirect to="/page-not-found" />;
    }
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="event-container__div">
        <main className="event-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="event-content__div">
            <div className="event-back__div">
              <CompBack backURL="/events" />
            </div>
            <section className="event-event__section">
              <div className="event-event__div">
                {
                  listEvents
                    .filter(event => event.id === match.params.event)
                    .map((event) => {
                      const {
                        id,
                        eventName,
                        imageURL,
                        hideDateTime,
                        startsAt,
                        endsAt,
                        description,
                        authorImageURL,
                        authorBio,
                        otherAssets,
                        credit,
                        imageTop,
                        imageTopIntro,
                        imageBottom,
                        imageBottomURL,
                        productImg,
                        productButton,
                        soldOut,
                        externalURL,
                        externalCtaText,
                      } = event;

                      const mStart = moment(startsAt, 'MM/DD/YYYY hh:mmA');
                      const mEnd = moment(endsAt, 'MM/DD/YYYY hh:mmA');

                      let timeFrame;

                      if (mStart.clone().startOf('day').isSame(mEnd.clone().startOf('day'))) {
                        timeFrame = `${mStart.format('dddd MMMM DD hh:mmA')}-${mEnd.format('hh:mmA')} ET`;
                      } else {
                        timeFrame = `${mStart.format('dddd MMMM DD hh:mmA')} through ${mEnd.format('dddd MMMM DD hh:mmA')} ET`;
                      }

                      return (
                        <div key={id} className="event-details__div">
                          <div className="event-book-image__div">
                            <img src={imageURL} alt="event-banner" className="event-book-image__img" />

                            {soldOut ? (
                              <div className="event-ticket-btn__div">
                                <Button
                                  classes={{
                                    disabled: classes.disabledBtn,
                                  }}
                                  disableRipple
                                  disabled
                                >
                                  Sold Out
                                </Button>
                              </div>
                            ) : Boolean(externalURL?.length) && (
                              <div className="event-ticket-btn__div">
                                <a
                                  href={externalURL}
                                  onClick={() => WithEvent('EVENT', `Clicked ${eventName} ticket button`, 'EVENT_CTA', 1)}
                                  className="event-ticket-btn__a"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {externalCtaText?.length ? externalCtaText : 'Purchase a ticket here'}
                                </a>
                              </div>
                            )}
                          </div>
                          <div className="event-copy__div">
                            <h1 className="event-header__h1" dangerouslySetInnerHTML={{ __html: eventName }} />
                            <div className="event-accent__div" />
                            {!hideDateTime && (
                              <div className="event-date__div">
                                <p className="event-details__p">
                                  {timeFrame}
                                </p>
                              </div>
                            )}
                            {
                              imageTop
                                && imageTop.length > 0
                                && (
                                  <div className="event-image-top__div">
                                    <img
                                      alt="eventName"
                                      src={imageTop}
                                      className="event-image-top__img"
                                    />
                                  </div>
                                )
                            }
                            {
                              imageTopIntro
                                && imageTopIntro.length > 0
                                && <div className="event-image-top-intro__div" dangerouslySetInnerHTML={(() => ({ __html: imageTopIntro }))()} />
                            }
                            {
                              productImg.length > 0
                                && productButton.length > 0
                                && (
                                  <div className="event-product__div">
                                    <div className="event-product-img__div">
                                      <img
                                        src={productImg}
                                        alt={eventName}
                                        className="event-product-img__img"
                                      />
                                    </div>
                                    {
                                      soldOut
                                        ? (
                                          <div className="event-product-btn__div--soldout">
                                            <p className="event-product-btn__p">Sold Out</p>
                                          </div>
                                        )
                                        : (
                                          <div className="event-product-btn__div">
                                            <a
                                              href={productButton}
                                              onClick={() => WithEvent('EVENT', `Clicked ${eventName} button`, 'EVENT_CTA', 1)}
                                              className="event-product-btn__a"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              Buy Now
                                            </a>
                                          </div>
                                        )
                                    }
                                  </div>
                                )
                            }
                            <div className="event-description__div" dangerouslySetInnerHTML={(() => ({ __html: description }))()} />
                            <div className="event-authors__div">
                              {
                                !authorImageURL.length && authorBio.length
                                  ? authorBio.map((bio, i) => (
                                    <div key={`bio-${id + i}`} className="event-bio__div" dangerouslySetInnerHTML={(() => ({ __html: bio }))()} />
                                  )) : ''
                              }
                              {
                                authorImageURL.length >= authorBio.length
                                  ? authorImageURL.map((authorImg, i) => (
                                    <div key={`${id + i}`}>
                                      {authorImg?.length && (
                                        <div key={`${id + i}`} className="event-author-image__div">
                                          <img src={authorImg} alt={`Event Guest ${i + 1}`} className="event-author-image__img" />
                                        </div>
                                      )}
                                      {credit.length > 0 && credit[i]?.length && (
                                        <p key={`${id + i}`} className="event-credit__p">
                                          ©
                                          {credit[i]}
                                        </p>
                                      )}
                                      <div className="event-bio__div" dangerouslySetInnerHTML={(() => ({ __html: authorBio[i] }))()} />
                                    </div>
                                  )) : ''
                              }
                              {
                                authorImageURL.length && authorImageURL.length < 2 && authorBio.length > 1 ? (
                                  <div key="2" className="event-bio__div" dangerouslySetInnerHTML={(() => ({ __html: authorBio[1] }))()} />
                                ) : ''
                              }
                            </div>
                            {
                              otherAssets.map((asset, i) => (
                                <div key={`${id + i}`} className="event-others__div" dangerouslySetInnerHTML={(() => ({ __html: asset }))()} />
                              ))
                            }
                            {
                              imageBottom
                                && imageBottom.length > 0
                                && imageBottomURL.length > 0
                                && (
                                  <a
                                    className="event-bottom-image-link__a"
                                    href={imageBottomURL}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <div className="event-bottom-image__div">
                                      <img src={imageBottom} alt="bottom banner" className="event-bottom-image__img" />
                                    </div>
                                  </a>
                                )
                            }
                          </div>
                        </div>
                      );
                    })
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Event.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Event;
