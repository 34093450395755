import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, Link } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import bbtfList from './bbtfList';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import './_bbtfCategory.scss';

export const GET_PRODUCT_SECTION = gql`
  query sectionProducts($section: String!, $param: ProductParamTypeIn!, $order: OrderCommonIn){
    productBySection(section: $section, param: $param, order: $order){
      product{
        id
        gtin13
        imageUrl
        title
        inventory{
          sku
          onHand
          salePriceStv
        }
      }
    }
  }
`;

const BBTFCategory = (props) => {
  const {
    match,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const { category } = match.params;
  const scroll = useScrollY();

  const {
    data,
    error,
    loading,
  } = useQuery(GET_PRODUCT_SECTION, {
    variables: {
      section: 'BKS/FT',
      param: {},
      order: {
        dir: 'ASC',
        orderBy: 'TITLE',
      },
    },
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (error) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtfcategory-container__div">
        {scroll > 1000 && <CompFab />}
        <div className="bbtfcategory-hero__div" />
        <div className="bbtfcategory-content__div">
          <PageHeader
            header={`Books by ${category.toLowerCase().slice(0, 1).toUpperCase()}${category.toLowerCase().slice(1)}`}
            text={category === 'color' && (
              <>
                Don&apos;t see the color you want? Reach out to&nbsp;
                <a href="mailto:bbtf@strandbooks.com">bbtf@strandbooks.com</a>
                &nbsp;for more color options.
              </>
            )}
          />
          <section className="bbtfcategory-products__section">
            <div className="bbtfcategory-products__div">
              {
                data
                  && data.productBySection
                  && data.productBySection.length > 0
                  && data.productBySection[0].product
                    .filter((prod) => {
                      if (category.toLowerCase() === 'color') {
                        return bbtfList.booksColor.indexOf(prod.gtin13) > -1;
                      }

                      if (category.toLowerCase() === 'subject') {
                        return bbtfList.booksSubject.indexOf(prod.gtin13) > -1;
                      }

                      return bbtfList.booksStyle.indexOf(prod.gtin13) > -1;
                    })
                    .map((prod) => {
                      const {
                        gtin13,
                        imageUrl,
                        id,
                        title,
                        inventory,
                      } = prod;

                      return (
                        <div key={id} className="bbtfcategory-product__div">
                          <Link
                            to={`/product/${gtin13}`}
                            className="bbtfcategory-product-link__link"
                            onClick={() => WithEvent('BBTF', `Clicked ${title}`, 'BBTF_CATEGORY', 1)}
                          >
                            <div className="bbtfcategory-image__div">
                              <img src={imageUrl} alt={title} className="bbtfcategory-image__img" />
                            </div>
                            <div className="bbtfcategory-title__div">
                              <h4 className="bbtfcategory-header__h4">{title}</h4>
                            </div>
                            <div className="bbtfcategory-details__div">
                              {
                                inventory
                                  && inventory.length > 0
                                  && inventory[0].salePriceStv
                                  && <p className="bbtfcategory-details__p">{`$${(inventory[0].salePriceStv / 100).toFixed(2)}`}</p>
                              }
                            </div>
                          </Link>
                        </div>
                      );
                    })
              }
            </div>
          </section>
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFCategory.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFCategory;
