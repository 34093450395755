import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import WithEvent from '../withEvent/WithEvent';
import navMenuData from './navMenuData';

const menuItems = navMenuData.slice(1).reduce((acc, curr, i) => {
  acc.push(i);
  acc.push(curr);

  return acc;
}, [navMenuData[0]]);

const NavMenu = (props) => {
  const {
    subMenuState,
    setSubMenu,
  } = props;

  const handleExit = () => setSubMenu(null);

  const items = menuItems.map((menuProps) => {
    if (typeof menuProps === 'number') {
      return (
        <div
          key={menuProps}
          className="navbardesktop-link-border__div"
        />
      );
    }

    const {
      id,
      title,
      url,
      subMenu,
    } = menuProps;

    const handleEnter = () => setSubMenu(id);

    const active = subMenuState === id;
    const activeNodes = active && (
      <>
        <div className="navbardesktop-dash__div" />
        <div className="navbardesktop-submenu-arrow__div" />
      </>
    );
    const submenuClass = `navbardesktop-submenu__div${active ? ' navbardesktop-submenu__div--active' : ''}`;
    let Component;
    let componentProps;

    if (url) {
      Component = NavLink;
      componentProps = {
        to: url,
        className: 'navbardesktop-bottom-link__navlink',
        activeClassName: 'navbardesktop-link__navlink--active',
        onClick: () => WithEvent('NAVBAR', `Clicked ${title} link`, 'NAVBAR_LINK', 1),
      };
    } else {
      Component = 'button';
      componentProps = {
        className: 'navbardesktop-bottom-link__button',
        type: 'button',
        onClick: handleEnter,
      };
    }

    return (
      <div
        key={id}
        className="navbardesktop-link__div"
        onMouseEnter={handleEnter}
        onFocus={handleEnter}
      >
        <Component
          {...componentProps}
        >
          {title}
        </Component>

        {activeNodes}

        {subMenu?.length && (
          <div className={submenuClass}>
            <div className="navbardesktop-submenu-links__div">
              {subMenu.map((subMenuProps) => {
                const { title: subTitle, url: subUrl } = subMenuProps;
                const external = !/^[/#]/.test(subUrl);

                let LinkComponent;
                let linkProps;

                if (external) {
                  LinkComponent = 'a';

                  linkProps = {
                    href: subUrl,
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  };
                } else {
                  LinkComponent = NavLink;

                  linkProps = {
                    to: subUrl,
                  };
                }

                return (
                  <LinkComponent
                    key={subTitle}
                    className="navbardesktop-bottom-link__navlink"
                    onClick={() => WithEvent('NAVBAR', `Clicked ${subTitle} link`, 'NAVBAR_LINK', 1)}
                    {...linkProps}
                  >
                    {subTitle}
                  </LinkComponent>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div
      className="navbardesktop-bottom__div"
      onMouseLeave={handleExit}
      onBlur={handleExit}
    >
      {items}
    </div>
  );
};

NavMenu.propTypes = {
  subMenuState: PropTypes.string,
  setSubMenu: PropTypes.func.isRequired,
};

NavMenu.defaultProps = {
  subMenuState: null,
};

export default NavMenu;
