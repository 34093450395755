import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEqual from 'lodash/isEqual';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import LazyLoad, { forceCheck } from 'react-lazyload';
import convertURL from '../../helpers/convertURL';
import useRootList from '../../hooks/useRootList';
import useProductListFilter from '../../hooks/useProductListFilter';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import stringLimiter from '../../helpers/stringLimiter';
import formatPrice from '../../helpers/formatPrice';
import lowestPrice from '../../helpers/lowestPrice';
import Loading from '../loading/Loading';
import ProductListPagination from '../productListPagination/ProductListPagination';
import ProductListPageOps from '../productListPageOps/ProductListPageOps';
import ProductListDrawer from '../productListDrawer/ProductListDrawer';
import './_productList.scss';

// GQL to get root page

// const GET_ROOT_LIST = gql`
//  query getRootList{
//     uiRootList {
//       active
//       publishAfter
//       rootName
//       uiCompInstId
//       uiRootId
//       url
//     }
//   }
// `;

const RENDER_ROOT = gql`
  query renderRoot ($rootName: String!, $uiRootId: ID) {
    uiRootRender(rootName: $rootName, uiRootId: $uiRootId){
      components{
        queryData
        uiCompInst{
          createdAt
          initialVariables
          modifiedAt
          prop
          uiComp{
            adminUi
            available
            createdAt
            description
            name
            publicUi
            sharedQuery
            uiCompId
          }
          uiCompId
          uiCompInstId
        }
        uiCompInstId
      }
      rootCompInstId
      rootId
    }
  }
`;

const ProductListHook = (props) => {
  const {
    match,
    location,
    history,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const { search } = location;
  const { productList } = match.params;
  const match767 = useMediaQuery('(min-width: 767px)');
  // console.log(props);

  const prodFilterPayload = useProductListFilter(); // provides template of the filter options

  const [paginationState, setPagination] = useState({ offset: 0, currentPage: 0 });

  const [pageOptionsState, setPageOptions] = useState({ sort: 1, pageLimit: 40 });

  // console.log('prodFilterPayload', prodFilterPayload);

  useEffect(() => {
    forceCheck();
    // if page didn't come from a link, populate the params
    if (!search) {
      history.replace(
        `/productlist/${productList}?page=1&pagelimit=40&sort=1&signed=false&bookbindings=null&bookcondition=null&booksection=null&bookprice=null`,
        {
          URLOffset: 0,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.pageLimit,
          URLPageFilters: prodFilterPayload.prodFilterState,
        },
      );
    }
    // if it has the search params, do populate state
    if (search.match(/page=[0-9]*/)
    && search.match(/pagelimit=40|pagelimit=60|pagelimit=120*/)
    && search.match(/sort=[1234]{1,}/)
    && search.match(/signed=false|signed=true/)
    && search.match(/bookbindings=[^&]*/)
    && search.match(/bookcondition=[^&]*/)
    && search.match(/booksection=[^&]*/)
    && search.match(/bookprice=[^&]*/)
    ) {
      if (paginationState.currentPage !== parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1) {
        setPagination({
          currentPage: parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1,
          offset: (parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1) * pageOptionsState.pageLimit,
        });
      }

      if (pageOptionsState.pageLimit !== parseInt(search.match(/pagelimit=[0-9]*/)[0].slice(10), 10)) {
        setPageOptions({
          pageLimit: parseInt(search.match(/pagelimit=[0-9]*/)[0].slice(10), 10),
          sort: pageOptionsState.sort,
        });
      }

      if (pageOptionsState.sort !== parseInt(search.match(/sort=[0-9]*/)[0].slice(5), 10)) {
        setPageOptions({
          sort: parseInt(search.match(/sort=[0-9]*/)[0].slice(5), 10),
          pageLimit: pageOptionsState.pageLimit,
        });
      }

      // filter options

      if (prodFilterPayload.prodFilterState.checkSigned !== JSON.parse(search.match(/signed=false|signed=true/)[0].slice(7))) {
        prodFilterPayload.handleProdFilter({ checkSigned: JSON.parse(search.match(/signed=false|signed=true/)[0].slice(7)) });
      }

      if (search.match(/bookbindings=[^&]*/)[0].slice(13) === 'null') {
        if (prodFilterPayload.prodFilterState.bookBindings.length) {
          prodFilterPayload.handleProdFilter({ bookBindings: [] });
        }
      }

      if (search.match(/bookbindings=[^&]*/)[0].slice(13) !== 'null') {
        if (prodFilterPayload.prodFilterState.bookBindings.map(binding => binding.split(' ').join('+')).join('|').toLowerCase() !== search.match(/bookbindings=[^&]*/)[0].slice(13).toLowerCase()) {
          prodFilterPayload.handleProdFilter({ bookBindings: search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(binding => binding.split('+').join(' ')) });
        }
      }

      if (search.match(/bookcondition=[^&]*/)[0].slice(14) === 'null') {
        if (prodFilterPayload.prodFilterState.bookCondition.length) {
          prodFilterPayload.handleProdFilter({ bookCondition: [] });
        }
      }

      if (search.match(/bookcondition=[^&]*/)[0].slice(14) !== 'null') {
        if (prodFilterPayload.prodFilterState.bookCondition.map(condition => condition.split(' ').join('+')).join('|').toLowerCase() !== search.match(/bookcondition=[^&]*/)[0].slice(14).toLowerCase()) {
          prodFilterPayload.handleProdFilter({ bookCondition: search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')) });
        }
      }

      if (search.match(/booksection=[^&]*/)[0].slice(12) === 'null') {
        if (prodFilterPayload.prodFilterState.bookSection.length) {
          prodFilterPayload.handleProdFilter({ bookSection: [] });
        }
      }

      if (search.match(/booksection=[^&]*/)[0].slice(12) !== 'null') {
        if (prodFilterPayload.prodFilterState.bookSection.map(sec => sec.split(' ').join('+')).join('|').toLowerCase() !== search.match(/booksection=[^&]*/)[0].slice(12).toLowerCase()) {
          prodFilterPayload.handleProdFilter({ bookSection: search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(sec => sec.split('+').join(' ')) });
        }
      }

      // if (search.match(/bookprice=[^&]*/)[0].slice(10) === 'null') {
      //   if (prodFilterPayload.prodFilterState.bookPrice.range[0] !== parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[0])
      //   || prodFilterPayload.prodFilterState.bookPrice.range[1] !== parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[1])) {
      //     prodFilterPayload.handleProdFilter({ bookPrice: { min: prodFilterPayload.prodFilterState.bookPrice.min, max: prodFilterPayload.prodFilterState.bookPrice.max, range: search.match(/bookprice=[^&]*/)[0].slice(10).split('|').map(num => parseFloat(num)) } });
      //   }
      // }

      if (search.match(/bookprice=[^&]*/)[0].slice(10) !== 'null') {
        if (prodFilterPayload.prodFilterState.bookPrice.range[0] !== parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[0])
        || prodFilterPayload.prodFilterState.bookPrice.range[1] !== parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[1])) {
          prodFilterPayload.handleProdFilter({ bookPrice: { min: prodFilterPayload.prodFilterState.bookPrice.min, max: prodFilterPayload.prodFilterState.bookPrice.max, range: search.match(/bookprice=[^&]*/)[0].slice(10).split('|').map(num => parseFloat(num)) } });
        }
      }
    } else {
      // if there are no search params, replace url with default
      history.replace(
        // `/productlist/${productList}?page=1&pagelimit=40&sort=1&signed=false&bookbindings=null&condition=null&section=null&price=null`,
        `/productlist/${productList}?page=1&pagelimit=40&sort=1&signed=false&bookbindings=null&bookcondition=null&booksection=null&bookprice=null`,
        {
          URLOffset: 0,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.pageLimit,
          URLPageFilters: prodFilterPayload.prodFilterState,
        },
      );
    }
  }, [
    search,
    productList,
    pageOptionsState,
    history,
    prodFilterPayload,
    paginationState,
  ]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [paginationState.offset]);

  const handlePagination = (pData) => {
    if (search.match(/page=[0-9]*/) && pData.selected !== parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1) {
      history.push(
        `/productlist/${productList}${search.replace(/page=[0-9]*/, `page=${pData.selected + 1}`)}`,
        {
          URLOffset: pData === 0 ? 0 : pData.selected * pageOptionsState.pageLimit,
          URLCurrentPage: pData.selected + 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.pageLimit,
          URLPageFilters: prodFilterPayload.prodFilterState,
        },
      );
    }
  };

  const handlePageLimit = (e) => {
    if (e.target.value !== parseInt(search.match(/pagelimit=[0-9]*/)[0].slice(10), 10)) {
      history.push(
        `/productlist/${productList}${search.replace(/pagelimit=[0-9]*/, `pagelimit=${e.target.value}`).replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: e.target.value,
          URLPageFilters: prodFilterPayload.prodFilterState,
        },
      );
    }
  };

  const handleSort = (e) => {
    if (e.target.value !== parseInt(search.match(/sort=[0-9]*/)[0].slice(5), 10)) {
      history.push(
        `/productlist/${productList}${search.replace(/sort=[0-9]*/, `sort=${e.target.value}`).replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: e.target.value,
          URLPageLimit: pageOptionsState.pageLimit,
          URLPageFilters: prodFilterPayload.prodFilterState,
        },
      );
    }
  };

  const rootList = useRootList();
  const [renderRootState, setRenderRootState] = useState({});
  const rootMatch = rootList
    .filter(root => root.active)
    .filter(root => convertURL(root.rootName) === productList)[0] || {};

  const { data, loading, error } = useQuery(RENDER_ROOT, {
    variables: { rootName: rootMatch.rootName || '', uiRootId: rootMatch.uiRootId },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (!isEqual(data, renderRootState)) {
        setRenderRootState(data);
      }

      if (data.uiRootRender) {
        // console.log(data);
        const onCompArr = data.uiRootRender.components[0]
          .queryData.metaList.product;

        const priceArr = [0, 0];

        onCompArr.filter(prod => (
          formatPrice(prod.retailPrice, prod.salePriceMax, prod.salePriceMin) !== null))
          .forEach((prod) => {
            const current = formatPrice(
              prod.retailPrice,
              prod.salePriceMax,
              prod.salePriceMin,
            );

            if (Array.isArray(current)) {
              priceArr[0] = Math.floor(Math.min(priceArr[0], parseInt(current[0], 10)));
              priceArr[1] = Math.ceil(Math.max(priceArr[1], parseInt(current[1], 10)));
            } else {
              priceArr[0] = Math.floor(Math.min(priceArr[0], current));
              priceArr[1] = Math.ceil(Math.max(priceArr[1], current));
            }
          });

        prodFilterPayload.handleProdFilter({ bookPrice: { min: priceArr[0], max: priceArr[1], range: priceArr } });

        history.replace(
          `/productlist/${productList}${search.replace(search.match(/bookprice=[^&]*/), `bookprice=${priceArr.join('|')}`)}`,
          {
            URLOffset: 0,
            URLCurrentPage: 1,
            URLSort: pageOptionsState.sort,
            URLPageLimit: pageOptionsState.pageLimit,
            URLPageFilters: prodFilterPayload.prodFilterState,
          },
        );
      }
    },
  });

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productlist-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (error) return `Error ${error}`;

  if (renderRootState.uiRootRender) {
    const productListArray = renderRootState.uiRootRender.components[0]
      .queryData.metaList.product;

    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productlist-container__div">
          <div className="productlist-content__div">
            {
              match767
                ? (
                  <ProductListDrawer
                    productList={productListArray}
                    checkSigned={prodFilterPayload.prodFilterState.checkSigned}
                    handleProdFilter={prodFilterPayload.handleProdFilter}
                    bookBindings={prodFilterPayload.prodFilterState.bookBindings}
                    bookCondition={prodFilterPayload.prodFilterState.bookCondition}
                    bookSection={prodFilterPayload.prodFilterState.bookSection}
                    bookPrice={prodFilterPayload.prodFilterState.bookPrice}
                    search={search}
                    history={history}
                    paginationState={paginationState}
                    pageOptionsState={pageOptionsState}
                    productListName={productList}

                  />
                )
                : <span />
            }
            <main className="productlist-main__main">
              <PageHeader header={rootMatch.rootName} />
              <ProductListPageOps
                handlePageLimit={handlePageLimit}
                pageLimit={pageOptionsState.pageLimit}
                sort={pageOptionsState.sort}
                handleSort={handleSort}
              />
              <ProductListPagination
                search={search}
                handlePagination={handlePagination}
                pageTotal={Math.ceil(
                  productListArray
                    .filter(product => (
                      formatPrice(
                        product.retailPrice,
                        product.salePriceMax,
                        product.salePriceMin,
                      ) !== null))
                    .filter((product) => {
                      if (prodFilterPayload.prodFilterState.checkSigned) {
                        return product.signedIdList;
                      }
                      return product;
                    })
                    .filter((product) => {
                      if (prodFilterPayload.prodFilterState.checkSigned) {
                        return product.signedIdList.length > 0;
                      }
                      return product;
                    })
                    .filter((product) => {
                      const bindings = prodFilterPayload.prodFilterState.bookBindings;

                      if (bindings.length > 0) {
                        return bindings.indexOf(product.bookBinding) > -1;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const conditions = prodFilterPayload.prodFilterState.bookCondition;
                      if (conditions.length > 0) {
                        const checkedCond = [];
                        if (product.conditionList !== null) {
                          product.conditionList.forEach((cond) => {
                            if (conditions.length > 0) {
                              if (conditions.indexOf(cond) > -1) {
                                checkedCond.push(cond);
                              }
                            }
                          });
                        }
                        return checkedCond.length > 0;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const sections = prodFilterPayload.prodFilterState.bookSection;
                      if (sections.length > 0) {
                        const checkedProd = [];
                        if (product.sectionList !== null) {
                          product.sectionList.forEach((sec) => {
                            if (sections.length > 0) {
                              if (sections.indexOf(sec) > -1) {
                                checkedProd.push(sec);
                              }
                            }
                          });
                        }
                        return checkedProd.length > 0;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const priceObj = prodFilterPayload.prodFilterState.bookPrice;
                      const current = formatPrice(
                        product.retailPrice,
                        product.salePriceMax,
                        product.salePriceMin,
                      );

                      if (Array.isArray(current)) {
                        if (priceObj.range[0] <= parseFloat(current[0])
                        && priceObj.range[1] >= parseFloat(current[1])) return product;
                      }

                      return parseFloat(current) >= priceObj.range[0]
                      && parseFloat(current) <= priceObj.range[1];
                    })
                    .length / pageOptionsState.pageLimit,
                )}
              />
              <div className="productlist-list__div">
                {
                    productListArray
                      .filter(product => (
                        formatPrice(product.retailPrice,
                          product.salePriceMax,
                          product.salePriceMin) !== null))
                      .filter((product) => {
                        if (prodFilterPayload.prodFilterState.checkSigned) {
                          return product.signedIdList;
                        }
                        return product;
                      })
                      .filter((product) => {
                        if (prodFilterPayload.prodFilterState.checkSigned) {
                          return product.signedIdList.length > 0;
                        }
                        return product;
                      })
                      .filter((product) => {
                        const bindings = prodFilterPayload.prodFilterState.bookBindings;
                        if (bindings.length > 0) {
                          return bindings.indexOf(product.bookBinding) > -1;
                        }
                        return product;
                      })
                      .filter((product) => {
                        const conditions = prodFilterPayload.prodFilterState.bookCondition;
                        if (conditions.length > 0) {
                          const checkedCond = [];
                          if (product.conditionList !== null) {
                            product.conditionList.forEach((cond) => {
                              if (conditions.length > 0) {
                                if (conditions.indexOf(cond) > -1) {
                                  checkedCond.push(cond);
                                }
                              }
                            });
                          }
                          return checkedCond.length > 0;
                        }

                        return product;
                      })
                      .filter((product) => {
                        const sections = prodFilterPayload.prodFilterState.bookSection;
                        if (sections.length > 0) {
                          const checkedProd = [];
                          if (product.sectionList !== null) {
                            product.sectionList.forEach((sec) => {
                              if (sections.length > 0) {
                                if (sections.indexOf(sec) > -1) {
                                  checkedProd.push(sec);
                                }
                              }
                            });
                          }
                          return checkedProd.length > 0;
                        }

                        return product;
                      })
                      .filter((product) => {
                        const priceObj = prodFilterPayload.prodFilterState.bookPrice;
                        const current = formatPrice(
                          product.retailPrice,
                          product.salePriceMax,
                          product.salePriceMin,
                        );

                        if (Array.isArray(current)) {
                          if (priceObj.range[0] <= parseFloat(current[0])
                          && priceObj.range[1] >= parseFloat(current[1])) return product;
                        }

                        return parseFloat(current) >= priceObj.range[0]
                        && parseFloat(current) <= priceObj.range[1];
                      })
                      .sort((a, b) => {
                        if (pageOptionsState.sort === 1) {
                          if (a.title > b.title) return 1;
                          if (b.title > a.title) return -1;
                          return 0;
                        }
                        if (pageOptionsState.sort === 2) {
                          const lowestA = lowestPrice(formatPrice(
                            a.retailPrice,
                            a.salePriceMax,
                            a.salePriceMin,
                          ));
                          const lowestB = lowestPrice(formatPrice(
                            b.retailPrice,
                            b.salePriceMax,
                            b.salePriceMin,
                          ));

                          return lowestA - lowestB;
                        }
                        if (pageOptionsState.sort === 3) {
                          const lowestA = lowestPrice(formatPrice(
                            a.retailPrice,
                            a.salePriceMax,
                            a.salePriceMin,
                          ));
                          const lowestB = lowestPrice(formatPrice(
                            b.retailPrice,
                            b.salePriceMax,
                            b.salePriceMin,
                          ));
                          return lowestB - lowestA;
                        }

                        if (a.title > b.title) return 1;
                        if (b.title > a.title) return -1;
                        return 0;
                      })
                      .slice(
                        paginationState.offset,
                        pageOptionsState.pageLimit * (paginationState.currentPage + 1),
                      )
                      .map((product) => {
                        const {
                          title,
                          salePriceMax,
                          salePriceMin,
                          retailPrice,
                          id,
                          imageUrl,
                          gtin13,
                        } = product;

                        return (
                          <div className="productlist-product__div" key={id}>
                            <Link to={{ pathname: `/product/${gtin13}`, search: `?title=${convertURL(title)}`, state: { title: convertURL(title), gtin13 } }} className="productlist-link__link">
                              <div className="productlist-productimage__div">
                                <LazyLoad height={150} offset={150}>
                                  <img
                                    className="productlist-productimage__img"
                                    alt={title}
                                    src={imageUrl}
                                  />
                                </LazyLoad>
                              </div>
                              <div className="productlist-details__div">
                                <p className="productlist-producttitle__p">{stringLimiter(title)}</p>
                                <p className="productlist-productprice__p">{formatPrice(retailPrice, salePriceMax, salePriceMin) ? `$${typeof formatPrice(retailPrice, salePriceMax, salePriceMin) === 'object' ? `${formatPrice(retailPrice, salePriceMax, salePriceMin)[0]} - $${formatPrice(retailPrice, salePriceMax, salePriceMin)[1]}` : formatPrice(retailPrice, salePriceMax, salePriceMin)}` : <span />}</p>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                }
              </div>
              <ProductListPagination
                search={search}
                handlePagination={handlePagination}
                pageTotal={Math.ceil(
                  productListArray
                    .filter(product => (
                      formatPrice(
                        product.retailPrice,
                        product.salePriceMax,
                        product.salePriceMin,
                      ) !== null))
                    .filter((product) => {
                      if (prodFilterPayload.prodFilterState.checkSigned) {
                        return product.signedIdList;
                      }
                      return product;
                    })
                    .filter((product) => {
                      if (prodFilterPayload.prodFilterState.checkSigned) {
                        return product.signedIdList.length > 0;
                      }
                      return product;
                    })
                    .filter((product) => {
                      const bindings = prodFilterPayload.prodFilterState.bookBindings;

                      if (bindings.length > 0) {
                        return bindings.indexOf(product.bookBinding) > -1;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const conditions = prodFilterPayload.prodFilterState.bookCondition;
                      if (conditions.length > 0) {
                        const checkedCond = [];
                        if (product.conditionList !== null) {
                          product.conditionList.forEach((cond) => {
                            if (conditions.length > 0) {
                              if (conditions.indexOf(cond) > -1) {
                                checkedCond.push(cond);
                              }
                            }
                          });
                        }
                        return checkedCond.length > 0;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const sections = prodFilterPayload.prodFilterState.bookSection;
                      if (sections.length > 0) {
                        const checkedProd = [];
                        if (product.sectionList !== null) {
                          product.sectionList.forEach((sec) => {
                            if (sections.length > 0) {
                              if (sections.indexOf(sec) > -1) {
                                checkedProd.push(sec);
                              }
                            }
                          });
                        }
                        return checkedProd.length > 0;
                      }

                      return product;
                    })
                    .filter((product) => {
                      const priceObj = prodFilterPayload.prodFilterState.bookPrice;
                      const current = formatPrice(
                        product.retailPrice,
                        product.salePriceMax,
                        product.salePriceMin,
                      );

                      if (Array.isArray(current)) {
                        if (priceObj.range[0] <= parseFloat(current[0])
                        && priceObj.range[1] >= parseFloat(current[1])) return product;
                      }

                      return parseFloat(current) >= priceObj.range[0]
                      && parseFloat(current) <= priceObj.range[1];
                    })
                    .length / pageOptionsState.pageLimit,
                )}
              />
            </main>
          </div>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <div className="productlist-container__div">
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <ViewFooter />
    </div>
  );
};

ProductListHook.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ProductListHook;


/*

Notes:

7/12/19
Needs to be refactored. Hooks can live in separate files.
Ideally, gql queries should live elsewhere, too.

7/16/19
Bug: When you go follow the link to any product and press back button, it gives an error

7/22/19
Bug fixed
*/
