import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import ReactTwitterTracker from 'react-twitter-conversion-tracker';

const withTracker = (WrappedComponent, options = {}, otherProps = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.send({ hitType: 'pageview', page, title: page });

    ReactPixel.pageView();

    ReactTwitterTracker.pageView();

    window.ttq.page();
  };

  const HOC = (props) => {
    const { location } = props;
    useEffect(() => trackPage(`${location.pathname}${location.search}`), [
      location.pathname,
      location.search,
    ]);

    return <WrappedComponent {...props} {...otherProps} />;
  };

  HOC.propTypes = {
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  return HOC;
};

export default withTracker;
