import React from 'react';
import PropTypes from 'prop-types';
import './_pageHeader.scss';

const PageHeader = (props) => {
  const {
    header,
    text,
    headerProps,
    textProps,
    ...restProps
  } = props;

  return (
    <div className="pageheader-container__div" {...restProps}>
      <h1 className="pageheader-title__h1" {...headerProps}>
        {header}
      </h1>
      {text && (
        <p className="pageheader-text__p" {...textProps}>
          {text}
        </p>
      )}
      <div className="pageheader-dash__div" />
    </div>
  );
};

PageHeader.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string,
  headerProps: PropTypes.shape({}),
  textProps: PropTypes.shape({}),
};

PageHeader.defaultProps = {
  text: null,
  headerProps: null,
  textProps: null,
};

export default PageHeader;
