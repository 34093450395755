import urlGenerator from '../../helpers/urlGenerator';

const giftGuide2022Data = [
  {
    id: 'food_and_drink',
    title: 'Food & Drink',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_food_and_drink.png'),
    url: '/metalist/gift_guide_2022_food_and_drink',
  },
  {
    id: 'totes',
    title: 'Totes',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_totes.png'),
    url: '/productsection/MERCH-TOTES',
  },
  {
    id: 'adults',
    title: 'Adult Reads',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_adults.png'),
    url: '/metalist/gift_guide_2022_adults',
  },
  {
    id: 'gifts_under_50',
    title: 'Gifts Under $50',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_gifts_under_50.png'),
    url: '/metalist/gift_guide_2022_gifts_under_50',
  },
  {
    id: 'gifts_under_20',
    title: 'Gifts Under $20',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_gifts_under_20.png'),
    url: '/metalist/gift_guide_2022_gifts_under_20',
  },
  {
    id: 'gifts_for_kids',
    title: 'Gifts for Kids',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_gifts_for_kids.png'),
    url: '/metalist/gift_guide_2022_gifts_for_kids',
  },
  {
    id: 'young_adults',
    title: 'Young Adult Reads',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_young_adults.png'),
    url: '/metalist/gift_guide_2022_young_adults',
  },
  {
    id: 'apparel',
    title: 'Apparel',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_apparel.png'),
    url: '/metalist/gift_guide_2022_apparel',
  },
  {
    id: 'the_arts',
    title: 'The Arts',
    imgUrl: urlGenerator('/S/pages/gift_guide_2022_the_arts.png'),
    url: '/metalist/gift_guide_2022_the_arts',
  },
];

export default giftGuide2022Data;
