import React from 'react';
// import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import './_footerDesktop.scss';

const FooterDesktop = () => (
  <React.Fragment>
    <div className="footerdesktop-border__div" />
    <div className="footerdesktop-container__div">
      <div className="footerdesktop-content__div">
        <div className="footerdesktop-item__div" id="footerdesktop-books__div">
          <p className="footerdesktop-header__p">
            Books &amp; Media
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/metalist/bestsellers"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bestsellers link', 'FOOTER_LINK', 1)}
            >
              Bestsellers
            </NavLink>

            <NavLink
              to="/browse-sections"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked browse by section link', 'FOOTER_LINK', 1)}
            >
              Browse by Section
            </NavLink>

            <NavLink
              to="/books-media"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked browse collections link', 'FOOTER_LINK', 1)}
            >
              Browse Collections
            </NavLink>

            <NavLink
              to="/metalist/new_arrivals"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked new arrivals link', 'FOOTER_LINK', 1)}
            >
              New Arrivals
            </NavLink>

            <NavLink
              to="/metalist/signed"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked signed link', 'FOOTER_LINK', 1)}
            >
              Signed
            </NavLink>

            <NavLink
              to="/books-media/authors-bookshelf"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked authors bookshelf link', 'FOOTER_LINK', 1)}
            >
              The Author&#39;s Bookshelf
            </NavLink>
            {/* <NavLink
              // disabled
              // style={{ pointerEvents: 'none', color: '#6c6c6c' }}
              to="/metalist/vinyl_bestsellers"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked vinyl link', 'FOOTER_LINK', 1)}
            >
              Vinyl
            </NavLink> */}

          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-event__div">
          <p className="footerdesktop-header__p">
            Events
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/events"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked all events link', 'FOOTER_LINK', 1)}
            >
              All Events
            </NavLink>
            <NavLink
              to="/preorders"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked all preorders link', 'FOOTER_LINK', 1)}
            >
              Preorders
            </NavLink>
          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-gifts__div">
          <p className="footerdesktop-header__p">
            Gifts &amp; Apparel
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/apparel"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked apparel link', 'FOOTER_LINK', 1)}
            >
              Apparel
            </NavLink>

            <NavLink
              to="/productsection/MERCH-BOOK%20ACCESSORIES"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked accessories link', 'FOOTER_LINK', 1)}
            >
              Book Accessories
              {/* <span style={{ color: '#af272f' }}>*coming soon*</span> */}
            </NavLink>

            <NavLink
              to="/productsection/MERCH-GIFT"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked gifts & fun stuff link', 'FOOTER_LINK', 1)}
            >
              Gifts &amp; Fun Stuff
            </NavLink>

            <NavLink
              to="/productsection/MERCH-KIDS%20GIFT"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked gifts for kids link', 'FOOTER_LINK', 1)}
            >
              Gifts for Kids
            </NavLink>

            <NavLink
              to="/productsection/MERCH-MUGS"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked mugs link', 'FOOTER_LINK', 1)}
            >
              Mugs
            </NavLink>

            <NavLink
              to="/productsection/MERCH-POUCHES"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked pouches link', 'FOOTER_LINK', 1)}
            >
              Pouches
            </NavLink>

            <NavLink
              to="/productsection/MERCH-STATIONERY"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked souvenirs link', 'FOOTER_LINK', 1)}
            >
              Stationery
            </NavLink>


            <NavLink
              to="/productsection/MERCH-TOTES"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked totes link', 'FOOTER_LINK', 1)}
            >
              Totes
            </NavLink>

          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-rare__div">
          <p className="footerdesktop-header__p">
            Rare &amp; Collectibles
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/rare"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked rare link', 'FOOTER_LINK', 1)}
            >
              Featured Rare Books
            </NavLink>
          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-bbtf__div">
          <p className="footerdesktop-header__p">
            Books By The Foot
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/books-by-the-foot"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked about bbtf link', 'FOOTER_LINK', 1)}
            >
              About BBTF
            </NavLink>
            <NavLink
              to="/books-by-the-foot/color"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf color link', 'FOOTER_LINK', 1)}
            >
              Books By Color
            </NavLink>
            <NavLink
              to="/books-by-the-foot/style"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf style link', 'FOOTER_LINK', 1)}
            >
              Books By Style
            </NavLink>
            <NavLink
              to="/books-by-the-foot/subject"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf subject link', 'FOOTER_LINK', 1)}
            >
              Books By Subject
            </NavLink>

            <NavLink
              to="/books-by-the-foot/FAQ"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf FAQ link', 'FOOTER_LINK', 1)}
            >
              FAQ
            </NavLink>

            <NavLink
              to="/books-by-the-foot/gallery"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf gallery link', 'FOOTER_LINK', 1)}
            >
              Gallery
            </NavLink>

            <NavLink
              to="/books-by-the-foot/rentals"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf rentals link', 'FOOTER_LINK', 1)}
            >
              Rentals
            </NavLink>

          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-sell__div">
          <p className="footerdesktop-header__p">
            Sell Your Books &amp; More
          </p>
          <div className="footerdesktop-list__div">
            <NavLink
              to="/more/library-services"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked library services link', 'FOOTER_LINK', 1)}
            >
              Library Services
            </NavLink>

            <NavLink
              to="/more/sell-books"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked sell books link', 'FOOTER_LINK', 1)}
            >
              Sell Your Books
            </NavLink>

            <NavLink
              to="/more/strandcast"
              className="footerdesktop-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked StrandCast link', 'FOOTER_LINK', 1)}
            >
              The StrandCast
            </NavLink>
          </div>
        </div>

        <div className="footerdesktop-item__div" id="footerdesktop-item-nolist__div">
          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-bhu__div">
            <NavLink
              to="/books-media/bookhookup"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked book hookup link', 'FOOTER_LINK', 1)}
            >
              Book HookUp
            </NavLink>
          </div>
          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-hours__div">
            <NavLink
              to="/buyout-price"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked buyout inquiry link', 'FOOTER_LINK', 1)}
            >
              Buyout &amp; Filming Inquiries
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-cards__div">
            <NavLink
              to="/gifts-apparel/gift-cards"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked gift cards link', 'FOOTER_LINK', 1)}
            >
              Gift Cards
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-bhu__div">
            <NavLink
              to="/pick-of-the-month"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked book of the month link', 'FOOTER_LINK', 1)}
            >
              Pick Of The Month
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-hours__div">
            <NavLink
              to="/hours-locations"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked hours location link', 'FOOTER_LINK', 1)}
            >
              Store Hours &amp; Locations
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-account__div">
            {/* <NavLink to="/myaccount" className="footerdesktop-link-header__navlink">
              My Account
            </NavLink> */}
          </div>
          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-account__div">
            {/* <NavLink to="/myaccount" className="footerdesktop-link-header__navlink">
              My Account
            </NavLink> */}
          </div>
          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-account__div">
            <NavLink
              to="/about"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked about link', 'FOOTER_LINK', 1)}
            >
              About
            </NavLink>
          </div>

          {/* <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-careers__div">
            <NavLink
              disabled
              style={{ pointerEvents: 'none', color: '#6c6c6c' }}
              to="/careers"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked careers link', 'FOOTER_LINK', 1)}
            >
              Careers at The Strand
            </NavLink>
          </div> */}

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-contact__div">
            <NavLink
              to="/contact"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked contact link', 'FOOTER_LINK', 1)}
            >
              Contact Us
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-contact__div">
            <NavLink
              to="/work-at-strand"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked work at strand link', 'FOOTER_LINK', 1)}
            >
              Work at Strand
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-contact__div">
            <NavLink
              to="/accessibility-statement"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked accessibility statement link', 'FOOTER_LINK', 1)}
            >
              Accessibility Statement
            </NavLink>
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-contact__div">
            {/* <NavLink to="/help" className="footerdesktop-link-header__navlink">
              Help
            </NavLink> */}
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-account__div">
            {/* <NavLink to="/myaccount" className="footerdesktop-link-header__navlink">
              My Account
            </NavLink> */}
          </div>

          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-faq__div">
            <NavLink
              to="/help/faq"
              className="footerdesktop-link-header__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked FAQ link', 'FOOTER_LINK', 1)}
            >
              FAQ
            </NavLink>
          </div>
          <div className="footerdesktop-item__div footerdesktop-item-header__div" id="footerdesktop-faq__div">
            <a
              href="https://strandbooks.us9.list-manage.com/subscribe?u=0afd67a5c5ed54633aface96f&id=94fd8572f3"
              className="footerdesktop-link-header__a"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => WithEvent('FOOTER', 'Clicked sign up link', 'FOOTER_LINK', 1)}
            >
              Sign up for the Strand Insider Newsletter
            </a>
          </div>
        </div>
        <div className="footerdesktop-newsletter__div" id="footerdesktop-newsletter-wrapper__div">
          {/* <p className="footerdesktop-form-header__p">
            Sign up for the Strand Insider Newsletter
          </p>
          <form onSubmit={handleSubmit}>
            <input aria-label="Newsletter Sign up" className="footerdesktop-input__input" type="email" value={email} onChange={handleEmail} />
            <input className="footerdesktop-input-submit__input" type="submit" value="Sign up" />
          </form> */}
          <div className="footerdesktop-logo__div">
            <a
              href="https://www.facebook.com/strandbookstore/"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => WithEvent('FOOTER', 'Clicked facebook link', 'FOOTER_LINK', 1)}
            >
              <img src={urlGenerator('/S/pages/facebook_logo.png')} className="footerdesktop-logo__img" alt="facebook-logo" />
            </a>
            <a
              href="https://www.instagram.com/strandbookstore/?hl=en"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => WithEvent('FOOTER', 'Clicked instagram link', 'FOOTER_LINK', 1)}
            >
              <img src={urlGenerator('/S/pages/ig_logo.png')} className="footerdesktop-logo__img" alt="instagram-logo" />
            </a>
            <a
              href="https://twitter.com/strandbookstore"
              target="_blank"
              rel="noreferrer noopener"
              onClick={() => WithEvent('FOOTER', 'Clicked twitter link', 'FOOTER_LINK', 1)}
            >
              <img src={urlGenerator('/S/pages/twitter_logo.png')} className="footerdesktop-logo__img" alt="twitter-logo" />
            </a>

            {window.sealElement && <div dangerouslySetInnerHTML={{ __html: window.sealElement.outerHTML }} />}
          </div>
        </div>
        <div className="footerdesktop-copyright__div" id="footerdesktop-copyright-wrapper__div">
          <p className="footerdesktop-copyright-text__p">
            &copy; Copyright 2020 Strand Book Store All Rights Reserved /&nbsp;
            <NavLink
              to="/privacy"
              className="footerdesktop-copyright-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked privacy policy link', 'FOOTER_LINK', 1)}
            >
              Privacy Policy
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default FooterDesktop;
