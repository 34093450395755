import urlGenerator from '../../helpers/urlGenerator';

const listBannedBooks = {
    spoilerList: [
        {
            id: 'bannedbooks01',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_psycho.png'),
            title: 'American Psycho',
            author: 'Bret Easton Ellis',
            description: '<p>The horrors of torture and murder portrayed in the book are so graphical that it was deemed &lsquo;harmful to minors&rsquo; in countries like Germany, Australia and New Zealand.</p>',
          },
          {
            id: 'bannedbooks02',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_charloette_web.png'),
            title: 'Charloette\'s Web',
            author: 'E.B White',
            description: '<p>In 2006, some parents in a Kansas school district decided that talking animals are blasphemous and unnatural - specifically &ldquo;showing lower life forms with human abilities is sacrilegious and disrespectful to God&rdquo;.</p>',
          },
          {
            id: 'bannedbooks03',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_golden_compass.png'),
            title: 'The Golden Compass',
            author: 'Phillip Pullman',
            description: '<p>Since it was published in 1995, the first book in the His Dark Materials trilogy has faced controversy after controversy, most of it coming from the religious right. The Amber Spyglass quotes the authors as &ldquo; The most dangerous author in Britain &ldquo;</p>',
          },
          {
            id: 'bannedbooks04',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_breakfast.png'),
            title: 'Breakfast of Champions',
            author: 'Kurt Vonnegut',
            description: '<p>Banned in Illinois for being &ldquo;pornographic trash&rdquo;</p>',
          },
          {
            id: 'bannedbooks05',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_drama.png'),
            title: 'Drama',
            author: 'aina Telgemeier',
            description: '<p>Banned and challenged for including LGBTQIA+ characters and themes</p>',
          },
          {
            id: 'bannedbooks06',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_satanic.png'),
            title: 'The Satanic Verse',
            author: 'Salman Rushdie',
            description: '<p>Rushdie wrote a novel that satirized some of the more narrow-minded and (to Westerners, at least) antiquated aspects of zealotous Islam. He included a murderous, fringe, irrational, power-abusing character modeled after Iran\'s Ayatollah Khomeini. Ayatollah decreed a fatwa, calling on "all good Muslims" to kill Rushdie. Rushdie went into hiding after repeated attempts on his life (and the lives of others connected to the book, like the translator). Most recently he was stabbed at a lecture in upstate New York this August.</p>',
          },
          {
            id: 'bannedbooks07',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_boys_arent_blue.png'),
            title: 'All Boys Aren\'t Blue',
            author: ' George M. Johnson',
            description: '<p>Banned b/c contains LGBTQIA+ characters, uses profanity and considered &ldquo;sexually explicit&rdquo;. Many critics believed young adults should not have access to this book because young adults should not be able to have access to sexual content.  Yet in the past few months All Boys Aren’t Blue has been removed from school libraries in at least eight states, including Pennsylvania, Florida, Iowa, Arkansas, Missouri, Kansas, Virginia and Texas. On Nov. 9, 2020, a member of Florida’s Flagler County School Board filed a criminal report with the local sheriff arguing that the book’s inclusion in three district school libraries violated state obscenity laws because it contained sexually graphic material, including descriptions of queer sex and masturbation.</p>',
          },
          {
            id: 'bannedbooks08',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_new_butt.png'),
            title: 'I need a New Butt!',
            author: 'Dawn McMillan',
            description: '<p>Banned because citizens of Llano County Library (Texas) claimed this book was too inappropriate for children. (2021)</p>',
          },
          {
            id: 'bannedbooks09',
            spoilerImg: urlGenerator('/S/2021-subscription/lbb_2022_catch.png'),
            title: 'Catch-22',
            author: 'Joseph Keller',
            description: '<p>This anti-war satire is surprisingly not challenged due to that theme, but because of the depiction of women in the novel. It was challenged in Dallas, TX and Snoqualmie, WA because of the frequent use of the word "whore." The novel was banned in Strongsville, OH in 1972 for indecent language but the ban was reversed in 1976.</p>',
          },
    ],
};
export default listBannedBooks;
