// import { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const USER_LOGGED_IN = gql`
query{
   customerLoggedIn 
}
`;

const useUserLoggedIn = () => {
  const {
    data,
    refetch,
    loading,
    error,
  } = useQuery(USER_LOGGED_IN);

  // const [loggedState, setLogged] = useState(data.customerLoggedIn || false);
  // useEffect(() => {
  //   const handleLogged = () => setLogged(data.customerLoggedIn);

  //   handleLogged();
  //   // return () => setLogged(false);
  // }, [data.customerLoggedIn, data]);
  return {
    // loggedState,
    refetch,
    loading,
    error,
    data,
  };
};

export default useUserLoggedIn;
