import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import * as Sentry from '@sentry/react';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_login.scss';

export const LOGIN_CUSTOMER = gql`
  mutation loginCustomer($email: String!, $password: String!){
    customerLogin(email: $email, password: $password){
      customer{
        customerId
        firstName
        lastName
        verified
      }
      message
      success
    }
  }
`;

const useStyles = makeStyles({
  paperDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    width: '125px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootIconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
  rootTextField: {
    margin: '10px 0',
  },
  rootIconButtonClose: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});

const Login = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const [inputState, setInput] = useState({
    forgotEmailInput: '',
    passwordInput: '',
    emailInput: '',
    firstNameInput: '',
    lastNameInput: '',
    phoneInput: '',
    newEmailInput: '',
    newPasswordInput: '',
    confirmPasswordInput: '',
    signUp: false,
    resetPassword: false,
    logIn: true,
  });

  const handleInput = name => (e) => {
    setInput({ ...inputState, [name]: e.target.value });
  };

  const [showPassword, setPassword] = useState(false);
  const handleShowPassword = () => setPassword(prev => !prev);

  const [customerLogin, {
    data: loginData,
    loading: loginLoading,
    error: loginError,
  }] = useMutation(LOGIN_CUSTOMER);

  if (loginError) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            loginError,
          },
        }}
      />
    );
  }

  if (loginLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="login-container__div">
          <main className="login-content__main">
            { scroll > 1000 && <CompFab />}
            <div className="login-content__div">
              <Loading />
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (loginData && loginData.customerLogin && loginData.customerLogin.success) {
    // handleClose();
    refetchCustomerLoggedIn();
    refetchCartList();
    return <Redirect to="/" />;
  }

  if (customerLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="login-container__div">
        <main className="login-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="login-content__div">
            <ThemeProvider theme={theme}>
              <div className="login-header-image__div">
                <img className="login-header-image__img" src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" />
              </div>
              <h1 className="login-header__h1">Log In to Your Strand Account</h1>
              {
                loginData
                  && inputState.logIn
                  && loginData.customerLogin
                  && !loginData.customerLogin.success
                  && <p className="login-error__p">The email address and/or password was incorrect. Please try again.</p>
              }
              {
                inputState.logIn
                  && (
                    <div className="login-form__div">
                      <form
                        className="login-form__form"
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();

                          Sentry.addBreadcrumb({
                            category: 'LOGIN',
                            message: 'Clicked Log In',
                            level: Sentry.Severity.Info,
                          });

                          customerLogin({
                            variables: {
                              email: inputState.emailInput,
                              password: inputState.passwordInput,
                            },
                          });
                        }}
                      >
                        <TextField
                          onChange={handleInput('emailInput')}
                          label="Email Address"
                          value={inputState.emailInput}
                          variant="outlined"
                          className="login-input__textfield"
                          type="email"
                          classes={{ root: classes.rootTextField }}
                          id="login-email"
                        />
                        <TextField
                          onChange={handleInput('passwordInput')}
                          classes={{ root: classes.rootTextField }}
                          id="login-password"
                          label="Password"
                          value={inputState.passwordInput}
                          variant="outlined"
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  disableRipple
                                  disableTouchRipple
                                  disableFocusRipple
                                  onClick={handleShowPassword}
                                  aria-label="Show Password"
                                >
                                  {
                                    showPassword
                                      ? <Visibility />
                                      : <VisibilityOff />
                                    }
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {
                            inputState.logIn
                            && (
                              <div className="login-options-forgot__div">
                                <Link to="/forgot-password" className="login-option-btn__link">
                                  <p className="login-forgot__p">Forgot password?</p>
                                </Link>
                              </div>
                            )
                        }
                        <input
                          aria-label="login"
                          className="login-submit__input"
                          type="submit"
                          value="Log In"
                        />
                      </form>
                    </div>
                  )
                }
              <div className="login-options__div">
                <Link to="/signup" className="login-option-btn__link">
                  <p className="login-signup__p">Don&#39;t have an account? Sign up!</p>
                </Link>
              </div>
            </ThemeProvider>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Login.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Login;
