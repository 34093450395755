const jobsList = [
  {
    id: 'jobs01',
    available: true,
    component: 'ArtDepartmentBooksellerFullTime',
  },
  {
    id: 'jobs02',
    available: true,
    component: 'BooksellerFullTime',
  },
  // {
  //   id: 'jobs03',
  //   available: true,
  //   component: 'InHouseStockAssociate',
  // },
  // {
  //   id: 'jobs05',
  //   available: true,
  //   component: 'ChildrenBookseller',
  // },
  // {
  //   id: 'jobs06',
  //  available: true,
  //  component: 'SeasonalBookseller',
  // },
  //  {
  //   id: 'jobs07',
  //  available: true,
  //  component: 'UwsBookseller',
  // },
  /* {
    id: 'jobs08',
   available: true,
   component: 'UwsSeasonalBookseller',
  }, */
  {
    id: 'jobs04',
   available: true,
   component: 'FloorManager',
  },
  {
    id: 'jobs09',
    available: true,
    component: 'VisualMerchandiserManager',
  },
    {
    id: 'jobs10',
    available: true,
    component: 'ChildrensBooksellerFullTime',
  },
];

export default jobsList;
