import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import capitalizeString from '../../helpers/capitalizeString';
import convertSlashURL from '../../helpers/convertSlashURL';
import './_productSectionLP.scss';

const GET_SECTION_GROUPS = gql`
query{
  sectionGroups{
    subsections
    prefix
  }
}
`;

const ProductSectionLP = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    data: sectionGroupsData,
    loading: sectionGroupsLoading,
    error: sectionGroupsError,
  } = useQuery(GET_SECTION_GROUPS);

  if (sectionGroupsError) return <Redirect to={{ pathname: '/error', state: { sectionGroupsError } }} />;

  if (sectionGroupsLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productsectionlp-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  const excludePrefix = ['3', '5', 'AUDIOBOOKS', 'APPRAISAL SERVICES', 'Books', 'BIOGRAPHY', 'BKS/FT', 'CD', 'CM INVENTORY', 'DVD', 'EVENT ORDERS', 'FILLER', 'GIFT CARD', 'INGRAM BUYBACK', 'MISCELLANEOUS', 'MISSING DEPARTMENT', 'Misc Sale Codes', 'MYLAR', 'PRE', 'RARE', 'RBR Room Rental', 'REBATE', 'REFUNDS', 'REMAINDERS', 'RETURNS', 'SHIPPING & HANDLING', 'SIGNED', 'UNKNOWN', 'UNKOWN', 'Unknown', 'USED BOOKS', 'blank'];

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="productsectionlp-container__div">
        <main className="productsectionlp-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="productsectionlp-content__div">
            <PageHeader header="Browse by Section" />
            <section className="productsectionlp-list__section">
              {/* <div className="productsectionlp-list__div">
                {
                  sectionGroupsData
                    && sectionGroupsData.sectionGroups
                    && sectionGroupsData.sectionGroups.length > 0
                    && sectionGroupsData.sectionGroups
                      .filter(sec => !excludePrefix.includes(sec.prefix))
                      .sort((a, b) => {
                        if (a.prefix > b.prefix) return 1;
                        if (b.prefix > a.prefix) return -1;
                        return 0;
                      })
                      .map(sec => (
                        <div className="productsectionlp-prefix__div" key={sec.prefix}>
                          <Link
                            to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                            className="productsectionlp-prefix-link__link"
                            onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                          >
                            {capitalizeString(sec.prefix)}
                          </Link>
                        </div>
                      ))
                }
              </div> */}
              <div className="productsectionlp-list__div">

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'a').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="a">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">A</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'a')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'b').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="b">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">B</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'b')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'c').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="c">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">C</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'c')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'd').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="d">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">D</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'd')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'e').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="e">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">E</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'e')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'f').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="f">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">F</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'f')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'g').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="g">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">G</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'g')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'h').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="h">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">H</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'h')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'i').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="i">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">I</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'i')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'j').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="j">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">J</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'j')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'k').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="k">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">K</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'k')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'l').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="l">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">L</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'l')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'm').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="m">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">M</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'm')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'n').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="n">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">N</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'n')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'o').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="o">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">O</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'o')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'p').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="p">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">P</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'p')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'q').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="q">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">Q</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'q')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'r').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="r">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">R</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'r')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 's').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="s">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">S</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 's')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 't').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="t">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">T</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 't')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'u').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="u">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">U</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'u')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'v').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="v">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">V</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'v')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'w').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="w">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">W</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'w')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'x').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="x">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">X</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'x')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'y').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="y">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">Y</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'y')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

                {
                  sectionGroupsData
                  && sectionGroupsData.sectionGroups
                  && sectionGroupsData.sectionGroups.length > 0
                  && sectionGroupsData.sectionGroups
                    .filter(sec => !excludePrefix.includes(sec.prefix))
                    .filter(sec => sec.prefix[0].toLowerCase() === 'z').length !== 0
                  && (
                    <div className="productsectionlp-list-alpha__div" id="z">
                      <div className="productsectionlp-list-alpha-header__div">
                        <h2 className="productsectionlp-list-alpha-header__h2">Z</h2>
                      </div>
                      <div className="productsectionlp-accent__div" />
                      {
                        sectionGroupsData.sectionGroups
                          .filter(sec => !excludePrefix.includes(sec.prefix))
                          .filter(sec => sec.prefix[0].toLowerCase() === 'z')
                          .sort((a, b) => {
                            if (a.prefix > b.prefix) return 1;
                            if (b.prefix > a.prefix) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productsectionlp-prefix__div" key={sec.prefix}>
                              <Link
                                to={`/browse-sections/${convertSlashURL(sec.prefix)}`}
                                className="productsectionlp-prefix-link__link"
                                onClick={() => WithEvent('BOOKS_MEDIA', `Click ${sec.prefix} Section`, 'BROWSE_SECTION_LINK', 1)}
                              >
                                {capitalizeString(sec.prefix)}
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  )
                }

              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ProductSectionLP.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ProductSectionLP;

/*

{
        sectionGroupsData
        && sectionGroupsData.sectionGroups
        && sectionGroupsData.sectionGroups.length > 0
        && sectionGroupsData.sectionGroups
            .filter(sec => sec.subsections.length > 0 && !excludePrefix.includes(sec.prefix))
            .sort((a, b) => {
            if (a.prefix > b.prefix) return 1;
            if (b.prefix > a.prefix) return -1;
            return 0;
            })
            .map((sec, i) => {
            const filteredList = sectionGroupsData.sectionGroups
                .filter(item => item.subsections.length > 0 && !excludePrefix.includes(item.prefix))
                .sort((a, b) => {
                if (a.prefix > b.prefix) return 1;
                if (b.prefix > a.prefix) return -1;
                return 0;
                });

            if (i === 0
                || (sec.prefix.toLowerCase()[0]
                    !== filteredList[i - 1].prefix.toLowerCase()[0])
            ) {
                return (
                <div className="productsectionlp-prefix__div" key={sec.prefix}>
                    <div className="productsectionlp-prefix-header__div">
                    <h2 className="productsectionlp-prefix-header__h2">{sec.prefix[0].toUpperCase()}</h2>
                    </div>
                    <Link to={`/browse-sections/${convertSlashURL(sec.prefix)}`} className="productsectionlp-prefix-link__link">
                    {capitalizeString(sec.prefix)}
                    </Link>
                </div>
                );
            }
            return (
                <div className="productsectionlp-prefix__div" key={sec.prefix}>
                <Link to={`/browse-sections/${convertSlashURL(sec.prefix)}`} className="productsectionlp-prefix-link__link">
                    {capitalizeString(sec.prefix)}
                </Link>
                </div>
            );
            })
    }

*/
