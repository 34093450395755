import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import './_returnPolicy.scss';

const ReturnPolicy = (props) => {
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="returnpolicy-container__div">
        <PageHeader header="Return Policy" />
        <div className="returnpolicy-content__div">
          <h1 className="returnpolicy-header__h1">
            Return Policy for In-Store Purchases
          </h1>
          <p className="returnpolicy-details__p">
            Hardcovers and merchandise are returnable within 3 days for a full refund.
            <br />
            <br />
            All other books and merchandise, excluding clearance items,
            may be returned for store credit within 14 days.
            <br />
            <br />
            All items must be returned in the condition purchased.
            Moleskines and other wrapped merchandise, including CDs and DVDs,
            must be returned unopened in the original shrink wrapping.
            <br />
            <br />
            <strong>
              Receipts must accompany all returns and exchanges.
            </strong>
          </p>
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ReturnPolicy.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ReturnPolicy;
