const bindingDictionary = [];

export default bindingDictionary;

export const excludedBindingsDictionary = [
  'VINYL',
  'DVD',
  'VIDEO',
  'MERCH',
  'AUDIO_TAPE',
  'NOVELTY',
  'AUDIO_CD',
  'CALENDAR',
  'MERCH-SIZED',
  'WRAPPERS',
];
