/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_bhuFaq.scss';

const Faq = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bhufaq-container__div">
        <PageHeader header="Book HookUp FAQ" />
        { scroll > 1000 && <CompFab />}
        <div className="bhufaq-content__div">
          <section className="bhufaq-questions__section">
            <h2 className="bhufaq-header__h2" id="bhu1">What is the Book HookUp?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Book HookUp is Strand’s quarterly subscription box.  There are eight genres to choose from, each complete with a signed first edition of a highly anticipated new release, additional paperback, bookish goodies, Strand merch and a featured partner. Past boxes have contained snacks, mugs, magazines, literary journals, notebooks, and more from partners like The Paris Review, Cafe Grumpy, and Socksmith.
                <br />
                <br />
                You can sign up for one or more of our eight (8) subscription boxes and can always expect to find a different merch or partner item in each genre.
                <br />
                <br />
                You can choose to receive a&nbsp;
                <strong>single installment</strong>
                &nbsp;(one time only), a&nbsp;
                <strong>full year</strong>
                &nbsp;(four boxes over four quarters), or a&nbsp;
                <strong>recurring subscription</strong>
                &nbsp;(until the end of time). You can update your subscription anytime.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu2">What Are the Genres Strand Offers?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                You may choose from one or more of the following eight (8) subscription boxes:
              </p>
            </div>
            <h3 className="bhufaq-header__h3">Fiction</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Fiction box costs $50. This includes one (1) newly released signed first edition, one (1) additional book, Strand merchandise, and other featured items from partners we love. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers. Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Young Adult (YA)</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Young Adult box costs $37.50. This includes one (1) newly released signed first edition of a Young Adult title, one (1) additional paperback, Strand merchandise, and items from featured partners. Economy shipping and handling are included for Continental U.S. subscribers. Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Feminist Literature</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Feminist Literature box costs $50. The service will include one (1) newly released signed first edition of a new feminist title, one (1) additional book, Strand merchadise, and other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers.  Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Nonfiction</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The NonFiction box costs $50. The service will include one (1) newly released signed first edition of a nonfiction book, one (1) additional book, as well as other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. You can see what was included in past boxes here. Economy shipping and handling are included for Continental U.S. subscribers. Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Mystery &amp; Suspense</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Mystery &amp; Suspense box costs $50. The service will include one (1) newly released signed first edition of a mystery, crime, or thriller novel, one (1) additional book, as well as other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers.  Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Sci-Fi &amp; Fantasy</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Sci-Fi &amp; Fantasy box costs $50. The service will include one (1) newly released signed first edition of a science fiction or fantasy novel, one (1) additional book, as well as other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers.  Shipments to Canada will be charged $34.99 for shipping costs.. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Little Readers</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Little Readers box costs $37.50. The service will include one (1) newly released signed first edition of a picture book, one (1) additional book, as well as other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers.  Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h3 className="bhufaq-header__h3">Classics</h3>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The Classics box costs $37.50. The service will include one (1) book curated by Strand staff, one (1) additional book, as well as other items from featured partners. Additional items such as publisher advance review copies, letters, or other Strand marketing materials may be included in your package. Economy shipping and handling are included for Continental U.S. subscribers.  Shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu3">How Do I Sign Up?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Please visit our Strand Subscriptions page&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/books-media/bookhookup">here</a>
                &nbsp;for all subscription options. Select your desired subscription box at the rate of your choice to add the box to your cart.
                <br />
                <br />
                Please note that a valid credit card is required to sign up for our subscription services. Unfortunately, we are unable to process subscription orders purchased by PayPal.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu4s">When Should I Sign Up?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Since our boxes sell out quickly, make sure you sign up by the first (1st) of the month before your subscription box is set to be shipped. For example, if you would like to receive a June box, sign up by May 1st. You may sign up later than the first of the previous month, but we recommend signing up sooner in case the box sells out.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu5">What Kind of Payment Methods Do You Accept For Your Subscription Services?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                The only accepted payment method is credit card. We accept all major credit cards including Visa, Mastercard, American Express, and Discover.
                <br />
                <br />
                <strong>Unfortunately, we are unable to process orders made with any other type of payment. Orders placed using any other payment method (including PayPal) will be cancelled. If your order is cancelled, you will have to place a new one through our site using credit card payment.</strong>
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu6">Can International Customers Sign Up?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                At this time, our subscription programs are limited to the United States and Canada. However, please note that Book Hook-Up and Pick of the Month shipments to Canada will be charged $34.99 for shipping costs. Please see our&nbsp;
                <a className="bhufaq-details-link__a" href="https://www.strandbooks.com/help/faq">FAQ</a>
                &nbsp;page for more information on shipping.
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu7">Why Was I Charged $3.99 at Checkout? I Thought It Was Free?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Economy shipping is included in the price of the box for Continental U.S. subscribers.  Though the price may show up at check-out, be assured that we will not charge it to your credit card. We manually delete the Economy shipping fees while processing orders.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu8">Can I Purchase More Than One Subscription?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Of course! You can sign up for as many as you like. You can always expect to find a different merch and partner item in each genre.
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu9">Can I Purchase A Strand Subscription As A Gift?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Absolutely! Our subscription service is the perfect gift for any fellow book lover! When purchasing the subscription service through our online checkout process, please be sure to provide the recipient’s name and shipping address, as well as an email address so we can keep them updated on shipments.
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu10">How Will I Know If My Gift Will Show Up In Time?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Please note ship dates have been severely affected by COVID-19 with delays in the USPS and publication dates. Our goal is to get the boxes out by the last week of the box’s month (so September boxes will ship by the end of September).
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu11">How Do I Send A Strand Subscription As A Gift?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                To send a Strand Subscription as a gift, you must address the recipient as the &ldquo;Ship To&rdquo; name and address.&nbsp;
                <strong>Please include the recipient&rsquo;s name and email address in the &ldquo;Special Instructions&rdquo; section of your order as well.</strong>
                &nbsp;Unfortunately, we are not able to include any personalized messages. It is important that you provide the recipient&rsquo;s email in the &ldquo;Special Instructions&rdquo; section so that they will receive updates about the program and expected ship dates from our store.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu12">Can I Find Out What Books Will Be In The Box?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Of course. Each quarter we send out&nbsp;
                <Link
                  to="/books-media/bookhookup/spoilers"
                  className="bhufaq-Links__p"
                >
                  spoilers
                </Link>
                &nbsp;for what is in the boxes-to-come. Spoilers reveal the first edition, signed copy only. You’ll still be surprised to find your additional paperback and other goodies, un-spoiled. See something you like and want to switch genres? Just send an email to&nbsp;
                <a className="bhufaq-details-link__a" href="mailto: subscriptions@strandbooks.com">subscriptions@strandbooks.com</a>
                &nbsp;and we’ll take care of it.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu13">When Do I Find About The Next Book Selection?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Current subscribers will receive an email update announcing the title selected for the next release 2-3 weeks before the order is shipped. Additionally, we will announce the latest selection on the Strand Subscription page of our website, through our social media accounts, and through our email newsletter.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu14">Can My Book Be Personalized?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Unfortunately, we are unable to offer personalization for our subscribers at this time, but every new release will be signed by the author!
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu15">Can I Switch My Subscription For Another?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Of course! Just be aware that you might have to pay the difference if your new box is more expensive, and we need to know of your switch before the boxes are shipped out.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu16">I Moved. How Do I Update My Address?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                To update your address with Strand, contact our customer service department via email at&nbsp;
                <a className="bhufaq-details-link__a" href="mailto: subscriptions@strandbooks.com">subscriptions@strandbooks.com</a>
                &nbsp;or via phone at&nbsp;
                <a className="bhufaq-details-link__a" href="tel: 212-473-1452">212-473-1452</a>
                &nbsp;ext. 420. If you are asked to leave a voicemail, please call back at a time when someone is in the office to assist you.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bh17">Where Is My Subscription Box?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                If you have not received your box, please send an email at&nbsp;
                <a className="bhufaq-details-link__a" href="mailto: subscriptions@strandbooks.com">subscriptions@strandbooks.com</a>
                &nbsp;and we will be more than happy to investigate the matter. If items were lost in transit or damaged, and we still have additional copies of the subscription book, then we will be more than happy to send you a replacement copy. Due to the limited quantity of signed first edition books on-hand, we may not be able to fill all replacement orders. If this is the case, you will not be charged for your order and we will contact you immediately. We do our best to fill all orders.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu18">What If I Don&#39;t Like the Book or Product I&#39;ve Received?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                If you are unsatisfied with the subscription book you’ve received, you may receive store credit for the retail cost of the book.
                <br />
                <br />
                You may return the book by mail or in-store for store credit within two weeks of receipt of your order. For items being returned by mail, please ship to Strand Book Store, ATTN: Book HookUp, 828 Broadway, New York, NY, 10003 (this info is also on your invoice). Please include a copy of your invoice and indicate that you are returning the item for store credit. All in-store returns can be made at our main floor registers for store credit, and you must bring a copy of your invoice.
                <br />
                <br />
                Please note that only subscription books may be returned for store credit. Any merchandise items, including additional books, can not be returned or exchanged.
              </p>
            </div>

            <h2 className="bhufaq-header__h2" id="bhu19">Are There Any Enrollment Or Cancellation Fees?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Nope! We do not have any enrollment or cancellation fees. You may cancel at any time by contacting us at subscriptions@strandbooks.com.
              </p>
            </div>
            <h2 className="bhufaq-header__h2" id="bhu20">What If I Have Other Questions?</h2>
            <div className="bhufaq-details__div">
              <p className="bhufaq-details__p">
                Please feel free to contact us at&nbsp;
                <a className="bhufaq-details-link__a" href="mailto: subscriptions@strandbooks.com">subscriptions@strandbooks.com</a>
                &nbsp;or&nbsp;
                <a className="bhufaq-details-link__a" href="tel: 212-473-1452">212-473-1452</a>
                &nbsp;x420 and we’ll be more than happy to answer any questions you may have regarding our subscription services.
              </p>
            </div>
          </section>
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Faq.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Faq;
