import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import use4Cite from '../../hooks/use4Cite';
import './_contactUs.scss';

const ContactUs = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="contactus-container__div">
        <PageHeader header="Contact Us" />
        <main className="contactus-content__main">
          <div className="contactus-content__div">
            <section className="contactus-form__section">
              <div className="contactus-details__div">
                For inquiries, you can contact us at&nbsp;
                <a className="contactus-link__a contactus-email-link__a" href="mailto: web@strandbooks.com">web@strandbooks.com</a>
                &nbsp; or call us at&nbsp;
                <a className="contactus-link__a contactus-mobile-link__a" href="tel: 2124731452">212-473-1452</a>
                .
              </div>
              <div className="contactus-border__div" />
              <div className="contactus-faq__div">
                <p className="contactus-faq__p">
                  Thank you for your overwhelming support. Here are some
                  common questions we are receiving and may help you.
                </p>
                <br />
                <br />
                <h3 className="contactus-header__h3">
                  What does it mean that the order status is &#34;Closed?&#34;
                </h3>
                <br />
                <p className="contactus-faq__p">
                  If your order status is Closed, the order has been shipped
                  and should arrive within1 to 2 weeks.
                </p>
                <br />
                <br />
                <h3 className="contactus-header__h3">
                  I received the book(s) I ordered, but haven&#39;t received
                  any non-book items. What is the status of these items?
                </h3>
                <br />
                <p className="contactus-faq__p">
                  The new book items were shipped directly from our distributor.
                  Non-book items and used books are still in queue to be shipped
                  from our store as quickly as possible.
                </p>
                <br />
                <br />
                <h3 className="contactus-header__h3">
                  I waited so long for this order and now items are cancelled. Will I get a refund?
                </h3>
                <br />
                <p className="contactus-faq__p">
                  Cancelled items were not charged, so refunds are not pending for cancelled items.
                </p>
                <br />
                <br />
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ContactUs.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ContactUs;
