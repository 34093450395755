import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
// import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompBack from '../compBack/CompBack';
import CompFab from '../compFab/CompFab';
import strandCastEpList from './strandCastEpList';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_strandcastep.scss';

const StrandCastEp = (props) => {
  const {
    match,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [match]);

  use4Cite();

  if (!strandCastEpList.filter(ep => ep.id.slice(2) === match.params.ep).length) {
    return <Redirect to="/more/strandcast" />;
  }

  const episode = strandCastEpList.filter(ep => ep.id.slice(2) === match.params.ep);

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="strandcastep-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="strandcastep-content__main">
          <div className="strandcastep-content__div">
            <div className="strandcastep-back__div">
              <CompBack backURL="/more/strandcast" />
            </div>
            <div className="strandcastep-image__div">
              <img className="strandcastep-image__img" alt={episode[0].epTitle} src={episode[0].thumbnailURL} />
            </div>
            <div className="strandcastep-card__div">
              <h2 className="strandcastep-header__h2">{`Episode ${episode[0].id.slice(2)}`}</h2>
              <div className="strandcastep-accent__div" />
              <div className="strandcastep-details__div">
                <h3 className="strandcastep-header__h3">{episode[0].epTitle}</h3>
                <h4 className="strandcastep-header__h4">{episode[0].epSubTitle}</h4>
                <div className="strandcastep-iframe__div">
                  <iframe style={{ border: 'none' }} title={episode[0].epTitle} src={episode[0].iFrame} height="45" width="100%" scrolling="no" allow="fullscreen" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" />
                </div>
                <h5 className="strandcastep-header__h5">Show Details</h5>
                <div className="strandcastep-details__p" dangerouslySetInnerHTML={(() => ({ __html: episode[0].description }))()} />
                <p className="strandcastep-details__p">{`Hosts: ${episode[0].hosts}`}</p>
                {episode[0].productURL.length > 0 && <Link className="strandcastep-link__link" to={episode[0].productURL} onClick={() => WithEvent('STRANDCAST', `Clicked ${episode[0].bookTitle}`)}><p className="strandcastep-details__p">{ episode[0].productURL.length > 0 ? `Purchase the Book: ${episode[0].bookTitle}` : <span />}</p></Link>}
                {
                  episode[0].authorImage.length > 0
                    && episode[0].authorBio.length > 0
                    && (
                      <div className="strandcastep-author__div">
                        <div className="strandcastep-author-image__div">
                          <img className="strandcastep-author-image__img" alt={episode[0].title} src={episode[0].authorImage} />
                        </div>
                        <div className="strandcastep-author-bio__div" dangerouslySetInnerHTML={(() => ({ __html: episode[0].authorBio }))()} />
                      </div>
                    )
                }
                <p className="strandcastep-details__p">{episode[0].date}</p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

StrandCastEp.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default StrandCastEp;
