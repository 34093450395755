import React from 'react';
import PropTypes from 'prop-types';
import './_pageSubHeader.scss';

const PageSubHeader = (props) => {
  const { header } = props;

  return (
    <div className="pagesubheader-container__div">
      <h2 className="pagesubheader-title__h2">
        {header}
      </h2>
      <div className="pagesubheader-dash__div" />
    </div>
  );
};

PageSubHeader.propTypes = {
  header: PropTypes.string.isRequired,
};

export default PageSubHeader;
