const sectionsDictionary = (str) => {
  const sectionsDic = {
    'MERCH-GM SOUV%2FSTAT': 'Souvenirs & Stationery',
    'MERCH-STRAND TOTES': 'Totes & Pouches',
    'MERCH-GM GIFTWARE': 'Gifts & Fun Stuff',
    'MERCH-JUST FOR KIDS': 'Gifts for Kids',
    'MERCH-STRAND WEARABLES': 'Apparel',
    'MERCH-GIFT': 'Gifts & Fun Stuff',
    'MERCH-KIDS GIFT': 'Gifts for Kids',
    'MERCH-MUGS': 'Mugs',
    'MERCH-STATIONERY': 'Stationery',
    'MERCH-TOTES': 'Totes',
    'MERCH-BOOK ACCESSORIES': 'Book Accessories',
    'MERCH-POUCHES': 'Pouches',
  };

  if (sectionsDic[str]) {
    return sectionsDic[str];
  }
  return str;
};

export const excludeSectionsDictionary = [
  '3',
  '5',
  'BKS/FT',
  'Books',
  'Books-by-the-foot',
  'CD',
  'CD-BLUES',
  'CD-CHILDREN\'S',
  'CD-CLASSICAL',
  'CD-COMEDY',
  'CD-COUNTRY',
  'CD-ELECTRONIC',
  'CD-INTERNATIONAL',
  'CD-JAZZ',
  'CD-OPERA',
  'CD-POP VOCAL',
  'CD-R&B/HIP-HOP',
  'CD-ROCK',
  'CD-SOUNDTRACKS/MUSICALS',
  'CD-SPOKEN WORD',
  'CM INVENTORY',
  'DVD-Blu-ray',
  'DVD-CHILDREN',
  'DVD-DOCUMENTARY',
  'DVD-FITNESS',
  'DVD-MEDIAMIX',
  'DVD-MOVIES',
  'DVD-MUSIC',
  'DVD-OPERAS',
  'DVD-PLAYS',
  'DVD-TV SHOWS',
  'EVENT ORDERS',
  'FILLER',
  'GIFT CARD',
  'INGRAM BYBACK',
  'MISCELLANEOUS',
  'MISSING DEPARTMENT',
  'MYLAR',
  'Misc Sale Codes',
  'PRE-RELEASE',
  'RBR Room Rental',
  'REBATE',
  'REFUNDS',
  'RETURNS',
  'SIGNED',
  'SUBSCRIPTIONS',
  'UNKNOWN',
  'UNKOWN',
  'USED BOOKS',
  'Unknown',
  'blank',
];

export default sectionsDictionary;
