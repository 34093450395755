import urlGenerator from '../../helpers/urlGenerator';

const hoursLocationData = [
  {
    id: 'usq',
    title: 'Main Location',
    image: urlGenerator('/S/pages/locations_usq.png'),
    imageAlt: 'Main Location',
    details: '<p>828 Broadway at 12th Street<br/>New York, NY 10003<br/><br/></p>'
    + '<p>Monday&ndash;Sunday</p>'
    + '<p>10:00am&ndash;8:00pm</p>'
    + '<p><br/>Rare Book Room, Saturday & Sunday</p>'
    + '<p>10am&ndash;6pm (<em>hours subject to change)</em></p>'
    + '<p><br/>Used Book Buying Desk, Thursday&ndash;Saturday<br/>10:00am&ndash;4:00pm</p>'
    + '<p><br/>(212) 473-1452</p>',
  },
  {
    id: 'uws',
    title: 'The Strand at Columbus Avenue',
    image: urlGenerator('/S/pages/locations_uws.png'),
    imageAlt: 'Columbus Avenue',
    details: '<p>450 Columbus Avenue</p>'
    + '<p>(between 81st and 82nd street)</p>'
    + '<p>New York, NY 10024<br/><br/></p>'
    + '<p>Monday&ndash;Saturday</p>'
    + '<p>10:00am&ndash;8:00pm</p>'
    + '<p>Sunday</p>'
    + '<p>10:00am&ndash;7:00pm<br/><br/></p>',
  },
  {
    id: 'cpk',
    title: 'The Strand Central Park Kiosk',
    image: urlGenerator('/S/pages/locations_central_park_three.png'),
    imageAlt: 'Central Park Kiosk',
    details: '<p>60th Street and 5th Avenue<br/>New York, NY 10065<br/><br/></p>'
    + '<p>Every Day (weather permitting)</p>'
    + '<p>10:00am&ndash;Dusk<br/><br/></p>',
  },
  {
    id: 'lga',
    title: 'The Strand at The Bowery Bay Shops',
    image: urlGenerator('/S/pages/locations_strand_walk_laguardia_2.jpg'),
    imageAlt: 'Bowery Bay Shops',
    details: '<p>LaGuardia Airport, Terminal B'
      + '<br />'
      + 'Queens, NY</p>'
      + '<br />'
      + '<p>Monday-Sunday</p>'
      + '<p>3:30am&ndash;7:00pm</p>',
  },
];

export default hoursLocationData;
