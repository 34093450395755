import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../loading/Loading';
import stringLimiter from '../../helpers/stringLimiter';
import CheckoutCartTotal from './CheckoutCartTotal';
import 'array-flat-polyfill';
import './_checkoutOrderSummary.scss';

export const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        discountNote
        catalogId
        description
        gtin
        isTaxable
        qty
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
          bookBinding
          apparel{
            apparelId
            size
            sizeDesc
            inventory{
              catalogId
              condition
              sku
              onHand
              salePriceStv
            }
          }
          inventory{
            sku
            onHand
            signed
          }
          inventoryOverview{
            sectionList
          }
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      customer{
        customerId
        firstName
        lastName
        verified
      }
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

const useStyles = makeStyles({
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '18px',
    color: '#ffffff',
    minWidth: '240px',
    height: '40px',
    borderRadius: '5px',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#af272f',
    },
  },
  rootBtnMore: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '15px',
    color: '#5a5a5a',
    '&:hover': {
      color: '#af272f',
      background: 'transparent',
    },
  },
  rootBtnDelete: {
    backgroundColor: 'transparent',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '15px',
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
});

const CheckoutOrderSummary = (props) => {
  const {
    handleReview,
    currentStep,
    // checkoutState,
    shipBase,
    shipAdd,
    taxRate,
    payments,
    overweightState,
    tally,
  } = props;

  // console.log('tally CheckoutOrderSummary', tally);

  const match767 = useMediaQuery('(min-width: 767px)');
  const { error, loading, data } = useQuery(GET_CART_DETAILS);

  const classes = useStyles();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [moreItemsState, setMoreItems] = useState(false);

  const handleMoreItems = () => setMoreItems(prev => !prev);

  if (loading) return <Loading />;

  if (error) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  const giftWrapped = () => data?.cartList?.[0]?.cartItem?.some(item => item.sku === '2697076');

  const giftWrapUnavailable = (catalog, productDetail) => catalog === '2' || productDetail?.inventoryOverview?.sectionList?.some(sec => sec === 'BKS/FT' || sec === 'SUBSCRIPTIONS');

  const isGiftWrap = productDetail => productDetail?.inventory?.some(item => item.sku === '2697076');

  return (
    <div className={match767 ? 'checkoutordersummary-container__div' : 'checkoutordersummary-container__div--mobile'}>
      <div className="checkoutordersummary-content__div">
        {
          currentStep === 0
            ? <h2 className="checkoutordersummary-header__h2">Cart Summary</h2>
            : <span />
        }
        <section className="checkoutordersummary-items__section">
          <div className="checkoutordersummary-items__div">
            {
              data.cartList.length
                && data.cartList[0].cartItem.length
                && data.cartList[0].cartItem.filter((o, i) => i <= 1)
                  .map((item) => {
                    const {
                      cartItemId,
                      salePriceStv,
                      productDetail,
                      cartPriceStv,
                      discountNote,
                      catalogId,
                      sku,
                      qty,
                    } = item;

                    return (
                      <div key={cartItemId} className="checkoutordersummary-item__div">
                        <div className="checkoutordersummary-item-image__div">
                          {
                            productDetail
                              && productDetail.imageUrl
                              && productDetail.title
                              && (
                                <img src={productDetail.imageUrl} alt={productDetail.title} className="checkoutordersummary-item-image__img" />
                              )
                          }
                          {
                            giftWrapped()
                              && !giftWrapUnavailable(catalogId, productDetail)
                              && !isGiftWrap(productDetail)
                              && (
                                <React.Fragment>
                                  <div className="checkoutordersummary-gift-icon__div">
                                    <p className="checkoutordersummary-gift-icon__p">Gift wrap</p>
                                  </div>
                                </React.Fragment>
                              )
                          }
                        </div>
                        <div className="checkoutordersummary-item-details__div">
                          {
                            productDetail
                              && productDetail.title
                              && (
                                <h3 className="checkoutordersummary-item-title__h3">{stringLimiter(productDetail.title)}</h3>
                              )
                          }
                          <div className="checkoutordersummary-accent__div" />
                          {
                            productDetail
                              && productDetail.originator
                              && (
                                <p className="checkoutordersummary-item-originator__p">{stringLimiter(productDetail.originator)}</p>
                              )
                          }
                          {
                            productDetail
                              && productDetail.bookBinding
                              && !productDetail.apparel
                              && (
                                <p className="checkoutordersummary-item-binding__p">{productDetail.bookBinding === 'PaperBack' ? 'Paperback' : productDetail.bookBinding}</p>
                              )
                          }
                          {
                          productDetail
                            && productDetail?.apparel
                            && productDetail?.apparel.length > 0
                            && productDetail?.apparel.reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [...acc, { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc }], [])
                              .filter(cv => cv.sku === sku)
                            && productDetail?.apparel.reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [...acc, { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc }], [])
                              .filter(cv => cv.sku === sku).length > 0
                            && productDetail?.apparel.reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [...acc, { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc }], [])
                              .filter(cv => cv.sku === sku)
                              .map(cv => <p key={cv} className="checkoutordersummary-item-binding__p">{`Size: ${cv.sizeDesc}`}</p>)
                        }
                          <p className="checkoutordersummary-item-price__p">{`$${(salePriceStv * 0.01).toFixed(2)}`}</p>
                          {
                            productDetail
                              && productDetail.inventory
                              && productDetail.inventory.length > 0
                              && productDetail.inventory.filter(prod => prod.sku === sku).length > 0
                              && productDetail.inventory.filter(prod => prod.sku === sku)[0].signed
                              && productDetail.inventory.filter(prod => prod.sku === sku)[0].signed.length > 0
                              && (
                                <div className="checkoutordersummary-item-signed__div">
                                  <p className="checkoutordersummary-item-signed__p">*Signed Copy*</p>
                                </div>
                              )
                          }
                          {discountNote && <p className="checkoutordersummary-item-discount-note__p">{discountNote}</p>}
                          {
                            giftWrapped()
                              && giftWrapUnavailable(catalogId, productDetail)
                              && !isGiftWrap(productDetail)
                              && (
                                <div className="checkoutordersummary-unavailable__div">
                                  <WarningIcon />
                                  <p className="checkoutordersummary-unavailable__p">GIFT WRAP UNAVAILABLE</p>
                                </div>
                              )
                          }
                        </div>
                        <div className="checkoutordersummary-item-price-qty__div">
                          <p className="checkoutordersummary-item-price__p checkoutordersummary-item-total-price__p">{`$${(qty * (cartPriceStv * 0.01)).toFixed(2)}`}</p>
                        </div>
                      </div>
                    );
                  })
            }
            {
              data.cartList.length && data.cartList[0].cartItem.length > 2
                && (
                  <Collapse data-testid="collapse" in={moreItemsState}>
                    {
                      data.cartList[0].cartItem.filter((o, i) => i > 1)
                        .map((item) => {
                          const {
                            cartItemId,
                            salePriceStv,
                            productDetail,
                            cartPriceStv,
                            discountNote,
                            catalogId,
                            qty,
                            sku,
                          } = item;

                          return (
                            <div key={cartItemId} className="checkoutordersummary-item__div">
                              <div className="checkoutordersummary-item-image__div">
                                {
                                  productDetail
                                    && productDetail.imageUrl
                                    && productDetail.title
                                    && (
                                      <img src={productDetail.imageUrl} alt={productDetail.title} className="checkoutordersummary-item-image__img" />
                                    )
                                }
                                {
                                  giftWrapped()
                                    && !giftWrapUnavailable(catalogId, productDetail)
                                    && !isGiftWrap(productDetail)
                                    && (
                                      <React.Fragment>
                                        <div data-testid="gift-wrap" className="checkoutordersummary-gift-icon__div">
                                          <p className="checkoutordersummary-gift-icon__p">Gift wrap</p>
                                        </div>
                                      </React.Fragment>
                                    )
                                }
                              </div>
                              <div className="checkoutordersummary-item-details__div">
                                {
                                  productDetail
                                    && productDetail.title
                                    && (
                                      <h3 className="checkoutordersummary-item-title__h3">{stringLimiter(productDetail.title)}</h3>
                                    )
                                }
                                <div className="checkoutordersummary-accent__div" />
                                {
                                  productDetail
                                    && productDetail.originator
                                    && (
                                      <p className="checkoutordersummary-item-originator__p">{stringLimiter(productDetail.originator)}</p>
                                    )
                                }
                                {
                                  productDetail
                                    && productDetail.bookBinding
                                    && (
                                      <p className="checkoutordersummary-item-binding__p">{productDetail.bookBinding === 'PaperBack' ? 'Paperback' : productDetail.bookBinding}</p>
                                    )
                                }
                                <p className="checkoutordersummary-item-price__p">{`$${(salePriceStv * 0.01).toFixed(2)}`}</p>
                                {
                                  productDetail
                                    && productDetail.inventory
                                    && productDetail.inventory.length > 0
                                    && productDetail.inventory.filter(prod => prod.sku === sku).length > 0
                                    && productDetail.inventory.filter(prod => prod.sku === sku)[0].signed
                                    && productDetail.inventory.filter(prod => prod.sku === sku)[0].signed.length > 0
                                    && (
                                      <div className="checkoutordersummary-item-signed__div">
                                        <p className="checkoutordersummary-item-signed__p">*Signed Copy*</p>
                                      </div>
                                    )
                                }
                                {discountNote && <p className="checkoutordersummary-item-discount-note__p">{discountNote}</p>}
                                {
                                  giftWrapped()
                                    && giftWrapUnavailable(catalogId, productDetail)
                                    && !isGiftWrap(productDetail)
                                    && (
                                      <div className="checkoutordersummary-unavailable__div">
                                        <WarningIcon />
                                        <p className="checkoutordersummary-unavailable__p">GIFT WRAP UNAVAILABLE</p>
                                      </div>
                                    )
                                }
                              </div>
                              <div className="checkoutordersummary-item-price-qty__div">
                                <p className="checkoutordersummary-item-price__p checkoutordersummary-item-total-price__p">{`$${(qty * (cartPriceStv * 0.01)).toFixed(2)}`}</p>
                              </div>
                            </div>
                          );
                        })
                    }
                  </Collapse>
                )
            }
            { data.cartList.length && data.cartList[0].cartItem.length > 2 && (
              <div className="checkoutordersummary-more-items-btn__div">
                <Button classes={{ root: classes.rootBtnMore }} disableRipple disableTouchRipple className="checkoutordersummary-more-items-btn__button" onClick={handleMoreItems}>
                  { moreItemsState ? 'View less items' : `View ${data.cartList[0].cartItem.length - 2} more ${data.cartList[0].cartItem.length - 2 === 1 ? 'item' : 'items'}`}
                  {moreItemsState ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </div>
            )}
          </div>
        </section>
        <section className="checkoutordersummary-cart-total__section">
          <CheckoutCartTotal
            overweightState={overweightState}
            payments={payments || []}
            tax={taxRate || '-'}
            shipping={((((shipBase + (((data && data.cartList.length > 0 && data.cartList[0] && data.cartList[0].cartItem && data.cartList[0].cartItem.reduce((acc, cv) => acc + cv.qty, 0)) - 1) * shipAdd)) * 0.01))) || '-'}
            subTotal={tally?.productStv || '-'}
            tally={tally}
            currentStep={currentStep}
          />
        </section>
        <section className="checkoutordersummary-continue__section">
          {
            currentStep === 0 || currentStep === 1 || currentStep === 2
              ? (
                <div className="checkoutordersummary-continue-btn__div">
                  <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>
                    Continue
                  </Button>
                </div>
              )
              : <span />
          }
        </section>
      </div>
    </div>
  );
};

CheckoutOrderSummary.propTypes = {
  handleReview: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  overweightState: PropTypes.bool,
  // checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  // eslint-disable-next-line react/require-default-props
  shipBase: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  shipAdd: PropTypes.number,
  taxRate: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  payments: PropTypes.arrayOf(PropTypes.any),
  tally: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CheckoutOrderSummary;
