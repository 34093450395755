import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import WithEvent from '../withEvent/WithEvent';
import stringLimiter from '../../helpers/stringLimiter';
import './_strandcastlist.scss';

const useStyles = makeStyles({
  rootCard: {
    height: 325,
    backgroundColor: 'rgba(255, 255, 255, .8)',
  },
  rootCardMedia: {
    height: 150,
    backgroundPosition: 'top',
    transition: '3s all ease',
  },

  rootCardMediaHover: {
    height: 150,
    backgroundPosition: 'top',
    transition: '3s all ease',
    transform: 'scale(1.2)',
  },
});

const StrandCastList = (props) => {
  const { epList } = props;
  const classes = useStyles();
  const match1339 = useMediaQuery('(min-width: 1339px)');
  const [hoverState, setHover] = useState(epList.reduce((acc, cv) => (
    Object.assign({}, acc, { [cv.id]: false })
  ), {}));

  return (
    <div className="strandcastlist-container__div">
      <div className="strandcastlist-items__div">
        {
          epList
            .sort((a, b) => {
              if (a.id > b.id) return -1;
              if (a.id < b.id) return 1;
              return 0;
            })
            .map((ep) => {
              const {
                id,
                epTitle,
                epSubTitle,
                description,
                thumbnailURL,
                date,
              } = ep;

              return (
                <div className="strandcastlist-item__div" key={id}>
                  <Card
                    onMouseEnter={() => setHover({ ...hoverState, [id]: true })}
                    onMouseLeave={() => setHover({ ...hoverState, [id]: false })}
                    classes={{ root: classes.rootCard }}
                  >
                    <Link
                      to={`/more/strandcast/${id.slice(2)}`}
                      className="strandcastlist-link__link"
                      onClick={() => WithEvent('STRANDCAST', `Clicked ${epTitle} link`, 'STRANDCAST_LIST_LINK', 1)}
                    >
                      <div className="strandcastlist-media__div">
                        <CardMedia
                          classes={{ root: hoverState[id] ? classes.rootCardMediaHover : classes.rootCardMedia }}
                          image={thumbnailURL}
                          title={epSubTitle}
                        />
                      </div>
                      <CardContent>
                        <div className="strandcastlist-details__div">
                          <h3 className="strandcastlist-header__h3">{`Episode ${id.slice(2)}`}</h3>
                          <div className="strandcastlist-accent__div" />
                          <h4 className="strandcastlist-header__h4">{epTitle}</h4>
                          <h5 className="strandcastlist-header__h5">{ match1339 ? stringLimiter(epSubTitle, 45) : stringLimiter(epSubTitle, 35)}</h5>
                          <div className="strandcastlist-details__p" dangerouslySetInnerHTML={(() => ({ __html: match1339 ? stringLimiter(description, 45) : stringLimiter(description, 35) }))()} />
                          <p className="strandcastlist-details__p">{date}</p>
                        </div>
                      </CardContent>
                    </Link>
                  </Card>
                </div>
              );
            })
        }
      </div>
    </div>
  );
};

StrandCastList.propTypes = {
  epList: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default StrandCastList;
