import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import './_checkoutDialogLS.scss';


const CheckoutDialogLS = (props) => {
  const {
    open,
    handleRemoveLS,
    handleSetLS,
    parseAddr,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const useStyles = makeStyles({
    rootButton: {
      backgroundColor: '#af272f',
      fontFamily: ['Trade Gothic LT Std', 'Proxima Nova, sans-serif', 'Roboto', 'Helvetica Neue', 'Arial'],
      textTransform: 'none',
      color: '#ffffff',
      minWidth: '100px',
      fontSize: match767 ? '15px' : '13px',
      margin: '10px',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: '#d8341e',
      },
    },
  });

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {
        parseAddr
          && (
            <React.Fragment>
              <div className="checkoutdialogls-header__div">
                <h2 className="checkoutdialogls-header__h2">
                  Do you want to use this previous shipping address?
                </h2>
              </div>
              <div className="checkoutdialogls-accent__div" />
              <div className="checkoutdialogls-details__div">
                <p className="checkoutdialogls-details__p">{parseAddr?.attention || ''}</p>
                <p className="checkoutdialogls-details__p">{parseAddr?.addr1 || ''}</p>
                {
                parseAddr.addr2 && parseAddr.addr2.length > 0 && <p className="checkoutdialogls-details__p">{parseAddr.addr2}</p>
                }
                <p className="checkoutdialogls-details__p">{parseAddr?.city || ''}</p>
                {
                parseAddr.state && parseAddr.state.length > 0 && <p className="checkoutdialogls-details__p">{parseAddr.state}</p>
                }
                <p className="checkoutdialogls-details__p">{parseAddr?.postalCode || ''}</p>
                <p className="checkoutdialogls-details__p">{parseAddr?.countryCode || ''}</p>
              </div>
              <div className="checkoutdialogls-buttons__div">
                <Button classes={{ root: classes.rootButton }} onClick={handleRemoveLS}>
                  No thanks
                </Button>
                <Button classes={{ root: classes.rootButton }} onClick={handleSetLS}>
                  Use this address
                </Button>
              </div>
            </React.Fragment>
          )
            }
    </Dialog>
  );
};

CheckoutDialogLS.propTypes = {
  open: PropTypes.bool.isRequired,
  handleRemoveLS: PropTypes.func.isRequired,
  handleSetLS: PropTypes.func.isRequired,
  parseAddr: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CheckoutDialogLS;
