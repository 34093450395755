import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import useFormInput from '../../hooks/useFormInput';
import WithEvent from '../withEvent/WithEvent';
import './_searchBarDesktop.scss';

const useStyles = makeStyles({
  rootSearch: {
    width: '1.25em',
    height: '1.25em',
    color: '#ffffff',
  },
  rootClose: {
    color: '#af272f',
  },
  rootIconButtonSearch: {
    margin: 0,
    position: 'absolute',
    right: '0px',
    zIndex: 2,
    background: '#af272f',
    borderRadius: 0,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '5px',
    '&:hover': {
      background: '#d4301a',
    },
  },
  rootIconButtonClear: {
    position: 'absolute',
    right: '-65px',
    top: '3px',
    zIndex: 2,
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
  },
  rootTextField: {
    position: 'absolute',
    zIndex: 2,
  },
  rootInput: {
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '15px',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: 'rgba(0, 0, 0, .05)',
  },
  rootFilledInput: {
    padding: '12px 8px 12px',
    color: '#616161',
    '&:hover': {
      color: '#000000',
    },
  },
});

const SearchBarDesktop = () => {
  const classes = useStyles();
  const { inputState, handleChange } = useFormInput();
  const [typeState, setType] = useState('product');
  const [submitVal, setSubmit] = useState('');

  // separated input and submit so I can reuse useFormInput logic

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    WithEvent('SEARCH', 'Use Search Bar', 'SEARCH_NAVBAR', 1);
    setSubmit(inputState);
  };

  const handleClear = () => {
    handleChange('');
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  useEffect(() => () => {
    setSubmit('');
  }, [submitVal]);

  return submitVal.length
    ? (
      <Redirect to={{
        pathname: '/search-results',
        search: `?page=1&${submitVal}&searchVal=${submitVal}&type=${typeState}`,
        state: {
          URLSearchTerm: submitVal,
          URLOffset: 0,
          URLCurrentPage: 1,
          URLPageLimit: 20,
        },
      }}
      />
    )
    : (
      <div className="searchbardesktop-container__div">
        <div className="searchbardesktop-main__div">
          <form onSubmit={handleSubmit} className="searchbardesktop-form__form">
            <input aria-label="Search" id="search" className="searchbardesktop-input__input" type="text" value={inputState} onChange={handleChange} />
            <TextField
              id="type"
              value={typeState}
              onChange={handleType}
              variant="filled"
              classes={{ root: classes.rootTextField }}
              select
              InputProps={{
                classes: {
                  root: classes.rootInput,
                  input: classes.rootFilledInput,
                },
                disableUnderline: true,
              }}
            >
              <MenuItem button value="product">
                Product
              </MenuItem>
              <MenuItem button value="section">
                Section
              </MenuItem>
            </TextField>
            {
              inputState.length > 0
                && (
                  <IconButton
                    onClick={handleClear}
                    classes={{ root: classes.rootIconButtonClear }}
                    disableRipple
                    disableTouchRipple
                    aria-label="Clear All"
                  >
                    <p className="searchbardesktop-clear__p">Clear All</p>
                  </IconButton>
                )
            }
            <IconButton
              type="submit"
              classes={{ root: classes.rootIconButtonSearch }}
              disableRipple
              disableTouchRipple
              aria-label="Search"
            >
              <Search
                className="searchbardesktop-search__search"
                classes={{ root: classes.rootSearch }}
              />
            </IconButton>
          </form>
        </div>
      </div>
    );
};

export default SearchBarDesktop;
