/* eslint-disable */
import { useEffect } from 'react';
import testingEnv from '../helpers/testingEnv';

const use4Cite = () => {

  useEffect(() => {
    if (!testingEnv()) {
      window._svq = window._svq || [];
      window._svq.push(['_setAccount', '5424_04558']);
      window._svq.push(['_trackPageView']);
      (function (d, c) {
        const sv = d.createElement(c); sv.type = 'text/javascript'; sv.src = '//track.securedvisit.com/js/sv.js';
        const s = d.getElementsByTagName(c)[0]; s.parentNode.insertBefore(sv, s);
      })(document, 'script');
    }

    return () => {
      if (!testingEnv()) {
        const svScript = document.getElementsByTagName('script')[0];
        svScript.parentNode.removeChild(svScript)
      }
    }
  }, []);
};

export default use4Cite;
