import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import giftGuide2022Data from './giftGuide2022Data';
import './_giftguide2022.scss';

const GiftGuide2022 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const prefix = 'giftguide2022';

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className={`${prefix}-container__div`}>
        {scroll > 1000 && <CompFab />}
        <main className={`${prefix}-content__main`}>
          <div className={`${prefix}-content__div`}>
            {/* Holiday Gift Guide 2022 */}
            <section className={`${prefix}-hero__section`}>
              <div className={`${prefix}-hero__div`}>
                <img
                  src={urlGenerator('/S/pages/gift_guide_2022-landing-banner.png')}
                  className={`${prefix}-hero__img`}
                  alt="Holiday Gift Guide 2022"
                />
              </div>
            </section>
            {/*
            <section className={`${prefix}-intro__section`}>
              <div className={`${prefix}-intro__div`}>
                <p className={`${prefix}-intro__p`}>
                  Insert text here
                </p>
              </div>
            </section>
            */}
            <div className={`${prefix}-border__div`} />
            <section className={`${prefix}-categories__section`}>
              <div className={`${prefix}-categories__div`}>
                {giftGuide2022Data.map(({
                  id, title, url, imgUrl, alt,
                }) => (
                  <LazyLoad key={id} height={300} offset={300}>
                    <div className={`${prefix}-category__div`}>
                      <div className={`${prefix}-category`}>
                        <Link
                          to={url}
                          className={`${prefix}-category-link__link`}
                          onClick={() => WithEvent('GIFT_GUIDE_2022', `Clicked ${title} tile`, 'GIFT_GUIDE_LINK', 1)}
                          aria-label={alt || title}
                        >
                          <img
                            className={`${prefix}-category-image__img`}
                            alt={alt || title}
                            src={imgUrl}
                          />
                        </Link>
                      </div>
                    </div>
                  </LazyLoad>
                ))}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

GiftGuide2022.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default GiftGuide2022;
