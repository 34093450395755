import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import use4Cite from '../../hooks/use4Cite';
import './_termsOfUse.scss';

const PickOfTheMonth2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="tou-container__div">
        <PageHeader header="GIVEAWAY Terms of Use" />
        <section className="tou-conditions__section">
          <div className="tou-content__div">
            <h2 className="tou-header__h2">Pick of the Month Sign Up Giveaway</h2>
            <p className="tou-text__p">
              No purchase necessary. Purchase does not enhance the chance of winning.
              <br />
              <br />
            </p>
            <div className="tou-details__div">
              <p className="tou-text__p">
                ENTRY: By signing up your cell phone number to receive SMS  updates from
                The Strand you’ll automatically be entered in for the chance to win 6
                shipments of The Strand’s Pick of the Month. One (1) winner will
                be chosen at random and will be given their choice of genre to
                receive for 6 months. Must be 18 years of age to enter. Open to US
                residents only, void where prohibited. Winner will be chosen and
                notified by 10/4 and must provide a valid shipping address to receive their
                shipment. The winner’s 6 month subscription will begin in the month
                they are contacted if possible but may be delayed to the next month.
                <br />
                <br />
                You will be able to enter by sharing your cell phone number via pop ups
                and prompts found on strandbooks.com and across Strand Book Store’s social
                media accounts and email communications.
                <br />
                <br />
              </p>
              <h2 className="tou-subheader__h2">PRIVACY POLICY</h2>
              <p className="tou-text__p">
                We will use any personal data we receive in connection with our use of the Entry in accordance with these Giveaway Terms of Use and in accordance with our Privacy Policy available on https://www.strandbooks.com/privacy, or the Privacy Policy applicable in your country as available on our local website, and upon request. You consent to our collection of any personal data you provide in connection with the use of the Entry as described herein. If you do not agree to the collection, use and disclosure of your personal information in this way, please do not provide us with personal information or agree to these terms. Your personal information may be transferred to servers located outside the country in which you live or to affiliates or other trusted third parties based in other countries (including Curalate, Inc.) so that they may process personal information on our behalf. You agree to such collection, use, disclosure, transfer and processing of your information in accordance with these Giveaway Terms of Use.
              </p>
              <h2 className="tou-subheader__h2">LIMITATION OF LIABILITY</h2>
              <p className="tou-text__p">
                You shall be liable for and shall indemnify us against any and all claims, actions,
                liabilities, losses, damages, expenses (including reasonable legal expenses)
                incurred by us which arise directly or indirectly from a breach by you
                of your obligations, representations or warranties under these
                Giveaway Terms of Use.
              </p>
              <h2 className="tou-subheader__h2">MISCELLANEOUS</h2>
              <p className="tou-text__p">
                These Giveaway Terms of Use are personal to you and may not be assigned or
                transferred by you for any reason whatsoever without our prior written consent.
                We may assign our rights and obligations under these Giveaway Terms of Use
                to any other person, in whole or in part, without consent.
                <br />
                <br />
                A failure by us to exercise and any delay, forbearance or indulgence by us in
                exercising any right, power or remedy under these Giveaway Terms of Use
                (in whole or in part) shall not operate as a waiver of that right, power
                or remedy or preclude its exercise at any subsequent time or on any
                subsequent occasion. A person not a party to these Giveaway Terms of Use
                has no right to enforce any of these terms.
                <br />
                <br />
                These Giveaway Terms of Use contain all the terms agreed between us regarding
                the Entry and supersede any prior agreement, understanding or arrangement
                between us, whether oral or in writing.
                <br />
                <br />
                The construction, validity and performance of these terms shall be governed by
                the laws of the State of New York, USA and the parties submit to the exclusive
                jurisdiction of the courts of New York to resolve any dispute arising under
                or in connection with these Giveaway Terms of Use. If any court of law,
                having the jurisdiction to decide on this matter, rules that any provision of
                these Giveaway Terms of Use are invalid, then that provision will be removed
                without affecting the rest of the Giveaway Terms of Use. The remaining
                provisions of these Giveaway Terms of Use will continue to be valid and enforceable.
                <br />
                <br />
                Any questions regarding these Giveaway Terms of Use may be directed to:
                <br />
                <br />
                Strand Book Store
                <br />
                Attn: General Counsel
                <br />
                828 Broadway
                <br />
                NY, NY 10003, U.S.A.
              </p>
            </div>
          </div>
        </section>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

PickOfTheMonth2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default PickOfTheMonth2021;
