import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import Loading from '../loading/Loading';
import PageHeader from '../pageHeader/PageHeader';
import useScrollY from '../../hooks/useScrollY';
import WithEvent from '../withEvent/WithEvent';
import landingPageData from './landingPageData';
import use4Cite from '../../hooks/use4Cite';
import './_landingPage.scss';

const PRODUCT_DETAIL = gql`
query getSingleProduct($ean: String!){
  productDetail(ean: $ean){
     gtin13
     id
     inventoryOverview{
       onHand
     }
     inventory{
       onHand
       sku
     }
     title
  }
}
`;

const LandingPage = (props) => {
  const {
    match,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const match767 = useMediaQuery('(min-width: 767px)');
  const scroll = useScrollY();

  const {
    data,
    loading,
  } = useQuery(PRODUCT_DETAIL, {
    variables: {
      ean: landingPageData.filter(lp => lp.id === match.params.campaignId).pop() ? landingPageData.filter(lp => lp.id === match.params.campaignId).pop().gtin : '',
    },
    errorPolicy: 'ignore',
  });

  const {
    data: secondaryProductData,
  } = useQuery(PRODUCT_DETAIL, {
    variables: {
      ean: landingPageData.filter(lp => lp.id === match.params.campaignId).pop() ? landingPageData.filter(lp => lp.id === match.params.campaignId).pop().secondaryGtin : '',
    },
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="landingpage-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (landingPageData.filter(lp => lp.id === match.params.campaignId).length <= 0) {
    return <Redirect to="/page-not-found" />;
  }

  const lpData = landingPageData.filter(lp => lp.id === match.params.campaignId).pop() || '';

  const ctaProps = {};
  let ctaText;
  let CtaComponent;

  if (!lpData.preorderEndObj?.length || moment().isBefore(moment(lpData.preorderEndObj))) {
    if (lpData.soldOut || !data?.productDetail?.inventory.length) {
      ctaText = 'Sold Out';

      ctaProps.style = {
        pointerEvents: 'none',
        backgroundColor: '#616161',
      };
    } else if (lpData.signed) {
      ctaText = lpData.bookplate ? 'Buy Copy (w/ signed bookplate)' : 'Buy Signed Copy';
    } else if (lpData.limited) {
      ctaText = lpData.bookplate ? 'Buy Limited Edition Tote' : 'Buy Limited Edition Tote';
    } else {
      ctaText = 'Buy Now';
    }
  } else {
    ctaText = 'Preorder Has Ended';

    ctaProps.style = {
      pointerEvents: 'none',
      backgroundColor: '#616161',
    };
  }

  if (lpData.productURL?.length) {
    CtaComponent = 'a';

    ctaProps.href = lpData.productURL;
  } else {
    CtaComponent = Link;

    ctaProps.to = `/product/${lpData.gtin}`;
  }

  const cta = (
    <CtaComponent
      onClick={() => WithEvent('LANDING_PAGE', `Clicked on ${lpData.title}`, 'GO_TO_PRODUCT', 1)}
      className="landingpage-link__link"
      {...ctaProps}
    >
      {ctaText}
    </CtaComponent>
  );
  const secondaryCtaProps = {};
  let secondaryCtaText;
  let secondaryCtaComponent;

  const inventoryLength = lpData.productDetail?.inventory.length || secondaryProductData?.productDetail?.inventory.length;

  if (!lpData.preorderEndObj?.length || moment().isBefore(moment(lpData.preorderEndObj))) {
    if (lpData.soldOut || !inventoryLength) {
      secondaryCtaText = 'Sold Out';
      secondaryCtaProps.style = {
        pointerEvents: 'none',
        backgroundColor: '#616161',
      };
    } else if (lpData.limited) {
      // Use the secondaryCtaText from the lpData object for limited items
      secondaryCtaText = lpData.secondaryCtaText || 'Buy Limited Edition Tote';
    } else {
      secondaryCtaText = 'Buy Limited Edition Tote';
    }
  } else {
    secondaryCtaText = 'Preorder Has Ended';
    secondaryCtaProps.style = {
      pointerEvents: 'none',
      backgroundColor: '#616161',
    };
  }

  if (lpData.secondaryProductURL?.length) {
    secondaryCtaComponent = 'a';
    secondaryCtaProps.href = lpData.secondaryProductURL;
  } else {
    secondaryCtaComponent = Link;
    secondaryCtaProps.to = `/product/${lpData.secondaryGtin}`;
  }

  const secondaryCta = React.createElement(
    secondaryCtaComponent,
    {
      onClick: () => WithEvent('LANDING_PAGE', `Clicked on ${lpData.title} secondary CTA`, 'GO_TO_PRODUCT', 1),
      className: 'landingpage-link__link',
      ...secondaryCtaProps,
    },
    secondaryCtaText,
  );
  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      { scroll > 1000 && <CompFab />}
      <div className="landingpage-container__div">
        <main className="landingpage-content__main">
          {
            !lpData.bannerImage
              && !lpData.bannerImageMobile
              && lpData.soldOut
              && <h1 className="landingpage-header__h1 landingpage-header__h1--red">*SOLD OUT*</h1>
          }
          {
            lpData.bannerImage
              && lpData.bannerImageMobile
              ? (
                <div className="landingpage-banner__div">
                  <img
                    src={match767 ? lpData.bannerImage : lpData.bannerImageMobile}
                    alt={lpData.title}
                    className="landingpage-banner__img"
                  />
                </div>
              )
              : <PageHeader header={lpData.title} />
          }
          <div className="landingpage-content__div">
            {
              lpData.altIntro.length > 0
                ? (
                  <div
                    className="landingpage-intro__div"
                    dangerouslySetInnerHTML={
                        (() => ({
                          __html: lpData.altIntro,
                        }))()
                    }
                  />
                )
                : (
                  <div
                    className="landingpage-intro__div"
                    dangerouslySetInnerHTML={
                        (() => ({
                          __html: `${lpData.signed && lpData.personalized ? 'Signed and personalized copies of' : ''} ${lpData.signed && !lpData.personalized ? 'Signed copies of' : ''} <strong><em>${lpData.fullTitle}</em></strong> by ${lpData.authorName} ${lpData.signed || lpData.personalized ? 'are' : 'is'} now available ${lpData.preorder ? 'for preorder' : ''} at Strand.`,
                        }))()
                    }
                  />
                )
            }
            <div className="landingpage-book-button__div">
              <div className="landingpage-book__div">
                <img
                  className="landingpage-book__img"
                  alt={lpData.title}
                  src={lpData.bookImage}
                />
                {
                  (lpData.customEndNote || lpData.preorderEnd)
                    && (
                      <div className="landingpage-end__div">
                        <p className="landingpage-end__p">
                          {lpData.customEndNote || `*Preorder ends at ${lpData.preorderEnd} ET*`}
                        </p>
                      </div>
                    )
                }
              </div>

              <div className="landingpage-link__div">
                {lpData.showCta === false ? undefined : cta}
              </div>
              <div>
                {lpData.showSecondaryCta === true && (
                  <div className="landingpage-link__div">
                    {secondaryCta}
                  </div>
                )}
              </div>
            </div>

            <div
              className="landingpage-blurb__div landingpage-details__div"
              dangerouslySetInnerHTML={(() => ({ __html: lpData.bookBlurb }))()}
            />
            {
              lpData.otherProducts.length > 0
                && (
                  <React.Fragment>
                    <div className="landingpage-others__div">
                      {
                        lpData.otherProducts.length > 0
                          && lpData.otherProducts.map((product) => {
                            const {
                              gtin,
                              bookImg,
                            } = product;

                            return (
                              <div key={gtin} className="landingpage-others-item__div">
                                <Link
                                  onClick={() => WithEvent('LANDING_PAGE', `Clicked on other titles ${gtin}`, 'GO_TO_PRODUCT', 1)}
                                  to={`/product/${gtin}`}
                                >
                                  <div className="landingpage-item-image__div">
                                    <img
                                      className="landingpage-item-image__img"
                                      alt={gtin}
                                      src={bookImg}
                                    />
                                  </div>
                                </Link>

                                <div className="landingpage-item-link__div">
                                  <Link
                                    className="landingpage-item-link__link"
                                    onClick={() => WithEvent('LANDING_PAGE', `Clicked on other titles ${gtin}`, 'GO_TO_PRODUCT', 1)}
                                    to={`/product/${gtin}`}
                                  >
                                    Buy
                                  </Link>
                                </div>
                              </div>
                            );
                          })
                      }
                    </div>
                  </React.Fragment>
                )
            }
            <div className="landingpage-author__div">
              {
                lpData.authorName.length > 0
                  && lpData.authorImage.length > 0
                  && lpData.authorImage.map((authorImage, i) => (
                    <React.Fragment key={lpData.authorName[i]}>
                      <div className="landingpage-author-image__div">
                        {authorImage?.length > 0 && (
                          <img
                            className="landingpage-author-image__img"
                            alt={lpData.authorName[i]}
                            src={authorImage}
                          />
                        )}
                        {lpData.credit.length > 0 && lpData.credit.filter((cdt, j) => j === i).map(cdt => <p key={cdt} className="landingpage-credit__p">{`©${cdt}`}</p>)}
                      </div>
                      <div className="landingpage-bio__div" dangerouslySetInnerHTML={(() => ({ __html: lpData.authorBio[i] }))()} />
                    </React.Fragment>
                  ))
              }
            </div>
            {/* <div
              className="landingpage-bio__div landingpage-details__div"
              dangerouslySetInnerHTML={(() => ({ __html: lpData.authorBio }))()}
            /> */}

          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

LandingPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default LandingPage;
