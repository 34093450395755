import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

const useScrollY = () => {
  const [scrollState, setScroll] = useState(window.scrollY);

  useEffect(() => {
    const newScroll = debounce(() => setScroll(window.scrollY), 500);
    window.addEventListener('scroll', newScroll);

    return () => {
      window.removeEventListener('scroll', newScroll);
    };
  }, [scrollState]);

  return scrollState;
};

export default useScrollY;
