import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';

import './_emailVerify.scss';

const CUSTOMER_VERIFY = gql`
mutation customerVerify( $verifyId: ID! ) {
  customerVerify( verifyId: $verifyId ) {
    firstName
  }
}
`;

const EmailVerify = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const { verifyKey } = useParams();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const [customerVerify, {
    data: verifyData,
    loading: verifyLoading,
    error: verifyError,
  }] = useMutation(CUSTOMER_VERIFY, { onError: err => console.error(err) });

  const [redirectState, setRedirect] = useState(false);
  const [verifyComplete, setVerifyComplete] = useState(false);

  if (!verifyComplete) {
    customerVerify({
      variables: { verifyId: verifyKey },
      onCompleted: setTimeout(() => setRedirect(true), 3000),
    });
    setVerifyComplete(true);
  }

  if (verifyLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="emailverify-container__div">
          <main className="emailverify-main__main">
            <div className="emailverify-content__div">
              <h3 className="emailverify-header__h3">
                Processing your verification...
              </h3>
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (verifyError) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="emailverify-container__div">
          <main className="emailverify-main__main">
            <div className="emailverify-content__div">
              <h3 className="emailverify-header__h3">
                There was a problem during verification.
                <br />
                <br />
                Your verification link might have expired and
                we may request you try again in the future.
              </h3>
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return redirectState
    ? <Redirect to="/" />
    : (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="emailverify-container__div">
          <main className="emailverify-main__main">
            <div className="emailverify-content__div">
              <h3 className="emailverify-header__h3">
                Thanks&nbsp;
                {verifyData?.customerVerify?.firstName}
                &nbsp;for verifying your account!
                You&#39;ll be redirected to the home page in a few seconds.
              </h3>
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
};

EmailVerify.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default EmailVerify;
