import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import NumberFormat from 'react-number-format';
import LazyLoad from 'react-lazyload';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import { useCartSnackbar } from '../cartSnackbar/CartSnackbarProvider';
import Loading from '../loading/Loading';
import InPageLoading from '../loading/InPageLoading';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import urlGenerator from '../../helpers/urlGenerator';
import useScrollY from '../../hooks/useScrollY';
import botmList from './botmList';
import use4Cite from '../../hooks/use4Cite';
import atcUpdate from '../../helpers/atcUpdate';
import './_bookOfTheMonth.scss';

export const ADD_ITEM_CART = gql`
mutation addItemCart($cartItemInput: CartItemInput!){
  cartItemAdd(cartItemInput: $cartItemInput){
    cartId
    cartItemId
    cartPriceStv
    catalogId
    description
    gtin
    isTaxable
    qty
    retailStv
    salePriceStv
    sku
    productDetail{
      gtin13
      id
      imageUrl
      isbn10
      title
      originator
      bookBinding
      inventory{
        sku
        onHand
        catalogId
        signed
      }
    }
  }
}
`;

const products = {
  fictionProdList: [
    '2900012848516',
    '2900012848530',
    '2900012848547',
  ],
  mysteryProdList: [
    '2900012848554',
    '2900012848561',
    '2900012848578',
  ],
  sciFiProdList: [
    '2900013016136',
    '2900013016143',
    '2900013016150',
  ],
};

const getPldQuery = (key, list) => `
  ${key}: productListDetail(ean: ${JSON.stringify(list)}){
    title
    retailStv
    id
    gtin13
    inventory{
      sku
      salePriceStv
      catalogId
      salePriceStv
      onHand
    }
  }`;

export const GET_PRODUCT_LIST_DETAIL = gql`
query getProductListDetail{
  ${Object.entries(products).map(([key, list]) => getPldQuery(key, list)).join('')}
  ${getPldQuery('allProdList', [].concat(...Object.values(products)))}
}
`;

const NumberFormatQty = (props) => {
  const {
    inputRef,
    onChange,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalSeparator={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

NumberFormatQty.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#af272f',
    },
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:hover': {
          '&:not(.Mui-disabled)': {
            '&:before': {
              borderBottom: 'none',
            },
          },
          borderBottomColor: '#af272f',
        },
      },
    },
  },
});

const BookOfTheMonth = (props) => {
  const {
    cartList: upperCartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match450 = useMediaQuery('(min-width: 450px)');
  const match767 = useMediaQuery('(min-width: 767px)');
  const match320 = useMediaQuery('(max-width: 320px)');

  const useStyles = makeStyles({
    rootFormControlType: {
      minWidth: 110,
      color: '#af272f',
      margin: '5px 20px',
      textAlign: 'center',
      maxWidth: match320 ? 250 : '100%',
    },
    rootFormControlQty: {
      minWidth: 50,
      margin: '5px 20px',
      textAlign: 'center',
      color: '#af272f',
    },
    rootBtn: {
      backgroundColor: '#af272f',
      color: '#ffffff',
      textTransform: 'none',
      borderRadius: '5px',
      fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
      fontSize: '18px',
      '&:hover': {
        backgroundColor: '#d4301a',
      },
      minWidth: '160px',
      height: '40px',
    },
    rootInputLabel: {
      color: '#000',
      fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
      top: '-16px',
      left: '-14px',
    },
    iconSelect: {
      color: '#af272f',
    },
    selectSelect: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    rootInfoIcon: {
      color: '#af272f',
      width: '.75em',
      height: '.75em',
      '&:hover': {
        color: '#d4301a',
      },
    },
    rootTextField: {
      width: 75,
    },
    rootSelect: {
      width: 250,
    },
    rootViewIconButton: {
      color: '#af272f',
      '&:hover': {
        background: 'transparent',
        color: '#d4301a',
      },
      fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
      fontSize: '15px',
    },
  });

  const classes = useStyles();
  const scroll = useScrollY();

  const [botmState, setBotm] = useState('');
  const [qtyState, setQty] = useState(1);
  const [genreState, setGenre] = useState({
    Fiction: false,
    Mystery: false,
    'Sci-Fi': false,
  });

  const handleBotm = e => setBotm(e.target.value);

  const handleQty = (e, genre, onHand) => {
    if (e.target.value > onHand) {
      if (onHand > 20) {
        setQty(20);
      } else setQty(parseFloat(onHand));
    } else if (e.target.value <= onHand && e.target.value > 20) {
      setQty(20);
    } else {
      setQty(parseFloat(e.target.value));
    }
  };

  const [prodQtyState, setProdQty] = useState({});

  const setCartSnackbar = useCartSnackbar();

  const handleCartSnackbarClose = useCallback(() => {
    setCartSnackbar(prev => ({ ...prev, open: false }));
  }, [setCartSnackbar]);

  const handleCartSnackbarOpen = useCallback((prodTitle, triggerElem) => {
    setCartSnackbar({
      prodTitle,
      open: true,
      triggerElem,
      onClose: handleCartSnackbarClose,
    });
  }, [setCartSnackbar, handleCartSnackbarClose]);

  const handleGenre = genre => setGenre({ ...genreState, [genre]: !genreState[genre] });


  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    error: errorProdDetail,
    loading: loadingProdDetail,
    data: dataProdDetail,
  } = useQuery(GET_PRODUCT_LIST_DETAIL);

  const [cartItemAdd, {
    error: errorItemAdd,
    loading: loadingItemAdd,
  }] = useMutation(ADD_ITEM_CART);

  useEffect(() => {
    if (dataProdDetail?.allProdList
      && dataProdDetail?.allProdList.length > 0) {
      setBotm(dataProdDetail?.allProdList?.[0]?.gtin13);
    }
  }, [dataProdDetail]);

  useEffect(() => {
    if (dataProdDetail?.allProdList
      && dataProdDetail?.allProdList.length > 0) {
      const prodDetailObj = dataProdDetail?.allProdList
        .reduce((acc, cv) => ({ ...acc, [cv?.gtin13]: cv?.inventory?.[0]?.onHand }), {});

      setProdQty(prodDetailObj);
    }
  }, [dataProdDetail]);

  if (errorProdDetail || errorItemAdd) return <Redirect to={{ pathname: '/error', state: { errorProdDetail, errorItemAdd } }} />;

  if (loadingProdDetail) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="bookhookup-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="botm-container__div">
        <main className="botm-content__main">
          {scroll > 1000 && <CompFab />}
          {loadingItemAdd && <InPageLoading />}
          <div className="botm-content__div">
            <ThemeProvider theme={theme}>
              <section className="botm-hero__section">
                <div className="botm-hero__div">
                  <img
                    src={match450 ? urlGenerator('/S/pages/potm_signed_landing-desktop-3.png') : urlGenerator('/S/pages/potm_signed-mobile-33.png')}
                    alt="Pick of the Month"
                    className="botm-hero__img"
                  />
                </div>
              </section>
              <section className="botm-intro__section">
                <div className="botm-intro__div">
                  <p className="botm-intro__p">
                    We’re picking our favorite new releases to share with you!
                    Every month, you’ll receive a signed, first edition hardcover book
                    and a surprise gift from The Strand. Just tell us which genre you’d prefer,
                    Fiction, Mystery or Sci-Fi, and we’ll take care of the rest.
                    Whether you keep it for yourself or give it as a gift,
                    The Strand Pick of the Month will make sure you always have a fresh book to
                    read.
                  </p>
                  <br />
                </div>
                <div className="botm-border__div" />
              </section>
              <section className="botm-options__section">
                <div className="botm-options__div">
                  <div className="botm-option-image__div">
                    <LazyLoad height={300}>
                      <img
                        src={urlGenerator('/S/pages/potm-book-asset.jpg')}
                        alt="Pick of the Month Product"
                        className="botm-option-image__img"
                      />
                    </LazyLoad>
                    {/*
                    <p className="botm-new_text__p">
                      <strong>Program Update</strong>
                      <br />
                      Hi readers! Our YA Pick of the Month will be going away soon. The final installment will be in June 2022. Make sure you join our&nbsp;
                      <a href="https://strandbooks.us9.list-manage.com/subscribe?u=0afd67a5c5ed54633aface96f&amp;id=94fd8572f3" className="botm-book_link_a">Strand Subscription Programs newsletter</a>
                      &nbsp;for the latest updates
                    </p>
                    */}
                  </div>
                  <div className="botm-option-form__div">
                    <div className="botm-inputs__div">
                      <div className="botm-option__div botm-option-subscription__div">
                        <FormControl variant="outlined" classes={{ root: classes.rootFormControlType }}>
                          <InputLabel
                            id="subscription-type-label"
                            classes={{ root: classes.rootInputLabel }}
                          >
                            Subscription option
                          </InputLabel>
                          <Select
                            classes={{
                              icon: classes.iconSelect,
                              select: classes.selectSelect,
                              root: classes.rootSelect,
                            }}
                            value={botmState}
                            onChange={handleBotm}
                            labelId="subscription-type-label"
                            id="subscription-option-id"
                          >
                            {
                              dataProdDetail?.allProdList
                              && dataProdDetail?.allProdList.length > 0
                              && dataProdDetail?.allProdList
                                .map(prod => <MenuItem key={prod.gtin13} value={prod?.gtin13}>{`${prod?.title.slice((prod?.title.indexOf(':') + 2))}: $${(prod?.inventory?.[0]?.salePriceStv * 0.01).toFixed(2)}`}</MenuItem>)
                            }
                          </Select>
                        </FormControl>
                      </div>

                      <div className="botm-option__div botm-option-qty__div">
                        <TextField
                          id="botm-qty"
                          label="Qty"
                          name="qty"
                          value={qtyState}
                          onChange={(e) => {
                            handleQty(e, botmState, prodQtyState[botmState]);
                          }}
                          variant="outlined"
                          size="small"
                          classes={{ root: classes.rootTextField }}
                          InputLabelProps={{
                            classes: { root: classes.rootInputLabel },
                            disableAnimation: true,
                          }}
                          InputProps={{
                            inputComponent: NumberFormatQty,
                            inputProps: {
                              min: 1,
                              max: prodQtyState[botmState],
                            },
                            notched: false,
                          }}
                        />
                      </div>
                    </div>

                    <div className="botm-add__div">
                      <Button
                        classes={{ root: classes.rootBtn }}
                        onClick={(e) => {
                          const { currentTarget: triggerElem } = e;
                          const prodTitle = dataProdDetail?.allProdList.filter(prod => (
                            prod.gtin13 === botmState
                          ))?.[0]?.title;

                          Sentry.addBreadcrumb({
                            category: 'BOOK_OF_THE_MONTH',
                            message: `Click add to cart ${botmState}`,
                            level: Sentry.Severity.Info,
                          });

                          WithEvent('BOTM', `Add ${botmState} to Cart`, 'BOTM_BUTTON', 1);
                          cartItemAdd({
                            variables: {
                              cartItemInput: {
                                catalogId: 1,
                                qty: qtyState || 1,
                                sku: dataProdDetail?.allProdList.filter(prod => (
                                  prod.gtin13 === botmState
                                ))?.[0]?.inventory?.[0]?.sku,
                              },
                            },
                            update: atcUpdate,
                          })
                            .then(() => refetchCartList())
                            .then(() => {
                              handleCartSnackbarOpen(prodTitle, triggerElem);
                            });
                        }}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  </div>
                </div>
              </section>
              <section className="botm-selection__section">
                <div className="botm-selection__div">
                  <div className="botm-featured__div">
                    <PageSubHeader header="Featured Books for June 2024" />
                    {
                      botmList?.featured.map((botm, i) => {
                        const {
                          id,
                          title,
                          author,
                          genre,
                          description,
                          imageUrl,
                        } = botm;

                        return (
                          <div className="botm-book__div" key={id}>
                            <div className="botm-book-details__div">
                              <div className="botm-book-image-container__div">
                                <h3 className="botm-header__h3 botm-book-genre__div">{genre}</h3>
                                {imageUrl && (
                                  <div className="botm-book-image__div">
                                    <img
                                      src={imageUrl}
                                      alt={title}
                                      className="botm-book-image__img"
                                    />
                                  </div>
                                )}
                                <div className="botm-book-title__div">
                                  {title && <h3 className="botm-header__h3">{title}</h3>}
                                  {author && <p className="botm-book-author__p">{`by ${author}`}</p>}
                                </div>
                              </div>
                              <div className="botm-book-description-container__div">
                                <Collapse in={genreState[genre]} collapsedHeight={match767 ? 225 : 100}>
                                  <div
                                    className="botm-book-description__div"
                                    dangerouslySetInnerHTML={(() => ({ __html: description }))()}
                                  />
                                </Collapse>
                                <div className="botm-view-btn__div">
                                  <IconButton
                                    onClick={() => handleGenre(genre)}
                                    classes={{ root: classes.rootViewIconButton }}
                                    disableRipple
                                    disableTouchRipple
                                  >
                                    {
                                      genreState[genre]
                                        ? 'View Less Details'
                                        : 'View More Details'
                                    }
                                  </IconButton>
                                </div>
                              </div>
                            </div>
                            {i < 2 && <div className="botm-book-border__div" />}
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className="botm-previous__div" />
                </div>
              </section>
              <section className="botm-faq__section">
                <div className="botm-faq__div">
                  <PageSubHeader header="Frequently Asked Questions" />
                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">Why subscribe to The Strand Pick of the Month instead of buying the book myself?</h3>
                    <p className="botm-faq-details__p">
                      Our booksellers have a sixth sense about books, so you’ll be sure to get
                      a quality new release that is signed exclusively for this book box.
                      Plus, there is something thrilling about getting a new book in the mail
                      that someone else picked for you!
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">What is the difference between Pick of the Month and The Book HookUp?</h3>
                    <p className="botm-faq-details__p">
                      There are two main differences between our 2 book box programs.
                      1&#41; Pick of the Month has one book while&nbsp;
                      <a className="botm-faq-link__a" href="/books-media/bookhookup">The Book HookUp</a>
                      &nbsp;has two books &#40;and a lot of other goodies&#41; and
                      2&#41; The Book HookUp comes in seasonal installments four times a year
                      while The Strand Pick of the Month is, you guessed it, monthly.
                      The Pick of the Month also includes one literary goodie
                      to accompany the book you receive.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">When will I get my first box?</h3>
                    <p className="botm-faq-details__p">
                      If you order before the 15th of the month, you&apos;ll get the current
                      month&apos;s book. Orders placed after the 15th will begin in the following
                      month.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">Can I give it as a gift?</h3>
                    <p className="botm-faq-details__p">
                      Yes! You&rsquo;ll have the option when checking out to select who will receive
                      The Strand Pick of the Month. We&rsquo;ll make sure all pricing information is
                      removed from the packaging before shipping. When purchasing the subscription
                      service through our online checkout process, please be sure to provide the
                      recipient&rsquo;s name and shipping address, as well as an email address in
                      the &ldquo;Special Instructions&rdquo; so we can keep them updated on
                      shipments.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">Can I skip a month?</h3>
                    <p className="botm-faq-details__p">
                      Sure. Just email us at&nbsp;
                      <a className="botm-faq-link__a" href="mailto: subscriptions@strandbooks.com">subscriptions@strandbooks.com</a>
                      &nbsp;and let us know what month you’d like to skip.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">Is there any overlap between Pick of the Month and The Book HookUp?</h3>
                    <p className="botm-faq-details__p">
                      On the months there is a Book HookUp, the featured signed first edition will be the same. So March, June, September, and December Picks of the Month will mirror the headlining title in the Book HookUp boxes for the respective genres.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">Does the price include shipping?</h3>
                    <p className="botm-faq-details__p">
                      Yes! The price of the box includes US domestic shipping only. There are additional costs for shipping outside of the U.S.
                    </p>
                  </div>

                  <div className="botm-faq-details__div">
                    <h3 className="botm-header__h3">When will I be charged?</h3>
                    <p className="botm-faq-details__p">
                      Your credit card will be charged after the order is complete, just prior to shipping, so you won&#39;t be charged until your Pick of the Month is on it&#39;s way to you. You will receive an automated shipping confirmation email once your order has shipped. Remember, delivery expectations take effect after your order has shipped, NOT from the time you place your order.
                    </p>
                  </div>

                </div>
              </section>
            </ThemeProvider>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BookOfTheMonth.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BookOfTheMonth;
