/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import HelpRoundedIcon from '@material-ui/icons/HelpRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import { useReactToPrint } from 'react-to-print';
import OrderToPrint from './OrderToPrint';
import OrderDetailDialog from './OrderDetailDialog';
import orderStatusDictionary from '../../helpers/orderStatusDictionary';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
// import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import './_orderDetail.scss';

const useStyles = makeStyles({
  rootIconButton: {
    '&:hover': {
      background: 'transparent',
      color: '#af272f',
    },
  },
  rootHelpIconButton: {
    position: 'absolute',
    top: '-25px',
    right: '-20px',
    padding: '5px',
    '&:hover': {
      background: 'transparent',
      color: '#af272f',
    },
  },
  rootHelpIcon: {
    width: '.75em',
    height: '.75em',
  },
});

// props spreading is a pattern from documentation; Linting rule are will be ignored

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const trackingUrl = (tracking) => {
  if (tracking === null) {
    return '';
  }

  if (tracking.startsWith('1Z')) {
    return (
      <div>
        <a
          href={`https://www.ups.com/mobile/track?trackingNumber=${tracking}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tracking}
        </a>
      </div>
    );
  }
  return (
    <div>
      <a
        href={`https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=${tracking}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {tracking}
      </a>
    </div>
  );
};

const stvToDollar = (stv) => {
  if (typeof stv === 'number') {
    return (stv / 100.0).toFixed(2);
  }
  return '';
};

const Address = (props) => {
  const { name, data } = props;
  return (
    <div className="orderdetail-detail__div orderdetail-detail-address__div">
      <h3 className="orderdetail-header__h3">{name}</h3>
      <div className="orderdetail-dash__div" />
      <p className="orderdetail-detail__p">{data?.attention}</p>
      <p className="orderdetail-detail__p">{data?.address[0]}</p>
      <p className="orderdetail-detail__p">{data?.address[1]}</p>
      <p className="orderdetail-detail__p">{data?.city}</p>
      <p className="orderdetail-detail__p">{data?.state}</p>
      <p className="orderdetail-detail__p">{data?.postalCode}</p>
      <p className="orderdetail-detail__p">{data?.country}</p>
    </div>
  );
};

Address.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Other = (props) => {
  // console.log( props );
  const { data } = props;
  return (
    <div className="orderdetail-detail__div orderdetail-detail-address__div">
      <h3 className="orderdetail-header__h3">Other Details</h3>
      <div className="orderdetail-dash__div" />
      <p className="orderdetail-detail__p">{`Email: ${data.buyerEmail || ''}`}</p>
      <p className="orderdetail-detail__p">
        {`Delivery Instructions: ${data.checkout?.deliveryInstructions || ''}`}
      </p>
    </div>
  );
};

Other.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const OrderDetail = (props) => {
  const {
    orderData,
  } = props;

  const match450 = useMediaQuery('(min-width: 450px)');

  const {
    compSwitchState: dialogState,
    handleCompOpen: handleDialogOpen,
    handleCompClose: handleDialogClose,
  } = useOpenCloseComponent();

  const classes = useStyles();

  const [itemsState] = useState({
    itemColumns: [
      { title: 'Status', field: 'status' },
      { title: 'ISBN', field: 'gtin' },
      { title: 'Description', field: 'description' },
      { title: 'Qty', field: 'qty' },
      { title: 'Price$', field: 'price' },
      { title: 'Ext$', field: 'extended' },
      { title: 'Tax$', field: 'tax' },
      { title: 'Ship$', field: 'shipping' },
    ],
    itemData: orderData ? orderData.orderLine.map(item => ({
      ...item,
      status:
      <div className="orderdetail-status__div">
        <div className="orderdetail-status-detail__div">
          <p className="orderdetail-status-detail__p">
            {item.status}
          </p>
          {
            orderStatusDictionary[item.status]
              && (
                <Tooltip title={orderStatusDictionary[item.status]}>
                  <IconButton
                    classes={{ root: classes.rootHelpIconButton }}
                    onClick={handleDialogOpen}
                  >
                    <HelpRoundedIcon classes={{ root: classes.rootHelpIcon }} />
                  </IconButton>
                </Tooltip>
              )
          }
        </div>
        {trackingUrl(item.trackingNumber)}
      </div>,
      price: stvToDollar(item.priceStv),
      tax: stvToDollar(item.taxStv),
      extended: stvToDollar(item.extendedStv),
      shipping: stvToDollar(item.shippingStv),
    }
    )) : [],
  });

  const componentRef = useRef();
  const handlePrintOrder = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="orderdetail-container__div">
      <main className="orderdetail-content__main">
        <div className="orderdetail-content__div">
          <div className="orderdetail-header__div">
            <h2 className="orderdetail-header__h2">{`Order # ${orderData?.orderNumber?.orderString}`}</h2>
            {
              match450
                && (
                  <IconButton
                    onClick={handlePrintOrder}
                    classes={{ root: classes.rootIconButton }}
                    disableTouchRipple
                    disableRipple
                  >
                    <Tooltip title="Print Order">
                      <PrintRoundedIcon />
                    </Tooltip>
                  </IconButton>
                )
            }
          </div>
          <section className="orderdetail-address__section">
            <div className="orderdetail-address__div">
              <Address name="Billing Address" data={orderData?.billAddr} />
              <Address name="Shipping Address" data={orderData?.shipAddr} />
              <Other data={orderData} />
            </div>
          </section>
          <section className="orderdetail-items__section">
            <div className="orderdetail-items__div">
              <MaterialTable
                columns={itemsState.itemColumns}
                icons={tableIcons}
                data={itemsState.itemData}
                title={`Order Items (Total: $${stvToDollar(orderData?.checkout?.tally?.totalStv)})`}
                options={{
                  search: false,
                }}
              />
            </div>
            <div style={{ display: 'none' }} className="orderdetail-print_div">
              <OrderToPrint orderData={orderData} ref={componentRef} />
            </div>
          </section>
        </div>
      </main>
      <OrderDetailDialog
        open={dialogState}
        onClose={handleDialogClose}
      />
    </div>
  );
};

OrderDetail.propTypes = {
  orderData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderDetail;
