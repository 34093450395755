import { useState } from 'react';

const useToggleLoginSignup = () => {
  const [toggleLoginSignupState, setLoginSignup] = useState(false);
  const handleLoginSignup = () => setLoginSignup(prev => !prev);
  const handleClose = () => setLoginSignup(false);

  return [toggleLoginSignupState, handleLoginSignup, handleClose];
};

export default useToggleLoginSignup;
