import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';

import './_bbtfTC.scss';

const BBTFTC = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <PageHeader header="Terms &amp; Conditions" />
      <div className="bbtftc-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="bbtftc-content__main">
          <div className="bbtftc-content__div">
            <section className="bbtftc-purchases__section">
              <div className="bbtftc-details__div">
                <h2 className="bbtftc-header__h2">Purchases</h2>
                <p className="bbtftc-details__p">
                  When placing your order, please note any cancelled or partially canceled orders
                  will be subject to a 10% cancelation fee.
                  Bargain book orders cannot be canceled once placed.
                  <br />
                  <br />
                  When you receive your collection, please check the contents against the
                  invoice to confirm that all items have been received.
                  <br />
                  <br />
                  Returns must be made within 2 weeks of purchase.
                  As returns are subject to approval, please speak with
                  a Books by the Foot representative before returning any items.
                  <br />
                  <br />
                  All book orders except for final sale and clearance items may be
                  subject to a 10% restock fee.
                  <br />
                  <br />
                  Final sale and clearance items are not eligible for return or exchange.
                  <br />
                  <br />
                  All orders paid for by purchase order are billed under Net 30 payment terms.
                  <br />
                  <br />
                </p>
              </div>
            </section>
            <section className="bbtftc-purchases__section">
              <div className="bbtftc-details__div">
                <h2 className="bbtftc-header__h2">Rentals</h2>
                <p className="bbtftc-details__p">
                  Rental orders cannot be refunded or canceled after the books
                  have been assembled for your order.
                  <br />
                  <br />
                  We are unable to provide photo or title previews for rentals.
                  Please contact us if you have specific titles you’d like to rent.
                  We’re happy to provide sample photos to give you a better idea
                  of what each style of book looks like.
                  <br />
                  <br />
                  Rentals can be customized by subject, color, time period, size, etc.
                  (subject to availability). These requests must be made before the order is placed.
                  <br />
                  <br />
                  Please return all books by the date agreed upon and listed on your invoice.
                  We’re happy to provide a title list of the books in your rental;
                  please request the list when placing your order
                  <br />
                  <br />
                  <span className="bbtftc-details__p--strong">When returning your books:</span>
                  <br />
                  <br />
                  All books should be returned to our Union Square location
                  at 828 Broadway during store hours.
                  Bring books to front information desk on the main floor to be logged in.
                  Once we finish going through your rental return, we will contact you
                  regarding any missing or damaged books.
                  Any missing or damaged books must be paid for in full.
                  In the case of multi-volume sets, if one or more volumes are missing or damaged,
                  the entire set must be purchased.
                  <br />
                  <br />
                  All orders paid for by purchase order are billed under Net 30 payment terms.
                  <br />
                  <br />
                  Any rentals you would like to pick out yourself can processed at the registers.
                  Please let a Register Manager know at the time of the transaction,
                  as they will need to record your contact information and rental dates.
                  The rental rate will be the full cost of the books
                  with half the cost back upon return.
                  When returning, please speak with the
                  Registers Manager to get your half back refund.
                  <br />
                  <br />
                  *Clearance and final sale items are not eligible for full price,
                  half back rentals.
                  Please contact us at&nbsp;
                  <a href="mailto: bbtf@strandbooks.com" className="bbtftc-link__a">bbtf@strandbooks.com</a>
                  &nbsp;or&nbsp;
                  <a href="tel:+12126606646" className="bbtftc-link__a">212-660-6646</a>
                  &nbsp;for clearance or final sale rentals.
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFTC.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFTC;
