import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// import moment from 'moment';
// import Collapse from '@material-ui/core/Collapse';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ButtonBase from '@material-ui/core/ButtonBase';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
// import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Loading from '../loading/Loading';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutForm from './CheckoutForm';
import CheckoutFormPOSPU from './CheckoutFormPOSPU';
import CheckoutSnackbar from './CheckoutSnackbar';
import use4Cite from '../../hooks/use4Cite';
import './_checkoutShipping.scss';

export const UPDATE_CHECKOUT = gql`
  mutation updateCheckout($submit: CheckoutTransactionIn!){
    checkoutUpdate(submit: $submit){
      alts{
        canPo
        addr{
          addr1
          addr2
          addressId
          attention
          city
          countryCode
          postalCode
          state
        }
        email
        payment{
          balanceStv
          display
          paymentType
        }
        shipMethod{
          addPriceStv
          basePriceStv
          longName
          shipMethodId
          shortName
          transitInfo
        }
      }
      tally{
        discountApplyStv
        dueStv
        insufficientFunds
        itemCount
        productStv
        shippingStv
        taxRate
        taxStv
        vatRate
        vatStv
        taxableStv
        totalStv
      }
      payments{
        balanceStv
        display
        paymentType
      }
      billAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      cart{
        cartId
        cartItem{
          cartId
          productDetail{
            gtin13
            title
            originator
          }
        }
      }
      shipAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipMethod{
        addPriceStv
        basePriceStv
        longName
        shipMethodId
        shortName
        transitInfo
      }
      contact{
        billEmail
        phone
        phoneSms
        shipEmail
      }
      completeInfo
      specialInstructions
      deliveryInstructions
      checkoutId
      complete
      taxRate
    }
  }
`;

const useStyles = makeStyles({
  rootIconBtn: {
    color: '#ffffff',
    // color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '18px',
    color: '#ffffff',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
    },
    minWidth: '240px',
    height: '40px',
    borderRadius: '5px',
  },
  rootIconBtnRed: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  colorPrimaryAppBar: {
    backgroundColor: '#af272f',
  },
  rootTextField: {
    width: '100%',
    maxWidth: '500px',
    margin: '10px 0',
  },
  rootCheckbox: {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedCheckbox: {
    color: '#af272f !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    checkedRadio: {
      color: '#af272f !important',
    },
    colorSecondaryRadio: {
      color: '#af272f',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '&$checked': {
        color: '#af272f',
      },
    },
  },
});

const CheckoutShipping = (props) => {
  const {
    setStepper,
    currentStep,
    checkoutDetails,
    checkoutState,
    setCheckout,
    handleReview,
    handleSPU,
    checkoutError,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');
  const match1023 = useMediaQuery('(min-width: 1023px)');

  const {
    alts,
    checkoutId,
  } = checkoutDetails.beginData.checkoutBegin;

  const [checkoutUpdate, {
    data: updateData,
    loading: updateLoading,
    error: updateError,
  }] = useMutation(UPDATE_CHECKOUT);

  useEffect(() => {
    checkoutUpdate({ variables: { submit: {} } })
      .catch(err => console.error(err));
  }, [checkoutUpdate]);

  use4Cite();

  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
      secondary: {
        main: '#af272f',
      },
    },
  });

  const [headerState, setHeader] = useState({
    paymentInfo: false,
    shippingAddress: false,
  });

  const handleHeader = header => setHeader(prev => ({ ...prev, [header]: !prev[header] }));

  if (updateError) {
    if (updateError.graphQLErrors && updateError.graphQLErrors.filter(err => err.message === 'no_active_checkout').length) {
      return <Redirect to="/" />;
    }
    return <Redirect to={{ pathname: '/error', state: { updateError } }} />;
  }

  if (updateLoading) {
    // console.log('loading');
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }

  const handleLocations = (e) => {
    // setLocation(e.target.value);
    setCheckout({ ...checkoutState, SPULocation: e.target.value });
    // if (e.target.value === 'broadway') {
    //   checkoutUpdate({
    //     variables: {
    //       submit: {
    //         shipAddr: {
    //           attention: 'see billing info',
    //           addr1: '828 Broadway',
    //           city: 'New York',
    //           countryCode: 'USA',
    //           postalCode: '10003',
    //           state: 'NY',
    //         },
    //         shipMethodId: '17',
    //       },
    //     },
    //   });
    // }
    // if (e.target.value === 'columbus') {
    //   checkoutUpdate({
    //     variables: {
    //       submit: {
    //         shipAddr: {
    //           attention: 'see billing info',
    //           addr1: '450 Columbus Avenue',
    //           city: 'New York',
    //           countryCode: 'USA',
    //           postalCode: '10024',
    //           state: 'NY',
    //         },
    //         shipMethodId: '17',
    //       },
    //     },
    //   });
    // }
  };

  return (
    <div className={!match767 ? 'checkoutshipping-container__div--mobile' : 'checkoutshipping-container__div'}>
      {
        !match767
          && (
            <div className="checkoutshipping-cont-btn__div">
              <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>Continue</Button>
            </div>
          )
      }
      <main className="checkoutshipping-info__main" aria-labelledby="checkoutshipping-title">
        <section className="checkoutshipping-shipping__section">
          <div className="checkoutshipping-shipping__div">
            <div className={headerState.shippingAddress ? 'checkoutshipping-header__div checkoutshipping-header__div--red' : 'checkoutshipping-header__div'}>
              { /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */ }
              <h3 id="checkoutshipping-title" className={headerState.shippingAddress ? 'checkoutshipping-header__h3 checkoutshipping-header__h3--white' : 'checkoutshipping-header__h3'} tabIndex="0">Shipping Information</h3>
              {/* <div className="checkoutshipping-shipping-checkbox__div">
                <Checkbox
                  disableRipple
                  inputProps={{ 'aria-label': 'same as billing' }}
                  checked={compSwitchState}
                  onChange={handleSameShip}
                  value="shipping address is the same as billing"
                  classes={{ root: classes.rootCheckbox, checked: classes.checkedCheckbox }}
                />
                <p className="checkoutshipping-shipping-checkbox-details__p">same as billing</p>
              </div> */}
              {
               alts && alts.addr && alts.addr.length > 1
                  && (
                    <IconButton classes={{ root: headerState.shippingAddress ? classes.rootIconBtn : classes.rootIconBtnRed }} onClick={() => handleHeader('shippingAddress')}>
                      {
                        headerState.shippingAddress
                          ? <ExpandLess />
                          : <ExpandMore />
                      }
                    </IconButton>
                  )
              }
              {
                checkoutError
                && checkoutError.graphQLErrors
                && (
                  <CheckoutSnackbar
                    open
                    message={checkoutError || {}}
                  />
                )
              }
            </div>
            {
              // !shippingState.shippingArr.length
              //   && (
              //     <div className="checkoutshipping-new-payment__div">
              //       <ButtonBase disableRipple disableTouchRipple onClick={() => handleNewOpen('newShippingAddress')}>
              //         <p className="checkoutshipping-new-payment__p">Add New Shipping Address</p>
              //       </ButtonBase>
              //     </div>
              //   )
            }
            <div>
              <ThemeProvider theme={theme}>
                <div className="checkoutpaymentshipping-methods__div">
                  <div className="checkoutpaymentshipping-instore__div">
                    {
                      updateData
                        && updateData.checkoutUpdate
                        && updateData.checkoutUpdate.alts
                        && updateData.checkoutUpdate.alts.shipMethod
                        && updateData.checkoutUpdate.alts.shipMethod.filter(method => (method.shortName === 'SPU' || method.shortName === 'SPUWS'))
                        && updateData.checkoutUpdate.alts.shipMethod.filter(method => (method.shortName === 'SPU' || method.shortName === 'SPUWS')).length > 0
                        && (
                          <div className="checkoutpaymentshipping-shipping-checkbox__div">
                            <Checkbox
                              disableRipple
                              inputProps={{ 'aria-label': 'in-store pickup' }}
                              checked={checkoutState.isSPU}
                              onChange={handleSPU}
                              value="shipping address is the same as billing"
                              classes={{ root: classes.rootCheckbox, checked: classes.checkedCheckbox }}
                            />
                            <p className="checkoutpaymentshipping-shipping-checkbox-details__p">Store Pick-Up</p>
                          </div>
                        )
                    }
                    {
                      checkoutState.isSPU
                        && updateData
                        && updateData.checkoutUpdate
                        && updateData.checkoutUpdate.alts
                        && updateData.checkoutUpdate.alts.shipMethod
                        && updateData.checkoutUpdate.alts.shipMethod.filter(method => (method.shortName === 'SPU' || method.shortName === 'SPUWS'))
                        && updateData.checkoutUpdate.alts.shipMethod.filter(method => (method.shortName === 'SPU' || method.shortName === 'SPUWS')).length > 0
                        ? (
                          <div className="checkoutpaymentshipping-pickup-list__div">
                            <h4 className="checkoutpaymentshipping-header__h4">Where do you want to pick it up?</h4>
                            <div className="checkoutpaymentshipping-locations__div">
                              <RadioGroup aria-label="pickup locations" name="locations" value={checkoutState.SPULocation} onChange={handleLocations}>
                                {
                                  updateData.checkoutUpdate.alts.shipMethod.filter(method => (method.shortName === 'SPU' || method.shortName === 'SPUWS'))
                                    .map((method) => {
                                      const {
                                        shipMethodId,
                                        shortName,
                                      } = method;

                                      const locName = (name) => {
                                        if (name === 'SPUWS') {
                                          return '450 Columbus Avenue New York, NY 10024';
                                        }

                                        return '828 Broadway New York, NY 10003';
                                      };
                                      return (
                                        <FormControlLabel
                                          control={<Radio classes={{ checked: classes.checkedRadio, colorPrimary: classes.colorSecondaryRadio, colorSecondary: classes.colorSecondaryRadio }} />}
                                          value={shipMethodId}
                                          label={locName(shortName)}
                                          key={shipMethodId}
                                        />
                                      );
                                    })
                                }
                                {/* <FormControlLabel
                                  control={<Radio classes={{ checked: classes.checkedRadio, colorSecondary: classes.colorSecondaryRadio }} />}
                                  value="SPUWS"
                                  label="450 Columbus Avenue New York, NY 10024"
                                /> */}
                              </RadioGroup>
                            </div>
                            {
                              !updateData?.checkoutUpdate?.alts?.canPo
                                && (
                                  <section className="checkoutpaymentshipping-instructions__section">
                                    <div className="checkoutpaymentshipping-instructions__div">
                                      <form className="checkoutpaymentshipping-instructions__form">
                                        <label htmlFor="specialIns" id="special-instructions">
                                          <h3 className="checkoutpaymentshipping-header__h3">Special Instructions</h3>
                                          <textarea
                                            rows="5"
                                            cols={match1023 ? 50 : 35}
                                            className="checkoutpaymentshipping-instructions__textarea"
                                            value={checkoutState.SPUSpecialIns}
                                            onChange={e => setCheckout({ ...checkoutState, SPUSpecialIns: e.target.value })}
                                            maxLength={500}
                                            aria-labelledby="special-instructions"
                                            id="specialIns"
                                          />
                                        </label>
                                      </form>
                                    </div>
                                  </section>
                                )
                            }
                          </div>
                        )
                        : <span />
                    }
                  </div>
                </div>
              </ThemeProvider>
            </div>
            {
              !checkoutState.isSPU
                && (
                  <CheckoutForm
                    checkoutState={checkoutState}
                    setCheckout={setCheckout}
                    canPO={updateData?.checkoutUpdate?.alts?.canPo || false}
                  />
                )
            }
            {
              checkoutState.isSPU
                && updateData?.checkoutUpdate?.alts?.canPo
                && (
                  <CheckoutFormPOSPU
                    checkoutState={checkoutState}
                    setCheckout={setCheckout}
                  />
                )
            }
            {
              match767
                && !checkoutState.isSPU
                && (
                  <div className="checkoutshipping-cont-btn__div checkoutshipping-cont-form-btn__div">
                    <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>Continue</Button>
                  </div>
                )
            }
          </div>
        </section>
      </main>
      { !match767 && <div className="checkoutpaymentshipping-divider__div" />}
      <aside data-testid="checkoutordersummary" className="checkoutpaymentshipping-order-summary__aside">
        <CheckoutOrderSummary
          taxRate={0}
          currentStep={currentStep}
          setStepper={setStepper}
          handleReview={handleReview}
          checkoutId={checkoutId}
          tally={updateData?.checkoutUpdate?.tally || {}}
        />
      </aside>
      { /* add Order Summary here */ }
      {/* <div className="checkoutpaymentshipping-cont-btn__div">
        <Button classes={{ root: classes.rootBtn }} onClick={() => setStepper(1)}>Continue</Button>
      </div> */}
    </div>
  );
};

CheckoutShipping.propTypes = {
  setStepper: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  checkoutDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  setCheckout: PropTypes.func.isRequired,
  handleReview: PropTypes.func.isRequired,
  handleSPU: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  checkoutError: PropTypes.objectOf(PropTypes.any),
};

export default CheckoutShipping;
