const about = {
  id: 'about',
  title: 'About',
  url: '/about',
  subMenu: null,
};

const booksMedia = {
  id: 'books-media',
  title: 'Books & Media',
  url: '/books-media',
  subMenu: [
    {
      title: 'Bestsellers',
      url: '/metalist/bestsellers',
    },
    {
      title: 'Browse by Section',
      url: '/browse-sections',
    },
    {
      title: 'Browse Collections',
      url: '/books-media',
    },
    {
      title: 'New Arrivals',
      url: '/metalist/new_arrivals',
    },
    {
      title: 'Signed',
      url: '/metalist/signed',
    },
    {
      title: 'Author\'s Bookshelf',
      url: '/books-media/authors-bookshelf',
    },
    {
      title: 'Audiobooks at Libro.fm',
      url: 'https://libro.fm/strandbooks',
    },
    {
      title: 'Bookshop.org',
      url: 'https://bookshop.org/shop/strandbooks',
    },
  ],
};

const bbtf = {
  id: 'bbtf',
  title: 'Books by the Foot',
  url: '/books-by-the-foot',
  subMenu: [
    {
      title: 'About Books by the Foot',
      url: '/books-by-the-foot',
    },
    {
      title: 'Books by Color',
      url: '/books-by-the-foot/color',
    },
    {
      title: 'Books by Style',
      url: '/books-by-the-foot/style',
    },
    {
      title: 'Books by Subject',
      url: '/books-by-the-foot/subject',
    },
    {
      title: 'FAQ',
      url: '/books-by-the-foot/faq',
    },
    {
      title: 'Gallery',
      url: '/books-by-the-foot/gallery',
    },
    {
      title: 'Rentals',
      url: '/books-by-the-foot/rentals',
    },
  ],
};

const events = {
  id: 'events',
  title: 'Events',
  url: '/events',
  subMenu: [
    {
      title: 'All Events',
      url: '/events',
    },
  ],
};

const gifts = {
  id: 'gifts',
  title: 'Gifts & Apparel',
  url: '/apparel',
  subMenu: [
    {
      title: 'Apparel',
      url: '/apparel',
    },
    {
      title: 'Book Accessories',
      url: '/productsection/MERCH-BOOK%20ACCESSORIES',
    },
    {
      title: 'Gifts & Fun Stuff',
      url: '/productsection/MERCH-GIFT',
    },
    {
      title: 'Gifts Cards',
      url: '/gifts-apparel/gift-cards',
    },
    {
      title: 'Gifts for Kids',
      url: '/productsection/MERCH-KIDS%20GIFT',
    },
    {
      title: 'Mugs',
      url: '/productsection/MERCH-MUGS',
    },
    {
      title: 'Pouches',
      url: '/productsection/MERCH-POUCHES',
    },
    {
      title: 'Stationery',
      url: '/productsection/MERCH-STATIONERY',
    },
    {
      title: 'Totes',
      url: '/productsection/MERCH-TOTES',
    },
  ],
};

const preorders = {
  id: 'preorders',
  title: 'Preorders',
  url: '/preorders',
  subMenu: [
    {
      title: 'All Preorders',
      url: '/preorders',
    },
  ],
};

const hoursLocations = {
  id: 'hours-locations',
  title: 'Hours & Locations',
  url: '/hours-locations',
  subMenu: null,
};

const rare = {
  id: 'rare',
  title: 'Rare & Collectibles',
  url: '/rare',
  subMenu: [
    {
      title: 'Featured Rare Books',
      url: '/rare',
    },
    {
      title: 'Rent the Rare Book Room',
      url: '/more/rent-the-rare-book-room',
    },
  ],
};

const more = {
  id: 'more',
  title: 'Sell Your Books & More',
  url: null,
  subMenu: [
    {
      title: 'Library Services',
      url: '/more/library-services',
    },
    {
      title: 'Sell Your Books',
      url: '/more/sell-books',
    },
    {
      title: 'The StrandCast',
      url: '/more/strandcast',
    },
    {
      title: 'Ben McFall Memorial',
      url: '/benmcfall-memorial',
    },
  ],
};

const subs = {
  id: 'subs',
  title: 'Subscriptions',
  url: '/books-media/bookhookup',
  subMenu: [
    {
      title: 'Pick Of The Month',
      url: '/pick-of-the-month',
    },
    {
      title: 'Book Hookup',
      url: '/books-media/bookhookup',
    },
  ],
};

export const mobileNavMenuData = [
  about,
  booksMedia,
  bbtf,
  events,
  gifts,
  preorders,
  hoursLocations,
  rare,
  more,
  subs,
];

export default [
  booksMedia,
  bbtf,
  events,
  gifts,
  preorders,
  rare,
  more,
  subs,
];
