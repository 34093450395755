import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Magnifier from 'react-magnifier';
import './_compModal.scss';

const useStyles = makeStyles({
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      background: 'transparent',
    },
  },
  rootPaper: {
    // border: '5px solid #af272f',
  },
});

const CompModal = (props) => {
  const {
    imageObj,
    open,
    onClose,
  } = props;

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.rootPaper }}>
      <div className="compmodal-container__div">
        <div className="compmodal-close__div">
          <IconButton
            onClick={onClose}
            aria-label="close-icon"
            classes={{ root: classes.rootIconButton }}
          >
            <Close />
          </IconButton>
        </div>

        <div className="compmodal-image__div">
          <Magnifier src={imageObj.imgSrc} alt={imageObj.altText} />
        </div>
      </div>
    </Dialog>
  );
};

CompModal.propTypes = {
  imageObj: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CompModal;
