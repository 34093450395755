const atcUpdate = query => (cache, { data: { cartItemAdd: cartItemData } }) => {
  const { cartList } = cache.readQuery({ query });

  const cachedCart = cartList.find(sCart => sCart.cartType === 'PRIMARY');

  if (cachedCart) {
    const cartItemUpdated = cachedCart.cartItem.map((cartProd) => {
      if (cartProd.cartItemId === cartItemData.cartItemId) {
        return cartItemData;
      }
      return cartProd;
    }); // if item already exists in cart, replace cartItemData

    if (!cartItemUpdated?.find(item => item.cartItemId === cartItemData.cartItemId)) {
      cartItemUpdated.push(cartItemData);
    }

    cachedCart.cartItem = cartItemUpdated;
    cache.writeQuery({
      query,
      data: { cartList },
    });
  }
};

export default atcUpdate;
