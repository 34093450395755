import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import urlGenerator from '../../helpers/urlGenerator';
import GenCarousel from '../genCarousel/GenCarousel';
import use4Cite from '../../hooks/use4Cite';
import hoursLocationData from './hoursLocationData';
import './_hoursLocations.scss';

export const META_LIST_DETAIL = gql`
  query{
    bestsellersMeta: metaListDetail(metaTag: "Bestsellers"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
  }
`;

export const PRODUCT_LIST_DETAIL = gql`
  query{
    productListDetail(ean: ["9112084751", "9111026634", "9112128198", "911218845X", "9112216984", "2900012830337", "2900012649519", "9112087718", "9112331937", "9112101117"]){
      gtin13
      imageUrl
      id
      title
    }
  }
`;

const HoursLocations = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  const {
    error: errorProductListDetail,
    loading: loadingProductListDetail,
    data: dataProductListDetail,
  } = useQuery(PRODUCT_LIST_DETAIL);

  use4Cite();

  if (errorMeta || errorProductListDetail) return <Redirect to={{ pathname: '/error', state: { errorMeta, errorProductListDetail } }} />;

  if (loadingMeta || loadingProductListDetail) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="home-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="hourslocations-hero__div">
        <img src={urlGenerator('/S/static/buyout_strandexterior3.jpg')} className="hourslocations-hero__img" alt="hours and locations" />
      </div>
      <div className="hourslocations-container__div">
        <PageHeader header="Hours &amp; Locations" />
        <section className="hourslocations-content__section">
          {/*
          <div className="hourslocations-notice__div">
            <p className="hourslocations-notice__p">
              Holiday Hours
            </p>

            <div className="hourslocations-notice-table__div">
              <table className="hourslocations-notice-table__table">
                <thead className="hourslocations-notice-table__thead">
                  <tr className="hourslocations-notice-table__tr">
                    <th className="hourslocations-notice-table__th"><strong className="hourslocations-notice-table__hidden">Dates</strong></th>
                    <th className="hourslocations-notice-table__th"><strong>Main Location</strong></th>
                    <th className="hourslocations-notice-table__th"><strong>The Strand at Columbus</strong></th>
                  </tr>
                </thead>
                <tbody className="hourslocations-notice-table__tbody">
                  <tr className="hourslocations-notice-table__tr">
                    <td className="hourslocations-notice-table__td"><strong>December 24 (Xmas Eve)</strong></td>
                    <td className="hourslocations-notice-table__td">10:00am - 6:00pm</td>
                    <td className="hourslocations-notice-table__td">10:00am - 6:00pm</td>
                  </tr>

                  <tr className="hourslocations-notice-table__tr">
                    <td className="hourslocations-notice-table__td"><strong>December 25 (Christmas)</strong></td>
                    <td className="hourslocations-notice-table__td">CLOSED</td>
                    <td className="hourslocations-notice-table__td">CLOSED</td>
                  </tr>

                  <tr className="hourslocations-notice-table__tr">
                    <td className="hourslocations-notice-table__td"><strong>December 31 (NYE)</strong></td>
                    <td className="hourslocations-notice-table__td">10:00am - 7:00pm</td>
                    <td className="hourslocations-notice-table__td">10:00am - 7:00pm</td>
                  </tr>

                  <tr className="hourslocations-notice-table__tr">
                    <td className="hourslocations-notice-table__td"><strong>January 1</strong></td>
                    <td className="hourslocations-notice-table__td">
                      11:00am - 8:00pm
                      <br />
                      Buying Desk Closed
                    </td>
                    <td className="hourslocations-notice-table__td">12:00pm - 8:00pm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          */}
          <div className="hourslocations-content__div">
            {hoursLocationData.map((hld) => {
              const {
                id,
                title,
                image,
                imageAlt,
                details,
              } = hld;

              return (
                <div key={id} className={`hourslocations-location__div hourslocations-location-${id}__div`}>
                  <div className="hourslocations-location-image__div">
                    <LazyLoad once>
                      <img
                        src={image}
                        alt={imageAlt}
                        className="hourslocations-location-image__img"
                      />
                    </LazyLoad>
                  </div>
                  <div className="hourslocations-location-details__div">
                    <h2 className="hourslocations-header__h2">{title}</h2>
                    <div className="hourslocations-accent__div" />
                    <p
                      className="hourslocations-details__p"
                      dangerouslySetInnerHTML={
                        (() => ({ __html: details }))()
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
        <div className="hourslocations-border__div" />
        <section className="hourslocations-products__section">
          <div className="hourslocations-products-header__div">
            <h2 className="hourslocations-products-header__h2">
              Check Out Some of Our Products
            </h2>
          </div>
          <div className="hourslocations-products__div">
            <GenCarousel
              locationName="bestsellers"
              dataType="metaList"
              products={(
                dataMeta
                && dataMeta.bestsellersMeta
                && dataMeta.bestsellersMeta.product)
                || []
              }
            />
            <GenCarousel
              locationName="/productsection/MERCH-TOTES"
              dataType="default"
              header="Totes"
              products={(
                dataProductListDetail?.productListDetail
              )
                  || []
                }
            />
          </div>
        </section>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

HoursLocations.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default HoursLocations;
