const statesDictionary = [
  {
    abbreviation: 'AA',
    stateName: 'Armed Forces of the Americas',
  },
  {
    abbreviation: 'AE',
    stateName: 'Armed Forces of Europe',
  },
  {
    abbreviation: 'AL',
    stateName: 'Alabama',
  },
  {
    abbreviation: 'AK',
    stateName: 'Alaska',
  },
  {
    abbreviation: 'AP',
    stateName: 'Armed Forces of the Pacific',
  },
  {
    abbreviation: 'AS',
    stateName: 'American Samoa',
  },
  {
    abbreviation: 'AZ',
    stateName: 'Arizona',
  },
  {
    abbreviation: 'AR',
    stateName: 'Arkansas',
  },
  {
    abbreviation: 'CA',
    stateName: 'California',
  },
  {
    abbreviation: 'CO',
    stateName: 'Colorado',
  },
  {
    abbreviation: 'CT',
    stateName: 'Connecticut',
  },
  {
    abbreviation: 'DE',
    stateName: 'Delaware',
  },
  {
    abbreviation: 'DC',
    stateName: 'District of Columbia',
  },
  {
    abbreviation: 'FM',
    stateName: 'Federated States of Micronesia',
  },
  {
    abbreviation: 'FL',
    stateName: 'Florida',
  },
  {
    abbreviation: 'GA',
    stateName: 'Georgia',
  },
  {
    abbreviation: 'GU',
    stateName: 'Guam',
  },
  {
    abbreviation: 'HI',
    stateName: 'Hawaii',
  },
  {
    abbreviation: 'ID',
    stateName: 'Idaho',
  },
  {
    abbreviation: 'IL',
    stateName: 'Illinois',
  },
  {
    abbreviation: 'IN',
    stateName: 'Indiana',
  },
  {
    abbreviation: 'IA',
    stateName: 'Iowa',
  },
  {
    abbreviation: 'KS',
    stateName: 'Kansas',
  },
  {
    abbreviation: 'KY',
    stateName: 'Kentucky',
  },
  {
    abbreviation: 'LA',
    stateName: 'Louisiana',
  },
  {
    abbreviation: 'ME',
    stateName: 'Maine',
  },
  {
    abbreviation: 'MH',
    stateName: 'Marshall Islands',
  },
  {
    abbreviation: 'MD',
    stateName: 'Maryland',
  },
  {
    abbreviation: 'MA',
    stateName: 'Massachusetts',
  },
  {
    abbreviation: 'MI',
    stateName: 'Michigan',
  },
  {
    abbreviation: 'MN',
    stateName: 'Minnesota',
  },
  {
    abbreviation: 'MS',
    stateName: 'Mississippi',
  },
  {
    abbreviation: 'MO',
    stateName: 'Missouri',
  },
  {
    abbreviation: 'MT',
    stateName: 'Montana',
  },
  {
    abbreviation: 'NE',
    stateName: 'Nebraska',
  },
  {
    abbreviation: 'NV',
    stateName: 'Nevada',
  },
  {
    abbreviation: 'NH',
    stateName: 'New Hampshire',
  },
  {
    abbreviation: 'NJ',
    stateName: 'New Jersey',
  },
  {
    abbreviation: 'NM',
    stateName: 'New Mexico',
  },
  {
    abbreviation: 'NY',
    stateName: 'New York',
  },
  {
    abbreviation: 'NC',
    stateName: 'North Carolina',
  },
  {
    abbreviation: 'ND',
    stateName: 'North Dakota',
  },
  {
    abbreviation: 'MP',
    stateName: 'Northern Mariana Islands',
  },
  {
    abbreviation: 'OH',
    stateName: 'Ohio',
  },
  {
    abbreviation: 'OK',
    stateName: 'Oklahoma',
  },
  {
    abbreviation: 'OR',
    stateName: 'Oregon',
  },
  {
    abbreviation: 'PW',
    stateName: 'Palau',
  },
  {
    abbreviation: 'PA',
    stateName: 'Pennsylvania',
  },
  {
    abbreviation: 'PR',
    stateName: 'Puerto Rico',
  },
  {
    abbreviation: 'RI',
    stateName: 'Rhode Island',
  },
  {
    abbreviation: 'SC',
    stateName: 'South Carolina',
  },
  {
    abbreviation: 'SD',
    stateName: 'South Dakota',
  },
  {
    abbreviation: 'TN',
    stateName: 'Tennessee',
  },
  {
    abbreviation: 'TX',
    stateName: 'Texas',
  },
  {
    abbreviation: 'UT',
    stateName: 'Utah',
  },
  {
    abbreviation: 'VT',
    stateName: 'Vermont',
  },
  {
    abbreviation: 'VI',
    stateName: 'Virgin Islands',
  },
  {
    abbreviation: 'VA',
    stateName: 'Virginia',
  },
  {
    abbreviation: 'WA',
    stateName: 'Washington',
  },
  {
    abbreviation: 'WV',
    stateName: 'West Virginia',
  },
  {
    abbreviation: 'WI',
    stateName: 'Wisconsin',
  },
  {
    abbreviation: 'WY',
    stateName: 'Wyoming',
  },
];

export default statesDictionary;

// list taken from https://pe.usps.com/text/pub28/28apb.htm
