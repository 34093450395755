import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import giftGuide2021Data from './giftGuide2021Data';
import './_giftguide2021.scss';

const GiftGuide2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const match767 = useMediaQuery('(min-width: 767px)');
  const prefix = 'giftguide2021';

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className={`${prefix}-container__div`}>
        {scroll > 1000 && <CompFab />}
        <main className={`${prefix}-content__main`}>
          <div className={`${prefix}-content__div`}>
            {/* Holiday Gift Guide 2021 */}
            <section className={`${prefix}-hero__section`}>
              <div className={`${prefix}-hero__div`}>
                <img
                  src={match767 ? urlGenerator('/S/pages/gift-guide-2021-landing-banner.jpg') : urlGenerator('/S/pages/gift-guide-2021-landing-mobile-banner.jpg')}
                  className={`${prefix}-hero__img`}
                  alt="Holiday Gift Guide 2021"
                />
              </div>
            </section>
            <section className={`${prefix}-intro__section`}>
              <div className={`${prefix}-intro__div`}>
                <p className={`${prefix}-intro__p`}>
                  The Strand&#39;s 2021 Holiday Gift Guide is our biggest Gift Guide ever! Each
                  section has been carefully curated by booksellers at The Strand, so we know
                  you&#39;ll find something special for everyone in your life.
                </p>
              </div>
            </section>
            <div className={`${prefix}-border__div`} />
            <section className={`${prefix}-categories__section`}>
              <div className={`${prefix}-categories__div`}>
                {giftGuide2021Data.map(({
                  id, title, url, imgUrl, alt,
                }) => (
                  <LazyLoad key={id} height={300} offset={300}>
                    <div className={`${prefix}-category__div`}>
                      <div className={`${prefix}-category`}>
                        <Link
                          to={url || `/metalist/gift_guide_2021_${id}`}
                          className={`${prefix}-category-link__link`}
                          onClick={() => WithEvent('GIFT_GUIDE_2021', `Clicked ${title} tile`, 'GIFT_GUIDE_LINK', 1)}
                          aria-label={alt || title}
                        >
                          <img
                            className={`${prefix}-category-image__img`}
                            alt={alt || title}
                            src={imgUrl}
                          />
                        </Link>
                      </div>
                    </div>
                  </LazyLoad>
                ))}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

GiftGuide2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default GiftGuide2021;
