import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import GenCarousel from '../genCarousel/GenCarousel';
import Loading from '../loading/Loading';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_readsolutions2022.scss';

const META_LIST_DETAIL = gql`
  query{
    over50: metaListDetail(metaTag: "Reads-olutions: Author Over 50"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    bipoc: metaListDetail(metaTag: "Reads-olutions: BIPOC author"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    inTranslation: metaListDetail(metaTag: "Reads-olutions: In Translation"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    womensRights: metaListDetail(metaTag: "Reads-olutions: Women's Rights"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    longBook: metaListDetail(metaTag: "Reads-olutions: Long Book"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    poetry: metaListDetail(metaTag: "Reads-olutions: Poetry Collection"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    shortStory: metaListDetail(metaTag: "Reads-olutions: Short Story"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    manga: metaListDetail(metaTag: "Reads-olutions: Manga"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    graphic: metaListDetail(metaTag: "Reads-olutions: Graphic Novel"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    queer: metaListDetail(metaTag: "Reads-olutions: Queer Narrator"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    oneSitting: metaListDetail(metaTag: "Reads-olutions: Read in one sitting"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
  }
`;

const Readsolutions2022 = (props) => {
  const {
    cartList,
    checkoutIsOpen,
    customerLoggedIn,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (errorMeta) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="home-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="readsolutions-container__div">
        <main className="readsolutions-content__main">
          <div className="readsolutions-content__div">
            <div className="readsolutions-hero__div">
              <img
                src={match767 ? urlGenerator('/S/banners/banners_2022_readsolutions-desktop.png') : urlGenerator('/S/banners/banners_2022_readsolutions-mobile.png')}
                alt="Readsolutions 2022"
                className="readsolutions-hero__img"
              />
            </div>
            <div className="readsolutions-list__div">

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">1. Read a book written by someone over the age of 50</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_over_50"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.over50
                      && dataMeta.over50.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">2. Read a book by a BIPOC author</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_bipoc"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.bipoc
                      && dataMeta.bipoc.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">3. Read a book translated from another language</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_in_translation"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                        && dataMeta.inTranslation
                        && dataMeta.inTranslation.product
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">4. Read a book about women&apos;s rights</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_womens_rights"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.womensRights
                      && dataMeta.womensRights.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">5. Read an intimidatingly long book</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_long_book"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.longBook
                      && dataMeta.longBook.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">6. Read poetry</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_poetry"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                        && dataMeta.poetry
                        && dataMeta.poetry.product
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">7. Read a short story collection</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_short_story"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                        && dataMeta.shortStory
                        && dataMeta.shortStory.product
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">8. Read a manga</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_manga"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                        && dataMeta.manga
                        && dataMeta.manga.product
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">9. Read a graphic novel</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_graphic_novel"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                        && dataMeta.graphic
                        && dataMeta.graphic.product
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">10. Read a book with a queer narrator</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_queer"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.queer
                      && dataMeta.queer.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">11. Read a book in one sitting</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2022_one_sitting"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.oneSitting
                      && dataMeta.oneSitting.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">
                  12. Go to an&nbsp;
                  <a className="readsolutions-link__a" href="/events">
                    author event
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Readsolutions2022.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Readsolutions2022;
