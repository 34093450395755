import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import urlGenerator from '../../helpers/urlGenerator';
import './_pageNotFound.scss';

const useStyles = makeStyles({
  rootIconButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
});

const BlogNotFound = (props) => {
  const {
    history,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const classes = useStyles();

  const handleRedirect = () => history.push('/');

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="pagenotfound-container__div">
        <main className="pagenotfound-content__main">
          <div className="pagenotfound-content__div">
            <div className="pagenotfound-image__div">
              <img className="pagenotfound-image__img" src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" />
            </div>
            <h2 className="pagenotfound-header__h2">A New Strand Blog is Underway</h2>
            <div className="pagenotfound-details__div">
              <p className="pagenotfound-details__p">
                Thank you so much for visiting the Strand Book Store blog.
                <br />
                <br />
                We&#39;re still working on adding this feature up on our website.
                <br />
                Thank you for you patience!
                <br />
                <br />
                If you would like to continue browsing our store
              </p>
            </div>
            <div className="pagenotfound-home-btn__div">
              <IconButton
                disableTouchRipple
                disableRipple
                classes={{ root: classes.rootIconButton }}
                onClick={handleRedirect}
              >
                Go back to Home
              </IconButton>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BlogNotFound.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BlogNotFound;
