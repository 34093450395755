import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Loading from '../loading/Loading';
import './_checkoutForm.scss';

export const COUNTRY_LIST = gql`
  query{
    countryList{
      alpha2
      alpha3
      name
    }
  }
`;

const useStyles = makeStyles({
  rootIconBtn: {
    color: '#ffffff',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '18px',
    color: '#ffffff',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
    },
  },
  rootIconBtnRed: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  colorPrimaryAppBar: {
    backgroundColor: '#af272f',
  },
  rootTextField: {
    width: '100%',
    maxWidth: '500px',
    margin: '10px 0',
  },
  rootTextFieldUS: {
    width: '100%',
    maxWidth: '250px',
    margin: '10px 0',
  },
  rootCheckbox: {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedCheckbox: {
    color: '#af272f !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
});

const NumberFormatCCNumber = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#### #### #### ####"
    />
  );
};

NumberFormatCCNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberFormatCCmmyy = (props) => {
  const { inputRef, onChange, ...other } = props;

  const limit = (val, max) => {
    let returnVal = val;

    if (val.length === 1 && val[0] > max[0]) {
      returnVal = `0${val}`;
    }

    if (val.length === 2) {
      if (Number(val) === 0) {
        returnVal = '01';

      // this can happen when user paste number
      } else if (val > max) {
        returnVal = max;
      }
    }
    return returnVal;
  };

  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), '12');
    const year = val.substring(2, 4);

    return month + (year.length ? ` / ${year}` : '');
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      format={cardExpiry}
    />
  );
};

NumberFormatCCmmyy.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberFormatCVV = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="####"
      isNumericString
    />
  );
};

NumberFormatCVV.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberFormatGCNumber = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###################"
      isNumericString
    />
  );
};

NumberFormatGCNumber.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberFormatGCPin = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="########"
      isNumericString
    />
  );
};

NumberFormatGCPin.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberFormatCPF = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="#########-##"
      isNumericString
    />
  );
};

NumberFormatCPF.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CheckoutForm = (props) => {
  const classes = useStyles();
  const match1023 = useMediaQuery('(min-width: 1023px)');
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
  });

  const {
    checkoutState,
    setCheckout,
  } = props;

  const {
    error: countryError,
    loading: countryLoad,
  } = useQuery(COUNTRY_LIST);

  if (countryError) return <Redirect to={{ pathname: '/error', state: { countryError } }} />;

  if (countryLoad) return <Loading />;

  const handleBillSubmit = (e) => {
    e.preventDefault();
  };

  const handleFormChange = field => (
    (e) => {
      if ((field === 'billSte' || field === 'shipSte')) {
        if (e.target.value.length < 3) {
          setCheckout({ ...checkoutState, [field]: e.target.value.toUpperCase() });
        } else setCheckout({ ...checkoutState, [field]: e.target.value.slice(0, 1).toUpperCase() + e.target.value.slice(1, 2).toLowerCase() + e.target.value.slice(2) });
      } else

      if (field === 'shipCountry') {
        setCheckout({ ...checkoutState, [field]: e.target.value, shipSte: '' });
      } else if (field === 'billCountry') {
        setCheckout({ ...checkoutState, [field]: e.target.value, billSte: '' });
      } else setCheckout({ ...checkoutState, [field]: e.target.value });
    }
  );

  return (
    <div className="checkoutform-container__div">
      <section className="checkoutform-form__section">
        <div className="checkoutform-form__div">
          <form
            onSubmit={handleBillSubmit}
            className="checkoutform-form__form"
            autoComplete="true"
          >
            <ThemeProvider theme={theme}>
              <TextField
                id="field-1"
                label="First Name"
                value={checkoutState.shipName}
                onChange={handleFormChange('shipName')}
                variant="outlined"
                autoComplete="given-name"
                required
                classes={{ root: classes.rootTextField }}
              />
              <TextField
                id="field-2"
                label="Last Name"
                value={checkoutState.shipLastName}
                onChange={handleFormChange('shipLastName')}
                variant="outlined"
                autoComplete="family-name"
                required
                classes={{ root: classes.rootTextField }}
              />
              <div className="checkoutform-po__div">
                <TextField
                  id="purchase-order"
                  label="Purchase Order No."
                  value={checkoutState.poNumber}
                  onChange={handleFormChange('poNumber')}
                  variant="outlined"
                  autoComplete="purchase-order"
                  required
                  classes={{ root: classes.rootTextField }}
                />
                <TextField
                  id="email-po"
                  label="Email"
                  value={checkoutState.poEmail}
                  onChange={handleFormChange('poEmail')}
                  variant="outlined"
                  autoComplete="email"
                  required
                  classes={{ root: classes.rootTextField }}
                  inputProps={{
                    type: 'email',
                  }}
                />
                <TextField
                  id="phone-po"
                  label="Phone #"
                  value={checkoutState.poContact}
                  onChange={handleFormChange('poContact')}
                  variant="outlined"
                  autoComplete="tel"
                  required
                  classes={{ root: classes.rootTextField }}
                />
              </div>
            </ThemeProvider>
          </form>
        </div>
      </section>
      <section className="checkoutform-instructions__section">
        <div className="checkoutform-instructions__div">
          <form className="checkoutform-instructions__form">
            <label htmlFor="specialIns" id="special-instructions">
              <div className="checkoutform-count__div">
                <h3 className="checkoutform-header__h3">
                  Special Instructions&nbsp;&nbsp;
                  <span className="checkoutform-count__span">
                    {checkoutState.SPUSpecialIns.length > 0 && checkoutState.SPUSpecialIns.length}
                    &nbsp;
                    {checkoutState.shipSpecialIns.length > 0 && 'char.'}
                  </span>
                </h3>
              </div>
              <textarea
                rows="5"
                cols={match1023 ? 50 : 35}
                className="checkoutform-instructions__textarea"
                value={checkoutState.SPUSpecialIns}
                onChange={handleFormChange('SPUSpecialIns')}
                maxLength={500}
                aria-labelledby="special-instructions"
                id="specialIns"
                data-testid="special-instructions"
              />
            </label>
          </form>
        </div>
      </section>
    </div>
  );
};

CheckoutForm.propTypes = {
  checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  setCheckout: PropTypes.func.isRequired,
};

export default CheckoutForm;
