import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import CompFab from '../compFab/CompFab';
import PageHeader from '../pageHeader/PageHeader';
import useScrollY from '../../hooks/useScrollY';
import './_submissionsPage.scss';

const SubmissionsPage = (props) => {
  const {
    match,
    submissionsList,
    is767,
  } = props;

  const scroll = useScrollY();

  const submitItem = submissionsList.filter(sub => sub.id === match?.params?.submissionNo).pop();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  if (!submitItem?.id) {
    return <Redirect to="/page-not-found" />;
  }

  if (moment.tz(moment(), 'America/New_York').isAfter(moment.tz(submitItem?.endObj, 'America/New_York'))) {
    return <Redirect to="/submissions/closed" />;
  }

  return (
    <React.Fragment>
      { scroll > 1000 && <CompFab />}
      <div className="submissionspage-container__div">
        <main className="submissionspage-content__main">
          <div className="submissionspage-content__div">
            <section className="submissionspage-header__section">
              <div className="submissionspage-header__div">
                <img
                  className="submissionspage-header__img"
                  src={is767 ? submitItem?.img : submitItem?.imgMobile}
                  alt={submitItem.title}
                />
              </div>
            </section>
            <section className="submissionspage-details__section">
              <PageHeader header={submitItem.title} />
              <div className="submissionspage-details__div" dangerouslySetInnerHTML={(() => ({ __html: submitItem?.intro }))()} />
            </section>
            <section className="submissionspage-form__section">
              <div className="submissionspage-form__div">
                <iframe
                  data-testid="contest-form"
                  id={submitItem?.id}
                  title="Enter the #MyStrandTote Contest"
                  // eslint-disable-next-line
                  allowtransparency="true"
                  allowFullScreen="true"
                  allow="geolocation; microphone; camera"
                  src="https://form.jotform.com/210765182726155"
                  frameBorder="0"
                  style={{
                    minWidth: '100%',
                    height: '539px',
                    border: 'none',
                  }}
                  scrolling="yes"
                />
              </div>
            </section>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

SubmissionsPage.propTypes = {
  submissionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  is767: PropTypes.bool.isRequired,
};

export default SubmissionsPage;
