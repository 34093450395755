import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import './_bbtfGalleryDialog.scss';


const BBTFGalleryDialog = (props) => {
  const {
    open,
    handleDialogClose,
    image,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
    >
      <div className="bbtfgallerydialog-image__div">
        <img src={image && image.image} alt="BBTF Gallery" className="bbtfgallerydialog-image__img" />
      </div>
    </Dialog>
  );
};

BBTFGalleryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  image: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BBTFGalleryDialog;
