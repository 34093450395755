import React from 'react';
import PropTypes from 'prop-types';
import submissionsList from './submissionsList';
import SubmissionsPage from './SubmissionsPage';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import use4Cite from '../../hooks/use4Cite';


const SubmissionsContainer = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
    match,
  } = props;

  const match767 = useMediaQueryMinWidth(767);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <SubmissionsPage
        submissionsList={submissionsList}
        match={match}
        is767={match767}
      />
      <ViewFooter />
    </React.Fragment>
  );
};

SubmissionsContainer.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubmissionsContainer;
