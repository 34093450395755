import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import './_footer.scss';

const Footer = (props) => {
  const {
    opened,
    handleCollapse,
    // email,
    // handleEmail,
    // handleSubmit,
  } = props;

  return (
    <div className="footer-container__div">
      <div className="footer-item__div footer-item__div--list footer-item__div--first">
        <button
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('books');
            document.getElementsByClassName('footer-btn__button')[0].classList.toggle('footer-btn__button--active');
          }
        }
        >
          Books &amp; Media
          {
            opened.books
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.books}>
          <div className="footer-collapse__div">
            <NavLink
              to="/metalist/bestsellers"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bestsellers link', 'FOOTER_LINK', 1)}
            >
              Bestsellers
            </NavLink>

            <NavLink
              to="/browse-sections"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked browse by section link', 'FOOTER_LINK', 1)}
            >
              Browse by Section
            </NavLink>

            <NavLink
              to="/books-media"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked browse collections link', 'FOOTER_LINK', 1)}
            >
              Browse Collections
            </NavLink>

            <NavLink
              to="/metalist/new_arrivals"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked new arrivals link', 'FOOTER_LINK', 1)}
            >
              New Arrivals
            </NavLink>

            <NavLink
              to="/metalist/signed"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked signed link', 'FOOTER_LINK', 1)}
            >
              Signed
            </NavLink>

            <NavLink
              to="/books-media/authors-bookshelf"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked authors bookshelf link', 'FOOTER_LINK', 1)}
            >
              The Author&#39;s Bookshelf
            </NavLink>
          </div>
        </Collapse>
      </div>

      <div className="footer-item__div footer-item__div--list">
        <button
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('bbtf');
            document.getElementsByClassName('footer-btn__button')[1].classList.toggle('footer-btn__button--active');
          }}
        >
          Books By The Foot
          {
            opened.bbtf
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.bbtf}>
          <div className="footer-collapse__div">
            <NavLink
              to="/books-by-the-foot/"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked about bbtf link', 'FOOTER_LINK', 1)}
            >
              About BBTF
            </NavLink>
            <NavLink
              to="/books-by-the-foot/color"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf color link', 'FOOTER_LINK', 1)}
            >
              Books By Color
            </NavLink>
            <NavLink
              to="/books-by-the-foot/style"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf style link', 'FOOTER_LINK', 1)}
            >
              Books By Style
            </NavLink>
            <NavLink
              to="/books-by-the-foot/subject"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf subject link', 'FOOTER_LINK', 1)}
            >
              Books By Subject
            </NavLink>
            <NavLink
              to="/books-by-the-foot/FAQ"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf FAQ link', 'FOOTER_LINK', 1)}
            >
              FAQ
            </NavLink>
            <NavLink
              to="/books-by-the-foot/gallery"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf gallery link', 'FOOTER_LINK', 1)}
            >
              Gallery
            </NavLink>
            <NavLink
              to="/books-by-the-foot/rentals"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked bbtf rentals link', 'FOOTER_LINK', 1)}
            >
              Rentals
            </NavLink>
          </div>
        </Collapse>
      </div>

      <div className="footer-item__div footer-item__div--list">
        <button
          // disabled
          // style={{ pointerEvents: 'none', color: '#6c6c6c' }}
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('events');
            document.getElementsByClassName('footer-btn__button')[2].classList.toggle('footer-btn__button--active');
          }}
        >
          Events
          {
            opened.events
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.events}>
          <div className="footer-collapse__div">
            <NavLink
              to="/events"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked all events link', 'FOOTER_LINK', 1)}
            >
              All Events
            </NavLink>
            <NavLink
              to="/preorders"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked all preorders link', 'FOOTER_LINK', 1)}
            >
              Preorders
            </NavLink>
          </div>
        </Collapse>
      </div>

      {/* <div className="footer-item__div footer-item-header__div">
        <NavLink to="/staff-picks" className="footer-link-header__navlink">
          Staff Picks
        </NavLink>
      </div> */}

      <div className="footer-item__div footer-item__div--list">
        <button
          // disabled
          // style={{ pointerEvents: 'none', color: '#6c6c6c' }}
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('gifts');
            document.getElementsByClassName('footer-btn__button')[3].classList.toggle('footer-btn__button--active');
          }}
        >
          Gifts &amp; Apparel
          {
            opened.gifts
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.gifts}>
          <div className="footer-collapse__div">
            <NavLink
              to="/apparel"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked apparel link', 'FOOTER_LINK', 1)}
            >
              Apparel
            </NavLink>

            <NavLink
              to="/productsection/MERCH-BOOK%20ACCESSORIES"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked accessories link', 'FOOTER_LINK', 1)}
            >
              Book Accessories
            </NavLink>

            <NavLink
              to="/productsection/MERCH-GIFT"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked gifts and fun stuff link', 'FOOTER_LINK', 1)}
            >
              Gifts &amp; Fun Stuff
            </NavLink>

            <NavLink
              to="/productsection/MERCH-KIDS%20GIFT"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked gifts for kids link', 'FOOTER_LINK', 1)}
            >
              Gifts for Kids
            </NavLink>

            <NavLink
              to="/productsection/MERCH-MUGS"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked mugs link', 'FOOTER_LINK', 1)}
            >
              Mugs
            </NavLink>

            <NavLink
              to="/productsection/MERCH-POUCHES"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked pouches link', 'FOOTER_LINK', 1)}
            >
              Pouches
            </NavLink>

            <NavLink
              to="/productsection/MERCH-Stationery"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked souvenirs link', 'FOOTER_LINK', 1)}
            >
              Stationery
            </NavLink>

            <NavLink
              to="/productsection/MERCH-TOTES"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked totes link', 'FOOTER_LINK', 1)}
            >
              Totes
            </NavLink>
          </div>
        </Collapse>
      </div>
      <div className="footer-item__div footer-item__div--list">
        <button
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('rare');
            document.getElementsByClassName('footer-btn__button')[4].classList.toggle('footer-btn__button--active');
          }}
        >
          Rare &amp; Collectibles
          {
            opened.rare
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.rare}>
          <div className="footer-collapse__div">
            <NavLink
              to="/rare"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked rare link', 'FOOTER_LINK', 1)}
            >
              Featured Rare Books
            </NavLink>

            <NavLink
              to="/more/rent-the-rare-book-room"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked rare link', 'FOOTER_LINK', 1)}
            >
              Rent the Rare Book Room
            </NavLink>
            {/* <NavLink to="/rare#subjects" className="footer-link__navlink">
              Browse by Genre
            </NavLink> */}
          </div>
        </Collapse>
      </div>

      <div className="footer-item__div footer-item__div--list">
        <button
          className="footer-btn__button"
          type="button"
          onClick={() => {
            handleCollapse('more');
            document.getElementsByClassName('footer-btn__button')[5].classList.toggle('footer-btn__button--active');
          }}
        >
          Sell Your Books &amp; More
          {
            opened.more
              ? <ExpandLess className="footer-expand__expandless" />
              : <ExpandMore className="footer-expand__expandmore" />
          }
        </button>
        <Collapse in={opened.more}>
          <div className="footer-collapse__div">
            <NavLink
              to="/more/library-services"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked library services link', 'FOOTER_LINK', 1)}
            >
              Library Services
            </NavLink>

            <NavLink
              to="/more/sell-books"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked sell books link', 'FOOTER_LINK', 1)}
            >
              Sell Your Books
            </NavLink>

            <NavLink
              to="/more/strandcast"
              className="footer-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked StrandCast link', 'FOOTER_LINK', 1)}
            >
              The StrandCast
            </NavLink>
          </div>
        </Collapse>
      </div>
      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/about"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked about link', 'FOOTER_LINK', 1)}
        >
          About
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/books-media/bookhookup"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked book hookup link', 'FOOTER_LINK', 1)}
        >
          Book HookUp
        </NavLink>
      </div>


      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/buyout-price"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked buyout inquiry link', 'FOOTER_LINK', 1)}
        >
          Buyout &amp; Filming Inquiries
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/contact"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked contact link', 'FOOTER_LINK', 1)}
        >
          Contact Us
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/help/faq"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked FAQ link', 'FOOTER_LINK', 1)}
        >
          FAQ
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/gifts-apparel/gift-cards"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked gift cards link', 'FOOTER_LINK', 1)}
        >
          Gift Cards
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/pick-of-the-month"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked book of the month link', 'FOOTER_LINK', 1)}
        >
          Pick Of The Month
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <a
          href="https://strandbooks.us9.list-manage.com/subscribe?u=0afd67a5c5ed54633aface96f&id=94fd8572f3"
          className="footer-link-header__a"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => WithEvent('FOOTER', 'Clicked sign up link', 'FOOTER_LINK', 1)}
        >
          Sign up for the Strand Insider Newsletter
        </a>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/hours-locations"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked hours location link', 'FOOTER_LINK', 1)}
        >
          Store Hours &amp; Locations
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/work-at-strand"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked work at strand link', 'FOOTER_LINK', 1)}
        >
          Work at Strand
        </NavLink>
      </div>

      <div className="footer-item__div footer-item-header__div">
        <NavLink
          to="/accessibility-statement"
          className="footer-link-header__navlink"
          onClick={() => WithEvent('FOOTER', 'Clicked accessibility statement link', 'FOOTER_LINK', 1)}
        >
          Accessibility Statement
        </NavLink>
      </div>

      <div className="footer-newsletter__div">
        {/* <p className="footer-form-header__p">
          Sign up for the Strand Insider Newsletter
        </p>
        <form onSubmit={handleSubmit}>
          <input aria-label="Newsletter Sign up" className="footer-input__input" type="email" value={email} onChange={handleEmail} />
          <input className="footer-input-submit__input" type="submit" value="Sign up" />
        </form> */}
        <div className="footer-logo__div">
          <a
            href="https://www.facebook.com/strandbookstore/"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => WithEvent('FOOTER', 'Clicked facebook link', 'FOOTER_LINK', 1)}
          >
            <img src={urlGenerator('/S/pages/facebook_logo.png')} className="footerdesktop-logo__img" alt="facebook-logo" />
          </a>
          <a
            href="https://www.instagram.com/strandbookstore/?hl=en"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => WithEvent('FOOTER', 'Clicked instagram link', 'FOOTER_LINK', 1)}
          >
            <img src={urlGenerator('/S/pages/ig_logo.png')} className="footerdesktop-logo__img" alt="instagram-logo" />
          </a>
          <a
            href="https://twitter.com/strandbookstore"
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => WithEvent('FOOTER', 'Clicked twitter link', 'FOOTER_LINK', 1)}
          >
            <img src={urlGenerator('/S/pages/twitter_logo.png')} className="footerdesktop-logo__img" alt="twitter-logo" />
          </a>

          {window.sealElement && <div dangerouslySetInnerHTML={{ __html: window.sealElement.outerHTML }} />}
        </div>
        <div className="footer-copyright__div">
          <p className="footer-copyright-text__p">
            &copy; Copyright 2020 Strand Book Store All Rights Reserved /&nbsp;
            <NavLink
              to="/privacy"
              className="footer-copyright-link__navlink"
              onClick={() => WithEvent('FOOTER', 'Clicked privacy policy link', 'FOOTER_LINK', 1)}
            >
              Privacy Policy
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  opened: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleCollapse: PropTypes.func.isRequired,
  // email: PropTypes.string.isRequired,
  // handleEmail: PropTypes.func.isRequired,
  // handleSubmit: PropTypes.func.isRequired,
};

export default Footer;
