import ReactGA from 'react-ga4';

// const WithEcomm = (id, name, sku, price, quantity, revenue, affiliation = 'none') => {
//   // send Item Data
//   ReactGA.plugin.execute(
//     'ecommerce',
//     'addItem',
//     {
//       id,
//       name,
//       sku,
//       price,
//       quantity,
//     },
//   );
//   // send Transaction Data
//   ReactGA.plugin.execute(
//     'ecommerce',
//     'addTransaction',
//     {
//       id,
//       revenue,
//       affiliation,
//     },
//   );

//   ReactGA.plugin.execute('ecommerce', 'send');
//   ReactGA.plugin.execute('ecommerce', 'clear');
// };

const WithEcommUpdated = (itemList, id, revenue, tally, affiliation = 'none') => {
  itemList.forEach((item) => {
    const {
      qty,
      sku,
      salePriceStv,
      productDetail,
    } = item;

    ReactGA.event(
      'addItem',
      'ecommerce',
      {
        id,
        name: productDetail?.title || 'no name',
        sku,
        price: parseFloat((salePriceStv * 0.01)).toFixed(2),
        quantity: qty,
      },
    );
  });

  // send Transaction Data
  ReactGA.event(
    'addTransaction',
    'ecommerce',
    {
      id,
      revenue: (tally.totalStv * 0.01).toFixed(2),
      shipping: (tally.shippingStv * 0.01).toFixed(2),
      tax: (tally.taxStv * 0.01).toFixed(2),
      affiliation,
    },
  );
};

export default WithEcommUpdated;
