import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import Loading from '../loading/Loading';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_forgotPW.scss';

export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!){
    customerRecover(email: $email)
  }
`;

const useStyles = makeStyles({
  paperDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    width: '125px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootIconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
  rootTextField: {
    margin: '10px 0',
  },
  rootIconButtonClose: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#af272f',
    },
  },
});

const ForgotPW = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const classes = useStyles();
  const scroll = useScrollY();
  const [inputState, setInput] = useState({
    forgotEmailInput: '',
    passwordInput: '',
    emailInput: '',
    firstNameInput: '',
    lastNameInput: '',
    phoneInput: '',
    newEmailInput: '',
    newPasswordInput: '',
    confirmPasswordInput: '',
    signUp: false,
    resetPassword: false,
    logIn: true,
  });

  const handleInput = name => (e) => {
    // console.log(e.target.value);
    setInput({ ...inputState, [name]: e.target.value });
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const [
    customerRecover,
    {
      data: dataCustomerRecover,
      loading: loadingCustomerRecover,
      error: errorCustomerRecover,
    }] = useMutation(RECOVER_PASSWORD);

  if (errorCustomerRecover) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            errorCustomerRecover,
          },
        }}
      />
    );
  }

  if (loadingCustomerRecover) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="forgotpw-container__div">
          <main className="forgotpw-content__main">
            { scroll > 1000 && <CompFab />}
            <div className="forgotpw-content__div">
              <Loading />
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  // console.log(dataCustomerRecover);

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="forgotpw-container__div">
        <main className="forgotpw-content__main">
          <div className="forgotpw-content__div">
            <ThemeProvider theme={theme}>
              <div className="forgotpw-header-image__div">
                <img className="forgotpw-header-image__img" src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" />
              </div>
              <h1 className="forgotpw-header__h1">Reset Password</h1>
              {
                dataCustomerRecover
                && !dataCustomerRecover.customerRecover
                && (
                  <p className="forgotpw-error__p">
                    It looks like we don&#39;t have an account associated with this email in our records. Please&nbsp;
                    <a className="forgotpw-link__a" href="/signup">sign up</a>
                    &nbsp;to create a new account.
                  </p>
                )
              }
              {
                dataCustomerRecover
                  && dataCustomerRecover.customerRecover
                  && <p className="forgotpw-success__p">A link has been sent to your email to reset your password.</p>
              }
              <div className="forgotpw-form__div">
                <p className="forgotpw-subheader__p">Enter your email address and we&#39;ll send you instructions to reset your password</p>
                <form
                  className="forgotpw-form__form"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();

                    Sentry.addBreadcrumb({
                      category: 'FORGOT_PASSWORD',
                      message: 'Clicked Reset Password',
                      level: Sentry.Severity.Info,
                    });

                    customerRecover({ variables: { email: inputState.forgotEmailInput } });
                  }}
                >
                  {
                    inputState.resetPassword
                    && dataCustomerRecover
                    && dataCustomerRecover.customerRecover
                    && <p className="forgotpw-success__p">A link has been sent to your email to reset your password.</p>
                  }
                  <TextField
                    onChange={handleInput('forgotEmailInput')}
                    label="Email Address"
                    id="reset-email"
                    value={inputState.usernameInput}
                    variant="outlined"
                    className="forgotpw-input__textfield"
                    type="email"
                    classes={{ root: classes.rootTextField }}
                    InputProps={{
                      'aria-label': 'Email Address',
                    }}
                  />
                  <input aria-label="reset-password" className="loginsignup-submit__input loginsignup-submit-reset__input" type="submit" value="Reset Password" />
                </form>
              </div>
            </ThemeProvider>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ForgotPW.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ForgotPW;
