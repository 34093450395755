import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/styles';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import BBTFDialog from './BBTFDialog';
import bbtfList from './bbtfList';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import './_bbtfRentals.scss';


const useStyles = makeStyles({
  rootButtonBase: {
    fontSize: '.85em',
    fontFamily: ['Trade Gothic LT Std', 'Proxima Nova, sans-serif', 'Arial'],
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
    },
  },
});

const BBTFRentals = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const classes = useStyles();
  const [dialogState, setDialog] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    21: false,
    22: false,
    23: false,
    24: false,
    25: false,
    26: false,
    27: false,
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const handleDialogOpen = i => setDialog({ ...dialogState, [i]: true });

  const handleDialogClose = () => setDialog({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    21: false,
    22: false,
    23: false,
    24: false,
    25: false,
    26: false,
    27: false,
  });

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtfrentals-container__div">
        <div className="bbtfrentals-hero__div" />
        <main className="bbtfrentals-content__main">
          <PageHeader header="Rentals" />
          <div className="bbtfrentals-content__div">
            <section className="bbtfrentals-header__section">
              <div className="bbtfrentals-header__div">
                <h3 className="bbtfrentals-header__h3">
                  Books By The Foot creates custom rental libraries to match and
                  enhance the look of any set, photo shoot, design installation, wedding or event.
                  Past clients include&nbsp;
                  <strong>Saturday Night Live</strong>
                  ,&nbsp;
                  <strong>Something&#39;s Gotta Give</strong>
                  &nbsp;and&nbsp;
                  <strong>The Royal Tenenbaums</strong>
                  . Our team of experts will scour the Strand
                  to select books by color, genre, binding, subject, size,
                  and more to create the perfect collection.
                  <br />
                  <br />
                  Collections can be curated in as fast as one day.
                  Contact us at&nbsp;
                  <a
                    href="mailto: bbtf@strandbooks.com"
                    className="bbtfrentals-link__a"
                    onClick={() => WithEvent('BBTF', 'Clicked Email Button', 'BBTF_RENTAL', 1)}
                  >
                    bbtf@strandbooks.com
                  </a>
                  &nbsp;
                  or&nbsp;
                  <a
                    href="tel: +12126606646"
                    className="bbtfrentals-link__a"
                    onClick={() => WithEvent('BBTF', 'Clicked Phone Button', 'BBTF_RENTAL', 1)}
                  >
                    212-660-6646
                  </a>
                  . Please note that prices may vary based on add-ons and special
                  requests. To ensure a timely delivery,
                  please let us know your lead time in advance.
                  <br />
                  <br />
                  You can check out our&nbsp;
                  <Link
                    to="/books-by-the-foot/terms-conditions"
                    className="bbtfrentals-link__link"
                    onClick={() => WithEvent('BBTF', 'Clicked Terms Button', 'BBTF_RENTAL', 1)}
                  >
                    Terms and Conditions
                  </Link>
                  &nbsp;and&nbsp;
                  <Link
                    to="/books-by-the-foot/faq"
                    className="bbtfrentals-link__link"
                    onClick={() => WithEvent('BBTF', 'Clicked FAQ Button', 'BBTF_RENTAL', 1)}
                  >
                    FAQ&#39;s
                  </Link>
                  &nbsp;for more information on orders.
                </h3>
              </div>
            </section>
            <div className="bbtfrentals-divider__div" />
            <section className="bbtfrentals-collections__section">
              <div className="bbtfrentals-details__div">
                <p className="bbtfrentals-details__p">
                  Place an order by sending an e-mail to&nbsp;
                  <a
                    className="bbtfrentals-link__a"
                    href="mailto: bksbythefoot@strandbooks.com"
                    onClick={() => WithEvent('BBTF', 'Clicked 2nd Email Button', 'BBTF_RENTAL', 1)}
                  >
                    bksbythefoot@strandbooks.com
                  </a>
                  &nbsp;or calling&nbsp;
                  <a
                    href="tel: +12126606646"
                    className="bbtfrentals-link__a"
                    onClick={() => WithEvent('BBTF', 'Clicked 2nd Tel Button', 'BBTF_RENTAL', 1)}
                  >
                    212-660-6646
                  </a>
                  .
                </p>
              </div>
              <div className="bbtfrentals-collections__div">
                {
                  bbtfList.bbtfRentalCollections.map((col, i) => {
                    const {
                      id,
                      colImage,
                      colTitle,
                      price,
                      galleryImages,
                      colDesc,
                    } = col;

                    return (
                      <div
                        className="bbtfrentals-collection__div"
                        key={id}
                      >
                        <div className="bbtfrentals-image__div">
                          <img
                            src={colImage}
                            alt={colTitle}
                            className="bbtfrentals-image__img"
                          />
                        </div>
                        <div className="bbtfrentals-button__div">
                          <ButtonBase
                            className="bbtfrentals-images__buttonbase"
                            disableRipple
                            disableTouchRipple
                            classes={{ root: classes.rootButtonBase }}
                            onClick={() => {
                              // handleImages(galleryImages);
                              WithEvent('BBTF', `Clicked ${colTitle} Images`, 'BBTF_RENTAL', 1);
                              handleDialogOpen(i);
                              // handleDialogOpen();
                            }}
                          >
                            View more images
                          </ButtonBase>
                        </div>
                        <div className="bbtfrentals-details__div">
                          <h4 className="bbtfrentals-header__h4">{colTitle}</h4>
                          {/* <div className="bbtfrentals-accent__div" /> */}
                          <p className="bbtfrentals-details__p bbtfrentals-price__p">
                            {price}
                          </p>
                        </div>
                        <BBTFDialog
                          onClose={handleDialogClose}
                          open={dialogState[i]}
                          images={galleryImages}
                          desc={colDesc}
                        />
                      </div>
                    );
                  })
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      {/* <BBTFDialog
        onClose={handleDialogClose}
        open={dialogState}
        images={imagesState}
      /> */}
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFRentals.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFRentals;
