import React, { useState, useEffect } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuRounded from '@material-ui/icons/MenuRounded';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import { makeStyles } from '@material-ui/core/styles';
import GlobalBanner from '../globalBanner/GlobalBanner';
import DrawerNav from '../drawerNav/DrawerNav';
import SearchBarDesktop from '../searchBar/SearchBarDesktop';
import NavMenu from '../navMenu/NavMenu';
import WithEvent from '../withEvent/WithEvent';
import urlGenerator from '../../helpers/urlGenerator';
import useToggleLoginSignup from '../../hooks/useToggleLoginSignup';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import useUserLogOut from '../../hooks/useUserLogOut';
import './_navbarDesktop.scss';
import LoginSignup from '../loginSignup/LoginSignup';

const useStyles = makeStyles({
  rootAppBar: {
    boxShadow: 'none',
  },
  appbarColorPrimary: {
    backgroundColor: '#fbfaf6',
  },
  iconBtnRoot: {
    color: '#af272f',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#d4301a',
    },
  },
  menuRoot: {
    width: '1.50em',
    height: '1.50em',
  },
  searchRoot: {
    width: '1.50em',
    height: '1.50em',
  },
  accountRoot: {
    width: '1.50em',
    height: '1.50em',
    color: '#af272f',

    '&:hover': {
      color: 'd4301a',
    },
  },
  shoppingRoot: {
    width: '1.50em',
    height: '1.50em',
  },

  badgeColorPrimary: {
    backgroundColor: '#af272f',
  },
  rootLogoutButton: {
    fontSize: '15px',
    fontfamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    color: '#000000',
    margin: 'auto',
    '&:hover': {
      color: '#d4301a',
    },
  },
  // positionFixed: {
  //   top: '50px',
  // },
});

const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        catalogId
        description
        gtin
        isTaxable
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      customer{
        customerId
        firstName
        lastName
        verified
      }
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

const OPEN_CHECKOUT = gql`
  query{
    checkoutIsOpen
  }
`;

const CUSTOMER_LOGGED_IN = gql`
  query{
    customerLoggedIn
  }
`;

const NavbarDesktopQuery = () => {
  const toggleLoginSignup = useToggleLoginSignup();
  const classes = useStyles();

  const {
    compSwitchState: openDrawer,
    handleCompToggle: drawerToggle,
  } = useOpenCloseComponent();

  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    data: dataLoggedIn,
    refetch: refetchLoggedIn,
  } = useQuery(CUSTOMER_LOGGED_IN, { fetchPolicy: 'cache-and-network' });

  const {
    customerLogout,
    error: errorLogout,
    loading: loadingLogout,
    data: dataLogout,
  } = useUserLogOut();

  const {
    refetch: openRefetch,
    loading: openLoad,
    error: openError,
  } = useQuery(OPEN_CHECKOUT);

  const {
    data,
    loading,
    error,
    refetch: cartRefresh,
  } = useQuery(GET_CART_DETAILS, { fetchPolicy: 'cache-and-network' });

  const [menuState, setMenu] = useState({
    events: false,
    about: false,
    hours: false,
  });

  const [subMenuState, setSubMenu] = useState(null);

  const enterAccount = () => setSubMenu('account');

  useEffect(() => {
    cartRefresh();
    openRefetch();
  }, [cartRefresh, openRefetch]);

  if (loading || openLoad || loadingLoggedIn || loadingLogout) return <div />;

  if (openError || error || errorLoggedIn || errorLogout) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            openError,
            error,
            errorLoggedIn,
            errorLogout,
          },
        }}
      />
    );
  }

  if (dataLogout && dataLogout.customerLogout) {
    refetchLoggedIn();
  }

  const handleLogOut = () => {
    // e.preventDefault();
    customerLogout();
    setSubMenu(null);
    toggleLoginSignup[2]();
    cartRefresh();
    refetchLoggedIn();
  };

  const handleMouseLeave = () => (
    setMenu({ events: false, about: false, hours: false })
  );

  const cartItemCount = (data?.cartList?.find(cart => cart?.cartType === 'PRIMARY')?.cartItem.reduce((acc, { qty }) => qty + acc, 0))
    || 0;

  const cartLabel = `Cart${cartItemCount > 0 ? ` (${cartItemCount} item${cartItemCount === 1 ? '' : 's'})` : ''}`;

  return (
    <div className="navbardesktop-container__div">
      <AppBar
        className="navbardesktop-appbar__appbar"
        classes={{ colorPrimary: classes.appbarColorPrimary, root: classes.rootAppBar }}
        position="fixed"
      >
        <GlobalBanner
          message="Open Daily 10AM-8PM"
          link="https://www.strandbooks.com/hours-locations"
        />
        <Toolbar disableGutters>
          <div className="navbardesktop-icons__div">
            <div className="navbardesktop-lefticon__div">
              <IconButton aria-label="menu" onClick={drawerToggle} classes={{ root: classes.iconBtnRoot }}>
                <MenuRounded
                  className="navbar-menurounded__menurounded"
                  classes={{ root: classes.menuRoot }}
                />
              </IconButton>
              <SearchBarDesktop />
            </div>

            <div className="navbardesktop-logo__div">
              <NavLink
                to="/"
                className="navbardesktop-navlink__navlink"
                onClick={() => WithEvent('NAVBAR', 'Clicked logo link', 'NAVBAR_LINK', 1)}
              >
                <img
                  className="navbardesktop-image__img"
                  alt="Strand Book Store"
                  src={urlGenerator('/S/static/strand-logo-exact.png')}
                />
              </NavLink>
            </div>

            <div className="navbardesktop-righticon__div">
              <div
                className="navbardesktop-right-icons__div"
                onMouseLeave={() => setSubMenu(null)}
              >
                <NavLink
                  className="navbardesktop-cart__navlink"
                  to="/cart"
                  onClick={() => WithEvent('NAVBAR', 'Clicked cart link', 'NAVBAR_LINK', 1)}
                  aria-label={cartLabel}
                >
                  <Badge
                    className="navbar-badge__badge"
                    badgeContent={cartItemCount}
                    color="primary"
                    classes={{ colorPrimary: classes.badgeColorPrimary }}
                  >
                    <ShoppingBasket
                      className="navbardesktop-shoppingbasket__shoppingbasket"
                      classes={{ root: classes.shoppingRoot }}
                    />
                  </Badge>
                </NavLink>
                <div
                  className="navbardesktop-link__div"
                  onMouseEnter={enterAccount}
                  onFocus={enterAccount}
                >
                  <IconButton
                    style={{ position: 'relative' }}
                    className="navbardesktop-bottom-link__navlink navbardesktop-bottom-link-account__navlink  navbardesktop-account__navlink"
                    aria-label="account"
                    onClick={() => setSubMenu(subMenuState === 'account' ? null : 'account')}
                    classes={{ root: classes.iconBtnRoot }}
                  >
                    <AccountCircle
                      className="navbardesktop-account-icon__accountcircle"
                      classes={{ root: classes.accountRoot }}
                    />
                  </IconButton>
                  <div className={`navbardesktop-submenu__div${subMenuState === 'account' ? ' navbardesktop-submenu-account__div--active' : ''}`}>
                    <div className="navbardesktop-submenu-links__div navbardesktop-submenu-account-links__div">
                      {
                        dataLoggedIn && dataLoggedIn.customerLoggedIn
                        && (
                          <NavLink
                            to="/myaccount#orders"
                            className="navbardesktop-bottom-link__navlink"
                            onClick={() => WithEvent('NAVBAR', 'Clicked my orders link', 'NAVBAR_LINK', 1)}
                          >
                            My Orders
                          </NavLink>
                        )
                      }
                      {
                        dataLoggedIn && dataLoggedIn.customerLoggedIn
                          ? (
                            <ButtonBase
                              classes={{ root: classes.rootLogoutButton }}
                              onClick={handleLogOut}
                              disableRipple
                              disableTouchRipple
                            >
                              Logout
                            </ButtonBase>
                          )

                          : (
                            <Link
                              to="/login"
                              className="navbardesktop-login-link__navlink"
                            >
                              Log in or Sign up
                            </Link>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'none' }} className="navbardesktop-main-links__div">
                <div
                  className="navbardesktop-main-link__div"
                >
                  <NavLink
                    disabled
                    style={{ pointerEvents: 'none', color: '#616161' }}
                    to="/events"
                    className="navbardesktop-main-link__navlink navbardesktop-events__navlink"
                    activeClassName="navbardesktop-link__navlink--active"
                    onClick={() => WithEvent('NAVBAR', 'Clicked events link', 'NAVBAR_LINK', 1)}
                  >
                    Events
                  </NavLink>
                  {menuState.events && <div className="navbardesktop-dash__div" />}
                </div>
                <div
                  className="navbardesktop-main-link__div"
                  onMouseEnter={
                    () => (setMenu(prev => ({ ...prev, ...{ about: true } })))}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavLink
                    to="/about"
                    className="navbardesktop-main-link__navlink navbardesktop-about__navlink"
                    activeClassName="navbardesktop-link__navlink--active"
                    onClick={() => WithEvent('NAVBAR', 'Clicked about link', 'NAVBAR_LINK', 1)}
                  >
                    About
                  </NavLink>
                  {menuState.about && <div className="navbardesktop-dash__div" />}
                </div>
                <div
                  className="navbardesktop-main-link__div"
                  onMouseEnter={
                    () => (setMenu(prev => ({ ...prev, ...{ hours: true } })))}
                  onMouseLeave={handleMouseLeave}
                >
                  <NavLink
                    to="/hours-locations"
                    className="navbardesktop-main-link__navlink navbardesktop-hours__navlink"
                    activeClassName="navbardesktop-link__navlink--active"
                    onClick={() => WithEvent('NAVBAR', 'Clicked hours location link', 'NAVBAR_LINK', 1)}
                  >
                    Hours &amp; Locations
                  </NavLink>
                  {menuState.hours && <div className="navbardesktop-dash__div" />}
                </div>
              </div>
            </div>
            <div className="navbardesktop-tagline__div">
              <p className="navbardesktop-tagline__p">Independent Booksellers for 97 Years</p>
            </div>
          </div>
        </Toolbar>
        <NavMenu
          subMenuState={subMenuState}
          setSubMenu={setSubMenu}
        />
      </AppBar>
      {/* Added toolbar components to provide space underneath floating navbar.
      Hacky, perhaps. But it is effective */}
      <Toolbar />
      <Toolbar />
      <DrawerNav openDrawer={openDrawer} drawerToggle={drawerToggle} />
      <LoginSignup open={toggleLoginSignup[0]} handleClose={toggleLoginSignup[2]} />
    </div>
  );
};

export default NavbarDesktopQuery;
