import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Navbar from '../../components/navbar/Navbar';
import NavbarDesktop from '../../components/navbar/NavbarDesktop';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import './_viewNavbar.scss';

const ViewNavbar = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCheckoutIsOpen,
    refetchCustomerLoggedIn,
  } = props;

  const match1023 = useMediaQuery('(min-width: 1023px)');

  const {
    compSwitchState: openDrawer,
    handleCompToggle: drawerToggle,
  } = useOpenCloseComponent();

  const {
    compSwitchState: openSearch,
    handleCompOpen: expandSearchBar,
    handleCompClose: hideSearchBar,
  } = useOpenCloseComponent();

  return match1023
    ? (
      <NavbarDesktop
        openDrawer={openDrawer}
        drawerToggle={drawerToggle}
        expandSearchBar={expandSearchBar}
        openSearch={openSearch}
        hideSearchBar={hideSearchBar}
        cartList={cartList}
        customerLoggedIn={customerLoggedIn}
        checkoutIsOpen={checkoutIsOpen}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
    )
    : (
      <Navbar
        openDrawer={openDrawer}
        drawerToggle={drawerToggle}
        expandSearchBar={expandSearchBar}
        openSearch={openSearch}
        hideSearchBar={hideSearchBar}
        cartList={cartList}
        customerLoggedIn={customerLoggedIn}
        checkoutIsOpen={checkoutIsOpen}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
    );
};

ViewNavbar.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ViewNavbar;
