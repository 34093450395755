import React from 'react';
import PropTypes from 'prop-types';
// import gql from 'graphql-tag';
// import { useQuery, useEffect } from '@apollo/react-hooks';
// import Drawer from '@material-ui/core/Drawer';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import { makeStyles } from '@material-ui/styles';
import { Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loading from '../loading/Loading';
// import MyAccountInfo from './MyAccountInfo';
// import MyAccountEvents from './MyAccountEvents';
import MyAccountOrders from './MyAccountOrders';
// import MyAccountPayments from './MyAccountPayments';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
// import NavSidebar from '../navSidebar/NavSidebar';
import use4Cite from '../../hooks/use4Cite';
import useUserLoggedIn from '../../hooks/useUserLoggedIn';
import './_myAccount.scss';

// const useStyles = makeStyles({
//   rootDrawer: {
//     position: 'relative',
//   },
//   rootButtonBase: {
//     fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
//     fontSize: '18px',
//     padding: '5px',
//     color: '#000000',
//   },
// });

const MyAccount = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  // const { history } = props;
  // const userLoggedIn = useUserLoggedIn();
  // const {
  //   loggedState,
  //   // refetch,
  //   // data,
  // } = userLoggedIn;
  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    data: dataLoggedIn,
    // refetch: refetchLoggedIn,
  } = useUserLoggedIn();

  const match767 = useMediaQuery('(min-width: 767px)');

  use4Cite();

  // const classes = useStyles();
  // const [accountState, setAccount] = useState(0);

  // const handleAccount = e => setAccount(e.target.value);

  if (errorLoggedIn) return <Redirect to={{ pathname: '/error', state: { errorLoggedIn } }} />;

  if (loadingLoggedIn) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="myaccount-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  // console.log(dataLoggedIn);

  if (dataLoggedIn && !dataLoggedIn.customerLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />

      <div className="myaccount-container__div">
        <main className="myaccount-main__main">
          <div className="myaccount-main__div">
            {
              !match767
                ? <span />
                : (
                  <section className="myaccount-sidebar__section">
                    <div className="myaccount-drawer__div">
                      <div className="myaccount-drawer-content__div">
                        <div className="myaccount-drawer-button__div">
                          {/* <ButtonBase
                            disableTouchRipple
                            disableRipple
                            classes={{ root: classes.rootButtonBase }}
                            onClick={handleAccount}
                            value={0}
                          >
                            User Profile
                          </ButtonBase> */}
                          {/* <a href="#account" className="myaccount-link__navlink">Account Information</a> */}
                          {/* <a href="#info" className="myaccount-link__a">Account Information</a> */}
                        </div>
                        <div className="myaccount-drawer-button__div">
                          {/* <ButtonBase
                            disableTouchRipple
                            disableRipple
                            classes={{ root: classes.rootButtonBase }}
                            onClick={handleAccount}
                            value={0}
                          >
                            User Profile
                          </ButtonBase> */}
                          {/* <a href="#payment" className="myaccount-link__navlink">Payment Information</a> */}
                        </div>
                        <div className="myaccount-drawer-button__div">
                          {/* <ButtonBase
                            disableTouchRipple
                            disableRipple
                            onClick={handleAccount}
                            classes={{ root: classes.rootButtonBase }}
                            value={1}
                          >
                            My Orders
                          </ButtonBase> */}
                          <a href="#orders" className="myaccount-link__navlink">My Orders</a>
                        </div>
                        <div className="myaccount-drawer-button__div">
                          {/* <ButtonBase
                            disableTouchRipple
                            disableRipple
                            onClick={handleAccount}
                            classes={{ root: classes.rootButtonBase }}
                            value={2}
                          >
                            My Events
                          </ButtonBase> */}
                          {/* <a href="#events" className="myaccount-link__a">My Events</a> */}
                          {/* <a href="#events" className="myaccount-link__navlink">My Events</a> */}
                        </div>
                        {/* <NavSidebar content={[{ name: 'Account Information', id: 'info' }, { name: 'Payment Information', id: 'payment' }, { name: 'My Orders', id: 'orders' }, { name: 'My Events', id: 'events' }]} /> */}
                      </div>
                    </div>
                  </section>
                )
            }
            <section id="account" className="myaccount-content__section">
              <div className="myaccount-content__div">
                {/* <MyAccountInfo />
                <div id="payment" className="myaccount-border__div" />
                <MyAccountPayments />
                <div id="orders" className="myaccount-border__div" /> */}
                <MyAccountOrders id="orders" />
                {/* <div id="events" className="myaccount-border__div" />
                <MyAccountEvents /> */}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

MyAccount.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default MyAccount;
