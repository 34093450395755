import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_buyoutPrice.scss';

const BuyoutPrice = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <div className="buyoutprice-container__div">
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="buyoutprice-hero__div">
        <img className="buyoutprice-hero__img" alt="Library Services" src={urlGenerator('/S/static/buyout_strandexterior3.jpg')} />
      </div>
      <PageHeader header="Buyout &amp; Filming Inquiries" />
      <section className="buyoutprice-main__section">
        <main className="buyoutprice-main__main">
          <div className="buyoutprice-content__div">
            <div className="buyoutprice-intro__div">
              <p className="buyoutprice-intro__p">
                Thank you for thinking of filming at The Strand!
                The below are estimates for renting out the various areas of The Strand.
                Please contact us for a direct quote; prices will fluctuate depending on timing,
                length, and scale of the shoot.
              </p>
            </div>
            <div className="buyoutprice-categories__div">
              <div className="buyoutprice-category__div">
                <h2 className="buyoutprice-header__h2">
                  Full Store Buyout
                </h2>
                <p className="buyoutprice-text__p">
                  Starting at $75,000 (weekday) - $100,000(weekend)
                </p>
                <p className="buyoutprice-text__p buyoutprice-text__p--strong">
                  *Above pricing does not include dates between November 1st through January 14th
                </p>
              </div>
              <div className="buyoutprice-category__div">
                <h2 className="buyoutprice-header__h2">
                  Store Exterior with Branding
                </h2>
                <p className="buyoutprice-text__p">
                  $3000
                </p>
              </div>
              <div className="buyoutprice-category__div">
                <h2 className="buyoutprice-header__h2">
                  Main Floor Buyout
                </h2>
                <p className="buyoutprice-text__p">
                  Starting at $30,000 to $100,000
                </p>
              </div>
              <div className="buyoutprice-category__div">
                <h2 className="buyoutprice-header__h2">
                  Basement Buyout
                </h2>
                <p className="buyoutprice-text__p">
                  Starting at $25,000
                </p>
              </div>
              <div className="buyoutprice-category__div">
                <h2 className="buyoutprice-header__h2">
                  Rare Book Room Buyout
                </h2>
                <p className="buyoutprice-text__p">
                  Starting at $10,000
                </p>
              </div>
            </div>
            <section className="buyoutprice-contact__section">
              <div className="buyoutprice-contact__div">
                <h3 className="buyoutprice-header__h3">
                  Please contact&nbsp;
                  <a
                    className="buyoutprice-link__a"
                    href="mailto:rentals@strandbooks.com"
                  >
                    rentals@strandbooks.com
                  </a>
                  &nbsp;for further information and direct quotes. When reaching out, please provide
                  as much information as possible including a date (or range of dates), time, size
                  of your crew, duration, and other relevant details.
                </h3>
              </div>
            </section>
          </div>
        </main>
      </section>
      <ViewFooter />
    </div>
  );
};

BuyoutPrice.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BuyoutPrice;
