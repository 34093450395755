import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const USER_LOG_OUT = gql`
mutation{
   customerLogout
}
`;

const useUserLogOut = () => {
  const [customerLogout, { error, loading, data }] = useMutation(USER_LOG_OUT);

  return {
    customerLogout,
    data,
    error,
    loading,
  };
};

export default useUserLogOut;
