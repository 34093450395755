import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import WithEvent from '../withEvent/WithEvent';
import stringLimiter from '../../helpers/stringLimiter';
import './_strandcastfeat.scss';

const useStyles = makeStyles({
  rootCard: {
    backgroundColor: 'rgba(255, 255, 255, .8)',
  },
});

const StrandCastFeat = (props) => {
  const { featEp } = props;
  const [hoverState, setHover] = useState(false);
  const classes = useStyles();
  const match1023 = useMediaQuery('(min-width: 1023px)');

  return (
    <div
      className="strandcastfeat-container__div"
      onMouseLeave={() => setHover(false)}
      onMouseEnter={() => setHover(true)}
    >
      <div className={hoverState ? 'strandcastfeat-image__div strandcastfeat-image__div--hover' : 'strandcastfeat-image__div'}>
        <img className="strandcastfeat-image__img" src={featEp.thumbnailURL} alt={featEp.epTitle} />
      </div>
      <div className="strandcastfeat-card__div">
        <Card classes={{ root: classes.rootCard }}>
          <CardContent>
            <h3 className="strandcastfeat-header__h3">{`Episode ${featEp.id.slice(2)}`}</h3>
            <div className="strandcastfeat-accent__div" />
            <h4 className="strandcastfeat-header__h4">{featEp.epTitle}</h4>
            <h5 className="strandcastfeat-header__h5">{featEp.epSubTitle}</h5>
            <div className="strandcastfeat-details__div">
              {/* <h5 className="strandcastfeat-header__h5">Show Details</h5> */}
              <div className="strandcastfeat-details__p" dangerouslySetInnerHTML={(() => ({ __html: match1023 ? featEp.description : stringLimiter(featEp.description, 200) }))()} />
              {/* <p className="strandcastfeat-details__p">{view > 1023 ? featEp.description : stringLimiter(featEp.description, 200)}</p> */}
              <div className="strandcastfeat-link__div">
                <Link
                  className="strandcastfeat-link__link"
                  to={`/more/strandcast/${featEp.id.slice(2)}`}
                  onClick={() => WithEvent('STRANDCAST', `Clicked ${featEp.epTitle} ep`, 'STRANDCAST_EP_LINK', 1)}
                >
                  Go to episode
                </Link>
              </div>
              <p className="strandcastfeat-details__p">{featEp.date}</p>
            </div>
            <div className="strandcastfeat-iframe__div">
              <iframe style={{ border: 'none' }} title={featEp.epTitle} src={featEp.iFrame} height="45" width="100%" scrolling="no" allow="fullscreen" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

StrandCastFeat.propTypes = {
  featEp: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StrandCastFeat;
