import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Fab from '@material-ui/core/Fab';
import ExpandLess from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles({
  rootFab: {
    backgroundColor: '#af272f',
    color: '#ffffff',
    position: 'fixed',
    right: '25px',
    bottom: '100px',
    zIndex: 10000,
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootIcon: {
    width: '32px',
    height: '32px',
  },
});

const CompFab = () => {
  const classes = useStyles();

  const handleScrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="compfab-container__div">
      <Fab
        onClick={handleScrollTop}
        disableRipple
        disableTouchRipple
        classes={{ root: classes.rootFab }}
        aria-label="back to top"
      >
        <ExpandLess classes={{ root: classes.rootIcon }} />
      </Fab>
    </div>
  );
};

export default CompFab;
