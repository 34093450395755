import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import { Redirect, Link } from 'react-router-dom';
import queryString from 'query-string';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import LazyLoad from 'react-lazyload';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import useProductListFilter from '../../hooks/useProductListFilter';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import ProductPageOps from '../productListPageOps/ProductListPageOps';
// import ProductListLocationDrawer from '../productListDrawer/ProductListLocationDrawer';
import ProductListLocationTempDrawer from '../productListDrawer/ProductListLocationTempDrawer';
import convertSlashRegex from '../../helpers/convertSlashRegex';
import locationsDictionary from '../../helpers/locationsDictionary';
import convertURL from '../../helpers/convertURL';
import formatPrice from '../../helpers/formatPrice';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import './_productLocationList.scss';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';

const useStyles = makeStyles({
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const GET_PRODUCT_LOCATION = gql`
query getProdLocation($order: OrderCommonIn, $locationCode: ID!, $filter: FilterCommonIn){
  productByLocationCode(order: $order, locationCode: $locationCode, filter: $filter, limit: 400){
      gtin13
      id
      imageUrl
      bookBinding
      inventory{
        onHand
        salePrice
        salePriceStv
        section
        signed
        sku
        store
      }
      inventoryOverview{
        conditionList
        locationList
        onHand
        sectionList
        signedList
      }
      isbn10
      keywords
      originator
      outOfPrint
      publisher
      retailStv
      retailPrice
      title
      webDescription
  }
}
`;

const ProductLocationList = (props) => {
  const {
    match,
    location,
    history,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const classes = useStyles();
  const scroll = useScrollY();
  const prodFilterPayload = useProductListFilter();
  const { compSwitchState, handleCompOpen, handleCompClose } = useOpenCloseComponent();
  const sortTypes = ['1', '2', '3', '4'];
  const condTypes = ['NEW', 'USED_GOOD', 'USED_VERYGOOD', 'RARE_FAIR', 'RARE_FINE', 'RARE_GOOD', 'RARE_NEARFINE', 'RARE_VERYFINE', 'RARE_VERYGOOD'];
  const bindTypes = ['ADVANCE_READING_COPY', 'AUDIO_CD', 'AUDIO_TAPE', 'BOX_SET', 'CALENDAR', 'DVD', 'FLEXI', 'HARDCOVER', 'LIBRARY_BINDING', 'MASS_MARKET', 'MERCH', 'NEW_TRADE', 'NOVELTY', 'OVERSIZED_HARDCOVER', 'OVERSIZED_PAPER_BACK', 'PAPERBACK', 'PRINTS', 'PROOF', 'SCHOOL_LIBRARY_BINDING', 'TURTLEBACK_BINDING', 'VIDEO', 'VINYL', 'WRAPPERS'];

  // useStates
  const [signedState, setSigned] = useState(queryString.parse(location.search).signed === 'true');
  const [sortState, setSort] = useState(
    sortTypes.indexOf(queryString.parse(location.search).sort) > -1
      ? parseInt(queryString.parse(location.search).sort, 10) : 1,
  );
  const [condState, setCond] = useState(
    condTypes.indexOf(queryString.parse(location.search).cond) > -1
      ? queryString.parse(location.search).cond : 'ANY',
  );
  const [bindState, setBind] = useState(
    bindTypes.indexOf(queryString.parse(location.search).bind) > -1
      ? queryString.parse(location.search).bind : 'ANY',
  );

  const [stockState, setStock] = useState(queryString.parse(location.search).stock || 'ANY');

  const [priceRangeState, setPriceRange] = useState({
    maxStv: queryString.parse(location.search).maxprice ? (parseInt(queryString.parse(location.search).maxprice, 10) / 100).toString() : '10000',
    minStv: queryString.parse(location.search).minprice ? (parseInt(queryString.parse(location.search).minprice, 10) / 100).toString() : '1',
  });

  const [copyrightState, setCopyright] = useState({
    minYear: 0,
    maxYear: 0,
  });

  // const [publishRangeState, setPublishRange] = useState([1900, new Date(Date.now()).getFullYear()]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [match]);

  use4Cite();

  const orderByFn = (sort) => {
    if (!sort || sort === '1') {
      return 'TITLE';
    }

    if (sort === '4') {
      return 'ARRIVE_DATE';
    }

    return 'PRICE';
  };

  const {
    loading,
    error,
    data,
    refetch,
    networkStatus,
  } = useQuery(GET_PRODUCT_LOCATION, {
    variables: {
      locationCode: convertSlashRegex(match.params.location).toUpperCase(),
      order: {
        dir: queryString.parse(location.search).sort === '3' ? 'DSC' : 'ASC',
        orderBy: orderByFn(queryString.parse(location.search).sort),
      },
      filter: {
        onHand: 1,
        signed: queryString.parse(location.search).signed === 'true',
        condition: queryString.parse(location.search).cond ? queryString.parse(location.search).cond : 'ANY',
        binding: queryString.parse(location.search).bind ? queryString.parse(location.search).bind : 'ANY',
        salePriceStv: {
          maxStv: queryString.parse(location.search).maxprice ? parseFloat(queryString.parse(location.search).maxprice) : 1000000,
          minStv: queryString.parse(location.search).minprice ? parseFloat(queryString.parse(location.search).minprice) : 100,
        },
        copyrightYear: {
          minYear: queryString.parse(location.search).copymin ? parseFloat(queryString.parse(location.search).copymin) : 0,
          maxYear: queryString.parse(location.search).copymax ? parseFloat(queryString.parse(location.search).copymax) : 0,
        },
        stockFilter: queryString.parse(location.search).stock ? queryString.parse(location.search).stock : 'ANY',
      },
    },
    // fetchPolicy: 'cache-and-network',
  });

  const handleReset = () => {
    WithEvent('FILTER_OPTION', 'Clicked Clear Filters', 'FILTER_LOCATION_CLEAR', 1);
    refetch({
      // param: {
      //   // publishBetween: publishRangeState,
      // },
      locationCode: convertSlashRegex(match.params.location).toUpperCase(),
      order: {
        dir: 'ASC',
        orderBy: 'TITLE',
      },
      filter: {
        signed: signedState,
        condition: condState,
        binding: bindState,
        salePriceStv: {
          maxStv: (parseInt(priceRangeState.maxStv, 10) * 100) || 1000000,
          minStv: (parseInt(priceRangeState.minStv, 10) * 100) || 100,
        },
        stockFilter: stockState,
        copyrightYear: {
          maxYear: parseInt(copyrightState.maxYear, 10) || 0,
          minYear: parseInt(copyrightState.minYear, 10) || 0,
        },
      },
    })
      .then(() => setCond('ANY'))
      .then(() => setBind('ANY'))
      .then(() => setStock('ANY'))
      .then(() => {
        setSort(1);
      })
      .then(() => setPriceRange({ minStv: 1, maxStv: 10000 }))
      .then(() => setCopyright({ minYear: 0, maxYear: 0 }))
      .then(() => setSigned(false))
      .then(() => history.push(`/productlocation/${match.params.location}?signed=false&sort=1&cond=ANY&stock=ANY&bind=ANY&minprice=100&maxprice=1000000&copymin=0&copymax=0`))
      .then(handleCompClose)
      .catch(err => console.error(err));
  };

  const handleSort = (e) => {
    if (e.target.value === 1) {
      WithEvent('FILTER_OPTION', 'Clicked Sort by Title', 'FILTER_LOCATION_SORT', 1);
      refetch({
        order: {
          dir: 'ASC',
          orderBy: 'TITLE',
        },
        // locationCode: convertSlashRegex(match.params.location).toUpperCase(),
        // filter: {
        //   onHand: 1,
        //   signed: signedState,
        //   binding: bindState,
        //   salePriceStv: {
        //     maxStv: (parseInt(priceRangeState.maxStv, 10) * 100) || 1000000,
        //     minStv: (parseInt(priceRangeState.minStv, 10) * 100) || 100,
        //   },
        //   stockFilter: stockState,
        // },
      })
        .then(() => history.push(`/productlocation/${match.params.location}?signed=${signedState}&sort=1&stock=${stockState}&cond=${condState}&bind=${bindState}&minprice=${parseInt(priceRangeState.minStv, 10) ? (parseInt(priceRangeState.minStv, 10) * 100) : 100}&maxprice=${parseInt(priceRangeState.maxStv, 10) ? (parseInt(priceRangeState.maxStv, 10) * 100) : 1000000}&copymin=${parseInt(copyrightState.minYear, 10) ? parseInt(copyrightState.minYear, 10) : 0}&copymax=${parseInt(copyrightState.maxYear, 10) ? parseInt(copyrightState.maxYear, 10) : 0}`))
        .then(() => setSort(e.target.value));
    }

    if (e.target.value === 2) {
      WithEvent('FILTER_OPTION', 'Clicked Sort by Price Low - High', 'FILTER_LOCATION_SORT', 1);
      refetch({
        order: {
          dir: 'ASC',
          orderBy: 'PRICE',
        },
        // locationCode: convertSlashRegex(match.params.location).toUpperCase(),
        // filter: {
        //   onHand: 1,
        //   signed: signedState,
        //   binding: bindState,
        //   salePriceStv: {
        //     maxStv: (parseInt(priceRangeState.maxStv, 10) * 100) || 1000000,
        //     minStv: (parseInt(priceRangeState.minStv, 10) * 100) || 100,
        //   },
        //   stockFilter: stockState,
        // },
      })
        .then(() => history.push(`/productlocation/${match.params.location}?signed=${signedState}&sort=2&cond=${condState}&stock=${stockState}&bind=${bindState}&minprice=${parseInt(priceRangeState.minStv, 10) ? (parseInt(priceRangeState.minStv, 10) * 100) : 100}&maxprice=${parseInt(priceRangeState.maxStv, 10) ? (parseInt(priceRangeState.maxStv, 10) * 100) : 1000000}&copymin=${parseInt(copyrightState.minYear, 10) ? parseInt(copyrightState.minYear, 10) : 0}&copymax=${parseInt(copyrightState.maxYear, 10) ? parseInt(copyrightState.maxYear, 10) : 0}`))
        .then(() => setSort(e.target.value));
    }

    if (e.target.value === 3) {
      WithEvent('FILTER_OPTION', 'Clicked Sort by Price High - Low', 'FILTER_LOCATION_SORT', 1);
      refetch({
        order: {
          dir: 'DSC',
          orderBy: 'PRICE',
        },
        // param: {
        //   publishBetween: publishRangeState,
        // },
        // locationCode: convertSlashRegex(match.params.location).toUpperCase(),
        // filter: {
        //   onHand: 1,
        //   signed: signedState,
        //   binding: bindState,
        //   salePriceStv: {
        //     maxStv: (parseInt(priceRangeState.maxStv, 10) * 100) || 1000000,
        //     minStv: (parseInt(priceRangeState.minStv, 10) * 100) || 100,
        //   },
        //   stockFilter: stockState,
        // },
      })
        .then(() => history.push(`/productlocation/${match.params.location}?signed=${signedState}&sort=3&cond=${condState}&stock=${stockState}&bind=${bindState}&minprice=${parseInt(priceRangeState.minStv, 10) ? (parseInt(priceRangeState.minStv, 10) * 100) : 100}&maxprice=${parseInt(priceRangeState.maxStv, 10) ? (parseInt(priceRangeState.maxStv, 10) * 100) : 1000000}&copymin=${parseInt(copyrightState.minYear, 10) ? parseInt(copyrightState.minYear, 10) : 0}&copymax=${parseInt(copyrightState.maxYear, 10) ? parseInt(copyrightState.maxYear, 10) : 0}`))
        .then(() => setSort(e.target.value));
    }

    if (e.target.value === 4) {
      WithEvent('FILTER_OPTION', 'Clicked Sort by Arrival Date', 'FILTER_LOCATION_SORT', 1);
      refetch({
        order: {
          dir: 'ASC',
          orderBy: 'ARRIVE_DATE',
        },
      })
        .then(() => history.push(`/productlocation/${match.params.location}?signed=${signedState}&sort=4&cond=${condState}&stock=${stockState}&bind=${bindState}&minprice=${parseInt(priceRangeState.minStv, 10) ? (parseInt(priceRangeState.minStv, 10) * 100) : 100}&maxprice=${parseInt(priceRangeState.maxStv, 10) ? (parseInt(priceRangeState.maxStv, 10) * 100) : 1000000}&copymin=${parseInt(copyrightState.minYear, 10) ? parseInt(copyrightState.minYear, 10) : 0}&copymax=${parseInt(copyrightState.maxYear, 10) ? parseInt(copyrightState.maxYear, 10) : 0}`))
        .then(() => setSort(e.target.value));
    }
  };

  const handleSigned = () => {
    WithEvent('FILTER_OPTION', 'Clicked Signed Filter Option', 'FILTER_LOCATION_SIGNED', 1);
    setSigned(!signedState);
  };

  // condition filter function

  const handleCondition = (cond) => {
    WithEvent('FILTER_OPTION', `Clicked option ${cond}`, 'FILTER_LOCATION_CONDITION', 1);
    setCond(cond);
  };

  // binding filter function

  const handleBind = (bind) => {
    WithEvent('FILTER_OPTION', `Clicked option ${bind}`, 'FILTER_LOCATION_BINDING', 1);
    setBind(bind);
  };

  // stock filter function

  const handleStock = (stock) => {
    WithEvent('FILTER_OPTION', `Clicked option ${stock}`, 'FILTER_LOCATION_STOCK', 1);
    setStock(stock);
  };

  // price filter function

  const handlePriceRange = (e, priceVal) => {
    // console.log(e, priceRangeState);
    if (priceVal === 'maxStv') {
      setPriceRange({
        minStv: priceRangeState.minStv,
        maxStv: e.target.value,
      });
    } else {
      setPriceRange({
        maxStv: priceRangeState.maxStv,
        minStv: e.target.value,
      });
    }
  };

  const handleCopyright = (e, yearVal) => {
    // console.log(e, priceRangeState);
    if (yearVal === 'maxYear') {
      setCopyright({
        minYear: copyrightState.minYear,
        maxYear: e.target.value,
      });
    } else {
      setCopyright({
        maxYear: copyrightState.maxYear,
        minYear: e.target.value,
      });
    }
  };

  // const priceRangeButton = () => {
  //   refetch({
  //     locationCode: convertSlashRegex(match.params.section).toUpperCase(),
  //     order: {
  //       dir: sortState === 3 ? 'DSC' : 'ASC',
  //       orderBy: sortState === 1 ? 'TITLE' : 'PRICE',
  //     },
  //     filter: {
  //       onHand: 1,
  //       signed: signedState,
  //       condition: condState,
  //       binding: bindState,
  //       salePriceStv: {
  //         maxStv: (priceRangeState[1] * 100),
  //         minStv: (priceRangeState[0] * 100),
  //       },
  //     },
  //   });
  // };

  // publish between function

  // const handlePublish = (e, newVal) => {
  //   setPublishRange(newVal);
  //   // console.log(newVal);
  // };

  const handleApply = () => {
    // console.log('priceRangeState', priceRangeState);
    WithEvent('FILTER_OPTION', 'Clicked Apply Filters', 'FILTER_LOCATION_APPLY', 1);
    refetch({
      // param: {
      //   // publishBetween: publishRangeState,
      // },
      locationCode: convertSlashRegex(match.params.location).toUpperCase(),
      order: {
        dir: 'ASC',
        orderBy: stockState === 'JUST_ARRIVED' ? 'ARRIVE_DATE' : 'TITLE',
      },
      filter: {
        signed: signedState,
        condition: condState,
        binding: bindState,
        salePriceStv: {
          maxStv: (parseInt(priceRangeState.maxStv, 10) * 100) || 1000000,
          minStv: (parseInt(priceRangeState.minStv, 10) * 100) || 100,
        },
        stockFilter: stockState,
        copyrightYear: {
          maxYear: parseInt(copyrightState.maxYear, 10) || 0,
          minYear: parseInt(copyrightState.minYear, 10) || 0,
        },
      },
    })
      .then(() => history.push(`/productlocation/${match.params.location}?signed=${signedState}&sort=${stockState === 'JUST_ARRIVED' ? 4 : 1}&cond=${condState}&stock=${stockState}&bind=${bindState}&minprice=${parseInt(priceRangeState.minStv, 10) ? (parseInt(priceRangeState.minStv, 10) * 100) : 100}&maxprice=${parseInt(priceRangeState.maxStv, 10) ? (parseInt(priceRangeState.maxStv, 10) * 100) : 1000000}&copymin=${parseInt(copyrightState.minYear, 10) ? parseInt(copyrightState.minYear, 10) : 0}&copymax=${parseInt(copyrightState.maxYear, 10) ? parseInt(copyrightState.maxYear, 10) : 0}`))
      .then(() => {
        if (stockState === 'JUST_ARRIVED') {
          setSort(4);
        } else setSort(1);
      })
      .then(handleCompClose)
      .catch(err => console.error(err));
  };

  const publishButton = () => {
    refetch({
      // param: {
      //   publishBetween: publishRangeState,
      // },
      // section: convertSlashRegex(match.params.section).toUpperCase(),
      order: {
        dir: sortState === 3 ? 'DSC' : 'ASC',
        orderBy: sortState === 1 ? 'TITLE' : 'PRICE',
      },
      filter: {
        onHand: 1,
        signed: signedState,
        condition: condState,
        binding: bindState,
        salePriceStv: {
          maxStv: (priceRangeState[1] * 100),
          minStv: (priceRangeState[0] * 100),
        },
      },
    });
  };

  if (networkStatus === 4) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (error) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  if (loading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </React.Fragment>
    );
  }

  // console.log('locationdata', data);

  if (data && data.productByLocationCode && !data.productByLocationCode.length) {
    // if data comes out as an empty array
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productlocationlist-container__div">
          <main className="productlocationlist-content__main">
            {/* <section className="productlocationlist-filter__section">
              {
                view > 767
                  && (
                    <ProductListLocationDrawer
                      productList={data.productByLocationCode}
                      checkSigned={prodFilterPayload.prodFilterState.checkSigned}
                      handleProdFilter={prodFilterPayload.handleProdFilter}
                      bookBindings={prodFilterPayload.prodFilterState.bookBindings}
                      bookCondition={data.productByLocationCode}
                      bookSection={prodFilterPayload.prodFilterState.bookSection}
                      bookPrice={prodFilterPayload.prodFilterState.bookPrice}
                      productListName={convertSlashRegex(match.params.location).toUpperCase()}
                      handleSigned={handleSigned}
                      signedState={signedState}
                      sectionName={match.params.location}
                      handleCondition={handleCondition}
                      condState={condState}
                      bindState={bindState}
                      handleBind={handleBind}
                      section={match.params.location}
                      handlePriceRange={handlePriceRange}
                      priceRangeState={priceRangeState}
                      handleReset={handleReset}
                      handlePublish={handlePublish}
                      publishButton={publishButton}
                      publishRangeState={publishRangeState}
                      refetch={refetch}
                      match={match}
                      sortState={sortState}
                      history={history}
                      setSigned={setSigned}
                      setCond={setCond}
                      setBind={setBind}
                      handleApply={handleApply}
                    />
                  )
              }
            </section> */}
            <div className="productlocationlist-no-items__div">
              <PageHeader className="productlocationlist-header__pageheader" header={locationsDictionary(match.params.location.toUpperCase())} />
              <section className="productlocationlist-filter-btn__section">
                <IconButton
                  classes={{ root: classes.rootIconButton }}
                  disableTouchRipple
                  disableRipple
                  onClick={handleCompOpen}
                >
                  <FilterListIcon />
                  &nbsp;
                  <span className="productlocationlist-filter__span">Filter Options</span>
                </IconButton>
                <ProductListLocationTempDrawer
                  productList={data.productByLocationCode}
                  checkSigned={prodFilterPayload.prodFilterState.checkSigned}
                  handleProdFilter={prodFilterPayload.handleProdFilter}
                  bookBindings={prodFilterPayload.prodFilterState.bookBindings}
                  bookCondition={data.productByLocationCode.product}
                  bookSection={prodFilterPayload.prodFilterState.bookSection}
                  bookPrice={prodFilterPayload.prodFilterState.bookPrice}
                  // search={search}
                  // history={history}
                  // paginationState={paginationState}
                  // pageOptionsState={pageOptionsState}
                  productListName={convertSlashRegex(match.params.location).toUpperCase()}
                  handleSigned={handleSigned}
                  signedState={signedState}
                  sectionName={match.params.location}
                  handleCondition={handleCondition}
                  condState={condState}
                  bindState={bindState}
                  handleBind={handleBind}
                  section={match.params.location}
                  handlePriceRange={handlePriceRange}
                  priceRangeState={priceRangeState}
                  copyrightState={copyrightState}
                  handleCopyright={handleCopyright}
                  handleReset={handleReset}
                  // handlePublish={handlePublish}
                  publishButton={publishButton}
                  // publishRangeState={publishRangeState}
                  open={compSwitchState}
                  onClose={handleCompClose}
                  refetch={refetch}
                  sortState={sortState}
                  history={history}
                  setSigned={setSigned}
                  setCond={setCond}
                  setBind={setBind}
                  stockState={stockState}
                  handleStock={handleStock}
                  handleApply={handleApply}
                />
              </section>
              <p className="productlocationlist-no-items__p">
                We&#39;re sorry--we don&#39;t have the product you are looking for
              </p>
            </div>
          </main>
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="productlocationlist-container__div">
        <main className="productlocationlist-content__main">
          {scroll > 1000 && <CompFab />}
          <section className="productlocationlist-filter__section">
            {
              // view > 767
              //   ? (
              //     <ProductListLocationDrawer
              //       productList={data.productByLocationCode}
              //       checkSigned={prodFilterPayload.prodFilterState.checkSigned}
              //       handleProdFilter={prodFilterPayload.handleProdFilter}
              //       bookBindings={prodFilterPayload.prodFilterState.bookBindings}
              //       bookCondition={data.productByLocationCode.product}
              //       bookSection={prodFilterPayload.prodFilterState.bookSection}
              //       bookPrice={prodFilterPayload.prodFilterState.bookPrice}
              //       // search={search}
              //       // history={history}
              //       // paginationState={paginationState}
              //       // pageOptionsState={pageOptionsState}
              //       productListName={convertSlashRegex(match.params.location).toUpperCase()}
              //       handleSigned={handleSigned}
              //       signedState={signedState}
              //       sectionName={match.params.location}
              //       handleCondition={handleCondition}
              //       condState={condState}
              //       bindState={bindState}
              //       handleBind={handleBind}
              //       handlePriceRange={handlePriceRange}
              //       priceRangeState={priceRangeState}
              //       priceRangeButton={priceRangeButton}
              //       handleReset={handleReset}
              //       handlePublish={handlePublish}
              //       publishButton={publishButton}
              //       publishRangeState={publishRangeState}
              //       view={view}
              //       refetch={refetch}
              //       match={match}
              //       sortState={sortState}
              //       history={history}
              //       setSigned={setSigned}
              //       setCond={setCond}
              //       setBind={setBind}
              //       handleApply={handleApply}
              //     />
              //   )
              //   : (
              //     <ProductListLocationTempDrawer
              //       productList={data.productLocationCode}
              //       checkSigned={prodFilterPayload.prodFilterState.checkSigned}
              //       handleProdFilter={prodFilterPayload.handleProdFilter}
              //       bookBindings={prodFilterPayload.prodFilterState.bookBindings}
              //       bookCondition={data.productByLocationCode}
              //       bookSection={prodFilterPayload.prodFilterState.bookSection}
              //       bookPrice={prodFilterPayload.prodFilterState.bookPrice}
              //       // search={search}
              //       // history={history}
              //       // paginationState={paginationState}
              //       // pageOptionsState={pageOptionsState}
              //       productListName={convertSlashRegex(match.params.location).toUpperCase()}
              //       handleSigned={handleSigned}
              //       signedState={signedState}
              //       sectionName={match.params.location}
              //       handleCondition={handleCondition}
              //       condState={condState}
              //       bindState={bindState}
              //       handleBind={handleBind}
              //       section={match.params.location}
              //       handlePriceRange={handlePriceRange}
              //       priceRangeState={priceRangeState}
              //       handleReset={handleReset}
              //       handlePublish={handlePublish}
              //       publishButton={publishButton}
              //       publishRangeState={publishRangeState}
              //       open={compSwitchState}
              //       onClose={handleCompClose}
              //       refetch={refetch}
              //       sortState={sortState}
              //       history={history}
              //       setSigned={setSigned}
              //       setCond={setCond}
              //       setBind={setBind}
              //       handleApply={handleApply}
              //     />
              //   )
            }
            <ProductListLocationTempDrawer
              productList={data.productByLocationCode[0].product}
              checkSigned={prodFilterPayload.prodFilterState.checkSigned}
              handleProdFilter={prodFilterPayload.handleProdFilter}
              bookBindings={prodFilterPayload.prodFilterState.bookBindings}
              bookCondition={data.productByLocationCode[0].product}
              bookSection={prodFilterPayload.prodFilterState.bookSection}
              bookPrice={prodFilterPayload.prodFilterState.bookPrice}
              // search={search}
              // history={history}
              // paginationState={paginationState}
              // pageOptionsState={pageOptionsState}
              productListName={convertSlashRegex(match.params.location).toUpperCase()}
              handleSigned={handleSigned}
              signedState={signedState}
              sectionName={match.params.location}
              handleCondition={handleCondition}
              condState={condState}
              bindState={bindState}
              handleBind={handleBind}
              section={match.params.location}
              handlePriceRange={handlePriceRange}
              priceRangeState={priceRangeState}
              handleReset={handleReset}
              // handlePublish={handlePublish}
              // publishButton={publishButton}
              // publishRangeState={publishRangeState}
              copyrightState={copyrightState}
              handleCopyright={handleCopyright}
              open={compSwitchState}
              onClose={handleCompClose}
              refetch={refetch}
              sortState={sortState}
              history={history}
              setSigned={setSigned}
              setCond={setCond}
              setBind={setBind}
              handleApply={handleApply}
              handleStock={handleStock}
              stockState={stockState}
            />
          </section>
          <div className="productlocationlist-content__div">
            <div className="productlocationlist-header__pageheader">
              <PageHeader header={locationsDictionary(match.params.location.toUpperCase())} />
            </div>
            {
              // view < 767
              //   && (
              //     <section className="productlocationlist-filter-btn__section">
              //       <IconButton
              //         classes={{ root: classes.rootIconButton }}
              //         disableTouchRipple
              //         disableRipple
              //         onClick={handleCompOpen}
              //       >
              //         <FilterListIcon />
              //         &nbsp;
              //         <span className="productlocationlist-filter__span">Filter Options</span>
              //       </IconButton>
              //     </section>
              //   )
            }
            <section className="productlocationlist-filter-btn__section">
              <IconButton
                classes={{ root: classes.rootIconButton }}
                disableTouchRipple
                disableRipple
                onClick={handleCompOpen}
              >
                <FilterListIcon />
                &nbsp;
                <span className="productlocationlist-filter__span">Filter Options</span>
              </IconButton>
            </section>
            <section className="productlocationlist-order__section">
              <ProductPageOps
                handleSort={handleSort}
                sort={sortState}
                location={location}
              />
            </section>
            <section className="productlocationlist-items__section">
              <div className="productlocationlist-items__div">
                {
                  data
                    && data.productByLocationCode
                    && data.productByLocationCode.length > 0
                    && data.productByLocationCode.map((prod) => {
                      const {
                        gtin13,
                        imageUrl,
                        inventory,
                        originator,
                        title,
                        id,
                      } = prod;

                      return (
                        <Link className="productlocationlist-item-link__link" key={id} to={{ pathname: `/product/${gtin13}`, search: `?title=${convertURL(title)}`, state: { title: convertURL(title), gtin13 } }}>
                          <div className="productlocationlist-item__div">
                            <LazyLoad height={130} offset={100}>
                              <div className="productlocationlist-item-image__div">
                                <img src={imageUrl} alt={title} className="productsectionlist-item-image__img" />
                              </div>
                            </LazyLoad>
                            <div className="productlocationlist-details__div">
                              <h4 className="productlocationlist-header__h4">{title}</h4>
                              <p className="productlocationlist-details__p">{originator}</p>
                              {
                                inventory
                                  && inventory.length > 0
                                  && <p className="productlistlocation-details__p">{`$${formatPrice(inventory[0].salePrice)}`}</p>
                              }
                              {
                                inventory
                                  && inventory.length === 0
                                  && <p className="productlistlocation-details__p productlistlocation-details-out-of-stock__p">Out of stock</p>
                              }
                            </div>
                          </div>
                        </Link>
                      );
                    })
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ProductLocationList.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ProductLocationList;
