const stringLimiter = (str, maxChar) => {
  if (str) {
    if (Number.isInteger(maxChar)) {
      return str && str.length < maxChar ? str : `${str.slice(0, maxChar)}...`;
    }
    if (str && str.length > 25) {
      return `${str.slice(0, 24)}...`;
    }
  }
  return str;
};

export default stringLimiter;
