import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import './_productListPageOps.scss';

const styles = {
  rootMenuItem: {
    width: '150px',
  },
  selectSelect: {
    minWidth: '100px',
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    textAlign: 'center',
  },
  underlineInput: {
    '&:after': {
      borderBottom: '2px solid #af272f',
    },
  },
  iconSelect: {
    color: '#af272f',
  },
};

const ProductListPageOps = (props) => {
  const {
    // handlePageLimit,
    // pageLimit,
    classes,
    sort,
    handleSort,
    location,
  } = props;

  return (
    <div className="productlistpageops-container__div">
      {/* <div className="productlistpageops-view__div">
        <p className="productlistpageops-view-header__p">View:</p>
        <FormControl>
          <Select
            value={pageLimit}
            onChange={handlePageLimit}
            // renderValue={value => value}
            input={<Input classes={{ underline: classes.underlineInput }} />}

            classes={{ select: classes.selectSelect, icon: classes.iconSelect }}
          >
            <MenuItem classes={{ root: classes.rootMenuItem }} value={40}>40</MenuItem>
            <MenuItem classes={{ root: classes.rootMenuItem }} value={60}>60</MenuItem>
            <MenuItem classes={{ root: classes.rootMenuItem }} value={120}>120</MenuItem>
          </Select>
        </FormControl>
      </div> */}
      <div className="productlistpageops-sort__div">
        <p className="productlistpageops-sort-header__p">Sort By:</p>
        <FormControl>
          <Select
            value={sort}
            onChange={handleSort}
            // renderValue={value => value}
            classes={{ select: classes.selectSelect, icon: classes.iconSelect }}
            input={<Input classes={{ underline: classes.underlineInput }} />}
          >
            <MenuItem classes={{ root: classes.rootMenuItem }} value={1}>
              Title
            </MenuItem>
            <MenuItem classes={{ root: classes.rootMenuItem }} value={2}>
              Price: Low - High
            </MenuItem>
            <MenuItem classes={{ root: classes.rootMenuItem }} value={3}>
              Price: High - Low
            </MenuItem>
            {
              queryString.parse(location.search).stock === 'JUST_ARRIVED'
                && (
                  <MenuItem classes={{ root: classes.rootMenuItem }} value={4}>
                    Arrival Date
                  </MenuItem>
                )
            }
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

ProductListPageOps.propTypes = {
  // handlePageLimit: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  // pageLimit: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  sort: PropTypes.number.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(ProductListPageOps);
