import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Close from '@material-ui/icons/Close';
import useFormInput from '../../hooks/useFormInput';
// import WithEvent from '../withEvent/WithEvent';
import './_searchBar.scss';

const useStyles = makeStyles({
  rootSearch: {
    width: '1.25em',
    height: '1.25em',
    color: '#ffffff',
  },
  rootClose: {
    width: '1.25em',
    height: '1.25em',
    color: '#af272f',
  },
  rootIconButtonSearch: {
    margin: 0,
    position: 'absolute',
    right: '0px',
    zIndex: 2,
    background: '#af272f',
    borderRadius: 0,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '5px',
    '&:hover': {
      background: '#d4301a',
    },
  },
  rootIconButtonClear: {
    position: 'absolute',
    right: '-65px',
    top: '3px',
    zIndex: 2,
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
  },
  rootIconButtonClose: {
    color: '#af272f',
    position: 'absolute',
    right: '0px',
    top: '10px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#d4301a',
    },
  },
  rootTextField: {
    position: 'absolute',
    zIndex: 2,
    left: '0px',
  },
  rootInput: {
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '15px',
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    backgroundColor: 'rgba(0, 0, 0, .05)',
  },
  rootFilledInput: {
    padding: '12px 8px 12px',
    color: '#616161',
    '&:hover': {
      color: '#000000',
    },
  },
});

const SearchBar = (props) => {
  const classes = useStyles();
  const { inputState, handleChange } = useFormInput();
  const [submitVal, setSubmit] = useState('');
  const [typeState, setType] = useState('product');
  const { onClose } = props;

  // separated input and submit so I can reuse useFormInput logic
  // no clear all to accommodate close searchbar

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    setSubmit(inputState);
  };

  const handleType = (e) => {
    setType(e.target.value);
  };

  useEffect(() => () => {
    setSubmit('');
  }, [submitVal]);

  return submitVal.length
    ? (
      <Redirect to={{
        pathname: '/search-results',
        search: `?page=1&${submitVal}&searchVal=${submitVal}&type=${typeState}`,
        state: {
          URLSearchTerm: submitVal,
          URLOffset: 0,
          URLCurrentPage: 1,
          URLPageLimit: 20,
        },
      }}
      />
    )
    : (
      <React.Fragment>
        <IconButton
          onClick={onClose}
          classes={{ root: classes.rootIconButtonClose }}
          className="searchbar-icon-close__iconbutton"
          aria-label="close-searchbar"
        >
          <Close classes={{ root: classes.rootClose }} />
        </IconButton>
        <div className="searchbar-container__div">
          <div className="searchbar-main__div">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <input aria-label="Search" id="search" className="searchbar-input__input" type="text" value={inputState} onChange={handleChange} />
              <TextField
                id="type"
                value={typeState}
                onChange={handleType}
                variant="filled"
                classes={{ root: classes.rootTextField }}
                className="searchbar-select__textfield"
                select
                InputProps={{
                  classes: {
                    root: classes.rootInput,
                    input: classes.rootFilledInput,
                  },
                  disableUnderline: true,
                }}
              >
                <MenuItem button value="product">
                  Product
                </MenuItem>
                <MenuItem button value="section">
                  Section
                </MenuItem>
              </TextField>
              <IconButton
                type="submit"
                classes={{ root: classes.rootIconButtonSearch }}
                disableRipple
                disableTouchRipple
                aria-label="Search"
              >
                <Search
                  className="searchbar-shoppingbasket__shoppingbasket"
                  classes={{ root: classes.rootSearch }}
                />
              </IconButton>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
};

SearchBar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SearchBar;
