const convertSlashURL = (str) => {
  const newStr = [];

  if (str.length > 0) {
    str.split('').forEach((l) => {
      if (l === '/') {
        newStr.push('%2F');
      } else newStr.push(l);
    });
  }

  return newStr.length > 0 ? newStr.join('') : str;
};

export default convertSlashURL;
