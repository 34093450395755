import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import gql from 'graphql-tag';
import { Redirect, Prompt } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, Dialog } from '@material-ui/core';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import NavbarQuery from '../navbar/NavbarQuery';
import NavbarDesktopQuery from '../navbar/NavbarDesktopQuery';
import Loading from '../loading/Loading';
import CheckoutStepper from './CheckoutStepper';
import CheckoutPayment from './CheckoutPayment';
import CheckoutShipping from './CheckoutShipping';
import CheckoutShipMethod from './CheckoutShipMethod';
import CheckoutReviewOrder from './CheckoutReviewOrder';
import CheckoutDialogLS from './CheckoutDialogLS';
import WithEvent from '../withEvent/WithEvent';
import 'array-flat-polyfill';
import './_checkout.scss';
import availableLS from '../../helpers/availableLS';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import useUserLoggedIn from '../../hooks/useUserLoggedIn';

const useStyles = makeStyles({
  rootButton: {
    backgroundColor: '#af272f',
    fontFamily: ['Trade Gothic LT Std', 'Proxima Nova, sans-serif', 'Roboto', 'Helvetica Neue', 'Arial'],
    textTransform: 'none',
    color: '#ffffff',
    minWidth: '100px',
    fontSize: '15px',
    margin: '10px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#d8341e',
    },
  },
});

export const UPDATE_CHECKOUT = gql`
  mutation updateCheckout($submit: CheckoutTransactionIn!){
    checkoutUpdate(submit: $submit){
      alts{
        canPo
        addr{
          addr1
          addr2
          addressId
          attention
          city
          countryCode
          postalCode
          state
        }
        email
        shipMethod{
          addPriceStv
          basePriceStv
          longName
          shipMethodId
          shortName
          transitInfo
        }
      }
      billAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      tally{
        discountApplyStv
        dueStv
        insufficientFunds
        itemCount
        productStv
        shippingStv
        taxRate
        taxStv
        vatRate
        vatStv
        taxableStv
        totalStv
      }
      specialInstructions
      deliveryInstructions
      checkoutId
      complete
      taxRate
    }
  }
`;

const Checkout = (props) => {
  const {
    history,
    location,
    // cartList,
    // customerLoggedIn,
    // checkoutIsOpen,
    // refetchCartList,
    // refetchCustomerLoggedIn,
    // refetchCheckoutIsOpen,
  } = props;
  const { state: checkoutDetails } = location;

  const classes = useStyles();

  const match1023 = useMediaQuery('(min-width: 1023px)');

  const dialogBtnRef = useRef(null);

  const [stepperState, setStepper] = useState(0);
  const [promptState, setPromptState] = useState(true);
  const [dialogPath, setDialogPath] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [canRedirect, setCanRedirect] = useState(false);

  useEffect(() => {
    if (!(dialogPath?.length && canRedirect)) {
      return;
    }

    history.push(dialogPath);
  }, [history, dialogPath, canRedirect]);

  const {
    compSwitchState: stateLS,
    handleCompOpen: handleLSOpen,
    handleCompClose: handleLSClose,
  } = useOpenCloseComponent();

  const [overweightState, setOverweight] = useState(false);

  useEffect(() => {
    if (checkoutDetails
      && checkoutDetails.beginData
      && checkoutDetails.beginData.checkoutBegin
      && checkoutDetails.beginData.checkoutBegin.cart
      && checkoutDetails.beginData.checkoutBegin.cart.length > 0
      && checkoutDetails.beginData.checkoutBegin.cart.reduce((acc, cv) => (
        [...acc, cv.cartItem]), []).length > 0
      && checkoutDetails.beginData.checkoutBegin.cart.reduce((acc, cv) => (
        [...acc, cv.cartItem]), [])
        .flat()
        .reduce((acc, cv) => [...acc, cv.productDetail.inventory], [])
        .flat()
        .some((cv) => {
          if (cv) {
            return cv.section === 'BKS/FT';
          }
          return false;
        })) {
      setOverweight(true);
    }

    return () => setOverweight(false);
  }, [checkoutDetails]);

  // const [paymentMethodState, setPaymentMethod] = useState({
  //   cc: true,
  //   paypal: false,
  // });
  const [checkoutState, setCheckout] = useState({
    billName: '',
    billLastName: '',
    billAddr1: '',
    billAddr2: '',
    billCity: '',
    billSte: '',
    inputValBillSte: '',
    billAttn: '',
    billZip: '',
    billTel: '',
    billCountry: 'USA',
    billSpecialIns: '',
    billDeliveryIns: '',
    billCC: '',
    billExp: '',
    billCVV: '',
    billEmail: '',
    billPhone: '',
    shipName: '',
    shipLastName: '',
    shipAddr1: '',
    shipAddr2: '',
    shipCity: '',
    shipSte: '',
    inputValShipSte: '',
    shipZip: '',
    shipTel: '',
    shipCountry: 'USA',
    shipSpecialIns: '',
    shipDeliveryIns: '',
    shipMethod: '',
    shipBase: 0,
    shipAdd: 0,
    orderNo: '',
    isSPU: false,
    SPUSpecialIns: '',
    SPULocation: 'SPU',
    payMethod: 'cc',
    gcNumber: '',
    gcPin: '',
    noShipMethod: true,
    noShipDirty: false,
    cpf: '',
    poNumber: '',
    poEmail: '',
    poContact: '',
    sameShip: true,
    checkoutPayment: {},
    cartItemsList: {},
    tally: {},
  });

  // console.log('checkoutState', checkoutState);

  useEffect(() => {
    if (availableLS('localStorage')) {
      if (localStorage.getItem('STRAND_SHIPADDR')) {
        handleLSOpen();
      }
    }
  }, [handleLSOpen]);

  const [
    checkoutUpdate,
    {
      error: checkoutError,
      loading: checkoutLoad,
      data: checkoutData,
    },
  ] = useMutation(UPDATE_CHECKOUT, { variables: { submit: {} }, errorPolicy: 'all' });

  useEffect(() => {
    if (stepperState === 0) {
      checkoutUpdate({ variables: { submit: {} } })
        .catch(err => console.error(err));
    }
  }, [checkoutUpdate, stepperState]);

  // const {
  //   loggedState,
  //   refetch,
  //   loading: logLoad,
  //   error: logError,
  // } = useUserLoggedIn();

  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    data: dataLoggedIn,
    refetch: refetchLoggedIn,
  } = useUserLoggedIn();

  if (!checkoutDetails) return <Redirect to="/" />;

  if (checkoutError) {
    if (checkoutError.graphQLErrors && checkoutError.graphQLErrors.filter(err => err.message === 'no_active_checkout').length) {
      // handleRedirect();
      return <Redirect to="/" />;
    }
  }

  // if (logLoad || countryLoad || checkoutLoad) {
  //   return (
  //     <div className="cart-container__div">
  // <ViewNavbar
  //   cartList={cartList}
  //   checkoutIsOpen={checkoutIsOpen}
  //   customerLoggedIn={customerLoggedIn}
  //   refetchCartList={refetchCartList}
  //   refetchCustomerLoggedIn={refetchCustomerLoggedIn}
  //   refetchCheckoutIsOpen={refetchCheckoutIsOpen}
  // />
  //       <Loading />
  //       <ViewFooter />
  //     </div>
  //   );
  // }

  if (loadingLoggedIn || checkoutLoad) {
    return (
      <div className="cart-container__div">
        {/* <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        /> */}
        {
          match1023 ? <NavbarDesktopQuery /> : <NavbarQuery />
        }
        <Loading />
        <ViewFooter />
      </div>
    );
  }

  // if (logError || countryError) {
  //   // console.log('checkoutError', checkoutError);
  //   return <Redirect to="/error" />;
  // }

  if (errorLoggedIn) {
    // console.log('checkoutError', checkoutError);
    return <Redirect to={{ pathname: '/error', state: { errorLoggedIn } }} />;
  }

  // if (!loggedState) return <Redirect to="/" />;

  if (dataLoggedIn && !dataLoggedIn.customerLoggedIn) return <Redirect to="/" />;

  // if (stepperState === 4) return <Redirect to="/checkout/confirmation" />;

  // console.log(checkoutState, 'location', location);

  // console.log('checkoutData', checkoutData);

  const handleBack = () => {
    const trackerVal = (stateCheckout) => {
      if (stateCheckout === 1) return 'Shipping Method';
      if (stateCheckout === 2 && checkoutState.isSPU) return 'Shipping Method';
      if (stateCheckout === 2) return 'Payment Method';
      if (stateCheckout === 3 && checkoutData?.checkoutUpdate?.alts?.canPo) return 'Shipping Method';
      return 'Review Order';
    };
    WithEvent('CHECKOUT', `Clicked back in ${trackerVal(stepperState)}`, 'BACK_BUTTON', 1);
    if (stepperState === 2 && checkoutState.isSPU) {
      setStepper(stepperState - 2);
    } else if (stepperState === 3 && checkoutData?.checkoutUpdate?.alts?.canPo) {
      if (checkoutState.isSPU) {
        setStepper(stepperState - 3);
      } else {
        setStepper(stepperState - 2);
      }
    } else setStepper(stepperState - 1);
  };

  const handleReview = () => {
    // add conditional that checks each entered field.
    // If all entries are valid, proceed to next step.

    if (stepperState === 0) {
      Sentry.addBreadcrumb({
        category: 'CHECKOUT',
        message: 'Clicked Continue from CheckoutShipping',
        level: Sentry.Severity.Info,
      });
      if (checkoutState.isSPU) {
        if (checkoutData?.checkoutUpdate?.alts?.canPo) {
          checkoutUpdate({
            variables: {
              submit: {
                shipMethodId: checkoutState.SPULocation,
                shipAddr: {
                  attention: `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                  firstName: checkoutState.shipName,
                  lastName: checkoutState.shipLastName,
                  addr1: '828 Broadway',
                  addr2: '',
                  city: 'New York',
                  countryCode: 'USA',
                  postalCode: '10003',
                  state: 'NY',
                },
                billAddr: {
                  attention: `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                  firstName: checkoutState.shipName,
                  lastName: checkoutState.shipLastName,
                  addr1: '828 Broadway',
                  addr2: '',
                  city: 'New York',
                  countryCode: 'USA',
                  postalCode: '10003',
                  state: 'NY',
                },
                contact: {
                  billEmail: checkoutState.poEmail,
                  phone: checkoutState.poContact,
                  phoneSms: false,
                },
                payments: [
                  {
                    openAccount: {
                      purchaseOrder: checkoutState.poNumber,
                    },
                    paymentType: 'OPEN_ACCOUNT',
                  },
                ],
                deliveryInstructions: checkoutState.cpf.length > 0 && checkoutState.shipCountry === 'BRA' ? checkoutState.shipSpecialIns.concat(`\n CPF: ${checkoutState.cpf}`) : checkoutState.shipSpecialIns,
              },
            },
          })
            .then(() => {
              if (!checkoutError) setStepper(stepperState + 3);
            })
            .catch(err => console.error(err));
        } else {
          checkoutUpdate({
            variables: {
              submit: {
                shipMethodId: checkoutState.SPULocation,
                shipAddr: {
                  attention: 'Store Pick-Up', // change when other SPU is enabled
                  addr1: '828 Broadway',
                  addr2: '',
                  city: 'New York',
                  countryCode: 'USA',
                  postalCode: '10003',
                  state: 'NY',
                },
                deliveryInstructions: checkoutState.SPUSpecialIns,
              },
            },
          })
            .then(() => {
              if (!checkoutError) setStepper(stepperState + 2);
            })
            .catch(err => console.error(err));
        }
        // setStepper(stepperState + 2);
      } else if (checkoutData?.checkoutUpdate?.alts?.canPo) {
        checkoutUpdate({
          variables: {
            submit: {
              shipAddr: {
                attention: `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                firstName: checkoutState.shipName,
                lastName: checkoutState.shipLastName,
                addr1: checkoutState.shipAddr1,
                addr2: checkoutState.shipAddr2,
                city: checkoutState.shipCity,
                countryCode: checkoutState.shipCountry,
                postalCode: checkoutState.shipZip,
                state: checkoutState.shipSte,
              },
              billAddr: {
                attention: `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                firstName: checkoutState.shipName,
                lastName: checkoutState.shipLastName,
                addr1: checkoutState.shipAddr1,
                addr2: checkoutState.shipAddr2,
                city: checkoutState.shipCity,
                countryCode: checkoutState.shipCountry,
                postalCode: checkoutState.shipZip,
                state: checkoutState.shipSte,
              },
              contact: {
                billEmail: checkoutState.poEmail,
                phone: checkoutState.poContact,
                phoneSms: false,
              },
              payments: [
                {
                  openAccount: {
                    purchaseOrder: checkoutState.poNumber,
                  },
                  paymentType: 'OPEN_ACCOUNT',
                },
              ],
              deliveryInstructions: checkoutState.cpf.length > 0 && checkoutState.shipCountry === 'BRA' ? checkoutState.shipSpecialIns.concat(`\n CPF: ${checkoutState.cpf}`) : checkoutState.shipSpecialIns,
            },
          },
        })
          .then(() => {
            if (!checkoutError) setStepper(stepperState + 1);
          })
          .catch(err => console.error(err));
      } else {
        checkoutUpdate({
          variables: {
            submit: {
              shipAddr: {
                attention: `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                firstName: checkoutState.shipName,
                lastName: checkoutState.shipLastName,
                addr1: checkoutState.shipAddr1,
                addr2: checkoutState.shipAddr2,
                city: checkoutState.shipCity,
                countryCode: checkoutState.shipCountry,
                postalCode: checkoutState.shipZip,
                state: checkoutState.shipSte,
              },
              deliveryInstructions: checkoutState.cpf.length > 0 && checkoutState.shipCountry === 'BRA' ? checkoutState.shipSpecialIns.concat(`\n CPF: ${checkoutState.cpf}`) : checkoutState.shipSpecialIns,
            },
          },
        })
          .then(() => {
            if (!checkoutError) setStepper(stepperState + 1);
          })
          .catch(err => console.error(err));
      }
    }

    if (stepperState === 1) {
      Sentry.addBreadcrumb({
        category: 'CHECKOUT',
        message: 'Clicked Continue from CheckoutShipMethod',
        level: Sentry.Severity.Info,
      });

      checkoutUpdate({
        variables: {
          submit: {
            shipMethodId: checkoutState.shipMethod,
          },
        },
      })
        .then(() => setCheckout({ ...checkoutState, noShipDirty: true }))
        .then(() => {
          if (!checkoutError && !checkoutState.noShipMethod) setStepper(checkoutData?.checkoutUpdate?.alts?.canPo ? stepperState + 2 : stepperState + 1);
        })
        .catch(err => console.error(err));
    }

    // submit: { contact: { billEmail: "dom@strandbooks.com", phone: "9293462591", phoneSms: false} billAddr: { addr1: "828 Broadway", addr2: "112", city: "New York", countryCode: "USA", attention: "dominic g", state: "NY", postalCode: "10003"}, payments: [{creditCard: { acct: "4012888888881881", expMmyy: "1224", cvv: "543"}, paymentType: CREDIT_CARD}]}
    if (stepperState === 2) {
      Sentry.addBreadcrumb({
        category: 'CHECKOUT',
        message: 'Clicked Continue from CheckoutPayment',
        level: Sentry.Severity.Info,
      });
      if (checkoutState.gcNumber.length > 0 || checkoutState.gcPin.length > 0) {
        checkoutUpdate({
          variables: {
            submit: {
              billAddr: {
                attention: checkoutState.sameShip ? `${checkoutState.shipName} ${checkoutState.shipLastName}` : `${checkoutState.billName} ${checkoutState.billLastName}`,
                firstName: checkoutState.sameShip ? checkoutState.shipName : checkoutState.billName,
                lastName: checkoutState.sameShip ? checkoutState.shipLastName : checkoutState.billLastName,
                addr1: checkoutState.sameShip ? checkoutState.shipAddr1 : checkoutState.billAddr1,
                addr2: checkoutState.sameShip ? checkoutState.shipAddr2 : checkoutState.billAddr2,
                city: checkoutState.sameShip ? checkoutState.shipCity : checkoutState.billCity,
                countryCode: checkoutState.sameShip ? checkoutState.shipCountry : checkoutState.billCountry,
                postalCode: checkoutState.sameShip ? checkoutState.shipZip : checkoutState.billZip,
                state: checkoutState.sameShip ? checkoutState.shipSte : checkoutState.billSte,
              },
              shipAddr: {
                attention: checkoutState.isSPU ? `${checkoutState.billName} ${checkoutState.billLastName}` : `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                firstName: checkoutState.isSPU ? checkoutState.billName : checkoutState.shipName,
                lastName: checkoutState.isSPU ? checkoutState.billLastName : checkoutState.shipLastName,
                addr1: checkoutState.isSPU ? '828 Broadway' : checkoutState.shipAddr1,
                addr2: checkoutState.isSPU ? '' : checkoutState.shipAddr2,
                city: checkoutState.isSPU ? 'New York' : checkoutState.shipCity,
                countryCode: checkoutState.isSPU ? 'USA' : checkoutState.shipCountry,
                postalCode: checkoutState.isSPU ? '10003' : checkoutState.shipZip,
                state: checkoutState.isSPU ? 'NY' : checkoutState.shipSte,
              },
              contact: {
                billEmail: checkoutState.billEmail,
                phone: checkoutState.billPhone,
                phoneSms: false,
              },
              payments: [
                {
                  giftCard: {
                    acct: checkoutState.gcNumber,
                    pin: checkoutState.gcPin,
                  },
                  paymentType: 'GIFT_CARD',
                },
                {
                  creditCard: {
                    acct: checkoutState.billCC,
                    expMmyy: checkoutState.billExp,
                    cvv: checkoutState.billCVV,
                  },
                  paymentType: 'CREDIT_CARD',
                }],
            },
          },
        })
          .then(() => {
            if (!checkoutError) setStepper(stepperState + 1);
          })
          .catch(err => console.error(err));
      } else {
        checkoutUpdate({
          variables: {
            submit: {
              billAddr: {
                attention: checkoutState.sameShip ? `${checkoutState.shipName} ${checkoutState.shipLastName}` : `${checkoutState.billName} ${checkoutState.billLastName}`,
                firstName: checkoutState.sameShip ? checkoutState.shipName : checkoutState.billName,
                lastName: checkoutState.sameShip ? checkoutState.shipLastName : checkoutState.billLastName,
                addr1: checkoutState.sameShip ? checkoutState.shipAddr1 : checkoutState.billAddr1,
                addr2: checkoutState.sameShip ? checkoutState.shipAddr2 : checkoutState.billAddr2,
                city: checkoutState.sameShip ? checkoutState.shipCity : checkoutState.billCity,
                countryCode: checkoutState.sameShip ? checkoutState.shipCountry : checkoutState.billCountry,
                postalCode: checkoutState.sameShip ? checkoutState.shipZip : checkoutState.billZip,
                state: checkoutState.sameShip ? checkoutState.shipSte : checkoutState.billSte,
              },
              shipAddr: {
                attention: checkoutState.isSPU ? `${checkoutState.billName} ${checkoutState.billLastName}` : `${checkoutState.shipName} ${checkoutState.shipLastName}`,
                firstName: checkoutState.isSPU ? checkoutState.billName : checkoutState.shipName,
                lastName: checkoutState.isSPU ? checkoutState.billLastName : checkoutState.shipLastName,
                addr1: checkoutState.isSPU ? '828 Broadway' : checkoutState.shipAddr1,
                addr2: checkoutState.isSPU ? '' : checkoutState.shipAddr2,
                city: checkoutState.isSPU ? 'New York' : checkoutState.shipCity,
                countryCode: checkoutState.isSPU ? 'USA' : checkoutState.shipCountry,
                postalCode: checkoutState.isSPU ? '10003' : checkoutState.shipZip,
                state: checkoutState.isSPU ? 'NY' : checkoutState.shipSte,
              },
              contact: {
                billEmail: checkoutState.billEmail,
                phone: checkoutState.billPhone,
                phoneSms: false,
              },
              payments: [
                {
                  creditCard: {
                    acct: checkoutState.billCC,
                    expMmyy: checkoutState.billExp,
                    cvv: checkoutState.billCVV,
                  },
                  paymentType: 'CREDIT_CARD',
                }],
            },
          },
        })
          .then(() => {
            if (!checkoutError) setStepper(stepperState + 1);
          })
          .catch(err => console.error(err));
      }
    }
  };

  const handleSPU = () => {
    setCheckout({ ...checkoutState, isSPU: !checkoutState.isSPU, sameShip: false });
  };

  const handleSameShip = () => {
    setCheckout({ ...checkoutState, sameShip: !checkoutState.sameShip });
  };

  // console.log('checkoutState', checkoutState);

  const handleSetLS = () => {
    const parseAddr = JSON.parse(localStorage.getItem('STRAND_SHIPADDR'));

    setCheckout({
      ...checkoutState,
      shipName: parseAddr.attention.lastIndexOf(' ') > -1 ? parseAddr.attention.slice(0, parseAddr.attention.lastIndexOf(' ')) : parseAddr.attention,
      shipLastName: parseAddr.attention.lastIndexOf(' ') > -1 ? parseAddr.attention.slice(parseAddr.attention.lastIndexOf(' ')) : '',
      shipAddr1: parseAddr.addr1 || '',
      shipAddr2: parseAddr.addr2 || '',
      shipCity: parseAddr.city,
      shipCountry: parseAddr.countryCode,
      shipZip: parseAddr.postalCode || '',
      shipSte: parseAddr.state || '',
    });

    handleLSClose();
  };

  const handleRemoveLS = () => {
    localStorage.removeItem('STRAND_SHIPADDR');

    handleLSClose();
  };

  return (
    <React.Fragment>
      <Prompt
        when={promptState && stepperState !== 4 && stepperState !== 0}
        message={(newLocation) => {
          setDialogPath(newLocation.pathname);
          setShowDialog(true);
          return false;
        }}
      />
      <Dialog
        aria-labelledby="checkout-redirect-dialog-message"
        open={showDialog}
        onEntered={() => {
          if (!dialogBtnRef?.current) {
            return;
          }

          dialogBtnRef.current.focus();
        }}
        onClose={() => setShowDialog(false)}
      >
        <div className="checkout-redirect-dialog-container__div">
          <p id="checkout-redirect-dialog-message" className="checkout-redirect-dialog-message__p">Are you sure you want to leave checkout?</p>

          <div className="checkout-redirect-dialog-button-wrapper__div">
            <Button
              ref={dialogBtnRef}
              classes={{ root: classes.rootButton }}
              onClick={() => {
                setPromptState(false);
                setCanRedirect(true);
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Dialog>
      {/* <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      /> */}
      {
        match1023 ? <NavbarDesktopQuery /> : <NavbarQuery />
      }
      <div className="checkout-container__div">
        <div className="checkout-content__div">
          {
            stepperState === 0
              && localStorage.getItem('STRAND_SHIPADDR')
              && (
              <CheckoutDialogLS
                open={stateLS}
                handleRemoveLS={handleRemoveLS}
                handleSetLS={handleSetLS}
                parseAddr={JSON.parse(localStorage.getItem('STRAND_SHIPADDR'))}
              />
              )
          }
          {
            stepperState < 4
              && <CheckoutStepper currentStep={stepperState} setStepper={setStepper} />
          }
          {
            stepperState === 0
              && (
              <CheckoutShipping
                setStepper={setStepper}
                currentStep={stepperState}
                checkoutDetails={checkoutDetails}
                checkoutState={checkoutState}
                setCheckout={setCheckout}
                checkoutData={checkoutData}
                handleReview={handleReview}
                handleSPU={handleSPU}
                checkoutError={checkoutError}
                history={history}
              />
              )
          }
          {
            stepperState === 1
              && (
                <CheckoutShipMethod
                  setStepper={setStepper}
                  currentStep={stepperState}
                  checkoutDetails={checkoutDetails}
                  checkoutState={checkoutState}
                  setCheckout={setCheckout}
                  refetch={refetchLoggedIn}
                  checkoutData={checkoutData}
                  handleReview={handleReview}
                  handleBack={handleBack}
                  checkoutError={checkoutError}
                  overweightState={overweightState}
                />
              )
          }
          {
            stepperState === 2
              && (
                <CheckoutPayment
                  setStepper={setStepper}
                  currentStep={stepperState}
                  checkoutDetails={checkoutDetails}
                  checkoutState={checkoutState}
                  setCheckout={setCheckout}
                  stepperState={stepperState}
                  checkoutData={checkoutData}
                  handleReview={handleReview}
                  handleBack={handleBack}
                  checkoutError={checkoutError}
                  overweightState={overweightState}
                  handleSameShip={handleSameShip}
                />
              )
          }
          {
            stepperState === 3
              && (
              <CheckoutReviewOrder
                currentStep={stepperState}
                setStepper={setStepper}
                checkoutDetails={checkoutDetails}
                checkoutState={checkoutState}
                setCheckout={setCheckout}
                checkoutData={checkoutData}
                handleReview={handleReview}
                handleBack={handleBack}
                overweightState={overweightState}
                setPromptState={setPromptState}
              />
              )
          }
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Checkout.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  // cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  // customerLoggedIn: PropTypes.bool.isRequired,
  // checkoutIsOpen: PropTypes.bool.isRequired,
  // refetchCartList: PropTypes.func.isRequired,
  // refetchCustomerLoggedIn: PropTypes.func.isRequired,
  // refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Checkout;
