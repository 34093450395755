import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ThemeProvider } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Close from '@material-ui/icons/Close';
import Loading from '../loading/Loading';
import useUserLoggedIn from '../../hooks/useUserLoggedIn';
import urlGenerator from '../../helpers/urlGenerator';
import './_loginSignup.scss';

const CREATE_CUSTOMER = gql`
mutation createCustomer($customer: CustomerInput!){
  customerCreate(customer: $customer){
    failure
    success
    customer{
      customerId
      firstName
      lastName
      verified
    }
  }
}
`;

const LOGIN_CUSTOMER = gql`
  mutation loginCustomer($email: String!, $password: String!){
    customerLogin(email: $email, password: $password){
      customer{
        customerId
        firstName
        lastName
        verified
      }
      message
      success
    }
  }
`;

// const LOGOUT_CUSTOMER = gql`
//   mutation logoutCustomer{
//     customerLogout
//   }
// `;

// const RESET_PASSWORD = gql`
//   mutation resetPassword($email: String!, $password: String!, $resetId: ID!){
//     customerPasswordReset(email: $email, password: $password, resetId: $resetId){
//       customerId
//       firstName
//       lastName
//       verified
//     }
//   }
// `;

const RECOVER_PASSWORD = gql`
  mutation recoverPassword($email: String!){
    customerRecover(email: $email)
  }
`;

const useStyles = makeStyles({
  paperDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootButton: {
    color: '#ffffff',
    backgroundColor: '#af272f',
    borderRadius: '4px',
    fontSize: '18px',
    padding: '10px',
    width: '125px',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },
  rootIconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
  rootTextField: {
    margin: '10px 0',
  },
  rootIconButtonClose: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#af272f',
    },
  },
});

const LoginSignup = (props) => {
  const [customerCreate, {
    data: createData,
    loading: createLoading,
    error: createError,
  }] = useMutation(CREATE_CUSTOMER);

  const [customerLogin, {
    data: loginData,
    loading: loginLoading,
    error: loginError,
  }] = useMutation(LOGIN_CUSTOMER);

  // const { loggedState, refetch } = useUserLoggedIn();
  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    // data: dataLoggedIn,
    refetch: refetchLoggedIn,
  } = useUserLoggedIn();

  // const [logoutCustomer] = useMutation(LOGOUT_CUSTOMER);
  // const [resetPassword] = useMutation(RESET_PASSWORD);
  const [
    customerRecover,
    {
      data: dataCustomerRecover,
      loading: loadingCustomerRecover,
      error: errorCustomerRecover,
    }] = useMutation(RECOVER_PASSWORD);

  const {
    open,
    handleClose,
    // cartlistData,
    // location,
    // cartCheckout,
  } = props;

  const [showPassword, setPassword] = useState(false);
  // const [loginErrorState, setLoginError] = useState(false);

  // console.log(props);

  // const [pwMatchState, setPwMatch] = useState(false);

  // const handlePwMatch = () => {
  //   setPwMatch(true);
  // };

  const handleShowPassword = () => setPassword(prev => !prev);
  const [inputState, setInput] = useState({
    forgotEmailInput: '',
    passwordInput: '',
    emailInput: '',
    firstNameInput: '',
    lastNameInput: '',
    phoneInput: '',
    newEmailInput: '',
    newPasswordInput: '',
    confirmPasswordInput: '',
    signUp: false,
    resetPassword: false,
    logIn: true,
  });

  // const [successState, setSuccess] = useState(true);

  useEffect(() => {
    setInput(prevState => (
      {
        ...prevState,
        forgotEmailInput: '',
        passwordInput: '',
        emailInput: '',
        firstNameInput: '',
        lastNameInput: '',
        phoneInput: '',
        newEmailInput: '',
        newPasswordInput: '',
        confirmPasswordInput: '',
      }
    ));
  }, [inputState.signUp, inputState.resetPassword, inputState.logIn]);

  const classes = useStyles();

  const handleInput = name => (e) => {
    // console.log(e.target.value);
    setInput({ ...inputState, [name]: e.target.value });
  };

  const handlesignUp = () => {
    setInput(prev => ({ ...prev, ...{ signUp: true, resetPassword: false, logIn: false } }));
  };

  const handleForgotPassword = () => {
    setInput(prev => ({
      ...prev,
      ...{
        signUp: false,
        resetPassword: true,
        logIn: false,
      },
    }));
  };

  const handleLogIn = () => {
    setInput(prev => ({
      ...prev,
      ...{
        signUp: false,
        resetPassword: false,
        logIn: true,
      },
    }));
  };

  useEffect(() => {
    handleLogIn();
  }, [createData]);

  // const handleCheckoutGuest = () => {
  //   if (inCart) {
  //     history.push('/checkout', {});
  //   }
  // };

  // useEffect(() => () => setInput({
  //   usernameInput: '',
  //   passwordInput: '',
  //   emailInput: '',
  //   newPasswordInput: '',
  //   confirmPasswordInput: '',
  //   signUp: false,
  //   resetPassword: false,
  //   logIn: true,
  // }), []);

  // useEffect(() => {
  //   // refetch();
  //   refetchLoggedIn();
  //   return () => {
  //     refetch();
  //   };
  // }, [loggedState, refetch, loginData]);

  // if (loginData && loginData.customerLogin) {
  //   if (loginData.customerLogin.success) {
  //     if (inCart && history) {
  //       history.push('/checkout', {});
  //     }
  //     handleClose();
  //     refetch();
  //   }
  //   // console.log(loginData);
  // }

  // if (loggedState) {
  //   if (location && location.pathname === '/cart') {
  //     history.push('/checkout', {});
  //   }
  //   handleClose();
  //   refetch();
  // }

  if (errorLoggedIn || loginError || errorCustomerRecover) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            errorLoggedIn,
            loginError,
            errorCustomerRecover,
          },
        }}
      />
    );
  }

  if (loadingLoggedIn || loginLoading || createLoading || loadingCustomerRecover) {
    return (
      <Dialog
        disableBackdropClick
        aria-label="Login Signup"
        open={open}
        classes={{ paper: classes.paperDialog }}
      >
        <div className="loginsignup-loading__div" style={{ minWidth: '300px' }} />
        <Loading />
      </Dialog>
    );
  }

  // if (loggedState) {
  //   if (loginData && loginData.customerLogin.success) {
  //     handleClose();
  //     refetch();
  //   }
  // }

  if (loginData && loginData.customerLogin && loginData.customerLogin.success) {
    // handleClose();
    refetchLoggedIn();
  }

  // if (createData && createData.customerCreate && createData.customerCreate.success) {
  //   // console.log('createFailure', createData.customerCreate.failure);
  //   return (
  //     <Dialog
  //       onClose={handleCloseSuccess}
  //       aria-labelledby="Sign up Sucessful"
  //       open={successState}
  //       classes={{ paper: classes.paperDialog }}
  //     >
  //       <div className="loginsignup-success__div">
  //         <div className="loginsignup-success-close__div">
  //           <IconButton disableRipple disableTouchRipple aria-label="success close" onClick={handleCloseSuccess} classes={{ root: classes.rootIconButtonClose }}>
  //             <Close />
  //           </IconButton>
  //         </div>
  //         <p className="loginsignup-success__p">
  //           Success! A verification link has been sent to your email. You may now log in.
  //         </p>
  //       </div>
  //     </Dialog>
  //   );
  // }

  // if (dataCustomerRecover && dataCustomerRecover.customerRecover) {
  //   handleClose();
  // }

  // console.log(dataCustomerRecover);

  const errorDic = (errTerm) => {
    const errObj = {
      last_name: 'Last Name',
      first_name: 'First Name',
      email: 'Email',
      password: 'Password',
      'email Account': 'Email Account',
    };

    return errObj[errTerm] ? errObj[errTerm] : errTerm;
  };

  const dialogProps = {};
  const describeLabels = [];

  if (
    (
      loginData
        && inputState.logIn
        && loginData.customerLogin
        && !loginData.customerLogin.success
    ) || (
      createData
        && inputState.signUp
        && createData.customerCreate
        && !createData.customerCreate.success
    ) || (
      createData
        && inputState.logIn
        && createData.customerCreate
        && createData.customerCreate.success
    )
  ) {
    describeLabels.push('loginsignup-message');
  }

  if (createError?.graphQLErrors?.length && createError.graphQLErrors[0].message !== 'address is undeliverable') {
    describeLabels.push.apply(createError.graphQLErrors.map((_item, i) => `loginsignup-message-${i}`));
  }

  if (dataCustomerRecover) {
    describeLabels.push('loginsignup-recover');
  }

  if (inputState.resetPassword) {
    describeLabels.push('loginsignup-subheader');
  }

  if (describeLabels.length) {
    dialogProps['aria-describedby'] = describeLabels.join(' ');
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="loginsignup-header"
      open={open}
      classes={{ paper: classes.paperDialog }}
      {...dialogProps}
    >
      <div className="loginsignup-content__div">
        <div className="loginsignup-header-image__div">
          <img className="loginsignup-header-image__img" src={urlGenerator('/S/static/strand-logo-exact.png')} alt="Strand Logo" />
          <div className="loginsignup-close__div">
            <IconButton aria-label="close" onClick={handleClose} classes={{ root: classes.rootIconButton }}>
              <Close />
            </IconButton>
          </div>
        </div>
        {
          inputState.resetPassword && <h1 id="loginsignup-header" className="loginsignup-header__h1">Reset Password</h1>
        }
        {
          inputState.signUp && <h1 id="loginsignup-header" className="loginsignup-header__h1">Create a Strand Account</h1>
        }
        {
          inputState.logIn && <h1 id="loginsignup-header" className="loginsignup-header__h1">Log In to Your Strand Account</h1>
        }
        {
          loginData
            && inputState.logIn
            && loginData.customerLogin
            && !loginData.customerLogin.success
            && <p id="loginsignup-message" className="loginsignup-error__p">The email address and/or password was incorrect. Please try again.</p>
        }
        {
          createData
            && inputState.signUp
            && createData.customerCreate
            && !createData.customerCreate.success
            && <p id="loginsignup-message" className="loginsignup-error__p">The email address already exists. Please log in.</p>
        }
        {
          createData
            && inputState.logIn
            && createData.customerCreate
            && createData.customerCreate.success
            && <p id="loginsignup-message" className="loginsignup-success__p">Success! A verification link has been sent to your email. You may now log in.</p>
        }
        {
          createError?.graphQLErrors
            && createError?.graphQLErrors.length > 0
            && createError?.graphQLErrors?.[0]?.message === 'address is undeliverable'
            && <p id="loginsignup-message" className="loginsignup-error__p">Verification email failed to deliver. Please check your email.</p>
        }
        {
          createError?.graphQLErrors
            && createError?.graphQLErrors.length > 0
            && createError?.graphQLErrors?.[0]?.message !== 'address is undeliverable'
            && createError?.graphQLErrors
              .map((err, i) => (
                <p id={`loginsignup-message-${i}`} className="loginsignup-error__p" key={err?.pathf}>
                  {`${errorDic(err?.pathf)} ${err?.message}`}
                </p>
              ))
        }
        {
          dataCustomerRecover
            && !dataCustomerRecover.customerRecover
            && <p id="loginsignup-recover" className="loginsignup-error__p">Something went wrong. Please try again later.</p>
        }
        {
          inputState.resetPassword
            && dataCustomerRecover
            && dataCustomerRecover.customerRecover
            && <p id="loginsignup-recover" className="loginsignup-success__p">A link has been sent to your email to reset your password.</p>
        }
        <ThemeProvider theme={theme}>
          {
            inputState.resetPassword
              && (
                <div className="loginsignup-form__div">
                  <p id="loginsignup-subheader" className="loginsignup-subheader__p">Enter your email address and we&#39;ll send you instructions to reset your password</p>
                  <form
                    className="loginsignup-form__form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();

                      Sentry.addBreadcrumb({
                        category: 'FORGOT_PASSWORD_DIALOG',
                        message: 'Clicked Reset Password',
                        level: Sentry.Severity.Info,
                      });

                      customerRecover({ variables: { email: inputState.forgotEmailInput } });
                    }}
                  >
                    <TextField
                      autoFocus
                      onChange={handleInput('forgotEmailInput')}
                      label="Email Address"
                      id="reset-email"
                      value={inputState.usernameInput}
                      variant="outlined"
                      className="loginsignup-input__textfield"
                      type="email"
                      classes={{ root: classes.rootTextField }}
                    />
                    <input aria-label="email address" className="loginsignup-submit__input loginsignup-submit-reset__input" type="submit" value="Reset Password" />
                  </form>
                </div>
              )
          }
          {
            inputState.logIn
              && (
              <div className="loginsignup-form__div">
                <form
                  className="loginsignup-form__form"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();

                    Sentry.addBreadcrumb({
                      category: 'LOGIN_DIALOG',
                      message: 'Clicked Log In',
                      level: Sentry.Severity.Info,
                    });

                    customerLogin({
                      variables: {
                        email: inputState.emailInput,
                        password: inputState.passwordInput,
                      },
                    });
                  }}
                >
                  <TextField
                    autoFocus
                    onChange={handleInput('emailInput')}
                    label="Email Address"
                    value={inputState.emailInput}
                    variant="outlined"
                    className="loginsignup-input__textfield"
                    type="email"
                    classes={{ root: classes.rootTextField }}
                    id="login-email"
                  />
                  <TextField
                    onChange={handleInput('passwordInput')}
                    classes={{ root: classes.rootTextField }}
                    id="login-password"
                    label="Password"
                    value={inputState.passwordInput}
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disableRipple
                            disableTouchRipple
                            disableFocusRipple
                            onClick={handleShowPassword}
                            aria-label="Show Password"
                          >
                            {
                              showPassword
                                ? <Visibility />
                                : <VisibilityOff />
                            }
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {
                    inputState.logIn
                      && (
                        <div className="loginsignup-options-forgot__div">
                          <ButtonBase className="loginsignup-option-btn__buttonbase" disableRipple disableTouchRipple onClick={handleForgotPassword}>
                            <p className="loginsignup-forgot__p">Forgot password?</p>
                          </ButtonBase>
                        </div>
                      )
                  }
                  <input
                    aria-label="login"
                    className="loginsignup-submit__input"
                    type="submit"
                    value="Log In"
                  />
                </form>
                {
                  // OAuth Log In
                  // <p className="loginsignup-alt__p">Or</p>
                  // <input className="loginsignup-fb__input" type="button" value="Log In with Facebbok" />
                  // <input className="loginsignup-google__input" type="button" value="Log In with Google" />
                }

              </div>
              )
          }
          {
            inputState.signUp
              && (
                <div className="loginsignup-form__div">
                  <form
                    className="loginsignup-form__form"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();

                      Sentry.addBreadcrumb({
                        category: 'SIGNUP_DIALOG',
                        message: 'Clicked Sign Up',
                        level: Sentry.Severity.Info,
                      });

                      customerCreate({
                        variables: {
                          customer: {
                            email: inputState.newEmailInput,
                            firstName: inputState.firstNameInput,
                            lastName: inputState.lastNameInput,
                            password: inputState.newPasswordInput,
                          },
                        },
                      })
                        .catch(() => {});
                    }}
                  >
                    <TextField
                      autoFocus
                      onChange={handleInput('firstNameInput')}
                      label="First Name"
                      value={inputState.firstNameInput}
                      classes={{ root: classes.rootTextField }}
                      variant="outlined"
                      className="loginsignup-input__textfield"
                      type="email"
                      id="signup-firstname"
                      required
                    />
                    <TextField
                      onChange={handleInput('lastNameInput')}
                      label="Last Name"
                      value={inputState.lastNameInput}
                      classes={{ root: classes.rootTextField }}
                      variant="outlined"
                      className="loginsignup-input__textfield"
                      type="email"
                      id="signup-lastname"
                      required
                    />
                    <TextField
                      onChange={handleInput('newEmailInput')}
                      label="Email Address"
                      value={inputState.newEmailInput}
                      classes={{ root: classes.rootTextField }}
                      variant="outlined"
                      className="loginsignup-input__textfield"
                      type="email"
                      id="signup-email"
                      required
                    />
                    <TextField
                      onChange={handleInput('newPasswordInput')}
                      label="Password"
                      value={inputState.newPasswordInput}
                      classes={{ root: classes.rootTextField }}
                      variant="outlined"
                      required
                      id="signup-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disableRipple
                              disableTouchRipple
                              disableFocusRipple
                              onClick={handleShowPassword}
                              aria-label="Show Password"
                            >
                              {
                                showPassword
                                  ? <Visibility />
                                  : <VisibilityOff />
                              }
                            </IconButton>
                          </InputAdornment>
                        ),
                        autoComplete: 'new-password',
                      }}
                    />
                    {/* <TextField
                      onChange={handleInput('confirmPasswordInput')}
                      label="Confirm Password"
                      value={inputState.confirmPasswordInput}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disableRipple
                              disableTouchRipple
                              disableFocusRipple
                              onClick={handleShowPassword}
                              aria-label="Show Password"
                            >
                              {
                                showPassword
                                  ? <Visibility />
                                  : <VisibilityOff />
                              }
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    <input
                      aria-label="sign up"
                      className="loginsignup-submit__input"
                      type="submit"
                      value="Sign Up"
                    />
                  </form>
                </div>
              )
          }
        </ThemeProvider>
        {
          inputState.logIn
            && (
              <div className="loginsignup-options__div">
                <ButtonBase className="loginsignup-option-btn__buttonbase" disableRipple disableTouchRipple onClick={handlesignUp}>
                  <p className="loginsignup-signup__p">Don&#39;t have an account? Sign up!</p>
                </ButtonBase>
              </div>
            )
        }
        {
          inputState.resetPassword
            && (
              <div className="loginsignup-options__div">
                <ButtonBase className="loginsignup-option-btn__buttonbase" disableRipple disableTouchRipple onClick={handleLogIn}>
                  <p className="loginsignup-forgot__p">Or Log In</p>
                </ButtonBase>
              </div>
            )
        }
        {
          inputState.signUp
            && (
              <div className="loginsignup-options__div">
                <ButtonBase className="loginsignup-option-btn__buttonbase" disableRipple disableTouchRipple onClick={handleLogIn}>
                  <p className="loginsignup-forgot__p">Already have an account? Log In</p>
                </ButtonBase>
              </div>
            )
        }
      </div>
    </Dialog>
  );
};

// LoginSignup.defaultProps = {
//   history: null,
//   inCart: false,
// };

LoginSignup.propTypes = {
  // history: PropTypes.objectOf(PropTypes.any),
  // inCart: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LoginSignup;
