import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import listEvents from '../events/listEvents';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import './_upcomingEventsBanner.scss';
import convertURL from '../../helpers/convertURL';

const UpcomingEventsBanner = () => {
  const match1023 = useMediaQuery('(min-width: 1023px)');

  const createHeader = () => {
    const upcomingWithBanners = listEvents
    && listEvents
      .filter((event) => {
        const eventDate = moment(event.dateObj);
        return eventDate.isSameOrAfter(moment());
      })
      .filter(event => event?.upcomingBannerUrl.length > 0);

    const sortUpcomingWithBanners = upcomingWithBanners.sort((a, b) => moment(a.dateObj).format('YYYYMMDD') - moment(b.dateObj).format('YYYYMMDD'));

    return sortUpcomingWithBanners.shift();
  };

  return (
    <div className="upcomingeventsbanner-container__div">
      <main className="upcomingeventsbanner-content__main">
        <div className="upcomingeventsbanner-content__div">
          <div className="upcomingeventsbanner-banner__div">
            {
              match1023
                && listEvents
                && listEvents
                  .filter((event) => {
                    const eventDate = moment(event.dateObj);
                    return eventDate.isSameOrAfter(moment());
                  })
                  .filter(event => event?.upcomingBannerUrl.length > 0)
                  .length > 0
                ? (
                  <React.Fragment>
                    <Link
                      to={`/events/${createHeader().id}?title=${convertURL(createHeader().eventName)}`}
                      className="upcomingeventsbanner-link__link"
                    >
                      <div className="upcomingeventsbanner-image__div">
                        <img className="upcomingeventsbanner-image__img" src={createHeader().upcomingBannerUrl} alt={createHeader().eventName} />
                      </div>
                    </Link>
                    <PageSubHeader header="Upcoming Events" />
                  </React.Fragment>
                )
                : <PageSubHeader header="Upcoming Events" />
            }
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpcomingEventsBanner;
