const graphQLErrorsDictionary = (str, pathf) => {
  if (!str) return '';

  // console.log(str, pathf);
  const dictionary = {
    addr1: 'Address 1',
    addr2: 'Address 2',
    attention: 'First Name/Last Name',
    city: 'City',
    countryCode: 'Country',
    postalCode: 'Zip Code',
    state: 'State',
    billEmail: 'Email',
    phone: 'Phone #',
    acct: pathf[0] === 'gift_card' ? 'Gift' : 'Credit',
    cvv: 'CVV',
    expMmyy: 'Exp. Date',
    paymentType: 'Payment Type',
    pin: 'Pin Number',
    exp_mmyy: 'Exp. Date',
    card_type: 'Credit Card Number',
    bill_email: 'Email',
    avs_zip: 'Zip Code',
    gift_card: ' ',
  };

  if (dictionary[str]) return dictionary[str];

  return str;
};

export default graphQLErrorsDictionary;
