import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_spooktober.scss';

const Spooktober2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="spooktober-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="spooktober-content__main">
          <div className="spooktober-content__div">
            <div className="spooktober-border__div" />
            <section className="spooktober-categories__section">
              <div className="spooktober-categories__div">

                <LazyLoad height={300} offset={300}>
                  <div className="spooktober-category__div">
                    <Link
                      to="/metalist/spooktober_books"
                      className="spooktober-category-link__link"
                      onClick={() => WithEvent('SPOOKTOBER_BOOKS', 'Clicked Spooktober Books tile', 'SPOOKTOBER_LINK', 1)}
                      aria-label="Spooktober Books"
                    >
                      <img
                        className="spooktober-category-image__img"
                        alt="Spooktober Books"
                        src={urlGenerator('/S/pages/spooktober_books_2021.png')}
                      />
                      <div className="spooktober-header__div">
                        <h1 className="spooktober-header__h1">Books</h1>
                      </div>

                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="spooktober-category__div">
                    <Link
                      to="/metalist/spooktober_merch"
                      className="spooktober-category-link__link"
                      onClick={() => WithEvent('SPOOKTOBER_MERCH', 'Clicked Spooktober Merch tile', 'SPOOKTOBER_LINK', 1)}
                      aria-label="Spooktober Merch 2021"
                    >
                      <img
                        className="spooktober-category-image__img"
                        alt="Spooktober Merch"
                        src={urlGenerator('/S/pages/spooktober_merch_2021.png')}
                      />
                      <div className="spooktober-header__div">
                        <h1 className="spooktober-header__h1">Merch</h1>
                      </div>
                    </Link>
                  </div>
                </LazyLoad>

              </div>
            </section>

          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Spooktober2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Spooktober2021;
