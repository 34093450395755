import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../loading/Loading';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutForm from './CheckoutForm';
import CheckoutBack from './CheckoutBack';
import CheckoutSnackbar from './CheckoutSnackbar';
import use4Cite from '../../hooks/use4Cite';
import './_checkoutPayment.scss';

/*
************
GRAPHQL
************
*/

export const UPDATE_CHECKOUT = gql`
  mutation updateCheckout($submit: CheckoutTransactionIn!){
    checkoutUpdate(submit: $submit){
      alts{
        canPo
        addr{
          addr1
          addr2
          addressId
          attention
          city
          countryCode
          postalCode
          state
        }
        email
        shipMethod{
          addPriceStv
          basePriceStv
          longName
          shipMethodId
          shortName
          transitInfo
        }
      }
      contact{
        billEmail
        phone
        phoneSms
        shipEmail
      }
      billAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      shipAddr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      tally{
        discountApplyStv
        dueStv
        insufficientFunds
        itemCount
        productStv
        shippingStv
        taxRate
        taxStv
        vatRate
        vatStv
        taxableStv
        totalStv
      }
      completeInfo
      specialInstructions
      deliveryInstructions
      checkoutId
      complete
      taxRate
    }
  }
`;

/*
******************
MATERIAL-UI STYLES
******************
*/

const useStyles = makeStyles({
  rootIconBtn: {
    color: '#ffffff',
    // color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '18px',
    color: '#ffffff',
    minWidth: '240px',
    height: '40px',
    borderRadius: '5px',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
    },
  },
  rootIconBtnRed: {
    color: '#af272f',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#d4301a',
      color: '#ffffff',
    },
  },
  colorPrimaryAppBar: {
    backgroundColor: '#af272f',
  },
  rootTextField: {
    width: '100%',
    maxWidth: '500px',
    margin: '10px 0',
  },
  rootCheckbox: {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedCheckbox: {
    color: '#af272f !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  checkedRadio: {
    color: '#af272f !important',
  },
  colorSecondaryRadio: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
});

const CheckoutPayment = (props) => {
  const {
    setStepper,
    currentStep,
    checkoutDetails,
    checkoutState,
    setCheckout,
    handleReview,
    handleBack,
    checkoutError,
    overweightState,
    handleSameShip,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const {
    alts,
    checkoutId,
  } = checkoutDetails.beginData.checkoutBegin;

  const [checkoutUpdate, {
    data: updateData,
    loading: updateLoading,
    error: updateError,
  }] = useMutation(UPDATE_CHECKOUT);

  useEffect(() => {
    checkoutUpdate({ variables: { submit: {} } });
  }, [checkoutUpdate]);

  use4Cite();

  const classes = useStyles();

  const [methodState, setMethod] = useState('cc');

  const handleMethod = e => setMethod(e.target.value);

  const [headerState, setHeader] = useState({
    paymentInfo: false,
    shippingAddress: false,
  });

  const handleHeader = header => setHeader(prev => ({ ...prev, [header]: !prev[header] }));

  if (updateLoading) {
    return (
      <React.Fragment>
        <Loading />
      </React.Fragment>
    );
  }

  if (updateError) {
    return <Redirect to={{ pathname: '/error', state: { updateError } }} />;
  }

  return (
    <div className={!match767 ? 'checkoutpayment-container__div--mobile' : 'checkoutpayment-container__div'}>
      {
        !match767
          && (
            <div className="checkoutpayment-cont-btn__div">
              <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>Continue</Button>
            </div>
          )
      }
      <main className="checkoutpayment-info__main" aria-labelledby="checkoutpayment-title">
        <CheckoutBack handleBack={handleBack} />
        <section className="checkoutpayment-payment__section">
          <div className="checkoutpayment-payment__div">
            <div className={headerState.paymentInfo ? 'checkoutpayment-header__div checkoutpayment-header__div--red' : 'checkoutpayment-header__div'}>
              { /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */ }
              <h3 id="checkoutpayment-title" className={headerState.paymentInfo ? 'checkoutpayment-header__h3 checkoutpayment-header__h3--white' : 'checkoutpayment-header__h3'} tabIndex="0">Payment Method</h3>
              {
                checkoutError
                && checkoutError.graphQLErrors
                && (
                  <CheckoutSnackbar
                    open
                    message={checkoutError || {}}
                  />
                )
              }
              {
                alts && alts.payment && alts.payment.length > 1
                  && (
                    <IconButton classes={{ root: headerState.paymentInfo ? classes.rootIconBtn : classes.rootIconBtnRed }} onClick={() => handleHeader('paymentInfo')}>
                      {
                        headerState.paymentInfo
                          ? <ExpandLess />
                          : <ExpandMore />
                      }
                    </IconButton>
                  )
              }
            </div>
            <div className="checkoutpayment-methods__div">
              <div className="checkoutpayment-method__div">
                <div className="checkoutpayment-radio__div">
                  <Radio
                    checked={methodState === 'cc'}
                    onChange={handleMethod}
                    value="cc"
                    inputProps={{ 'aria-label': 'credit or debit card' }}
                    classes={{
                      checked: classes.checkedRadio,
                      colorSecondary: classes.colorSecondaryRadio,
                    }}
                  />
                  <p className="checkoutpayment-method-name__p">Credit or Debit Card</p>
                </div>
                {
                  !checkoutState.isSPU
                    && (
                      <div className="checkoutpayment-same-ship-checkbox__div">
                        <Checkbox
                          disableRipple
                          inputProps={{ 'aria-label': 'billing address is the same as shipping' }}
                          checked={checkoutState.sameShip}
                          onChange={handleSameShip}
                          value="billing address is the same as shipping"
                          classes={{ root: classes.rootCheckbox, checked: classes.checkedCheckbox }}
                        />
                        <p className="checkoutpayment-same-ship-checkbox-details__p">billing address same as shipping</p>
                      </div>
                    )
                }
                {
                  checkoutState.sameShip
                    && (
                      <div className="checkoutpayment-same-ship-details__div">
                        <h3 className="checkoutpayment-header__h3">Billing Address</h3>
                        <p className="checkoutpayment-same-ship-details__p">{`${checkoutState.shipName} ${checkoutState.shipLastName}`}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipAddr1}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipAddr2}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipCity}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipSte}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipZip}</p>
                        <p className="checkoutpayment-same-ship-details__p">{checkoutState.shipCountry}</p>
                      </div>
                    )
                }
                <Collapse in={methodState === 'cc'}>
                  <CheckoutForm sameShip={checkoutState.sameShip} billing checkoutState={checkoutState} setCheckout={setCheckout} />
                </Collapse>
                {
                  match767
                    && (
                      <div className="checkoutpayment-cont-btn__div checkoutpayment-cont-form-btn__div">
                        <Button classes={{ root: classes.rootBtn }} onClick={handleReview}>Continue</Button>
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </section>
      </main>
      { !match767 && <div className="checkoutpayment-divider__div" />}
      <aside data-testid="checkoutordersummary" className="checkoutpayment-order-summary__aside">
        <CheckoutOrderSummary
          taxRate={0}
          currentStep={currentStep}
          setStepper={setStepper}
          handleReview={handleReview}
          checkoutId={checkoutId}
          checkoutState={checkoutState}
          shipBase={checkoutState.isSPU ? 0 : checkoutState.shipBase}
          shipAdd={checkoutState.isSPU ? 0 : checkoutState.shipAdd}
          overweightState={overweightState}
          tally={updateData?.checkoutUpdate?.tally || {}}
        />
      </aside>
    </div>
  );
};

CheckoutPayment.propTypes = {
  setStepper: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  checkoutDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  setCheckout: PropTypes.func.isRequired,
  handleReview: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  checkoutError: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/require-default-props
  overweightState: PropTypes.bool,
  handleSameShip: PropTypes.func.isRequired,
};

export default CheckoutPayment;
