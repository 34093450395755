const urlGenerator = (str) => {
  /*  const IMAGE_URL = 'https://www-img.strandbooks.com'; */
  const IMAGE_URL = 'https://d2p7wwv96gt4xt.cloudfront.net';

  return str ? `${IMAGE_URL}${str}` : str;
};

export default urlGenerator;

export const generateURLs = arr => arr.map(uri => urlGenerator(uri));
