import React, {
  useState,
  // useEffect,
} from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import { createMuiTheme } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonBase from '@material-ui/core/ButtonBase';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import Collapse from '@material-ui/core/Collapse';
import capitalizeString from '../../helpers/capitalizeString';
import { excludedBindingsDictionary } from '../../helpers/bindingDictionary';
import { excludeSectionsDictionary } from '../../helpers/sectionsDictionary';
// import condDictionary from '../../helpers/condDictionary';
import WithEvent from '../withEvent/WithEvent';
import './_compFilter.scss';

export const CONDITION_LIST = gql`
query{
  conditionList{
    display
    key
  }
}
`;

export const BINDING_LIST = gql`
query{
  bindingList{
    display
    key
  }
}
`;

export const SECTION_GROUPS = gql`
query{
  sectionGroups{
    prefix
    subsections
  }
}
`;

const useStyles = makeStyles({
  drawerPaperAnchorLeft: {
    // backgroundColor: '#fbf5df',
    backgroundColor: '#fbfaf6',
    width: '260px',
  },

  drawerRoot: {
    zIndex: '13000 !important',
  },

  colorSecondaryCheckbox: {
    color: '#af272f !important',
  },

  iconButtonRoot: {
    width: '48px',
    display: 'block',
    margin: '10px 15px 0 auto',
    color: '#af272f',

    '&:hover': {
      background: 'transparent',
      color: 'd4301a',
    },
  },

  rootExpandButton: {
    color: '#af272f',
    top: '-3px',
    '&:hover': {
      color: 'd4301a',
      background: 'transparent',
    },
  },

  rootTextField: {
    width: '100%',
    maxWidth: '90px',
    margin: '10px 0',
  },

  rootButtonBase: {
    backgroundColor: '#af272f',
    color: '#ffffff',
    borderRadius: '5px',
    position: 'relative',
    left: '-10px',
    padding: '10px',
    fontSize: '15px',

    '&:hover': {
      backgroundColor: '#d4301a',
    },
  },

  closeRoot: {
    fontSize: '24px',
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      background: 'transparent',
    },
  },
});

const NumberCopyrightMin = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={4}
    />
  );
};

NumberCopyrightMin.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberCopyrightMax = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={4}
    />
  );
};

NumberCopyrightMax.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberPriceMin = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={6}
    />
  );
};

NumberPriceMin.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberPriceMax = (props) => {
  const { inputRef, onChange, ...other } = props;
  // console.log('numPropsCCNumber', props, inputRef);
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={false}
      maxLength={6}
    />
  );
};

NumberPriceMax.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CompFilter = (props) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#af272f',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        input: {
          padding: '10.5px 10px',
        },
      },
    },
  });

  const classes = useStyles();

  const {
    onClose,
    open,
    refetchFilter,
    // inputTerm,
    optionsState,
    setOptions,
    search,
  } = props;

  const [collapseState, setCollapse] = useState({
    binding: false,
    condition: false,
    section: false,
  });

  // const [optionsState, setOptions] = useState({
  //   order: {
  //     dir: 'ASC',
  //     orderBy: 'RANK',
  //   },
  //   filter: {
  //     binding: 'ANY',
  //     condition: 'ANY',
  //     copyrightYear: {
  //       maxYear: moment().year(),
  //       minYear: 1900,
  //     },
  //     at828: true,
  //     signed: false,
  //     salePriceStv: {
  //       maxStv: 10000,
  //       minStv: 1,
  //     },
  //   },
  // });

  // console.log('optionsState', optionsState);

  const handleApply = () => {
    WithEvent('FILTER_OPTION', 'Clicked Apply Filters', 'FILTER_SEARCH_APPLY', 1);
    refetchFilter({
      order: {
        dir: optionsState.order.dir,
        orderBy: optionsState.order.orderBy,
      },
      filter: {
        binding: optionsState.filter.binding,
        condition: optionsState.filter.condition,
        section: optionsState.filter.section,
        copyrightYear: {
          maxYear: parseInt(optionsState.filter.copyrightYear.maxYear, 10) ? parseInt(optionsState.filter.copyrightYear.maxYear, 10) : 0,
          minYear: parseInt(optionsState.filter.copyrightYear.minYear, 10) ? parseInt(optionsState.filter.copyrightYear.minYear, 10) : 0,
        },
        stockFilter: optionsState.filter.stockFilter,
        // at828: optionsState.filter.at828,
        signed: optionsState.filter.signed,
        salePriceStv: {
          maxStv: (parseInt(optionsState.filter.salePriceStv.maxStv, 10) * 100) ? (parseInt(optionsState.filter.salePriceStv.maxStv, 10) * 100) : 0,
          minStv: (parseInt(optionsState.filter.salePriceStv.minStv, 10) * 100) ? (parseInt(optionsState.filter.salePriceStv.minStv, 10) * 100) : 0,
        },
      },
    });
    onClose();
  };

  const handleClear = () => {
    WithEvent('FILTER_OPTION', 'Clicked Clear Filters', 'FILTER_SEARCH_CLEAR', 1);
    refetchFilter({
      order: {
        dir: 'ASC',
        orderBy: 'RANK',
      },
      filter: {
        binding: 'ANY',
        condition: 'ANY',
        copyrightYear: {
          maxYear: 0,
          minYear: 0,
        },
        stockFilter: 'IN_STOCK',
        section: null,
        // at828: optionsState.filter.at828,
        signed: false,
        salePriceStv: {
          maxStv: 0,
          minStv: 0,
        },
      },
    })
      .then(() => {
        setOptions({
          order: {
            dir: 'ASC',
            orderBy: 'RANK',
          },
          filter: {
            binding: 'ANY',
            condition: 'ANY',
            copyrightYear: {
              maxYear: 0,
              minYear: 0,
            },
            stockFilter: 'IN_STOCK',
            // at828: false,
            signed: false,
            salePriceStv: {
              maxStv: 0,
              minStv: 0,
            },
          },
        });
      })
      .then(onClose)
      .catch(err => console.error(err));
  };

  // useEffect(() => () => (
  //   setOptions({
  //     order: {
  //       dir: 'ASC',
  //       orderBy: 'RANK',
  //     },
  //     filter: {
  //       binding: 'ANY',
  //       condition: 'ANY',
  //       copyrightYear: {
  //         maxYear: moment().year().toString(),
  //         minYear: '1990',
  //       },
  //       at828: true,
  //       signed: false,
  //       salePriceStv: {
  //         maxStv: '10000',
  //         minStv: '1',
  //       },
  //     },
  //   })
  // ), []);

  const {
    error: errorConditionList,
    loading: loadingConditionList,
    data: dataConditionList,
  } = useQuery(CONDITION_LIST);

  const {
    error: errorBindingList,
    loading: loadingBindingList,
    data: dataBindingList,
  } = useQuery(BINDING_LIST);

  const {
    error: errorSectionGroups,
    loading: loadingSectionGroups,
    data: dataSectionGroups,
  } = useQuery(SECTION_GROUPS);

  if (errorConditionList || errorBindingList || errorSectionGroups) return <Redirect to={{ pathname: '/error', state: { errorConditionList, errorBindingList } }} />;

  if (loadingConditionList || loadingBindingList || loadingSectionGroups) return <span />;

  // const condTypes = ['ANY', 'NEW', 'USED_GOOD', 'USED_VERYGOOD', 'RARE_FAIR', 'RARE_FINE', 'RARE_GOOD', 'RARE_NEARFINE', 'RARE_VERYFINE', 'RARE_VERYGOOD'];
  // const bindTypes = ['ANY', 'CALENDAR', 'FLEXI', 'HARDCOVER', 'LIBRARY_BINDING', 'MASS_MARKET', 'MERCH', 'NEW_TRADE', 'NOVELTY', 'OVERSIZED_HARDCOVER', 'OVERSIZED_PAPER_BACK', 'PAPERBACK', 'PRINTS', 'PROOF', 'SCHOOL_LIBRARY_BINDING', 'TURTLEBACK_BINDING', 'VIDEO', 'VINYL', 'WRAPPERS'];
  const orderTypes = ['RANK', 'PRICE', 'TITLE', 'PUBLISHER', 'COPYRIGHT'];

  const stockTypes = [
    {
      display: 'All',
      value: 'ANY',
    },
    {
      display: 'In Stock',
      value: 'IN_STOCK',
    },
    {
      display: 'Just Arrived',
      value: 'JUST_ARRIVED',
    },
    {
      display: 'In Stock and Recently Out of Stock',
      value: 'IN_STOCK_AND_RECENT',
    },
  ];

  // console.log(dataBindingList);

  return (
    <ThemeProvider theme={theme}>
      <div className="compfilter-container__div">
        <Drawer
          open={open}
          onClose={onClose}
          anchor="left"
          classes={{ paperAnchorLeft: classes.drawerPaperAnchorLeft, root: classes.drawerRoot }}
        >
          <div className="compfilter-content__div">
            <div className="compfilter-close__div">
              <IconButton
                onClick={onClose}
                disableTouchRipple
                disableRipple
                classes={{ root: classes.closeRoot }}
                aria-label="close"
              >
                <CloseRoundedIcon />
              </IconButton>
            </div>
            <div className="compfilter-filters__div">
              {
                !search
                  && (
                    <div className="compfilter-filter__div">
                      <div className="compfilter-sort__div">
                        <div className="compfilter-header__div">
                          <h2 className="compfilter-header__h2 compfilter-header__h2--no-margin">Sort By</h2>
                          {
                            optionsState
                              && optionsState.order
                              && optionsState.order.dir === 'ASC'
                              ? (
                                <IconButton
                                  onClick={
                                    () => setOptions({
                                      ...optionsState,
                                      order: {
                                        ...optionsState.order,
                                        dir: 'DSC',
                                      },
                                    })
                                  }
                                  classes={{ root: classes.rootExpandButton }}
                                  aria-label="Ascending"
                                  disableRipple
                                  disableTouchRipple
                                >
                                  <ArrowUpwardRoundedIcon />
                                </IconButton>
                              )
                              : (
                                <IconButton
                                  onClick={
                                    () => setOptions({
                                      ...optionsState,
                                      order: {
                                        ...optionsState.order,
                                        dir: 'ASC',
                                      },
                                    })
                                  }
                                  classes={{ root: classes.rootExpandButton }}
                                  aria-label="Descending"
                                  disableRipple
                                  disableTouchRipple
                                >
                                  <ArrowDownwardRoundedIcon />
                                </IconButton>
                              )
                          }
                        </div>
                        <div className="compfilter-accent__div" />
                        {
                        optionsState
                          && optionsState.order
                          && optionsState.order.orderBy
                          && orderTypes.map(order => (
                            <div className="productlistdrawer-filter-details__div" key={order}>
                              <Radio
                                classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                value={order}
                                color="secondary"
                                inputProps={{ 'aria-label': `Order By ${order}` }}
                                checked={order === optionsState.order.orderBy}
                                onChange={() => setOptions({
                                  ...optionsState,
                                  order: { ...optionsState.order, orderBy: order },
                                })}
                              />
                              <div className="productlistdrawer-filter-text-items__div">
                                <p className="productlistdrawer-filter__p">{`${order === 'RANK' ? 'Relevance' : capitalizeString(order.replace(/_/gi, ' '))}`}</p>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  )
              }

              <div className="compfilter-filter__div">
                <div className="compfilter-price__div">
                  <div className="compfilter-header__div">
                    <h3 className="compfilter-header__h2">Price</h3>
                  </div>
                  <div className="compfilter-accent__div" />
                  <div className="compfilter-range__div">
                    {
                      optionsState
                        && optionsState.filter
                        && optionsState.filter.salePriceStv
                        && (
                          <React.Fragment>
                            <TextField
                              id="price-min"
                              label="Min Price"
                              value={optionsState.filter.salePriceStv.minStv}
                              name="minPrice"
                              onChange={e => setOptions({
                                ...optionsState,
                                filter: {
                                  ...optionsState.filter,
                                  salePriceStv: {
                                    ...optionsState.filter.salePriceStv,
                                    minStv: e.target.value,
                                  },
                                },
                              })}
                              variant="outlined"
                              classes={{ root: classes.rootTextField }}
                              InputProps={{
                                inputComponent: NumberPriceMin,
                              }}
                            />
                            <TextField
                              id="price-max"
                              label="Max Price"
                              value={optionsState.filter.salePriceStv.maxStv}
                              name="maxPrice"
                              onChange={e => setOptions({
                                ...optionsState,
                                filter: {
                                  ...optionsState.filter,
                                  salePriceStv: {
                                    ...optionsState.filter.salePriceStv,
                                    maxStv: e.target.value,
                                  },
                                },
                              })}
                              variant="outlined"
                              classes={{ root: classes.rootTextField }}
                              InputProps={{
                                inputComponent: NumberPriceMax,
                              }}
                            />
                          </React.Fragment>
                        )
                    }
                  </div>
                </div>
              </div>

              <div className="compfilter-filter__div">
                <div className="compfilter-cond__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Stock Type</h2>
                  </div>
                  <div className="compfilter-accent__div" />
                  {
                    stockTypes
                      .sort((a, b) => {
                        if (a.display > b.display) return 1;
                        if (b.display > a.display) return -1;
                        return 0;
                      })
                      .map(stock => (
                        <div className="productlistdrawer-filter-details__div" key={stock.display}>
                          <div className="productlistdrawer-filter-details__div" key={stock.display}>
                            <Radio
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={stock.value}
                              color="secondary"
                              inputProps={{ 'aria-label': `Stock Type ${stock.display}` }}
                              checked={optionsState.filter.stockFilter === stock.value}
                              onChange={() => {
                                WithEvent('FILTER_OPTION', `Clicked Stock Filter ${stock.value}`, 'FILTER_SEARCH_STOCK', 1);
                                setOptions({
                                  ...optionsState,
                                  filter: {
                                    ...optionsState.filter,
                                    stockFilter: stock.value,
                                  },
                                });
                              }}
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              <p className="productlistdrawer-filter__p">{stock.display}</p>
                            </div>
                          </div>
                        </div>
                      ))
                  }
                </div>
              </div>

              <div className="compfilter-filter__div">
                <div className="compfilter-cond__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Condition Type</h2>
                    {
                    collapseState.condition
                      ? (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => ({
                              ...prev,
                              ...{ condition: !prev.condition },
                            }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand Less"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-less-condition"
                        >
                          <ExpandLess />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => (
                              { ...prev, ...{ condition: !prev.condition } }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand More"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-more-condition"
                        >
                          <ExpandMore />
                        </IconButton>
                      )
                  }
                  </div>
                  <div className="compfilter-accent__div" />
                  <Collapse in={collapseState.condition}>
                    {
                      optionsState
                        && optionsState.filter
                        && optionsState.filter.condition
                        && dataConditionList
                        && dataConditionList.conditionList
                        && dataConditionList.conditionList.length > 0
                        && (
                          dataConditionList.conditionList
                            .sort((a, b) => {
                              if (a.key > b.key) return 1;
                              if (b.key > a.key) return -1;
                              return 0;
                            })
                            .map(cond => (
                              <div className="productlistdrawer-filter-details__div" key={cond.key}>
                                <div className="productlistdrawer-filter-details__div">
                                  <Radio
                                    classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                    value={cond.key}
                                    color="secondary"
                                    inputProps={{ 'aria-label': `Condition ${cond.display}` }}
                                    checked={optionsState.filter.condition === cond.key}
                                    onChange={() => {
                                      WithEvent('FILTER_OPTION', `Clicked Condition Filter ${cond.key}`, 'FILTER_SEARCH_CONDITION', 1);
                                      setOptions({
                                        ...optionsState,
                                        filter: {
                                          ...optionsState.filter,
                                          condition: cond.key,
                                        },
                                      });
                                    }}
                                  />
                                  <div className="productlistdrawer-filter-text-items__div">
                                    <p className="productlistdrawer-filter__p">{cond.display}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                        )
                    }
                  </Collapse>
                </div>
              </div>

              <div className="compfilter-filter__div">
                <div className="compfilter-bind__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Binding Type</h2>
                    {
                    collapseState.binding
                      ? (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => ({ ...prev, ...{ binding: !prev.binding } }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand Less"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-less-binding"
                        >
                          <ExpandLess />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => (
                              { ...prev, ...{ binding: !prev.binding } }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand More"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-more-binding"
                        >
                          <ExpandMore />
                        </IconButton>
                      )
                  }
                  </div>
                  <div className="compfilter-accent__div" />
                  <Collapse in={collapseState.binding} collapsedHeight={80}>
                    {
                      optionsState
                      && optionsState.filter
                      && optionsState.filter.binding
                      && dataBindingList
                      && dataBindingList.bindingList
                      && dataBindingList.bindingList.length > 0
                      && dataBindingList.bindingList
                        .filter(binding => binding.key === 'HARDCOVER').length > 0
                      && dataBindingList.bindingList
                        .filter(binding => binding.key === 'HARDCOVER')
                        .map(binding => (
                          <div className="productlistdrawer-filter-details__div" key={binding.key}>
                            <Radio
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={binding.key}
                              color="secondary"
                              inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                              checked={binding.key === optionsState.filter.binding}
                              onChange={() => {
                                WithEvent('FILTER_OPTION', `Clicked Binding Filter ${binding.key}`, 'FILTER_SEARCH_BINDING', 1);
                                setOptions({
                                  ...optionsState,
                                  filter: { ...optionsState.filter, binding: binding.key },
                                });
                              }}
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              {/* <p className="productlistdrawer-filter__p">{`${binding === 'PaperBack' ? 'Paperback' : capitalizeString(binding.replace(/_/gi, ' '))}`}</p> */}
                              <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                            </div>
                          </div>
                        ))
                    }
                    {
                      optionsState
                      && optionsState.filter
                      && optionsState.filter.binding
                      && dataBindingList
                      && dataBindingList.bindingList
                      && dataBindingList.bindingList.length > 0
                      && dataBindingList.bindingList
                        .filter(binding => binding.key === 'PAPERBACK').length > 0
                      && dataBindingList.bindingList
                        .filter(binding => binding.key === 'PAPERBACK')
                        .map(binding => (
                          <div className="productlistdrawer-filter-details__div" key={binding.key}>
                            <Radio
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={binding.key}
                              color="secondary"
                              inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                              checked={binding.key === optionsState.filter.binding}
                              onChange={() => {
                                WithEvent('FILTER_OPTION', `Clicked Binding Filter ${binding.key}`, 'FILTER_SEARCH_BINDING', 1);
                                setOptions({
                                  ...optionsState,
                                  filter: { ...optionsState.filter, binding: binding.key },
                                });
                              }}
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              {/* <p className="productlistdrawer-filter__p">{`${binding === 'PaperBack' ? 'Paperback' : capitalizeString(binding.replace(/_/gi, ' '))}`}</p> */}
                              <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                            </div>
                          </div>
                        ))
                    }
                    {
                    optionsState
                      && optionsState.filter
                      && optionsState.filter.binding
                      && dataBindingList
                      && dataBindingList.bindingList
                      && dataBindingList.bindingList.length > 0
                      && dataBindingList.bindingList
                        .filter(binding => excludedBindingsDictionary.indexOf(binding.key) < 0)
                        .filter(binding => binding.key !== 'PAPERBACK')
                        .filter(binding => binding.key !== 'HARDCOVER')
                        .sort((a, b) => {
                          if (a.key > b.key) return 1;
                          if (b.key > a.key) return -1;
                          return 0;
                        })
                        .map(binding => (
                          <div className="productlistdrawer-filter-details__div" key={binding.key}>
                            <Radio
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={binding.key}
                              color="secondary"
                              inputProps={{ 'aria-label': `Book Binding ${binding.display}` }}
                              checked={binding.key === optionsState.filter.binding}
                              onChange={() => {
                                WithEvent('FILTER_OPTION', `Clicked Binding Filter ${binding.key}`, 'FILTER_SEARCH_BINDING', 1);
                                setOptions({
                                  ...optionsState,
                                  filter: { ...optionsState.filter, binding: binding.key },
                                });
                              }
                            }
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              {/* <p className="productlistdrawer-filter__p">{`${binding === 'PaperBack' ? 'Paperback' : capitalizeString(binding.replace(/_/gi, ' '))}`}</p> */}
                              <p className="productlistdrawer-filter__p">{`${binding.display === 'PaperBack' ? 'Paperback' : binding.display}`}</p>
                            </div>
                          </div>
                        ))
                    }
                  </Collapse>
                </div>
              </div>

              {/* <div className="compfilter-filter__div">
                <div className="compfilter-cond__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Section Type</h2>
                    {
                    collapseState.section
                      ? (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => ({
                              ...prev,
                              ...{ section: !prev.section },
                            }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand Less"
                          disableRipple
                          disableTouchRipple
                        >
                          <ExpandLess />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => (
                              { ...prev, ...{ section: !prev.section } }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand More"
                          disableRipple
                          disableTouchRipple
                        >
                          <ExpandMore />
                        </IconButton>
                      )
                  }
                  </div>
                  <div className="compfilter-accent__div" />
                  <Collapse in={collapseState.section}>
                    {
                      optionsState
                        && optionsState.filter
                        && dataSectionList
                        && dataSectionList.sectionList
                        && dataSectionList.sectionList.length > 0
                        && (
                          dataSectionList.sectionList
                            .filter(sec => excludeSectionsDictionary.indexOf(sec) < 0)
                            .sort((a, b) => {
                              if (a > b) return 1;
                              if (b > a) return -1;
                              return 0;
                            })
                            .map(sec => (
                              <div className="productlistdrawer-filter-details__div" key={sec}>
                                <div className="productlistdrawer-filter-details__div">
                                  <Radio
                                    classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                    value={sec}
                                    color="secondary"
                                    inputProps={{ 'aria-label': `Section ${sec}` }}
                                    checked={optionsState.filter.section === sec}
                                    onChange={() => setOptions({
                                      ...optionsState,
                                      filter: {
                                        ...optionsState.filter,
                                        section: sec,
                                      },
                                    })}
                                  />
                                  <div className="productlistdrawer-filter-text-items__div">
                                    <p className="productlistdrawer-filter__p">{capitalizeString(sec)}</p>
                                  </div>
                                </div>
                              </div>
                            ))
                        )
                    }
                  </Collapse>
                </div>
              </div> */}

              <div className="compfilter-filter__div">
                <div className="compfilter-cond__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Section Type</h2>
                    {
                    collapseState.section
                      ? (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => ({
                              ...prev,
                              ...{ section: !prev.section },
                            }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand Less"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-less-section"
                        >
                          <ExpandLess />
                        </IconButton>
                      )
                      : (
                        <IconButton
                          onClick={
                            () => setCollapse(prev => (
                              { ...prev, ...{ section: !prev.section } }))
                          }
                          classes={{ root: classes.rootExpandButton }}
                          aria-label="Expand More"
                          disableRipple
                          disableTouchRipple
                          data-testid="expand-more-section"
                        >
                          <ExpandMore />
                        </IconButton>
                      )
                  }
                  </div>
                  <div className="compfilter-accent__div" />
                  <Collapse in={collapseState.section}>
                    {
                      optionsState
                        && optionsState.filter
                        && dataSectionGroups
                        && dataSectionGroups.sectionGroups
                        && dataSectionGroups.sectionGroups.length > 0
                        && (
                          dataSectionGroups.sectionGroups
                            .filter(sec => excludeSectionsDictionary.indexOf(sec.prefix) < 0)
                            .sort((a, b) => {
                              if (a.prefix > b.prefix) return 1;
                              if (b.prefix > a.prefix) return -1;
                              return 0;
                            })
                            .map((sec) => {
                              if (sec.subsections && sec.subsections.length > 0) {
                                return (
                                  <div className="productlistdrawer-filter-details-subsection-container__div" key={sec.prefix}>
                                    <p className="productlistdrawer-filter__p productlistdrawer-filter__p--strong">{capitalizeString(sec.prefix)}</p>
                                    {
                                      sec.subsections
                                        .sort((a, b) => {
                                          if (a > b) return 1;
                                          if (b > a) return -1;
                                          return 0;
                                        })
                                        .map(subsec => (
                                          <div key={subsec} className="productlistdrawer-filter-details-subsection__div">
                                            <Radio
                                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                              value={subsec}
                                              color="secondary"
                                              inputProps={{ 'aria-label': `Section ${subsec}` }}
                                              checked={optionsState.filter.section === subsec}
                                              onChange={() => {
                                                WithEvent('FILTER_OPTION', `Clicked Section Filter ${subsec}`, 'FILTER_SEARCH_SECTION', 1);
                                                setOptions({
                                                  ...optionsState,
                                                  filter: {
                                                    ...optionsState.filter,
                                                    section: subsec,
                                                  },
                                                });
                                              }}
                                            />
                                            <div className="productlistdrawer-filter-text-items__div">
                                              <p className="productlistdrawer-filter__p">{capitalizeString(subsec.replace((new RegExp(`${sec.prefix}-`, 'gi')), ''))}</p>
                                            </div>
                                          </div>
                                        ))
                                    }
                                  </div>
                                );
                              }

                              return (
                                <div className="productlistdrawer-filter-details__div" key={sec.prefix}>
                                  <div className="productlistdrawer-filter-details__div">
                                    <Radio
                                      classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                      value={sec.prefix}
                                      color="secondary"
                                      inputProps={{ 'aria-label': `Section ${sec.prefix}` }}
                                      checked={optionsState.filter.section === sec.prefix}
                                      onChange={() => {
                                        WithEvent('FILTER_OPTION', `Clicked Section Filter ${sec.prefix}`, 'FILTER_SEARCH_SECTION', 1);
                                        setOptions({
                                          ...optionsState,
                                          filter: {
                                            ...optionsState.filter,
                                            section: sec.prefix,
                                          },
                                        });
                                      }}
                                    />
                                    <div className="productlistdrawer-filter-text-items__div">
                                      <p className="productlistdrawer-filter__p">{capitalizeString(sec.prefix)}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        )
                    }
                  </Collapse>
                </div>
              </div>

              <div className="compfilter-filter__div">
                <div className="compfilter-signed__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Signed</h2>
                  </div>
                  <div className="compfilter-accent__div" />
                  <div className="compfilter-checkbox__div">
                    {
                      optionsState
                        && optionsState.filter
                        && (
                          <React.Fragment>
                            <Checkbox
                              inputProps={{ 'aria-label': 'signed' }}
                              checked={optionsState.filter.signed}
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              onClick={() => {
                                WithEvent('FILTER_OPTION', 'Clicked Signed Filter', 'FILTER_SEARCH_SIGNED', 1);
                                setOptions({
                                  ...optionsState,
                                  filter: {
                                    ...optionsState.filter,
                                    signed: !optionsState.filter.signed,
                                  },
                                });
                              }}
                            />
                            <p className="compfilter-details__p">Signed</p>
                          </React.Fragment>
                        )
                    }
                  </div>
                </div>
              </div>

              <div className="compfilter-filter__div">
                <div className="compfilter-copyright__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">Copyright</h2>
                  </div>
                  <div className="compfilter-accent__div" />
                  <div className="compfilter-range__div">
                    {
                      optionsState
                        && optionsState.filter
                        && optionsState.filter.copyrightYear
                        && (
                          <React.Fragment>
                            <TextField
                              id="copyright-min"
                              label="Min Year"
                              value={optionsState.filter.copyrightYear.minYear}
                              name="minYear"
                              onChange={e => setOptions({
                                ...optionsState,
                                filter: {
                                  ...optionsState.filter,
                                  copyrightYear: {
                                    ...optionsState.filter.copyrightYear,
                                    minYear: e.target.value,
                                  },
                                },
                              })}
                              variant="outlined"
                              classes={{ root: classes.rootTextField }}
                              InputProps={{
                                inputComponent: NumberCopyrightMin,
                              }}
                            />
                            <TextField
                              id="copyright-max"
                              label="Max Year"
                              value={optionsState.filter.copyrightYear.maxYear}
                              name="maxYear"
                              onChange={e => setOptions({
                                ...optionsState,
                                filter: {
                                  ...optionsState.filter,
                                  copyrightYear: {
                                    ...optionsState.filter.copyrightYear,
                                    maxYear: e.target.value,
                                  },
                                },
                              })}
                              variant="outlined"
                              classes={{ root: classes.rootTextField }}
                              InputProps={{
                                inputComponent: NumberCopyrightMax,
                              }}
                            />
                          </React.Fragment>
                        )
                    }
                  </div>
                </div>
              </div>

              {/* <div className="compfilter-filter__div">
                <div className="compfilter-store__div">
                  <div className="compfilter-header__div">
                    <h2 className="compfilter-header__h2">In the Main Store</h2>
                  </div>
                  <div className="compfilter-accent__div" />
                  <div className="compfilter-checkbox__div">
                    {
                      optionsState
                        && optionsState.filter
                        && (
                          <React.Fragment>
                            <Checkbox
                              inputProps={{ 'aria-label': 'main store' }}
                              checked={optionsState.filter.at828}
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              onClick={() => setOptions({
                                ...optionsState,
                                filter: {
                                  ...optionsState.filter,
                                  at828: !optionsState.filter.at828,
                                },
                              })}
                            />
                            <p className="compfilter-details__p">Main Store</p>
                          </React.Fragment>
                        )
                    }
                  </div>
                </div>
              </div> */}


            </div>
            <div className="compfilter-apply__div">
              <ButtonBase
                classes={{ root: classes.rootButtonBase }}
                onClick={handleApply}
              >
                Apply Filters
              </ButtonBase>
            </div>

            <div className="compfilter-apply__div">
              <ButtonBase
                classes={{ root: classes.rootButtonBase }}
                onClick={handleClear}
              >
                Clear Filters
              </ButtonBase>
            </div>
          </div>
        </Drawer>
      </div>
    </ThemeProvider>
  );
};

CompFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchFilter: PropTypes.func.isRequired,
  // inputTerm: PropTypes.string.isRequired,
  optionsState: PropTypes.objectOf(PropTypes.any).isRequired,
  setOptions: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
};

export default CompFilter;
