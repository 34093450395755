import { deletedMetaList } from '../../helpers/metaListDictionary';

import Home from '../home/Home';
import SingleProductPage from '../singleProductPage/UpdatedSingleProductPage';
import PageNotFound from '../pageNotFound/PageNotFound';
import Privacy from '../privacy/Privacy';
import ReturnPolicy from '../returnPolicy/ReturnPolicy';
import LibraryServices from '../libraryServices/LibraryServices';
import HoursLocations from '../hoursLocations/HoursLocations';
import Rare from '../rare/Rare';
import Giveaway from '../giveaway/Giveaway';
// import RareTours from '../rare/RareTours';
// import StaffPicks from '../staffPicks/StaffPicks';
// import GiftsApparel from '../giftsApparel/GiftsApparel';
import Apparel from '../giftsApparel/Apparel';
import BooksMedia from '../booksMedia/BooksMedia';
import BooksMediaLP from '../booksMedia/BooksMediaLP';
import BooksByTheFoot from '../booksByTheFoot/BooksByTheFoot';
// import SellBooksMore from '../sellBooksMore/SellBooksMore';
import Cart from '../cart/Cart';
import SellYourBooks from '../sellYourBooks/SellYourBooks';
// import SameDayDelivery from '../sameDayDelivery/SameDayDelivery';
import Events from '../events/Events';
import Event from '../events/Event';
import About from '../about/About';
import Faq from '../faq/Faq';
import BookHookUp from '../bookHookUp/BookHookUp';
import BhuFAQ from '../bookHookUp/BhuFAQ';
import BhuSpoilers from '../bookHookUp/BhuSpoilers';
import BannedBooksWeeks from '../bannedBooksWeek/bannedBooksWeek';
import BuyoutPrice from '../buyoutPrice/BuyoutPrice';
import SearchResultsHook from '../searchResults/SearchResultsHook';
import ProductListHook from '../productList/ProductListHook';
// import Calendar from '../calendar/Calendar';
// import Loading from '../loading/Loading';
import Error from '../error/Error';
import EmailVerify from '../emailVerify/EmailVerify';
import RentRare from '../rentRare/RentRare';
import ContactUs from '../contactUs/ContactUs';
import ProductSectionLP from '../productSectionList/ProductSectionLP';
import ProductSectionSub from '../productSectionList/ProductSectionSub';
import ProductSectionList from '../productSectionList/ProductSectionList';
import RecoverAccount from '../recoverAccount/RecoverAccount';
import BBTFCollections from '../booksByTheFoot/BBTFCollections';
import BBTFFaq from '../booksByTheFoot/BBTFFaq';
import BBTFRentals from '../booksByTheFoot/BBTFRentals';
import BBTFGallery from '../booksByTheFoot/BBTFGallery';
import BBTFCategory from '../booksByTheFoot/BBTFCategory';
import StrandCast from '../strandcast/StrandCast';
import StrandCastEp from '../strandcast/StrandCastEp';
import AuthorsBookshelves from '../authorsBookshelves/AuthorsBookshelves';
// import Vinyl from '../vinyl/Vinyl';
import SingleAuthorsBookshelves from '../authorsBookshelves/SingleAuthorsBookshelves';
// import NavbarDesktopRevised from '../navbar/NavbarDesktopRevised';
import MyAccount from '../myAccount/MyAccount';
import BBTFTC from '../booksByTheFoot/BBTFTC';
import CheckoutConfirmationPage from '../checkout/CheckoutConfirmationPage';
import ProductLocationList from '../productLocation/ProductLocationList';
import ProductMetaList from '../productMetaList/ProductMetaList';
// import Admin from '../admin/Admin';
import GiftCard from '../giftCard/GiftCard';
import LandingPage from '../landingPage/LandingPage';
// import EventDraft from '../events/EventDraft';
import Preorders from '../landingPage/Preorders';
import Login from '../loginSignup/Login';
import Signup from '../loginSignup/Signup';
import ForgotPW from '../loginSignup/ForgotPW';
import BookOfTheMonth from '../bookOfTheMonth/BookOfTheMonth';
import Readsolutions2021 from '../landingPage/Readsolutions2021';
import Readsolutions2022 from '../landingPage/Readsolutions2022';
import SubmissionsContainer from '../submissionsPage/SubmissionsContainer';
// import SubmissionsPage from '../submissionsPage/SubmissionsPage';
import SubmissionsTOU from '../submissionsPage/SubmissionsTOU';
import SubmissionsClosed from '../submissionsPage/SubmissionsClosed';
import Jobs from '../jobs/Jobs';
import BlogNotFound from '../pageNotFound/BlogNotFound';
import MetaNotFound from '../pageNotFound/MetaNotFound';
import PromoNotFound from '../pageNotFound/PromoNotFound';
import Pride2021 from '../landingPage/Pride2021';
import StrandDay2021 from '../landingPage/StrandDay2021';
import StrandUniversity2021 from '../landingPage/StrandUniversity2021';
import PickOfTheMonth2021 from '../termsOfUse/PickOfTheMonth2021';
import Spooktober2021 from '../landingPage/Spooktober2021';
import GiftGuide2021 from '../landingPage/GiftGuide2021';
import GiftGuide2022 from '../landingPage/GiftGuide2022';
import TwelveDaysOfHoliday2021 from '../landingPage/TwelveDaysOfHoliday2021';
import IndieDay2022 from '../landingPage/IndieDay2022';
import BooksThatShaped2021 from '../landingPage/BooksThatShaped2021';
import Memorial from '../memorial/Memorial';
import AccessibilityStatement from '../accessibilityStatement/accessibilityStatement';
import HolidayShop from '../holidayShop/holidayShop';

const routesData = [
  {
    type: 'component', exact: true, path: '/events', Component: Events,
  },
  {
    type: 'component', exact: true, path: '/about', Component: About,
  },
  {
    type: 'component', exact: true, path: '/hours-locations', Component: HoursLocations,
  },
  {
    type: 'component', exact: true, path: '/accessibility-statement', Component: AccessibilityStatement,
  },
  {
    type: 'component', exact: true, path: '/holiday-shop-2023', Component: HolidayShop,
  },
  /* Events Routes */
  {
    type: 'component', exact: true, path: '/events/:event', Component: Event,
  },
  {
    type: 'component', exact: true, path: '/preorders', Component: Preorders,
  },

  /* Main Nav Routes */
  {
    type: 'component', exact: true, path: '/', Component: Home,
  },
  {
    type: 'redirect', exact: true, path: '/home', to: '/',
  },
  {
    type: 'component', exact: true, path: '/rare', Component: Rare,
  },
  {
    type: 'component', exact: true, path: '/books-media', Component: BooksMediaLP,
  },
  {
    type: 'component', exact: true, path: '/books-media/:floor(first-floor|second-floor|underground)', Component: BooksMedia,
  },
  {
    type: 'component', exact: true, path: '/apparel', Component: Apparel,
  },
  {
    type: 'component', exact: true, path: '/gifts-apparel/gift-cards', Component: GiftCard,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot', Component: BooksByTheFoot,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/about', Component: BooksByTheFoot,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/collections', Component: BBTFCollections,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/faq', Component: BBTFFaq,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/rentals', Component: BBTFRentals,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/gallery', Component: BBTFGallery,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/:category(color|style|subject)', Component: BBTFCategory,
  },
  {
    type: 'component', exact: true, path: '/books-by-the-foot/terms-conditions', Component: BBTFTC,
  },
  {
    type: 'component', exact: true, path: '/checkout/confirmation', Component: CheckoutConfirmationPage,
  },
  {
    type: 'component', exact: true, path: '/cart', Component: Cart,
  },
  {
    type: 'component', exact: true, path: '/myaccount', Component: MyAccount,
  },

  /* Books & Media */
  {
    type: 'component', exact: true, path: '/books-media/bookhookup', Component: BookHookUp,
  },
  {
    type: 'redirect', exact: true, path: '/strand-subscriptions', to: '/books-media/bookhookup',
  },
  {
    type: 'redirect', exact: true, path: '/podsaveamerica', to: '/campaign/campaign74?title=battling_the_big_lie',
  },
  {
    type: 'component', exact: true, path: '/books-media/bookhookup/faq', Component: BhuFAQ,
  },
  {
    type: 'component', exact: true, path: '/books-media/bookhookup/spoilers', Component: BhuSpoilers,
  },
  {
    type: 'component', exact: true, path: '/books-media/bannedBooksWeek', Component: BannedBooksWeeks,
  },
  {
    type: 'component', exact: true, path: '/books-media/authors-bookshelf', Component: AuthorsBookshelves,
  },
  {
    type: 'component', exact: true, path: '/books-media/authors-bookshelf/:author', Component: SingleAuthorsBookshelves,
  },

  /* Sell Your Books & More */
  {
    type: 'component', exact: true, path: '/more/library-services', Component: LibraryServices,
  },
  {
    type: 'component', exact: true, path: '/more/sell-books', Component: SellYourBooks,
  },
  {
    type: 'component', exact: true, path: '/sellyourbooks', Component: SellYourBooks,
  },
  {
    type: 'component', exact: true, path: '/more/rent-the-rare-book-room', Component: RentRare,
  },
  {
    type: 'component', exact: true, path: '/more/strandcast', Component: StrandCast,
  },
  {
    type: 'component', exact: true, path: '/more/strandcast/:ep', Component: StrandCastEp,
  },

  /* ProductList Routes */
  {
    type: 'component', exact: true, path: '/productlist/:productList', Component: ProductListHook,
  },
  {
    type: 'component', exact: true, path: '/product/:ean', Component: SingleProductPage,
  },

  /* ProductBySection Routes */
  {
    type: 'component', exact: true, path: '/browse-sections', Component: ProductSectionLP,
  },
  {
    type: 'component', exact: true, path: '/browse-sections/:prefix', Component: ProductSectionSub,
  },
  {
    type: 'component', exact: true, path: '/productsection/:section', Component: ProductSectionList,
  },

  /* ProductByLocation Routes */
  {
    type: 'component', exact: true, path: '/productlocation/:location', Component: ProductLocationList,
  },

  /* ProductByMetaList Routes */
  {
    type: 'component', exact: true, path: `/metalist/:location(${Object.keys(deletedMetaList).join('|')})`, Component: MetaNotFound,
  },
  {
    type: 'component', exact: true, path: '/metalist/:location', Component: ProductMetaList,
  },

  /* Static Pages */
  {
    type: 'component', exact: true, path: '/privacy', Component: Privacy,
  },
  {
    type: 'component', exact: true, path: '/giveaway-terms', Component: Giveaway,
  },
  {
    type: 'component', exact: true, path: '/return-policy', Component: ReturnPolicy,
  },
  {
    type: 'component', exact: true, path: '/return-policy', Component: ReturnPolicy,
  },
  {
    type: 'component', exact: true, path: '/help/faq', Component: Faq,
  },
  {
    type: 'component', exact: true, path: '/work-at-strand', Component: Jobs,
  },
  {
    type: 'component', exact: true, path: '/buyout-price', Component: BuyoutPrice,
  },
  {
    type: 'component', exact: true, path: '/contact', Component: ContactUs,
  },
  {
    type: 'component', exact: false, path: '/strand-blog', Component: BlogNotFound,
  },
  {
    type: 'component', exact: false, path: '/stacked', Component: BlogNotFound,
  },

  /* Landing Pages/Preorder Pages/Campaign Pages */
  {
    type: 'component', exact: true, path: '/campaign/:campaignId', Component: LandingPage,
  },
  {
    type: 'redirect', exact: true, path: '/submissions/contests/sub01', to: '/submissions/contests/mystrandtote',
  },
  {
    type: 'component', exact: true, path: '/submissions/contests/:submissionNo', Component: SubmissionsContainer,
  },
  {
    type: 'redirect', exact: true, path: '/submissions/terms/sub01', to: '/submissions/terms/mystrandtote',
  },
  {
    type: 'component', exact: true, path: '/submissions/terms/:touNo', Component: SubmissionsTOU,
  },
  {
    type: 'component', exact: true, path: '/submissions/closed', Component: SubmissionsClosed,
  },
  {
    type: 'redirect', exact: true, path: '/robin-hood-strand', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/lwv-strand-2020', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/hispanic-heritage-month-2020', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/holiday-gift-guide-2020', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/holiday-gift-guide-2020/:category(books-for-adults|food-and-drink|art-and-photo|young-adult|gifts-for-kids|gifts-under-40|gifts-under-20)', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/index.cfm', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/books-by-the-foot-services', to: '/books-by-the-foot',
  },

  /* Gloria Steinem Redirects */
  {
    type: 'redirect', exact: true, path: '/new-arrivals/my-life-on-the-road-0679456201', to: '/product/9780679456209?title=my_life_on_the_road',
  },
  {
    type: 'redirect', exact: true, path: '/self-help/revolution-from-within-a-book-of-self-esteem/_/searchString/revolution from within', to: '/product/9780316706360?title=revolution_from_within_a_book_of_selfesteem',
  },
  {
    type: 'redirect', exact: true, path: '/women-s-studies/moving-beyond-words-age-rage-sex-power-money-muscles-breaking-boundaries-of-gender/_/searchString/moving beyond words', to: '/product/9780671510527?title=moving_beyond_words_age_rage_sex_power_money_muscles_breaking_boundaries_of_gender',
  },
  {
    type: 'redirect', exact: true, path: '/women-s-studies/outrageous-acts-and-everyday-rebellions-0805042024/_/searchString/outrageous acts', to: '/product/9781250204868?title=outrageous_acts_and_everyday_rebellions',
  },

  /* Mary Karr Redirect */
  {
    type: 'redirect', exact: true, path: '/event/terrance-hayes', to: '/',
  },
  {
    type: 'component', exact: true, path: '/pick-of-the-month', Component: BookOfTheMonth,
  },
  {
    type: 'component', exact: true, path: '/book-of-the-month', Component: BookOfTheMonth,
  },
  {
    type: 'component', exact: true, path: '/readsolutions_2021', Component: Readsolutions2021,
  },
  {
    type: 'component', exact: true, path: '/readsolutions_2022', Component: Readsolutions2022,
  },
  {
    type: 'component', exact: true, path: '/new_year_sale_2021', Component: PromoNotFound,
  },
  {
    type: 'component', exact: true, path: '/indie-bookstore-day-2021', Component: PromoNotFound,
  },
  {
    type: 'component', exact: true, path: '/pride_2021', Component: Pride2021,
  },
  {
    type: 'component', exact: true, path: '/strand_day_2021', Component: StrandDay2021,
  },
  {
    type: 'component', exact: true, path: '/strand_university_2021', Component: StrandUniversity2021,
  },
  {
    type: 'component', exact: true, path: '/books_that_shaped_2021', Component: BooksThatShaped2021,
  },
  {
    type: 'component', exact: true, path: '/spooktober', Component: Spooktober2021,
  },
  {
    type: 'component', exact: true, path: '/holiday-gift-guide-2021', Component: GiftGuide2021,
  },
  {
    type: 'component', exact: true, path: '/twelve-days-of-holiday-2021', Component: TwelveDaysOfHoliday2021,
  },
  {
    type: 'component', exact: true, path: '/indie-day-2022', Component: IndieDay2022,
  },
  {
    type: 'component', exact: true, path: '/holiday-gift-guide_2022', Component: GiftGuide2022,
  },

  /* Email Verification and Recovery */
  {
    type: 'component', exact: true, path: '/verify-email/:verifyKey', Component: EmailVerify,
  },
  {
    type: 'component', exact: false, path: '/password-reset', Component: RecoverAccount,
  },
  {
    type: 'component', exact: true, path: '/search-results', Component: SearchResultsHook,
  },

  {
    type: 'component', exact: true, path: '/page-not-found', Component: PageNotFound,
  },

  /* Testing Routes */
  {
    type: 'component', exact: true, path: '/login', Component: Login,
  },
  {
    type: 'component', exact: true, path: '/signup', Component: Signup,
  },
  {
    type: 'component', exact: true, path: '/forgot-password', Component: ForgotPW,
  },
  {
    type: 'component', exact: true, path: '/about-strand-books', Component: About,
  },
  {
    type: 'component', exact: true, path: '/terms-of-use/potm-signup-giveaway-2021', Component: PickOfTheMonth2021,
  },
  {
    type: 'component', exact: true, path: '/benmcfall-memorial', Component: Memorial,
  },

  /* Legacy Links Rerouted to Home */
  {
    type: 'redirect', exact: true, path: '/indie-bookstore-day', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/posters/poster-1938-strand-book-store', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/unsubscribe/post', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/profile/send-email', to: '/',
  },
  {
    type: 'redirect', exact: true, path: '/strand-history', to: '/about',
  },
  {
    type: 'component', exact: true, path: '/error', Component: Error,
  },
];

export default routesData;
