import React, { useState, forwardRef } from 'react';
import gql from 'graphql-tag';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import './_myAccountOrders.scss';
// import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';

// Icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Loading from '../loading/Loading';
import OrderDetail from './OrderDetail';
import convertISO from '../../helpers/convertISO';

// pattern from documentation; Linting rules are will be ignored

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const theme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
  props: {
    MuiIconButton: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
});

const SHOP_ORDER_LIST = gql`
query {shopOrderList {
  id
  customerPo
  buyerEmail
  customerName
  trackingNumber
  orderLine {
    id
    gtin
    description
    qty
    trackingNumber
    status
    priceStv
    taxStv
    extendedStv
    shippingStv
  }
  shipAddr {
    recipient
    address
    city
    state
    postalCode
    country
  }
  billAddr {
    recipient
    address
    city
    state
    postalCode
    country
  }
  checkout {
    commitAt
    deliveryInstructions
    tally {
      itemCount
      productStv
      shippingStv
      taxRate
      taxStv
      totalStv
    }
  }
  orderNumber { orderString }
  shipMethod
  status
  statusUpdateAt
  itemCount
  totalStv
}}
`;

const MyAccountOrders = () => {
  const {
    data: orderData,
    error: orderError,
    loading: orderLoading,
  } = useQuery(SHOP_ORDER_LIST, { fetchPolicy: 'no-cache' });

  // const {
  //   compSwitchState: stateDialog,
  //   handleCompOpen: handleOpenDialog,
  //   handleCompClose: handleCloseDialog,
  // } = useOpenCloseComponent();

  const [state] = useState({
    columns: [
      { title: 'Order #', field: 'orderString', defaultSort: 'desc' },
      { title: 'Checkout ID', field: 'id', hidden: true },
      { title: 'Order Date', field: 'commitAt' },
      { title: 'Ship Method', field: 'shipMethod' },
      { title: 'Status', field: 'status' },
      { title: 'Items', field: 'itemCount' },
    ],
    selected: {},
  });

  if (orderError) return <Redirect to={{ pathname: '/error', state: { orderError } }} />;

  if (orderLoading) return <Loading />;

  // returns array of order data for table
  const rowOrderData = () => (
    orderData.shopOrderList ? orderData.shopOrderList.map(order => (
      // do data transforms here
      {
        ...order,
        orderString: order?.orderNumber?.orderString,
        itemCount: order?.checkout?.tally?.itemCount,
        commitAt: convertISO(order?.checkout?.commitAt),
      }
    )) : []
  );

  // const rowOrderDetailData = (rowData) => {
  //   return rowData;
  // };

  return (
    <div className="myaccountorders-container__div">
      <div className="myaccountorders-header__div">
        <h2 className="myaccountorders-header__h2">My Orders</h2>
        <div className="myaccountorders-dash__div" />
      </div>
      <ThemeProvider theme={theme}>
        <main className="myaccountorders-content__main">
          <div className="myaccountorders-content__div" id="orders">
            <div className="myaccountorders-orders__div">
              <MaterialTable
                title="Your Orders"
                columns={state.columns}
                options={{
                  selection: false,
                  sorting: true,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  pageSize: 20,
                }}
                data={rowOrderData()}
                icons={tableIcons}
                detailPanel={[{
                  icon: () => (
                    <p className="myaccountorders-text-icon__p">
                      Order
                      <br />
                      Details
                    </p>
                  ),
                  openIcon: () => (
                    <p className="myaccountorders-text-icon__p">
                      Order
                      <br />
                      Details
                    </p>
                  ),
                  render: rowData => <OrderDetail orderData={rowData} />,
                },
                ]}
              />
            </div>
          </div>
        </main>
      </ThemeProvider>
    </div>
  );
};

export default MyAccountOrders;
