import urlGenerator from '../../helpers/urlGenerator';

const twelveDaysOfHoliday2021Data = [
  {
    id: 'day1',
    title: 'Best Fiction 2021',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_best_of_fiction.png'),
    url: '/metalist/twelve_days_best_fiction_2021',
  },
  {
    id: 'day2',
    title: 'Pick of the Month',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_pick_of_the_month.png'),
    url: '/pick-of-the-month',
  },
  {
    id: 'day3',
    title: 'Strand Apparel',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_strand_apparel.png'),
    url: '/metalist/twelve_days_apparel',
  },
  {
    id: 'day4',
    title: 'Cooking & Cocktails',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_cooking_and_cocktails.jpg'),
    url: '/metalist/twelve_days_cooking_and_cocktails',
  },
  {
    id: 'day5',
    title: 'Hats, Caps, & Beanies',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_hats.jpg'),
    url: '/metalist/twelve_days_hats',
  },
  {
    id: 'day6',
    title: 'Signed by the Author',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_signed_copies.jpg'),
    url: '/metalist/twelve_days_signed_copies',
  },
  {
    id: 'day7',
    title: 'Biographies',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_biographies.jpg'),
    url: '/metalist/twelve_days_biographies',
  },
  {
    id: 'day8',
    title: 'Totes & Pouches',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_totes.jpg'),
    url: '/metalist/twelve_days_totes',
  },
  {
    id: 'day9',
    title: 'Award Winners',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_award_winners.png'),
    url: '/metalist/twelve_days_award_winners',
  },
  {
    id: 'day10',
    title: 'Mugs & Drinkware',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_mugs_and_drinkware.png'),
    url: '/metalist/twelve_days_mugs_and_drinkware',
  },
  {
    id: 'day11',
    title: 'Bestsellers',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_bestsellers.png'),
    url: '/metalist/twelve_days_bestsellers',
  },
  {
    id: 'day12',
    title: 'Strand Gift Cards',
    imgUrl: urlGenerator('/S/pages/twelve_days_2021_gift_cards.png'),
    url: '/gifts-apparel/gift-cards',
  },
];

export default twelveDaysOfHoliday2021Data;
