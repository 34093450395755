import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import BBTFGalleryDialog from './BBTFGalleryDialog';
import bbtfList from './bbtfList';
import use4Cite from '../../hooks/use4Cite';
import './_bbtfGallery.scss';

const BBTFGallery = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const [dialogState, setDialog] = useState(bbtfList.bbtfGallery.reduce((acc, cv, ci) => {
    if (ci >= 43) {
      return { ...acc, [(ci + 1)]: false };
    }
    return { ...acc, [ci]: false };
  }, {}));

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  /*
   Reduce fn is like that because we had to take out image 43.
   This can be made unnecessary if you change the css grid data
  */
  const handleDialog = (i) => {
    setDialog({ ...dialogState, [i]: true });
  };

  const handleDialogClose = () => setDialog(bbtfList.bbtfGallery.reduce((acc, cv, ci) => {
    if (ci >= 43) {
      return { ...acc, [(ci + 1)]: false };
    }
    return { ...acc, [ci]: false };
  }, {}));

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtfgallery-container__div">
        <div className="bbtfgallery-hero__div" />
        <main className="bbtfgallery-content__main">
          <div className="bbtfgallery-content__div">
            <PageHeader header="Gallery" />
            <div className="bbtfgallery-grid__div">
              {
               bbtfList.bbtfGallery.map((photo, i) => {
                 const {
                   id,
                   image,
                   review,
                 } = photo;

                 if (review) {
                   return (
                     <div key={id} className="bbtfgallery-photo__div bbtfgallery-photo-review__div" id={id}>
                       <div className="bbtfgallery-review__div">
                         <p className="bbtfgallery-review__p">
                           {review}
                         </p>
                       </div>
                     </div>
                   );
                 }

                 return (
                   <LazyLoad height={250} once offset={500} key={id}>
                     <button aria-label={`bbtf photo ${id}`} onClick={() => handleDialog(i)} type="button" className="bbtfgallery-photo-container__div" id={id}>
                       <div
                         style={{
                           background: `url(${image})`,
                           backgroundSize: 'cover',
                           backgroundPosition: 'center',
                           backgroundRepeat: 'no-repeat',
                         }}
                         className="bbtfgallery-photo__div bbtfgallery-photo-image__div"
                       />
                     </button>
                     <BBTFGalleryDialog
                       open={dialogState[i] || false}
                       handleDialogClose={handleDialogClose}
                       image={photo}
                     />
                   </LazyLoad>
                 );
               })
              }
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFGallery.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFGallery;
