/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import WithEvent from '../withEvent/WithEvent';
import CompFab from '../compFab/CompFab';
import PageHeader from '../pageHeader/PageHeader';
import bookshelfAuthorsList from './bookshelfAuthorsList';
import './_authorsBookshelves.scss';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import convertURL from '../../helpers/convertURL';
import use4Cite from '../../hooks/use4Cite';

const AuthorsBookshelves = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match450 = useMediaQuery('(min-width: 450px)');
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="authorsbookshelves-container__div">
        <main className="authorsbookshelves-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="authorsbookshelves-content__div">
            <div className="authorsbookshelves-image__div">
              <img
                src={match450 ? urlGenerator('/S/pages/ph-abookshelf-AuthorsBookshelf_LandingPage.png') : urlGenerator('/S/pages/ph-abookshelf-AuthorsBookshelf_Mobile.png')}
                alt="Author's Bookshelves"
                className="authorsbookshelves-image__img"
              />
            </div>
            <section className="authorsbookshelves-feature__section">
              <PageHeader header="Featured Author" />
              <div className="authorsbookshelves-feature__div">
                <div className="authorsbookshelves-feat-image__div">
                  <img
                    className="authorsbookshelves-feat-image__img"
                    src={bookshelfAuthorsList.feature.imgPath}
                    alt={bookshelfAuthorsList.feature.name}
                  />
                </div>
                <div className="authorsbookshelves-header__div authorsbookshelves-header-name__div">
                  <h3 className="authorsbookshelves-header__h3">{bookshelfAuthorsList.bookshelves[0].name}</h3>
                </div>
                <div className="authorsbookshelves-feat-details__div">
                  <div
                    dangerouslySetInnerHTML={
                    (() => ({ __html: bookshelfAuthorsList.bookshelves[0].bio }))()
                  }
                  />
                </div>
                <div className="authorsbookshelves-view__div">
                  <Link
                    className="authorsbookshelves-view__link"
                    to={`/books-media/authors-bookshelf/${convertURL(bookshelfAuthorsList.bookshelves[0].metaTag)}`}
                    onClick={() => WithEvent('AUTHOR_BOOKSHELF', 'Clicked Featured Author', 'CLICK_FEATURE', 1)}
                  >
                    View Author&#39;s Bookshelf
                  </Link>
                </div>
              </div>
            </section>
            <div className="authorsbookshelves-border__div" />
            <section className="authorsbookshelves-authors__section">
              <div className="authorsbookshelves-authors__div">
                <PageHeader header="Past Bookshelves" />
                <div className="authorsbookshelves-list__div">
                  {
                    bookshelfAuthorsList.bookshelves.slice(1).map((bookshelf) => {
                      const {
                        authorId,
                        nameFirst,
                        nameSecond,
                        imgPath,
                        metaTag,
                      } = bookshelf;
                      return (
                        <Link
                          className="authorsbookshelves-author-link__link"
                          to={`/books-media/authors-bookshelf/${convertURL(metaTag)}`}
                          key={authorId}
                          onClick={() => WithEvent('AUTHOR_BOOKSHELF', `Clicked ${metaTag}`, 'CLICK_BOOKSHELF', 1)}
                        >
                          <LazyLoad>
                            <div className="authorsbookshelves-item__div">
                              <div style={{ backgroundImage: `url(${imgPath})` }} className="authorsbookshelves-item-image__div">
                                <div className="authorsbookshelves-header__div authorsbookshelves-header-authors__div">
                                  <h4 className="authorsbookshelves-header__h4">
                                    {nameFirst}
                                    <br />
                                    {nameSecond}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </LazyLoad>
                        </Link>
                      );
                    })
                  }
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

AuthorsBookshelves.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default AuthorsBookshelves;
