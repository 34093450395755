import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import './_productListPagination.scss';

const styles = {
  rootChevronLeft: {
    width: '1.75em',
    height: '1.75em',
  },
  rootChevronRight: {
    width: '1.75em',
    height: '1.75em',
  },
};


const ProductListPagination = (props) => {
  const {
    handlePagination,
    pageTotal,
    classes,
    search,
  } = props;

  // console.log(search);
  const previous = (
    <div className="productlistpagination-previous__div">
      <ChevronLeft classes={{ root: classes.rootChevronLeft }} />
      <p className="productlistpagination-previous-text__p">previous</p>
    </div>
  );

  const next = (
    <div className="productlistpagination-next__div">
      <p className="productlistpagination-next-text__p">next</p>
      <ChevronRight classes={{ root: classes.rootChevronRight }} />
    </div>
  );

  return (
    <div className={pageTotal ? 'productlistpagination-container__div' : 'productlistpagination-container__div--nodisplay'}>
      <ReactPaginate
        pageCount={pageTotal || 1}
        previousLabel={previous}
        nextLabel={next}
        breakLabel="..."
        breakClassName="productlistpagination-break__li"
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePagination}
        containerClassName="productlistpagination-pagination__ul"
        subContainerClassName="pages pagination"
        activeClassName="productlistpagination-page__li--active"
        pageClassName="productlistpagination-page__li"
        previousClassName="productlistpagination-previous__li"
        nextClassName="productlistpagination-next__li"
        previousLinkClassName="productlistpagination-previous-link__a"
        nextLinkClassName="productlistpagination-next-link__a"
        pageLinkClassName="productlistpagination-page-link__a"
        forcePage={search.match(/page=[0-9]*/) ? parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1 : 0}
        initialPage={search.match(/page=[0-9]*/) ? parseInt(search.match(/page=[0-9]*/)[0].slice(5), 10) - 1 : 0}
        // forcePage={currentPage}
        // initialPage={0}
      />
    </div>
  );
};

ProductListPagination.propTypes = {
  handlePagination: PropTypes.func.isRequired,
  pageTotal: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  search: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProductListPagination);


/*
Notes:

7/17/19
- make the component more modular; at the moment, it's all over the place.
Each component using this has to create a hook to accommodate it.
- maybe create a custom hook that lives inside this component?
It will receive currentPage and pageTotal
*/
