import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import './_strandUniversity2021.scss';

const StrandUniversity2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const match767 = useMediaQueryMinWidth(767);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="strandu-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="strandu-content__main">
          <div className="strandu-content__div">
            <section className="strandu-hero__section">
              <div className="strandu-hero__div">
                <img
                  src={match767 ? urlGenerator('/S/banners/banners_2021_strand_u_desktop.jpg') : urlGenerator('/S/banners/banners_2021_strand_u_mobile.jpg')}
                  className="strandu-hero__img"
                  alt="Strand University 2021"
                />
              </div>
            </section>
            <section className="strandu-intro__section">
              <div className="strandu-intro__div">
                <p className="strandu-intro__p">
                  Our new collection of merch, stationery, and books
                  inspired by the constant pursuit of learning and growth.
                </p>
              </div>
            </section>
            <div className="strandu-border__div" />
            <section className="strandu-categories__section">
              <div className="strandu-categories__div">

                <LazyLoad height={300} offset={300}>
                  <div className="strandu-category__div">
                    <Link
                      to="/metalist/strand_university_apparel_2021"
                      className="strandu-category-link__link"
                      onClick={() => WithEvent('STRAND_U_2021', 'Clicked Apparel tile', 'STRAND_U_LINK', 1)}
                      aria-label="Strand U Apparel"
                    >
                      <img
                        className="strandu-category-image__img"
                        alt="Strand U Apparel"
                        src={urlGenerator('/S/pages/strand_u_2021_apparel.jpg')}
                      />
                      <div className="strandu-header__div">
                        <h1 className="strandu-header__h1">Merch</h1>
                      </div>

                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="strandu-category__div">
                    <Link
                      to="/metalist/strand_university_stationery_2021"
                      className="strandu-category-link__link"
                      onClick={() => WithEvent('STRAND_U_2021', 'Clicked Stationery tile', 'STRAND_U_LINK', 1)}
                      aria-label="STRAND U Stationery"
                    >
                      <img
                        className="strandu-category-image__img"
                        alt="Strand U Stationery"
                        src={urlGenerator('/S/pages/strand_u_2021_stationery.jpg')}
                      />
                      <div className="strandu-header__div">
                        <h1 className="strandu-header__h1">Stationery</h1>
                      </div>
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="strandu-category__div">
                    <Link
                      to="/metalist/strand_university_2021"
                      className="strandu-category-link__link"
                      onClick={() => WithEvent('STRAND_U_2021', 'Clicked Books tile', 'STRAND_U_LINK', 1)}
                      aria-label="Strand U Books"
                    >
                      <img
                        className="strandu-category-image__img"
                        alt="Strand U Books"
                        src={urlGenerator('/S/pages/strand_u_2021_books.jpg')}
                      />
                      <div className="strandu-header__div">
                        <h1 className="strandu-header__h1">Books</h1>
                      </div>
                    </Link>
                  </div>
                </LazyLoad>

              </div>
            </section>

          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

StrandUniversity2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default StrandUniversity2021;
