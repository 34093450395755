import React from 'react';
import HighlightItem from './HighlightItem';
import urlGenerator from '../../helpers/urlGenerator';
import './_highlight.scss';

const Highlight = () => (
  <div className="highlight-container__div">
    <div className="highlight-content__div">

      <div className="highlight-item__div highlight-highlight-1__highlightitem">
        <HighlightItem
          link="/books-media/bookhookup"
          imgUrl={urlGenerator('/S/banners/spotlight_2022_bookhookup.png')}
          alt="Book Hookup"
        />
      </div>

      <div className="highlight-item__div highlight-highlight-2__highlightitem">
        <HighlightItem
          link="/more/sell-books"
          imgUrl={urlGenerator('/S/banners/spotlight_2023_sell_the_books.png')}
          alt="Sell your Books"
        />
      </div>

      <div className="highlight-item__div highlight-highlight-3__highlightitem">
        <HighlightItem
          link="/metalist/new_merch"
          imgUrl={urlGenerator('/S/banners/spotlight_new_merch.jpg')}
          alt="New Merch"
        />
      </div>

    </div>
  </div>
);

export default Highlight;
