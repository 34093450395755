import urlGenerator from '../../helpers/urlGenerator';

const rentRarePartnersList = {
  partners: [
    {
      id: 'partners301',
      name: 'Great Performances',
      description: 'Great Performances is proud to be a preferred caterer for The Strand Rare Book Room. Dedicated to seasonal and local ingredients, flavorful food, and gracious service, we are the leading full-service catering company in NYC. Great Performances is also the first to own and operate an organic farm, Katchkie Farm, in Kinderhook, NY.',
      link: 'https://www.greatperformances.com/',
      contactName: 'Gary Bedigan',
      contactEmail: 'celebratefood@greatperformances.com',
      partnerImg: urlGenerator('/S/pages/rentrare-great-performances.jpg'),
    },
    {
      id: 'partners302',
      name: 'Gracious Thyme',
      description: 'Author David Eggers said, “Books have a unique way of stopping time in a particular moment and saying: Let’s not forget this.” Like your favorite book, a Gracious Thyme event is not to be forgotten. We have had the pleasure of serving bookworms, bibliophiles and bibliosmiacs at The Strand Rare Book Room for many years. From cocktail parties to weddings and anniversary celebrations, we provide delicious food, impeccable service and a shared love of literature.',
      link: 'http://www.graciousthyme-nyc.com/',
      contactName: 'Gracious Thyme',
      contactEmail: 'lvaught@graciousthyme.com',
      contactEmail2: 'info@graciousthyme.com',
      partnerImg: urlGenerator('/S/pages/rentrare-gracious-thyme.jpg'),
    },
    {
      id: 'partners303',
      name: 'Gooey & Co',
      description: 'Gooey & Co is inspired by a curiously distinct cake that originated in St. Louis, MO in the 1930’s. Gooey Butter Cake is still a St. Louie staple, and hard to come by just about anyplace else. Perfect in the morning with coffee or for celebrations, our cakes are crisp and flakey on top, light and fluffy on the bottom, and perfectly gooey in the center. Gooey&Co craft’s cakes from original recipes, using only the freshest, all-natural ingredients, and serves them in our signature bite-size "Gooey Bits", the perfect one-and-a-half-inch square bite. Available in a variety of flavors with multiple serving options.',
      link: 'https://gooeyandco.com/',
      contactName: '',
      contactEmail: '',
      partnerImg: urlGenerator('/S/pages/rentrare-gooey.jpg'),
    },
    {
      id: 'partners304',
      name: 'OM Booth',
      description: 'The ultimate photo, video and GIF booth. The OMbooth instantly creates and shares photography, GIF and video experiences for you to simply and elegantly share your big day.',
      link: 'https://omdigital.com/ombooth/',
      contactName: '',
      contactEmail: '',
      partnerImg: urlGenerator('/S/pages/rentrare-om-gallery.gif'),

    },
    {
      id: 'partners305',
      name: 'Rev. & DJ CherishTheLuv',
      description: 'Featured on Huffington Post, NPR Music, Bravo TV & more, award-winning DJ CherishTheluv is a Bose® Professional DJ, and is Resident DJ for Nile Rodgers We Are Family Foundation, Whole Foods NYC, Heritage Radio Network, and Urban Vegan Kitchen. Her local and international clients include Google, YouTube, W Hotels, the Canadian Consulate, Viacom, Netflix, TBS, Food Network, Ryan Seacrest CIVIC, museums and private/corporate events. Seen spinning at Google HQ, Whole Foods Bryant Park, Hammerstein Ballroom, Apollo Theater and more, DJ CherishTheLuv has rocked crowds for celebrities Amy Schumer, Samantha Bee, Anne Burrell and Oprah, and has opened for Elvis Costello, Chic, LL Cool J, Roger Daltrey and U2. As a Music Missionary, DJ CherishTheLuv aligns herself with charities & non-profits focused on empowering girls, women, and cancer patients. She is a Survivor herself, and is the DJ spokesperson for the LivingWith app. An ordained minister, Rev. Luv writes and officiates truly memorable weddings. Check out her 5-star reviews on both of her pages on TheKnot.',
      link: 'https://www.theknot.com/marketplace/dj-and-rev-cherishtheluv-new-york-ny-1012464',
      contactName: '',
      contactEmail: '',
      partnerImg: urlGenerator('/S/pages/rentrare-cherish-luv.jpg'),
    },
    {
      id: 'partners306',
      name: 'NYC Flower Project',
      description: 'Gus and Nicole are artistically driven, customizing the floral experience for each of their unique clients. They tend to every individual and their desired style to make each event a memorable one. Combining the classic and trendy aspects of floral arrangements, their designs are both eclectic and personal. Having styled events across New York City and the tri-state area in venues of all kinds, their expertise in their field is a contender to none. With up-beat and kind personsas, their services are incomparable.',
      link: 'https://www.nycflowerproject.com/',
      contactName: 'florist',
      contactEmail: 'nycflowerproject@gmail.com',
      partnerImg: urlGenerator('/S/pages/rentrare-nyc-flower-project.png'),
    },
    {
      id: 'partners307',
      name: 'The Giftbox',
      description: 'Capture unlimited Gif and Still photos instantly sharing them to your event guests. The coolest PhotoBooth around, The GifBox is the fastest way to capture the fun and share instantly to Instagram, Facebook, Snapchat and more. I Heart New York Photography (https://www.i-am-nyc.com/) specializes in capturing romantic surprise marriage proposals with over 600 captured in New York City. We offer a 24/7 concierge to help plan your perfect proposal, a team of expert photographers and lightning fast turn around to share the moments with your friends and family.',
      link: 'https://www.gifbox.nyc/',
      contactName: '',
      contactEmail: '',
      partnerImg: urlGenerator('/S/pages/rentrare-giftbox.jpg'),
    },
    {
      id: 'partners308',
      name: 'Rachel Pearlman Photography',
      description: 'My approach to photographing your day is laid-back, non-instrustive and is ideally based on friendship and trust between the three of us. My philosophy is simple: I like to develop a friendship prior to photographing your day, as I truly believe that fostering a genuine and authentic relationship will help me create the best and most beautiful images fro you. After all, the most important part of your wedding is spending quality time with your spouse - not worrying about all the moving pieces (including photography!).',
      link: 'http://rachelpearlmanphotography.com/',
      contactName: '',
      contactEmail: '',
      partnerImg: urlGenerator('/S/pages/rentrare-rachel-pearlman.jpg'),
    },
    {
      id: 'partners309',
      name: 'New York Edge Music',
      description: 'Home of multi award winning New York Edge Band, City Edge DJs, City Edge Strings, and City Edge Jazz. The band features a fantastically large repertoire of the best dance music from current hits to classic classics. Think... the best dance hits from all decades and you’ll get the idea! We love to get to know your music tastes and that, combined with watching your crowd and going with the flow of what your guests are responding to, creates a party that everyone remembers! Give us a shout… we love to talk music! ',
      link: 'http://www.newyorkedge.com/',
      contactLink: 'http://www.newyorkedge.com/',
      contactName: 'New York Edge Music',
      contactEmail: 'rich@newyorkedge.com',
      partnerImg: urlGenerator('/S/pages/rentrare-nyc-edge.png'),
    },
  ],
  gallery: [
    {
      id: 'gallery320',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-1.jpg'),
      name: 'gallery 1',
    },
    {
      id: 'gallery321',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-2.jpg'),
      name: 'gallery 2',
    },
    {
      id: 'gallery322',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-3.jpg'),
      name: 'gallery 3',
    },
    {
      id: 'gallery323',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-4.jpg'),
      name: 'gallery 4',
    },
    {
      id: 'gallery324',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-5.jpg'),
      name: 'gallery 5',
    },
    {
      id: 'gallery325',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-6.jpg'),
      name: 'gallery 6',
    },
    {
      id: 'gallery326',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-7.jpg'),
      name: 'gallery 7',
    },
    {
      id: 'gallery327',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-8.jpg'),
      name: 'gallery 8',
    },
    {
      id: 'gallery328',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-9.jpg'),
      name: 'gallery 9',
    },
    {
      id: 'gallery329',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-10.jpg'),
      name: 'gallery 10',
    },
    {
      id: 'gallery330',
      galleryImg: urlGenerator('/S/pages/rbr-gallery-11.jpg'),
      name: 'gallery 11',
    },
    {
      id: 'gallery331',
      galleryImg: urlGenerator('/S/pages/rentrare-pricing-banner.jpg'),
      name: 'gallery 12',
    },
    {
      id: 'gallery332',
      galleryImg: urlGenerator('/S/pages/rentrare-pricing-banner-2.jpg'),
      name: 'gallery 13',
    },
    {
      id: 'gallery333',
      galleryImg: urlGenerator('/S/pages/rentrare-partner-banner.jpg'),
      name: 'gallery 14',
    },
    {
      id: 'gallery334',
      galleryImg: urlGenerator('/S/pages/rentrare-gallery.jpg'),
      name: 'gallery 15',
    },
  ],
  photos: [
    {
      id: 'photos350',
      photoImg: urlGenerator('/S/pages/rbr-gallery-couple-1.jpg'),
      name: 'Emily & Josh',
      link: 'http://jessicaschmittblog.com/2017/05/strand-bookstore-wedding-nyc/',
    },
    {
      id: 'photos351',
      photoImg: urlGenerator('/S/pages/rbr-gallery-couple-2.jpg'),
      name: 'Tom & Dustin',
      link: 'http://aaronandwhitney.com/tom-dustin-strand-bookstore-wedding-nyc/',
    },
    {
      id: 'photos352',
      photoImg: urlGenerator('/S/pages/rbr-gallery-couple-3.jpg'),
      name: 'Emily & Graham',
      link: 'http://www.booksuniverseeverything.com/2016/08/22/a-tale-of-two-weddings-part-2-strand-bookstore/',
    },
    {
      id: 'photos353',
      photoImg: urlGenerator('/S/pages/rbr-gallery-couple-4.jpg'),
      name: 'Jenna & Nick',
      link: 'https://www.llstylephoto.com/blog/engagement-photo-session-jenna-nick-strand-bookstore/',
    },
    {
      id: 'photos354',
      photoImg: urlGenerator('/S/pages/rbr-gallery-couple-5.png'),
      name: 'Margot & Elle',
      link: 'https://www.gaycitynews.nyc/stories/2018/20/margot-wedding-gcn-09-27-gcn_2018_20.html',
    },
  ],
};

export default rentRarePartnersList;
