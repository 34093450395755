import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import LazyLoad from 'react-lazyload';
import { makeStyles } from '@material-ui/core/styles';
// import ButtonBase from '@material-ui/core/ButtonBase';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import IconButton from '@material-ui/core/IconButton';
import queryString from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import Loading from '../loading/Loading';
import InPageLoading from '../loading/InPageLoading';
import GenCarousel from '../genCarousel/GenCarousel';
import CompFab from '../compFab/CompFab';
import CompFilter from '../compFilter/CompFilter';
import FilterFab from './FilterFab';
import { useCartSnackbar } from '../cartSnackbar/CartSnackbarProvider';
import WithEvent from '../withEvent/WithEvent';
import convertURL from '../../helpers/convertURL';
import convertSlashURL from '../../helpers/convertSlashURL';
import useScrollY from '../../hooks/useScrollY';
import capitalizeString from '../../helpers/capitalizeString';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import use4Cite from '../../hooks/use4Cite';
import atcUpdate from '../../helpers/atcUpdate';
import './_searchResults.scss';

export const GET_SEARCH_RESULTS = gql`
query search($searchTerm: String!, $order: OrderCommonIn, $filter: FilterCommonIn){
  searchProductResult(searchTerm: $searchTerm, order: $order, filter: $filter){
    param{
      searchTerm
    }
    productList{
      copyright
      gtin13
      imageUrl
      bookBinding
      bookFormat
      retailPrice
      inventory{
        salePriceStv
        catalogId
        onHand
        sku
      }
      apparel{
        apparelId
        size
        inventory{
          catalogId
          condition
          sku
          onHand
          salePriceStv
        }
      }
      isbn10
      originator
      outOfPrint
      publisher
      rank
      title
      id
    }
  }
  searchOriginator(searchTerm: $searchTerm){
    card
    originator
    rank
  }
  searchProductKeyword(searchTerm: $searchTerm){
    copyright
    gtin13
    imageUrl
    isbn10
    originator
    outOfPrint
    publisher
    rank
    title
    id
  }
  searchSection(searchTerm: $searchTerm){
    rank
    section
  }
}
`;

export const ADD_ITEM_CART = gql`
  mutation addItemCart($cartItemInput: CartItemInput!){
    cartItemAdd(cartItemInput: $cartItemInput){
      cartId
      cartItemId
      cartPriceStv
      catalogId
      description
      gtin
      isTaxable
      qty
      retailStv
      salePriceStv
      sku
      productDetail{
        gtin13
        id
        imageUrl
        isbn10
        title
        originator
        bookBinding
        inventory{
          sku
          onHand
          catalogId
          signed
        }
        apparel{
          apparelId
          size
          inventory{
            catalogId
            condition
            sku
            onHand
            salePriceStv
          }
        }
      }
    }
  }
`;

export const META_LIST_DETAIL = gql`
  query{
    bestsellersMeta: metaListDetail(metaTag: "Bestsellers"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    indieNextMeta: metaListDetail(metaTag: "Indie Next"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
  }
`;

export const PRODUCT_LIST_DETAIL = gql`
  query{
    productListDetail(ean: ["9112084751", "9111026634", "9112128198", "911218845X", "9112216984", "2900012830337", "2900012649519", "9112087718", "9112331937", "9112101117"]){
      gtin13
      imageUrl
      id
      title
    }
  }
`;

const SearchResults = (props) => {
  const {
    location,
    // history,
    cartList: upperCartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const match767 = useMediaQuery('(min-width: 767px)');

  const [optionsState, setOptions] = useState({
    order: {
      dir: 'ASC',
      orderBy: 'RANK',
    },
    filter: {
      binding: 'ANY',
      condition: 'ANY',
      copyrightYear: {
        maxYear: 0,
        minYear: 0,
      },
      stockFilter: 'IN_STOCK',
      section: null,
      // at828: false,
      signed: false,
      salePriceStv: {
        maxStv: 0,
        minStv: 0,
      },
    },
  });

  const {
    compSwitchState: filterState,
    handleCompOpen: handleFilterOpen,
    handleCompClose: handleFilterClose,
  } = useOpenCloseComponent();

  const [searchTerm,
    setSearch,
  ] = useState(queryString.parse(location.search).searchVal ? queryString.parse(location.search).searchVal : '');

  const [typeState, setType] = useState(queryString.parse(location.search).type ? queryString.parse(location.search).type : 'product');

  // const handleType = (e) => {
  //   setType(e.target.value);
  //   history.push(`/search-results?page=1&${searchTerm}&searchVal=${searchTerm}&type=${e.target.value}`);
  // };

  const [viewState, setView] = useState(true);

  const handleViewOpen = () => setView(true);

  const handleViewClose = () => setView(false);

  const setCartSnackbar = useCartSnackbar();

  const handleCartSnackbarClose = useCallback(() => {
    setCartSnackbar(prev => ({ ...prev, open: false }));
  }, [setCartSnackbar]);

  const handleCartSnackbarOpen = useCallback((prodTitle, triggerElem) => {
    setCartSnackbar({
      prodTitle,
      open: true,
      triggerElem,
      onClose: handleCartSnackbarClose,
    });
  }, [setCartSnackbar, handleCartSnackbarClose]);

  const useStyles = makeStyles({
    disabledButtonBase: {
      color: '#5f5f5f',
    },
    rootIconButton: {
      color: '#af272f',
      fontSize: '18px',
      '&:hover': {
        color: '#df301a',
        backgroundColor: 'transparent',
      },
    },
    rootIcon: {
      width: '35px',
      height: '35px',
    },
    rootGridViewIconButton: {
      color: viewState ? '#af272f' : 'initial',
      '&:hover': {
        color: '#df301a',
        backgroundColor: 'transparent',
      },
    },
    rootListViewIconButton: {
      color: viewState ? 'initial' : '#af272f',
      '&:hover': {
        color: '#df301a',
        backgroundColor: 'transparent',
      },
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (location.search) {
      setSearch(queryString.parse(location.search).searchVal);
      setType(queryString.parse(location.search).type ? queryString.parse(location.search).type : '1');
    }
    return () => {
      setSearch('');
    };
  }, [location.search]);

  use4Cite();

  const scroll = useScrollY();

  // const [tabState, setTab] = useState(1);

  const {
    data: searchData,
    error: searchError,
    loading: searchLoading,
    refetch: searchRefetch,
  } = useQuery(GET_SEARCH_RESULTS, {
    variables: { searchTerm, filter: { stockFilter: 'IN_STOCK' } },
  });

  // const {
  //   data: sectionData,
  //   error: sectionError,
  //   loading: sectionLoading,
  //   refetch: sectionRefetch,
  // } = useQuery(SECTION_RESULTS, {
  //   variables: { searchTerm },
  // });

  const [
    cartItemAdd,
    {
      error: errorAddItemCart,
      loading: loadingAddItemCart,
      // data: dataAddItemCart,
    }] = useMutation(ADD_ITEM_CART);

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  const {
    error: errorProductListDetail,
    loading: loadingProductListDetail,
    data: dataProductListDetail,
  } = useQuery(PRODUCT_LIST_DETAIL);

  const NoResults = (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="searchresults-container__div">
        <div className="searchresults-results__div">
          { scroll > 1000 && <CompFab />}
          { typeState === 'product' && <FilterFab handleFilterOpen={handleFilterOpen} />}
          <div className="searchresults-sorry__div">
            <h2 className="searchresults-sorry__h2">
              Sorry, our booksellers can&#39;t find what you are looking for
            </h2>
          </div>
          <div className="searchresults-sorry-accent__div" />
          <div className="searchresults-browse__div">
            <div className="searchresults-sorry__div">
              <h2 className="searchresults-sorry__h2 searchresults-sorry__h2--black">
                Explore Some of Our Recommendations Below
              </h2>
            </div>
            <div className="searchresults-carousels__div">

              <div className="saerchresults-carousel__div">
                <GenCarousel
                  locationName="bestsellers"
                  dataType="metaList"
                  products={(
                    dataMeta
                    && dataMeta.bestsellersMeta
                    && dataMeta.bestsellersMeta.product)
                    || []
                  }
                />
              </div>

              <div className="saerchresults-carousel__div">
                <GenCarousel
                  locationName="indie_next"
                  dataType="metaList"
                  products={(
                    dataMeta
                    && dataMeta.indieNextMeta
                    && dataMeta.indieNextMeta.product)
                    || []
                  }
                />
              </div>

              <div className="saerchresults-carousel__div">
                <GenCarousel
                  locationName="/productsection/MERCH-TOTES"
                  dataType="default"
                  header="Totes"
                  products={(
                    dataProductListDetail?.productListDetail
                  )
                      || []
                    }
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <CompFilter
        open={filterState}
        onClose={handleFilterClose}
        refetchFilter={searchRefetch}
        inputTerm={queryString.parse(location.search).searchVal}
        optionsState={optionsState}
        setOptions={setOptions}
        search
      />
      <ViewFooter />
    </React.Fragment>
  );

  const LoadingResults = (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <Loading />
      <ViewFooter />
    </React.Fragment>
  );

  if (searchLoading || loadingMeta || loadingProductListDetail) return LoadingResults;

  if (searchError || errorAddItemCart || errorMeta || errorProductListDetail) {
    return (
      <Redirect to={{
        pathname: '/error',
        state: {
          searchError,
          errorAddItemCart,
          errorMeta,
          errorProductListDetail,
        },
      }}
      />
    );
  }

  if (searchData
      && searchData.searchProductResult
      && (
        (!searchData.searchProductResult.productList || !searchData.searchProductResult.productList.length)
          && !searchData.searchOriginator.length
          && !searchData.searchProductKeyword.length
          && !searchData.searchSection.length
      )) {
    return NoResults;
  }

  if (searchData
      && searchData.searchProductResult
      // && tabState === 1
      && typeState === 'product'
      && (!searchData.searchProductResult.productList || !searchData.searchProductResult.productList.length)) {
    return NoResults;
  } // refactor when you introduce categorized search

  // if (searchData
  //     && searchData.searchProductResult
  //     && searchData.searchProductResult.productList
  //     && searchData.searchProductResult.productList.length === 1) {
  //   return (
  //     <Redirect to={`product/${searchData.searchProductResult.productList[0].gtin13}`} />
  //   );
  // }

  if (searchData
      && (searchData.searchOriginator.length
      || (searchData.searchProductResult && searchData.searchProductResult.productList && searchData.searchProductResult.productList.length)
      || searchData.searchProductKeyword.length
      || searchData.searchSection.length)) {
    const addToCart = (sku, catalogId, title, val, triggerElem) => {
      WithEvent('SEARCH_RESULT', 'Add to Cart via Search', 'ADD_SEARCH', parseFloat((val * 0.01).toFixed(2)));

      cartItemAdd({
        variables: { cartItemInput: { catalogId, sku, qty: 1 } },
        update: atcUpdate,
      })
        .then(() => refetchCartList())
        .then(() => {
          handleCartSnackbarOpen(title, triggerElem);
        });
    };

    // console.log('searchData', searchData);

    let searchProductList;
    if (
      Object.keys(searchData).length
      && ((searchData.searchProductResult && searchData.searchProductResult.productList && searchData.searchProductResult.productList.length)
        || searchData.searchProductKeyword.length)
      && searchData.searchProductResult
      && searchData.searchProductResult.productList
      && searchData.searchProductResult.productList.length > 0
    ) {
      const idMap = new Map();
      [
        searchData.searchProductResult.productList,
        searchData.searchProductKeyword,
      ].forEach((list) => {
        list.reduce((map, prod) => map.set(prod.id, prod), idMap);
      });

      searchProductList = [...idMap.values()];
    }

    return (
      <React.Fragment>
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="searchresults-container__div">
          <div className="searchresults-loading__div">
            {loadingAddItemCart && <InPageLoading />}
          </div>
          <div className="searchresults-results__div">
            { scroll > 1000 && <CompFab />}
            { typeState === 'product' && <FilterFab handleFilterOpen={handleFilterOpen} />}
            {/* <h1 className="searchresults-header__h1">{`Search Results for: "${searchData.searchProductResult.param.searchTerm}"`}</h1> */}
            {
              searchData
                && searchData.searchProductResult
                && searchData.searchProductResult.param
                && searchData.searchProductResult.param.searchTerm
                && searchData.searchProductResult.param.searchTerm.length > 0
                && <h1 className="searchresults-header__h1">{`Search results for: "${searchData.searchProductResult.param.searchTerm}"`}</h1>
            }
            <section className="searchresults-tabs__section">
              <div className="searchresults-tabs__div">
                {/* <div className="searchresults-tab__div">
                  <ButtonBase
                    disableRipple
                    className={typeState === 'product' ? 'searchresults-tab-header__buttonbase searchresults-tab-header__buttonbase--active' : 'searchresults-tab-header__buttonbase'}
                    disabled={(!searchProductResult.productList && searchProductResult.productList.length && !searchProductKeyword.length)}
                    onClick={handleType}
                    classes={{ disabled: classes.disabledButtonBase }}
                    value="product"
                  >
                    Products
                  </ButtonBase>
                </div>
                <div className="searchresults-tab__div">
                  <ButtonBase
                    disableRipple
                    className={tabState === 2 ? 'searchresults-tab-header__buttonbase searchresults-tab-header__buttonbase--active' : 'searchresults-tab-header__buttonbase'}
                    disabled={!searchOriginator.length}
                    onClick={() => setTab(2)}
                    classes={{ disabled: classes.disabledButtonBase }}
                  >
                    Originator
                  </ButtonBase>
                </div>
                <div className="searchresults-tab__div">
                  <ButtonBase
                    disableRipple
                    className={typeState === 'section' ? 'searchresults-tab-header__buttonbase searchresults-tab-header__buttonbase--active' : 'searchresults-tab-header__buttonbase'}
                    // disabled={searchData && !searchData.searchSection.length}
                    onClick={handleType}
                    classes={{ disabled: classes.disabledButtonBase }}
                    value="section"
                  >
                    Section
                  </ButtonBase>
                </div> */}
                <div className="searchresults-tab-header__div">
                  {
                    typeState === 'product'
                      && <p className="searchresults-tab-header__p">Product</p>
                  }
                  {
                    typeState === 'section'
                      && <p className="searchresults-tab-header__p">Section</p>
                  }
                </div>
              </div>
              {
                    match767
                      && typeState === 'product'
                      && (
                        <div className="searchresults-views__div">
                          <Tooltip title="List View">
                            <IconButton
                              onClick={handleViewClose}
                              classes={{ root: classes.rootListViewIconButton }}
                              disableTouchRipple
                              disableRipple
                              aria-label="List View"
                            >
                              <ListAltRoundedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Grid View">
                            <IconButton
                              onClick={handleViewOpen}
                              classes={{ root: classes.rootGridViewIconButton }}
                              disableTouchRipple
                              disableRipple
                              aria-label="Grid View"
                            >
                              <GridOnRoundedIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                  }
              <div className="searchresults-count__div">
                {
                  typeState === 'product'
                    && searchData
                    && searchData.searchProductResult
                    && searchData.searchProductResult.productList
                    && searchData.searchProductResult.productList.length > 0
                    && (
                      <p className="searchresults-details__p searchresults-count__p">
                        {`${searchData.searchProductResult.productList.length} ${searchData.searchProductResult.productList.length === 1 ? 'result' : 'results'}`}
                      </p>
                    )
                }
                {
                  typeState === 'section'
                    && searchData
                    && searchData.searchSection
                    && searchData.searchSection.length > 0
                    && (
                      <p className="searchresults-details__p searchresults-count__p">
                        {`${searchData.searchSection.length} ${searchData.searchSection.length === 1 ? 'result' : 'results'}`}
                      </p>
                    )
                }
              </div>
            </section>
            {
              typeState === 'product'
                && (
                  <section className="searchresults-products__section">
                    <div className={viewState ? 'searchresults-products__div--grid' : 'searchresults-products__div'}>
                      {/* <div className="searchresults-count__div">
                        <p className="searchresults-details__p searchresults-count__p" />
                      </div> */}
                      {searchProductList.map((prod, i) => {
                        const {
                          id,
                          gtin13,
                          imageUrl,
                          originator,
                          title,
                          bookBinding,
                          bookFormat,
                          // retailPrice,
                          inventory,
                          apparel,
                        } = prod;

                        // console.log('inventory', inventory);

                        return (
                          <div key={id} className={viewState ? 'searchresults-product__div searchresults-product__div--grid' : 'searchresults-product__div'}>
                            <Link
                              className="searchresults-product-link__link"
                              to={{ pathname: `/product/${bookBinding === 'Merch' && gtin13.match(/979[0-9]{12,13}/) && gtin13.match(/979[0-9]{12,13}/).length ? gtin13.slice(3) : gtin13}`, search: `?title=${convertURL(title)}`, state: { title: convertURL(title), gtin13 } }}
                              key={gtin13}
                              aria-label={`gtin13${i}`}
                              onClick={() => WithEvent('SEARCH_RESULT', `Clicked ${gtin13} image link`, 'SEARCH_RESULT_IMAGE', 1)}
                            >
                              <div className={viewState ? 'searchresults-product-image__div--grid' : 'searchresults-product-image__div'}>
                                <LazyLoad offset={125} height={125}>
                                  <img
                                    className="searchresults-product-image__img"
                                    alt={title}
                                    src={imageUrl}
                                  />
                                </LazyLoad>
                              </div>
                            </Link>
                            <div className={viewState ? 'searchresults-product-details__div--grid' : 'searchresults-product-details__div'}>
                              <Link
                                className="searchresults-product-link__link"
                                to={{ pathname: `/product/${bookBinding === 'Merch' && gtin13.match(/979[0-9]{12,13}/) && gtin13.match(/979[0-9]{12,13}/).length ? gtin13.slice(3) : gtin13}`, search: `?title=${convertURL(title)}`, state: { title: convertURL(title), gtin13 } }}
                                onClick={() => WithEvent('SEARCH_RESULT', `Clicked ${gtin13} title link`, 'SEARCH_RESULT_TITLE', 1)}
                              >
                                {title && <p className={viewState ? 'searchresults-details__p searchresults-product-title__p--grid' : 'searchresults-details__p searchresults-product-title__p'}>{title}</p>}
                              </Link>
                              {originator && <p className="searchresults-details__p searchresults-product-originator__p">{originator}</p>}
                              {bookBinding && <p className="searchresults-details__p searchresultsbookBinding__p">{bookBinding}</p>}
                              {bookFormat && <p className="searchresults-details__p searchresultsbookBinding__p">{bookFormat}</p>}
                              {inventory && inventory.length > 0 && inventory[0].salePriceStv && <p className="searchresults-details__p searchresults-product-originator__p">{`$${(inventory[0].salePriceStv * 0.01).toFixed(2)}`}</p>}
                              {apparel && apparel.length > 0 && apparel?.[0]?.inventory && apparel?.[0]?.inventory.length > 0 && apparel?.[0]?.inventory?.[0]?.salePriceStv && <p className="searchresults-details__p searchresults-product-originator__p">{`$${(apparel?.[0]?.inventory?.[0]?.salePriceStv * 0.01).toFixed(2)}`}</p>}
                              {
                                inventory
                                  && inventory.length > 0
                                  && (
                                    <div className={viewState ? 'searchresults-add-btn__div--grid' : 'searchresults-add-btn__div'}>
                                      <Tooltip title="Add 1 to Cart">
                                        <IconButton
                                          classes={{ root: classes.rootIconButton }}
                                          onClick={e => addToCart(
                                            inventory[0].sku,
                                            inventory[0].catalogId,
                                            title,
                                            inventory[0].salePriceStv,
                                            e.currentTarget,
                                          )}
                                          aria-label="add 1 to cart"
                                        >
                                          <ShoppingCartIcon classes={{
                                            root: classes.rootIcon,
                                          }}
                                          />
                                          <div className="searchresults-add-badge__div">
                                            <p className="searchresults-add-badge__p">
                                              1
                                            </p>
                                          </div>
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  )
                              }
                              {
                                inventory
                                  && inventory.length === 0
                                  && !apparel
                                  && (
                                    <div className="searchresults-out-of-stock__div">
                                      <p className="searchresults-out-of-stock__p">
                                        Out of stock
                                      </p>
                                    </div>
                                  )
                              }
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                )
            }
            {
              // tabState === 2
              //   && (
              //     <section className="searchresult-originator__section">
              //       <div className="searchresults-originator__div">
              //         <div className="searchresults-count__div">
              //           <p className="searchresults-details__p searchresults-count__p">
              //             {`${searchData.searchOriginator.length} ${searchData.searchOriginator.length === 1 ? 'result' : 'results'}`}
              //           </p>
              //         </div>
              //         {
              //         Object.keys(searchData.searchResults.data).length
              //         && Object.keys(searchData.searchOriginator).length > 0
              //           && (
              //             searchData.searchOriginator.map((originator, i) => (
              //               <p className="searchresults-details__p searchresults-originator__p" key={`${originator}${i + 100}`}>{originator.originator}</p>
              //             ))
              //           )
              //         }
              //       </div>
              //     </section>
              //   )
            }
            {
              typeState === 'section'
                && (
                  <section className="searchresult-section__section">
                    <div className="searchresults-section__div">
                      {/* <div className="searchresults-count__div">
                        <p className="searchresults-details__p searchresults-count__p">
                          {`${searchData.searchSection.length} ${searchData.searchSection.length === 1 ? 'result' : 'results'}`}
                        </p>
                      </div> */}
                      {
                        searchData
                          && searchData.searchSection
                          && searchData.searchSection.length < 1
                          && (
                            <div className="searchresults-sorry__div">
                              <h2 className="searchresults-sorry__h2--content">
                                Sorry, our booksellers can&#39;t find what you are looking for
                              </h2>
                            </div>
                          )
                      }
                      {
                        Object.keys(searchData)
                        && Object.keys(searchData).length > 0
                        && Object.keys(searchData.searchSection)
                        && Object.keys(searchData.searchSection).length > 0
                          && (
                            searchData.searchSection
                              .filter(sec => sec.section !== 'MERCH-STRAND WEARABLES')
                              .map((sec, i) => (
                                <Link
                                  className="searchresults-section-link__link"
                                  key={`${sec.section}${i + 100}`}
                                  to={`/productsection/${convertSlashURL(sec.section)}`}
                                  onClick={() => WithEvent('SEARCH_RESULT', `Clicked ${sec.section} link`, 'SEARCH_RESULT_SECTION', 1)}
                                >
                                  <p className="searchresults-details__p searchresults-section__p">{capitalizeString(sec.section)}</p>
                                </Link>
                              ))
                          )
                      }
                    </div>
                  </section>
                )
            }
          </div>
        </div>
        <CompFilter
          open={filterState}
          onClose={handleFilterClose}
          refetchFilter={searchRefetch}
          inputTerm={queryString.parse(location.search).searchVal}
          optionsState={optionsState}
          setOptions={setOptions}
          search
        />
        <ViewFooter />
      </React.Fragment>
    );
  }

  return LoadingResults;
};

SearchResults.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default SearchResults;
