import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/styles';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import landingPageData from './landingPageData';
import WithEvent from '../withEvent/WithEvent';
import convertURL from '../../helpers/convertURL';
import './_preorders.scss';

const useStyles = makeStyles({
  rootIconBtn: {
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '15px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
});

const Preorders = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const classes = useStyles();

  const [collapseState, setCollapse] = useState(landingPageData.reduce((acc, cv) => ({ ...acc, [cv.id]: false }), {}));

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const handleCollapse = (campaign) => {
    setCollapse({
      ...collapseState,
      [campaign]: !collapseState[campaign],
    });
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="preorders-container__div">
        <main className="preorders-content__main">
          { scroll > 1000 && <CompFab />}
          <PageHeader header="Preorders" />
          <div className="preorders-content__div">
            <section className="preorders-list__section">
              <div className="preorders-list__div">
                {
                  landingPageData
                    && landingPageData
                      .filter(event => !event.private)
                      .filter((event) => {
                        const eventDate = moment(event.preorderEndObj);
                        // return eventDate.isSameOrBefore(moment()) || event.preorderEndObj.length === 0;
                        return eventDate.isSameOrAfter(moment()) || event.preorderNoEnd;
                      }).length > 0
                    ? (
                      landingPageData
                        .filter(event => !event.private)
                        .filter((event) => {
                          const eventDate = moment(event.preorderEndObj);
                          // return eventDate.isSameOrAfter(moment()) || event.preorderEndObj.length === 0;
                          return eventDate.isSameOrAfter(moment()) || event.preorderNoEnd;
                        })
                        .sort((a, b) => {
                          if (moment(a.preorderEndObj).isBefore(moment(b.preorderEndObj))) {
                            return -1;
                          }

                          if (moment(a.dateObj).isAfter(moment(b.dateObj))) {
                            return 1;
                          }

                          return 0;
                        })
                        .map((event) => {
                          const {
                            id,
                            title,
                            bookImage,
                            preorderEndObj,
                            bookBlurb,
                          } = event;

                          return (
                            <div key={id} className="preorders-item__div">
                              <Link
                                to={`/campaign/${id}?title=${convertURL(title)}`}
                                className="preorders-link__link"
                                onClick={() => WithEvent('PREORDERS', `Clicked ${title} Image`, 'PREORDERS_LIST_IMAGE', 1)}
                              >
                                <div className="preorders-image__div">
                                  <img className="preorders-image__img" src={bookImage} alt={title} />
                                </div>
                              </Link>
                              <div className="preorders-details__div">
                                <Link
                                  to={`/campaign/${id}?title=${convertURL(title)}`}
                                  className="preorders-link__link"
                                  onClick={() => WithEvent('PREORDERS', `Clicked ${title} Title`, 'PREORDERS_LIST_TITLE', 1)}
                                >
                                  <h3 className="preorders-header__h3">{title}</h3>
                                </Link>
                                <div className="preorders-accent__div" />
                                {
                                  preorderEndObj.length > 0
                                    ? <p className="preorders-details__p preorders-date__p">{event.customEndNote || `Preorder ends on ${moment(preorderEndObj).format('dddd')} ${moment(preorderEndObj).format('MMMM')} ${moment(preorderEndObj).format('DD')} ${moment(preorderEndObj).format('LT')} ET`}</p>
                                    : <p className="preorders-details__p preorders-date__p" />
                                }
                                <Collapse in={collapseState[id]} collapsedHeight="100px">
                                  <div className="preorders-summary__div" dangerouslySetInnerHTML={(() => ({ __html: bookBlurb }))()} />
                                </Collapse>
                                <div className="preorders-more__div">
                                  {
                                    collapseState[id]
                                      ? (
                                        <IconButton
                                          disableTouchRipple
                                          disableRipple
                                          classes={{ root: classes.rootIconBtn }}
                                          onClick={() => handleCollapse(id)}
                                          aria-label="expand less"
                                        >
                                          <p className="preorders-more__p">Read less</p>
                                          <ExpandLessIcon />
                                        </IconButton>
                                      )
                                      : (
                                        <IconButton
                                          onClick={() => handleCollapse(id)}
                                          disableTouchRipple
                                          disableRipple
                                          classes={{ root: classes.rootIconBtn }}
                                          aria-label="expand more"
                                        >
                                          <p className="preorders-more__p">Read more</p>
                                          <ExpandMoreIcon />
                                        </IconButton>
                                      )
                                  }
                                </div>
                              </div>
                            </div>
                          );
                        })
                    )
                    : (
                      <div className="preorders-no-events__div">
                        <h2 className="preorders-header__h2">
                          We don&#39;t have any preorders at this time
                        </h2>
                      </div>
                    )
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Preorders.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Preorders;
