import { useState } from 'react';
import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import { useQuery } from '@apollo/react-hooks';

// const RENDER_ROOT = gql`
//   query renderRoot ($rootName: String!, $uiRootId: ID) {
//     uiRootRender(rootName: $rootName, uiRootId: $uiRootId){
//       components{
//         queryData
//         uiCompInst{
//           createdAt
//           initialVariables
//           modifiedAt
//           prop
//           uiComp{
//             adminUi
//             available
//             createdAt
//             description
//             name
//             publicUi
//             sharedQuery
//             uiCompId
//           }
//           uiCompId
//           uiCompInstId
//         }
//         uiCompInstId
//       }
//       rootCompInstId
//       rootId
//     }
//   }
// `;

const GET_ROOT_LIST = gql`
 query getRootList($rootName: String){
    uiRootList(rootName: $rootName) {
      active
      publishAfter
      rootName
      uiCompInstId
      uiRootId
      url
    }
  }
`;
const GET_ROOT_LIST_GENERAL = gql`
  query getRootList{
    uiRootList{
      active
      publishAfter
      rootName
      uiCompInstId
      uiRootId
      url
    }
  }
`;
const useRootList = (rootName) => {
  const [rootListState, setRootList] = useState([]);
  const { data: rootListData } = useQuery(rootName ? GET_ROOT_LIST : GET_ROOT_LIST_GENERAL, {
    skip: window.location.pathname === '/',
    onCompleted: () => {
      if (!isEqual(rootListState, rootListData)) {
        setRootList(rootListData);
      }
    },
    variables: { rootName },
  });

  if (window.location.pathname !== '/') {
    return rootListState.uiRootList || [];
  }

  return [];
};

export default useRootList;
