import React, { Component } from 'react';
import PropTypes from 'prop-types';
import stringLimiter from '../../helpers/stringLimiter';
import urlGenerator from '../../helpers/urlGenerator';

const Address = (props) => {
  const { name, data } = props;

  return (
    <div className="ordertoprint-detail__div ordertoprint-detail-address__div">
      <h3 className="ordertoprint-header__h3">{name}</h3>
      <div className="ordertoprint-dash__div" />
      <p className="ordertoprint-detail__p">{data?.attention}</p>
      <p className="ordertoprint-detail__p">{data?.address[0]}</p>
      <p className="ordertoprint-detail__p">{data?.address[1]}</p>
      <p className="ordertoprint-detail__p">{data?.city}</p>
      <p className="ordertoprint-detail__p">{data?.state}</p>
      <p className="ordertoprint-detail__p">{data?.postalCode}</p>
      <p className="ordertoprint-detail__p">{data?.country}</p>
    </div>
  );
};

Address.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

const Other = (props) => {
  const { data } = props;
  return (
    <div className="ordertoprint-detail__div ordertoprint-detail-address__div">
      <h3 className="ordertoprint-header__h3">Other Details</h3>
      <div className="ordertoprint-dash__div" />
      <p className="ordertoprint-detail__p">{`Email: ${data.buyerEmail || ''}`}</p>
      <p className="ordertoprint-detail__p">
        {`Delivery Instructions: ${data.checkout?.deliveryInstructions || ''}`}
      </p>
    </div>
  );
};

Other.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

// eslint-disable-next-line
class OrderToPrint extends Component {
  render() {
    const { orderData } = this.props;
    // console.log(orderData);

    return (
      <div className="ordertoprint-container__div">
        <main className="ordertoprint-content__main">
          <div className="ordertoprint-content__div">
            <img
              src={urlGenerator('/S/layout/logo.png')}
              alt="Strand Logo"
              style={{ display: 'block', margin: 'auto', width: '150px' }}
            />
            <h2 className="ordertoprint-header__h2">{`Order # ${orderData?.orderNumber?.orderString}`}</h2>
            <section className="ordertoprint-address__section">
              <div
                style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}
                className="ordertoprint-address__div"
              >
                <Address name="Billing Address" data={orderData?.billAddr} />
                <Address name="Shipping Address" data={orderData?.shipAddr} />
                <Other data={orderData} />
              </div>
            </section>
            <section className="ordertoprint-items__section">
              <h2 className="ordertoprint-header__h2">Your Orders</h2>
              {
                orderData
                  && orderData.checkout
                  && orderData.checkout.tally
                  && orderData.checkout.tally.totalStv
                  && (
                  <h3
                    className="ordertoprint-header__h3"
                  >
                    {orderData.checkout.tally.totalStv ? `Total: $${(orderData.checkout.tally.totalStv / 100).toFixed(2)}` : 0}
                  </h3>
                  )
              }
              <div className="ordertoprint-items__div">
                <table>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>GTIN</th>
                      <th>Qty</th>
                      <th>Price$</th>
                      <th>Ext$</th>
                      <th>Tax$</th>
                      <th>Ship$</th>
                      <th>Status</th>
                      <th>Tracking#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      orderData
                        && orderData.orderLine
                        && orderData.orderLine.map((order) => {
                          const {
                            description,
                            priceStv,
                            qty,
                            gtin,
                            shippingStv,
                            extendedStv,
                            status,
                            id,
                            taxStv,
                            trackingNumber,
                          } = order;

                          return (
                            <tr
                              key={id}
                            >
                              {
                                description
                                  && description.length > 0
                                  && <td>{stringLimiter(description, 40)}</td>
                              }
                              <td>{gtin}</td>
                              <td>{qty}</td>
                              <td>{priceStv ? (priceStv / 100).toFixed(2) : '-'}</td>
                              <td>{extendedStv ? (extendedStv / 100).toFixed(2) : '-'}</td>
                              <td>{taxStv ? (taxStv / 100).toFixed(2) : '-'}</td>
                              <td>{shippingStv ? (shippingStv / 100).toFixed(2) : '-'}</td>
                              <td>{status}</td>
                              <td>{trackingNumber}</td>
                            </tr>
                          );
                        })
                    }
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </main>
      </div>
    );
  }
}

OrderToPrint.propTypes = {
  orderData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderToPrint;
