import urlGenerator from './urlGenerator';

const metaListDictionary = (str) => {
  const metaListObj = {
    bestsellers: {
      metaTag: 'Bestsellers',
      alt: 'Bestsellers',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    new_arrivals: {
      metaTag: 'New Arrivals',
      alt: 'New Arrivals',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    rare: {
      metaTag: 'Rare',
      alt: 'Featured in Rare',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    banned: {
      metaTag: 'Banned Books',
      alt: 'Banned Books',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pulitzer_prize_winners_2020: {
      metaTag: 'Pulitzer Prize Winners 2020',
      alt: 'Pulitzer Prize Winners 2020',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    aapi_2020: {
      metaTag: 'AAPI 2020',
      alt: 'Asian-Pacific Islander Heritage Month',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    merch: {
      metaTag: 'Merch',
      alt: 'Merchandise',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    apparel_youth: {
      metaTag: 'apparel youth ',
      alt: 'Youth',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    apparel_socks: {
      metaTag: 'apparel socks & slides',
      alt: 'Socks',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    apparel_hats_beanies: {
      metaTag: 'apparel hats and beanies',
      alt: 'Hats & Beanies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    apparel_sweatshirts_hoodies: {
      metaTag: 'apparel sweatshirts and hoodies',
      alt: 'Sweatshirts & Hoodies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    apparel_tshirts: {
      metaTag: 'apparel t-shirts',
      alt: 'T-shirts',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomLink: null,
    },
    teacher_appreciation: {
      metaTag: 'Teacher Appreciation',
      alt: 'Teacher Appreciation',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: urlGenerator('/S/banners/banner_teacher_gift_card.png'),
      promoteTopLink: '/gifts-apparel/gift-cards',
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_studies: {
      metaTag: 'Black Studies',
      alt: 'Black Studies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride: {
      metaTag: 'Pride',
      alt: 'Pride',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    fathers_day: {
      metaTag: "Father's Day",
      alt: "Father's Day",
      landingImage: null,
      landingDesc: '',
      promoteTopImage: urlGenerator('/S/banners/banners_fathers_day_gc.png'),
      promoteTopLink: '/gifts-apparel/gift-cards',
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    summer_reads_2020: {
      metaTag: 'Summer Reads 2020',
      alt: 'Summer Reads 2020',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    disability_pride_2020: {
      metaTag: 'Disability Pride 2020',
      alt: 'Disability Pride 2020',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    work_study_essentials_2020: {
      metaTag: 'Work & Study Essentials',
      alt: 'Work & Study Essentials',
      landingImage: urlGenerator('/S/banners/banners_lp_study-work-essentials-2020.jpg'),
      landingDesc: 'Everything you need for work, school, and studying! From stationery and notebooks to mind-opening reads, your next destination is productivity.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nyc_strand_robin_hood: {
      metaTag: 'NYC: Strand x Robin Hood',
      alt: 'NYC: Strand x Robin Hood',
      landingImage: urlGenerator('/S/banners/banners-nyc-robin-hood.jpg'),
      landingDesc: "The Strand is teaming up with <a href='https://www.robinhood.org/' style='text-decoration: none; color: #af272f;' target='_blank' rel='noopener noreferrer'>Robin Hood</a>, New York’s largest poverty fighting organization, to highlight stories about our home to benefit New Yorkers in need. With families needing more help than ever due to COVID-19, we wanted to remember the stories that bring our city together while giving 20% of sales to Robin Hood's COVID-19 Relief Fund.",
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lwv_strand: {
      metaTag: 'LWV x Strand',
      alt: 'LWV x Strand',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lwv_strand_2020: {
      metaTag: 'LWV X Strand V2',
      alt: 'LWV x Strand',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lwv_strand_2020_kids: {
      metaTag: 'LWV x Strand: Kids',
      alt: 'LWV x Strand: Kids',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lwv_strand_2020_merch: {
      metaTag: 'LWV x Strand: Merch',
      alt: 'LWV x Strand: Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    hispanic_heritage_lit: {
      metaTag: 'Hispanic Heritage: Lit',
      alt: 'Hispanic Heritage Month',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    in_memoriam_rbg: {
      metaTag: 'In Memoriam: RBG',
      alt: 'In Memoriam: RBG',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    vinyl_bestsellers: {
      metaTag: 'Vinyl',
      alt: 'Vinyl Bestsellers',
      landingImage: urlGenerator('/S/pages/vinyl_metalist_lp.png'),
      landingDesc: 'Check out some of Strand Book Store\'s vinyl bestsellers',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nba_longlist_2020_fiction: {
      metaTag: 'NBA Longlist 2020 Fiction',
      alt: 'National Book Awards 2020 Fiction',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nba_longlist_2020_nonfiction: {
      metaTag: 'NBA Longlist 2020 Nonfiction',
      alt: 'National Book Awards 2020 Nonfiction',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nba_2020_finalists: {
      metaTag: 'NBA 2020 Finalists',
      alt: 'National Book Awards 2020 Finalists',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mentally_im_here: {
      metaTag: "mentally i'm here",
      alt: "Mentally I'm Here",
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    discount_cart: {
      metaTag: 'Discount Cart',
      alt: 'Discount Cart',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    our_kinda_early_holiday_gift_guide: {
      metaTag: 'The (Very) Early Holiday Gift Guide',
      alt: 'Our (Kinda) Early Holiday Gift Guide',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    biographies: {
      metaTag: 'Biographies',
      alt: 'Biographies',
      landingImage: urlGenerator('/S/pages/lp-biography-assets-1300x300.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    fiction: {
      metaTag: 'Fiction',
      alt: 'Fiction',
      landingImage: urlGenerator('/S/pages/holidays-2020-fiction-landingpage-banner-1300x300.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mystery: {
      metaTag: 'Mystery',
      alt: 'Mystery',
      landingImage: urlGenerator('/S/pages/holidays-2020-mystery-landingpage-banner-1300x300.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    midori_francis_book_picks: {
      metaTag: 'Midori Francis Book Picks',
      alt: 'Midori Francis Book Picks',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    award_winners_2020: {
      metaTag: 'Award Winners 2020',
      alt: 'Award Winners 2020',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    native_american: {
      metaTag: 'Native American',
      alt: 'Native American',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nyt_notable_2020: {
      metaTag: 'NYT Notable 2020',
      alt: 'New York Times 100 Notable Books of 2020',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    beanies_1220: {
      metaTag: 'Beanies 1220',
      alt: 'Beanies',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    memoirs_bios_1220: {
      metaTag: 'Biographies1220',
      alt: 'Memoirs & Biographies',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    apparel_1220: {
      metaTag: 'Apparel 1220',
      alt: 'Apparel',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    totes_1220: {
      metaTag: 'Totes 1220',
      alt: 'Totes',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    kids_1220: {
      metaTag: 'Kids1220',
      alt: 'Kids',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mugs_1220: {
      metaTag: 'Mugs 1220',
      alt: 'Mugs',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    cooking_1220: {
      metaTag: 'Cooking1220',
      alt: 'Cooking',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    games_puzzles_1220: {
      metaTag: 'Games & Puzzles 1220',
      alt: 'Games & Puzzles',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    signed_1220: {
      metaTag: 'Signed',
      alt: 'Signed',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    signed: {
      metaTag: 'Signed',
      alt: 'Signed',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    last_minute_gifts_1220: {
      metaTag: 'Last Minute Gifts 1220',
      alt: 'Last Minute Gifts',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    books_that_got_us_through_2020: {
      metaTag: 'Books That Got Us Through 2020',
      alt: 'Books That Got Us Through 2020',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    first_lady_nyc_favorites: {
      metaTag: "First Lady of NYC's Favorites",
      alt: "First Lady of NYC's Favorites",
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    barack_obamas_books_about_america: {
      metaTag: 'Barack Obamas Books About America',
      alt: 'Barack Obama\'s Books About America',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    patti_smith_collection: {
      metaTag: 'Patti Smith Collection',
      alt: 'Patti Smith Collection',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    planners: {
      metaTag: 'Planners',
      alt: 'Planners',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    shelf_improvement: {
      metaTag: '2021 Is Your Year',
      alt: 'Shelf Improvement',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_bipoc: {
      metaTag: 'reads-olution BIPOC authors',
      alt: 'Reads-olutions: BIPOC authors',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_planet: {
      metaTag: 'reads-olution planet',
      alt: 'Reads-olutions: Planet',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_under_35: {
      metaTag: 'reads-olution under 35',
      alt: 'Reads-olutions: Under 35',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_long_books: {
      metaTag: 'reads-olutions long books',
      alt: 'Reads-olutions: Long Books',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_one_sitting: {
      metaTag: 'reads-olutions one sitting',
      alt: 'Reads-olutions: One Sitting',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2021_queer: {
      metaTag: 'reads-olutions queer',
      alt: 'Reads-olutions: Queer',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    new_years_sale_books_2021: {
      metaTag: "New Year's Sale Books",
      alt: "New Year's Sale: Books",
      landingImage: urlGenerator('/S/banners/banners_new_years_sale_2021_desktop.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    new_years_sale_merch_2021: {
      metaTag: "New Year's Sale Merch",
      alt: "New Year's Sale: Merch",
      landingImage: urlGenerator('/S/banners/banners_new_years_sale_2021_desktop.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    amanda_gorman_preorders: {
      metaTag: 'Amanda Gorman Pre-orders',
      alt: 'Amanda Gorman',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_2021: {
      metaTag: 'Black History Month 2021',
      alt: 'Black History Month',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    romance_2021: {
      metaTag: 'Romance',
      alt: 'Romance',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    valentines_merch_2021: {
      metaTag: 'Valentines Merch 2021',
      alt: 'Valentine\'s Day Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    read_with_jenna: {
      metaTag: "ReadwithJenna - Nancy's Book Recommendations",
      alt: 'Read with Jenna',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    short_stories: {
      metaTag: 'Short Stories',
      alt: 'Short Stories',
      landingImage: urlGenerator('/S/banners/banners_short_stories_banner_1350x275.jpg'),
      landingDesc: '*Your discount will be applied at checkout.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    indie_next_feb: {
      metaTag: 'Indie Next Feb',
      alt: 'The Indie Next List',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    toni_morrison_birthday: {
      metaTag: "Toni Morrison's Birthday",
      alt: 'Toni Morrison',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    empower_merch: {
      metaTag: 'EmpowerMERCH',
      alt: 'Empower Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_classics_collection: {
      metaTag: "Women's Classics",
      alt: "Women's Classics",
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    luvvie_ajayi_reading_list: {
      metaTag: "Luvvie Ajayi Jones' Reading List",
      alt: "Luvvie Ajayi Jones' Reading List",
      landingImage: '',
      landingDesc: '<em>New York Times</em> bestselling author Luvvie Ajayi Jones shares the titles that have defined her journey as an author. Her latest book <em>Professional Troublemaker</em> is out now.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    baublebar_x_strand: {
      metaTag: 'BaubleBar x The Strand',
      alt: 'BaubleBar x The Strand',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    joanna_rakoff_reading_list: {
      metaTag: "Joanna Rakoff's Reading List",
      alt: "Joanna Rakoff's Reading List",
      landingImage: '',
      landingDesc: 'In celebration of the release of the film adaptation of her memoir <em>My Salinger Year</em>, bestselling author Joanna Rakoff shares some of her favorite memoirs, all portraying the many ways a woman\'s coming of age can occur.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    silently_judging_your_grammar: {
      metaTag: 'Silently Judging Your Grammar',
      alt: 'Silently Judging Your Grammar',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gabriel_garcia_marquez: {
      metaTag: 'Gabriel Garcia Marquez Titles',
      alt: 'Gabriel Garcia Marquez',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    great_contemporary_women_authors: {
      metaTag: 'Great Contemporary Women Authors',
      alt: 'Great Contemporary Women Authors',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    indie_next: {
      metaTag: 'Indie Next',
      alt: 'The Indie Next List',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    women_in_sci_fi_fantasy: {
      metaTag: 'Women in Sci Fi Fantasy',
      alt: 'Women in Sci-Fi/Fantasy',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    irish_authors: {
      metaTag: 'Irish Authors',
      alt: 'Irish Authors',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    spring_sale_2021: {
      metaTag: 'Spring Sale 2021',
      alt: 'Spring Sale 2021',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_memoirs_2021: {
      metaTag: "Women's Memoirs",
      alt: "Women's Memoirs",
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    a_literary_guide_to_anti_asian_racism_in_america_2021: {
      metaTag: 'A Literary Guide to Anti-Asian Racism in America',
      alt: 'A Literary Guide to Anti-Asian Racism in America',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    classic_poetry: {
      metaTag: 'Classic Poetry',
      alt: 'Classic Poetry',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    a_decade_of_great_poetry: {
      metaTag: 'A Decade of Great Poetry',
      alt: 'A Decade of Great Poetry',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    beverly_cleary: {
      metaTag: 'Beverly Cleary',
      alt: 'Beverly Cleary',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    aapi_2021: {
      metaTag: 'AAPI 2021',
      alt: 'Asian-Pacific Islander Heritage Month',
      landingImage: '',
      landingDesc: '30% of proceeds from this collection will go to <a href="https://www.wingonwoand.co/aboutwowproject" style="text-decoration: none; color: #af272f;" target="_blank" rel="noopener noreferrer">The W.O.W. Project</a>, whose mission is to sustain ownership over Chinatown\'s future by growing, protecting and preserving Chinatown\'s creative culture through arts, culture and activism',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    queer_poetry_2021: {
      metaTag: 'Queer Poetry',
      alt: 'Queer Poetry',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    // staff_picks: {
    //   metaTag: 'Staff Picks',
    //   alt: 'Staff Picks',
    //   landingImage: '',
    //   landingDesc: '',
    //   promoteTopImage: null,
    //   promoteTopLink: null,
    //   promoteBottomImage: null,
    //   promoteBottomLink: null,
    // },
    strand_recommends: {
      metaTag: 'Recommends',
      alt: 'Strand Recommends',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    oscar_looks: {
      metaTag: 'Oscar Looks',
      alt: 'Oscar Looks',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mystery_2021: {
      metaTag: 'Mystery 2021',
      alt: 'Mystery',
      landingImage: urlGenerator('/S/2021-promo/promos_mystery_2021.jpg'),
      landingImageMobile: urlGenerator('/S/2021-promo/promos_mystery_2021_mysterysale_350x300.jpg'),
      landingDesc: 'In celebration of Sir Arthur Conan Doyle, born May 22nd 1859.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    book_to_screen_2021: {
      metaTag: 'From Book to Screen',
      alt: 'From Book to Screen',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    graphic_memoirs_2021: {
      metaTag: 'Graphic Memoirs',
      alt: 'Graphic Memoirs',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride_2021: {
      metaTag: 'Pride 2021',
      alt: 'Pride',
      landingImage: urlGenerator('/S/banners/banners_pride_2021_desktop.jpg'),
      landingDesc: "For this year's PRIDE collection, we'll be donating 30% of sales to <a href='https://hmi.org/' style='text-decoration: none; color: #af272f;' target='_blank' rel='noopener noreferrer'>The Hetrick-Martin Institute</a> the nation's largest and oldest LGBTQIA+ youth service organization.",
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride_merch_2021: {
      metaTag: 'Pride 2021 - Merch',
      alt: 'Pride Merch',
      landingImage: urlGenerator('/S/banners/banners_pride_2021_desktop.jpg'),
      landingDesc: "For this year's PRIDE collection, we'll be donating 30% of sales to <a href='https://hmi.org/' style='text-decoration: none; color: #af272f;' target='_blank' rel='noopener noreferrer'>The Hetrick-Martin Institute</a> the nation's largest and oldest LGBTQIA+ youth service organization.",
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    beach_reads_2021: {
      metaTag: 'Beach Reads 2021',
      alt: 'Beach Reads',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    books_to_make_you_cry_2021: {
      metaTag: 'Books That Make You Cry',
      alt: 'Books That\'ll Make You Cry',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pulitzer_2021: {
      metaTag: 'Pulitzer 2021',
      alt: 'Pulitzer 2021',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    '30_to_watch_2021': {
      metaTag: '30 to Watch',
      alt: '30 to Watch',
      landingImage: urlGenerator('/S/banners/banners_2021_30_to_watch_1350x275.jpg'),
      landingDesc: 'Discover 30 of the most exciting authors we\'re keeping our eyes on.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    great_american_authors_2021: {
      metaTag: 'American Authors',
      alt: 'Great American Authors',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    sports_memoirs_2021: {
      metaTag: 'Sports Memoirs',
      alt: 'Sports Memoirs',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    summer_thrills_2021: {
      metaTag: 'Summer Thrills',
      alt: 'Summer Thrills',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    strand_university_2021: {
      metaTag: 'Strand University',
      alt: 'Strand University Books',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    strand_university_merch_2021: {
      metaTag: 'Strand University Merch',
      alt: 'Strand University Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    strand_university_apparel_2021: {
      metaTag: 'Strand University Apparel',
      alt: 'Strand University Apparel',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    strand_university_stationery_2021: {
      metaTag: 'Strand University Stationery',
      alt: 'Strand University Stationery',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    ya_bestsellers_2021: {
      metaTag: 'YA Bestsellers',
      alt: 'YA Bestsellers',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    booker_prize_longlist_2021: {
      metaTag: 'Booker Prize Longlist 2021',
      alt: 'Booker Prize Longlist 2021',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    indie_script_collection_2021: {
      metaTag: 'Indie Script Collection',
      alt: 'Indie Script Collection',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    classic_merch_2021: {
      metaTag: 'Classic Merch',
      alt: 'Classic Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    kids_bestsellers_2021: {
      metaTag: 'Kids Bestsellers',
      alt: 'Kids Bestsellers',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    epic_journeys_2021: {
      metaTag: 'Epic Journeys',
      alt: 'Epic Journeys',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    national_translation_month_2021: {
      metaTag: 'National Translation Month 2021',
      alt: 'National Translation Month',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    sally_rooney_bookshelf: {
      metaTag: 'Sally Rooney Bookshelf',
      alt: 'Sally Rooney\'s Bookshelf',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    caitlin_doughty_bookshelf: {
      metaTag: 'Caitlin Doughty Bookshelf',
      alt: 'Caitlin Doughty\'s Bookshelf',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    erin_morgenstern_bookshelf: {
      metaTag: 'Erin Morgenstern Bookshelf',
      alt: 'Erin Morgenstern\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    carmen_maria_machado_bookshelf: {
      metaTag: 'Carmen Maria Machado Bookshelf',
      alt: 'Carmen Maria Machado\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    jacqueline_woodson_bookshelf: {
      metaTag: 'Jacqueline Woodson Bookshelf',
      alt: 'Jacqueline Woodson\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    anne_carson_bookshelf: {
      metaTag: 'Anne Carson Bookshelf',
      alt: 'Anne Carson\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    elizabeth_acevedo_bookshelf: {
      metaTag: 'Elizabeth Acevedo Bookshelf',
      alt: 'Elizabeth Acevedo\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    marlon_james_bookshelf: {
      metaTag: 'Marlon James Bookshelf',
      alt: 'Marlon James\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    rebecca_solnit_bookshelf: {
      metaTag: 'Rebecca Solnit Bookshelf',
      alt: 'Rebecca Solnit\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    kevin_kwan_bookshelf: {
      metaTag: 'Kevin Kwan Bookshelf',
      alt: 'Kevin Kwan\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    stephanie_danler_bookshelf: {
      metaTag: 'Stephanie Danler Bookshelf',
      alt: 'Stephanie Danler\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lauren_groff_bookshelf: {
      metaTag: 'Lauren Groff Bookshelf',
      alt: 'Lauren Groff\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gloria_steinem_bookshelf: {
      metaTag: 'Gloria Steinem Bookshelf',
      alt: 'Gloria Steinem\'s Bookshelf',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    hispanic_heritage_month_2021: {
      metaTag: 'Hispanic Heritage Month',
      alt: 'Hispanic Heritage Month',
      landingImage: '',
      landingDesc: '30% of sales to benefit the <a href="https://hispanicfederation.org/" style="text-decoration: none; color: #af272f;" target="_blank" rel="noopener noreferrer">Hispanic Federation</a>',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    horror_2021: {
      metaTag: 'Horror 2021',
      alt: 'Horror',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mirtha_michelle_castro_marmol_reading_list: {
      metaTag: 'Mirtha Michelle Castro Marmol Reading List',
      alt: 'Mirtha Michelle Castro Marmol\'s Reading List',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    banned_books_2021: {
      metaTag: 'Banned Books 2021',
      alt: 'Banned Books',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    spooktober_books: {
      metaTag: 'Spooktober Books',
      alt: 'Spooktober Books',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    spooktober_merch: {
      metaTag: 'Spooktober Merch',
      alt: 'Spooktober Merch',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    indigenous_voices: {
      metaTag: 'Indigenous Voices',
      alt: 'Indigenous Voices',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_books_for_adults: {
      metaTag: 'Gift Guide 2021 Books for Adults',
      alt: 'Gift Guide 2021 Books for Adults',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_food_and_drink: {
      metaTag: 'Gift Guide 2021 Food & Drink',
      alt: 'Gift Guide 2021 Food & Drink',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_visual_arts: {
      metaTag: 'Gift Guide 2021 Visual Arts',
      alt: 'Gift Guide 2021 Visual Arts',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_young_adult: {
      metaTag: 'Gift Guide 2021 Young Adult',
      alt: 'Gift Guide 2021 Young Adult',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_gifts_for_kids: {
      metaTag: 'Gift Guide 2021 Gifts for Kids',
      alt: 'Gift Guide 2021 Gifts for Kids',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_gifts_under_50: {
      metaTag: 'Gift Guide 2021 Gifts Under 50',
      alt: 'Gift Guide 2021 Gifts Under $50',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_gifts_under_20: {
      metaTag: 'Gift Guide 2021 Gifts Under 20',
      alt: 'Gift Guide 2021 Gifts Under $20',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_stocking_stuffers: {
      metaTag: 'Gift Guide 2021 Stocking Stuffers',
      alt: 'Gift Guide 2021 Stocking Stuffers',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    cooking_2021: {
      metaTag: 'Cooking 2021',
      alt: 'Cooking 2021',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2021_sized_items: {
      metaTag: 'Gift Guide 2021 Sized Items',
      alt: 'Gift Guide 2021 Sized Items',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    cats_cats_and_more_cats: {
      metaTag: 'Cats, Cats and More Cats',
      alt: 'Cats, Cats and More Cats',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    transgender_awareness_month: {
      metaTag: 'Transgender Awareness Month',
      alt: 'Transgender Awareness Month',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_apparel: {
      metaTag: '12 Days Apparel',
      alt: '12 Days Apparel',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_award_winners: {
      metaTag: '12 Days Award Winners',
      alt: '12 Days Award Winners',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_best_fiction_2021: {
      metaTag: '12 Days Best Fiction of 2021',
      alt: '12 Days Best Fiction of 2021',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_bestsellers: {
      metaTag: '12 Days Bestsellers',
      alt: '12 Days Bestsellers',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_biographies: {
      metaTag: '12 Days Biographies',
      alt: '12 Days Biographies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_cooking_and_cocktails: {
      metaTag: '12 Days Cooking & Cocktails',
      alt: '12 Days Cookies & Cocktails',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_hats: {
      metaTag: '12 Days Hats',
      alt: '12 Days Hats',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_mugs_and_drinkware: {
      metaTag: '12 Days Mugs & Drinkware',
      alt: '12 Days Mugs & Drinkware',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_signed_copies: {
      metaTag: '12 Days Signed Copies',
      alt: '12 Days Signed Copies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    twelve_days_totes: {
      metaTag: '12 Days Totes',
      alt: '12 Days Totes',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    national_book_award_winners_2021: {
      metaTag: 'National Book Award Winners 2021',
      alt: 'National Book Award Winners 2021',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nyt_best_books_2021: {
      metaTag: 'NYT Best Books 2021',
      alt: 'NYT Best Books 2021',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    shoe_charms: {
      metaTag: 'shoe charms',
      alt: 'Shoe Charms',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_fiction_2021: {
      metaTag: 'Best Fiction of 2021',
      alt: 'Best Fiction',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_nonfiction_2021: {
      metaTag: 'Best Non-Fiction of 2021',
      alt: 'Best Non-Fiction',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_scifi_mystery_2021: {
      metaTag: 'Best Sci-Fi and Mystery of 2021',
      alt: 'Best Sci-Fi/Mystery',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_ya_2021: {
      metaTag: 'Best YA of 2021',
      alt: 'Best YA',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    holiday_clearance_2021: {
      metaTag: 'Holiday Clearance 2021',
      alt: 'Holiday Clearance',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nepal_tea_book_pairings: {
      metaTag: 'Nepal Tea Book Pairings',
      alt: 'Nepal Tea Book Pairings',
      landingImage: '',
      landingDesc: '<a href="https://nepalteallc.com/collections/teas" target="_blank" rel="noopener noreferrer">Nepal Tea</a> is a social enterprise that distributes the finest high mountain organic teas from the foothills of Mt. Kanchanjangha, the&nbsp;third highest mountain in the world.&nbsp;Not only do they empower farmers economically by providing them better revenues,&nbsp;but they also&nbsp;uplift them socially by providing free housing, free education to their children, and distributing cows for additional income. On the other side, consumers receive fresh high-quality teas at a fair price and an opportunity to directly impact the underprivileged farmers. <a href="https://nepalteallc.com/collections/teas" target="_blank" rel="noopener noreferrer">Explore Nepal Tea&rsquo;s many varieties of hand-plucked, certified organic teas!</a>',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    puzzle_month_2022: {
      metaTag: 'Puzzle Month',
      alt: 'Puzzles',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_over_50: {
      metaTag: 'Reads-olutions: Author Over 50',
      alt: 'Reads-olutions: Author Over 50',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_bipoc: {
      metaTag: 'Reads-olutions: BIPOC author',
      alt: 'Reads-olutions: BIPOC author',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_graphic_novel: {
      metaTag: 'Reads-olutions: Graphic Novel',
      alt: 'Reads-olutions: Graphic Novel',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_in_translation: {
      metaTag: 'Reads-olutions: In Translation',
      alt: 'Reads-olutions: In Translation',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_long_book: {
      metaTag: 'Reads-olutions: Long Book',
      alt: 'Reads-olutions: Long Book',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_manga: {
      metaTag: 'Reads-olutions: Manga',
      alt: 'Reads-olutions: Manga',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_poetry: {
      metaTag: 'Reads-olutions: Poetry Collection',
      alt: 'Reads-olutions: Poetry Collection',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_queer: {
      metaTag: 'Reads-olutions: Queer Narrator',
      alt: 'Reads-olutions: Queer Narrator',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_one_sitting: {
      metaTag: 'Reads-olutions: Read in one sitting',
      alt: 'Reads-olutions: Read in one sitting',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_short_story: {
      metaTag: 'Reads-olutions: Short Story',
      alt: 'Reads-olutions: Short Story',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    readsolutions_2022_womens_rights: {
      metaTag: 'Reads-olutions: Women\'s Rights',
      alt: 'Reads-olutions: Women\'s Rights',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    ben_recommends: {
      metaTag: 'Ben Recommends',
      alt: 'Ben Recommends',
      landingImage: urlGenerator('/S/banners/banners_2022_ben_recommends.png'),
      landingDesc: '20% of proceeds from this collection will go to the <a href="https://hmi.org/" target="_blank" rel="noopener noreferrer">Hetrick-Martin Institute</a>, which believes all young people, regardless of sexual orientation or identity, deserve a safe and supportive environment in which to achieve their full potential.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    hobbies: {
      metaTag: 'New Year, New Hobbies',
      alt: 'New Year, New Hobbies',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    fred_bass_day: {
      metaTag: 'Fred Bass Day',
      alt: 'Fred Bass Day',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    modern_love: {
      metaTag: 'Modern Love',
      alt: 'Modern Love',
      landingImage: urlGenerator('/S/banners/banners_2022_modern_love-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_modern_love-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    bhm: {
      metaTag: 'Black History Month 2022',
      alt: 'Black History Month 2022',
      landingImage: urlGenerator('/S/banners/banners_2022_bhm-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_bhm-mobile.png'),
      landingDesc: '20% of proceeds from this collection will go to <a href="https://www.brooklynnaacp.org/" target="_blank" rel="noopener noreferrer">The Brooklyn NAACP</a>, whose mission is to ensure the political, educational, social, and economic equality of rights of all persons and to eliminate race-based discrimination.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    tiktok: {
      metaTag: 'The Strand on TikTok',
      alt: 'The Strand on TikTok',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    toni_morrison: {
      metaTag: 'Toni Morrison',
      alt: 'Toni Morrison',
      landingImage: urlGenerator('/S/banners/banners_2022_toni_morrison-desktop.png'),
      landingDesc: '20% of all sales from this collection will be donated to <a href="https://www.brooklynnaacp.org/" target="_blank" rel="noopener noreferrer">The Brooklyn NAACP',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    ukraine: {
      metaTag: 'Ukraine',
      alt: 'Ukraine',
      landingImage: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_classics_2022: {
      metaTag: 'Women\'s Classics 2022',
      alt: 'Women’s Classics 2022',
      landingImage: urlGenerator('/S/banners/banners_2022_womens_classics-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_womens_classics-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    seuss: {
      metaTag: 'Dr. Seuss',
      alt: 'Dr. Seuss',
      landingImage: urlGenerator('/S/banners/banners_2022_dr_seuss-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_dr_seuss-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    trailblazing_women: {
      metaTag: 'Women in STEAM',
      alt: 'Women in STEAM',
      landingImage: urlGenerator('/S/banners/banners_2022_trailblazing_women-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_trailblazing_women-mobile.png'),
      landingDesc: '20% of proceeds from this collection will be donated to <a href="https://girlswritenow.org/about/" target="_blank" rel="noopener noreferrer">Girls Write Now</a>, whose mission is to break down barriers of gender, race, age and poverty to&nbsp;mentor the next generation of writers and leaders&nbsp;who are impacting businesses, shaping culture and creating change.',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    irish_authors_2022: {
      metaTag: 'Irish Authors 2022',
      alt: 'Irish Authors 2022',
      landingImage: urlGenerator('/S/banners/banners_2022_irish_authors-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_irish_authors-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    spring_collection_2022: {
      metaTag: 'Spring Collection 2022',
      alt: 'Spring Collection 2022',
      landingImage: urlGenerator('/S/banners/banners_spring_collection_2022-desktop.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    women_in_poetry_2022: {
      metaTag: 'Women in Poetry 2022',
      alt: 'Women in Poetry 2022',
      landingImage: urlGenerator('/S/banners/banners_women_in_poetry_2022-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_women_in_poetry_2022-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_totes: {
      metaTag: 'Tote Bestsellers',
      alt: 'Tote Bestsellers',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    humor_me: {
      metaTag: 'Humor Me: Laugh Out Loud Reads',
      alt: 'Humor Me: Laugh Out Loud Reads',
      landingImage: urlGenerator('/S/banners/banners_humor_me-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_humor_me-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    classic_poetry_2022: {
      metaTag: 'Classic Poetry 2022',
      alt: 'Classic Poetry 2022',
      landingImage: urlGenerator('/S/banners/banners_2022_classic_poetry-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_classic_poetry-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    staff_poetry_picks: {
      metaTag: 'Strand Poetry Picks: Bookseller Favorites',
      alt: 'Strand Poetry Picks: Bookseller Favorites',
      landingImage: urlGenerator('/S/banners/banners_2022_staff_poetry_picks-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_staff_poetry_picks-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    teen_literature_day_2022: {
      metaTag: 'Teen Literature Day 2022',
      alt: 'Teen Literature Day 2022',
      landingImage: urlGenerator('/S/banners/banners_2022_teen_literature_day-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_teen_literature_day-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    march_for_science: {
      metaTag: 'March for Science Climate Change',
      alt: 'Climate Change',
      landingImage: urlGenerator('/S/banners/banners_2022_climate_change-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_climate_change-mobile.png'),
      landingDesc: '20% of proceeds from this collection will be donated to </strong><a href="https://www.marchforscience.nyc/">March for Science NYC</a><strong>, a non-partisan platform formed by the greater scientific community that stands for open access of scientific information, the use of science for the common good and in the preservation of an informed democracy, and the protection of human and environmental rights.</strong>',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    modern_shakespeare: {
      metaTag: 'Modern Shakespeare',
      alt: 'Modern Shakespeare',
      landingImage: urlGenerator('/S/banners/banners_2022_modern_shakespeare-desktop.png'),
      landingImageMobile: urlGenerator('/S/banners/banners_2022_modern_shakespeare-mobile.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    aapi_2022: {
      metaTag: 'AAPI Heritage 2022',
      alt: 'AAPI Heritage Month 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_rights_2022: {
      metaTag: 'Women\'s Rights 2022',
      alt: 'Women\'s Rights 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    short_stories_2022: {
      metaTag: 'Short Stories 2022',
      alt: 'Short Stories 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    graduation_2022: {
      metaTag: 'Graduation 2022',
      alt: 'Graduation 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride_2022: {
      metaTag: 'Pride 2022',
      alt: 'Pride 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    juneteenth_2022: {
      metaTag: 'Juneteenth 2022',
      alt: 'Juneteenth 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    summer_totes_2022: {
      metaTag: 'Summer Totes 2022',
      alt: 'Summer Totes 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride_apparel_2022: {
      metaTag: 'Pride Apparel 2022',
      alt: 'Pride Apparel 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    summer_romance_2022: {
      metaTag: 'Summer Romance 2022',
      alt: 'Summer Romance 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nyc_2022: {
      metaTag: 'NYC 2022',
      alt: 'NYC 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nyrb_lifetime_top_50_2022: {
      metaTag: 'NYRB Lifetime Top 50 2022',
      alt: 'NYRB Lifetime Top 50 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    modern_classics_2022: {
      metaTag: 'Modern Classics 2022',
      alt: 'Modern Classics 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    campus_to_classroom: {
      metaTag: 'Modern Classics 2022',
      alt: 'Campus to Classroom',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    collegiate_merch: {
      metaTag: 'Collegiate Merch',
      alt: 'Collegiate Collection',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    hispanic_heritage_month_2022: {
      metaTag: 'Hispanic Heritage Month 2022',
      alt: 'Hispanic Heritage Month 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    horror_by_women: {
      metaTag: 'Horror by Women 2022',
      alt: 'Horror by Women 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    fall_apparel_promo: {
      metaTag: 'Fall Apprel Promo',
      alt: 'Fall Apparel',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_annie_ernaux: {
      metaTag: '30 Days Annie Ernaux',
      alt: '30 Days Annie Ernaux',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_banned_books: {
      metaTag: '30 Days Banned Books',
      alt: '30 Days Banned Books',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_games_and_puzzles: {
      metaTag: '30 Days Games & Puzzles',
      alt: '30 Days Games & Puzzles',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_romance: {
      metaTag: '30 Days Romance',
      alt: '30 Days Romance',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_book_accessories: {
      metaTag: '30 Days Book Accessories',
      alt: '30 Days Book Accessories',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_adults: {
      metaTag: 'Gift Guide 2022 Adults',
      alt: 'Gift Guide 2022 Adults',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_apparel: {
      metaTag: 'Gift Guide 2022 Apparel',
      alt: 'Gift Guide 2022 Apparel',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_food_and_drink: {
      metaTag: 'Gift Guide 2022 Food & Drink',
      alt: 'Gift Guide 2022 Food & Drink',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_gifts_for_kids: {
      metaTag: 'Gift Guide 2022 Gifts for Kids',
      alt: 'Gift Guide 2022 Gifts for Kids',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_gifts_under_20: {
      metaTag: 'Gift Guide 2022 Gifts Under 20',
      alt: 'Gift Guide 2022 Gifts Under $20',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_gifts_under_50: {
      metaTag: 'Gift Guide 2022 Gifts Under 50',
      alt: 'Gift Guide 2022 Gifts Under $50',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2022_the_arts: {
      metaTag: 'Gift Guide 2022 The Arts',
      alt: 'Gift Guide 2022 The Arts',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    /* gift_guide_2022_totes: {
      metaTag: 'Gift Guide 2022 Totes',
      alt: 'Gift Guide 2022 Totes',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    }, */
    gift_guide_2022_young_adults: {
      metaTag: 'Gift Guide 2022 Young Adult',
      alt: 'Gift Guide 2022 Young Adult',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_visual_arts: {
      metaTag: '30 Days Visual Arts',
      alt: 'Visual Arts',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_note_books: {
      metaTag: '30 Days Notebooks',
      alt: 'Notebooks & Journals',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_mystery: {
      metaTag: '30 Days Mystery',
      alt: 'Mystery Novels',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_now_paperback: {
      metaTag: '30 Days Now in Paperback',
      alt: 'Now in Paperback',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_books_for_kids: {
      metaTag: '30 Days Books for Kids',
      alt: 'Books for Kids',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_gifts_for_kids: {
      metaTag: '30 Days Gifts for Kids',
      alt: 'Gifts for Kids',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_biographies: {
      metaTag: '30 Days Biographies',
      alt: 'Biographies',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_seasonal_gifts: {
      metaTag: '30 Days Seasonal Gifts',
      alt: 'Seasonal Gifts',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_totes: {
      metaTag: '30 Days Totes',
      alt: 'Totes',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_bestsellers: {
      metaTag: '30 Days Bestsellers',
      alt: 'Bestsellers',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_signed_books: {
      metaTag: '30 Days Signed Books',
      alt: 'Signed Books',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_favorite_ya: {
      metaTag: '30 Days Favorite YA of 2022',
      alt: 'Favorite Young Adult',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_gifts_under_20: {
      metaTag: '30 Days Gifts Under 20',
      alt: 'Gifts Under $20',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_science_fiction: {
      metaTag: '30 Days Science Fiction',
      alt: 'Science Fiction',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_award_winners: {
      metaTag: '30 Days Award Winners',
      alt: 'Award Winners',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_blind_date_with_a_book: {
      metaTag: '30 Days Blind Date with a Book',
      alt: 'Strand\'s Blind Date with a Book',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_cookbooks: {
      metaTag: '30 Days Cookbooks',
      alt: 'Cookbooks',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_mugs: {
      metaTag: '30 Days Mugs',
      alt: 'Mugs',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    new_york_times_2022_ten: {
      metaTag: 'New York Times 2022 Top 10',
      alt: 'New York Times Top 10 Books of 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    thirty_days_favorite_fictions_2022: {
      metaTag: '30 Days Favorite Fiction of 2022',
      alt: 'Strand’s Favorite Fiction of 2022',
      landingImage: '',
      landingImageMobile: '',
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_2023: {
      metaTag: 'Black History Month 2023',
      alt: 'Black History Month',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    lady_antiheroes_2023: {
      metaTag: 'Lady Antiheroes 2023',
      alt: 'Lady Antiheroes',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    modern_classic_women: {
      metaTag: 'Modern Classics by Women 23',
      alt: 'Modern Classics by Women',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_merch_2023: {
      metaTag: 'Womens Merch 2023',
      alt: 'Trailblazing Women',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    womens_art_2023: {
      metaTag: 'Womens Art 2023',
      alt: 'Women in Art',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    horror_by_women_2022: {
      metaTag: 'Horror by Women 2022',
      alt: 'Horror by Women',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mile_18_collection: {
      metaTag: '18 Miles Collection',
      alt: '18 Miles Collection',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    queer_poetry: {
      metaTag: 'Queer Poetry',
      alt: '',
      landingImage: urlGenerator('/S/banners/national_poetry.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    living_poets_2023: {
      metaTag: 'Living Poets 2023',
      alt: '',
      landingImage: urlGenerator('/S/banners/living_poets.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    aapi_2023: {
      metaTag: 'AAPI New Yorkers 2023',
      alt: '',
      landingImage: urlGenerator('/S/banners/2023_banners_aapi.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    mothers_day_2023: {
      metaTag: 'Mother\'s Day 2023',
      alt: 'Mother’s Day Gift Guide',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    queer_debut_authors: {
      metaTag: 'Queer Debut Authors',
      alt: 'New Debuts from Queer Authors',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    alec_soth_signed_books: {
      metaTag: 'Alec Soth Signed Books',
      alt: 'Signed Books by Alec Soth',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    new_merch: {
      metaTag: 'New Merch',
      alt: 'New Merch',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    summer_of_love: {
      metaTag: 'Summer of Love',
      alt: 'Summer of Love',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    back_to_school_2023: {
      metaTag: 'Back to School 2023',
      alt: 'Back to School Essentials',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    phaidon_100: {
      metaTag: 'Phaidon 100',
      alt: 'Phaidon: 100 Years of Creativity',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    hispanic_heritage_2023: {
      metaTag: 'Hispanic Heritage- NYers 2023',
      alt: '',
      landingImage: urlGenerator('/S/banners/2023_hispanic_heritage.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    amateur_detectives_2023: {
      metaTag: 'Amateur Detectives 23',
      alt: 'Amateur Detectives',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    immature_detectives_2023: {
      metaTag: 'Immature Detectives 23',
      alt: 'Immature Detectives',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    jon_fosse: {
      metaTag: 'Jon Fosse',
      alt: 'Jon Fosse',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    nonfic_november_2023: {
      metaTag: 'Nonfic November 2023',
      alt: 'Nonfiction November',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_apparel: {
      metaTag: 'Gift Guide 2023 Apparel',
      alt: 'Holiday Shop: Apparel',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_cooking: {
      metaTag: 'Gift Guide 2023 Cooking',
      alt: 'Holiday Shop: Cooking',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_fiction: {
      metaTag: 'Gift Guide 2023 Fiction',
      alt: 'Holiday Shop: Fiction',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_gift: {
      metaTag: 'Gift Guide 2023 Gift',
      alt: 'Holiday Shop: Unique Gifts',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_kids: {
      metaTag: 'Gift Guide 2023 Kids',
      alt: 'Holiday Shop: For Kids',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_nonfiction: {
      metaTag: 'Gift Guide 2023 Nonfiction',
      alt: 'Holiday Shop: Non-Fiction',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_signed: {
      metaTag: 'Gift Guide 2023 Signed',
      alt: 'Holiday Shop: Signed Books',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_studio_strand: {
      metaTag: 'Gift Guide 2023 Studio Strand',
      alt: 'Holiday Shop: Studio Strand',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_translation: {
      metaTag: 'Gift Guide 2023 Translation',
      alt: 'Holiday Shop: Works in Translation',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    gift_guide_2023_visual_arts: {
      metaTag: 'Gift Guide 2023 Visual Arts',
      alt: 'Holiday Shop: Visual Arts',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
      gift_guide_2023_young_adult: {
      metaTag: 'Gift Guide 2023 Young Adult',
      alt: 'Holiday Shop: YA',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    calendars_2024: {
      metaTag: '2024 Calendars',
      alt: '2024 Calendars',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    holiday_decor: {
      metaTag: 'Holiday Decor',
      alt: 'Holiday Decor',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    signed_neil_gaiman: {
      metaTag: 'Signed Neil Gaiman',
      alt: 'Signed Neil Gaiman',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    top_totes_2023: {
      metaTag: '2023 Top Totes',
      alt: '2023 Top Totes',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_selling_fiction_2023: {
      metaTag: '2023 Bestselling Fiction',
      alt: '2023 Bestselling Fiction',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    best_selling_nonfiction_2023: {
      metaTag: '2023 Bestselling Nonfiction',
      alt: '2023 Bestselling Nonfiction',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    blind_date_with_a_book: {
      metaTag: 'Blind Date With a Book',
      alt: 'Blind Date With a Book',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    beanies: {
      metaTag: 'Beanies',
      alt: 'Beanies',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_2024: {
      metaTag: 'Black History Month 2024',
      alt: 'Black History Month 2024',
      landingImage: urlGenerator('/S/banners/BHM_2024_banner_1350.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    romance_2024: {
      metaTag: 'Romance 2024',
      alt: 'Love Stories',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    valentines_day_gifts: {
      metaTag: 'Valentine\'s Day Gifts',
      alt: 'Gifts for Valentine\'s Day',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_visual_arts: {
      metaTag: 'BHM-Visual Arts',
      alt: 'Black History Month: Visual Arts',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_film_drama: {
      metaTag: 'BHM - Film/Drama',
      alt: 'Black History Month: Film & Drama',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_childrens_ya: {
      metaTag: 'BHM - Children\'s/YA',
      alt: 'Black History Month: Children\'s & YA',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_literature: {
      metaTag: 'BHM - Literature',
      alt: 'Black History Month: Literature',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    black_history_month_music: {
      metaTag: 'BHM - Music',
      alt: 'Black History Month: Music',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    born_in_the_big_apple: {
      metaTag: 'Born in the Big Apple',
      alt: '',
      landingImage: urlGenerator('/S/banners/born_in_the_big_apple.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    small_press_poetry: {
      metaTag: 'Small Press Poetry',
      alt: '',
      landingImage: urlGenerator('/S/banners/small_press_poetry.jpg'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    one_bad_mother: {
      metaTag: 'One Bad Mother',
      alt: 'One Bad Mother: Tales of Imperfect Parenting',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    cinco_de_mayo: {
      metaTag: 'Cinco de Mayo',
      alt: 'Five Titles by Five Authors',
      landingImage: urlGenerator('/S/banners/cinco_de_mayo.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    edgar_awards_2024: {
      metaTag: 'Edgar Awards 2024',
      alt: '2024 Edgar Award Nominees',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    kids_book_week: {
      metaTag: 'Kids Book Week',
      alt: 'Children’s Book Week',
      landingImage: null,
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
    pride_2024: {
      metaTag: 'Pride 2024',
      alt: 'Pride 2024',
      landingImage: urlGenerator('/S/banners/pride_2024-min.png'),
      landingDesc: '',
      promoteTopImage: null,
      promoteTopLink: null,
      promoteBottomImage: null,
      promoteBottomLink: null,
    },
  };

  const defaultList = {
    metaTag: null,
    alt: null,
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  };
  return metaListObj[str] ? metaListObj[str] : {
    ...defaultList,
    metaTag: str,
    alt: str,
    noMetalist: true,
  };
};

export default metaListDictionary;

export const metaListFlags = {
  // 9780802157003: 'Dreaming of Alien Existence',
  // 9781982112691: 'Dreaming of going to a comedy show',
  // 9780593085332: 'Dreaming of romance & espionage',
  // 9780316316132: 'Dreaming of travel and self-discovery',
  // 9780374102098: 'Dreaming of something Grimm',
  // 9780062444134: 'Dreaming of a Love Is Love space adventure',
  // 9780316485340: 'Dreaming of embracing your quirkiness',
  // 9780062978578: 'Dreaming of being the villian',
  // 9780062334978: 'Dreaming of saving the post office',
  // 9780593182574: 'Dreaming of Broadway',
};

export const deletedMetaList = {
  aapi_2020: {
    metaTag: 'AAPI 2020',
    alt: 'Asian-Pacific Islander Heritage Month',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  apparel_1220: {
    metaTag: 'Apparel 1220',
    alt: 'Apparel',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  award_winners_2020: {
    metaTag: 'Award Winners 2020',
    alt: 'Award Winners 2020',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  banned: {
    metaTag: 'Banned Books',
    alt: 'Banned Books',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  barack_obamas_books_about_america: {
    metaTag: 'Barack Obamas Books About America',
    alt: 'Barack Obama\'s Books About America',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  beanies_1220: {
    metaTag: 'Beanies 1220',
    alt: 'Beanies',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  memoirs_bios_1220: {
    metaTag: 'Biographies1220',
    alt: 'Memoirs & Biographies',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  books_that_got_us_through_2020: {
    metaTag: 'Books That Got Us Through 2020',
    alt: 'Books That Got Us Through 2020',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  cooking_1220: {
    metaTag: 'Cooking1220',
    alt: 'Cooking',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  disability_pride_2020: {
    metaTag: 'Disability Pride 2020',
    alt: 'Disability Pride 2020',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  fathers_day: {
    metaTag: "Father's Day",
    alt: "Father's Day",
    landingImage: null,
    landingDesc: '',
    promoteTopImage: urlGenerator('/S/banners/banners_fathers_day_gc.png'),
    promoteTopLink: '/gifts-apparel/gift-cards',
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  fiction: {
    metaTag: 'Fiction',
    alt: 'Fiction',
    landingImage: urlGenerator('/S/pages/holidays-2020-fiction-landingpage-banner-1300x300.jpg'),
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  first_lady_nyc_favorites: {
    metaTag: "First Lady of NYC's Favorites",
    alt: "First Lady of NYC's Favorites",
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  games_puzzles_1220: {
    metaTag: 'Games & Puzzles 1220',
    alt: 'Games & Puzzles',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  hispanic_heritage_lit: {
    metaTag: 'Hispanic Heritage: Lit',
    alt: 'Hispanic Heritage Month',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  in_memoriam_rbg: {
    metaTag: 'In Memoriam: RBG',
    alt: 'In Memoriam: RBG',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  kids_1220: {
    metaTag: 'Kids1220',
    alt: 'Kids',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  last_minute_gifts_1220: {
    metaTag: 'Last Minute Gifts 1220',
    alt: 'Last Minute Gifts',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  lwv_strand_2020: {
    metaTag: 'LWV X Strand V2',
    alt: 'LWV x Strand',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  lwv_strand_2020_kids: {
    metaTag: 'LWV x Strand: Kids',
    alt: 'LWV x Strand: Kids',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  lwv_strand_2020_merch: {
    metaTag: 'LWV x Strand: Merch',
    alt: 'LWV x Strand: Merch',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  mentally_im_here: {
    metaTag: "mentally i'm here",
    alt: "Mentally I'm Here",
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  mugs_1220: {
    metaTag: 'Mugs 1220',
    alt: 'Mugs',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  native_american: {
    metaTag: 'Native American',
    alt: 'Native American',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  nba_2020_finalists: {
    metaTag: 'NBA 2020 Finalists',
    alt: 'National Book Awards 2020 Finalists',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  nba_longlist_2020_nonfiction: {
    metaTag: 'NBA Longlist 2020 Nonfiction',
    alt: 'National Book Awards 2020 Nonfiction',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  nyt_notable_2020: {
    metaTag: 'NYT Notable 2020',
    alt: 'New York Times 100 Notable Books of 2020',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  patti_smith_collection: {
    metaTag: 'Patti Smith Collection',
    alt: 'Patti Smith Collection',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  pride: {
    metaTag: 'Pride',
    alt: 'Pride',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  pulitzer_prize_winners_2020: {
    metaTag: 'Pulitzer Prize Winners 2020',
    alt: 'Pulitzer Prize Winners 2020',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  signed_1220: {
    metaTag: 'Signed',
    alt: 'Signed',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  summer_reads_2020: {
    metaTag: 'Summer Reads 2020',
    alt: 'Summer Reads 2020',
    landingImage: null,
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  our_kinda_early_holiday_gift_guide: {
    metaTag: 'The (Very) Early Holiday Gift Guide',
    alt: 'Our (Kinda) Early Holiday Gift Guide',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  totes_1220: {
    metaTag: 'Totes 1220',
    alt: 'Totes',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  work_study_essentials_2020: {
    metaTag: 'Work & Study Essentials',
    alt: 'Work & Study Essentials',
    landingImage: urlGenerator('/S/banners/banners_lp_study-work-essentials-2020.jpg'),
    landingDesc: 'Everything you need for work, school, and studying! From stationery and notebooks to mind-opening reads, your next destination is productivity.',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  indie_next_feb: {
    metaTag: 'Indie Next Feb',
    alt: 'The Indie Next List',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  short_stories: {
    metaTag: 'Short Stories',
    alt: 'Short Stories',
    landingImage: urlGenerator('/S/banners/banners_short_stories_banner_1350x275.jpg'),
    landingDesc: '*Your discount will be applied at checkout.',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  spring_sale_2021: {
    metaTag: 'Spring Sale 2021',
    alt: 'Spring Sale 2021',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  mystery_2021: {
    metaTag: 'Mystery 2021',
    alt: 'Mystery',
    landingImage: urlGenerator('/S/2021-promo/promos_mystery_2021.jpg'),
    landingImageMobile: urlGenerator('/S/2021-promo/promos_mystery_2021_mysterysale_350x300.jpg'),
    landingDesc: 'In celebration of Sir Arthur Conan Doyle, born May 22nd 1859.',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
  puzzle_month_2021: {
    metaTag: 'Puzzle Month',
    alt: 'Puzzles',
    landingImage: '',
    landingDesc: '',
    promoteTopImage: null,
    promoteTopLink: null,
    promoteBottomImage: null,
    promoteBottomLink: null,
  },
};
