import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, Link } from 'react-router-dom';
import Loading from '../loading/Loading';
import GenCarousel from '../genCarousel/GenCarousel';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import './_apparel.scss';

export const META_LIST_DETAIL = gql`
  query{
    hats: metaListDetail(metaTag: "apparel hats and beanies"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    hoodies: metaListDetail(metaTag: "apparel sweatshirts and hoodies"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    shirts: metaListDetail(metaTag: "apparel t-shirts"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    youth: metaListDetail(metaTag: "apparel youth "){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    socks: metaListDetail(metaTag: "apparel socks"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    totes: metaListDetail(metaTag: "Summer Totes 2022"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
    pride: metaListDetail(metaTag: "Pride Apparel 2022"){
      metaTag
      product{
        gtin13
        id
        imageUrl
        retailStv
        retailPrice
        title
        originator
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            onHand
            salePriceStv
            sku
          }
        }
        inventory{
          salePriceStv
        }
      }
    }
  }
`;

const Apparel = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  if (errorMeta) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="apparel-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  // console.log(dataMeta);

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="apparel-container__div">
        <main className="apparel-content__main">
          {scroll > 1000 && <CompFab />}
          <div className="apparel-content__div">
            <PageHeader header="Apparel" />
            <section className="apparel-categories__section">
              <div className="apparel-categories__div">

                <div className="apparel-category__div">
                  <Link
                    to="/metalist/apparel_tshirts"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on T-shirts link', 'APPAREL_LINK', 1)}
                    aria-label="t-shirts"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/G/2A0A40F0/EAN-9789112224559')} alt="t-shirts" className="apparel-category-image__img" />
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">T-shirts</h2>
                  </Link>
                </div>

                <div className="apparel-category__div">
                  <Link
                    to="/metalist/apparel_hats_beanies"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on Hats & Beanies link', 'APPAREL_LINK', 1)}
                    aria-label="hats and beanies"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/G/99384576/EAN-9781299333550')} alt="hats and beanies" className="apparel-category-image__img" />
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">Hats &amp; Beanies</h2>
                  </Link>
                </div>

                <div className="apparel-category__div">
                  <Link
                    to="/metalist/apparel_sweatshirts_hoodies"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on Sweatshirts & Hoodies link', 'APPAREL_LINK', 1)}
                    aria-label="sweatshirts and hoodies"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/G/F4FA78D3/EAN-2900012620105')} alt="sweatshirts and hoodies" className="apparel-category-image__img" />
                      {/* <img src={urlGenerator('/G/4F7448F0/EAN-2900012708667')} alt="sweatshirts and hoodies" className="apparel-category-image__img" /> */}
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">Sweatshirts &amp; Hoodies</h2>
                  </Link>
                </div>

                <div className="apparel-category__div">
                  <Link
                    to="/metalist/apparel_youth"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on Youth link', 'APPAREL_LINK', 1)}
                    aria-label="youth"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/G/88ABD3BA/EAN-9789112182750')} alt="youth" className="apparel-category-image__img" />
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">Youth</h2>
                  </Link>
                </div>

                <div className="apparel-category__div">
                  <Link
                    to="/metalist/apparel_socks"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on Socks link', 'APPAREL_LINK', 1)}
                    aria-label="socks"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/G/F3505DF3/EAN-9789112368321')} alt="socks" className="apparel-category-image__img" />
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">Socks</h2>
                  </Link>
                </div>

                <div className="apparel-category__div">
                  <Link
                    to="/productsection/MERCH-TOTES"
                    className="apparel-link__link"
                    onClick={() => WithEvent('APPAREL', 'Clicked on Totes link', 'APPAREL_LINK', 1)}
                    aria-label="totes"
                  >
                    <div className="apparel-category-image__div">
                      <img src={urlGenerator('/S/pages/apparel_totes_new.png')} alt="totes" className="apparel-category-image__img" />
                    </div>
                    <h2 className="apparel-header__h2 apparel-header-category__h2">Totes</h2>
                  </Link>
                </div>

              </div>
            </section>

            <div className="apparel-border__div" />

            <section className="apparel-carousels__section">
              <div className="apparel-carousels__div">

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="apparel_sweatshirts_hoodies"
                    dataType="metaList"
                    products={(
                      dataMeta
                        && dataMeta.hoodies
                        && dataMeta.hoodies.product)
                        || []
                      }
                  />
                </div>

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="apparel_hats_beanies"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.hats
                      && dataMeta.hats.product)
                      || []
                    }
                  />
                </div>


                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="apparel_tshirts"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.shirts
                      && dataMeta.shirts.product)
                      || []
                    }
                  />
                </div>

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="apparel_youth"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.youth
                      && dataMeta.youth.product)
                      || []
                    }
                  />
                </div>

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="apparel_socks"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.socks
                      && dataMeta.socks.product)
                      || []
                    }
                  />
                </div>

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="summer_totes_2022"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.totes
                      && dataMeta.totes.product)
                      || []
                    }
                  />
                </div>

                <div className="apparel-carousel__div">
                  <GenCarousel
                    locationName="pride_apparel_2022"
                    dataType="metaList"
                    products={(
                      dataMeta
                      && dataMeta.pride
                      && dataMeta.pride.product)
                      || []
                    }
                  />
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Apparel.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Apparel;
