import React from 'react';
import PropTypes from 'prop-types';
import './_globalBanner.scss';
import WithEvent from '../withEvent/WithEvent';

const GlobalBanner = (props) => {
  const {
    message,
    link,
  } = props;

  return (
    message.length > 0
      ? (
        <div className="globalbanner-container__div">
          {
            link?.length > 0
              ? (
                <a
                  className="globalbanner-link__a"
                  href={link}
                  onClick={() => WithEvent('GLOBAL_BANNER', 'Clicked global banner link', 'GLOBAL_BANNER_LINK', 1)}
                >
                  <p className="globalbanner-message__p">
                    { message }
                  </p>
                </a>
              )
              : (
                <p className="globalbanner-message__p globalbanner-message__p--black">
                  { message }
                </p>
              )
          }
        </div>
      )
      : <div className="globalbanner-container__div globalbanner-container__div--nodisplay" />
  );
};

GlobalBanner.propTypes = {
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default GlobalBanner;
