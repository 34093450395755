import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import testingEnv from '../../helpers/testingEnv';

const WithEvent = (category, action, label, value = 0) => {
  if (!testingEnv()) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
};

export default WithEvent;

export const WithFBEvent = (event, data) => {
  ReactPixel.track(event, data);
};
