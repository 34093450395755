/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import listBHU from './listBHU';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import PageHeader from '../pageHeader/PageHeader';
import urlGenerator from '../../helpers/urlGenerator';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_bhuSpoilers.scss';

const BHUSpoilers = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bhuspoilers-container__div">
        <main className="bhuspoilers-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="bhuspoilers-content__div">
            <div className="bhuspoilers-header__div">
              <Link to="/books-media/bookhookup" className="bhuspoilers-header-link__link">
                <div className="bhuspoilers-title__div">
                  <img src={urlGenerator('/S/pages/bhu-header.png')} alt="The Book HookUp" className="bhuspoilers-title__img" />
                </div>
                <h1 className="bhuspoilers-header__h1--spoiler">
                  *SPOILERS*
                </h1>
              </Link>
            </div>
            <PageHeader header="Summer 2024 Featured Books" />
            <section className="bhuspoilers-subhead__section">
              <div className="bhuspoilers-subhead__div">
                <p className="bhuspoilers-subhead__p">
                  <Link to="/books-media/bookhookup" className="bhuspoilers-subhead-button__link">
                    Order Here
                  </Link>
                </p>
              </div>
            </section>
            <section className="bhuspoilers-list__section">
              <div className="bhuspoilers-list__div">
                {
                  listBHU.spoilerList
                    .map((spoiler, i) => {
                      const {
                        bhuName,
                        spoilerImg,
                        id,
                        title,
                        author,
                        info,
                        description,
                      } = spoiler;

                      return (
                        <div key={id} className={i % 2 ? 'bhuspoilers-book__div bhuspoilers-book__div--even' : 'bhuspoilers-book__div bhuspoilers-book__div--odd'}>
                          <PageSubHeader className="bhuspoilers-header__subheader" header={bhuName} />
                          <div className="bhuspoilers-book-info__div">
                            <div className="bhuspoilers-book-image__div">
                              <img
                                className="bhuspoilers-book-image__img"
                                src={spoilerImg}
                                alt={title}
                              />
                            </div>
                            <div className="bhuspoilers-book-title__div">
                              <h3 className="bhuspoilers-header__h3">{title}</h3>
                              <h3 className="bhuspoilers-header__h3">{`by ${author}`}</h3>
                              {info && <h4 className="bhuspoilers-header__h4">{info}</h4>}
                            </div>
                          </div>
                          <div className="bhuspoilers-book-details__div" dangerouslySetInnerHTML={(() => ({ __html: description }))()} />
                        </div>
                      );
                    })
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BHUSpoilers.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BHUSpoilers;
