import React, { useState, useEffect } from 'react';
import { NavLink, Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuRounded from '@material-ui/icons/MenuRounded';
import ButtonBase from '@material-ui/core/ButtonBase';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Search from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import Fade from '@material-ui/core/Fade';
// import Close from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
// import Banner from '../banner/Banner';
import GlobalBanner from '../globalBanner/GlobalBanner';
import DrawerNav from '../drawerNav/DrawerNav';
import SearchBar from '../searchBar/SearchBar';
import WithEvent from '../withEvent/WithEvent';
import useToggleLoginSignup from '../../hooks/useToggleLoginSignup';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import useUserLogOut from '../../hooks/useUserLogOut';
import urlGenerator from '../../helpers/urlGenerator';
import './_navbar.scss';
import LoginSignup from '../loginSignup/LoginSignup';

const useStyles = makeStyles({
  appbarColorPrimary: {
    // backgroundColor: '#fbf5df',
    backgroundColor: '#fbfaf6',
  },
  iconBtnRoot: {
    color: '#af272f',
    padding: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#d4301a',
    },
  },
  menuRoot: {
    width: '1.25em',
    height: '1.25em',
  },
  searchRoot: {
    width: '1.25em',
    height: '1.25em',
  },
  accountRoot: {
    width: '1.25em',
    height: '1.25em',
  },
  shoppingRoot: {
    width: '1.25em',
    height: '1.25em',
  },

  badgeColorPrimary: {
    backgroundColor: '#af272f',
  },
  rootLogoutButton: {
    fontSize: '15px',
    fontfamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    color: '#000000',
    margin: 'auto',
    '&:hover': {
      color: '#d4301a',
    },
  },
  mobileToolbar: {
    minHeight: '64px',
  },
  tabletToolbar: {
    minHeight: '54px',
  },
  // positionFixed: {
  //   top: '50px',
  // },
});

const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        catalogId
        description
        gtin
        isTaxable
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      customer{
        customerId
        firstName
        lastName
        verified
      }
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

const OPEN_CHECKOUT = gql`
  query{
    checkoutIsOpen
  }
`;

const CUSTOMER_LOGGED_IN = gql`
  query{
    customerLoggedIn
  }
`;

const Navbar = () => {
  const classes = useStyles();

  const {
    compSwitchState: openDrawer,
    handleCompToggle: drawerToggle,
  } = useOpenCloseComponent();

  const {
    compSwitchState: openSearch,
    handleCompOpen: expandSearchBar,
    handleCompClose: hideSearchBar,
  } = useOpenCloseComponent();

  const match425 = useMediaQuery('(min-width: 425px)');

  const toggleLoginSignup = useToggleLoginSignup();
  // const { loggedState, refetch } = useUserLoggedIn();
  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    data: dataLoggedIn,
    refetch: refetchLoggedIn,
  } = useQuery(CUSTOMER_LOGGED_IN, { fetchPolicy: 'cache-and-network' });

  const {
    customerLogout,
    error: errorLogout,
    loading: loadingLogout,
    data: dataLogout,
  } = useUserLogOut();

  const {
    refetch: openRefetch,
    loading: openLoad,
    error: openError,
    // data: openData,
  } = useQuery(OPEN_CHECKOUT);

  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery(GET_CART_DETAILS, { fetchPolicy: 'cache-and-network' });
  const [accountState, setAccount] = useState(false);

  useEffect(() => {
    refetch();
    openRefetch();
  }, [refetch, openRefetch, dataLoggedIn]);

  // const [cartState, setCart] = useState(data.cartList && data.cartList.filter(cart => cart.cartType === 'PRIMARY').length && data.cartList.filter(cart => cart.cartType === 'PRIMARY')[0].cartItem.reduce((av, cv) => cv.qty + av, 0));
  // useEffect(() => {
  //   setCart(data.cartList && data.cartList.filter(cart => cart.cartType === 'PRIMARY').length && data.cartList.filter(cart => cart.cartType === 'PRIMARY').length && data.cartList.filter(cart => cart.cartType === 'PRIMARY')[0].cartItem.reduce((av, cv) => cv.qty + av, 0));
  // }, [data, setCart]);

  const handleAccount = () => {
    // return loggedState ? setAccount(!accountState) : toggleLoginSignup[1]();
    // if (loggedState) {
    //   console.log('loggedState');
    //   setAccount(!accountState);
    // } else toggleLoginSignup[1]();

    setAccount(!accountState);
  };

  // const handleCloseAccount = () => setAccount(false);

  // useEffect(() => {
  //   // handles account dropdown when there is a click away from the sub menu
  //   const handleClick = (e) => {
  //     if (
  //       !e.target.closest('.navbar-submenu__div')
  //         && !e.target.closest('.navbar-account-icon__accountcircle')
  //         && !e.target.closest('navbar-logout__buttonbase')
  //         && !e.target.closest('navbar-bottom-link__navlink')) handleCloseAccount();
  //   };

  //   document.addEventListener('click', handleClick);

  //   return () => document.removeEventListener('click', handleClick);
  // }, []);

  if (error || errorLoggedIn || errorLogout || openError) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            error,
            errorLoggedIn,
            errorLogout,
            openError,
          },
        }}
      />
    );
  }

  if (loading || loadingLoggedIn || loadingLogout || openLoad) return <div />;

  if (dataLogout && dataLogout.customerLogout) {
    refetchLoggedIn();
  }
  const handleLogOut = () => {
    // e.preventDefault();
    customerLogout();
    // window.location.reload(true);
    handleAccount();
    refetch();
    // refetch();
    refetchLoggedIn();
  };

  const cartItemCount = (data?.cartList?.find(cart => cart?.cartType === 'PRIMARY')?.cartItem.reduce((acc, { qty }) => qty + acc, 0))
    || 0;

  const cartLabel = `Cart${cartItemCount > 0 ? ` (${cartItemCount} item${cartItemCount === 1 ? '' : 's'})` : ''}`;

  return (
    <div className="navbar-container__div">
      <AppBar
        className="navbar-appbar__appbar"
        classes={{ colorPrimary: classes.appbarColorPrimary }}
        position="fixed"
      >
        <GlobalBanner
          message="Open Daily 10AM-8PM"
          link="https://www.strandbooks.com/hours-locations"
        />
        {
          !openSearch
            ? (
              <Toolbar disableGutters>
                <div className="navbar-icons__div">
                  <div className="navbar-lefticon__div">
                    <IconButton aria-label="menu" onClick={drawerToggle} classes={{ root: classes.iconBtnRoot }}>
                      <MenuRounded
                        className="navbar-menurounded__menurounded"
                        classes={{ root: classes.menuRoot }}
                      />
                    </IconButton>
                    <IconButton aria-label="search" className={openSearch ? 'navbar-search-btn__iconbutton--active' : 'navbar-search-btn__iconbutton'} onClick={expandSearchBar} classes={{ root: classes.iconBtnRoot }}>
                      <Search
                        className="navbar-search-icon__search"
                        classes={{ root: classes.searchRoot }}
                      />
                    </IconButton>
                  </div>
                  <NavLink
                    to="/"
                    className="navbar-navlink__navlink"
                    onClick={() => WithEvent('NAVBAR', 'Clicked logo link', 'NAVBAR_LINK', 1)}
                  >
                    <div className="navbar-logo__div">
                      <img
                        className="navbar-image__img"
                        alt="Strand Book Store"
                        src={urlGenerator('/S/static/strand-logo-exact.png')}
                      />
                    </div>
                  </NavLink>
                  <div className="navbar-righticon__div">
                    {/* <IconButton aria-label="My Account" classes={{ root: classes.iconBtnRoot }} onClick={loggedState ? handleAccount : handleAccountLoggedOut}> */}
                    {
                      dataLoggedIn && dataLoggedIn.customerLoggedIn
                        ? (
                          <IconButton aria-label="My Account" classes={{ root: classes.iconBtnRoot }} onClick={handleAccount}>
                            <AccountCircle
                              // className="navbar-account-icon__accountcircle"
                              classes={{ root: classes.accountRoot }}
                            />
                          </IconButton>
                        )
                        : (
                          <Link to="/login" className={classes.iconBtnRoot}>
                            <AccountCircle
                              className="navbar-account-icon__accountcircle"
                              classes={{ root: classes.accountRoot }}
                            />
                          </Link>
                        )
                    }
                    {/* <IconButton aria-label="My Account" classes={{ root: classes.iconBtnRoot }} onClick={dataLoggedIn && dataLoggedIn.customerLoggedIn ? handleAccount : handleAccountLoggedOut}>
                      <AccountCircle
                        className="navbar-account-icon__accountcircle"
                        classes={{ root: classes.accountRoot }}
                      />
                    </IconButton> */}
                    <NavLink
                      className="navbar-cart__navlink"
                      to="/cart"
                      onClick={() => WithEvent('NAVBAR', 'Clicked cart link', 'NAVBAR_LINK', 1)}
                      aria-label={cartLabel}
                    >
                      <Badge
                        className="navbar-badge__badge"
                        badgeContent={cartItemCount}
                        color="primary"
                        // invisible={data.cartList.filter(cart => cart.cartType === 'PRIMARY').length && data.cartList.filter(cart => cart.cartType === 'PRIMARY').length && !data.cartList.filter(cart => cart.cartType === 'PRIMARY')[0].cartItem.length}
                        classes={{ colorPrimary: classes.badgeColorPrimary }}
                      >
                        <ShoppingBasket
                          className="navbar-shoppingbasket__shoppingbasket"
                          classes={{ root: classes.shoppingRoot }}
                        />
                      </Badge>
                    </NavLink>
                    <div className={accountState ? 'navbar-submenu__div navbar-submenu-account__div--active' : 'navbar-submenu__div'}>
                      <div className="navbar-submenu-links__div navbar-submenu-account-links__div">
                        {
                          dataLoggedIn && dataLoggedIn.customerLoggedIn
                          && (
                            <NavLink
                              to="/myaccount#orders"
                              className="navbar-bottom-link__navlink"
                              onClick={() => WithEvent('NAVBAR', 'Clicked my orders link', 'NAVBAR_LINK', 1)}
                            >
                              My Orders
                            </NavLink>
                          )
                        }
                        {/* <NavLink to="/myaccount" className="navbar-bottom-link__navlink">
                          My Account
                        </NavLink> */}
                        {/* <NavLink to="/myaccount#orders" className="navbar-bottom-link__navlink">
                          My Orders
                        </NavLink> */}
                        {/* <NavLink to="/productlist/new_arrivals" className="navbar-bottom-link__navlink">
                          My Events
                        </NavLink> */}
                        {/* <NavLink to="/books-media/bookhookup" className="navbar-bottom-link__navlink">
                          Account Settings
                        </NavLink> */}
                        <ButtonBase className="navbar-logout__buttonbase" classes={{ root: classes.rootLogoutButton }} onClick={handleLogOut}>
                          Logout
                        </ButtonBase>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-tagline__div">
                    <p className="navbar-tagline__p">Independent Booksellers for 97 Years</p>
                  </div>
                </div>
              </Toolbar>
            )
            : (
              <Fade in={openSearch} appear unmountOnExit>
                <Toolbar disableGutters>
                  <div className="navbar-searchbar__div">
                    <SearchBar onClose={hideSearchBar} />
                    {/* <IconButton onClick={hideSearchBar} classes={{ root: classes.iconBtnRoot }} className="navbar-icon-close__iconbutton">
                      <Close />
                    </IconButton> */}
                  </div>
                </Toolbar>
              </Fade>
            )
        }
      </AppBar>
      <DrawerNav openDrawer={openDrawer} drawerToggle={drawerToggle} />
      <LoginSignup open={toggleLoginSignup[0]} handleClose={toggleLoginSignup[2]} />
      {/* Added toolbar components to provide space underneath floating navbar. Hacky, perhaps. But it is effective */}
      <Toolbar classes={{ regular: match425 ? classes.tabletToolbar : classes.mobileToolbar }} />
      <Toolbar classes={{ regular: match425 ? classes.tabletToolbar : classes.mobileToolbar }} />
      {/* <Toolbar classes={{ regular: match425 ? classes.tabletToolbar : classes.mobileToolbar }} /> */}
    </div>
  );
};

export default Navbar;
