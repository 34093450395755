import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/styles';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import CompFab from '../compFab/CompFab';
import bbtfList from './bbtfList';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_bbtfFaq.scss';

const useStyles = makeStyles({
  rootIconBtn: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#af272f',
    },
  },
});

const BBTFFaq = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const classes = useStyles();

  const scroll = useScrollY();
  const [collapseState, setCollapse] = useState({
    purchases: true,
    rentals: false,
  });

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const handleCollapse = header => setCollapse({
    ...collapseState, [header]: !collapseState[header],
  });

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtffaq-container__div">
        { scroll > 1000 && <CompFab />}
        <div className="bbtffaq-hero__div" />
        <main className="bbtffaq-content__main">
          <PageHeader header="Frequently Asked Questions" />
          <div className="bbtffaq-content__div">
            <section className="bbtffaq-questions__section">
              <div className="bbtffaq-questions__div">
                <h3 className="bbtffaq-header__h3">Purchases</h3>
                {
                  bbtfList.bbtfFaqs.purchases.map((purchases, i) => {
                    const {
                      id,
                      question,
                      answer,
                    } = purchases;

                    return (
                      <div className="bbtffaq-details__div" key={id}>
                        <h4 className="bbtffaq-header__h4" id={`purchase${i + 1}`}>{question}</h4>
                        <p className="bbtffaq-details__p">{answer}</p>
                      </div>
                    );
                  })
                }
                <h3 className="bbtffaq-header__h3">Rentals</h3>
                {
                  bbtfList.bbtfFaqs.rentals.map((rentals, i) => {
                    const {
                      id,
                      question,
                      answer,
                    } = rentals;

                    return (
                      <div className="bbtffaq-details__div" key={id}>
                        <h4 className="bbtffaq-header__h4" id={`rentals${i + 1}`}>{question}</h4>
                        <p className="bbtffaq-details__p">{answer}</p>
                      </div>
                    );
                  })
                }
              </div>
            </section>
            <aside className="bbtffaq-sidenav__aside">
              <div className="bbtffaq-sidenav__div">
                <div className="bbtffaq-question-header__div">
                  <h5 className="bbtffaq-header__h5">Purchases</h5>
                  {
                      collapseState.purchases
                        ? (
                          <IconButton
                            disableTouchRipple
                            disableRipple
                            classes={{ root: classes.rootIconBtn }}
                            onClick={() => handleCollapse('purchases')}
                            aria-label="purchases"
                          >
                            <ExpandLessIcon />
                          </IconButton>
                        )
                        : (
                          <IconButton
                            onClick={() => handleCollapse('purchases')}
                            disableTouchRipple
                            disableRipple
                            classes={{ root: classes.rootIconBtn }}
                            aria-label="purchases"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        )
                  }
                </div>
                <Collapse in={collapseState.purchases}>
                  {
                    bbtfList.bbtfFaqs.purchases.map((purchases, i) => {
                      const {
                        id,
                        question,
                      } = purchases;

                      return (
                        <div key={id} className="bbtffaq-link__div">
                          <a className="bbtffaq-link__a" href={`#purchase${i + 1}`}>
                            {question}
                          </a>
                        </div>
                      );
                    })
                  }
                </Collapse>
                <div className="bbtffaq-question-header__div">
                  <h5 className="bbtffaq-header__h5">Rentals</h5>
                  {
                      collapseState.rentals
                        ? (
                          <IconButton
                            onClick={() => handleCollapse('rentals')}
                            className="bbtffaq-expand__iconbutton"
                            classes={{ root: classes.rootIconBtn }}
                            disableTouchRipple
                            disableRipple
                            aria-label="rentals"
                          >
                            <ExpandLessIcon />
                          </IconButton>
                        )
                        : (
                          <IconButton
                            onClick={() => handleCollapse('rentals')}
                            className="bbtffaq-expand__iconbutton"
                            classes={{ root: classes.rootIconBtn }}
                            disableTouchRipple
                            disableRipple
                            aria-label="rentals"
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        )
                  }
                </div>
                <Collapse in={collapseState.rentals}>
                  {
                    bbtfList.bbtfFaqs.rentals.map((rentals, i) => {
                      const {
                        id,
                        question,
                      } = rentals;

                      return (
                        <div key={id} className="bbtffaq-link__div">
                          <a className="bbtffaq-link__a" href={`#rentals${i + 1}`}>
                            {question}
                          </a>
                        </div>
                      );
                    })
                  }
                </Collapse>
              </div>
            </aside>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BBTFFaq.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BBTFFaq;
