import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Redirect, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import GenCarousel from '../genCarousel/GenCarousel';
import PageHeader from '../pageHeader/PageHeader';
import WithEvent from '../withEvent/WithEvent';
import {
  locationsDictionaryFirst,
  locationsDictionarySecond,
  locationsDictionaryUG,
  locationsObjFirst,
  locationsObjSecond,
  locationsObjUG,
} from '../../helpers/locationsDictionary';
import convertURL from '../../helpers/convertURL';
import use4Cite from '../../hooks/use4Cite';
import './_booksMedia.scss';

export const LOCATION_CODE_FIRST = gql`
  query{
    locTBEST: productByLocationCode(locationCode: "TBEST", limit: 8){
      title
      originator
      id
      imageUrl
      retailStv
      gtin13
      inventoryOverview{
        salePriceMaxStv
        salePriceMinStv
      }
      inventory{
        onHand
        sku
      }
    }
    locTNEW: productByLocationCode(locationCode: "TNEW", limit: 8){
      title
      originator
      id
      imageUrl
      retailStv
      gtin13
      inventoryOverview{
        salePriceMaxStv
        salePriceMinStv
      }
      inventory{
        onHand
        sku
      }
    }
    locTFIC5: productByLocationCode(locationCode: "TFIC5", limit: 8){
      title
      originator
      id
      imageUrl
      retailStv
      gtin13
      inventoryOverview{
        salePriceMaxStv
        salePriceMinStv
      }
      inventory{
        onHand
        sku
      }
    }
  }
  `;

export const LOCATION_CODE_SECOND = gql`
query{
  loc2NA1: productByLocationCode(locationCode: "2NA1", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
  loc2COM7: productByLocationCode(locationCode: "2COM7", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
  loc2KBST: productByLocationCode(locationCode: "2KBST", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
}
`;

export const LOCATION_CODE_UNDERGROUND = gql`
query{
  locBSNP: productByLocationCode(locationCode: "BSNP", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
  locBSSS: productByLocationCode(locationCode: "BSSS", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
  locBPHIL: productByLocationCode(locationCode: "BPHIL", limit: 8){
    title
    originator
    id
    imageUrl
    retailStv
    gtin13
    inventoryOverview{
      salePriceMaxStv
      salePriceMinStv
    }
    inventory{
      onHand
      sku
    }
  }
}
`;

const BooksMedia = (props) => {
  const {
    match,
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const handleHeader = (floor) => {
    if (floor === 'first-floor') return 'Books & Media - First Floor';

    if (floor === 'second-floor') return 'Books & Media - Second Floor';

    return 'Books & Media - Underground';
  };

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    error: locError,
    loading: locLoading,
    data: locData,
  } = useQuery(LOCATION_CODE_FIRST);

  const {
    error: locSecondError,
    loading: locSecondLoading,
    data: locSecondData,
  } = useQuery(LOCATION_CODE_SECOND);

  const {
    error: locUGError,
    loading: locUGLoading,
    data: locUGData,
  } = useQuery(LOCATION_CODE_UNDERGROUND);

  if (locError || locSecondError || locUGError) return <Redirect to={{ pathname: '/error', state: { locError, locSecondError, locUGError } }} />;

  if (locLoading || locSecondLoading || locUGLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="booksmedia-container__div">
          <Loading />
        </div>
      </React.Fragment>
    );
  }

  const excludeLoc = ['TPICK', 'TBEST', 'TBUCK', 'TPICK3', 'CRNR', 'TCK3', 'TLGBTQ', 'MSTAT3', 'THOT', '2KBST2', 'TCOL2', 'TCOL1', 'TCOM', 'TNEW', 'TNF1C1', 'LNY1', 'TFRONT', 'FICSTOCK', 'TPLAY', 'TSHORT', 'TFIC3', 'MRCHW', 'TFRONT2', '2KBST', '2NA1', 'BSSS', 'BCRIME', 'TFIC5', 'TFIC', 'TFIC4', '2COM1', 'MART', 'TCAL', 'MCOL3', 'MGAME', 'MMAG', 'MCOL6', 'MCOL7', 'MCOL4', 'MCOL5', 'MCOL', 'MCOL2', 'MCOL90', 'MWALLF', 'MWALL1', 'MWALL3', 'MWALL4', 'MWALL5', 'MLEDGE2', 'MSPIN', 'MFRNT', 'MRCH', 'MPUZZ', 'HISTSETS', 'MSOCK', 'MSTAT1', 'MSTAT2', 'MSHIRT', 'TTAROT', 'TFILM', 'SWALL', '2INF3', '2KF5', '2KF3', '2KF2', '2KWALL', '2COL3', '2COL5', '2COL6', '2COL7', '2COL8', '2COL9', '2COL1', '2COL4', '2COL11', '2COL2', '2COL10', '2KEND2', '2KEND4', '2KEND1', '2MEZZ2', '2SOCK', '2SOCK2', '2SOCK3', '2SSPIN', '2TOTE', 'RBR01', 'RBR04', 'RBRART', 'RBR13', 'RBRMOD', 'RBR15', 'RBREL', 'RBRGIFT', 'RBR12', 'RBRCOLL', 'RBR02', 'RBR03', 'RBRTC1', 'RBRTC2', 'RBRTC3', 'RBR11', 'RBRHC', 'RBR05', 'RBR10', 'RBRPICK1', 'MRBR', 'RBR07', 'RBR09', 'RBRMYSF', 'RBRPHOTO', 'RBSALE', 'RBEARLY', 'RBRPICK2', 'RBR08', 'RBRSIGN1', 'RBRPICK3', 'OFFSITE', 'RBACK', 'EVPRE', 'EVTICKET', 'EVONLY', 'EVCARD', 'EVBOOK', 'EVWSA', 'SECTION', 'INTERNETDEPT', 'TTOWN', 'BDVD', 'BMAG', 'BBIGSH1', 'BBIGSH2', 'BOSTOCK', 'BMAP2', 'BCOL5', 'BREC', 'BREC2'];

  return (
    <div className="booksmedia-container__div">
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <PageHeader header={handleHeader(match.params.floor)} />
      <div className="booksmedia-subheader__div">
        <h2 className="booksmedia-header__h2">Browse through our collections as seen in our main store</h2>
      </div>
      <div className="booksmedia-content__div">
        <section className="booksmedia-top__section">
          <div className="booksmedia-top__div">
            {
              match.params
                && match.params.floor === 'first-floor'
                && locData
                && Object.keys(locData).length > 0
                && Object.keys(locData).map(loc => <GenCarousel key={loc} dataType="location" locationName={loc.slice(3)} products={locData[loc]} />)
            }
            {
              match.params
                && match.params.floor === 'second-floor'
                && locSecondData
                && Object.keys(locSecondData).length > 0
                && Object.keys(locSecondData).map(loc => <GenCarousel key={loc} dataType="location" locationName={loc.slice(3)} products={locSecondData[loc]} />)
            }
            {
              match.params
                && match.params.floor === 'underground'
                && locUGData
                && Object.keys(locUGData).length > 0
                && Object.keys(locUGData).map(loc => <GenCarousel key={loc} dataType="location" locationName={loc.slice(3)} products={locUGData[loc]} />)
            }
          </div>
        </section>
        <section className="booksmedia-others__section">
          <h2 className="booksmedia-header__h2 booksmedia-header__h2--gray">Browse the Rest of Our Collection</h2>
          <div className="booksmedia-others__div">
            {
              match.params
                && match.params.floor === 'first-floor'
                && Object.keys(locationsObjFirst)
                  .filter(loc => (excludeLoc.indexOf(loc) < 0))
                  .map(loc => (
                    <Link
                      key={loc}
                      to={`/productlocation/${convertURL(loc)}`}
                      className="booksmedia-others-link__link"
                      onClick={() => WithEvent('BOOKS_MEDIA', `Clicked ${loc} link`, 'BOOKS_MEDIA_BROWSE', 1)}
                    >
                      { locationsDictionaryFirst(loc) }
                    </Link>
                  ))
            }
            {
              match.params
                && match.params.floor === 'second-floor'
                && Object.keys(locationsObjSecond)
                  .filter(loc => (excludeLoc.indexOf(loc) < 0))
                  .map(loc => (
                    <Link
                      key={loc}
                      to={`/productlocation/${convertURL(loc)}`}
                      className="booksmedia-others-link__link"
                      onClick={() => WithEvent('BOOKS_MEDIA', `Clicked ${loc} link`, 'BOOKS_MEDIA_BROWSE', 1)}
                    >
                      { locationsDictionarySecond(loc) }
                    </Link>
                  ))
            }
            {
              match.params
                && match.params.floor === 'underground'
                && Object.keys(locationsObjUG)
                  .filter(loc => (excludeLoc.indexOf(loc) < 0))
                  .map(loc => (
                    <Link
                      key={loc}
                      to={`/productlocation/${convertURL(loc)}`}
                      className="booksmedia-others-link__link"
                      onClick={() => WithEvent('BOOKS_MEDIA', `Clicked ${loc} link`, 'BOOKS_MEDIA_BROWSE', 1)}
                    >
                      { locationsDictionaryUG(loc) }
                    </Link>
                  ))
            }
          </div>
        </section>
      </div>
      <ViewFooter />
    </div>
  );
};

BooksMedia.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BooksMedia;

/*
Saving this piece of code if we decide to go sectional

{
  data.sectionGroups
    .filter(group => excludeGroups.indexOf(group.prefix) < 0)
    .sort((a, b) => {
      if (a.prefix > b.prefix) return 1;
      if (a.prefix < b.prefix) return -1;
      return 0;
    })
    .map(group => (
      <div key={group.prefix} className="booksmedia-group__div">
        <p className="booksmedia-header__p">{group.prefix}</p>
      </div>
    ))

}
*/
