import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import listBannedBooks from './listBannedBooks';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import PageHeader from '../pageHeader/PageHeader';
import urlGenerator from '../../helpers/urlGenerator';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './bannedBooksWeek.scss';

const BannedBooksWeeks = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bannedbooks-container__div">
        <main className="bannedbooks-content__main">
          { scroll > 1000 && <CompFab />}
          <div className="bannedbooks-content__div">
            <div className="bannedbooks-header__div">
              <Link to="/books-media/bannedBooksWeek" className="bannedbooks-header-link__link">
                <div className="bannedbooks-title__div">
                  <img src={urlGenerator('/S/pages/llb_banned.png')} alt="Banned Books" className="bannedbooks-title__img" />
                </div>
              </Link>
            </div>
            <PageHeader header="" />
            <section className="bannedbooks-list__section">
              <div className="bannedbooks-list__div">
                {
                  listBannedBooks.spoilerList
                    .sort((a, b) => {
                      if (a.bhuName > b.bhUName) return 1;
                      if (a.bhuName < b.bhuName) return -1;
                      return 0;
                    })
                    .map((spoiler, i) => {
                      const {
                        bhuName,
                        spoilerImg,
                        id,
                        title,
                        author,
                        info,
                        description,
                      } = spoiler;

                      return (
                        <div key={id} className={i % 2 ? 'bannedbooks-book__div bannedbooks-book__div--even' : 'bannedbooks-book__div bannedbooks-book__div--odd'}>
                          <PageSubHeader className="bannedbooks-header__subheader" header={bhuName} />
                          <div className="bannedbooks-book-info__div">
                            <div className="bannedbooks-book-image__div">
                              <img
                                className="bannedbooks-book-image__img"
                                src={spoilerImg}
                                alt={title}
                              />
                            </div>
                            <div className="bannedbooks-book-title__div">
                              <h3 className="bannedbooks-header__h3">{title}</h3>
                              <h3 className="bannedbooks-header__h3">{`by ${author}`}</h3>
                              {info && <h4 className="bannedbooks-header__h4">{info}</h4>}
                            </div>
                          </div>
                          <div className="bannedbooks-book-details__div" dangerouslySetInnerHTML={(() => ({ __html: description }))()} />
                        </div>
                      );
                    })
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BannedBooksWeeks.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BannedBooksWeeks;
