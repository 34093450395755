import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import './accessibilityStatement.scss';

const AccessibilityStatement = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  // This is for testing error delivery to Sentry
  // if (true) throw new Error('test error');

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="accessibility-statement-container__div">
        <PageHeader header="Strand Book Store Accessibility Statement" />
        <main className="accessibility-statement-content__main">
          <div className="accessibility-statement-content__div">
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  General
                </h2>
                <p className="accessibility-statement-details__p">
                  <strong>Strand Book Store</strong>
                  &nbsp;strives to ensure that its services are accessible to people with disabilities.&nbsp;
                  <strong>Strand Book Store</strong>
                  &nbsp;has invested a significant
                  amount of resources to help ensure that its website is made
                  easier to use and more accessible for people with
                  disabilities, with the strong belief that&nbsp;
                  <a href="https://userway.org/">website accessibility</a>
                  &nbsp;efforts assist all users and that every person has the right
                  to live with dignity, equality, comfort and independence.
                </p>
              </div>
            </section>
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  Accessibility on Strand Book Store
                </h2>
                <p className="accessibility-statement-details__p">
                  Strand Book Store makes UserWay&apos;s&nbsp;
                  <a href="https://userway.org/">Web Accessibility</a>
                  &nbsp;Widget available which is powered by a dedicated accessibility server. The software allows Strand Book Store to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).
                </p>
              </div>
            </section>
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  Enabling the Accessibility Menu
                </h2>
                <p className="accessibility-statement-details__p">
                  The Strand Book Store  accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.
                </p>
              </div>
            </section>
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  Disclaimer
                </h2>
                <p className="accessibility-statement-details__p">
                  <strong>Strand Book Store</strong>
                  &nbsp;continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities
                </p>
                <p className="accessibility-statement-details__p">
                  Despite our efforts to make all pages and content on Strand Book Store  fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.
                </p>
              </div>
            </section>
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  Here For You
                </h2>
                <p className="accessibility-statement-details__p">
                  If you are experiencing difficulty with any content on Strand Book Store  or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.
                </p>
              </div>
            </section>
            <section className="accessibility-statement__section">
              <div className="accessibility-statement-details__div">
                <h2 className="accessibility-statement-header__h2">
                  Contact Us
                </h2>
                <p className="accessibility-statement-details__p">
                  If you wish to report an accessibility issue, have any questions or need assistance, please contact&nbsp;
                  <strong>Strand Book Store</strong>
                  &nbsp;Customer Support as follows:
                </p>
                <p className="accessibility-statement-details__p">
                  Email:&nbsp;
                  <a href="mailto:web@strandbooks.com">web@strandbooks.com</a>
                  <br />
                  Phone: 212-660-6658
                  <br />
                  Hours: 9:00 AM - 5:00 PM ET
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

AccessibilityStatement.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default AccessibilityStatement;
