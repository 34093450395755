import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import CartSnackbar from './CartSnackbar';

const CartSnackbarContext = React.createContext(null);

const CartSnackbarProvider = (props) => {
  const { children } = props;

  const [snackbarState, setSnackbar] = useState({
    prodTitle: '',
    open: false,
  });

  return (
    <CartSnackbarContext.Provider value={setSnackbar}>
      {children}

      <CartSnackbar {...snackbarState} />
    </CartSnackbarContext.Provider>
  );
};

CartSnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCartSnackbar = () => useContext(CartSnackbarContext);

export default CartSnackbarProvider;
