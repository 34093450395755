/* eslint-disable max-len */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Redirect, Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import NavigateBeforeRounded from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRounded from '@material-ui/icons/NavigateNextRounded';
import { makeStyles } from '@material-ui/styles';
import Slider from 'react-slick';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import useScrollY from '../../hooks/useScrollY';
import { useCartSnackbar } from '../cartSnackbar/CartSnackbarProvider';
import InPageLoading from '../loading/InPageLoading';
import CompFab from '../compFab/CompFab';
import Loading from '../loading/Loading';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import WithEvent from '../withEvent/WithEvent';
import capitalizeString from '../../helpers/capitalizeString';
import convertSlashURL from '../../helpers/convertSlashURL';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import stringLimiter from '../../helpers/stringLimiter';
import atcUpdate from '../../helpers/atcUpdate';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_rare.scss';

const useStyles = makeStyles({
  rootIconButtonPrev: {
    color: '#ffffff',
    backgroundColor: 'rgba(191, 46, 26, .25)',
    '&:hover': {
      backgroundColor: 'rgba(191, 46, 26, 1)',
    },
  },
  rootNavigate: {
    width: '1em',
    height: '1em',
  },
  rootBtn: {
    backgroundColor: '#af272f',
    color: '#ffffff',
    textTransform: 'none',
    borderRadius: '5px',
    fontFamily: ['Trade Gothic LT Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '1em',
    '&:hover': {
      backgroundColor: '#d4301a',
    },
    minWidth: '160px',
    height: '40px',
  },
  rootIconButtonNext: {
    color: '#ffffff',
    backgroundColor: 'rgba(191, 46, 26, .25)',
    '&:hover': {
      backgroundColor: 'rgba(191, 46, 26, 1)',
    },
  },
  rootIconButtonExpandLess: {
    color: '#af272f',
    '&:hover': {
      color: '#fff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
    },
  },
  rootIconButtonExpandMore: {
    '&:hover': {
      color: '#fff',
      backgroundColor: 'rgba(191, 46, 26, 1)',
    },
  },
});

export const ADD_ITEM_CART = gql`
  mutation addItemCart($cartItemInput: CartItemInput!){
    cartItemAdd(cartItemInput: $cartItemInput){
      cartId
      cartItemId
      cartPriceStv
      catalogId
      description
      gtin
      isTaxable
      qty
      retailStv
      salePriceStv
      sku
      productDetail{
        gtin13
        id
        imageUrl
        isbn10
        title
        originator
        bookBinding
        inventory{
          sku
          onHand
          catalogId
          signed
        }
      }
    }
  }
`;

export const SEARCH_GROUPS_SECTION = gql`
  query{
    sectionGroups{
      subsections
      prefix
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        catalogId
        description
        gtin
        isTaxable
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
          bookBinding
          inventory{
            sku
            onHand
          }
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      customer{
        customerId
        firstName
        lastName
        verified
      }
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

export const GET_RARE_METALIST = gql`
  query{
    metaListDetail(metaTag: "Rare"){
      product{
        bookBinding
        copyright
        description
        gtin13
        id
        imageUrl
        isbn10
        originator
        publisher
        retailStv
        title
        webDescription
        inventory{
          salePriceStv
          catalogId
          onHand
          signed
          sku
        }
      }
    }
  }
`;

const Rare = (props) => {
  const {
    cartList: upperCartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const classes = useStyles();

  const scroll = useScrollY();

  const [sectionList, setSectionList] = useState({});

  const [cartItemAdd, {
    loading: cartItemAddLoading,
    error: cartItemAddError,
  }] = useMutation(ADD_ITEM_CART);

  const { compSwitchState, handleCompOpen, handleCompClose } = useOpenCloseComponent();

  const setCartSnackbar = useCartSnackbar();

  const handleCartSnackbarClose = useCallback(() => {
    setCartSnackbar(prev => ({ ...prev, open: false }));
  }, [setCartSnackbar]);

  const handleCartSnackbarOpen = useCallback((prodTitle, triggerElem) => {
    setCartSnackbar({
      prodTitle: stringLimiter(prodTitle),
      open: true,
      triggerElem,
      onClose: handleCartSnackbarClose,
    });
  }, [setCartSnackbar, handleCartSnackbarClose]);

  const {
    loading: sectionGroupsLoading,
    error: sectionGroupsError,
    data: sectionGroupsData,
  } = useQuery(SEARCH_GROUPS_SECTION);

  const {
    loading: rareLoading,
    error: rareError,
    data: rareData,
  } = useQuery(GET_RARE_METALIST);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (sectionGroupsData
        && sectionGroupsData.sectionGroups
        && sectionGroupsData.sectionGroups.length > 0) {
      const rareGroup = sectionGroupsData.sectionGroups.filter(section => section.prefix === 'RARE');
      setSectionList(rareGroup[0]);
    }
  }, [sectionGroupsData]);

  use4Cite();

  if (sectionGroupsLoading || rareLoading) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="rare-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  if (sectionGroupsError || rareError || cartItemAddError) {
    return <Redirect to={{ pathname: '/error', state: { rareError, sectionGroupsError } }} />;
  }

  const PrevArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;
    return (
      <div style={{
        ...style,
        position: 'absolute',
        zIndex: 8,
        top: '75px',
        left: '50px',
      }}
      >
        <IconButton aria-label="previous" onClick={onClick} classes={{ root: classes.rootIconButtonPrev }}>
          <NavigateBeforeRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };

  const NextArrow = (arrowProps) => {
    const { onClick, style } = arrowProps;
    return (
      <div style={{
        ...style,
        position: 'absolute',
        zIndex: 8,
        top: '75px',
        right: '50px',
      }}
      >
        <IconButton aria-label="next" onClick={onClick} classes={{ root: classes.rootIconButtonNext }}>
          <NavigateNextRounded classes={{ root: classes.rootNavigate }} />
        </IconButton>
      </div>
    );
  };

  const settings = {
    slidesToScroll: 1,
    slidesToShow: 1,
    lazyLoad: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <section className="rare-hero__section">
        <div className="rare-hero__div">
          { scroll > 1000 && <CompFab /> }
          {cartItemAddLoading && <InPageLoading />}
          <div className="rare-header-border__div">
            <div className="rare-header__div">
              <h1 className="rare-header__h1">Rare &amp; Collectibles</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="rare-container__div">
        <main className="rare-content__main">
          <div className="rare-content__div">
            <section className="rare-featured__section">
              <h2 className="rare-header__h2">Featured in Rare</h2>
              <div className="rare-header-accent__div" />
              <div className="rare-featured__div">
                <div className="rare-slider__div">
                  {
                    rareData
                      && rareData.metaListDetail
                      && rareData.metaListDetail.product
                      && rareData.metaListDetail.product.length > 0
                      && (
                        <Slider {...settings}>
                          {
                              rareData.metaListDetail.product.filter(product => (
                                product
                                  && product.inventory
                                  && product.inventory.length > 0
                                  && product.inventory[0].onHand !== 0
                              )).length !== 0
                            && (
                              rareData.metaListDetail.product.filter(product => (
                                product
                                  && product.inventory
                                  && product.inventory.length > 0
                                  && product.inventory[0].onHand !== 0
                              ))
                            )
                              .map((product) => {
                                const {
                                  id,
                                  title,
                                  originator,
                                  gtin13,
                                  imageUrl,
                                  publisher,
                                  copyright,
                                  inventory,
                                  bookBinding,
                                  webDescription,
                                } = product;

                                return (
                                  <div className="rare-product__div" key={id}>
                                    <div className="rare-product-wrapper__div">
                                      <div className="rare-product-image__div">
                                        {
                                          imageUrl
                                            && imageUrl.length > 0
                                            && title
                                            && title.length > 0
                                            && <img className="rare-product-image__img" src={imageUrl} alt={title} />
                                        }
                                      </div>
                                      <div className="rare-product-details__div">
                                        {
                                          title
                                            && title.length > 0
                                            && <h3 className="rare-header__h3">{title}</h3>
                                        }
                                        <div className="rare-product-accent__div" />
                                        {
                                          originator
                                            && originator.length > 0
                                            && <p className="rare-product-details__p">{originator}</p>
                                        }
                                        {
                                          gtin13
                                            && gtin13.length > 0
                                            && <p className="rare-product-details__p">{`GTIN13: ${gtin13}`}</p>
                                        }
                                        {
                                          inventory
                                            && inventory[0]
                                            && inventory[0].edition
                                            && <p className="rare-product-details__p rare-product-details__p--capitalize">{inventory[0].edition && capitalizeString(inventory[0].edition)}</p>
                                        }
                                        {
                                          copyright
                                            && copyright.length
                                            && <p className="rare-product-details__p">{copyright && `Copyright: ${copyright}`}</p>
                                        }
                                        {
                                          bookBinding
                                            && bookBinding.length
                                            && <p className="rare-product-details__p">{bookBinding && `Binding: ${bookBinding}`}</p>
                                        }
                                        {
                                          publisher
                                            && publisher.length
                                            && <p className="rare-product-details__p">{publisher && `Publisher: ${publisher}`}</p>
                                        }
                                        {
                                          inventory
                                            && inventory[0]
                                            && <p className="rare-product-details__p">{`$${(inventory[0].salePriceStv * 0.01).toFixed(2)}`}</p>
                                        }
                                      </div>
                                      <div className="rare-product-options__div">
                                        <div className="rare-button__div">
                                          {
                                            product
                                              && product.inventory
                                              && product.inventory.length > 0
                                              && product.inventory[0]
                                              && product.inventory[0].sku
                                              && (
                                                <Button
                                                  classes={{ root: classes.rootBtn }}
                                                  onClick={(e) => {
                                                    const { currentTarget: triggerElem } = e;

                                                    Sentry.addBreadcrumb({
                                                      category: 'ADD_ITEM',
                                                      message: `Click add to cart ${gtin13}`,
                                                      level: Sentry.Severity.Info,
                                                    });

                                                    cartItemAdd({
                                                      variables: {
                                                        cartItemInput: {
                                                          catalogId: '1',
                                                          qty: 1,
                                                          sku: product.inventory[0].sku,
                                                        },
                                                      },
                                                      update: atcUpdate,
                                                    })
                                                      .then(() => refetchCartList())
                                                      .then(() => {
                                                        handleCartSnackbarOpen(title, triggerElem);
                                                      });
                                                  }}
                                                >
                                                  Add to Cart
                                                </Button>
                                              )
                                          }
                                        </div>
                                      </div>
                                      <div className="rare-product-description__div">
                                        {
                                          webDescription
                                            && webDescription.length
                                            && (webDescription.length > 75
                                              ? (
                                                <Collapse in={compSwitchState} collapsedHeight="25px">
                                                  <p className={compSwitchState ? 'rare-product-details__p' : 'rare-product-details__p rare-product-details__p--gray'}>{webDescription}</p>
                                                </Collapse>
                                              )
                                              : <p className={compSwitchState ? 'rare-product-details__p' : 'rare-product-details__p rare-product-details__p--gray'}>{webDescription}</p>
                                            )
                                        }
                                        {
                                          !compSwitchState && webDescription && webDescription.length > 75
                                            ? <span className="rare-product-details__p rare-product-details__p--gray">...</span>
                                            : <span />
                                        }
                                      </div>
                                      {
                                          webDescription && webDescription.length > 75
                                            ? (
                                              <div style={{ color: '#af272f' }} className="rare-product-description-button__div">
                                                {
                                                  compSwitchState
                                                    ? (
                                                      <IconButton
                                                        aria-label="expand less description"
                                                        classes={{
                                                          root: classes.rootIconButtonExpandLess,
                                                        }}
                                                        onClick={handleCompClose}

                                                      >
                                                        <ExpandLess />
                                                      </IconButton>
                                                    )
                                                    : (
                                                      <IconButton
                                                        aria-label="expand more description"
                                                        onClick={handleCompOpen}
                                                        classes={{
                                                          root: classes.rootIconButtonExpandMore,
                                                        }}
                                                      >
                                                        <ExpandMore />
                                                      </IconButton>
                                                    )
                                                }
                                              </div>
                                            )
                                            : <div />
                                        }
                                    </div>
                                  </div>
                                );
                              })
                          }
                        </Slider>
                      )
                  }
                </div>
              </div>
            </section>
            <div className="rare-divider__div" />
            <section className="rare-subjects__section" id="subjects">
              <div className="rare-subjects-container__div">
                <h2 className="rare-header__h2">Explore Our Rare Book Collection</h2>
                <div className="rare-header-accent__div" />
                <div className="rare-subjects__div">
                  {
                    sectionList
                      && sectionList.subsections
                      && sectionList.subsections
                        .sort((a, b) => {
                          const sectionA = a.slice(5);
                          const sectionB = b.slice(5);

                          if (sectionA > sectionB) return 1;
                          if (sectionA < sectionB) return -1;
                          return 0;
                        })
                        .map(sectionHeader => (
                          <div className="rare-subject__div" key={sectionHeader}>
                            <Link
                              to={`/productsection/${convertSlashURL(sectionHeader)}`}
                              className="rare-subject-link__link"
                              onClick={() => WithEvent('RARE', `Clicked ${sectionHeader} link`, 'RARE_SECTION_LINK', 1)}
                            >
                              { sectionHeader === 'RARE-MOUNTAINERING' ? 'Mountaineering' : capitalizeString(sectionHeader.slice(5)) }
                            </Link>
                          </div>
                        ))
                  }
                </div>
              </div>
            </section>
            <section className="rare-experts__section">
              <div className="rare-experts__div">
                <h2 className="rare-header__h2 rare-header-experts__h2">Strand&#39;s Rare Book Expert</h2>
                <div className="rare-header-accent__div" />
                <div className="rare-experts-list__div">
                  <div className="rare-expert__div">
                    <div className="rare-expert-image__div rare-expert-image__div">
                      <img className="rare-expert-image__img" alt="Vasilis Terpsopoulos" src={urlGenerator('/S/pages/rare-vasilis.png')} />
                    </div>
                    <div className="rare-details__div rare-detail-name__div">
                      <p className="rare-detail-name__p">Vasilis Terpsopoulos - Rare Manager</p>
                    </div>
                  </div>
                  <div className="rare-details__div rare-description__div">
                    <p className="rare-details__p">Our rare books expert is a walking encyclopedia of all things rare and collectible. For recommendations on gifts, help finding the perfect addition to your collection, or a look through what’s new and interesting in the Rare Book Room, he has you covered.</p>
                  </div>
                  <div className="rare-logo-image__div">
                    <img className="rare-expert-image__img" alt="ABAA Logo" src={urlGenerator('/S/pages/rare-abaa.png')} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Rare.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};


export default Rare;
