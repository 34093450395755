import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Snackbar from '@material-ui/core/Snackbar';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import stringLimiter from '../../helpers/stringLimiter';
import './_cartSnackbar.scss';


const useStyles = makeStyles({
  rootIconBtn: {
    color: '#ffffff',
    // color: '#af272f',
    position: 'relative',
    top: '-15px',
    right: '-10px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  anchorOriginTopRightSnackbar: {
    top: '0px',
    right: '0px',
  },
});

const createMessage = title => `Item added to your cart: ${title}.`;

const CartSnackbar = (props) => {
  const {
    prodTitle,
    open,
    triggerElem,
    onClose,
  } = props;

  const classes = useStyles();
  const match767 = useMediaQuery('(min-width: 767px)');
  const btnRef = useRef(null);

  const focusIn = useCallback(() => {
    if (!btnRef?.current) {
      return;
    }

    const { current: btnElem } = btnRef;

    btnElem.focus();
  }, [btnRef]);

  const focusOut = useCallback(() => {
    if (!triggerElem) {
      return;
    }

    triggerElem.focus();
  }, [triggerElem]);

  useEffect(() => {
    if (open) {
      focusIn();
    } else {
      focusOut();
    }

    return focusOut;
  }, [focusIn, focusOut, open]);

  return (
    <div className="cartsnackbar-container__div">
      <Snackbar
        autoHideDuration={7000}
        anchorOrigin={!match767 ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={onClose}
        classes={{ anchorOriginTopRight: classes.anchorOriginTopRightSnackbar }}
      >
        <div className="cartsnackbar-content__div">
          <h2 className="cartsnackbar-header__h2" aria-hidden="true">
            {createMessage(stringLimiter(prodTitle))}
          </h2>

          <IconButton
            ref={btnRef}
            disableTouchRipple
            disableRipple
            onClick={onClose}
            classes={{ root: classes.rootIconBtn }}
            aria-label={`${createMessage(prodTitle)} Click to Dismiss.`}
          >
            <Close />
          </IconButton>
        </div>
      </Snackbar>
    </div>
  );
};

CartSnackbar.propTypes = {
  prodTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  triggerElem: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
};

CartSnackbar.defaultProps = {
  triggerElem: null,
  onClose: null,
};

export default CartSnackbar;
