import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import StaticBanner from '../staticBanner/StaticBanner';
import use4Cite from '../../hooks/use4Cite';
import './holidayShop.scss';
import urlGenerator from '../../helpers/urlGenerator';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import HighlightItem from '../highlight/HighlightItem';

const HolidayShop = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const mediaQuery = useMediaQueryMinWidth(500);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  // This is for testing error delivery to Sentry
  // if (true) throw new Error('test error');

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="holiday-shop-container__div">
        <main className="holiday-shop-content__main">
          <div className="holiday-shop-content__div">
            <StaticBanner
              alt="Strand Holiday Shop"
              imgUrl={urlGenerator(
                '/S/banners/banners_holiday_shop_2023-desktop.png',
              )}
              imgUrlMobile={urlGenerator(
                '/S/banners/banners_holiday_shop_2023-mobile.png',
              )}
              mediaQuery={mediaQuery}
            />
          </div>
          <div className="holiday-shop-content__div">
            <div className="highlight-container__div">
              <div className="highlight-content__div">
                <div className="highlight-item__div highlight-highlight-1__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_apparel"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_apparel.png',
                    )}
                    alt="Gift Guide 2023 Apparel"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-2__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_cooking"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_cooking.png',
                    )}
                    alt="Gift Guide 2023 Cooking"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-3__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_fiction"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_fiction.png',
                    )}
                    alt="Gift Guide 2023 Fiction"
                  />
                </div>
              </div>
              <div className="highlight-content__div">
                <div className="highlight-item__div highlight-highlight-1__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_kids"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_for_kids.png',
                    )}
                    alt="Gift Guide 2023 For Kids"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-2__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_gift"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_gift.png',
                    )}
                    alt="Gift Guide 2023 Gift"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-3__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_nonfiction"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_nonfiction.png',
                    )}
                    alt="Gift Guide 2023 Non-Fiction"
                  />
                </div>
              </div>

              <div className="highlight-content__div">
                <div className="highlight-item__div highlight-highlight-1__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_signed"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_signed.png',
                    )}
                    alt="Gift Guide 2023 Signed"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-2__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_studio_strand"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_studio_strand.png',
                    )}
                    alt="Gift Guide 2023 Studio Strand"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-3__highlightitem">
                  <HighlightItem
                    link="/books-media/bookhookup"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_subscriptions.png',
                    )}
                    alt="Gift Guide 2023 Subsriptions"
                  />
                </div>
              </div>
              <div className="highlight-content__div">
                <div className="highlight-item__div highlight-highlight-1__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_visual_arts"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_visual_arts.png',
                    )}
                    alt="Gift Guide 2023 Visual Arts"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-2__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_translation"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_works_in_translation.png',
                    )}
                    alt="Gift Guide 2023 Works in Translation"
                  />
                </div>

                <div className="highlight-item__div highlight-highlight-3__highlightitem">
                  <HighlightItem
                    link="/metalist/gift_guide_2023_young_adult"
                    imgUrl={urlGenerator(
                      '/S/banners/spotlight_2023_young_adult.png',
                    )}
                    alt="Gift Guide 2023 Young Adult"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

HolidayShop.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default HolidayShop;
