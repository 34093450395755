import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/Button';
import './_navSidebar.scss';

const NavSidebar = (props) => {
  const { content } = props;
  const useStyles = makeStyles({
    rootButtonBase: {
      textTransform: 'none',
      fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
      fontSize: '15px',
      lineHeight: '1.5',
      color: '#333333',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
  const classes = useStyles();
  return (
    <div className="navsidebar-container__div">
      <nav className="navsidebar-content__nav">
        {
          content.map(subject => (
            <ButtonBase disableTouchRipple disableRipple classes={{ root: classes.rootButtonBase }} onClick={() => document.getElementById(subject.id).scrollIntoView({ behavior: 'smooth', block: 'center' })} className="navsidebar-item-link__a" key={subject.id}>{subject.name}</ButtonBase>
          ))
        }
      </nav>
    </div>
  );
};

NavSidebar.propTypes = {
  content: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default NavSidebar;
