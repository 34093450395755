import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import GenCarousel from '../genCarousel/GenCarousel';
import Loading from '../loading/Loading';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_readsolutions2021.scss';

const META_LIST_DETAIL = gql`
  query{
    bipoc: metaListDetail(metaTag: "reads-olution BIPOC authors"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    planet: metaListDetail(metaTag: "reads-olution planet"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    under35: metaListDetail(metaTag: "reads-olution under 35"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    longBooks: metaListDetail(metaTag: "reads-olutions long books"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    oneSitting: metaListDetail(metaTag: "reads-olutions one sitting"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    queer: metaListDetail(metaTag: "reads-olutions queer"){
        product{
          gtin13
          id
          imageUrl
          originator
          retailStv
          title
        }
      }
  }
`;

const PRODUCT_BY_LOCATION_CODE = gql`
  query{
    TFIC5: productByLocationCode(locationCode: "TFIC5", limit: 8){
      gtin13
      id
      imageUrl
      originator
      title
    }
    TSHORT: productByLocationCode(locationCode: "TSHORT", limit: 8){
      gtin13
      id
      imageUrl
      originator
      title
    }
    graphic: productByLocationCode(locationCode: "2COM1", limit: 8){
      gtin13
      id
      imageUrl
      originator
      title
    }
    TPLAY: productByLocationCode(locationCode: "TPLAY", limit: 8){
      gtin13
      id
      imageUrl
      originator
      title
    }
    TNEW: productByLocationCode(locationCode: "TNEW", limit: 8){
      gtin13
      id
      imageUrl
      originator
      title
    }
  }
`;


const Readsolutions2021 = (props) => {
  const {
    cartList,
    checkoutIsOpen,
    customerLoggedIn,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  const {
    error: errorLocation,
    loading: loadingLocation,
    data: dataLocation,
  } = useQuery(PRODUCT_BY_LOCATION_CODE);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  if (errorMeta || errorLocation) return <Redirect to={{ pathname: '/error', state: { errorMeta, errorLocation } }} />;

  if (loadingMeta || loadingLocation) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="home-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="readsolutions-container__div">
        <main className="readsolutions-content__main">
          <div className="readsolutions-content__div">
            <div className="readsolutions-hero__div">
              <img
                src={match767 ? urlGenerator('/S/banners/banners_readsolutions-desktop_1350-275.jpg') : urlGenerator('/S/banners/banners_readsolutions-mobile-350x300.jpg')}
                alt="Readsolutions 2021"
                className="readsolutions-hero__img"
              />
            </div>
            <div className="readsolutions-list__div">

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">1. Read a book written by someone under the age of 35</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_under_35"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.under35
                      && dataMeta.under35.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">2. Read a book by a BIPOC author</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_bipoc"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.bipoc
                      && dataMeta.bipoc.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">3. Read a book translated from another language</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="TFIC5"
                    dataType="location"
                    noHeader
                    products={(
                      dataLocation
                        && dataLocation.TFIC5
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">4. Read a book about the planet</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_planet"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.planet
                      && dataMeta.planet.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">5. Read a book with a queer narrator</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_queer"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.queer
                      && dataMeta.queer.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">6. Read an intimidatingly long book</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_long_books"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.longBooks
                      && dataMeta.longBooks.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">7. Read a book in one sitting</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="readsolutions_2021_one_sitting"
                    dataType="metaList"
                    noHeader
                    products={(
                      dataMeta
                      && dataMeta.oneSitting
                      && dataMeta.oneSitting.product)
                      || []
                    }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">
                  8. Go to an&nbsp;
                  <a className="readsolutions-link__a" href="/events">
                    author event
                  </a>
                </h3>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">9. Read a short story collection</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="TSHORT"
                    dataType="location"
                    noHeader
                    products={(
                      dataLocation
                        && dataLocation.TSHORT
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">10. Read a graphic novel</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="2COM1"
                    dataType="location"
                    noHeader
                    products={(
                      dataLocation
                        && dataLocation.graphic
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">11. Read a play</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="TPLAY"
                    dataType="location"
                    noHeader
                    products={(
                      dataLocation
                        && dataLocation.TPLAY
                    )
                        || []
                      }
                  />
                </div>
              </div>

              <div className="readsolutions-list-item__div">
                <h3 className="readsolutions-header__h3">12. Read something that was published in the last 6 months</h3>
                <div className="readsolutions-carousel__div">
                  <GenCarousel
                    locationName="TNEW"
                    dataType="location"
                    noHeader
                    products={(
                      dataLocation
                        && dataLocation.TNEW
                    )
                        || []
                      }
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Readsolutions2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Readsolutions2021;
