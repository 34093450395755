import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import graphQLErrorsDictionary from '../../helpers/graphQLErrorsDictionary';
import './_checkoutSnackbar.scss';

const CheckoutSnackbar = (props) => {
  const {
    message,
    open,
    // handleClose,
  } = props;

  const [showState, setShow] = useState(true);

  const useStyles = makeStyles({
    rootIconButton: {
      color: '#ffffff',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#fbf5df',
      },
    },
    rootSnackbar: {
      backgroundColor: '#af272f',
      color: '#ffffff',
      border: '3px solid #fbf5df',
      display: showState ? 'flex' : 'none',
    },
  });

  const handleClose = () => setShow(false);

  const classes = useStyles();

  // console.log('error message', message.graphQLErrors);

  return (
    <Snackbar
      classes={{ root: classes.rootSnackbar }}
      autoHideDuration={6000}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div className="checkoutsnackbar-container__div">
        <div className="checkoutsnackbar-content__div">
          <div className="checkoutsnackbar-space__div" />
          &nbsp;
          {
              message
                && message.graphQLErrors
                && message.graphQLErrors.length
                && message.graphQLErrors.map((errorObj, i) => {
                  const { message: errorMsg, pathf } = errorObj;
                  // This happens if there is a near side Graphql error, ie. bad field selection.
                  if (!pathf) { return null; }
                  return (
                    <div key={`Error ${i + 300}`} className="checkoutsnackbar-message__div">
                      <ErrorIcon />
                      <p className="checkoutsnackbar-message__p">
                        {`${graphQLErrorsDictionary(pathf[pathf.length - 1], pathf)} ${errorMsg}`}
                      </p>
                    </div>
                  );
                })
          }
        </div>
        <IconButton
          disableRipple
          disableTouchRipple
          classes={{ root: classes.rootIconButton }}
          onClick={handleClose}
          className="checkoutsnackbar-close-btn__iconbutton"
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Snackbar>
  );
};

CheckoutSnackbar.propTypes = {
  // handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  message: PropTypes.objectOf(PropTypes.any),
};

export default CheckoutSnackbar;
