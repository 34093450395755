/*

This file and other components that are affected by this file can be simplified
even further and refactored. locationsDictionary with an added param to indicate
the floor would tell which dictionary to use to reduce the number of functions
needed to 1. Excluded location codes should also be moved to this file.
For now, if you want to make a location code update, you have to
update locationsDictionary, the object, and the function of the respective floor.

*/
const locationsDictionary = (str) => {
  const locationsDic = {
    MART: '1st Floor - Artist Merchandise Display',
    TSTAT: '1st Floor - Authors of Color Table',
    TART1: '1st Floor - Best of the 2nd Floor - Art Display Table',
    TBEST: '1st Floor - Best of the Best Table',
    TBAN: '1st Floor - Banned Books',
    TBASE: '1st Floor - Best of the Underground - Basement Bestsellers Table',
    TBUCK: '1st Floor - Black Studies Table',
    TGIF3: '1st Floor - Black Studies Table',
    TBUY: '1st Floor - Books Behind Buying Desk',
    FRED: '1st Floor - Buying Desk Wall',
    TCAL: '1st Floor - Calendars Table',
    TKID: '1st Floor - Children\'s Bestsellers Table',
    TPICK3: '1st Floor - Climate Change Table',
    MCOL3: '1st Floor - Coloring Merchandise Display Column',
    TGIF2: '1st Floor - Contemporary Bestsellers Table',
    LCOOK: '1st Floor - Cookbooks Ledge',
    MCOOK: '1st Floor - Cooking Merchandise Display',
    CRNR: '1st Floor - Corner Window Display',
    TGIFT: '1st Floor - Everybody Loves Books Table',
    TFIC5: '1st Floor - Fiction in Translation Table',
    TFIC2: '1st Floor - The Future of Fiction Table',
    TCK3: '1st Floor - Food Writing Table',
    MGAME: '1st Floor - Games Display',
    MSTAT3: '1st Floor - Gift Wrap Display',
    TCOM: '1st Floor - Graphic Novels Display',
    MWALL2: '1st Floor - Greeting Cards Display',
    TFIC: '1st Floor - Historical Fiction Table',
    THIST: '1st Floor - Noteable Nonfiction Table',
    TFIC4: '1st Floor - Horror Table',
    TLGBTQ: '1st Floor - LGBTQ Literature Table',
    TLGBT: '1st Floor - LGBTQ Literature Table',
    MMAG: '1st Floor - Main Floor Magnet Spinner',
    THOL: '1st Floor - Featured Collection',
    THOL2: '1st Floor - Spotlight Collection',
    MCOL6: '1st Floor - Merchandise Display Column',
    MCOL7: '1st Floor - Merchandise Display Column',
    MCOL4: '1st Floor - Merchandise Display Column',
    MCOL5: '1st Floor - Merchandise Display Column',
    MCOL: '1st Floor - Merchandise Display Column',
    MCOL2: '1st Floor - Merchandise Display Column',
    MCOL90: '1st Floor - Merchandise Display Column',
    MWALLF: '1st Floor - Merchandise Display Wall',
    MWALL1: '1st Floor - Merchandise Display Wall',
    MWALL3: '1st Floor - Merchandise Display Wall',
    MWALL4: '1st Floor - Merchandise Display Wall',
    MWALL5: '1st Floor - Merchandise Display Wall',
    MLEDGE2: '1st Floor - Merchandise Ledge',
    MSPIN: '1st Floor - Merchandise Spinner',
    MFRNT: '1st Floor - Merchandise Table',
    MRCH: '1st Floor - Merchandise Table',
    TS90: '1st Floor - Modern Classics Table',
    TCOOK: '1st Floor - Chef\'s Choice Table',
    TNEW: '1st Floor - New Arrivals Table',
    TNF1C1: '1st Floor - New Fiction Just Arrived Table',
    TNFIC1: '1st Floor - New Fiction Just Arrived Table',
    TNYRB: '1st Floor - New York Book Review Spinner',
    LNY1: '1st Floor - New York Books Ledge',
    TNY1: '1st Floor - New York Table',
    TFRONT: '1st Floor - No Place Like New York Table',
    TNONF: '1st Floor - Non-Fiction Just Arrived Table',
    TRACK: '1st Floor - Oxford\'s Very Short Introduction Spinner',
    TCLASS: '1st Floor - Paperback Classics Shelf',
    FICSTOCK: '1st Floor - Paperback Fiction overstock opposite the Buying Desk',
    TPLAY: '1st Floor - Plays & Screenplays Table',
    TPOET: '1st Floor - Poetry Specials Table',
    TPOLI: '1st Floor - Elections Are Coming',
    MPUZZ: '1st Floor - Puzzle Display',
    TSCIFI: '1st Floor - Science Fiction Table',
    HISTSETS: '1st Floor - Sets located on top shelves of Civil War/ American Revolution/ Americana.',
    TSHORT: '1st Floor - Short Stories Table',
    TFIC3: '1st Floor - Small Press Table',
    MSOCK: '1st Floor - Sock Wall',
    TPICK2: '1st Floor - Staff Picks Table',
    MSTATC: '1st Floor - Pouch Column',
    MSTAT1: '1st Floor - Stationery Table',
    MSTAT2: '1st Floor - Stationery Table',
    MRCHW: '1st Floor - Strand Bestsellers Wall',
    MSHIRT: '1st Floor - T-Shirt Spinners',
    TFRONT2: '1st Floor - Time Travel Table',
    TCK2: '1st Floor - New & Bestselling Cookbooks Table',
    THOT: '1st Floor - Toni Morrison Table',
    TFILM: '1st Floor - Visual Entertainment Table',
    SWALL: '1st Floor - Wall Behind Registers',
    TPICK: '1st Floor - Manhattan Table',
    TWRITE: '1st Floor - Writer\'s Technique Table',
    '2KBST2': '1st Floor - YA Gift Table',
    MFRNT2: '1st Floor - Literary Gift Ideas',
    TCOL2: '1st Floor - Film & Drama Endcap',
    TCOL1: '1st Floor - Stay Current Column',
    TYA1: '1st Floor - YA & Comics Bestsellers',
    '2WALA': '2nd Floor - Architecture Books Ledge',
    '2ARC1': '2nd Floor - Architecture Tables',
    '2INF2': '2nd Floor - Art Department Back Info Desk',
    '2INF1': '2nd Floor - Art Department Info Desk',
    '2HIS1': '2nd Floor - Art History Tables',
    '2WALM': '2nd Floor - Art Monographs Ledge',
    '2NA1': '2nd Floor - Art New Arrivals',
    '2NA4': '2nd Floor - Art New Arrivals Tables',
    '2END0': '2nd Floor - Art New Arrivals Tables',
    '2END3': '2nd Floor - Art Shelving Endcap',
    '2END6': '2nd Floor - Art Shelving Endcap',
    '2END9': '2nd Floor - Art Shelving Endcap',
    '2END4': '2nd Floor - Art Shelving Endcap',
    '2END2': '2nd Floor - Art Shelving Endcap',
    '2END1': '2nd Floor - Art Shelving Endcap',
    '2END7': '2nd Floor - Art Shelving Endcap',
    '2END10': '2nd Floor - Art Staff Picks Endcap',
    '2TEC1': '2nd Floor - Art Technique Table',
    '2BIO': '2nd Floor - Artist Bios and Writing Table',
    '2ART': '2nd Floor - Artistic Inspiration Table',
    '2ELEV': '2nd Floor - Bestseller & Gift Ideas Table',
    '2K7': '2nd Floor - Black History Month Table',
    '2K10': '2nd Floor - Board Books Table',
    '2KAWARD': '2nd Floor - Children\'s Award Winners Table',
    '2KID1': '2nd Floor - Children\'s Bestsellers Table',
    '2KID2': '2nd Floor - Children\'s Comics Table',
    '2TOY1': '2nd Floor - Children\'s Comics/Novelties Display',
    '2INF3': '2nd Floor - Children\'s Department Info Desk',
    '2KF5': '2nd Floor - Children\'s Merchandise Display',
    '2KF3': '2nd Floor - Children\'s Merchandise Endcap',
    '2KF2': '2nd Floor - Children\'s Merchandise Endcap',
    '2KID5': '2nd Floor - Children\'s New Adventure Books',
    '2KNY': '2nd Floor - Children\'s New York Books Table',
    '2KID3': '2nd Floor - Children\'s Picture Books Table',
    '2KIDP': '2nd Floor - Children\'s Staff Picks Table',
    '2THEME': '2nd Floor - Children\'s Staff Picks Table',
    '2KCORE': '2nd Floor - Classic Academic Books Table',
    '2COM1': '2nd Floor - Comics/Graphic Novels Best of the Best Table',
    '2COM3': '2nd Floor - Comics/Graphic Novels Black Wire Display',
    '2COM6': '2nd Floor - Comics/Graphic Novels Classics Table',
    '2COM2': '2nd Floor - Comics/Graphic Novels Ledge',
    '2COM4': '2nd Floor - Comics/Graphic Novels Memoir Table',
    '2COM7': '2nd Floor - Comics/Graphic Novels New Arrivals Table',
    '2COMP': '2nd Floor - Comics/Graphic Novels Staff Picks',
    '2COM': '2nd Floor - Comics/Graphic Novels Table',
    '2CRFT2': '2nd Floor - Crafts Display',
    '2CRFTW': '2nd Floor - Crafts Display Wall',
    '2CRAFT': '2nd Floor - Crafts Table',
    '2K11': '2nd Floor - Create and Inspire Theme Table',
    '2K14': '2nd Floor - Dinosaur Table',
    '2KSPIN2': '2nd Floor - Dover Books Spinner',
    '2KSPIN3': '2nd Floor - Dover Coloring Books Spinner',
    '2KSPIN4': '2nd Floor - Dr. Seuss Books Spinner',
    '2KSPIN5': '2nd Floor - Early Reader Spinner',
    '2PHO3': '2nd Floor - Erotica Table',
    '2FASH': '2nd Floor - Fashion Table',
    '2FASH2': '2nd Floor - Fashion Table',
    '2K13': '2nd Floor - Favorite Characters Table',
    '2GDES': '2nd Floor - Graphic Design Table',
    '2PHO4': '2nd Floor - Icons & Troublemakers Photography Table',
    '2MEZZ3': '2nd Floor - Illustration Table',
    '2NPHO': '2nd Floor - In Focus Photography Table',
    '2KWALL': '2nd Floor - Info Desk Wall in Children\'s Department',
    '2KSPIN': '2nd Floor - Kid\'s Spinners Display',
    '2K8': '2nd Floor - Kids\' Chapter Books Display',
    '2KCLAS': '2nd Floor - Kids\' Classics Table',
    '2KCOM': '2nd Floor - Kids\' Graphic Novels Display',
    '2KUMON': '2nd Floor - Kumon Spinner',
    '2KSPIN1': '2nd Floor - Little Golden Books Spinner',
    '2PHO1': '2nd Floor - Mad World Photography Table',
    '2MAGNET2': '2nd Floor - Magnet Spinner',
    '2MAGNET': '2nd Floor - Magnet Spinner',
    '2MANGA': '2nd Floor - Manga Spinner',
    '2COL3': '2nd Floor - Merchandise Display Column',
    '2COL5': '2nd Floor - Merchandise Display Column',
    '2COL6': '2nd Floor - Merchandise Display Column',
    '2COL7': '2nd Floor - Merchandise Display Column',
    '2COL8': '2nd Floor - Merchandise Display Column',
    '2COL9': '2nd Floor - Merchandise Display Column',
    '2COL1': '2nd Floor - Merchandise Display Column',
    '2COL4': '2nd Floor - Merchandise Display Column',
    '2COL11': '2nd Floor - Merchandise Display Column',
    '2COL2': '2nd Floor - Merchandise Display Column',
    '2COL10': '2nd Floor - Merchandise Display Column',
    '2KEND2': '2nd Floor - Merchandise Display Endcap',
    '2KEND4': '2nd Floor - Merchandise Display Endcap',
    '2KEND1': '2nd Floor - Merchandise Display Endcap',
    '2MEZZ2': '2nd Floor - Mezzanine Merchandise Column',
    '2MON2': '2nd Floor - Monographs Tables',
    '2MON1': '2nd Floor - Monographs Tables',
    '2NY1': '2nd Floor - New York Photography Table',
    '2K15': '2nd Floor - Nonfiction for Kids Table',
    '2BIG': '2nd Floor - Oversized Photography Books Endcap',
    RBRON2: '2nd Floor - Rare Art Books Case',
    RBR14: '2nd Floor - Rare Comics Case',
    RBR06: '2nd Floor - Rare Humanities Books Case',
    '2SOCK': '2nd Floor - Socks Spinner',
    '2SOCK2': '2nd Floor - Socks Spinner',
    '2SOCK3': '2nd Floor - Socks Spinner',
    '2SSPIN': '2nd Floor - Socks Spinnner',
    '2KEND': '2nd Floor - Staff Picks for Kids Endcap',
    '2PICK': '2nd Floor - Steidl Table',
    '2K12': '2nd Floor - STEM (Educational Books for Kids) Theme Table',
    '2KEND5': '2nd Floor - Stickers Endcap',
    '2MEZZ1': '2nd Floor - Style Mezzanine Table',
    '2K9': '2nd Floor - Tackling Complex Topics Table',
    '2MEZZ': '2nd Floor - Taschen Mezzanine Display',
    '2PHO2': '2nd Floor - Too Cool to be Categorized Photography Table',
    '2KEND3': '2nd Floor - What Pet Should You Get Endcap',
    '2COM5': '2nd Floor - Women in Comics/Graphic Novels Table',
    '2KEND6': '2nd Floor - YA Bestsellers Endcap',
    '2KBST': '2nd Floor - YA Bestsellers Table',
    '2KYA': '2nd Floor - YA Display Table',
    '2K6': '2nd Floor - YA LGBTQIA Books Table',
    '2TOTE': '2nd Floor - Merchandise Display',
    RBRNEW: '2nd Floor - Rare New Arrivals',
    RBR01: '3rd Floor Rare Book Room',
    RBR04: '3rd Floor Rare Book Room - 19th Century Case',
    RBRART: '3rd Floor Rare Book Room - Art Since 1900 Table',
    RBR13: '3rd Floor Rare Book Room - Back Wall',
    RBRMOD: '3rd Floor Rare Book Room - Books We Love Under $50',
    RBR15: '3rd Floor Rare Book Room - Children\'s & Illustrated Case',
    RBREL: '3rd Floor Rare Book Room - Elevator Case',
    RBRGIFT: '3rd Floor Rare Book Room - Fashion',
    RBR12: '3rd Floor Rare Book Room - Fine Press Case',
    RBRCOLL: '3rd Floor Rare Book Room - For the Kid in All of Us Table',
    RBR02: '3rd Floor Rare Book Room - Front Desks Displays',
    RBR03: '3rd Floor Rare Book Room - Front Register Desk Display',
    RBRTC1: '3rd Floor Rare Book Room - Glass Vitrine',
    RBRTC2: '3rd Floor Rare Book Room - Glass Vitrine',
    RBRTC3: '3rd Floor Rare Book Room - Glass Vitrine',
    RBR11: '3rd Floor Rare Book Room - Gold Vault',
    RBRHC: '3rd Floor Rare Book Room - Hacker Art Case',
    RBR05: '3rd Floor Rare Book Room - History/Travel Case',
    RBR10: '3rd Floor Rare Book Room - Holiday Gift Ideas Table',
    RBRPICK1: '3rd Floor Rare Book Room - Managers\' Picks Table',
    MRBR: '3rd Floor Rare Book Room - Merchandise Display',
    RBR07: '3rd Floor Rare Book Room - Modern First Editions Case',
    RBR09: '3rd Floor Rare Book Room - Mystery & Science Fiction Case',
    RBRMYSF: '3rd Floor Rare Book Room - Mystery & Science Fiction Table',
    RBRPHOTO: '3rd Floor Rare Book Room - Photography Picks Table',
    RBSALE: '3rd Floor Rare Book Room - Photography Shelves',
    RBEARLY: '3rd Floor Rare Book Room - Poets Corner Table',
    RBRPICK2: '3rd Floor Rare Book Room - Rare Display Table',
    RBR08: '3rd Floor Rare Book Room - Science/Medicine Case',
    RBRSIGN1: '3rd Floor Rare Book Room - Signed Copies Table',
    RBRPICK3: '3rd Floor Rare Book Room - Staff Picks Table',
    OFFSITE: 'Ask Associate',
    RBACK: 'Basement - Vinyl Overstock',
    EVPRE: 'Event - Signed Copy, no admission',
    EVTICKET: 'Event - Admission',
    EVONLY: 'Event - Admission & Book',
    EVCARD: 'Event - Admission & Gift Card',
    EVBOOK: 'Event - Admission & Signed Copy',
    EVWSA: 'Event - Admission & Writers Studio Anthology',
    SECTION: 'In-Store Section Shelf',
    INTERNETDEPT: 'Internet Sales',
    LCKW: 'Main Floor - Themed Display Wall',
    TTOWN: 'Table Town - Ask Staff',
    BMUSIC3: 'Underground - 33 1/3 Books Spinner',
    BMED2: 'Underground - Alternative Medicine & Yoga Table',
    BTRVL2: 'Underground - Armchair Traveler Table',
    BBEST: 'Underground - Bestsellers Table',
    BHOLY: 'Underground - Bibles Endcap',
    BGIF6: 'Underground - Books Under $10 Table',
    BGIF8: 'Underground - Bucket List Table',
    LBIZ: 'Underground - Business Books on Ledge',
    BBUS: 'Underground - Business Shelving Endcaps',
    BDESK: 'Underground - Buying Desk',
    BPHILE2: 'Underground - Classical Philosophy Endcap',
    BDINO: 'Underground - Dinosaur Table',
    BSTFP: 'Underground - Dissent Magazine Collaboration Endcap',
    BDVD: 'Underground - DVD Tables',
    BGIF4: 'Underground - Elections Are Coming Table',
    BEND2: 'Underground - Exploration Table',
    BEND: 'Underground - Exploration Table',
    BTWNTY: 'Underground - Featured Books Under $20 Table',
    BPETSH: 'Underground - Featured Pet Books Shelf',
    BGAME: 'Underground - Games Table',
    BSNP: 'Underground - Gender Studies Book Table',
    BGIFT: 'Underground - Gift Ideas Table',
    BHIP: 'Underground - Hip Hop Merch and Books Display',
    BHUM: 'Underground - Humor Table',
    BWRITE: 'Underground - Inspiration Table',
    BMAP: 'Underground - International City Maps Table',
    BLIT: 'Underground - Literary Non-Fiction Tables',
    BEDUE: 'Underground - Literary Nonfiction Endcap',
    BMAG: 'Underground - Magnet Spinner',
    BMED: 'Underground - Medicine and Health Books Table',
    BMEM: 'Underground - Memoirs Table',
    BSTACKS: 'Underground - Merchandise Display',
    BGIF9: 'Underground - Merchandise Display',
    BWARE: 'Underground - Military Endcap',
    BMOLE: 'Underground - Moleskine Planner Spinner',
    BMUSIC2: 'Underground - Music Books Display',
    BMUSIC: 'Underground - Music Table',
    BMYTH: 'Underground - Mythology and the Occult Table',
    BBIGSH1: 'Underground - Oversized Shelf',
    BBIGSH2: 'Underground - Oversized Shelf',
    BOSTOCK: 'Underground - Overstock of Books',
    BPNGN: 'Underground - Penguin Spinner',
    BPET: 'Underground - Pet Merchandise Display',
    BPHIL2: 'Underground - Philosophy Bestsellers Table',
    BPHILE: 'Underground - Philosophy Endcap',
    BPHIL: 'Underground - Philosophy Table',
    BPOLI: 'Underground - Political Science Endcap',
    BMAP2: 'Underground - Poster Maps Display',
    BPSYCH: 'Underground - Psychology Books Table',
    BGIF3: 'Underground - Punk Rock Table',
    BRELE: 'Underground - Religion Books Endcap',
    BREL: 'Underground - Religion Table',
    BSCI: 'Underground - Science & Mathematics Table',
    BSNPE2: 'Underground - Self Help/Psychology Endcap',
    BSNPE: 'Underground - Social Sciences Endcap',
    BSSS: 'Underground - Social Sciences Table',
    BELEV: 'Underground - Songwriters Table',
    BEND3: 'Underground - Sports Table',
    BEND4: 'Underground - Sports Table',
    BPICK: 'Underground - Staff Picks Tables',
    BGARD: 'Underground - Strand Favorites Table',
    BGIF5: 'Underground - Strand Nonfiction Recommendations Table',
    BUFO: 'Underground - The Truth Is Out There Endcap',
    BGIF7: 'Underground - True Crime ... Or Is It?! Table',
    BCRIME: 'Underground - True Crime Table',
    BUNIV: 'Underground - University Review Shelves',
    BCOL5: 'Underground - Vinyl Display Column',
    BREC: 'Underground - Vinyl Records Table',
    BREC2: 'Underground - Vinyl Records Table',
    BWAR: 'Underground - War and Conflict Table',
    BGIF2: 'Underground - Well-Read Woman Display',
    BYOGASH: 'Underground - Yoga/Massage Featured Shelf',
    'MERCH-STRAND TOTES': 'Totes & Pouches',
    'MERCH-STRAND WEARABLES': 'Wearables',
    'MERCH-BOOK ACCESSORIES': 'Book Accessories',
    'MERCH-STRAND SOUV/STAT': 'Strand Souvenirs & Stationery',
    'MERCH-JUST FOR KIDS': 'Just for Kids',
    'MERCH-GM GIFTWARE': 'Giftware',
    'MERCH-MOLESKINES': 'Moleskines',
    'MERCH-KITCHENWARE': 'Kitchenware',
    'MERCH-GM NEW YORK': 'New York',
    'MERCH-GM DRINKWARE': 'Drinkware',
    'MERCH-GM SOUV/STAT': 'Souvenirs & Stationery',
    'MERCH-FOOD/WATER': 'Food & Water',
    'MERCH-GM GAMES/PUZZLES': 'Games & Puzzles',
    'MERCH-GM CARDS/POSTCARDS': 'Cards',
    'MERCH-GM TOTES/POUCHES': 'Totes & Pouches',
    'MERCH-MAGNETICS': 'Magnetics',
    'MERCH-GM WEARABLES': 'Wearables',
    'MERCH-GM CHOCOLATE': 'Chocolate',
    'MERCH-ARTS/CRAFTS': 'Arts & Crafts',
    'MERCH-STRAND DRINKWARE': 'Strand Drinkware',
    'MERCH-COOL MINTS ETC': 'Cool Mints',
    'MERCH-GM CARDS': 'Cards',
  };

  if (locationsDic[str]) {
    return locationsDic[str];
  }

  return str;
};

export default locationsDictionary;

export const locationsObjFirst = {
  MART: '1st Floor - Artist Merchandise Display',
  TSTAT: '1st Floor - Authors of Color Table',
  TART1: '1st Floor - Best of the 2nd Floor - Art Display Table',
  TBEST: '1st Floor - Best of the Best Table',
  TBAN: '1st Floor - Banned Books',
  TBASE: '1st Floor - Best of the Underground - Basement Bestsellers Table',
  TBUCK: '1st Floor - Black Studies Table',
  TGIF3: '1st Floor - Black Studies Table',
  TBUY: '1st Floor - Books Behind Buying Desk',
  FRED: '1st Floor - Buying Desk Wall',
  TCAL: '1st Floor - Calendars Table',
  TKID: '1st Floor - Children\'s Bestsellers Table',
  TPICK3: '1st Floor - Climate Change Table',
  MCOL3: '1st Floor - Coloring Merchandise Display Column',
  TGIF2: '1st Floor - Contemporary Bestsellers Table',
  LCOOK: '1st Floor - Cookbooks Ledge',
  MCOOK: '1st Floor - Cooking Merchandise Display',
  CRNR: '1st Floor - Corner Window Display',
  TGIFT: '1st Floor - Everybody Loves Books Table',
  TFIC5: '1st Floor - Fiction in Translation Table',
  TFIC2: '1st Floor - The Future of Fiction Table',
  TCK3: '1st Floor - Food Writing Table',
  MGAME: '1st Floor - Games Display',
  MSTAT3: '1st Floor - Gift Wrap Display',
  TCOM: '1st Floor - Graphic Novels Display',
  MWALL2: '1st Floor - Greeting Cards Display',
  TFIC: '1st Floor - Historical Fiction Table',
  THIST: '1st Floor - Noteable Nonfiction Table',
  TFIC4: '1st Floor - Horror Table',
  TLGBTQ: '1st Floor - LGBTQ Literature Table',
  TLGBT: '1st Floor - LGBTQ Literature Table',
  MMAG: '1st Floor - Main Floor Magnet Spinner',
  THOL: '1st Floor - AAPI Heritage Month Table',
  THOL2: '1st Floor - Mental Health Awareness Month Table',
  MCOL6: '1st Floor - Merchandise Display Column',
  MCOL7: '1st Floor - Merchandise Display Column',
  MCOL4: '1st Floor - Merchandise Display Column',
  MCOL5: '1st Floor - Merchandise Display Column',
  MCOL: '1st Floor - Merchandise Display Column',
  MCOL2: '1st Floor - Merchandise Display Column',
  MCOL90: '1st Floor - Merchandise Display Column',
  MWALLF: '1st Floor - Merchandise Display Wall',
  MWALL1: '1st Floor - Merchandise Display Wall',
  MWALL3: '1st Floor - Merchandise Display Wall',
  MWALL4: '1st Floor - Merchandise Display Wall',
  MWALL5: '1st Floor - Merchandise Display Wall',
  MLEDGE2: '1st Floor - Merchandise Ledge',
  MSPIN: '1st Floor - Merchandise Spinner',
  MFRNT: '1st Floor - Merchandise Table',
  MRCH: '1st Floor - Merchandise Table',
  TS90: '1st Floor - Modern Classics Table',
  TCOOK: '1st Floor - Chef\'s Choice Table',
  TNEW: '1st Floor - New Arrivals Table',
  TNF1C1: '1st Floor - New Fiction Just Arrived Table',
  TNFIC1: '1st Floor - New Fiction Just Arrived Table',
  TNYRB: '1st Floor - New York Book Review Spinner',
  LNY1: '1st Floor - New York Books Ledge',
  TNY1: '1st Floor - New York Table',
  TFRONT: '1st Floor - No Place Like New York Table',
  TNONF: '1st Floor - Non-Fiction Just Arrived Table',
  TRACK: '1st Floor - Oxford\'s Very Short Introduction Spinner',
  TCLASS: '1st Floor - Paperback Classics Shelf',
  FICSTOCK: '1st Floor - Paperback Fiction overstock opposite the Buying Desk',
  TPLAY: '1st Floor - Plays & Screenplays Table',
  TPOET: '1st Floor - Poetry Specials Table',
  MPUZZ: '1st Floor - Puzzle Display',
  TSCIFI: '1st Floor - Science Fiction Table',
  HISTSETS: '1st Floor - Sets located on top shelves of Civil War/ American Revolution/ Americana.',
  TSHORT: '1st Floor - Short Stories Table',
  TFIC3: '1st Floor - Small Press Table',
  MSOCK: '1st Floor - Sock Wall',
  TPICK2: '1st Floor - Staff Picks Table',
  MSTATC: '1st Floor - Pouch Column',
  MSTAT1: '1st Floor - Stationery Table',
  MSTAT2: '1st Floor - Stationery Table',
  MRCHW: '1st Floor - Strand Bestsellers Wall',
  MSHIRT: '1st Floor - T-Shirt Spinners',
  TFRONT2: '1st Floor - Time Travel Table',
  TCK2: '1st Floor - New & Bestselling Cookbooks Table',
  THOT: '1st Floor - Toni Morrison Table',
  TFILM: '1st Floor - Visual Entertainment Table',
  SWALL: '1st Floor - Wall Behind Registers',
  TPICK: '1st Floor - Manhattan Table',
  TWRITE: '1st Floor - Writer\'s Technique Table',
  '2KBST2': '1st Floor - YA Gift Table',
  MFRNT2: '1st Floor - Literary Gift Ideas',
  TCOL2: '1st Floor - Film & Drama Endcap',
  TCOL1: '1st Floor - Stay Current Column',
  TYA1: '1st Floor - YA & Comics Bestsellers',
};

export const locationsDictionaryFirst = (str) => {
  const locationsDic = {
    MART: '1st Floor - Artist Merchandise Display',
    TSTAT: '1st Floor - Authors of Color Table',
    TART1: '1st Floor - Best of the 2nd Floor - Art Display Table',
    TBEST: '1st Floor - Best of the Best Table',
    TBAN: '1st Floor - Banned Books',
    TBASE: '1st Floor - Best of the Underground - Basement Bestsellers Table',
    TBUCK: '1st Floor - Black Studies Table',
    TGIF3: '1st Floor - Black Studies Table',
    TBUY: '1st Floor - Books Behind Buying Desk',
    FRED: '1st Floor - Buying Desk Wall',
    TCAL: '1st Floor - Calendars Table',
    TKID: '1st Floor - Children\'s Bestsellers Table',
    TPICK3: '1st Floor - Climate Change Table',
    MCOL3: '1st Floor - Coloring Merchandise Display Column',
    TGIF2: '1st Floor - Contemporary Bestsellers Table',
    LCOOK: '1st Floor - Cookbooks Ledge',
    MCOOK: '1st Floor - Cooking Merchandise Display',
    CRNR: '1st Floor - Corner Window Display',
    TGIFT: '1st Floor - Everybody Loves Books Table',
    TFIC5: '1st Floor - Fiction in Translation Table',
    TFIC2: '1st Floor - The Future of Fiction Table',
    TCK3: '1st Floor - Food Writing Table',
    MGAME: '1st Floor - Games Display',
    MSTAT3: '1st Floor - Gift Wrap Display',
    TCOM: '1st Floor - Graphic Novels Display',
    MWALL2: '1st Floor - Greeting Cards Display',
    TFIC: '1st Floor - Historical Fiction Table',
    THIST: '1st Floor - Noteable Nonfiction Table',
    TFIC4: '1st Floor - Horror Table',
    TLGBTQ: '1st Floor - LGBTQ Literature Table',
    TLGBT: '1st Floor - LGBTQ Literature Table',
    MMAG: '1st Floor - Main Floor Magnet Spinner',
    THOL: '1st Floor - AAPI Heritage Month Table',
    THOL2: '1st Floor - Mental Health Awareness Month Table',
    MCOL6: '1st Floor - Merchandise Display Column',
    MCOL7: '1st Floor - Merchandise Display Column',
    MCOL4: '1st Floor - Merchandise Display Column',
    MCOL5: '1st Floor - Merchandise Display Column',
    MCOL: '1st Floor - Merchandise Display Column',
    MCOL2: '1st Floor - Merchandise Display Column',
    MCOL90: '1st Floor - Merchandise Display Column',
    MWALLF: '1st Floor - Merchandise Display Wall',
    MWALL1: '1st Floor - Merchandise Display Wall',
    MWALL3: '1st Floor - Merchandise Display Wall',
    MWALL4: '1st Floor - Merchandise Display Wall',
    MWALL5: '1st Floor - Merchandise Display Wall',
    MLEDGE2: '1st Floor - Merchandise Ledge',
    MSPIN: '1st Floor - Merchandise Spinner',
    MFRNT: '1st Floor - Merchandise Table',
    MRCH: '1st Floor - Merchandise Table',
    TS90: '1st Floor - Modern Classics Table',
    TCOOK: '1st Floor - Chef\'s Choice Table',
    TNEW: '1st Floor - New Arrivals Table',
    TNF1C1: '1st Floor - New Fiction Just Arrived Table',
    TNFIC1: '1st Floor - New Fiction Just Arrived Table',
    TNYRB: '1st Floor - New York Book Review Spinner',
    LNY1: '1st Floor - New York Books Ledge',
    TNY1: '1st Floor - New York Table',
    TFRONT: '1st Floor - No Place Like New York Table',
    TNONF: '1st Floor - Non-Fiction Just Arrived Table',
    TRACK: '1st Floor - Oxford\'s Very Short Introduction Spinner',
    TCLASS: '1st Floor - Paperback Classics Shelf',
    FICSTOCK: '1st Floor - Paperback Fiction overstock opposite the Buying Desk',
    TPLAY: '1st Floor - Plays & Screenplays Table',
    TPOET: '1st Floor - Poetry Specials Table',
    MPUZZ: '1st Floor - Puzzle Display',
    TSCIFI: '1st Floor - Science Fiction Table',
    HISTSETS: '1st Floor - Sets located on top shelves of Civil War/ American Revolution/ Americana.',
    TSHORT: '1st Floor - Short Stories Table',
    TFIC3: '1st Floor - Small Press Table',
    MSOCK: '1st Floor - Sock Wall',
    TPICK2: '1st Floor - Staff Picks Table',
    MSTATC: '1st Floor - Pouch Column',
    MSTAT1: '1st Floor - Stationery Table',
    MSTAT2: '1st Floor - Stationery Table',
    MRCHW: '1st Floor - Strand Bestsellers Wall',
    MSHIRT: '1st Floor - T-Shirt Spinners',
    TFRONT2: '1st Floor - Time Travel Table',
    TCK2: '1st Floor - New & Bestselling Cookbooks Table',
    THOT: '1st Floor - Toni Morrison Table',
    TFILM: '1st Floor - Visual Entertainment Table',
    SWALL: '1st Floor - Wall Behind Registers',
    TPICK: '1st Floor - Manhattan Table',
    TWRITE: '1st Floor - Writer\'s Technique Table',
    '2KBST2': '1st Floor - YA Gift Table',
    MFRNT2: '1st Floor - Literary Gift Ideas',
    TCOL2: '1st Floor - Film & Drama Endcap',
    TCOL1: '1st Floor - Stay Current Column',
    TYA1: '1st Floor - YA & Comics Bestsellers',
  };

  if (locationsDic[str]) {
    return locationsDic[str];
  }

  return str;
};

export const locationsObjSecond = {
  '2WALA': '2nd Floor - Architecture Books Ledge',
  '2ARC1': '2nd Floor - Architecture Tables',
  '2INF2': '2nd Floor - Art Department Back Info Desk',
  '2INF1': '2nd Floor - Art Department Info Desk',
  '2HIS1': '2nd Floor - Art History Tables',
  '2WALM': '2nd Floor - Art Monographs Ledge',
  '2NA1': '2nd Floor - Art New Arrivals',
  '2NA4': '2nd Floor - Art New Arrivals Tables',
  '2END0': '2nd Floor - Art New Arrivals Tables',
  '2END3': '2nd Floor - Art Shelving Endcap',
  '2END6': '2nd Floor - Art Shelving Endcap',
  '2END9': '2nd Floor - Art Shelving Endcap',
  '2END4': '2nd Floor - Art Shelving Endcap',
  '2END2': '2nd Floor - Art Shelving Endcap',
  '2END1': '2nd Floor - Art Shelving Endcap',
  '2END7': '2nd Floor - Art Shelving Endcap',
  '2END10': '2nd Floor - Art Staff Picks Endcap',
  '2TEC1': '2nd Floor - Art Technique Table',
  '2BIO': '2nd Floor - Artist Bios and Writing Table',
  '2ART': '2nd Floor - Artistic Inspiration Table',
  '2ELEV': '2nd Floor - Bestseller & Gift Ideas Table',
  '2K7': '2nd Floor - Black History Month Table',
  '2K10': '2nd Floor - Board Books Table',
  '2KAWARD': '2nd Floor - Children\'s Award Winners Table',
  '2KID1': '2nd Floor - Children\'s Bestsellers Table',
  '2KID2': '2nd Floor - Children\'s Comics Table',
  '2TOY1': '2nd Floor - Children\'s Comics/Novelties Display',
  '2INF3': '2nd Floor - Children\'s Department Info Desk',
  '2KF5': '2nd Floor - Children\'s Merchandise Display',
  '2KF3': '2nd Floor - Children\'s Merchandise Endcap',
  '2KF2': '2nd Floor - Children\'s Merchandise Endcap',
  '2KID5': '2nd Floor - Children\'s New Adventure Books',
  '2KNY': '2nd Floor - Children\'s New York Books Table',
  '2KID3': '2nd Floor - Children\'s Picture Books Table',
  '2KIDP': '2nd Floor - Children\'s Staff Picks Table',
  '2THEME': '2nd Floor - Children\'s Staff Picks Table',
  '2KCORE': '2nd Floor - Classic Academic Books Table',
  '2COM1': '2nd Floor - Comics/Graphic Novels Best of the Best Table',
  '2COM3': '2nd Floor - Comics/Graphic Novels Black Wire Display',
  '2COM6': '2nd Floor - Comics/Graphic Novels Classics Table',
  '2COM2': '2nd Floor - Comics/Graphic Novels Ledge',
  '2COM4': '2nd Floor - Comics/Graphic Novels Memoir Table',
  '2COM7': '2nd Floor - Comics/Graphic Novels New Arrivals Table',
  '2COMP': '2nd Floor - Comics/Graphic Novels Staff Picks',
  '2COM': '2nd Floor - Comics/Graphic Novels Table',
  '2CRFT2': '2nd Floor - Crafts Display',
  '2CRFTW': '2nd Floor - Crafts Display Wall',
  '2CRAFT': '2nd Floor - Crafts Table',
  '2K11': '2nd Floor - Create and Inspire Theme Table',
  '2K14': '2nd Floor - Dinosaur Table',
  '2KSPIN2': '2nd Floor - Dover Books Spinner',
  '2KSPIN3': '2nd Floor - Dover Coloring Books Spinner',
  '2KSPIN4': '2nd Floor - Dr. Seuss Books Spinner',
  '2KSPIN5': '2nd Floor - Early Reader Spinner',
  '2PHO3': '2nd Floor - Erotica Table',
  '2FASH': '2nd Floor - Fashion Table',
  '2FASH2': '2nd Floor - Fashion Table',
  '2K13': '2nd Floor - Favorite Characters Table',
  '2GDES': '2nd Floor - Graphic Design Table',
  '2PHO4': '2nd Floor - Icons & Troublemakers Photography Table',
  '2MEZZ3': '2nd Floor - Illustration Table',
  '2NPHO': '2nd Floor - In Focus Photography Table',
  '2KWALL': '2nd Floor - Info Desk Wall in Children\'s Department',
  '2KSPIN': '2nd Floor - Kid\'s Spinners Display',
  '2K8': '2nd Floor - Kids\' Chapter Books Display',
  '2KCLAS': '2nd Floor - Kids\' Classics Table',
  '2KCOM': '2nd Floor - Kids\' Graphic Novels Display',
  '2KUMON': '2nd Floor - Kumon Spinner',
  '2KSPIN1': '2nd Floor - Little Golden Books Spinner',
  '2PHO1': '2nd Floor - Mad World Photography Table',
  '2MAGNET2': '2nd Floor - Magnet Spinner',
  '2MAGNET': '2nd Floor - Magnet Spinner',
  '2MANGA': '2nd Floor - Manga Spinner',
  '2COL3': '2nd Floor - Merchandise Display Column',
  '2COL5': '2nd Floor - Merchandise Display Column',
  '2COL6': '2nd Floor - Merchandise Display Column',
  '2COL7': '2nd Floor - Merchandise Display Column',
  '2COL8': '2nd Floor - Merchandise Display Column',
  '2COL9': '2nd Floor - Merchandise Display Column',
  '2COL1': '2nd Floor - Merchandise Display Column',
  '2COL4': '2nd Floor - Merchandise Display Column',
  '2COL11': '2nd Floor - Merchandise Display Column',
  '2COL2': '2nd Floor - Merchandise Display Column',
  '2COL10': '2nd Floor - Merchandise Display Column',
  '2KEND2': '2nd Floor - Merchandise Display Endcap',
  '2KEND4': '2nd Floor - Merchandise Display Endcap',
  '2KEND1': '2nd Floor - Merchandise Display Endcap',
  '2MEZZ2': '2nd Floor - Mezzanine Merchandise Column',
  '2MON2': '2nd Floor - Monographs Tables',
  '2MON1': '2nd Floor - Monographs Tables',
  '2NY1': '2nd Floor - New York Photography Table',
  '2K15': '2nd Floor - Nonfiction for Kids Table',
  '2BIG': '2nd Floor - Oversized Photography Books Endcap',
  RBRON2: '2nd Floor - Rare Art Books Case',
  RBR14: '2nd Floor - Rare Comics Case',
  RBR06: '2nd Floor - Rare Humanities Books Case',
  '2SOCK': '2nd Floor - Socks Spinner',
  '2SOCK2': '2nd Floor - Socks Spinner',
  '2SOCK3': '2nd Floor - Socks Spinner',
  '2SSPIN': '2nd Floor - Socks Spinnner',
  '2KEND': '2nd Floor - Staff Picks for Kids Endcap',
  '2PICK': '2nd Floor - Steidl Table',
  '2K12': '2nd Floor - STEM (Educational Books for Kids) Theme Table',
  '2KEND5': '2nd Floor - Stickers Endcap',
  '2MEZZ1': '2nd Floor - Style Mezzanine Table',
  '2K9': '2nd Floor - Tackling Complex Topics Table',
  '2MEZZ': '2nd Floor - Taschen Mezzanine Display',
  '2PHO2': '2nd Floor - Too Cool to be Categorized Photography Table',
  '2KEND3': '2nd Floor - What Pet Should You Get Endcap',
  '2COM5': '2nd Floor - Women in Comics/Graphic Novels Table',
  '2KEND6': '2nd Floor - YA Bestsellers Endcap',
  '2KBST': '2nd Floor - YA Bestsellers Table',
  '2KYA': '2nd Floor - YA Display Table',
  '2K6': '2nd Floor - YA LGBTQIA Books Table',
  '2TOTE': '2nd Floor - Merchandise Display',
  RBRNEW: '2nd Floor - Rare New Arrivals',
};

export const locationsDictionarySecond = (str) => {
  const locationsDic = {
    '2WALA': '2nd Floor - Architecture Books Ledge',
    '2ARC1': '2nd Floor - Architecture Tables',
    '2INF2': '2nd Floor - Art Department Back Info Desk',
    '2INF1': '2nd Floor - Art Department Info Desk',
    '2HIS1': '2nd Floor - Art History Tables',
    '2WALM': '2nd Floor - Art Monographs Ledge',
    '2NA1': '2nd Floor - Art New Arrivals',
    '2NA4': '2nd Floor - Art New Arrivals Tables',
    '2END0': '2nd Floor - Art New Arrivals Tables',
    '2END3': '2nd Floor - Art Shelving Endcap',
    '2END6': '2nd Floor - Art Shelving Endcap',
    '2END9': '2nd Floor - Art Shelving Endcap',
    '2END4': '2nd Floor - Art Shelving Endcap',
    '2END2': '2nd Floor - Art Shelving Endcap',
    '2END1': '2nd Floor - Art Shelving Endcap',
    '2END7': '2nd Floor - Art Shelving Endcap',
    '2END10': '2nd Floor - Art Staff Picks Endcap',
    '2TEC1': '2nd Floor - Art Technique Table',
    '2BIO': '2nd Floor - Artist Bios and Writing Table',
    '2ART': '2nd Floor - Artistic Inspiration Table',
    '2ELEV': '2nd Floor - Bestseller & Gift Ideas Table',
    '2K7': '2nd Floor - Black History Month Table',
    '2K10': '2nd Floor - Board Books Table',
    '2KAWARD': '2nd Floor - Children\'s Award Winners Table',
    '2KID1': '2nd Floor - Children\'s Bestsellers Table',
    '2KID2': '2nd Floor - Children\'s Comics Table',
    '2TOY1': '2nd Floor - Children\'s Comics/Novelties Display',
    '2INF3': '2nd Floor - Children\'s Department Info Desk',
    '2KF5': '2nd Floor - Children\'s Merchandise Display',
    '2KF3': '2nd Floor - Children\'s Merchandise Endcap',
    '2KF2': '2nd Floor - Children\'s Merchandise Endcap',
    '2KID5': '2nd Floor - Children\'s New Adventure Books',
    '2KNY': '2nd Floor - Children\'s New York Books Table',
    '2KID3': '2nd Floor - Children\'s Picture Books Table',
    '2KIDP': '2nd Floor - Children\'s Staff Picks Table',
    '2THEME': '2nd Floor - Children\'s Staff Picks Table',
    '2KCORE': '2nd Floor - Classic Academic Books Table',
    '2COM1': '2nd Floor - Comics/Graphic Novels Best of the Best Table',
    '2COM3': '2nd Floor - Comics/Graphic Novels Black Wire Display',
    '2COM6': '2nd Floor - Comics/Graphic Novels Classics Table',
    '2COM2': '2nd Floor - Comics/Graphic Novels Ledge',
    '2COM4': '2nd Floor - Comics/Graphic Novels Memoir Table',
    '2COM7': '2nd Floor - Comics/Graphic Novels New Arrivals Table',
    '2COMP': '2nd Floor - Comics/Graphic Novels Staff Picks',
    '2COM': '2nd Floor - Comics/Graphic Novels Table',
    '2CRFT2': '2nd Floor - Crafts Display',
    '2CRFTW': '2nd Floor - Crafts Display Wall',
    '2CRAFT': '2nd Floor - Crafts Table',
    '2K11': '2nd Floor - Create and Inspire Theme Table',
    '2K14': '2nd Floor - Dinosaur Table',
    '2KSPIN2': '2nd Floor - Dover Books Spinner',
    '2KSPIN3': '2nd Floor - Dover Coloring Books Spinner',
    '2KSPIN4': '2nd Floor - Dr. Seuss Books Spinner',
    '2KSPIN5': '2nd Floor - Early Reader Spinner',
    '2PHO3': '2nd Floor - Erotica Table',
    '2FASH': '2nd Floor - Fashion Table',
    '2FASH2': '2nd Floor - Fashion Table',
    '2K13': '2nd Floor - Favorite Characters Table',
    '2GDES': '2nd Floor - Graphic Design Table',
    '2PHO4': '2nd Floor - Icons & Troublemakers Photography Table',
    '2MEZZ3': '2nd Floor - Illustration Table',
    '2NPHO': '2nd Floor - In Focus Photography Table',
    '2KWALL': '2nd Floor - Info Desk Wall in Children\'s Department',
    '2KSPIN': '2nd Floor - Kid\'s Spinners Display',
    '2K8': '2nd Floor - Kids\' Chapter Books Display',
    '2KCLAS': '2nd Floor - Kids\' Classics Table',
    '2KCOM': '2nd Floor - Kids\' Graphic Novels Display',
    '2KUMON': '2nd Floor - Kumon Spinner',
    '2KSPIN1': '2nd Floor - Little Golden Books Spinner',
    '2PHO1': '2nd Floor - Mad World Photography Table',
    '2MAGNET2': '2nd Floor - Magnet Spinner',
    '2MAGNET': '2nd Floor - Magnet Spinner',
    '2MANGA': '2nd Floor - Manga Spinner',
    '2COL3': '2nd Floor - Merchandise Display Column',
    '2COL5': '2nd Floor - Merchandise Display Column',
    '2COL6': '2nd Floor - Merchandise Display Column',
    '2COL7': '2nd Floor - Merchandise Display Column',
    '2COL8': '2nd Floor - Merchandise Display Column',
    '2COL9': '2nd Floor - Merchandise Display Column',
    '2COL1': '2nd Floor - Merchandise Display Column',
    '2COL4': '2nd Floor - Merchandise Display Column',
    '2COL11': '2nd Floor - Merchandise Display Column',
    '2COL2': '2nd Floor - Merchandise Display Column',
    '2COL10': '2nd Floor - Merchandise Display Column',
    '2KEND2': '2nd Floor - Merchandise Display Endcap',
    '2KEND4': '2nd Floor - Merchandise Display Endcap',
    '2KEND1': '2nd Floor - Merchandise Display Endcap',
    '2MEZZ2': '2nd Floor - Mezzanine Merchandise Column',
    '2MON2': '2nd Floor - Monographs Tables',
    '2MON1': '2nd Floor - Monographs Tables',
    '2NY1': '2nd Floor - New York Photography Table',
    '2K15': '2nd Floor - Nonfiction for Kids Table',
    '2BIG': '2nd Floor - Oversized Photography Books Endcap',
    RBRON2: '2nd Floor - Rare Art Books Case',
    RBR14: '2nd Floor - Rare Comics Case',
    RBR06: '2nd Floor - Rare Humanities Books Case',
    '2SOCK': '2nd Floor - Socks Spinner',
    '2SOCK2': '2nd Floor - Socks Spinner',
    '2SOCK3': '2nd Floor - Socks Spinner',
    '2SSPIN': '2nd Floor - Socks Spinnner',
    '2KEND': '2nd Floor - Staff Picks for Kids Endcap',
    '2PICK': '2nd Floor - Steidl Table',
    '2K12': '2nd Floor - STEM (Educational Books for Kids) Theme Table',
    '2KEND5': '2nd Floor - Stickers Endcap',
    '2MEZZ1': '2nd Floor - Style Mezzanine Table',
    '2K9': '2nd Floor - Tackling Complex Topics Table',
    '2MEZZ': '2nd Floor - Taschen Mezzanine Display',
    '2PHO2': '2nd Floor - Too Cool to be Categorized Photography Table',
    '2KEND3': '2nd Floor - What Pet Should You Get Endcap',
    '2COM5': '2nd Floor - Women in Comics/Graphic Novels Table',
    '2KEND6': '2nd Floor - YA Bestsellers Endcap',
    '2KBST': '2nd Floor - YA Bestsellers Table',
    '2KYA': '2nd Floor - YA Display Table',
    '2K6': '2nd Floor - YA LGBTQIA Books Table',
    '2TOTE': '2nd Floor - Merchandise Display',
    RBRNEW: '2nd Floor - Rare New Arrivals',
  };

  if (locationsDic[str]) {
    return locationsDic[str];
  }

  return str;
};

export const locationsObjUG = {
  BMUSIC3: 'Underground - 33 1/3 Books Spinner',
  BMED2: 'Underground - Alternative Medicine & Yoga Table',
  BTRVL2: 'Underground - Armchair Traveler Table',
  BBEST: 'Underground - Bestsellers Table',
  BHOLY: 'Underground - Bibles Endcap',
  BGIF6: 'Underground - Books Under $10 Table',
  BGIF8: 'Underground - Bucket List Table',
  LBIZ: 'Underground - Business Books on Ledge',
  BBUS: 'Underground - Business Shelving Endcaps',
  BDESK: 'Underground - Buying Desk',
  BPHILE2: 'Underground - Classical Philosophy Endcap',
  BDINO: 'Underground - Dinosaur Table',
  BSTFP: 'Underground - Dissent Magazine Collaboration Endcap',
  BDVD: 'Underground - DVD Tables',
  BGIF4: 'Underground - Elections Are Coming Table',
  BEND2: 'Underground - Exploration Table',
  BEND: 'Underground - Exploration Table',
  BTWNTY: 'Underground - Featured Books Under $20 Table',
  BPETSH: 'Underground - Featured Pet Books Shelf',
  BGAME: 'Underground - Games Table',
  BSNP: 'Underground - Gender Studies Book Table',
  BGIFT: 'Underground - Gift Ideas Table',
  BHIP: 'Underground - Hip Hop Merch and Books Display',
  BHUM: 'Underground - Humor Table',
  BWRITE: 'Underground - Inspiration Table',
  BMAP: 'Underground - International City Maps Table',
  BLIT: 'Underground - Literary Non-Fiction Tables',
  BEDUE: 'Underground - Literary Nonfiction Endcap',
  BMAG: 'Underground - Magnet Spinner',
  BMED: 'Underground - Medicine and Health Books Table',
  BMEM: 'Underground - Memoirs Table',
  BSTACKS: 'Underground - Merchandise Display',
  BGIF9: 'Underground - Merchandise Display',
  BWARE: 'Underground - Military Endcap',
  BMOLE: 'Underground - Moleskine Planner Spinner',
  BMUSIC2: 'Underground - Music Books Display',
  BMUSIC: 'Underground - Music Table',
  BMYTH: 'Underground - Mythology and the Occult Table',
  BBIGSH1: 'Underground - Oversized Shelf',
  BBIGSH2: 'Underground - Oversized Shelf',
  BOSTOCK: 'Underground - Overstock of Books',
  BPNGN: 'Underground - Penguin Spinner',
  BPET: 'Underground - Pet Merchandise Display',
  BPHIL2: 'Underground - Philosophy Bestsellers Table',
  BPHILE: 'Underground - Philosophy Endcap',
  BPHIL: 'Underground - Philosophy Table',
  BPOLI: 'Underground - Political Science Endcap',
  BMAP2: 'Underground - Poster Maps Display',
  BPSYCH: 'Underground - Psychology Books Table',
  BGIF3: 'Underground - Punk Rock Table',
  BRELE: 'Underground - Religion Books Endcap',
  BREL: 'Underground - Religion Table',
  BSCI: 'Underground - Science & Mathematics Table',
  BSNPE2: 'Underground - Self Help/Psychology Endcap',
  BSNPE: 'Underground - Social Sciences Endcap',
  BSSS: 'Underground - Social Sciences Table',
  BELEV: 'Underground - Songwriters Table',
  BEND3: 'Underground - Sports Table',
  BEND4: 'Underground - Sports Table',
  BPICK: 'Underground - Staff Picks Tables',
  BGARD: 'Underground - Strand Favorites Table',
  BGIF5: 'Underground - Strand Nonfiction Recommendations Table',
  BUFO: 'Underground - The Truth Is Out There Endcap',
  BGIF7: 'Underground - True Crime ... Or Is It?! Table',
  BCRIME: 'Underground - True Crime Table',
  BUNIV: 'Underground - University Review Shelves',
  BCOL5: 'Underground - Vinyl Display Column',
  BREC: 'Underground - Vinyl Records Table',
  BREC2: 'Underground - Vinyl Records Table',
  BWAR: 'Underground - War and Conflict Table',
  BGIF2: 'Underground - Well-Read Woman Display',
  BYOGASH: 'Underground - Yoga/Massage Featured Shelf',
};

export const locationsDictionaryUG = (str) => {
  const locationsDic = {
    BMUSIC3: 'Underground - 33 1/3 Books Spinner',
    BMED2: 'Underground - Alternative Medicine & Yoga Table',
    BTRVL2: 'Underground - Armchair Traveler Table',
    BBEST: 'Underground - Bestsellers Table',
    BHOLY: 'Underground - Bibles Endcap',
    BGIF6: 'Underground - Books Under $10 Table',
    BGIF8: 'Underground - Bucket List Table',
    LBIZ: 'Underground - Business Books on Ledge',
    BBUS: 'Underground - Business Shelving Endcaps',
    BDESK: 'Underground - Buying Desk',
    BPHILE2: 'Underground - Classical Philosophy Endcap',
    BDINO: 'Underground - Dinosaur Table',
    BSTFP: 'Underground - Dissent Magazine Collaboration Endcap',
    BDVD: 'Underground - DVD Tables',
    BGIF4: 'Underground - Elections Are Coming Table',
    BEND2: 'Underground - Exploration Table',
    BEND: 'Underground - Exploration Table',
    BTWNTY: 'Underground - Featured Books Under $20 Table',
    BPETSH: 'Underground - Featured Pet Books Shelf',
    BGAME: 'Underground - Games Table',
    BSNP: 'Underground - Gender Studies Book Table',
    BGIFT: 'Underground - Gift Ideas Table',
    BHIP: 'Underground - Hip Hop Merch and Books Display',
    BHUM: 'Underground - Humor Table',
    BWRITE: 'Underground - Inspiration Table',
    BMAP: 'Underground - International City Maps Table',
    BLIT: 'Underground - Literary Non-Fiction Tables',
    BEDUE: 'Underground - Literary Nonfiction Endcap',
    BMAG: 'Underground - Magnet Spinner',
    BMED: 'Underground - Medicine and Health Books Table',
    BMEM: 'Underground - Memoirs Table',
    BSTACKS: 'Underground - Merchandise Display',
    BGIF9: 'Underground - Merchandise Display',
    BWARE: 'Underground - Military Endcap',
    BMOLE: 'Underground - Moleskine Planner Spinner',
    BMUSIC2: 'Underground - Music Books Display',
    BMUSIC: 'Underground - Music Table',
    BMYTH: 'Underground - Mythology and the Occult Table',
    BBIGSH1: 'Underground - Oversized Shelf',
    BBIGSH2: 'Underground - Oversized Shelf',
    BOSTOCK: 'Underground - Overstock of Books',
    BPNGN: 'Underground - Penguin Spinner',
    BPET: 'Underground - Pet Merchandise Display',
    BPHIL2: 'Underground - Philosophy Bestsellers Table',
    BPHILE: 'Underground - Philosophy Endcap',
    BPHIL: 'Underground - Philosophy Table',
    BPOLI: 'Underground - Political Science Endcap',
    BMAP2: 'Underground - Poster Maps Display',
    BPSYCH: 'Underground - Psychology Books Table',
    BGIF3: 'Underground - Punk Rock Table',
    BRELE: 'Underground - Religion Books Endcap',
    BREL: 'Underground - Religion Table',
    BSCI: 'Underground - Science & Mathematics Table',
    BSNPE2: 'Underground - Self Help/Psychology Endcap',
    BSNPE: 'Underground - Social Sciences Endcap',
    BSSS: 'Underground - Social Sciences Table',
    BELEV: 'Underground - Songwriters Table',
    BEND3: 'Underground - Sports Table',
    BEND4: 'Underground - Sports Table',
    BPICK: 'Underground - Staff Picks Tables',
    BGARD: 'Underground - Strand Favorites Table',
    BGIF5: 'Underground - Strand Nonfiction Recommendations Table',
    BUFO: 'Underground - The Truth Is Out There Endcap',
    BGIF7: 'Underground - True Crime ... Or Is It?! Table',
    BCRIME: 'Underground - True Crime Table',
    BUNIV: 'Underground - University Review Shelves',
    BCOL5: 'Underground - Vinyl Display Column',
    BREC: 'Underground - Vinyl Records Table',
    BREC2: 'Underground - Vinyl Records Table',
    BWAR: 'Underground - War and Conflict Table',
    BGIF2: 'Underground - Well-Read Woman Display',
    BYOGASH: 'Underground - Yoga/Massage Featured Shelf',
  };

  if (locationsDic[str]) {
    return locationsDic[str];
  }

  return str;
};

export const locationsNonPhysical = {
  OFFSITE: 'Ask Associate',
  EVPRE: 'Event - Signed Copy, no admission',
  EVTICKET: 'Event - Admission',
  EVONLY: 'Event - Admission & Book',
  EVCARD: 'Event - Admission & Gift Card',
  EVBOOK: 'Event - Admission & Signed Copy',
  EVWSA: 'Event - Admission & Writers Studio Anthology',
  INTERNETDEPT: 'Internet Sales',
};
