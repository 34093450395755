import { useState, useEffect } from 'react';

const useOpenCloseComponent = () => {
  const [compSwitchState, setCompSwitch] = useState(false);

  const handleCompClose = () => {
    setCompSwitch(false);
  };

  const handleCompOpen = () => {
    setCompSwitch(true);
  };

  const handleCompToggle = () => {
    setCompSwitch(!compSwitchState);
  };

  useEffect(() => () => handleCompClose);

  return {
    compSwitchState,
    handleCompOpen,
    handleCompClose,
    handleCompToggle,
  };
};

export default useOpenCloseComponent;
