import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import './_about.scss';

const About = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  // This is for testing error delivery to Sentry
  // if (true) throw new Error('test error');

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="about-hero__div" />
      <div className="about-container__div">
        <PageHeader header="About Strand" />
        <main className="about-content__main">
          <div className="about-content__div">
            <section className="about-past__section">
              <div className="about-details__div">
                <h2 className="about-header__h2">Where it all began: Bound by a Family</h2>
                <p className="about-details__p">
                  The Strand was born in 1927 over on Fourth Avenue on what was
                  then called “Book Row.”
                  Book Row covered six city blocks and housed forty-eight bookstores.
                  Ben Bass, the father, was all of twenty-five years old when
                  he began his modest used bookstore. An entrepreneur at heart
                  and a reader by nature,
                  this erudite man began with $300 dollars of his own and $300 dollars
                  that he borrowed
                  from a friend. Ben sought to create a place where books would be loved,
                  and book lovers could congregate. He named his bookstore after the
                  London street where avant-garde writers like Thackeray,
                  Dickens and Mill once gathered
                  and interesting book publishers thrived. The Strand quickly became
                  a Greenwich Village
                  institution where writers went to converse, sell their books and
                  find a hidden treasure to buy. Today, the Strand is the sole survivor
                  of Book Row’s colorful past.
                  <br />
                  <br />
                  Ben’s son, Fred, was learning the family business by the age of 13.
                  He too a lover of books quickly took to the book trade.
                  After Fred completed a tour of duty in the Armed Forces, he came home to New York
                  where he worked side-by-side with his father. By 1957, Fred moved the store just
                  around the corner, to our current space at 12th Street and Broadway.
                  Fred came to spend most of his time at the buying desk, cultivating relationships
                  with regulars and scoping out the next great find…
                  <br />
                  <br />
                  The next in the Bass family to take to the business was Fred’s daughter, Nancy.
                  After college and a short stint in Louisiana,
                  she came back to her roots and joined
                  the Strand team at the age of 25. Today, she manages the store and is honored
                  to carry her family&#39;s 90 year legacy forward.
                  The jury’s still out on whether one,
                  two or all three of Nancy’s kids will be next in line to join the Strand team.
                  <br />
                  <br />
                </p>
              </div>
            </section>
            <section className="about-present__section">
              <div className="about-details__div">
                <h2 className="about-header__h2">Where we stand today: Guided by the Customer</h2>
                <p className="about-details__p">
                  Today, the Strand carries over 2.5 million used, new, and rare books,
                  covering topics as far-ranging as occult to philosophy to finance.
                  The store also offers an ever-changing array of literary gifts and goods
                  from Moleskines to coffee mugs, and everything in-between. Really, we mean
                  everything in-between, from a Shakespeare insult mug to
                  the wildly popular Strand onesie.
                  <br />
                  <br />
                  Our employees are not only knowledgeable about books but they’re also passionate
                  about them. Not only can they help you find a book,
                  they can also recommend something
                  you might love if you give them a quick description of your literary tastes.
                  With our famous dollar carts outside you can always find a gem but that
                  doesn’t mean there’s not a hidden treasure inside the store,
                  like a beautiful Rabelais for $15. Strand continues to house old treasures for
                  reasonable prices while offering one of the largest, most diverse selections of
                  books in the country.
                  <br />
                  <br />
                  Our in-store events feature some of today’s most interesting and provocative
                  personalities, from Lin Manuel Miranda to Patti Smith and dialogues between
                  such greats as Ocean Vuong and Jaqueline Woodson. If you are looking for a
                  relaxed, intimate evening where you’ll be both engaged and entertained,
                  we have the programming for you.
                </p>
              </div>
            </section>
            <section className="about-future__section">
              <div className="about-details__div">
                <h2 className="about-header__h2">Where we’re going: Driven by You</h2>
                <p className="about-details__p">
                  In all seriousness, at the Strand, you, our booklovers,
                  are our number one priority.
                  As we change and grow with the times, we will never
                  lose sight of our roots – we are
                  a community bookstore first and foremost. We are forever invested
                  in you and want to
                  make your shopping experience with us a memorable, enjoyable and easy one.
                  The Strand is a place where books are loved and book lovers of all kind
                  are always welcome.
                </p>
              </div>
            </section>
          </div>
        </main>
      </div>
      <div className="about-bottom-hero__div" />
      <ViewFooter />
    </React.Fragment>
  );
};

About.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default About;
