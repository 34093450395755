import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import WithEvent from '../withEvent/WithEvent';


const FilterFab = (props) => {
  const match769 = useMediaQuery('(min-width: 769px)');

  const useStyles = makeStyles({
    rootFab: {
      backgroundColor: '#af272f',
      color: '#ffffff',
      position: 'fixed',
      right: match769 ? 'auto' : '25px',
      left: match769 < 769 ? '25px' : 'auto',
      // right: '25px',
      top: '225px',
      zIndex: 10000,
      '&:hover': {
        backgroundColor: '#d4301a',
      },
    },
    rootIcon: {
      width: '32px',
      height: '32px',
    },
  });

  const {
    handleFilterOpen,
  } = props;

  const classes = useStyles();

  const handleOpenFilter = () => {
    WithEvent('SEARCH_RESULTS', 'Clicked Filter Options', 'USE_FILTER', 1);
    handleFilterOpen();
  };

  return (
    <div className="compfab-container__div">
      <Tooltip title="Filter Options">
        <Fab
          onClick={handleOpenFilter}
          disableRipple
          disableTouchRipple
          classes={{ root: classes.rootFab }}
          aria-label="filter options"
        >
          <FilterListRoundedIcon classes={{ root: classes.rootIcon }} />
        </Fab>
      </Tooltip>
    </div>
  );
};

FilterFab.propTypes = {
  handleFilterOpen: PropTypes.func.isRequired,
};

export default FilterFab;
