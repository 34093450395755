import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import './_pride2021.scss';

const Pride2021 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();

  const match767 = useMediaQuery('(min-width: 767px)');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="pride2021-container__div">
        <main className="pride2021-content__main">
          <div className="pride2021-content__div">
            { scroll > 1000 && <CompFab />}
            <div className="pride2021-hero__div">
              <img className="pride2021-hero__img" alt="Pride 2021" src={match767 ? urlGenerator('/S/banners/banners_pride_2021_desktop.jpg') : urlGenerator('/S/banners/banners_pride_2021_mobile.jpg')} />
              <div className="pride2021-hero-intro__div">
                <h4 className="pride2021-header__h4">
                  For this year&#39;s PRIDE collection, we&#39;ll be donating 30% of sales to&nbsp;
                  <a className="pride2021-link__a" href="https://hmi.org/" rel="noopener noreferrer" target="_blank">The Hetrick-Martin Institute</a>
                  &nbsp;the nation&#39;s largest and oldest LGBTQIA+ youth service organization.
                </h4>
              </div>
            </div>
            <div className="pride2021-lists-categories__div">
              <div className="pride2021-lists-category__div">
                <Link
                  onClick={() => WithEvent('PRIDE 2021', 'Clicked Pride Books', 'CLICK_CATEGORY', 1)}
                  to="/metalist/pride_2021"
                >
                  <img src={urlGenerator('/S/pages/pride_2021_books.jpg')} alt="Pride Books 2021" className="pride2021-lists-category-image__img" />
                </Link>
              </div>

              <div className="pride2021-lists-category__div">
                <Link
                  onClick={() => WithEvent('PRIDE 2021', 'Clicked Pride Merch', 'CLICK_CATEGORY', 1)}
                  to="/metalist/pride_merch_2021"
                >
                  <img src={urlGenerator('/S/pages/pride_2021_merch.jpg')} alt="Pride Books 2021" className="pride2021-lists-category-image__img" />
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Pride2021.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Pride2021;
