const convertSlashRegex = (str) => {
  // const newStr = [];

  if (str.length > 0) {
    return str.replace(/%2F/g, '/');
  }
  return '';
};

export default convertSlashRegex;
