import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import './_checkoutStepper.scss';

const getSteps = () => ['Shipping', 'Ship Method', 'Payment', 'Review Order'];

const useStyles = makeStyles({
  labelStepLabel: {
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '15px',
  },
  labelStepLabelMobile: {
    fontFamily: "'Trade Gothic LT Std', ",
    fontSize: '10px',
  },
  completedStepIcon: {
    color: '#af272f !important',
  },
  activeStepLabel: {
    color: '#af272f !important',
  },
  rootMobileStepper: {
    fontFamily: "'Trade Gothic LT Std', ",
    fontSize: '15px',
    textTransform: 'none',
    justifyContent: 'center',
  },
  dotActiveMobileStepper: {
    backgroundColor: '#af272f',
  },
  horizontalStepper: {
    justifyContent: 'center',
  },
});
const CheckoutStepper = (props) => {
  const {
    currentStep,
    // setStepper,
  } = props;

  const steps = getSteps();
  const classes = useStyles();
  const match767 = useMediaQuery('(min-width: 767px)');
  // const theme = useTheme();

  // const handleNext = () => {
  //   setStepper(currentStep + 1);
  // };

  // const handleBack = () => {
  //   setStepper(currentStep - 1);
  // };

  return (
    <div className="checkoutstepper-container__div">
      <Stepper activeStep={currentStep} classes={{ horizontal: classes.horizontalStepper }}>
        {
          steps.map((label) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel
                  {...labelProps}
                  StepIconProps={{
                    classes: {
                      active: classes.activeStepLabel,
                      completed: classes.completedStepIcon,
                    },
                  }}
                  classes={{
                    label: match767
                      ? classes.labelStepLabel
                      : classes.labelStepLabelMobile,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })
        }
      </Stepper>
    </div>
  );
};

CheckoutStepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  // setStepper: PropTypes.func.isRequired,
};

export default CheckoutStepper;
