import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import WithEvent from '../withEvent/WithEvent';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_indieDay2022.scss';

const IndieDay2022 = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const match767 = useMediaQuery('(min-width: 767px)');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="indie-container__div">
        { scroll > 1000 && <CompFab />}
        <main className="indie-content__main">
          <div className="indie-content__div">
            <section className="indie-hero__section">
              <div className="indie-hero__div">
                <img
                  src={match767 ? urlGenerator('/S/2021-promo/indie_2022_landing_page_desktop-2.png') : urlGenerator('/S/2021-promo/indie_2022_landing_page_mobile-2.png')}
                  className="indie-hero__img"
                  alt="Indie Bookstore Day 2022"
                />
              </div>
            </section>
            <section className="indie-intro__section">
              <div className="indie-intro__div">
                <p className="indie-intro__p">
                  Indie Bookstore Day is coming up on April 30th! Join us in-store and online for
                  free gifts, fun treats, amazing sales, bookish giveaways and so much more.
                </p>
              </div>
            </section>
            <div className="indie-border__div" />
            <section className="indie-categories__section">
              <div className="indie-categories__div">
                <LazyLoad height={300} offset={300}>
                  <div className="indie-category__div">
                    <Link
                      to="/metalist/new_arrivals"
                      className="indie-category-link__link"
                      onClick={() => WithEvent('INDIE_DAY_2022', 'Clicked New Arrivals tile', 'INDIE_DAY_LINK', 1)}
                      aria-label="New Arrivals"
                    >
                      <img
                        className="indie-category-image__img"
                        alt="New Arrivals"
                        src={urlGenerator('/S/2021-promo/indie_2022_new_arrivals.png')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="indie-category__div">
                    <Link
                      to="/metalist/bestsellers"
                      className="indie-category-link__link"
                      onClick={() => WithEvent('INDIE_DAY_2022', 'Clicked Bestsellers tile', 'INDIE_DAY_LINK', 1)}
                      aria-label="Bestsellers"
                    >
                      <img
                        className="indie-category-image__img"
                        alt="Bestsellers"
                        src={urlGenerator('/S/2021-promo/indie_2022_bestsellers.png')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="indie-category__div">
                    <Link
                      to="/apparel"
                      className="indie-category-link__link"
                      onClick={() => WithEvent('INDIE_DAY_2022', 'Clicked Apparel tile', 'INDIE_DAY_LINK', 1)}
                      aria-label="Apparel"
                    >
                      <img
                        className="indie-category-image__img"
                        alt="Apparel"
                        src={urlGenerator('/S/2021-promo/indie_2022_apparel.png')}
                      />
                    </Link>
                  </div>
                </LazyLoad>

                <LazyLoad height={300} offset={300}>
                  <div className="indie-category__div">
                    <Link
                      to="/productlocation/tpick2"
                      className="indie-category-link__link"
                      onClick={() => WithEvent('INDIE_DAY_2022', 'Clicked Staff Picks tile', 'INDIE_DAY_LINK', 1)}
                      aria-label="Staff Picks"
                    >
                      <img
                        className="indie-category-image__img"
                        alt="Staff Picks"
                        src={urlGenerator('/S/2021-promo/indie_2022_staff_picks.png')}
                      />
                    </Link>
                  </div>
                </LazyLoad>
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

IndieDay2022.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default IndieDay2022;
