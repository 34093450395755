/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import SentimentVerySatisfiedRoundedIcon from '@material-ui/icons/SentimentVerySatisfiedRounded';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import BBTFTop from './BBTFTop';
import WithEvent from '../withEvent/WithEvent';
import bbtfList from './bbtfList';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_booksByTheFoot.scss';

const useStyles = makeStyles({
  rootIcon: {
    fontSize: '1em',
  },
  rootIconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

const BooksByTheFoot = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const classes = useStyles();
  const match767 = useMediaQuery('(min-width: 767px)');
  const [howState, setHowState] = useState(0);
  const scroll = useScrollY();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const howSteps = [
    {
      id: 'how00',
      text: 'Take a look through our <a style="text-decoration: none; color: #af272f;" href="#bbtf-top">ready-made collections</a> to get an idea of the types of books we have on hand.',
      stepImg: <VisibilityRoundedIcon classes={{ root: classes.rootIcon }} />,
    },
    {
      id: 'how01',
      text: 'Place a custom order by calling Books By The Foot at <a style="text-decoration: none; color: #af272f;" href="tel: 212-660-6646">212-660-6646</a> or email <a style="text-decoration: none; color: #af272f;" href="mailto: bbtf@strandbooks.com">bbtf@strandbooks.com</a>. We customize your collection by subject, style, binding, size, and even by color.',
      stepImg: <PhoneRoundedIcon classes={{ root: classes.rootIcon }} />,
    },
    {
      id: 'how02',
      text: "Our expert staff scours Strand's 18 miles of books to populate your library. Because of our large inventory, we can fill most orders within 24 hours, and most extra-large orders within 72 hours.*",
      stepImg: <PeopleRoundedIcon classes={{ root: classes.rootIcon }} />,
    },
    {
      id: 'how03',
      text: 'We offer an installation service anywhere within 3 hours of our flagship store in Manhattan. Sit back and relax as your library takes shape.',
      stepImg: <SentimentVerySatisfiedRoundedIcon classes={{ root: classes.rootIcon }} />,
    },
  ];

  const handleHow = (index) => {
    WithEvent('BBTF', 'Clicked How To Icon', 'BBTF_HOW', 1);
    setHowState(index);
  };

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="bbtf-container__div">
        {/* <PageHeader header="Books by the Foot" /> */}
        { scroll > 1000 && <CompFab />}
        <div className="bbtf-hero__div">
          {/* <div className="bbtf-header-border__div">
            <div className="bbtf-header__div">
              <h1 className="bbtf-header__h1">Books by the Foot</h1>
            </div>
          </div> */}
        </div>
        <main className="bbtf-content__main">
          <div className="bbtf-content__div">
            <div className="bbtf-banner__div" />
            <section className="bbtf-about__section">
              <div className="bbtf-about__div">
                { match767 ? <div className="bbtf-about-circle__div" /> : <span />}
                <PageHeader header="About" />
                <div className="bbtf-details__div bbtf-details-about__div">
                  <p className="bbtf-details__p">
                    Books by the Foot creates one-of-a-kind, custom book collections and
                    rentals for interior decorators, set designers, commercial spaces, and
                    personal libraries. Let our staff put together
                    the collection you&#39;ve always wanted, or buy online.
                    <br />
                    <br />
                    For our custom collections, we&#39;ll work with you
                    to curate your ideal personal library. Whether you&#39;re
                    looking for antique leather bound books, art monographs,
                    or the books you read as a child, we&#39;ll find it in
                    Strand Bookstore&#39;s unparalleled inventory
                    (we weren&#39;t kidding about 18 miles of books!)
                    <br />
                    <br />
                    Want to find out more?
                  </p>
                  <a
                    className="bbtf-email-link__a"
                    href="mailto:bbtf@strandbooks.com"
                    onClick={() => WithEvent('BBTF', 'Clicked Email Button', 'BBTF_EMAIL', 1)}
                  >
                    <div className="bbtf-email-link__div">
                      Email us
                    </div>
                  </a>
                  <p className="bbtf-details__p">
                    or
                    <br />
                    give us a call at&nbsp;
                    <a
                      className="bbtf-call-link__a"
                      href="tel:+12126606646"
                      onClick={() => WithEvent('BBTF', 'Clicked Phone Button', 'BBTF_PHONE', 1)}
                    >
                      212-660-6646
                    </a>
                    <br />
                  </p>
                </div>
              </div>
            </section>
            {/* <div className="bbtf-divider__div" /> */}
            <section className="bbtf-how__section">
              <div className="bbtf-how__div">
                <PageHeader header="How It Works" />
                <div className="bbtf-steps-container__div">
                  {
                    match767
                      ? (
                        <div className="bbtf-steps__div bbtf-steps__div--desktop">
                          {
                            howSteps.map((step, i) => (
                              <div className="bbtf-step__div" key={step.id}>
                                <IconButton
                                  onClick={() => handleHow(i)}
                                  classes={{ root: classes.rootIconButton }}
                                  disableTouchRipple
                                  disableRipple
                                >
                                  <div className={i === howState ? 'bbtf-icon__div bbtf-icon__div--active' : 'bbtf-icon__div'}>
                                    <p className="bbtf-step-num__p">{i + 1}</p>
                                    {step.stepImg}
                                  </div>
                                </IconButton>
                              </div>
                            ))
                          }
                        </div>
                      )
                      : (
                        <div className="bbtf-steps__div">
                          <div className="bbtf-step__div">
                            <div className="bbtf-icon__div">
                              <VisibilityRoundedIcon classes={{ root: classes.rootIcon }} />
                            </div>
                            <div className="bbtf-details__div">
                              <p className="bbtf-details__p">
                                Take a look through our&nbsp;
                                <a className="bbtf-call-link__a" href="#bbtf-top">ready-made collections</a>
                                &nbsp;to get an idea
                                of the types of books we have on hand.
                              </p>
                            </div>
                          </div>
                          <div className="bbtf-arrow__div">
                            <ArrowDownwardRoundedIcon classes={{ root: classes.rootIcon }} />
                          </div>
                          <div className="bbtf-step__div">
                            <div className="bbtf-icon__div">
                              <PhoneRoundedIcon classes={{ root: classes.rootIcon }} />
                            </div>
                            <div className="bbtf-details__div bbtf-details-how-2__div">
                              <p className="bbtf-details__p">
                                Place a custom order by calling Books By The Foot at&nbsp;
                                <a className="bbtf-call-link__a" href="tel:+12126606646">212-660-6646</a>
                                &nbsp;or email&nbsp;
                                <a className="bbtf-call-link__a" href="mailto: bbtf@strandbooks.com">bbtf@strandbooks.com</a>
                                . We customize your collection by
                                subject, style, binding, size, and even by color.
                              </p>
                            </div>
                          </div>
                          <div className="bbtf-arrow__div">
                            <ArrowDownwardRoundedIcon classes={{ root: classes.rootIcon }} />
                          </div>
                          <div className="bbtf-step__div">
                            <div className="bbtf-icon__div">
                              <PeopleRoundedIcon classes={{ root: classes.rootIcon }} />
                            </div>
                            <div className="bbtf-details__div">
                              <p className="bbtf-details__p">
                                Our expert staff scours Strand&#39;s 18 miles of books to
                                populate your library. Because of our large inventory,
                                we can fill most orders within 24 hours,
                                and most extra-large orders within 72 hours.*
                              </p>
                            </div>
                          </div>
                          <div className="bbtf-arrow__div">
                            <ArrowDownwardRoundedIcon classes={{ root: classes.rootIcon }} />
                          </div>
                          <div className="bbtf-step__div">
                            <div className="bbtf-icon__div">
                              <SentimentVerySatisfiedRoundedIcon
                                classes={{ root: classes.rootIcon }}
                              />
                            </div>
                            <div className="bbtf-details__div">
                              <p className="bbtf-details__p">
                                We offer an installation service anywhere within
                                3 hours of our flagship store in Manhattan.
                                Sit back and relax as your library takes shape.
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                  }
                  {
                    match767
                      && (
                        <div className="bbtf-step-details__div">
                          <div className="bbtf-accent__div" />
                          <div className="bbtf-details__div" dangerouslySetInnerHTML={(() => ({ __html: howSteps[howState].text }))()} />
                          <div className="bbtf-accent__div" />
                        </div>
                      )
                  }
                </div>
              </div>

              <div className="bbtf-gallery__div">
                <Link
                  className="bbtf-gallery-link__link"
                  to="/books-by-the-foot/gallery"
                  onClick={() => WithEvent('BBTF', 'Clicked View Gallery', 'BBTF_VIEW_GALLERY', 1)}
                >
                  View our Gallery
                </Link>
              </div>
            </section>
            <div className="bbtf-divider__div" />
            <section className="bbtf-process__section">
              <div className="bbtf-process__div">
                <PageHeader header="Our Process" />
                <div className="bbtf-details__div bbtf-process-details__div">
                  <p className="bbtf-details__p bbtf-process-1__p">
                    Books by the Foot creates one-of-a-kind, custom book collections
                    and rentals for interior decorators, set designers, commercial spaces
                    and personal libraries. Let our staff put together
                    the collection you’ve always wanted.
                    <br />
                    <br />
                  </p>
                  <p className="bbtf-details__p bbtf-process-2__p">
                    For our custom collections, we’ll work with you to curate
                    your ideal personal library. Any subject, any style, even any Pantone color.
                    Whether you’re looking for antique leather bound books, art monographs,
                    or the books you read as a child, we’ll find it in Strand Bookstore’s
                    unparalleled inventory (we weren’t kidding about 18 miles of books!).
                    <br />
                    <br />
                  </p>
                  <div className="bbtf-image-divider__div bbtf-image-divider-1__div">
                    <img
                      src={urlGenerator('/S/pages/bbtf-red-bookshelf.jpg')}
                      alt="bbtf yellow bookshelf"
                      className="bbtf-image-divider__img"
                    />
                  </div>

                  <div className="bbtf-details__div bbtf-process-3__div">
                    <p className="bbtf-details__p bbtf-process-3__p">
                      Need your order in a hurry? Books by the Foot can curate
                      collections in as little as one day.
                      <br />
                      <br />
                      Our expert staff can install and style your collection
                      in the way that suits you best. We can also incorporate any other
                      objects you’d like on your shelves, including picture frames and art pieces.
                      Installations are $200.00 per hour, per installer. Our team will travel up to
                      3 hours for an installation
                      (please note that travel time is included in the installation fee).
                      <br />
                      <br />
                      Stop in for a consultation
                      <br />
                      or
                    </p>
                    <span className="bbtf-details-email__span">
                      <a
                        className="bbtf-email-link__a"
                        href="mailto:bbtf@strandbooks.com"
                        onClick={() => WithEvent('BBTF', 'Clicked Email Button', 'BBTF_EMAIL', 1)}
                      >
                        <div className="bbtf-email-link__div">
                          Email us
                        </div>
                      </a>
                    </span>
                    <br />
                    <br />
                    <p className="bbtf-details__p bbtf-process-3__p">
                      <span className="bbtf-details-notice__span">
                        *Please note that turnaround time has increased during the current climate,
                        and online orders can take approximately 3 days to prepare before
                        being ready to ship or be available for store pick up.
                        We will contact you once your order is complete
                        and ready to ship or pick up in store.*
                      </span>
                    </p>
                  </div>

                  <div className="bbtf-image-divider__div bbtf-image-divider-2__div">
                    <img
                      src={urlGenerator('/S/pages/bbtf-gallery-28.jpg')}
                      alt="bbtf yellow bookcase"
                      className="bbtf-image-divider__img "
                    />
                  </div>
                </div>
                <div className="bbtf-process-experts__div">
                  <PageHeader header="Our Expert Staff" />
                  <div className="bbtf-experts__div">
                    {
                      bbtfList.bbtfExperts.map((expert) => {
                        const {
                          id,
                          name,
                          role,
                          expertImage,
                        } = expert;

                        return (
                          <div className="bbtf-expert__div" key={id}>
                            <div className="bbtf-expert-image__div">
                              <img
                                className="bbtf-expert-image__img"
                                src={expertImage}
                                alt={name}
                              />
                            </div>
                            <div className="bbtf-details__div">
                              <h3 className="bbtf-header__h3">{name}</h3>
                              <p className="bbtf-details__p">
                                {role}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </section>
            <div className="bbtf-divider__div" />
            <section className="bbtf-top__section">
              <div className="bbtf-top__div" id="bbtf-top">
                <BBTFTop />
              </div>
            </section>
            <div className="bbtf-divider__div" />
            <section className="bbtf-seen__section">
              <div className="bbtf-seen__div">
                <PageHeader header="As Seen In" />
                <div className="bbtf-seen-list__div">
                  <div className="bbtf-seen-category__div bbtf-interior">
                    <h4 className="bbtf-header__h4">Interior Decorators</h4>
                    <div className="bbtf-header-accent__div bbtf-accent-category__div" />
                    <div className="bbtf-seen-items__div bbtf-seen-items-interior">
                      {
                        bbtfList.seen.filter(item => item.type === 'interior')
                          .sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                          })
                          .map(fItem => (
                            <div className="bbtf-seen-item__div" key={fItem.id}>
                              <p className="bbtf-seen-item__p">
                                {fItem.name}
                              </p>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div className="bbtf-seen-category__div bbtf-theater">
                    <h4 className="bbtf-header__h4">Theater</h4>
                    <div className="bbtf-header-accent__div bbtf-accent-category__div" />
                    <div className="bbtf-seen-items__div bbtf-seen-items-theater">
                      {
                        bbtfList.seen.filter(item => item.type === 'theater')
                          .sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                          })
                          .map(fItem => (
                            <div className="bbtf-seen-item__div" key={fItem.id}>
                              <p className="bbtf-seen-item__p">
                                {fItem.name}
                              </p>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div className="bbtf-seen-category__div bbtf-commercial">
                    <h4 className="bbtf-header__h4">Commercial</h4>
                    <div className="bbtf-header-accent__div bbtf-accent-category__div" />
                    <div className="bbtf-seen-items__div bbtf-seen-items-commercial">
                      {
                        bbtfList.seen.filter(item => item.type === 'commercial')
                          .sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                          })
                          .map(fItem => (
                            <div className="bbtf-seen-item__div" key={fItem.id}>
                              <p className="bbtf-seen-item__p">
                                {fItem.name}
                              </p>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div className="bbtf-seen-category__div bbtf-tv">
                    <h4 className="bbtf-header__h4">Television</h4>
                    <div className="bbtf-header-accent__div bbtf-accent-category__div" />
                    <div className="bbtf-seen-items__div bbtf-seen-items-tv">
                      {
                        bbtfList.seen.filter(item => item.type === 'tv')
                          .sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                          })
                          .map(fItem => (
                            <div className="bbtf-seen-item__div" key={fItem.id}>
                              <p className="bbtf-seen-item__p">
                                {fItem.name}
                              </p>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div className="bbtf-seen-category__div bbtf-movies">
                    <h4 className="bbtf-header__h4">Movies</h4>
                    <div className="bbtf-header-accent__div bbtf-accent-category__div" />
                    <div className="bbtf-seen-items__div bbtf-seen-items-movies">
                      {
                        bbtfList.seen.filter(item => item.type === 'movies')
                          .sort((a, b) => {
                            if (a.name > b.name) return 1;
                            if (a.name < b.name) return -1;
                            return 0;
                          })
                          .map(fItem => (
                            <div className="bbtf-seen-item__div" key={fItem.id}>
                              <p className="bbtf-seen-item__p">
                                {fItem.name}
                              </p>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <div className="bbtf-divider__div" />
            <section className="bbtf-gallery__section">
              <h2 className="bbtf-header__h2">Gallery</h2>
            </section> */}
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

BooksByTheFoot.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default BooksByTheFoot;
