import urlGenerator from '../../helpers/urlGenerator';

const giftGuide2021Data = [
  {
    id: 'books_for_adults',
    title: 'Books for Adults',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-adults.jpg'),
  },
  {
    id: 'food_and_drink',
    title: 'Food & Drink',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-food.jpg'),
  },
  {
    id: 'young_adult',
    title: 'For Young Adults',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-ya.jpg'),
  },
  {
    id: 'visual_arts',
    title: 'The Visual Arts',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-visual-arts.jpg'),
  },
  {
    id: 'gifts_for_kids',
    title: 'Gifts for Kids',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-kids.jpg'),
  },
  {
    id: 'stocking_stuffers',
    title: 'Stocking Stuffers',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-stocking-stuffers.jpg'),
  },
  {
    id: 'gifts_under_20',
    title: 'Gifts Under $20',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-under-20.jpg'),
  },
  {
    id: 'gifts_under_50',
    title: 'Gifts Under $50',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-under-50_2.jpg'),
  },
  {
    id: 'strand_university',
    title: 'Strand University',
    url: '/metalist/strand_university_apparel_2021',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-strand-university.jpg'),
  },
  {
    id: 'books_by_the_foot',
    title: 'Books by the Foot',
    url: '/books-by-the-foot/collections',
    imgUrl: urlGenerator('/S/pages/gift-guide-2021-books-by-the-foot.png'),
  },
];

export default giftGuide2021Data;
