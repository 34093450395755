import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import EventsList from './EventsList';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import listEvents from './listEvents';
import './_events.scss';

const Events = (props) => {
  const scroll = useScrollY();
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <div className="events-container__div">
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      { scroll > 1000 && <CompFab />}
      <PageHeader header="Events" />
      <section data-testid="events-list" className="events-list__section">
        <EventsList listEvents={listEvents} />
      </section>
      <ViewFooter />
    </div>
  );
};

Events.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Events;
