import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import jobsList from './jobsList';
import './_jobs.scss';

const Jobs = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="jobs-hero__div" />
      <div className="jobs-container__div">
        <main className="jobs-content__main">
          <PageHeader header="Work At Strand" />
          <div className="jobs-content__div">
            <section className="jobs-available__section">
              <div className="jobs-available__div">
                {
                  jobsList.filter(job => job.available).length === 0
                    ? (
                      <div className="jobs-notice__div">
                        <p className="jobs-notice__p">
                          We are not hiring at this time.
                        </p>
                      </div>
                    )
                    : (
                      <React.Fragment>
                        <h1 className="jobs-header__h1">{`Job Openings Available (${jobsList.filter(job => job.available).length})`}</h1>
                        <div className="jobs-list__div">
                          {
                            jobsList
                              .filter(job => job.available)
                              .map((job, i) => {
                                const {
                                  id,
                                  component,
                                } = job;

                                const Job = React.lazy(() => import(`./list/${component}`));

                                return (
                                  <div key={id} className={i % 2 ? 'jobs-item__div jobs-item__div--gray' : 'jobs-item__div'}>
                                    <React.Suspense fallback={null}>
                                      <Job />
                                    </React.Suspense>
                                  </div>
                                );
                              })
                          }
                        </div>
                      </React.Fragment>
                    )
                }
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Jobs.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Jobs;
