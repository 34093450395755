/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import NavSidebar from '../navSidebar/NavSidebar';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import './_faq.scss';

const Faq = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;
  const scroll = useScrollY();
  const match767 = useMediaQuery('(min-width: 767px)');

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="faq-container__div">
        <PageHeader header="Frequently Asked Questions" />
        { scroll > 1000 && <CompFab />}
        <div className="faq-content__div">
          <section className="faq-questions__section">
            <h2 className="faq-header__h2" id="store-ops">Updated Store Operations</h2>
            <h3 className="faq-header__h3">Union Square location</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                We are located on the corner of Broadway and 12th Street, just steps away from the Union Square Park and Subway Station.  Store hours are 10AM - 8PM seven days a week.
              </p>
              <br />
            </div>
            <h3 className="faq-header__h3">Is store pick-up still available?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                It is! In fact, we encourage it. Pick-up hours are from 10AM - 8PM seven days a week. Scroll down to the Store Pick-Up section for more detailed instructions.
              </p>
            </div>
            <h3 className="faq-header__h3">Other questions?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Reach out to&nbsp;
                <a className="faq-details-link__a" href="mailto:customerservice@strandbooks.com">customerservice@strandbooks.com</a>
              </p>
            </div>
            <h2 className="faq-header__h2" id="columbus">The Strand at Columbus Ave.</h2>
            <h3 className="faq-header__h3">Where is The Strand at Columbus Ave.?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Glad you asked! The Strand at Columbus Ave. is located at 450 Columbus Ave. between 81st &amp; 82nd on the Upper West Side. It’s right around the corner from the American Natural History Museum!
              </p>
            </div>
            <h3 className="faq-header__h3">Is it open?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Yes! The Strand at Columbus Ave. is open Monday-Thursday, 11AM to 7PM, Friday-Saturday, 10AM to 8PM, and Sunday, 9AM - 6AM.
              </p>
            </div>
            <h3 className="faq-header__h3">Is pick-up available?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Unfortunately, at the moment, The Strand at Columbus Ave. does not offer holds or curbside pick-up. The store is small, so we’ll be able to find the book you’re looking for quickly!.
              </p>
            </div>
            <h3 className="faq-header__h3">What kind of books are available?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                The Strand at Columbus Ave. has a little bit of everything you love about The Strand. New, used, and rare books of all genres, with a robust children’s section in the basement!
              </p>
            </div>

            <h3 className="faq-header__h3">Can I sell books here?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                We’re currently only buying books at our main 12th and Broadway store on Thursdays, Fridays and Saturdays between 10AM and 4PM.&nbsp;
              </p>
            </div>
            <h2 className="faq-header__h2" id="spu">Store Pick-up</h2>
            <h3 className="faq-header__h3">Where do we go for store pick-up?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                The pick up location is our flagship store at 828 Broadway. Pick-up is available 10AM - 8PM seven days a week in The Strand Underground (Basement Level).
              </p>
            </div>
            <h3 className="faq-header__h3">What is the process for store pick-up?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                When you order a book from&nbsp;
                <a className="faq-details-link__a" href="https://www.strandbooks.com">strandbooks.com</a>
                &nbsp;you can select the store pick-up option. Keep your eyes peeled for an email that says your order is ready for pick-up. Please allow 24-72 hours for us to process your order. Once you have received the email confirming your order is ready, you can come to our main store 828 Broadway at 12th St.
              </p>
            </div>
            <h2 className="faq-header__h2" id="gift-cards">Gift Cards</h2>
            <h3 className="faq-header__h3">How do I use my gift card online?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Thank you for buying a gift card! Enter the gift card number in the space provided. No spaces or punctuation are necessary. Please note that only one gift card can be used per order for payment.
                <br />
                <br />
                Also include a valid credit card; this is required for security purposes. If the purchase exceeds the gift card balance, the credit card will be charged the difference unless you indicate another form of payment.
              </p>
            </div>
            <h3 className="faq-header__h3">What if my gift card does not cover the cost of my order?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">You may split payment with a gift card and any other method of payment.</p>
            </div>
            <h3 className="faq-header__h3">How can I find out how much is left on my card?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                You can check the current value of your e-gift card&nbsp;
                <a className="faq-details-link__a" href="https://strandbooks.cashstar.com/self_service/v2/register/?locale=en-us">here</a>
                . For questions about the value on a physical gift card, please email&nbsp;
                <a className="faq-details-link__a" href="mailto:customerservice@strandbooks.com">customerservice@strandbooks.com</a>
                .
              </p>
            </div>
            <h2 className="faq-header__h2" id="selling-books">Selling Books</h2>
            <h3 className="faq-header__h3">How do I sell my books?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                The buying desk is open on Thursdays, Fridays and Saturdays between 10AM and 4PM. You can sell your books by bringing them to the seller&apos;s entrance on 12th street at our 828 Broadway location. For questions about selling books, visit our&nbsp;
                <Link className="faq-details-link__a" to="/more/sell-books">Sell Your Books</Link>
                &nbsp;page.
              </p>
            </div>
            <h3 className="faq-header__h3">I wrote a book. Will Strand sell it in the store?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                <strong>First of all, congrats!</strong>
                &nbsp;What a major accomplishment!  At this time, we are not taking submissions.  All of of buyers and booksellers have their attention focused on keeping our bookstore alive so we can serve you for many years to come.  Please check here for future updates.
              </p>
            </div>
            <h2 className="faq-header__h2" id="ordering">Ordering</h2>
            <h3 className="faq-header__h3">Was my order successfully placed?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                You will receive on-screen confirmation that your order has been placed successfully as well as an automated email confirmation including your order number and details. This is not a confirmation of availability; this confirms only that your order was successfully placed online. Please note that you are not charged at the time you receive your order confirmation. Orders are processed once the item(s) is in hand and ready to be processed and shipped. You will receive a shipment confirmation email when your order has been processed and shipped.
              </p>
            </div>
            <h3 className="faq-header__h3">Why are some of the features disabled on the strandbooks.com site?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                This is the first version of our new website, so there are many features we can’t offer just yet, but we’re working on updating everything as soon as possible.
              </p>
            </div>
            <h3 className="faq-header__h3">Can I get my order gift-wrapped?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Yes! At checkout, simply check the box marked “Do you want to gift-wrap your order?” Please note that gift wrap is unavailable for out-of-state warehouse items, Books By The Foot, The Book HookUp, and Pick of the Month.
              </p>
            </div>

            <h2 className="faq-header__h2" id="order-status">Order Status</h2>
            <h3 className="faq-header__h3">What does it mean that the order status is &#34;Closed?&#34;</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                If your order status is Closed, the order has been shipped and should arrive within 1 to 2 weeks.
              </p>
            </div>
            <h3 className="faq-header__h3">I received the book(s) I ordered, but haven&#39;t received any non-book items. What is the status of these items?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                The new book items were shipped directly from our distributor. Non-book items and used books are still in queue to be shipped from our store as quickly as possible.
              </p>
            </div>
            <h3 className="faq-header__h3">I waited so long for this order and now items are cancelled. Will I get a refund?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Cancelled items were not charged, so refunds are not pending for cancelled items.
              </p>
            </div>

            <h2 className="faq-header__h2" id="payment-methods">Payment Methods</h2>
            <h3 className="faq-header__h3">What credit cards does the Strand accept?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                The Strand Bookstore accepts Visa, Mastercard, American Express and Discover cards. The billing address that you provide must match the address that appears on your credit card statements. Your card will not be charged until your order is complete and ready to be shipped.
              </p>
            </div>
            <h3 className="faq-header__h3">What if my gift card does not cover the cost of my order?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                You may split payment with a gift card and any other method of payment.
              </p>
            </div>
            <h3 className="faq-header__h3">When will I be charged?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                We require 2-3 days processing time from the time you place your order before it can be invoiced and shipped. Your credit card will be charged after the order is complete, just prior to shipping. You will receive an automated shipping confirmation email once your order has shipped. Remember, delivery expectations take effect after your order has shipped, NOT from the time you place your order.
              </p>
            </div>
            <h2 className="faq-header__h2" id="shipping">Shipping</h2>
            <h3 className="faq-header__h3">What is a business day?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                A business day is Monday through Friday.
              </p>
            </div>
            <h3 className="faq-header__h3">When can I expect my online order to be delivered?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                United States Postal Service takes approximately 4-14 business days and UPS Ground takes approximately 2-7 business days for delivery from the time the order has shipped. UPS Second Day Air should arrive within two business days of the ship date. UPS Next Day Air will arrive the next business day.
              </p>
            </div>
            <h3 className="faq-header__h3">Large and Multi-Volume Sets</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                In some cases, due to the size of an order (books that are extremely heavy or  multi-volume sets, for example), customers may be contacted after an order has been placed regarding additional shipping costs; in which case regular shipping costs do not apply and shipping rates will be based on weight.
              </p>
            </div>
            <h3 className="faq-header__h3">Shipping to United States</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                <strong>Economy:</strong>
                &nbsp;
                $4.99 for 1st book + $0.99 each additional book; 4-14 business days after shipping
                <br />
                <strong>Priority:</strong>
                &nbsp;
                $6.99 for 1st book + $1.99 each additional book; 2-7 business days after shipping
                <br />
                <strong>2nd Day Air:</strong>
                &nbsp;
                $24.99 for 1st book + $6.99 each additional book; 2-3 business days after shipping
                <br />
                <strong>Next Day:</strong>
                &nbsp;
                $34.99 for 1st book + $6.99 each additional book; 1 business day after shipping
                <br />
                <br />
                Please note that all orders require 1-2 business days for processing in addition to the above estimated shipping times. Please allow additional shipping time for orders shipped to Hawaii, Alaska, Puerto Rico, and APOs.
                <br />
                <br />
                Only Economy (USPS) is available for orders being shipped to a P.O. Box. If you&#39;d like to use a different shipping method, please provide a street address.
                <br />
                <br />
                All items marked as &#34;Warehouse Only&#34; will be shipped separately from items currently available in our store; therefore, please be aware that delivery times may vary per shipment.
                <br />
                <br />
                **Please note that orders placed Friday-Sunday using an expedited shipping method will not ship until Monday. Additionally, expedited shipping services are not available for orders to Hawaii, Alaska, Puerto Rico, APOs, or P.O. box addresses.
              </p>
            </div>
            <h3 className="faq-header__h3">International Shipping</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                <strong>Economy:</strong>
                &nbsp;
                Economy $34.99 for 1st book + $9.99 each additional book; 7-30 business days after shipping
                <br />
                <strong>Priority:</strong>
                &nbsp;
                Priority $49.99 for 1st book + $19.99 each additional book; 4-10 business days after shipping
                <br />
                <br />
                Your packages may be subject to the customs fees and import duties of the country to which you have your order shipped. These charges are always the recipient&#39;s responsibility.
                <br />
                <br />
                Books that require special handling because they are extremely heavy (for example, multi-volume sets) cannot be shipped outside the U.S. In some cases, due to the size of an order, customers may be contacted after an order has been placed regarding additional shipping costs; in which case regular shipping costs do not apply and shipping rates will be based on weight.
              </p>
            </div>
            <h2 className="faq-header__h2" id="shopping-cart">Shopping &amp; Cart</h2>
            <h3 className="faq-header__h3">Does the subtotal displayed in the Shopping Cart include shipping and taxes?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                No, shipping and taxes will be calculated and included at checkout
              </p>
            </div>
            <h3 className="faq-header__h3">How can I add more items to my cart?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                You may continue shopping by entering a search item at the top left of the page or by clicking the &#34;Strand Logo&#34; in the header of our site which will take you back to the strandbooks.com homepage.
              </p>
            </div>
            <h3 className="faq-header__h3">How can I change the quantity of an item in my shopping cart?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Adjust your cursor to the box marked &#34;qty&#34; to the right of the item&#39;s image and change the quantity as desired.
              </p>
            </div>
            <h3 className="faq-header__h3">How can I view an item&#39;s description after I have placed it in my cart?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                To view the details of an item from the cart page,
                click on the item&#39;s title or image.
              </p>
            </div>
            <h3 className="faq-header__h3">What are the different book conditions?</h3>
            <div className="faq-details__div">
              <ul className="faq-list__ul">
                <li className="faq-list-item__li">
                  <span className="faq-list-item__span--strong">New</span>
                    &nbsp;- Brand new, never been sold before
                </li>
                <li className="faq-list-item__li">
                  <span className="faq-list-item__span--strong">Used - Good</span>
                    &nbsp;- Some wear and tear; hardcovers may not have a dust jacket.
                </li>
                <li className="faq-list-item__li">
                  <span className="faq-list-item__span--strong">Used - Very Good</span>
                    &nbsp;- Almost as good as new; may have a mark (a dot or a dash) on the bottom.
                </li>
                <li className="faq-list-item__li">
                  <span className="faq-list-item__span--strong">Rare - Good</span>
                    &nbsp;- Some wear and tear. A rare and collectable item.
                </li>
                <li className="faq-list-item__li">
                  <span className="faq-list-item__span--strong">Rare - Very Good</span>
                    &nbsp;- Almost as good as new. A rare and collectable item.
                </li>
              </ul>
            </div>
            <h3 className="faq-header__h3">How do I remove items from my cart?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                To remove an item from your cart, click on the &#34;Remove from cart&#34; button to the right of the item you wish to remove. The page will refresh automatically and your cart will be updated.
              </p>
            </div>
            <h3 className="faq-header__h3">Is the book reserved for me once I place it in my cart?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Placing an item in your cart does not automatically reserve the item for you. Another customer may purchase the book before it is secured by our department. We do our best to process online orders as quickly as possible but, remember, all items are subject to prior sale. This also holds true for order confirmations.
              </p>
            </div>
            <h2 className="faq-header__h2" id="my-account">My Account</h2>
            <h3 className="faq-header__h3">What&#39;s my username and/or password?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Your username is the email address that you used when you first created your account.
                Remember that passwords are case-sensitive.
                Bookstore is not the same as BOOKSTORE or BoOKstore.
                <br />
                <br />
                If you cannot remember your password, simply click on the &#34;Forgot password?&#34; link on the Log In/ Sign Up  screen and enter the email address that you used to create the account. Your password will be emailed to that address.
              </p>
            </div>
            <h3 className="faq-header__h3">Why does my order still say processing?</h3>
            <div className="faq-details__div">
              <p className="faq-details__p">
                Strandbooks.com requires 2-3 days to completely process your order. If your order still says &#34;processing&#34; more than 3 days after it was placed, please contact us and we will work quickly to resolve any issues that may be delaying your order. If you’re having any issues reach out to&nbsp;
                <a className="faq-details-link__a" href="mailto:customerservice@strandbooks.com">customerservice@strandbooks.com</a>
                .
              </p>
            </div>
          </section>
          <section className="faq-sidebar__section">
            { match767 && <NavSidebar content={[{ name: 'Updated Store Operations', id: 'store-ops' }, { name: 'The Strand at Columbus Ave.', id: 'columbus' }, { name: 'Store Pick-up', id: 'spu' }, { name: 'Gift Cards', id: 'gift-cards' }, { name: 'Selling Books', id: 'selling-books' }, { name: 'Ordering', id: 'ordering' }, { name: 'Order Status', id: 'order-status' }, { name: 'Payment Methods', id: 'payment-methods' }, { name: 'Shipping', id: 'shipping' }, { name: 'Shopping & Cart', id: 'shopping-cart' }, { name: 'My Account', id: 'my-account' }]} /> }
          </section>
        </div>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Faq.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Faq;
