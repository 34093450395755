import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './_checkoutBack.scss';

const useStyles = makeStyles({
  rootIconButton: {
    color: '#af272f',
    fontFamily: ['Trade Gothic Lt Std', 'Proxima Nova, sans-serif', 'Helvetica', 'Roboto', 'Arial'],
    fontSize: '15px',
    '&:hover': {
      background: 'transparent',
      color: '#d4301a',
    },
  },
});

const CheckoutBack = (props) => {
  const {
    handleBack,
  } = props;

  const classes = useStyles();

  return (
    <div className="checkoutback-container__div">
      <div className="checkoutback-button__div">
        <IconButton
          disableRipple
          disableTouchRipple
          classes={{ root: classes.rootIconButton }}
          onClick={handleBack}
        >
          <ArrowBackIcon />
          <span className="checkoutback-btn-text__span">Go Back</span>
        </IconButton>
      </div>
    </div>
  );
};

CheckoutBack.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default CheckoutBack;
