import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import WarningIcon from '@material-ui/icons/Warning';
import useUserLoggedIn from '../../hooks/useUserLoggedIn';
import useOpenCloseComponent from '../../hooks/useOpenCloseComponent';
import convertURL from '../../helpers/convertURL';
import stringLimiter from '../../helpers/stringLimiter';
import useToggleLoginSignup from '../../hooks/useToggleLoginSignup';
import PageHeader from '../pageHeader/PageHeader';
import LoginSignup from '../loginSignup/LoginSignup';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import Loading from '../loading/Loading';
import WithEvent, { WithFBEvent } from '../withEvent/WithEvent';
import onlyOnePromo from '../../helpers/promoLists';
import InPageLoading from '../loading/InPageLoading';
import use4Cite from '../../hooks/use4Cite';
import 'array-flat-polyfill';
import './_cart.scss';

export const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      customerId
      cartItem{
        cartId
        cartItemId
        cartPriceStv
        discountNote
        catalogId
        description
        gtin
        isTaxable
        productDetail{
          gtin13
          id
          imageUrl
          isbn10
          title
          originator
          bookBinding
          apparel{
            apparelId
            size
            sizeDesc
            inventory{
              catalogId
              condition
              sku
              onHand
              salePriceStv
            }
          }
          inventory{
            sku
            onHand
            catalogId
            signed
          }
          inventoryOverview{
            sectionList
          }
        }
        qty
        retailStv
        salePriceStv
        sku
      }
      cartType
      count
      decoration
      image
      name
      props
      public
      valueStv
    }
  }
`;

export const ADD_ITEM_CART = gql`
  mutation addItemCart($cartItemInput: CartItemInput!){
    cartItemAdd(cartItemInput: $cartItemInput){
      cartId
      cartItemId
      cartPriceStv
      discountNote
      catalogId
      description
      gtin
      isTaxable
      qty
      retailStv
      salePriceStv
      sku
      productDetail{
        gtin13
        id
        imageUrl
        isbn10
        title
        originator
        bookBinding
        apparel{
          apparelId
          size
          sizeDesc
          inventory{
            catalogId
            condition
            sku
            onHand
            salePriceStv
          }
        }
        inventory{
          sku
          onHand
          catalogId
          signed
        }
        inventoryOverview{
          sectionList
        }
      }
    }
  }
`;

export const CHECKOUT_BEGIN = gql`
mutation beginCheckout($carts: [CartInput!]!){
  checkoutBegin(carts: $carts){
    alts{
      addr{
        addr1
        addr2
        addressId
        attention
        city
        countryCode
        postalCode
        state
      }
      email
      payment{
        balanceStv
        display
        paymentId
        paymentType
      }
      shipMethod{
        addPriceStv
        basePriceStv
        longName
        shipMethodId
        shortName
        transitInfo
      }
    }
    billAddr{
      attention
      addr1
      addr2
      addressId
      city
      countryCode
      postalCode
      state
    }
    cart{
      cartId
      cartType
      cartItem{
        cartId
        qty
        salePriceStv
        productDetail{
          title
          id
          gtin13
          originator
          imageUrl
          inventory{
            section
          }
          apparel{
            apparelId
            size
            sizeDesc
            inventory{
              catalogId
              condition
              sku
              onHand
              salePriceStv
            }
          }
        }
      }
    }
    checkoutId
    complete
    contact{
      billEmail
      phone
      phoneSms
      shipEmail
    }
    deliveryInstructions
    payments{
      balanceStv
      display
      paymentId
      paymentType
    }
    promoCode
    shipAddr{
      addr1
      addr2
      addressId
      attention
      city
      countryCode
      postalCode
      state
    }
    shipMethod{
      addPriceStv
      basePriceStv
      shipMethodId
      transitInfo
    }
    specialInstructions
    tally{
      itemCount
      productStv
    }
    taxRate
  }
}
`;

export const GET_GIFT_WRAP_PRODUCT = gql`
  query{
    productListDetail(ean: "9789111542807"){
      gtin13
      inventory{
        sku
        onHand
      }
    }
  }
`;

const useStyles = makeStyles({
  rootButtonBase: {
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '1em',
    '&:hover': {
      color: '#d4301a',
    },
  },

  paperDialog: {
    backgroundColor: '#fbfaf6',
  },

  rootCheckbox: {
    width: 16,
    height: 16,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  checkedCheckbox: {
    color: '#af272f !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    checkedRadio: {
      color: '#af272f !important',
    },
    colorSecondaryRadio: {
      color: '#af272f',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '&$checked': {
        color: '#af272f',
      },
    },
  },
});

const Cart = (props) => {
  const {
    history,
    location,
    cartList: upperCartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const {
    compSwitchState: dialogState,
    handleCompClose: dialogClose,
    handleCompOpen: dialogOpen,
  } = useOpenCloseComponent();

  const [giftState, setGift] = useState(false);

  // disable gift wrap option

  const [disableGiftWrapState] = useState(false);

  const toggleLoginSignup = useToggleLoginSignup();
  const classes = useStyles();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const {
    error: errorLoggedIn,
    loading: loadingLoggedIn,
    data: dataLoggedIn,
    refetch: refetchLoggedIn,
  } = useUserLoggedIn();

  // APOLLO HOOKS

  const [
    cartItemAdd,
    {
      error: errorAddItemCart,
      loading: loadingAddItemCart,
    }] = useMutation(ADD_ITEM_CART);

  const {
    error: errorGiftWrapProduct,
    loading: loadingGiftWrapProduct,
    data: dataGiftWrapProduct,
  } = useQuery(GET_GIFT_WRAP_PRODUCT);

  const {
    loading,
    error,
    data,
  } = useQuery(GET_CART_DETAILS, { fetchPolicy: 'network-only' });

  const [checkoutBegin, {
    data: beginData,
    error: beginError,
    loading: beginLoad,
  }] = useMutation(CHECKOUT_BEGIN);

  useEffect(() => {
    if (upperCartList
      && upperCartList.length > 0
      && upperCartList[0]
      && upperCartList[0].cartItem
      && upperCartList[0].cartItem.length > 0
      && upperCartList[0].cartItem.some(item => item.sku === '2697076')) {
      setGift(true);
    } else setGift(false);
  }, [upperCartList, dataLoggedIn]);

  if (error || errorAddItemCart || errorLoggedIn || errorGiftWrapProduct) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            error,
            beginError,
            errorAddItemCart,
            errorLoggedIn,
          },
        }}
      />
    );
  }

  if (loading || beginLoad || loadingLoggedIn || loadingGiftWrapProduct) {
    return (
      <div className="cart-container__div">
        <ViewNavbar
          cartList={upperCartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <Loading />
        <ViewFooter />
      </div>
    );
  }

  // console.log(data);

  const handlecheckoutBegin = () => {
    checkoutBegin(
      {
        variables: {
          carts: [{ cartId: data.cartList.filter(cart => cart.cartType === 'PRIMARY')[0].cartId, cartVersion: 1 }],
        },
      },
    )
      .catch((err) => {
        if (err.message === 'GraphQL error: must be logged in') {
          dialogOpen();
        } else console.error(err);
      });
  };

  const handleCheckout = (e) => {
    e.preventDefault();
    WithEvent('CART', 'Started Checkout', 'CART_CHECKOUT', 1);
    WithFBEvent('InitiateCheckout', {});
    Sentry.addBreadcrumb({
      category: 'BEGIN_CHECKOUT',
      message: 'Clicked Checkout Button in Cart',
      level: Sentry.Severity.Info,
    });


    handlecheckoutBegin();
  };

  const handleDialogError = () => {
    dialogClose();
    refetchLoggedIn();
  };

  if (beginData) {
    return (
      <Redirect
        to={{
          pathname: '/checkout',
          state: { beginData },
        }}
      />
    );
  }

  const handleGiftWrap = () => {
    if (giftState
      && data
      && data.cartList
      && data.cartList.length > 0
      && data.cartList[0]
      && data.cartList[0].cartItem
      && data.cartList[0].cartItem.length > 0
      && data.cartList[0].cartItem.some(item => item.sku === '2697076')) {
      cartItemAdd(
        {
          variables: {
            cartItemInput: {
              catalogId: '1',
              qty: 0,
              sku: '2697076',
            },
          },
          update: (cache) => {
            const { cartList } = cache.readQuery({ query: GET_CART_DETAILS });

            const cartItemUpdated = cartList[0]
              .cartItem.filter(cartProd => cartProd.sku !== '2697076');
            cartList[0].cartItem = cartItemUpdated;
            cache.writeQuery({
              query: GET_CART_DETAILS,
              data: {
                cartList,
              },
            });
          },
          refetchQueries: ['{ query: GET_CART_DETAILS }'],
        },
      )
        .then(() => setGift(!giftState))
        .catch(err => console.error(err));
    } else {
      cartItemAdd(
        {
          variables: {
            cartItemInput: {
              catalogId: '1',
              qty: 1,
              sku: '2697076',
            },
          },
          update: (cache, { data: { cartItemAdd: cartItemData } }) => {
            const { cartList } = cache.readQuery({ query: GET_CART_DETAILS });

            cartList[0].cartItem.push(cartItemData);
            cache.writeQuery({
              query: GET_CART_DETAILS,
              data: { cartList },
            });
          },
          refetchQueries: ['{ query: GET_CART_DETAILS }'],
        },
      )
        .then(() => setGift(!giftState))
        .catch(err => console.error(err));
    }
  };

  const giftWrapUnavailable = (catalog, productDetail) => catalog === '2' || productDetail?.inventoryOverview?.sectionList?.some(sec => sec === 'BKS/FT' || sec === 'SUBSCRIPTIONS');

  const isGiftWrap = productDetail => productDetail?.inventory?.some(item => item.sku === '2697076');

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={upperCartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      {
        beginError
          && beginError.graphQLErrors
          && beginError.graphQLErrors[0].message === 'must be logged in'
          && (
            <Dialog
              classes={{ paper: classes.paperDialog }}
              open={dialogState}
              onClose={handleDialogError}
            >
              <p className="cart-expired__p">Your session has expired. Please log in again.</p>
            </Dialog>
          )
      }
      <div className="cart-container__div">
        { loadingAddItemCart && <InPageLoading />}
        <main className="cart-content__main">
          {
            data
            && data.cartList
            && data.cartList.length > 0
            && data.cartList[0].cartItem
            && data.cartList[0].cartItem.length > 0
            && (
            <section className="cart-checkout__section">
              <div className="cart-checkout__div">
                <p className="cart-subtotal__p">{`Subtotal: $${(data.cartList[0].cartItem.reduce((acc, cv) => acc + (cv.cartPriceStv * cv.qty), 0) * 0.01).toFixed(2)} (${data.cartList[0].cartItem.reduce((acc, cv) => acc + cv.qty, 0)} ${data.cartList[0].cartItem.length === 1 ? 'item' : 'items'})`}</p>
                <div className="cart-checkout-btn__div">
                  <ButtonBase
                    className="cart-checkout-btn__buttonbase"
                    disableRipple
                    disableTouchRipple
                    onClick={
                      (dataLoggedIn && dataLoggedIn.customerLoggedIn)
                        ? handleCheckout
                        : () => toggleLoginSignup[1]()
                      }
                  >
                    <p className={dataLoggedIn && dataLoggedIn.customerLoggedIn ? 'cart-checkout-btn__p' : 'cart-checkout-btn-login__p'}>{ dataLoggedIn && dataLoggedIn.customerLoggedIn ? 'Checkout' : 'Log in to checkout'}</p>
                  </ButtonBase>
                </div>
              </div>
              <div className="cart-divider__div" />
            </section>
            )
            }
          <section className="cart-content__section">
            <div className="cart-content__div">
              <PageHeader header="Cart Items" />
            </div>
            {
              data
                && data.cartList
                && data.cartList.length > 0
                && data.cartList[0]
                && data.cartList[0].cartItem
                && data.cartList[0].cartItem.length > 0
                ? data.cartList[0].cartItem.map((cartItem) => {
                  const {
                    cartId,
                    cartItemId,
                    salePriceStv,
                    cartPriceStv,
                    discountNote,
                    productDetail,
                    qty,
                    sku,
                    catalogId,
                    gtin,
                  } = cartItem;

                  const createQty = (num) => {
                    const divs = [];
                    if (num > 20) {
                      for (let i = 0; i < 20; i += 1) {
                        divs.push(<option key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</option>);
                      }
                    } else {
                      for (let i = 0; i < num; i += 1) {
                        divs.push(<option key={`qty${i}`} className="singleproductpage-item-opt__option">{i + 1}</option>);
                      }
                    }

                    return divs;
                  };

                  return (
                    <div className="cart-item__div" key={cartItemId}>
                      <div className="cart-details__div">
                        <Link
                          to={{ pathname: `/product/${gtin}`, search: `?title=${productDetail && productDetail.title && convertURL(productDetail.title)}`, state: { title: productDetail && productDetail.title && convertURL(productDetail.title), gtin } }}
                          className="cart-item-link__link"
                          onClick={() => WithEvent('CART', 'Clicked image link', 'CART_IMAGE_LINK', 1)}
                        >
                          <div className="cart-details-image__div">
                            {
                              giftState
                                && !giftWrapUnavailable(catalogId, productDetail)
                                && !isGiftWrap(productDetail)
                                && (
                                  <React.Fragment>
                                    <div className="cart-gift-icon__div">
                                      <p className="cart-gift-icon__p">Gift wrap</p>
                                    </div>
                                  </React.Fragment>
                                )
                            }
                            <img className="cart-details-image__img" src={productDetail && productDetail.imageUrl} alt={productDetail && productDetail.title} />
                            {productDetail && productDetail.inventory && productDetail.inventory.length > 0 && productDetail.inventory.filter(inv => inv.sku === sku)[0] && productDetail.inventory.filter(inv => inv.sku === sku)[0].signed && <p className="cart-signed__p">*Signed Copy*</p>}
                          </div>
                          {
                              giftState
                              && giftWrapUnavailable(catalogId, productDetail)
                              && !isGiftWrap(productDetail)
                              && (
                                <div className="cart-unavailable__div">
                                  <WarningIcon />
                                  <p className="cart-unavailable__p">GIFT WRAP UNAVAILABLE</p>
                                </div>
                              )
                            }
                        </Link>
                        <div className="cart-title__div">
                          {
                            productDetail
                              && productDetail.title
                              && (
                                <React.Fragment>
                                  <Link
                                    to={{ pathname: `/product/${gtin}`, search: `?title=${productDetail && productDetail.title && convertURL(productDetail.title)}`, state: { title: productDetail && productDetail.title && convertURL(productDetail.title), gtin } }}
                                    className="cart-item-link__link"
                                    onClick={() => WithEvent('CART', 'Clicked title link', 'CART_TITLE_LINK', 1)}
                                  >
                                    <p className="cart-title__p">{match767 && productDetail && productDetail.title ? stringLimiter(productDetail.title, 100) : stringLimiter(productDetail.title)}</p>
                                  </Link>
                                  <p className="cart-accent__div" />
                                </React.Fragment>
                              )
                          }
                        </div>
                        {
                          productDetail
                            && productDetail.originator
                            && (
                              <p className="cart-author__p">{match767 && productDetail && productDetail.originator ? stringLimiter(productDetail.originator, 100) : stringLimiter(productDetail.originator, 18)}</p>
                            )
                        }
                        {
                          productDetail
                            && productDetail.bookBinding
                            && !productDetail.apparel
                            && (
                              <p className="cart-binding__p">{productDetail && productDetail.bookBinding === 'PaperBack' ? 'Paperback' : productDetail.bookBinding}</p>
                            )
                        }
                        {
                          productDetail
                            && productDetail?.apparel
                            && productDetail?.apparel.length > 0
                            && productDetail?.apparel
                              .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [
                                ...acc,
                                { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc },
                              ], [])
                              .filter(cv => cv.sku === sku)
                            && productDetail?.apparel
                              .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [
                                ...acc,
                                { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc },
                              ], [])
                              .filter(cv => cv.sku === sku).length > 0
                            && productDetail.apparel
                              .reduce((acc, cv) => [...acc, [cv?.inventory?.[0]]], [])
                              .flat()
                              .reduce((acc, cv, ci) => [
                                ...acc,
                                { ...cv, sizeDesc: productDetail.apparel?.[ci]?.sizeDesc },
                              ], [])
                              .filter(cv => cv.sku === sku)
                              .map(cv => <p key={cv} className="cart-binding__p">{`Size: ${cv.sizeDesc}`}</p>)
                        }
                        {
                          sku !== '2697076'
                            && <p className="cart-price__p">{`$${(salePriceStv * 0.01).toFixed(2)}`}</p>
                        }
                        {discountNote && <p className="cart-discount-note__p">{discountNote}</p>}
                        <div className="cart-qty__div">
                          {
                            sku !== '2697076'
                              && productDetail?.gtin13
                              && onlyOnePromo.indexOf(productDetail?.gtin13) < 0
                              && (
                              <React.Fragment>
                                <span className="cart-qty__p">Qty:</span>
                                <select
                                  aria-label="quantity"
                                  className="cart-item-select__select"
                                  value={qty}
                                  onChange={(e) => {
                                    cartItemAdd(
                                      {
                                        variables: {
                                          cartItemInput: {
                                            catalogId,
                                            qty: parseInt(e.target.value, 10),
                                            sku,
                                          },
                                        },
                                        update: (cache, {
                                          data: { cartItemAdd: cartItemData },
                                        }) => {
                                          const { cartList } = cache
                                            .readQuery({ query: GET_CART_DETAILS });
                                          const cartItemUpdated = cartList
                                            .filter(sCart => sCart.cartId === cartId)[0]
                                            .cartItem.map((cartProd) => {
                                              if (cartProd.cartItemId === cartItemId) {
                                                return cartItemData;
                                              }

                                              return cartProd;
                                            });

                                          cartList[0].cartItem = cartItemUpdated;
                                          cache.writeQuery({
                                            query: GET_CART_DETAILS,
                                            data: { cartList },
                                          });
                                        },
                                        refetchQueries: ['{ query: GET_CART_DETAILS }'],
                                      },
                                    );
                                  }}
                                >
                                  {
                                  productDetail
                                    && productDetail.inventory
                                    && !productDetail.apparel
                                    && productDetail.inventory.length > 0
                                    && createQty(productDetail.inventory
                                      .filter(prod => prod.sku === sku)
                                      .reduce((acc, cv) => acc + cv.onHand, 0))
                                  }
                                  {
                                  productDetail
                                    && productDetail.apparel
                                    && productDetail.apparel.length > 0
                                    && productDetail.apparel
                                      .reduce((acc, cv) => [...acc, cv.inventory], [])
                                      .flat()
                                      .filter(cv => cv.sku === sku).length > 0
                                    && createQty(productDetail.apparel
                                      .reduce((acc, cv) => [...acc, cv.inventory], [])
                                      .flat()
                                      .filter(cv => cv.sku === sku)
                                      .pop().onHand)
                                  }
                                </select>
                              </React.Fragment>
                              )
                          }
                        </div>
                        <p className="cart-item-total__p">{`$${(qty * (cartPriceStv * 0.01)).toFixed(2)}`}</p>
                        <div className="cart-item-options__div">
                          {
                            sku !== '2697076'
                              && (
                                <ButtonBase
                                  onClick={() => {
                                    WithEvent('CART', 'Click remove from cart', 'REMOVE_CART_ITEM', 1);
                                    cartItemAdd(
                                      {
                                        variables: {
                                          cartItemInput: {
                                            catalogId,
                                            qty: 0,
                                            sku,
                                          },
                                        },
                                        update: (cache) => {
                                          const { cartList } = cache
                                            .readQuery({ query: GET_CART_DETAILS });

                                          const cartItemUpdated = cartList
                                            .filter(sCart => sCart.cartId === cartId)[0].cartItem
                                            .filter(cartProd => (
                                              (cartProd.cartItemId !== cartItemId)
                                                && (cartProd.sku !== sku)));
                                          cartList[0].cartItem = cartItemUpdated;
                                          cache.writeQuery({
                                            query: GET_CART_DETAILS,
                                            data: {
                                              cartList,
                                            },
                                          });
                                        },
                                        refetchQueries: ['{ query: GET_CART_DETAILS }'],
                                      },
                                    );
                                  }}
                                  className="cart-remove-btn__buttonbase"
                                  classes={{ root: classes.rootButtonBase }}
                                >
                                  Remove from cart
                                </ButtonBase>
                              )
                          }
                        </div>
                      </div>
                    </div>
                  );
                })
                : (
                  <div className="cart-no-items__div">
                    <h2 className="cart-header__h2 cart-header__h2--gray">There are no items in your cart</h2>
                  </div>
                )
            }
            {
              data?.cartList?.[0]?.cartItem
              && data?.cartList?.[0]?.cartItem.length > 0
              && data?.cartList?.[0]?.cartItem.some(item => (item?.catalogId === '1' && (item?.productDetail?.inventoryOverview?.sectionList && item?.productDetail?.inventoryOverview?.sectionList.length > 0 && item?.productDetail?.inventoryOverview?.sectionList.some(sec => sec && sec !== 'BKS/FT' && sec !== 'SUBSCRIPTIONS'))))
              && !disableGiftWrapState
              && (
                <div className="cart-gift__div">
                  <Checkbox
                    disableRipple
                    inputProps={{ 'aria-label': 'gift-wrap' }}
                    checked={giftState}
                    onChange={handleGiftWrap}
                    value="gift wrap"
                    classes={{ root: classes.rootCheckbox, checked: classes.checkedCheckbox }}
                    disabled={
                      dataGiftWrapProduct?.productListDetail
                      && dataGiftWrapProduct?.productListDetail.length > 0
                      && dataGiftWrapProduct?.productListDetail?.[0]?.inventory
                      && dataGiftWrapProduct?.productListDetail?.[0]?.inventory.length === 0
                    }
                  />
                  <div className="cart-gift-details__div">
                    <p className="cart-gift-details__p">Do you want to gift wrap your order? (+$6.00)</p>
                    <p className="cart-gift-details__p cart-gift-details__p--red cart-gift-details__p--size13">
                      {
                        dataGiftWrapProduct?.productListDetail
                        && dataGiftWrapProduct?.productListDetail.length > 0
                        && dataGiftWrapProduct?.productListDetail?.[0]?.inventory
                        && dataGiftWrapProduct?.productListDetail?.[0]?.inventory.length !== 0
                          ? '(Note: Gift wrap is unavailable for out-of-state warehouse items, Books By The Foot, The Book HookUp, and Pick of the Month)'
                          : '*Gift wrapping is unavailable at this time. We apologize for the inconvenience.*'
                      }
                    </p>
                  </div>
                </div>
              )
            }

            {
              data
                && data.cartList
                && data.cartList.length > 0
                && data.cartList[0].cartItem
                && data.cartList[0].cartItem.length > 0
                ? (
                  <div className="cart-checkout__div">
                    <p className="cart-subtotal__p">{`Subtotal: $${(data.cartList[0].cartItem.reduce((acc, cv) => acc + (cv.cartPriceStv * cv.qty), 0) * 0.01).toFixed(2)} (${data.cartList[0].cartItem.reduce((acc, cv) => acc + cv.qty, 0)} ${data.cartList[0].cartItem.length === 1 ? 'item' : 'items'})`}</p>
                    <div className="cart-checkout-btn__div">
                      <ButtonBase
                        className="cart-checkout-btn__buttonbase"
                        disableRipple
                        disableTouchRipple
                        onClick={
                          (dataLoggedIn && dataLoggedIn.customerLoggedIn)
                            ? handleCheckout
                            : () => toggleLoginSignup[1]()
                          }
                      >

                        <p className={dataLoggedIn && dataLoggedIn.customerLoggedIn ? 'cart-checkout-btn__p' : 'cart-checkout-btn-login__p'}>{ dataLoggedIn && dataLoggedIn.customerLoggedIn ? 'Checkout' : 'Log in to checkout'}</p>
                      </ButtonBase>
                    </div>
                  </div>
                )
                : <span />
            }
          </section>
          <LoginSignup
            open={toggleLoginSignup[0]}
            handleClose={toggleLoginSignup[2]}
            history={history}
            location={location}
            cartListData={data}
            cartCheckout
          />
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Cart.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Cart;
