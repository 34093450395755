import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import CompFab from '../compFab/CompFab';
import useScrollY from '../../hooks/useScrollY';
import use4Cite from '../../hooks/use4Cite';
import submissionsList from './submissionsList';
import './_submissionsTOU.scss';

const SubmissionsTOU = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
    match,
  } = props;

  const touItem = submissionsList.filter(sub => sub.id === match?.params?.touNo).pop();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const match767 = useMediaQuery('(min-width: 767px)');
  const scroll = useScrollY();

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      { scroll > 1000 && <CompFab />}
      <div className="submissionstou-container__div">
        <main className="submissionstou-content__main">
          <div className="submissionstou-content__div">
            <section className="submissionstou-header__section">
              <Link to={`/submissions/contests/${touItem.id}`}>
                <div className="submissionstou-header__div">
                  <img
                    className="submissionstou-header__img"
                    src={match767 ? touItem.img : touItem.imgMobile}
                    alt={touItem.title}
                  />
                </div>
              </Link>
            </section>

            <section className="submissionstou-details__section">
              <div className="submissionstou-details__div" dangerouslySetInnerHTML={(() => ({ __html: touItem.termsOfUse }))()} />
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

SubmissionsTOU.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SubmissionsTOU;
