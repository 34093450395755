import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import use4Cite from '../../hooks/use4Cite';
import './_privacy.scss';

const Privacy = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="privacy-container__div">
        <PageHeader header="Privacy Policy" />
        <section className="privacy-conditions__section">
          <div className="privacy-content__div">
            <h1 className="privacy-header__h1">Conditions of Use</h1>
            <p className="privacy-text__p">
              Welcome to Strand Book Store.
              <br />
              <br />
              Strand Book Store provides its services to you
              subject to the following conditions.
              If you visit or shop at Strand Book Store, you accept these conditions.
              Please read them carefully. In addition, when you use any current or future
              Strand Book Store service or visit or purchase from any business affiliated with
              Strand Book Store, whether or not included in the Strand Book Store Web site,
              you also will be subject to the guidelines and conditions
              applicable to such service or business.
              <br />
              <br />
            </p>
            <div className="privacy-details__div">
              <h2 className="privacy-subheader__h2">Privacy</h2>
              <p className="privacy-text__p">
                Please review our Privacy Notice, which also governs your visit to
                Strand Book Store, to understand our practices.
              </p>
              <h2 className="privacy-subheader__h2">Copyright</h2>
              <p className="privacy-text__p">
                All content included on this site, such as text, graphics, logos,
                button icons, images, audio clips, digital downloads, data compilations,
                and software, is the property of Strand Book Store or its content suppliers
                and protected by United States and international copyright laws.
                The compilation of all content on this site is the exclusive property of
                Strand Book Store and protected by U.S. and international copyright laws.
                All software used on this site is the property of Strand Book Store or its
                software suppliers and protected by United States and international copyright laws.
                <br />
                Some data licensed from Muze Inc.; Copyright 1995-2006 Muze Inc.
                For personal non-commercial use only. All rights reserved.
                <br />
                <br />
              </p>
              <h2 className="privacy-subheader__h2">Trademarks</h2>
              <p className="privacy-text__p">
                STRAND BOOK STORE; 8 MILES OF BOOKS are registered trademarks of
                Strand Book Store, Inc. or its subsidiaries, in the United States and
                other countries. Strand Book Store graphics, logos, page headers, button icons,
                scripts, and service names are trademarks or trade dress of
                Strand Book Store, Inc. or its subsidiaries. Strand Book Store&#39;s trademarks
                and trade dress may not be used in connection with any product or service that
                is not Strand Book Store&#39;s, in any manner that is likely to cause confusion
                among customers, or in any manner that disparages or discredits
                Strand Book Store. All other trademarks not owned by Strand Book Store or its
                subsidiaries that appear on this site are the property of their
                respective owners, who may or may not be affiliated with, connected to,
                or sponsored by Strand Book Store or its subsidiaries.
              </p>
              <h2 className="privacy-subheader__h2">License and Site Access</h2>
              <p className="privacy-text__p">
                Strand Book Store grants you a limited license to access and make personal use
                of this site and not to download (other than page caching) or modify it,
                or any portion of it, except with express written consent of Strand Book Store.
                This license does not include any resale or commercial use of this site or its
                contents; any collection and use of any product listings, descriptions, or prices;
                any derivative use of this site or its contents; any downloading or copying
                of account information for the benefit of another merchant; or any use of data
                mining, robots, or similar data gathering and extraction tools. This site or
                any portion of this site may not be reproduced, duplicated, copied, sold,
                resold, visited, or otherwise exploited for any commercial purpose without
                express written consent of Strand Book Store. You may not frame or utilize
                raming techniques to enclose any trademark, logo, or other proprietary
                information (including images, text, page layout, or form) of Strand Book Store
                and our affiliates without express written consent. You may not use any
                meta tags or any other &#34;hidden text&#34; utilizing Strand Book Store&#39;s name
                or trademarks without the express written consent of Strand Book Store.
                Any unauthorized use terminates the permission or license granted by
                Strand Book Store. You are granted a limited, revocable,
                and nonexclusive right to create a hyperlink to the home page of
                Strand Book Store so long as the link does not portray Strand Book Store,
                its affiliates, or their products or services in a false, misleading,
                derogatory, or otherwise offensive matter. You may not use any
                Strand Book Store logo or other proprietary graphic or trademark as
                part of the link without express written permission.
                <br />
                <br />
              </p>
              <h2 className="privacy-subheader__h2">Your Account</h2>
              <p className="privacy-text__p">
                If you use this site, you are responsible for maintaining the confidentiality
                of your account and password and for restricting access to your computer,
                and you agree to accept responsibility for all activities that occur under
                your account or password. Strand Book Store does sell products for children,
                but it sells them to adults, who can purchase with a credit card.
                If you are under 18, you may use Strand Book Store only with
                involvement of a parent or guardian. Strand Book Store and its affiliates
                reserve the right to refuse service, terminate accounts, remove or
                edit content, or cancel orders in their sole discretion.
              </p>
              <h2 className="privacy-subheader__h2">Copyright Complaints</h2>
              <p className="privacy-text__p">
                Strand Book Store and its affiliates respect the intellectual
                property of others. If you believe that your work has been
                copied in a way that constitutes copyright infringement,
                please contact us.
              </p>
              <h2 className="privacy-subheader__h2">Risk of Loss</h2>
              <p className="privacy-text__p">
                All items purchased from Strand Book Store are made pursuant
                to a shipment contract. This means that the risk of loss and title
                for such items pass to you upon our delivery to the carrier.
              </p>
              <h2 className="privacy-subheader__h2">Product Descriptions</h2>
              <p className="privacy-text__p">
                Strand Book Store and its affiliates attempt to be as
                accurate as possible. However, Strand Book Store does not
                warrant that product descriptions or other content of this site
                is accurate, complete, reliable, current, or error-free. If a
                product offered by Strand Book Store itself is not as described,
                your sole remedy is to return it in unused condition.
                <br />
                <br />
                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY THIS SITE IS PROVIDED
                BY STRAND BOOK STORE ON AN &#34;AS IS&#34; AND &#34;AS AVAILABLE&#34; BASIS.
                STRAND BOOK STORE MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION,
                CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY AGREE
                THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT
                PERMISSIBLE BY APPLICABLE LAW, STRAND BOOK STORE DISCLAIMS ALL WARRANTIES,
                EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. STRAND BOOK STORE
                DOES NOT WARRANT THAT THIS SITE, ITS SERVERS, OR E-MAIL SENT FROM
                STRAND BOOK STORE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                STRAND BOOK STORE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
                FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
                INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES. CERTAIN STATE LAWS DO NOT
                ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
                EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
              </p>
              <h2 className="privacy-subheader__h2">Applicable Law</h2>
              <p className="privacy-text__p">
                By visiting Strand Book Store, you agree that the laws of the state of New York,
                without regard to principles of conflict of laws, will govern these Conditions
                of Use and any dispute of any sort that might arise between you
                and Strand Book Store or its affiliates.
              </p>
              <h2 className="privacy-subheader__h2">Site Policies, Modification, and Severability</h2>
              <p className="privacy-text__p">
                Please review our other policies, such as our Pricing and
                Availability policy, posted on this site. These policies
                also govern your visit to Strand Book Store. We reserve the right
                to make changes to our site, policies, and these Conditions of Use
                at any time. If any of these conditions shall be deemed invalid, void,
                or for any reason unenforceable, that condition shall be deemed severable
                and shall not affect the validity and enforceability of any remaining condition.
              </p>
              <h2 className="privacy-subheader__h2">Our Address</h2>
              <p className="privacy-text__p">
                Strand Book Store, Inc.
                <br />
                828 Broadway
                <br />
                New York, NY 10003
                <br />
                https://www.strandbooks.com
              </p>
            </div>
          </div>
        </section>

        <section className="privacy-cookies__section">
          <div className="privacy-content__div">
            <h1 className="privacy-header__h1">Cookies</h1>
            <p className="privacy-text__p">
              <strong>Effective Date: February 2015</strong>
              <br />
              <br />
              This web site requires cookie support. If the user agent (browser) does not support
              cookies or has cookies disabled (completely or selectively), the site
              will not function as intended. There are both long-term cookies and
              short-term cookies used, neither of which contain financial or
              password information. Since all financial and login information is
              transacted over a secure connection (TLS) and cookies do not contain
              financial nor login information, the cookies we store do not comprise
              a significant security risk.
            </p>
          </div>
        </section>

        <section className="privacy-privacy__section">
          <div className="privacy-content__div">
            <h1 className="privacy-header__h1">Privacy Policy</h1>
            <p className="privacy-text__p">
              <strong>Effective Date: February 2015</strong>
              <br />
              <br />
              Strand Book Store Inc.,
              (collectively, &#34;Strand Book Store,&#34; &#34;we,&#34;
              &#34;us,&#34; or &#34;our&#34;), is committed to protecting the privacy
              and information of all individuals who visit and use our website. We collect
              information about you through our website in order to manage your shopping
              experience with us and to communicate with you about our products, services,
              contests and promotions. This policy explains what personal information we collect
              about you and why; how we will use, share, and protect this information
              once it is collected; and the choices you can make about
              our collection and use of your personal information.
              We encourage you to review this policy to make sure you understand
              how any personal information you provide to us online will be treated.
              We may change this policy from time to time. When we do, we will let you know
              by posting the changed policy on this page. In some cases, for example,
              if we make any significant changes, we may contact you directly about changes
              to this policy, such as by sending you an e-mail.
            </p>
            <div className="privacy-details__div">
              <h2 className="privacy-subheader__h2">What Information Does Strand Book Store Collect?</h2>
              <p className="privacy-text__p">
                We collect two general types of information from Web site users: (1) non-personal
                information concerning the use of our Web site and (2) personal information
                that identifies or can be used to identify or contact an individual.
                Sometimes, we may link Non-Personal Information with Personal Information, in which
                case it becomes Personal Information. Non-Personal Information: As is the case with
                many Web sites, our servers automatically collect your browser type and IP address
                when you visit our Web site. We may also capture certain clickstream data,
                which includes, for example, the referring page that linked you to the
                Strand Book Store Web site, the pages you visit on our Web site,
                and any search terms you have entered on our Web site or a referral site.
                In addition, we and our third party partners acting on our behalf may use cookies
                and/or pixel tags to keep track of your visits, preferences and profile information.
                Cookies are small data files stored on your computer’s hard drive
                at the request of a
                Web site, and do not contain personal information about you. Cookies may be used to
                enhance your experience on our Web sites
                (including by allowing you to remain logged in); to store some of your preferences;
                or to collect general usage and aggregated statistical information such as traffic
                patterns that does not by itself identify you personally. Any or all of the
                above-described activities with respect to Non-Personal Information may be performed
                on our behalf by our service providers. Personal Information: Most of the Personal
                Information that we collect from you on our Web site is what you provide to us.
                For example, we may collect Personal Information from you, such as your name, email
                address, postal address, phone number, credit card number, gender, birthday, etc.,
                when you place an order online, save your information with us online, sign up for
                our newsletters, contact us with a question or concern, or participate in a contest,
                or promotion.
              </p>
              <h2 className="privacy-subheader__h2">How Does Strand Book Store Use My Information?</h2>
              <p className="privacy-text__p">
                We and our trusted partners acting on our behalf may use the information
                (including Personal and Non-Personal Information) we collect from and about you to:
              </p>
              <br />
              <ul className="privacy-list__ul">
                <li className="privacy-list-item__li">
                  Process and fulfill your orders, including sending you confirmation
                  and shipment status emails using the email address you
                  provide when placing your order
                </li>
                <li className="privacy-list-item__li">
                  Communicate with you and to send you information by email, telephone,
                  text message, or other means about our products, services, contests,
                  and promotions, unless you have directed us not to contact you
                  with promotional communications
                </li>
                <li className="privacy-list-item__li">
                  Administer and fulfill our contests and other promotions
                </li>
                <li className="privacy-list-item__li">
                  Help us address problems with and develop our site design, products and services
                </li>
                <li className="privacy-list-item__li">
                  Conduct research and analysis, including the use of Google Analytics
                </li>
                <li className="privacy-list-item__li">
                  Protect the security or integrity of our website and our business
                </li>
                <li className="privacy-list-item__li">
                  Respond to customer service inquiries, questions, and concerns
                </li>
              </ul>
              <br />
              <p className="privacy-text__p">
                You always have the choice not to receive marketing information.
                To learn how opt out,
                please see the Opt Out section below.
              </p>
              <h2 className="privacy-subheader__h2">Opt Out</h2>
              <p className="privacy-text__p">
                You have the right to tell us not to send certain communications to you.
                For example,
                you may tell us not to send you our promotional
                emails by clicking on the appropriate
                link included with any such email you receive from us or by contacting our
                customer service representatives or web marketing team using the information
                provided in the Contact Us section. Please note, however, that it may take 2-5
                business days to process your email request, during which period you may continue
                to receive promotional communications from Strand Book Store. Moreover, even if
                you opt out of receiving promotional correspondence from us,
                we may still contact you
                in connection with your online orders, in-store transactions, and/or to follow up
                prior communication with us. If you change your mind, you can start receiving
                promotional communications from us again by logging into your online account and
                changing your preferences, by signing up when you visit our website,
                or by contacting customer service.
              </p>
              <h2 className="privacy-subheader__h2">Sharing With Third Parties</h2>
              <p className="privacy-text__p">
                We do not sell, trade, or otherwise transfer to outside parties your
                personally identifiable information. This does not include trusted third
                parties who assist us in operating our website and conducting our business,
                including sending communication to our customers, and processing credit card
                transactions. These companies are authorized to use your information
                only as necessary to provide these services.
                In addition, we may share your information:
              </p>
              <br />
              <ul className="privacy-list__ul">
                <li className="privacy-list-item__li">
                  As required by law and when we believe in good faith that disclosure is
                  necessary to protect our rights or those of third parties, protect
                  your safety or the safety of others, investigate fraud, or comply
                  with a court order or other legal process
                </li>
                <li className="privacy-list-item__li">
                  In connection with co-branded or co-sponsored products, services,
                  contests, or promotions when we team up with others to offer
                  or provide these things to our customers
                </li>
                <li className="privacy-list-item__li">
                  Administer and fulfill our contests and other promotions
                </li>
                <li className="privacy-list-item__li">
                  Help us address problems with and develop our site design, products and services
                </li>
                <li className="privacy-list-item__li">
                  Conduct research and analysis, including the use of Google Analytics
                </li>
                <li className="privacy-list-item__li">
                  Protect the security or integrity of our website and our business
                </li>
                <li className="privacy-list-item__li">
                  Respond to customer service inquiries, questions, and concerns
                </li>
              </ul>
              <br />
              <p className="privacy-text__p">
                In addition, we may share aggregate Web site usage information that does not
                identify individual users with third parties for any reason, including,
                for example, marketing or analytical purposes such as Google Analytics.
              </p>
              <h2 className="privacy-subheader__h2">Security</h2>
              <p className="privacy-text__p">
                All security on our Website is treated seriously. We constantly review our systems
                and data to ensure the best possible service and security to our customers.
                Where applicable, we undertake security steps, including use of SSL technology,
                on our back-end systems that store customer account information and to protect
                data transmissions. However, this is not a guarantee that such data transmissions
                cannot be accessed, altered or deleted due to firewall or other security software
                failures. In the unlikely event that we believe that the security of your
                Personal Information in our possession or control may have been compromised,
                we may seek to notify you of that development.If a notification is appropriate,
                we would endeavor to do so as promptly as possible under the circumstances,
                and, to the extent we have your email address, we may notify you by email.
                <strong>
                  Unless you provide us with another method to notify you in this situation,
                  you consent to our use of email as a means of such notification.
                </strong>
              </p>
              <h2 className="privacy-subheader__h2">Third Party &#34;Linked-To&#34; Web Sites</h2>
              <p className="privacy-text__p">
                Our Web site may contain links, banners, widgets or advertisements that lead
                to other Web sites. We are not responsible for these other sites, and so
                their posted privacy policies will govern the collection and use of your
                information on them. We encourage you to read the privacy statements of the
                Web sites you visit after leaving the Strand Book Store Web site to learn about
                how your information is treated.
              </p>
              <h2 className="privacy-subheader__h2">Consent to Processing</h2>
              <p className="privacy-text__p">
                By registering on our Web site, you consent to the transmission of your information
                to, and its collection and processing in, the United States, and any other
                jurisdiction, in accordance with this policy.
              </p>
              <h2 className="privacy-subheader__h2">Your Access Rights</h2>
              <p className="privacy-text__p">
                You may be able to access, view, delete and change your preferences and other
                information by visiting the “my account” area on our Web site. You may also
                inform Strand Book Store of changes or deletions by sending an email via our&nbsp;
                <NavLink to="/contact" className="privacy-link-contact__navlink">
                  Contact Us
                </NavLink>
                &nbsp;section. We will endeavor to respond to your request to access,
                update or delete your information as soon as possible. But before we are
                able to provide you with information, correct any inaccuracies, or
                delete any information, we may ask you to verify your identity or
                provide other details to help us respond to your request.
              </p>
              <h2 className="privacy-subheader__h2">Contact Us</h2>
              <p className="privacy-text__p">
                Strand Book Store welcomes your questions and comments about privacy.
                Please feel free to contact us by sending an e-mail via our&nbsp;
                <NavLink to="/contact" className="privacy-link-contact__navlink">
                  Contact Us
                </NavLink>
                  &nbsp;section.
              </p>
            </div>
          </div>
        </section>

        <section className="privacy-security__section">
          <div className="privacy-content__div">
            <h1 className="privacy-header__h1">Security</h1>
            <div className="privacy-details__div">
              <h2 className="privacy-subheader__h2">Shopping At Strandbooks.com is 100% Safe</h2>
              <p className="privacy-text__p">
                We guarantee that every transaction at strandbooks.com will be
                100% safe and secure. Our secure server software (SSL) is the industry
                standard and among the best available today for secure commerce transactions.
                It encrypts all of your personal information, including credit card number,
                name, and address, so that it cannot be read as the information travels over
                the Internet. You will pay nothing if unauthorized charges are made to your
                credit card as a result of shopping at strandbooks.com. See our guarantee below.
              </p>
              <h2 className="privacy-subheader__h2">Paying By Credit Card</h2>
              <p className="privacy-text__p">
                We accept Visa, Visa Check Card, MasterCard/EuroCard, MasterMoney Card, Discover,
                and American Express.
                <br />
                <br />
                When selecting your payment method on the order form:
                If you&#39;re paying with a Visa Check Card, please select Visa from the
                dropdown menu. Similarly, if you&#39;re paying with a EuroCard or
                MasterMoney Card, select MasterCard.
              </p>
              <h2 className="privacy-subheader__h2">Paying By Purchase Order</h2>
              <p className="privacy-text__p">
                Strandbooks.com accepts purchase orders from established institutions.
                For all those interested in creating a purchase order account please contact
                accountservices@strandbooks.com. Once the account is created please indicate
                &#34;Paying by Purchase Order&#34; in the dropdown box on the payment
                options screen.
                You will pay nothing if unauthorized charges are made to your credit card as a
                result of shopping at strandbooks.com. See our guarantee below.
              </p>
              <h2 className="privacy-subheader__h2">Strandbooks.com Shopping Guarantee</h2>
              <p className="privacy-text__p">
                Under the Fair Credit Billing Act, your bank cannot hold you liable for more than
                $50.00 of fraudulent charges. If your bank does hold you liable for any of
                this $50.00, strandbooks.com will cover the entire liability for you, up to
                the full $50.00. strandbooks.com will cover this liability only if the unauthorized
                use of your credit card resulted through no fault of your own from purchases made at
                strandbooks.com while using the secure server. In the event of
                unauthorized use of your credit card, you must notify your credit card provider
                in accordance with its reporting rules and procedures.
              </p>
            </div>
          </div>
        </section>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Privacy.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Privacy;
