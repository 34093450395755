import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Home from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ButtonBase from '@material-ui/core/ButtonBase';
import WithEvent from '../withEvent/WithEvent';
import { mobileNavMenuData } from '../navMenu/navMenuData';
import './_drawerNav.scss';

const styles = {
  drawerPaperAnchorLeft: {
    // backgroundColor: '#fbf5df',
    backgroundColor: '#fbfaf6',
    width: '250px',
  },

  drawerRoot: {
    zIndex: '13000 !important',
  },

  iconButtonRoot: {
    width: '48px',
    display: 'block',
    margin: '10px 15px 0 auto',
    color: '#af272f',

    '&:hover': {
      background: 'transparent',
    },
  },

  closeRoot: {
    fontSize: '24px',
  },
};

const DrawerNav = (props) => {
  const { classes, openDrawer, drawerToggle } = props;
  const [collapseState, setCollapse] = useState(mobileNavMenuData.reduce((acc, curr) => {
    if (curr.subMenu?.length) {
      acc[curr.id] = false;
    }

    return acc;
  }, {}));

  const handleToggle = (menu, flag) => {
    setCollapse(prev => ({ ...prev, ...{ [menu]: (typeof flag === 'undefined' ? !prev[menu] : flag) } }));
  };

  return (
    <div className="drawernav-container__div">
      <Drawer
        className="drawernav-drawer__drawer"
        anchor="left"
        open={openDrawer}
        onClose={drawerToggle}
        classes={{ paperAnchorLeft: classes.drawerPaperAnchorLeft, root: classes.drawerRoot }}
      >
        <IconButton
          onClick={drawerToggle}
          className="drawernav-close-button__iconbutton"
          classes={{ root: classes.iconButtonRoot }}
          aria-label="close"
        >
          <Close classes={{ root: classes.closeRoot }} />
        </IconButton>

        <div className="drawernav-link__div">
          <NavLink
            to="/"
            className="drawernav-navlink__navlink"
            // activeClassName="drawernav-navlink__navlink--active"
            activeStyle={{ color: '#af272f' }}
            aria-label="home"
            onClick={() => WithEvent('DRAWER', 'Clicked home icon', 'DRAWER_LINK', 1)}
          >
            <Home
              className="drawernav-home-icon__home"
            />
          </NavLink>
          <div className="drawernav-border__div" />
        </div>

        {mobileNavMenuData.map((menuProps) => {
          const {
            id,
            title,
            url,
            subMenu,
          } = menuProps;

          const active = collapseState[id];
          const pClass = `drawernav-header__p${active ? ' drawernav-header__p--active' : ''}`;
          let content;

          if (subMenu?.length) {
            content = (
              <>
                <div className="drawernav-header__div">
                  <ButtonBase disableRipple disableTouchRipple onClick={() => handleToggle(id)}>
                    <p className={pClass}>{title}</p>
                  </ButtonBase>
                </div>

                <Collapse in={active}>
                  <div className="drawernav-submenu__div">
                    {subMenu.map((subProps) => {
                      const {
                        title: subTitle,
                        url: subUrl,
                      } = subProps;

                      const external = !/^[/#]/.test(subUrl);

                      let LinkComponent;
                      let linkProps;

                      if (external) {
                        LinkComponent = 'a';
                        linkProps = {
                          href: subUrl,
                          target: '_blank',
                          rel: 'noopener noreferrer',
                        };
                      } else {
                        LinkComponent = NavLink;
                        linkProps = {
                          to: subUrl,
                          activeClassName: 'drawernav-navlink__navlink--active',
                        };
                      }

                      return (
                        <LinkComponent
                          key={subTitle}
                          className="drawernav-navlink__navlink"
                          onClick={() => WithEvent('DRAWER', `Clicked ${subTitle} link`, 'DRAWER_LINK', 1)}
                          {...linkProps}
                        >
                          {subTitle}
                        </LinkComponent>
                      );
                    })}
                  </div>
                </Collapse>
              </>
            );
          } else {
            content = (
              <NavLink
                to={url}
                className="drawernav-navlink__navlink"
                activeStyle={{ color: '#af272f' }}
                onClick={() => WithEvent('DRAWER', `Clicked ${title} link`, 'DRAWER_LINK', 1)}
              >
                {title}
              </NavLink>
            );
          }

          return (
            <div key={id} className="drawernav-link__div" onFocus={() => handleToggle(id, true)}>
              {content}

              <div className="drawernav-border__div" />
            </div>
          );
        })}

      </Drawer>
    </div>

  );
};

DrawerNav.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  drawerToggle: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withStyles(styles)(DrawerNav);
