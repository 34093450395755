import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import orderStatusDictionary from '../../helpers/orderStatusDictionary';
import './_orderDetailDialog.scss';

const useStyles = makeStyles({
  rootDialog: {
    backgroundColor: '#fbfaf6',
  },
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      color: '#d4301a',
      backgroundColor: 'transparent',
    },
  },
});

const CatalogInfo = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Order Detail Status"
      classes={{ paper: classes.rootDialog }}
    >
      <div className="orderdetaildialog-container__div">
        <div className="orderdetaildialog-content__div">
          <div className="orderdetaildialog-close__div">
            <IconButton
              onClick={onClose}
              classes={{ root: classes.rootIconButton }}
              aria-label="close"
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <section className="orderdetaildialog-status__section">
            <h2 className="orderdetaildialog-header__h2">Order Status</h2>
            <div className="orderdetaildialog-accent__div" />
            <div className="orderdetaildialog-status__div">
              {
                Object.keys(orderStatusDictionary).map(status => (
                  <p key={status} className="orderdetaildialog-details__p">
                    <span className="orderdetaildialog-details__span--strong">{`${status} - `}</span>
                    {orderStatusDictionary[status]}
                  </p>
                ))
              }
            </div>
          </section>
        </div>
      </div>
    </Dialog>
  );
};

CatalogInfo.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CatalogInfo;
