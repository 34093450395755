import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../pageHeader/PageHeader';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import urlGenerator from '../../helpers/urlGenerator';
import use4Cite from '../../hooks/use4Cite';
import './_libraryServices.scss';

const LibraryServices = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <div className="libraryservices-container__div">
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="libraryservices-hero__div">
        <img className="libraryservices-hero__img" alt="Library Services" src={urlGenerator('/S/pages/libraryservices-_hero_library-services.jpg')} />
      </div>
      <PageHeader header="Library Services" />
      <section className="libraryservices-quote__section">
        <div className="libraryservices-quote__div">
          <p className="libraryservices-quote__p">
            &#34;A great library contains the diary of the human race.&#34;
          </p>
          <p className="libraryservices-quote-author__p">
            - George Mercer Dawson (1849-1901)
          </p>
        </div>
      </section>
      <section className="libraryservices-main__section">
        <main className="libraryservices-main__main">
          <div className="libraryservices-content__div">
            <h1 className="libraryservices-header__h1">
              Let Strand Bookstore help save your library money.
            </h1>
            <p className="libraryservices-text__p">
              Strand offers new, used, and out-of-print books in a wide variety of subject areas.
              Our experienced staff can help make your collection development an easy process.
              Let us provide you with an easy way to order books that are deeply discounted,
              quickly processed and shipped free of charge.
              With our many complimentary services and our vast experience,
              you will find that Strand shopping is smart shopping.
            </p>
            <h2 className="libraryservices-header__h2">
              Contact Our Library Manager
            </h2>
            <p className="libraryservices-text__p">
              Dan Roth
              <br />
              Phone: 212.473.1452 x490
              <br />
              Email:&nbsp;
              <a className="libraryservices-link__a" href="mailto:libraries@strandbooks.com">libraries@strandbooks.com</a>
              <br />
              Monday-Friday
              <br />
              9:15am - 6:15pm
            </p>
            <h3 className="libraryservices-header__h3">Incredible Selection</h3>
            <p className="libraryservices-text__p">Our warehouses contain a large volume of popular material available for immediate shipment to you. We offer discounted reviewer’s copies of newly published books and remaindered books.  We have audio and audiovisual materials that are extremely popular with library patrons, including books on CD and language learning products. We also have a wide selection of Vinyl from all genres of music as well as an assortment of Broadway musicals on CD.</p>
            <h3 className="libraryservices-header__h3">Free Shipping</h3>
            <p className="libraryservices-text__p">We provide free economy shipping for libraries on all orders shipped domestically, no matter the size!</p>
            <h3 className="libraryservices-header__h3">Outstanding Customer Service</h3>
            <p className="libraryservices-text__p">Our Library Services and Customer Service teams are here to help process any orders, returns or exchanges. We can provide you with prompt and accurate order fulfillment, a large inventory of stock, and accurate reporting of your order’s status. As we have a staff familiar with the demands of library orders, we can accommodate any library’s needs or policies, especially concerning paperwork.</p>
            <h3 className="libraryservices-header__h3">Easy Billing Options</h3>
            <p className="libraryservices-text__p">Having worked with libraries for years, we know how complex billing procedures can be for librarians. Working with a purchase order system? No problem! We offer net-30 billing terms for most accounts. We also feature many convenient account options:</p>
            <ul className="libraryservices-list__ul">
              <li className="libraryservices-list-item__li">Pro-forma Invoices</li>
              <li className="libraryservices-list-item__li">Credit Memos</li>
              <li className="libraryservices-list-item__li">Invoice Reprints</li>
              <li className="libraryservices-list-item__li">Itemized Invoices</li>
              <li className="libraryservices-list-item__li">Balance Checks</li>
              <li className="libraryservices-list-item__li">Payment Status Updates</li>
              <li className="libraryservices-list-item__li">Monthly Billing Statement</li>
            </ul>
          </div>
        </main>
      </section>
      <ViewFooter />
    </div>
  );
};

LibraryServices.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default LibraryServices;
