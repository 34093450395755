import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import use4Cite from '../../hooks/use4Cite';
import './_giftCard.scss';

const GiftCard = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="giftcard-container__div">
        <main className="giftcard-content__main">
          <div className="giftcard-main__div">
            <div className="giftcard-header__div">
              <PageHeader header="Gift Cards" />
              <h3 className="giftcard-header__h3">E-Gift Cards are emailed directly to a recipient within an hour of purchase or on a specified date. E-Gift Cards can also be printed at home for hand-delivery. Our E-Gift Cards are easily customizable and may include a personalized message and photo. E-Gift Cards may be redeemed online and in-store. E-gift cards are not available for purchase from outside the United States.</h3>
            </div>
            <section className="giftcard-cashstar__section">
              <div className="giftcard-cashstar__div">
                <iframe data-testid="giftcard-iframe" title="Cashstar Strand Gift Card" className="giftcard-cashstar__iframe" src="https://strandbooks.cashstar.com/" />
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

GiftCard.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};


export default GiftCard;
