import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Loading from '../loading/Loading';
import PageHeader from '../pageHeader/PageHeader';
import WithEvent from '../withEvent/WithEvent';
import './_bbtfTop.scss';

export const GET_PRODUCT_LIST_DETAIL = gql`
  query getProductListDetail($ean: [String]!){
    productListDetail(ean: $ean){
      bookBinding
      copyright
      description
      gtin13
      id
      imageUrl
      webDescription
      inventory{
        condition
        edition
        jacketCondition
        onHand
        salePrice
        salePriceStv
        section
        signed
        sku
        store
      }
      inventoryOverview{
        conditionList
        discountMaxPct
        discountMinPct
        locationList
        salePriceMaxStv
        salePriceMinStv
        sectionList
        signedList
      }
      isbn10
      originDate
      originator
      outOfPrint
      prop{
        edition
        heightIn00
        pagecount
        publishPlace
        thicknessIn00
        weightOz00
        widthIn00
      }
      publisher
      retailPrice
      retailStv
      title
    }
  }
`;

const BBTFTop = () => {
  const topSelling = [
    '9789112001402',
    '9789112001501',
    '9789111979078',
    '9789111979061',
    '9789111979047',
    '9789112001532',
    '9789112001464',
    '9789112001624',
    '9789112001655',
    '9789112001761',
  ];

  const { data, error, loading } = useQuery(GET_PRODUCT_LIST_DETAIL, {
    variables: {
      ean: topSelling,
    },
  });

  if (error) return <Redirect to={{ pathname: '/error', state: { error } }} />;

  if (loading) return <Loading />;

  return (
    <div className="bbtftop-container__div">
      <div className="bbtftop-intro__div">
        <PageHeader header="Top Selling Collections" />
        <h3 className="bbtftop-header__h3">
          These are our most popular collections, available online.
          Readymade collections are best for those who know exactly what they are looking for.
        </h3>
      </div>
      <div className="bbtftop-items__div">
        {
          data.productListDetail
            && data.productListDetail.length > 0
            && data.productListDetail.map((item) => {
              const {
                title,
                inventory,
                id,
                imageUrl,
                gtin13,
              } = item;

              return (
                <div className="bbtftop-item__div" key={id}>
                  <Link
                    to={`/product/${gtin13}`}
                    className="bbtftop-button__link"
                    onClick={() => WithEvent('BBTF', `Clicked ${title} Image`, 'BBTF_TOP', 1)}
                  >
                    <div className="bbtftop-item-image__div">
                      <img
                        className="bbtftop-item-image__img"
                        src={imageUrl}
                        alt={title}
                      />
                    </div>
                  </Link>
                  <h3 className="bbtftop-header__h3">{title}</h3>
                  <div className="bbtftop-details__div">
                    {
                      inventory?.[0]?.salePrice
                        ? <p className="bbtftop-price__p">{`$${inventory[0].salePrice.toFixed(2)}`}</p>
                        : <p className="bbtftop-price__p bbtftop-price__p--red bbtftop-price__p--strong">*Out of Stock*</p>
                    }
                  </div>
                  {
                    inventory?.[0]?.salePrice
                      ? (
                        <Link
                          to={`/product/${gtin13}`}
                          className="bbtftop-button__link"
                          onClick={() => WithEvent('BBTF', `Clicked ${title} Button`, 'BBTF_TOP', 1)}
                        >
                          <div className="bbtftop-button__div">
                            <p className="bbtftop-button__p">Buy this product</p>
                          </div>
                        </Link>
                      )
                      : (
                        <div className="bbtftop-button__div bbtftop-button__div--disabled">
                          <p className="bbtftop-button__p">Buy this product</p>
                        </div>
                      )
                  }
                </div>
              );
            })
        }
      </div>
      <div className="bbtftop-more__div">
        <Link
          className="bbtftop-link__link"
          to="/books-by-the-foot/collections"
          onClick={() => WithEvent('BBTF', 'Clicked View Collections', 'BBTF_TOP', 1)}
        >
          View All Collections
        </Link>
      </div>
    </div>
  );
};

export default BBTFTop;
