const capitalizeString = (str) => {
  const newArr = [];

  const exceptionsObj = {
    'R&B/HIP-HOP': 'R&B/Hip-Hop',
  };

  if (str && str.length > 0) {
    if (exceptionsObj[str]) {
      return exceptionsObj[str];
    }

    str.split('').forEach((l, i) => {
      if (i === 0 || str[i - 1] === '-' || str[i - 1] === ' ' || str[i - 1] === '/' || (str[i - 1] === 'Y' && str[i] === 'A' && str[i + 1] === ' ')) {
        newArr.push(l.toUpperCase());
      } else newArr.push(l.toLowerCase());
    });
  }
  return newArr.length ? newArr.join('') : str;
};

export default capitalizeString;

/*

Optimization: Regex maybe?

*/
