import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import WithEvent from '../withEvent/WithEvent';
import './_staticBanner.scss';

const StaticBanner = (props) => {
  const {
    link,
    alt,
    imgUrl,
    imgUrlMobile,
    hasHref,
    mediaQuery,
  } = props;

  const contentEl = (
    <div className="staticbanner-image__div">
      <img
        className="staticbanner-image__img"
        src={mediaQuery ? imgUrl : imgUrlMobile}
        alt={alt}
      />
    </div>
  );

  const linkProps = {
    onClick: () => WithEvent('HERO', `Clicked static banner ${alt}`, 'STATIC_BANNER', 1),
    'aria-label': alt,
  };

  let childEl;

  if (link?.length) {
    if (hasHref) {
      childEl = (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          {contentEl}
        </a>
      );
    } else {
      childEl = (
        <Link
          to={link}
          {...linkProps}
        >
          {contentEl}
        </Link>
      );
    }
  } else {
    childEl = contentEl;
  }

  return (
    <div className="staticbanner-container__div">
      <div className="staticbanner-content__div">
        {childEl}
      </div>
    </div>
  );
};

StaticBanner.propTypes = {
  link: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  imgUrlMobile: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  hasHref: PropTypes.bool,
  mediaQuery: PropTypes.bool.isRequired,
};

export default StaticBanner;
