import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import ButtonBase from '@material-ui/core/ButtonBase';
import './_bbtfDialog.scss';

const BBTFDialog = (props) => {
  const {
    onClose,
    open,
    images,
    desc,
  } = props;

  const [currentState, setCurrent] = useState(0);

  const handleCurrent = index => setCurrent(index);

  return (
    <Dialog open={open} onClose={onClose}>
      {
        images.length === 1
          ? (
            <div className="bbtfdialog-content__div">
              <div className="bbtfdialog-image__div">
                <img
                  className="bbtfdialog-image__img"
                  alt="bbtf"
                  src={images[0]}
                />
              </div>
              <div className="bbtfdialog-divider__div" />
              <div className="bbtfdialog-description__div">
                <div className="bbtfdialog-details__div">
                  <p className="bbtfdialog-details__p">{desc}</p>
                </div>
              </div>
            </div>
          )
          : (
            <div className="bbtfdialog-content__div">
              <div className="bbtfdialog-images__div">
                <div className="bbtfdialog-image__div">
                  <img
                    className="bbtfdialog-image__img"
                    alt="bbtf"
                    src={`${images[currentState]}`}
                  />
                </div>
                <div className="bbtfdialog-alternates__div">
                  {
                    images.map((img, i) => (
                      <div className="bbtfdialog-alternate__div" key={`altBBTF${i + 200}`}>
                        <ButtonBase
                          onClick={() => handleCurrent(i)}
                          disableTouchRipple
                          disableRipple
                        >
                          <img
                            className="bbtfdialog-alternate__img"
                            alt={`alternate bbtf ${i}`}
                            src={img}
                          />
                        </ButtonBase>
                        {
                          currentState === i
                            && <div className="bbtfdialog-accent-active__div" />
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="bbtfdialog-divider__div" />
              <div className="bbtfdialog-description__div">
                <div className="bbtfdialog-details__div">
                  <p className="bbtfdialog-details__p">{desc}</p>
                </div>
              </div>
            </div>
          )
      }
    </Dialog>
  );
};

BBTFDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  desc: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default BBTFDialog;
