import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import GenCarousel from '../genCarousel/GenCarousel';
import Loading from '../loading/Loading';
import PageSubHeader from '../pageSubHeader/PageSubHeader';
import Highlight from '../highlight/Highlight';
import StaticBanner from '../staticBanner/StaticBanner';
// import SecondaryBanner from '../secondaryBanner/SecondaryBanner';
import UpcomingEvents from '../upcomingEvents/UpcomingEvents';
import listEvents from '../events/listEvents';
import use4Cite from '../../hooks/use4Cite';
import useMediaQueryMinWidth from '../../hooks/useMediaQueryMinWidth';
import urlGenerator from '../../helpers/urlGenerator';
import hoursLocationData from '../hoursLocations/hoursLocationData';
import './_home.scss';

export const META_LIST_DETAIL = gql`
  query{
    bestsellersMeta: metaListDetail(metaTag: "Bestsellers"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    newArrivalsMeta: metaListDetail(metaTag: "New Arrivals"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
    apparelHatsBeaniesMeta: metaListDetail(metaTag: "apparel hats and beanies"){
      product{
        gtin13
        id
        imageUrl
        originator
        retailStv
        title
      }
    }
  }
`;

export const PRODUCT_LIST_DETAIL = gql`
  query{
    productListDetail(ean: ["9112084751", "9111026634", "9112128198", "911218845X", "9112216984", "2900012830337", "2900012649519", "9112087718", "9112331937", "9112101117", "2900012865445"]){
      gtin13
      imageUrl
      id
      title
    }
  }
`;

const Home = (props) => {
  const {
    cartList,
    checkoutIsOpen,
    customerLoggedIn,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  const mediaQuery = useMediaQueryMinWidth(500);

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL);

  const {
    error: errorProductListDetail,
    loading: loadingProductListDetail,
    data: dataProductListDetail,
  } = useQuery(PRODUCT_LIST_DETAIL);


  use4Cite();

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  if (errorMeta || errorProductListDetail) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta || loadingProductListDetail) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="home-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  const mSaleStart = moment.tz(moment('2022-11-28 10:00:00.000'), 'America/New_York');
  const mSaleEnd = moment.tz(moment('2022-11-29 00:00:00.000'), 'America/New_York');
  const mCurrent = moment.tz(moment(Date.now()), 'America/New_York');

  const isSalePeriod = mCurrent.isSameOrAfter(mSaleStart) && mCurrent.isBefore(mSaleEnd);

  const saleBanner = isSalePeriod
    ? (
      <StaticBanner
        link=""
        alt="Cyber Monday: Free Shipping"
        imgUrl={urlGenerator('/S/banners/banners_2022_cyber-monday-desktop.png')}
        imgUrlMobile={urlGenerator('/S/banners/banners_2022_cyber-monday-desktop.png')}
        mediaQuery={mediaQuery}
      />
    )
    : (
      <StaticBanner
        link="/metalist/pride_2024"
        alt="Pride 2024"
        imgUrl={urlGenerator('/S/banners/zombie_pride_2024_dsk-min.png')}
        imgUrlMobile={urlGenerator('/S/banners/zombie_pride_2024_mobile.png')}
        mediaQuery={mediaQuery}
      />
    );

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="home-container__div">
        <main className="home-content__main">
          <div className="home-content__div">
            {/*
            <section className="home-vote__section">
              <SecondaryBanner />
            </section>
            */}
            <section className="home-hero__section">
              <div data-testid="main-banner" className="home-static-hero__div">
                {saleBanner}
              </div>
            </section>
            <section className="home-highlights__section">
              <div data-testid="highlight-banner" className="home-highlights__div">
                <Highlight />
              </div>
            </section>
            <section className="home-events__section">
              <div className="home-events__div">
                <UpcomingEvents data-testid="upcoming-events" listEvents={listEvents} />
              </div>
            </section>
            <section className="home-carousels__section">
              <div className="home-carousels__div">
                <div className="home-carousel__div">
                  <GenCarousel
                    locationName="bestsellers"
                    dataType="metaList"
                    data-testid="bestsellers-carousel"
                    products={(
                      dataMeta
                      && dataMeta.bestsellersMeta
                      && dataMeta.bestsellersMeta.product)
                      || []
                    }
                  />
                </div>
                <div className="home-carousel__div">
                  <GenCarousel
                    locationName="new_arrivals"
                    dataType="metaList"
                    data-testid="new-arrivals-carousel"
                    products={(
                      dataMeta
                      && dataMeta.newArrivalsMeta
                      && dataMeta.newArrivalsMeta.product)
                      || []
                    }
                  />
                </div>
                <div className="home-carousel__div">
                  <GenCarousel
                    locationName="apparel_hats_beanies"
                    dataType="metaList"
                    data-testid="apparel-hats-beanies-carousel"
                    products={(
                      dataMeta
                      && dataMeta.apparelHatsBeaniesMeta
                      && dataMeta.apparelHatsBeaniesMeta.product)
                      || []
                    }
                  />
                </div>
              </div>
            </section>
            <section className="home-other-carousels__section">
              <div className="home-other-carousels__div">
                <section className="home-second-banner__section">
                  <div className="home-second-banner__div" data-testid="secondary-banner">
                    <StaticBanner
                      link="/pick-of-the-month"
                      alt="Pick of the Month"
                      imgUrl={urlGenerator('/S/pages/potm_new_w_landing-page-desktop-3.jpg')}
                      imgUrlMobile={urlGenerator('/S/pages/potm_landing_new-page-mobile-33.jpg')}
                      mediaQuery={mediaQuery}
                    />
                  </div>
                </section>
                <div className="home-carousel__div">
                  <GenCarousel
                    locationName="/productsection/MERCH-TOTES"
                    dataType="default"
                    data-testid="totes-carousel"
                    header="Totes"
                    products={(
                      dataProductListDetail?.productListDetail
                    )
                      || []
                    }
                  />
                </div>
              </div>
            </section>
            <section className="home-locations__section">
              <PageSubHeader header="Store Hours and Locations" />
              <div className="home-locations__div">
                {hoursLocationData.map((hld) => {
                  const {
                    id,
                    title,
                    image,
                    imageAlt,
                    details,
                  } = hld;

                  return (
                    <div key={id} className={`home-location__div home-location-${id}__div`}>
                      <div className="home-location-image__div">
                        <img
                          src={image}
                          alt={imageAlt}
                          className="home-location-image__img"
                        />
                      </div>
                      <div className="home-location-details__div">
                        <h3 className="home-location-name__h3">{title}</h3>
                        <div className="home-accent__div" />
                        <p
                          className="home-location-details__p"
                          dangerouslySetInnerHTML={
                            (() => ({ __html: details }))()
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
          </div>
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

Home.propTypes = {
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default Home;
