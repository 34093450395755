import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import CheckoutOrderSummary from './CheckoutOrderSummary';
import CheckoutBack from './CheckoutBack';
import WithEvent, { WithFBEvent } from '../withEvent/WithEvent';
import WithEcomm from '../withEcomm/WithEcomm';
import availableLS from '../../helpers/availableLS';
import use4Cite from '../../hooks/use4Cite';
import './_checkoutReviewOrder.scss';

const useStyles = makeStyles({
  rootBtn: {
    backgroundColor: '#af272f',
    textTransform: 'none',
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '1em',
    color: '#ffffff',
    margin: '20px 0',
    minWidth: '240px',
    height: '40px',
    borderRadius: '5px',
    '&:hover': {
      background: 'transparent',
      backgroundColor: '#af272f',
    },
  },
});

export const UPDATE_CHECKOUT = gql`
  mutation updateCheckout($submit: CheckoutTransactionIn!){
    checkoutUpdate(submit: $submit){
      checkoutId
      complete
      taxRate
      alts{
        canPo
      }
      cart{
        cartId
        cartType
        valueStv
        cartItem{
          cartItemId
          qty
          sku
          salePriceStv
          gtin
          productDetail{
            title
          }
        }
      }
      billAddr{
        addr1
        addr2
        attention
        city
        countryCode
        postalCode
        state
      }
      shipAddr{
        addr1
        addr2
        attention
        city
        countryCode
        postalCode
        state
      }
      shipMethod{
        longName
      }
      specialInstructions
      deliveryInstructions
      payments{
        amountStv
        balanceStv
        paymentType
        display
      }
      tally{
        discountApplyStv
        dueStv
        insufficientFunds
        itemCount
        productStv
        shippingStv
        taxRate
        taxStv
        vatRate
        vatStv
        taxableStv
        totalStv
      }
    }
  }
`;

export const COMMIT_CHECKOUT = gql`
  mutation commitCheckout($authorization: AuthorizationIn!){
    checkoutCommit(authorization: $authorization){
      dryrun
      problems{
        message
        problemType
        fieldPath
      }
      successful
      order
    }
  }
`;

export const GET_CART_DETAILS = gql`
  query getCartList{
    cartList{
      cartId
      customerId
    }
  }
`;

const CheckoutReviewOrder = (props) => {
  const classes = useStyles();
  const {
    checkoutDetails,
    checkoutState,
    handleBack,
    currentStep,
    overweightState,
    setPromptState,
  } = props;

  const match767 = useMediaQuery('(min-width: 767px)');

  const [checkoutCommit, {
    data: commitData,
    loading: commitLoading,
    error: commitError,
  }] = useMutation(COMMIT_CHECKOUT);

  const [
    checkoutUpdate,
    {
      data: updateData,
      loading: updateLoading,
      error: updateError,
    },
  ] = useMutation(UPDATE_CHECKOUT);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    checkoutUpdate({ variables: { submit: {} } });
  }, [checkoutUpdate]);

  useEffect(() => {
    WithFBEvent('AddPaymentInfo', {});
  }, []);

  use4Cite();

  const {
    checkoutId,
  } = checkoutDetails.beginData.checkoutBegin;

  // const placeholder = {
  //   paymentInfo: {
  //     ccId: '123',
  //     ccName: 'Customer 1',
  //     ccNumber: 'xxxx-xxxx-xxxx-xxxx',
  //     ccCvv: '123',
  //     ccExp: '3/25',
  //     ccAdd1: '828 Broadway',
  //     ccAdd2: 'Fl. 10',
  //     ccState: 'NY',
  //     ccCity: 'New York',
  //     ccCountry: 'United States',
  //     ccZip: '10003',
  //   },
  //   shippingInfo: {
  //     shipId: '123',
  //     shipName: 'Jane Doe',
  //     shipAdd1: '828 Broadway',
  //     shipAdd2: 'Fl. 10',
  //     shipCity: 'New York',
  //     shipState: 'NY',
  //     shipZip: '10003',
  //     shipTel: '1234567890',
  //     shipCountry: 'United States',
  //     shipSpecialIns: ' I have a dog',
  //   },
  // };

  if (commitLoading || updateLoading) {
    return (
      <div className="checkoutrevieworder-container__div">
        <Loading />
      </div>
    );
  }

  if (commitError || updateError) {
    return <Redirect to={{ pathname: '/error', state: { commitError, updateError } }} />;
  }

  if (commitData && commitData.checkoutCommit && commitData.checkoutCommit.successful) {
    // setCheckout({ ...checkoutState, orderNo: commitData.checkoutCommit.order });
    // setStepper(4);

    if (updateData?.checkoutUpdate?.tally?.totalStv) {
      window.purchaseData = {
        phone: checkoutState.billPhone,
        email: checkoutState.billEmail,
        orderId: commitData.checkoutCommit.order,
        cartTotal: parseFloat((updateData.checkoutUpdate.tally.totalStv * 0.01).toFixed(2)),
        items: updateData.checkoutUpdate.cart[0].cartItem.reduce((acc, cartItem) => {
          const item = {
            name: cartItem.productDetail.title,
            sku: cartItem.sku,
            price: parseFloat(cartItem.salePriceStv * 0.01).toFixed(2),
          };

          for (let i = 0; i < cartItem.qty; i += 1) {
            acc.push(item);
          }

          return acc;
        }, []),
      };

      WithEvent('CHECKOUT', 'Confirmed an Order', 'ORDER_CONFIRMED', parseFloat((updateData.checkoutUpdate.tally.totalStv * 0.01).toFixed(0)));

      if (checkoutState.isSPU) {
        WithEvent('CHECKOUT', 'Confirmed Store Pickup', 'ORDER_CONFIRMED_SPU', 1);
        WithEvent('CHECKOUT', 'Confirmed Store Pickup', 'ORDER_CONFIRMED_SPU_VAL', parseFloat((updateData.checkoutUpdate.tally.totalStv * 0.01).toFixed(0)));
      }

      // set shipaddr in local storage for return checkout

      if (updateData.checkoutUpdate.shipAddr) {
        if (availableLS('localStorage')) {
          localStorage.setItem('STRAND_SHIPADDR', JSON.stringify(updateData.checkoutUpdate.shipAddr));
        }
      }
    }

    WithEcomm(
      updateData.checkoutUpdate.cart[0].cartItem,
      updateData.checkoutUpdate.checkoutId,
      updateData.checkoutUpdate.payments.reduce((acc, cv) => acc + cv.amountStv, 0),
      updateData.checkoutUpdate.tally,
    );

    return (
      <Redirect to={{
        pathname: '/checkout/confirmation',
        state: {
          checkoutPayment: checkoutState.checkoutPayment,
          cartItemsList: checkoutState.cartItemsList,
          orderNo: commitData.checkoutCommit.order,
          tally: checkoutState.tally,
          success: true,
          isSPU: checkoutState.isSPU,
        },
      }}
      />
    );
  }

  const handleConfirm = () => {
    Sentry.addBreadcrumb({
      category: 'CHECKOUT',
      message: 'Clicked Confirm your Order',
      level: Sentry.Severity.Info,
    });

    setPromptState(false);

    checkoutCommit({
      variables: {
        authorization: {},
      },
      update: (cache) => {
        // const { cartList } = cache.readQuery({ query: GET_CART_DETAILS });

        // console.log(cache, checkoutCommit);
        // const cartItemUpdated = cartList.filter(sCart => sCart.cartType === 'PRIMARY')[0];
        // const cartListUpdated = [];

        // cartList[0].cartItem = cartListUpdated;
        cache.writeQuery({
          query: GET_CART_DETAILS,
          data: {
            cartList: [],
          },
        });
      },
    });
  };

  return (
    <div className="checkoutrevieworder-container__div">
      { /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */ }
      <PageHeader id="checkoutrevieworder-title" header="Review Your Order" headerProps={{ tabIndex: 0 }} />
      <main className="checkoutrevieworder-content__main" aria-labelledby="checkoutrevieworder-title">
        <CheckoutBack handleBack={handleBack} canPO={updateData?.checkoutUpdate?.alts?.canPo} />
        <div className="checkoutrevieworder-content__div">
          <section className="checkoutrevieworder-confirm__section checkoutrevieworder-confirm1__section">
            {
              !match767
                && (
                  <div className="checkoutrevieworder-confirm__div checkoutrevieworder-confirm1__div">
                    <Button onClick={handleConfirm} classes={{ root: classes.rootBtn }}>
                      Confirm Your Order
                    </Button>
                  </div>
                )
            }
          </section>
          <section data-testid="checkoutordersummary" className="checkoutrevieworder-cart__section">
            <CheckoutOrderSummary
              shipBase={checkoutState.isSPU ? 0 : checkoutState.shipBase}
              shipAdd={checkoutState.isSPU ? 0 : checkoutState.shipAdd}
              taxRate={(updateData && updateData.checkoutUpdate && updateData.checkoutUpdate.taxRate) || 0}
              checkoutId={checkoutId}
              payments={(updateData && updateData.checkoutUpdate && updateData.checkoutUpdate.payments) || []}
              handleReview={() => {}}
              currentStep={currentStep}
              overweightState={overweightState}
              tally={updateData?.checkoutUpdate?.tally || {}}
            />
          </section>
          <section className="checkoutrevieworder-info__section">
            <div className="checkoutrevieworder-info__div">
              <div className="checkoutrevieworder-payment__div">
                <h3 className="checkoutrevieworder-header__h3">Billing Information</h3>
                <div className="checkoutrevieworder-accent__div" />
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.attention
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.attention}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.addr1
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.addr1}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.addr2
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.addr2}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.city
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.city}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.state
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.state}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.postalCode
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.postalCode}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.billAddr
                  && updateData.checkoutUpdate.billAddr.countryCode
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.billAddr.countryCode}
                  </p>
                  )
                }
                {
                  updateData?.checkoutUpdate?.alts?.canPo
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {`Purchase Order #: ${checkoutState.poNumber}`}
                  </p>
                  )
                }
              </div>
              <div className="checkoutrevieworder-shipping__div">
                <h3 className="checkoutrevieworder-header__h3">Shipping Information</h3>
                <div className="checkoutrevieworder-accent__div" />
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.attention
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.attention}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.addr1
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.addr1}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.addr2
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.addr2}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.city
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.city}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.state
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.state}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.postalCode
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.postalCode}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipAddr
                  && updateData.checkoutUpdate.shipAddr.countryCode
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipAddr.countryCode}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.shipMethod
                  && updateData.checkoutUpdate.shipMethod.longName
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.shipMethod.longName}
                  </p>
                  )
                }
                {
                  updateData
                  && updateData.checkoutUpdate
                  && updateData.checkoutUpdate.deliveryInstructions
                  && (
                  <p className="checkoutrevieworder-details__p">
                    {updateData.checkoutUpdate.deliveryInstructions}
                  </p>
                  )
                }
              </div>
              <div className="checkoutrevieworder-notice__div">
                <p className="checkoutrevieworder-notice__p">
                  *Note: Please allow 2-3 days processing time from the time that you
                  place your order in addition to shipping time.*
                  <br />
                  <br />
                  *Books by the Foot: Please note that turnaround time has increased during
                  the current climate, and online orders can take approximately 3 days
                  to prepare before being ready to ship or be available for store pick up.
                  We will contact you once your order is complete
                  and ready to ship or pick up in store.*
                </p>
              </div>
              <div className="checkoutrevieworder-confirm__div">
                <Button onClick={handleConfirm} classes={{ root: classes.rootBtn }}>
                  Confirm Your Order
                </Button>
              </div>
            </div>
          </section>
          {/* <section className="checkoutrevieworder-confirm__section checkoutrevieworder-confirm2__section">
            <div className="checkoutrevieworder-confirm__div">
              <Button classes={{ root: classes.rootBtn }}>
                Confirm Your Order
              </Button>
            </div>
          </section> */}
        </div>
      </main>
    </div>
  );
};

CheckoutReviewOrder.propTypes = {
  checkoutDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  checkoutState: PropTypes.objectOf(PropTypes.any).isRequired,
  handleBack: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  // eslint-disable-next-line react/require-default-props
  overweightState: PropTypes.bool,
  setPromptState: PropTypes.func.isRequired,
};

export default CheckoutReviewOrder;
