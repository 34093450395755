/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UpcomingEventsBanner from './UpcomingEventsBanner';
// import listEvents from '../events/listEvents';
import convertURL from '../../helpers/convertURL';
import WithEvent from '../withEvent/WithEvent';
import './_upcomingEvents.scss';

const UpcomingEvents = (props) => {
  const { listEvents } = props;

  return (
    <div className="upcomingevents-container__div">
      <main className="upcomingevents-main__main">
        <div className="upcomingevents-main__div">
          <UpcomingEventsBanner />
          <section className="upcomingevents-events__section">
            <div className="upcomingevents-events__div">
              {
                listEvents
                  .filter((event) => {
                    const eventDate = new Date(event.dateObj);
                    return eventDate.getTime() >= Date.now();
                  }).length > 0
                  ? (
                    listEvents
                      .filter(event => !event.private)
                      .filter((event) => {
                        const eventDate = new Date(event.dateObj);
                        return eventDate.getTime() >= Date.now();
                      })
                      .sort((a, b) => {
                        if (moment(a.dateObj).isBefore(moment(b.dateObj))) {
                          return -1;
                        }

                        if (moment(a.dateObj).isAfter(moment(b.dateObj))) {
                          return 1;
                        }

                        return 0;
                      })
                      .slice(0, 6)
                      .map(event => (
                        <div className="upcomingevents-event__div" key={event.id}>
                          <div className="upcomingevents-event-image__div">
                            <img className="upcomingevents-event-image__img" src={event.imageURL} alt={event.eventName} />
                          </div>
                          <div className="upcomingevents-title__div">
                            <Link
                              to={`/events/${event.id}?title=${convertURL(event.eventName)}`}
                              className="upcomingevents-title-link__link"
                              onClick={() => WithEvent('EVENTS', `clicked homepage ${event.eventName} link`, 'CLICK_EVENT', 1)}
                            >
                              <h3 className="upcomingevents-header__h3">{event.eventName}</h3>
                            </Link>
                            <div className="upcomingevents-time__div">
                              <p className="upcomingevents-time__p">{`${moment(event.dateObj).format('dddd')}, ${moment(event.dateObj).format('MMM')} ${moment(event.dateObj).format('DD')} at ${moment(event.dateObj).format('LT')} ET`}</p>
                            </div>
                            <div className="upcomingevents-more__div">
                              <a className="upcomingevents-more__a" href={`/events/${event.id}?title=${convertURL(event.eventName)}`}>View More Details</a>
                            </div>
                          </div>
                        </div>
                      ))
                  )
                  : (
                    <div className="upcomingevents-no-events__div">
                      <h2 className="upcomingevents-header__h2 upcomingevents-header__h2--gray">
                        We have no upcoming events at this time
                      </h2>
                    </div>
                  )
              }
            </div>
          </section>
          {
            listEvents
              .filter((event) => {
                const eventDate = new Date(event.dateObj);
                return eventDate.getTime() >= Date.now();
              }).length > 0
                && (
                  <div className="upcomingevents-view__div">
                    <Link
                      className="upcomingevents-view-link__link"
                      to="/events"
                      onClick={() => WithEvent('EVENTS', 'Clicked View All', 'CLICK_ALL_EVENTS', 1)}
                    >
                      View all events
                    </Link>
                  </div>
                )
          }
        </div>
      </main>
    </div>
  );
};

UpcomingEvents.propTypes = {
  listEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UpcomingEvents;
