import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Slider from '@material-ui/core/Slider';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { ButtonBase } from '@material-ui/core';
import formatPrice from '../../helpers/formatPrice';
import capitalizeString from '../../helpers/capitalizeString';
import './_productListDrawer.scss';

/*

Should category only show the meta tags associated with the book?
I can also show it by genre. Also, does the payload contain the info?

*/
const useStyles = makeStyles({
  colorSecondaryCheckbox: {
    color: '#af272f !important',
  },
  rootSlider: {
    color: '#af272f',
    width: 175,
  },
  rootIconButton: {
    color: '#af272f',
    '&:hover': {
      backgroundColor: '#af272f',
      color: '#ffffff',
    },
  },
  colorSecondaryChip: {
    backgroundColor: '#af272f !important',
  },
  rootChip: {
    fontFamily: "'Trade Gothic LT Std', 'Proxima Nova, sans-serif'",
    fontSize: '13px',
  },
});

const ProductListDrawer = (props) => {
  const {
    productList,
    checkSigned,
    bookBindings,
    bookCondition,
    bookSection,
    bookPrice,
    history,
    search,
    pageOptionsState,
    paginationState,
    productListName,
  } = props;
  const classes = useStyles();

  const [collapseState, setCollapse] = useState({
    section: false,
    condition: false,
    binding: false,
  });

  const handleDelete = (filterOp) => {
    if (filterOp === 'signed') {
      history.push(
        `/productlist/${productListName}${search.replace(/signed=true/, 'signed=false').replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.PageLimit,
          URLPageFilters: {
            checkSigned: false,
            bookBindings,
            bookCondition,
            bookSection,
            bookPrice,
          },
        },
      );
    }

    if (bookBindings.indexOf(filterOp) > -1) {
      const formatArrCopy = search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(bind => bind.split('+').join(' ')).slice();
      formatArrCopy.splice(formatArrCopy.indexOf(filterOp), 1);
      const editedStr = formatArrCopy.length ? formatArrCopy.map(bind => bind.split(' ').join('+')).join('|') : 'null';

      history.push(
        `/productlist/${productListName}${search.replace(/bookbindings=[^&]*/, `bookbindings=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.PageLimit,
          URLPageFilters: {
            checkSigned,
            bookBindings: formatArrCopy.length ? formatArrCopy : [],
            bookCondition,
            bookSection,
            bookPrice,
          },
        },
      );
    }

    if (bookCondition.indexOf(filterOp) > -1) {
      const formatArrCopy = search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')).slice();
      formatArrCopy.splice(formatArrCopy.indexOf(filterOp), 1);
      const editedStr = formatArrCopy.length ? formatArrCopy.map(condition => condition.split(' ').join('+')).join('|') : 'null';

      history.push(
        `/productlist/${productListName}${search.replace(/bookcondition=[^&]*/, `bookcondition=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.PageLimit,
          URLPageFilters: {
            checkSigned,
            bookBindings,
            bookCondition: formatArrCopy.length ? formatArrCopy : [],
            bookSection,
            bookPrice,
          },
        },
      );
    }

    if (bookSection.indexOf(filterOp) > -1) {
      const formatArrCopy = search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(bind => bind.split('+').join(' ')).slice();
      formatArrCopy.splice(formatArrCopy.indexOf(filterOp), 1);
      const editedStr = formatArrCopy.length ? formatArrCopy.map(bind => bind.split(' ').join('+')).join('|') : 'null';

      history.push(
        `/productlist/${productListName}${search.replace(/booksection=[^&]*/, `booksection=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
        {
          URLOffset: paginationState.offset,
          URLCurrentPage: 1,
          URLSort: pageOptionsState.sort,
          URLPageLimit: pageOptionsState.PageLimit,
          URLPageFilters: {
            checkSigned,
            bookBindings,
            bookCondition,
            bookSection: formatArrCopy.length ? formatArrCopy : [],
            bookPrice,
          },
        },
      );
    }
  };

  return productList.length > 0
    && (
      <aside>
        <div className="productlistdrawer-container__div">
          <div className="productlistdrawer-changes__div">
            <div className="productlistdrawer-chips__div">
              {
                bookBindings.concat(bookCondition, bookSection).concat(checkSigned ? ['signed'] : [])
                  .map((filterOp, i) => (
                    <div className="productlistdrawer-chip__div" key={`filterKey${i + 200}`}>
                      <Chip
                        label={capitalizeString(filterOp)}
                        className="productlistdrawer-chip__chip"
                        onDelete={() => handleDelete(filterOp)}
                        color="secondary"
                        classes={{ colorSecondary: classes.colorSecondaryChip, root: classes.rootChip }}
                      />
                    </div>
                  ))
              }
            </div>
            {
              bookBindings.concat(bookCondition, bookSection).concat(checkSigned ? ['signed'] : []).length > 3
                && (
                  <div className="productlistdrawer-clear__div">
                    <ButtonBase
                      disableRipple
                      className="productlistdrawer-clear-btn__buttonbase"
                      onClick={() => {
                        history.push(
                          `/productlist/${productListName}?page=1&pagelimit=40&sort=1&signed=false&bookbindings=null&bookcondition=null&booksection=null&bookprice=null`,
                          {
                            URLOffset: 0,
                            URLCurrentPage: 1,
                            URLSort: pageOptionsState.sort,
                            URLPageLimit: pageOptionsState.pageLimit,
                            URLPageFilters: productList,
                          },
                        );
                      }}
                    >
                      <p className="productlistdrawer-clear-btn__p">Clear all</p>
                    </ButtonBase>
                  </div>
                )
            }
          </div>
          {
            productList
              .filter(prod => (
                formatPrice(prod.retailPrice, prod.salePriceMax, prod.salePriceMin) !== null
              ))
              && (
                <div className="productlistdrawer-filter__div productlistdrawer-price__div">
                  <h2 id="range-price-slider" className="productlistdrawer-filter-header__h2">Price</h2>
                  {
                    (() => {
                      const valueText = val => `$${val}`;
                      return (
                        <div className="productlistdrawer-filter-details__div">
                          <Slider
                            value={bookPrice.range}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-price-slider"
                            getAriaValueText={valueText}
                            classes={{ root: classes.rootSlider }}
                            step={5}
                            min={bookPrice.min}
                            max={bookPrice.max}
                            onChange={(e, newVal) => {
                              if (parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[0]) !== newVal[0] || parseFloat(search.match(/bookprice=[^&]*/)[0].slice(10).split('|')[1]) !== newVal[1]) {
                                history.push(
                                  `/productlist/${productListName}${search.replace(/bookprice=[^&]*/, `bookprice=${newVal[0]}|${newVal[1]}`).replace(/page=[0-9]*/, 'page=1')}`,
                                  {
                                    URLOffset: paginationState.offset,
                                    URLCurrentPage: 1,
                                    URLSort: pageOptionsState.sort,
                                    URLPageLimit: pageOptionsState.PageLimit,
                                    URLPageFilters: {
                                      checkSigned,
                                      bookBindings,
                                      bookCondition,
                                      bookSection,
                                      bookPrice: newVal,
                                    },
                                  },
                                );
                              }
                            }}
                          />
                        </div>
                      );
                    })()
                  }
                </div>
              )
          }
          {
            productList
              .filter(prod => (
                formatPrice(prod.retailPrice, prod.salePriceMax, prod.salePriceMin) !== null
              ))
              .filter(prod => prod.sectionList !== null)
              .filter(prod => prod.sectionList.length)
              && (
                <div className="productlistdrawer-filter__div">
                  <div className="productlistdrawer-filter-header__div">
                    <h2 className="productlistdrawer-filter-header__h2">Section</h2>
                    <div className="productlistdrawer-filter-expand__div">
                      {
                        collapseState.section
                          ? (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ section: !prev.section } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand Less"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandLess />
                            </IconButton>
                          )
                          : (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ section: !prev.section } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand More"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandMore />
                            </IconButton>
                          )
                      }
                    </div>
                  </div>
                  <Collapse in={collapseState.section}>
                    {
                      (() => {
                        const sectionDictionary = {};

                        productList
                          .filter(product => (
                            formatPrice(product.retailPrice,
                              product.salePriceMax,
                              product.salePriceMin) !== null))
                          .filter(prod => prod.sectionList !== null)
                          .filter(prod => prod.sectionList.length)
                          .forEach((prod) => {
                            prod.sectionList.forEach((cond) => {
                              if (sectionDictionary[cond]) {
                                sectionDictionary[cond] += 1;
                              } else {
                                sectionDictionary[cond] = 1;
                              }
                            });
                          });

                        return Object.keys(sectionDictionary)
                          .sort((a, b) => {
                            if (a > b) return 1;
                            if (a < b) return -1;
                            return 0;
                          })
                          .map(sec => (
                            <div className="productlistdrawer-filter-details__div" key={sec}>
                              <Checkbox
                                classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                                value={sec}
                                color="secondary"
                                inputProps={{ 'aria-label': `Section ${sec}` }}
                                checked={bookSection.indexOf(sec) > -1}
                                onChange={
                                  (e) => {
                                    if (search.match(/booksection=[^&]*/)[0].slice(12) === 'null') {
                                      history.push(
                                        `/productlist/${productListName}${search.replace(/booksection=[^&]*/, `booksection=${e.target.value.split(' ').join('+')}`).replace(/page=[0-9]*/, 'page=1')}`,
                                        {
                                          URLOffset: paginationState.offset,
                                          URLCurrentPage: 1,
                                          URLSort: pageOptionsState.sort,
                                          URLPageLimit: pageOptionsState.PageLimit,
                                          URLPageFilters: {
                                            checkSigned,
                                            bookBindings,
                                            bookCondition,
                                            bookSection: [e.target.value],
                                            bookPrice,
                                          },
                                        },
                                      );
                                    } else {
                                      if (search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(bind => bind.split('+').join(' ')).indexOf(e.target.value) > -1) {
                                        const formatArrCopy = search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(bind => bind.split('+').join(' ')).slice();
                                        formatArrCopy.splice(formatArrCopy.indexOf(e.target.value), 1);
                                        console.log('formatCopy', formatArrCopy);
                                        const editedStr = formatArrCopy.length ? formatArrCopy.map(bind => bind.split(' ').join('+')).join('|') : 'null';

                                        history.push(
                                          `/productlist/${productListName}${search.replace(/booksection=[^&]*/, `booksection=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
                                          {
                                            URLOffset: paginationState.offset,
                                            URLCurrentPage: 1,
                                            URLSort: pageOptionsState.sort,
                                            URLPageLimit: pageOptionsState.PageLimit,
                                            URLPageFilters: {
                                              checkSigned,
                                              bookBindings,
                                              bookCondition,
                                              bookSection: formatArrCopy.length ? formatArrCopy : [],
                                              bookPrice,
                                            },
                                          },
                                        );
                                      }

                                      if (search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(bind => bind.split('+').join(' ')).indexOf(e.target.value) === -1) {
                                        const formatArrCopy = search.match(/booksection=[^&]*/)[0].slice(12).split('|').map(bind => bind.split('+').join(' ')).slice();
                                        history.push(
                                          `/productlist/${productListName}${search.replace(/booksection=[^&]*/, `booksection=${search.match(/booksection=[^&]*/)[0].slice(12).concat('|', e.target.value.split(' ').join('+'))}`).replace(/page=[0-9]*/, 'page=1')}`,
                                          {
                                            URLOffset: paginationState.offset,
                                            URLCurrentPage: 1,
                                            URLSort: pageOptionsState.sort,
                                            URLPageLimit: pageOptionsState.PageLimit,
                                            URLPageFilters: {
                                              checkSigned,
                                              bookBindings,
                                              bookCondition,
                                              bookSection: [formatArrCopy],
                                              bookPrice,
                                            },
                                          },
                                        );
                                      }
                                    }
                                  }
                                }
                              />
                              <div className="productlistdrawer-filter-text-items__div">
                                {/* <p className="productlistdrawer-filter__p productlistdrawer-filter-section__p">{`${capitalizeString(sec)} (${sectionDictionary[sec]})`}</p> */}
                                <p className="productlistdrawer-filter__p productlistdrawer-filter-section__p">{`${capitalizeString(sec)}`}</p>
                              </div>
                            </div>
                          ));
                      })()
                    }
                  </Collapse>
                </div>
              )
          }
          {
            // new bookbinding filter
            productList
              .filter(prod => (
                formatPrice(prod.retailPrice, prod.salePriceMax, prod.salePriceMin) !== null
              ))
              .filter(prod => prod.bookBinding !== null)
              .filter(prod => prod.bookBinding.length)
              && (
                <div className="productlistdrawer-filter__div">
                  <div className="productlistdrawer-filter-header__div">
                    <h2 className="productlistdrawer-filter-header__h2">Book Binding</h2>
                    <div className="productlistdrawer-filter-expand__div">
                      {
                        collapseState.binding
                          ? (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ binding: !prev.binding } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand Less"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandLess />
                            </IconButton>
                          )
                          : (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ binding: !prev.binding } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand More"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandMore />
                            </IconButton>
                          )
                      }
                    </div>
                  </div>
                  <Collapse in={collapseState.binding}>
                    {
                    (() => {
                      const bindingDictionary = {};

                      productList
                        .filter(product => (
                          formatPrice(product.retailPrice,
                            product.salePriceMax,
                            product.salePriceMin) !== null))
                        .filter(prod => prod.bookBinding !== null)
                        .filter(prod => prod.bookBinding.length)
                        .forEach((prod) => {
                          if (bindingDictionary[prod.bookBinding]) {
                            bindingDictionary[prod.bookBinding] += 1;
                          } else {
                            bindingDictionary[prod.bookBinding] = 1;
                          }
                        });

                      return Object.keys(bindingDictionary).map(binding => (
                        <div className="productlistdrawer-filter-details__div" key={binding}>
                          <Checkbox
                            classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                            value={binding}
                            color="secondary"
                            inputProps={{ 'aria-label': `Book Binding ${binding}` }}
                            checked={bookBindings.indexOf(binding) > -1}
                            onChange={
                              (e) => {
                                if (search.match(/bookbindings=[^&]*/)[0].slice(13) === 'null') {
                                  history.push(
                                    `/productlist/${productListName}${search.replace(/bookbindings=[^&]*/, `bookbindings=${e.target.value.split(' ').join('+')}`).replace(/page=[0-9]*/, 'page=1')}`,
                                    {
                                      URLOffset: paginationState.offset,
                                      URLCurrentPage: 1,
                                      URLSort: pageOptionsState.sort,
                                      URLPageLimit: pageOptionsState.PageLimit,
                                      URLPageFilters: {
                                        checkSigned,
                                        bookBindings: [e.target.value],
                                        bookCondition,
                                        bookSection,
                                        bookPrice,
                                      },
                                    },
                                  );
                                } else {
                                  if (search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(bind => bind.split('+').join(' ')).indexOf(e.target.value) > -1) {
                                    const formatArrCopy = search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(bind => bind.split('+').join(' ')).slice();
                                    formatArrCopy.splice(formatArrCopy.indexOf(e.target.value), 1);
                                    const editedStr = formatArrCopy.length ? formatArrCopy.map(bind => bind.split(' ').join('+')).join('|') : 'null';

                                    history.push(
                                      `/productlist/${productListName}${search.replace(/bookbindings=[^&]*/, `bookbindings=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
                                      {
                                        URLOffset: paginationState.offset,
                                        URLCurrentPage: 1,
                                        URLSort: pageOptionsState.sort,
                                        URLPageLimit: pageOptionsState.PageLimit,
                                        URLPageFilters: {
                                          checkSigned,
                                          bookBindings: formatArrCopy.length ? formatArrCopy : [],
                                          bookCondition,
                                          bookSection,
                                          bookPrice,
                                        },
                                      },
                                    );
                                  }

                                  if (search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(bind => bind.split('+').join(' ')).indexOf(e.target.value) === -1) {
                                    const formatArrCopy = search.match(/bookbindings=[^&]*/)[0].slice(13).split('|').map(bind => bind.split('+').join(' ')).slice();
                                    history.push(
                                      `/productlist/${productListName}${search.replace(/bookbindings=[^&]*/, `bookbindings=${search.match(/bookbindings=[^&]*/)[0].slice(13).concat('|', e.target.value.split(' ').join('+'))}`).replace(/page=[0-9]*/, 'page=1')}`,
                                      {
                                        URLOffset: paginationState.offset,
                                        URLCurrentPage: 1,
                                        URLSort: pageOptionsState.sort,
                                        URLPageLimit: pageOptionsState.PageLimit,
                                        URLPageFilters: {
                                          checkSigned,
                                          bookBindings: [formatArrCopy],
                                          bookCondition,
                                          bookSection,
                                          bookPrice,
                                        },
                                      },
                                    );
                                  }
                                }
                              }
                            }
                          />
                          <div className="productlistdrawer-filter-text-items__div">
                            <p className="productlistdrawer-filter__p">{`${binding === 'PaperBack' ? 'Paperback' : binding}`}</p>
                          </div>
                        </div>
                      ));
                    })()
                  }
                  </Collapse>
                </div>
              )
          }
          {
            productList
              .filter(prod => (
                formatPrice(prod.retailPrice, prod.salePriceMax, prod.salePriceMin) !== null
              ))
              .filter(prod => prod.conditionList !== null)
              .filter(prod => prod.conditionList.length)
              && (
                <div className="productlistdrawer-filter__div">
                  <div className="productlistdrawer-filter-header__div">
                    <h2 className="productlistdrawer-filter-header__h2">Condition</h2>
                    <div className="productlistdrawer-filter-expand__div">
                      {
                        collapseState.condition
                          ? (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ condition: !prev.condition } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand Less"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandLess />
                            </IconButton>
                          )
                          : (
                            <IconButton
                              onClick={
                                () => setCollapse(prev => ({ ...prev, ...{ condition: !prev.condition } }))
                              }
                              classes={{ root: classes.rootIconButton }}
                              aria-label="Expand More"
                              disableRipple
                              disableTouchRipple
                            >
                              <ExpandMore />
                            </IconButton>
                          )
                      }
                    </div>
                  </div>
                  <Collapse in={collapseState.condition}>
                    {
                      (() => {
                        const conditionDictionary = {};

                        productList
                          .filter(product => (
                            formatPrice(product.retailPrice,
                              product.salePriceMax,
                              product.salePriceMin) !== null))
                          .filter(prod => prod.conditionList !== null)
                          .filter(prod => prod.conditionList.length)
                          .forEach((prod) => {
                            prod.conditionList.forEach((cond) => {
                              if (conditionDictionary[cond]) {
                                conditionDictionary[cond] += 1;
                              } else {
                                conditionDictionary[cond] = 1;
                              }
                            });
                          });

                        return Object.keys(conditionDictionary).map(cond => (
                          <div className="productlistdrawer-filter-details__div" key={cond}>
                            <Checkbox
                              classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                              value={cond}
                              color="secondary"
                              inputProps={{ 'aria-label': `Condition ${cond}` }}
                              checked={bookCondition.indexOf(cond) > -1}
                              onChange={
                                (e) => {
                                  if (search.match(/bookcondition=[^&]*/)[0].slice(14) === 'null') {
                                    history.push(
                                      `/productlist/${productListName}${search.replace(/bookcondition=[^&]*/, `bookcondition=${e.target.value.split(' ').join('+')}`).replace(/page=[0-9]*/, 'page=1')}`,
                                      {
                                        URLOffset: paginationState.offset,
                                        URLCurrentPage: 1,
                                        URLSort: pageOptionsState.sort,
                                        URLPageLimit: pageOptionsState.PageLimit,
                                        URLPageFilters: {
                                          checkSigned,
                                          bookBindings,
                                          bookCondition: [e.target.value],
                                          bookSection,
                                          bookPrice,
                                        },
                                      },
                                    );
                                  } else {
                                    if (search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')).indexOf(e.target.value) > -1) {
                                      const formatArrCopy = search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')).slice();
                                      formatArrCopy.splice(formatArrCopy.indexOf(e.target.value), 1);
                                      const editedStr = formatArrCopy.length ? formatArrCopy.map(condition => condition.split(' ').join('+')).join('|') : 'null';

                                      history.push(
                                        `/productlist/${productListName}${search.replace(/bookcondition=[^&]*/, `bookcondition=${editedStr}`).replace(/page=[0-9]*/, 'page=1')}`,
                                        {
                                          URLOffset: paginationState.offset,
                                          URLCurrentPage: 1,
                                          URLSort: pageOptionsState.sort,
                                          URLPageLimit: pageOptionsState.PageLimit,
                                          URLPageFilters: {
                                            checkSigned,
                                            bookBindings,
                                            bookCondition: formatArrCopy.length ? formatArrCopy : [],
                                            bookSection,
                                            bookPrice,
                                          },
                                        },
                                      );
                                    }

                                    if (search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')).indexOf(e.target.value) === -1) {
                                      const formatArrCopy = search.match(/bookcondition=[^&]*/)[0].slice(14).split('|').map(condition => condition.split('+').join(' ')).slice();
                                      history.push(
                                        `/productlist/${productListName}${search.replace(/bookcondition=[^&]*/, `bookcondition=${search.match(/bookcondition=[^&]*/)[0].slice(14).concat('|', e.target.value.split(' ').join('+'))}`).replace(/page=[0-9]*/, 'page=1')}`,
                                        {
                                          URLOffset: paginationState.offset,
                                          URLCurrentPage: 1,
                                          URLSort: pageOptionsState.sort,
                                          URLPageLimit: pageOptionsState.PageLimit,
                                          URLPageFilters: {
                                            checkSigned,
                                            bookBindings,
                                            bookCondition: [formatArrCopy],
                                            bookSection,
                                            bookPrice,
                                          },
                                        },
                                      );
                                    }
                                  }
                                }
                              }
                            />
                            <div className="productlistdrawer-filter-text-items__div">
                              <p className="productlistdrawer-filter__p">{`${cond}`}</p>
                            </div>
                          </div>
                        ));
                      })()
                    }
                  </Collapse>
                </div>
              )
          }
          {
            productList
              .filter(product => (
                formatPrice(product.retailPrice,
                  product.salePriceMax,
                  product.salePriceMin) !== null))
              .filter(prod => prod.signedIdList !== null)
              .filter(prod => prod.signedIdList.length > 0).length > 0
              && (
                <div className="productlistdrawer-filter__div">
                  <h2 className="productlistdrawer-filter-header__h2">Signed</h2>
                  <div className="productlistdrawer-filter-details__div">
                    <Checkbox
                      classes={{ colorSecondary: classes.colorSecondaryCheckbox }}
                      checked={checkSigned}
                      inputProps={{ 'aria-label': 'Signed Checkbox' }}
                      onChange={
                        () => {
                          history.push(
                            `/productlist/${productListName}${search.replace(/signed=false|signed=true/, `signed=${JSON.stringify(!JSON.parse(search.match(/signed=false|signed=true/)[0].slice(7)))}`).replace(/page=[0-9]*/, 'page=1')}`,
                            {
                              URLOffset: paginationState.offset,
                              URLCurrentPage: 1,
                              URLSort: pageOptionsState.sort,
                              URLPageLimit: pageOptionsState.PageLimit,
                              URLPageFilters: {
                                checkSigned: !JSON.parse(search.match(/signed=false|signed=true/)[0].slice(7)),
                                bookBindings,
                                bookCondition,
                                bookSection,
                                bookPrice,
                              },
                            },
                          );
                        }
                      }
                      value="checkedSigned"
                      color="secondary"
                    />
                    <p className="productlistdrawer-filter__p">
                      signed&nbsp;
                      {/* {`(${productList.filter(prod => prod.signedIdList !== null).filter(prod => prod.signedIdList.length > 0).length})`} */}
                    </p>
                  </div>
                </div>
              )
          }
        </div>
      </aside>
    );
};

ProductListDrawer.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkSigned: PropTypes.bool.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bookBindings: PropTypes.arrayOf(PropTypes.any).isRequired,
  bookCondition: PropTypes.arrayOf(PropTypes.any).isRequired,
  bookSection: PropTypes.arrayOf(PropTypes.any).isRequired,
  bookPrice: PropTypes.objectOf(PropTypes.any).isRequired,
  search: PropTypes.objectOf(PropTypes.any).isRequired,
  pageOptionsState: PropTypes.objectOf(PropTypes.any).isRequired,
  paginationState: PropTypes.objectOf(PropTypes.any).isRequired,
  productListName: PropTypes.string.isRequired,
};

export default ProductListDrawer;
