import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LazyLoad from 'react-lazyload';
import ViewNavbar from '../../views/viewNavbar/ViewNavbar';
import ViewFooter from '../../views/viewFooter/ViewFooter';
import PageHeader from '../pageHeader/PageHeader';
import Loading from '../loading/Loading';
import WithEvent from '../withEvent/WithEvent';
import use4Cite from '../../hooks/use4Cite';
import metaListDictionary, { metaListFlags, deletedMetaList } from '../../helpers/metaListDictionary';
import convertURL from '../../helpers/convertURL';
import './_productMetaList.scss';

const META_LIST_DETAIL = gql`
  query getMetaListDetail($metaTag: String!){
    metaListDetail(metaTag: $metaTag){
    metaTag
    product{
      gtin13
      id
      imageUrl
      retailStv
      retailPrice
      title
      originator
      apparel{
        apparelId
        size
        sizeDesc
        inventory{
          catalogId
          condition
          sku
          onHand
          salePriceStv
        }
      }
      inventory{
        salePriceStv
        signed
      }
    }
    }
}`;

const ProductMetaList = (props) => {
  const {
    cartList,
    customerLoggedIn,
    checkoutIsOpen,
    refetchCartList,
    refetchCustomerLoggedIn,
    refetchCheckoutIsOpen,
  } = props;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  use4Cite();

  const match767 = useMediaQuery('(min-width: 767px)');

  const { location } = props;

  // const [sortState, setSort] = useState(1);

  //   console.log(location);

  const listname = metaListDictionary(location.pathname.split('/').pop());

  const {
    error: errorMeta,
    loading: loadingMeta,
    data: dataMeta,
  } = useQuery(META_LIST_DETAIL, { variables: { metaTag: listname.metaTag }, skip: deletedMetaList[location.pathname.split('/').pop()] || listname?.noMetalist });

  if (listname?.noMetalist) {
    return <Redirect to="/page-not-found" />;
  }

  if (errorMeta) return <Redirect to={{ pathname: '/error', state: { errorMeta } }} />;

  if (loadingMeta) {
    return (
      <React.Fragment>
        <ViewNavbar
          cartList={cartList}
          checkoutIsOpen={checkoutIsOpen}
          customerLoggedIn={customerLoggedIn}
          refetchCartList={refetchCartList}
          refetchCustomerLoggedIn={refetchCustomerLoggedIn}
          refetchCheckoutIsOpen={refetchCheckoutIsOpen}
        />
        <div className="productmetalist-container__div">
          <Loading />
        </div>
        <ViewFooter />
      </React.Fragment>
    );
  }

  // console.log(dataMeta);

  return (
    <React.Fragment>
      <ViewNavbar
        cartList={cartList}
        checkoutIsOpen={checkoutIsOpen}
        customerLoggedIn={customerLoggedIn}
        refetchCartList={refetchCartList}
        refetchCustomerLoggedIn={refetchCustomerLoggedIn}
        refetchCheckoutIsOpen={refetchCheckoutIsOpen}
      />
      <div className="productmetalist-container__div">
        <main className="productmetalist-content__main">
          {
            dataMeta
              && dataMeta.metaListDetail
              && dataMeta.metaListDetail.metaTag
              && dataMeta.metaListDetail.product
              && dataMeta.metaListDetail.product.length > 0
              ? (
                <div className="productmetalist-content__div">
                  {
                    listname.landingImage
                      ? (
                        <div className="productmetalist-lp-image__div">
                          <img
                            className="productmetalist-lp-image__img"
                            src={match767 ? listname.landingImage : (listname?.landingImageMobile || listname.landingImage)}
                            alt={listname.alt ? listname.alt : listname.metaTag}
                          />
                        </div>
                      )
                      : (
                        <PageHeader
                          header={listname.alt ? listname.alt : listname.metaTag}
                        />
                      )
                  }
                  {
                    listname.landingDesc.length > 0
                      && (
                        <div className="productmetalist-lp-desc__div" dangerouslySetInnerHTML={(() => ({ __html: listname.landingDesc }))()} />
                      )
                  }
                  {/* <section className="productmetalist-sort__section">
                    <div className="productmetalist-sort__div">
                      Sort Options
                    </div>
                  </section> */}
                  {
                    listname.promoteTopImage
                      && dataMeta
                      && dataMeta.metaListDetail
                      && dataMeta.metaListDetail.metaTag
                      && (
                        <Link
                          to={listname.promoteTopLink}
                          onClick={() => WithEvent('METALIST', `Clicked ${dataMeta.metaListDetail.metaTag} top promo`, 'META_TOP_PROMO', 1)}
                        >
                          <div className="productmetalist-promotetop__div">
                            <img
                              src={listname.promoteTopImage}
                              alt="top promotion"
                              className="productmetalist-promotetop__img"
                            />
                          </div>
                        </Link>
                      )
                  }
                  <section className="productmetalist-products__section">
                    <div className="productmetalist-products__div">
                      {
                        dataMeta.metaListDetail.product
                        // filter out null items
                          .filter(prod => prod)
                          // filter out of stock items
                          .filter(prod => (
                            (prod.inventory && prod.inventory.length > 0)
                              || prod.apparel
                          ))
                          // filter products that ran out of signed skus
                          .filter((prod) => {
                            if (listname.metaTag === 'Signed') {
                              return prod?.inventory.some(item => item.signed);
                            } return prod;
                          })
                          .map((prod, i) => {
                            const {
                              gtin13,
                              id,
                              imageUrl,
                              // retailStv,
                              originator,
                              title,
                              inventory,
                              apparel,
                            } = prod;

                            return (
                              <Link
                                className="productmetalist-product-link__link"
                                key={id}
                                to={{ pathname: `/product/${gtin13}`, search: `?title=${convertURL(title)}`, state: { title: convertURL(title), gtin13 } }}
                                onClick={() => WithEvent('METALIST', `Clicked ${gtin13} product`, 'META_PRODUCT', 1)}
                              >
                                <div className="productmetalist-product__div" key={id}>
                                  <LazyLoad height={130} offset={100}>
                                    <div className="productmetalist-image__div">
                                      {
                                        dataMeta.metaListDetail.metaTag === 'Bestsellers'
                                        && (
                                        <div className="productmetalist-bestseller-flag__div">{i + 1}</div>
                                        )
                                      }
                                      {
                                        metaListFlags[gtin13]
                                          && dataMeta.metaListDetail.metaTag !== 'Bestsellers'
                                          && (
                                            <div className="productmetalist-flag__div">
                                              {metaListFlags[gtin13]}
                                            </div>
                                          )
                                      }
                                      <img alt={title} src={imageUrl} className="productmetalist-image__img" />
                                    </div>
                                  </LazyLoad>
                                  <div className="productmetalist-details__div">
                                    <h4 className="productmetalist-header__h4">{title}</h4>
                                    <p className="productmetalist-details__p">{originator}</p>
                                    {
                                      inventory
                                        && inventory.length > 0
                                        && inventory[0]
                                        && inventory[0].salePriceStv
                                        && <p className="productmetalist-details__p">{`$${(inventory[0].salePriceStv * 0.01).toFixed(2)}`}</p>
                                    }
                                    {
                                      apparel
                                        && apparel.length > 0
                                        && apparel[0]
                                        && apparel[0].inventory
                                        && apparel[0].inventory.length > 0
                                        && apparel[0].inventory[0]
                                        && apparel[0].inventory[0].salePriceStv
                                        && <p className="productmetalist-details__p">{`$${(apparel[0].inventory[0].salePriceStv * 0.01).toFixed(2)}`}</p>
                                    }
                                    {
                                      inventory
                                        && inventory.length === 0
                                        && !apparel
                                        && <p className="productmetalist-details__p productmetalist-details-out-of-stock__p">Out of stock</p>
                                    }
                                  </div>
                                </div>
                              </Link>
                            );
                          })
                      }
                    </div>
                  </section>
                </div>
              )
              : (
                <div className="productmetalist-content__div">
                  <p className="productmetalist-no-products__p">
                    We&#39;re sorry--we don&#39;t have the products you are looking for.
                  </p>
                </div>
              )
          }
        </main>
      </div>
      <ViewFooter />
    </React.Fragment>
  );
};

ProductMetaList.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  cartList: PropTypes.arrayOf(PropTypes.object).isRequired,
  customerLoggedIn: PropTypes.bool.isRequired,
  checkoutIsOpen: PropTypes.bool.isRequired,
  refetchCartList: PropTypes.func.isRequired,
  refetchCustomerLoggedIn: PropTypes.func.isRequired,
  refetchCheckoutIsOpen: PropTypes.func.isRequired,
};

export default ProductMetaList;
