import { useState, useEffect } from 'react';

const useFormInput = () => {
  const [inputState, setInput] = useState('');

  const handleChange = (e) => {
    if (e) {
      e.persist();
      setInput(e.target.value);
    } else setInput(e);
  };

  useEffect(() => setInput(''), []);

  return { inputState, handleChange };
};

export default useFormInput;
